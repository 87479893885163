export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_ERROR = 'FETCH_TASKS_ERROR';

export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASK_START = 'FETCH_TASK_START';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_TASK_ERROR = 'FETCH_TASK_ERROR';

export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_START = 'CREATE_TASK_START';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR';

export const CREATE_TASKS = 'CREATE_TASKS';
export const CREATE_TASKS_START = 'CREATE_TASKS_START';
export const CREATE_TASKS_SUCCESS = 'CREATE_TASKS_SUCCESS';
export const CREATE_TASKS_ERROR = 'CREATE_TASKS_ERROR';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_START = 'UPDATE_TASK_START';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const LEAVE_TASK = 'LEAVE_TASK';
export const LEAVE_TASK_START = 'LEAVE_TASK_START';
export const LEAVE_TASK_SUCCESS = 'LEAVE_TASK_SUCCESS';
export const LEAVE_TASK_ERROR = 'LEAVE_TASK_ERROR';

export const PAUSE_TASK = 'PAUSE_TASK';
export const PAUSE_TASK_START = 'PAUSE_TASK_START';
export const PAUSE_TASK_SUCCESS = 'PAUSE_TASK_SUCCESS';
export const PAUSE_TASK_ERROR = 'PAUSE_TASK_ERROR';

export const INCOMPLETE_SPECIFICATION_TASK = 'INCOMPLETE_SPECIFICATION_TASK';
export const INCOMPLETE_SPECIFICATION_TASK_START = 'INCOMPLETE_SPECIFICATION_TASK_START';
export const INCOMPLETE_SPECIFICATION_TASK_SUCCESS = 'INCOMPLETE_SPECIFICATION_TASK_SUCCESS';
export const INCOMPLETE_SPECIFICATION_TASK_ERROR = 'INCOMPLETE_SPECIFICATION_TASK_ERROR';

export const ACCEPT_TASK = 'ACCEPT_TASK';
export const ACCEPT_TASK_START = 'ACCEPT_TASK_START';
export const ACCEPT_TASK_SUCCESS = 'ACCEPT_TASK_SUCCESS';
export const ACCEPT_TASK_ERROR = 'ACCEPT_TASK_ERROR';

export const REVISION_TASK = 'REVISION_TASK';
export const REVISION_TASK_START = 'REVISION_TASK_START';
export const REVISION_TASK_SUCCESS = 'REVISION_TASK_SUCCESS';
export const REVISION_TASK_ERROR = 'REVISION_TASK_ERROR';

export const RESTORE_TASK = 'RESTORE_TASK';
export const RESTORE_TASK_START = 'RESTORE_TASK_START';
export const RESTORE_TASK_SUCCESS = 'RESTORE_TASK_SUCCESS';
export const RESTORE_TASK_ERROR = 'RESTORE_TASK_ERROR';

export const CANCEL_TASK = 'CANCEL_TASK';
export const CANCEL_TASK_START = 'CANCEL_TASK_START';
export const CANCEL_TASK_SUCCESS = 'CANCEL_TASK_SUCCESS';
export const CANCEL_TASK_ERROR = 'CANCEL_TASK_ERROR';

export const REALLOCATE_TASK = 'REALLOCATE_TASK';
export const REALLOCATE_TASK_START = 'REALLOCATE_TASK_START';
export const REALLOCATE_TASK_SUCCESS = 'REALLOCATE_TASK_SUCCESS';
export const REALLOCATE_TASK_ERROR = 'REALLOCATE_TASK_ERROR';

export const SEND_TASK_RESULTS_TO_EMAIL = 'SEND_TASK_RESULTS_TO_EMAIL';
export const SEND_TASK_RESULTS_TO_EMAIL_START = 'SEND_TASK_RESULTS_TO_EMAIL_START';
export const SEND_TASK_RESULTS_TO_EMAIL_SUCCESS = 'SEND_TASK_RESULTS_TO_EMAIL_SUCCESS';
export const SEND_TASK_RESULTS_TO_EMAIL_ERROR = 'SEND_TASK_RESULTS_TO_EMAIL_ERROR';

export const SUBMIT_TASK_RESULTS = 'SUBMIT_TASK_RESULTS';
export const SUBMIT_TASK_RESULTS_START = 'SUBMIT_TASK_RESULTS_START';
export const SUBMIT_TASK_RESULTS_SUCCESS = 'SUBMIT_TASK_RESULTS_SUCCESS';
export const SUBMIT_TASK_RESULTS_ERROR = 'SUBMIT_TASK_RESULTS_ERROR';

export const SUBMIT_TASK_SOURCE_FILES = 'SUBMIT_TASK_SOURCE_FILES';
export const SUBMIT_TASK_SOURCE_FILES_START = 'SUBMIT_TASK_SOURCE_FILES_START';
export const SUBMIT_TASK_SOURCE_FILES_SUCCESS = 'SUBMIT_TASK_SOURCE_FILES_SUCCESS';
export const SUBMIT_TASK_SOURCE_FILES_ERROR = 'SUBMIT_TASK_SOURCE_FILES_ERROR';

export const SUBMIT_TASK_FILE_ATTACHMENTS = 'SUBMIT_TASK_FILE_ATTACHMENTS';
export const SUBMIT_TASK_FILE_ATTACHMENTS_START = 'SUBMIT_TASK_FILE_ATTACHMENTS_START';
export const SUBMIT_TASK_FILE_ATTACHMENTS_SUCCESS = 'SUBMIT_TASK_FILE_ATTACHMENTS_SUCCESS';
export const SUBMIT_TASK_FILE_ATTACHMENTS_ERROR = 'SUBMIT_TASK_FILE_ATTACHMENTS_ERROR';

export const SUBMIT_TASK_SELECTED_PRODUCTS = 'SUBMIT_TASK_SELECTED_PRODUCTS';
export const SUBMIT_TASK_SELECTED_PRODUCTS_START = 'SUBMIT_TASK_SELECTED_PRODUCTS_START';
export const SUBMIT_TASK_SELECTED_PRODUCTS_SUCCESS = 'SUBMIT_TASK_SELECTED_PRODUCTS_SUCCESS';
export const SUBMIT_TASK_SELECTED_PRODUCTS_ERROR = 'SUBMIT_TASK_SELECTED_PRODUCTS_ERROR';

export const SUBMIT_TASK_SELECTED_TEMPLATES = 'SUBMIT_TASK_SELECTED_TEMPLATES';
export const SUBMIT_TASK_SELECTED_TEMPLATES_START = 'SUBMIT_TASK_SELECTED_TEMPLATES_START';
export const SUBMIT_TASK_SELECTED_TEMPLATES_SUCCESS = 'SUBMIT_TASK_SELECTED_TEMPLATES_SUCCESS';
export const SUBMIT_TASK_SELECTED_TEMPLATES_ERROR = 'SUBMIT_TASK_SELECTED_TEMPLATES_ERROR';

export const SUBMIT_TASK_SELECTED_MATERIALS = 'SUBMIT_TASK_SELECTED_MATERIALS';
export const SUBMIT_TASK_SELECTED_MATERIALS_START = 'SUBMIT_TASK_SELECTED_MATERIALS_START';
export const SUBMIT_TASK_SELECTED_MATERIALS_SUCCESS = 'SUBMIT_TASK_SELECTED_MATERIALS_SUCCESS';
export const SUBMIT_TASK_SELECTED_MATERIALS_ERROR = 'SUBMIT_TASK_SELECTED_MATERIALS_ERROR';

export const DOWNLOAD_TASK_RESULTS = 'DOWNLOAD_TASK_RESULTS';
export const DOWNLOAD_TASK_RESULTS_START = 'DOWNLOAD_TASK_RESULTS_START';
export const DOWNLOAD_TASK_RESULTS_SUCCESS = 'DOWNLOAD_TASK_RESULTS_SUCCESS';
export const DOWNLOAD_TASK_RESULTS_ERROR = 'DOWNLOAD_TASK_RESULTS_ERROR';

export const DOWNLOAD_TASKS_RESULTS = 'DOWNLOAD_TASKS_RESULTS';
export const DOWNLOAD_TASKS_RESULTS_START = 'DOWNLOAD_TASKS_RESULTS_START';
export const DOWNLOAD_TASKS_RESULTS_SUCCESS = 'DOWNLOAD_TASKS_RESULTS_SUCCESS';
export const DOWNLOAD_TASKS_RESULTS_ERROR = 'DOWNLOAD_TASKS_RESULTS_ERROR';

export const DOWNLOAD_TASK_FILE_ATTACHMENTS = 'DOWNLOAD_TASK_FILE_ATTACHMENTS';
export const DOWNLOAD_TASK_FILE_ATTACHMENTS_START = 'DOWNLOAD_TASK_FILE_ATTACHMENTS_START';
export const DOWNLOAD_TASK_FILE_ATTACHMENTS_SUCCESS = 'DOWNLOAD_TASK_FILE_ATTACHMENTS_SUCCESS';
export const DOWNLOAD_TASK_FILE_ATTACHMENTS_ERROR = 'DOWNLOAD_TASK_FILE_ATTACHMENTS_ERROR';

export const DOWNLOAD_TASK_ALLOCATIONS = 'DOWNLOAD_TASK_ALLOCATIONS';
export const DOWNLOAD_TASK_ALLOCATIONS_START = 'DOWNLOAD_TASK_ALLOCATIONS_START';
export const DOWNLOAD_TASK_ALLOCATIONS_SUCCESS = 'DOWNLOAD_TASK_ALLOCATIONS_SUCCESS';
export const DOWNLOAD_TASK_ALLOCATIONS_ERROR = 'DOWNLOAD_TASK_ALLOCATIONS_ERROR';

export const DOWNLOAD_TASK_SOURCE_FILES = 'DOWNLOAD_TASK_SOURCE_FILES';
export const DOWNLOAD_TASK_SOURCE_FILES_START = 'DOWNLOAD_TASK_SOURCE_FILES_START';
export const DOWNLOAD_TASK_SOURCE_FILES_SUCCESS = 'DOWNLOAD_TASK_SOURCE_FILES_SUCCESS';
export const DOWNLOAD_TASK_SOURCE_FILES_ERROR = 'DOWNLOAD_TASK_SOURCE_FILES_ERROR';

export const DOWNLOAD_TASK_SELECTED_PRODUCTS = 'DOWNLOAD_TASK_SELECTED_PRODUCTS';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_START = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_START';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_SUCCESS = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_ERROR = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_ERROR';

export const DOWNLOAD_TASK_SELECTED_TEMPLATES = 'DOWNLOAD_TASK_SELECTED_TEMPLATES';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_START = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_START';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_SUCCESS = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_ERROR = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_ERROR';

export const DOWNLOAD_TASK_SELECTED_MATERIALS = 'DOWNLOAD_TASK_SELECTED_MATERIALS';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_START = 'DOWNLOAD_TASK_SELECTED_MATERIALS_START';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_SUCCESS = 'DOWNLOAD_TASK_SELECTED_MATERIALS_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_ERROR = 'DOWNLOAD_TASK_SELECTED_MATERIALS_ERROR';

export const DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_START = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_START';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_SUCCESS = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_ERROR = 'DOWNLOAD_TASK_SELECTED_PRODUCTS_PDF_ERROR';

export const DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_START = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_START';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_SUCCESS = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_ERROR = 'DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_ERROR';

export const DOWNLOAD_TASK_SELECTED_MATERIALS_PDF = 'DOWNLOAD_TASK_SELECTED_MATERIALS_PDF';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_START = 'DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_START';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_SUCCESS = 'DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_SUCCESS';
export const DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_ERROR = 'DOWNLOAD_TASK_SELECTED_MATERIALS_PDF_ERROR';

export const TRACK_TASK_TIME = 'TRACK_TASK_TIME';
export const TRACK_TASK_TIME_START = 'TRACK_TASK_TIME_START';
export const TRACK_TASK_TIME_SUCCESS = 'TRACK_TASK_TIME_SUCCESS';
export const TRACK_TASK_TIME_ERROR = 'TRACK_TASK_TIME_ERROR';

export const TRACK_TASKS_TIME = 'TRACK_TASKS_TIME';
export const TRACK_TASKS_TIME_START = 'TRACK_TASKS_TIME_START';
export const TRACK_TASKS_TIME_SUCCESS = 'TRACK_TASKS_TIME_SUCCESS';
export const TRACK_TASKS_TIME_ERROR = 'TRACK_TASKS_TIME_ERROR';

export const TOGGLE_SELECTED_TASK = 'TOGGLE_SELECTED_TASK';
export const TOGGLE_SELECTED_TASK_START = 'TOGGLE_SELECTED_TASK_START';
export const TOGGLE_SELECTED_TASK_SUCCESS = 'TOGGLE_SELECTED_TASK_SUCCESS';
export const TOGGLE_SELECTED_TASK_ERROR = 'TOGGLE_SELECTED_TASK_ERROR';

export const REMOVE_TASK_FILE_ATTACHMENTS = 'REMOVE_TASK_FILE_ATTACHMENTS';
export const REMOVE_TASK_FILE_ATTACHMENTS_START = 'REMOVE_TASK_FILE_ATTACHMENTS_START';
export const REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS = 'REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS';
export const REMOVE_TASK_FILE_ATTACHMENTS_ERROR = 'REMOVE_TASK_FILE_ATTACHMENTS_ERROR';

export const REMOVE_TASK_RESULTS = 'REMOVE_TASK_RESULTS';
export const REMOVE_TASK_RESULTS_START = 'REMOVE_TASK_RESULTS_START';
export const REMOVE_TASK_RESULTS_SUCCESS = 'REMOVE_TASK_RESULTS_SUCCESS';
export const REMOVE_TASK_RESULTS_ERROR = 'REMOVE_TASK_RESULTS_ERROR';

export const REMOVE_TASK_SOURCE_FILES = 'REMOVE_TASK_SOURCE_FILES';
export const REMOVE_TASK_SOURCE_FILES_START = 'REMOVE_TASK_SOURCE_FILES_START';
export const REMOVE_TASK_SOURCE_FILES_SUCCESS = 'REMOVE_TASK_SOURCE_FILES_SUCCESS';
export const REMOVE_TASK_SOURCE_FILES_ERROR = 'REMOVE_TASK_SOURCE_FILES_ERROR';

export const REMOVE_TASK_SELECTED_PRODUCTS = 'REMOVE_TASK_SELECTED_PRODUCTS';
export const REMOVE_TASK_SELECTED_PRODUCTS_START = 'REMOVE_TASK_SELECTED_PRODUCTS_START';
export const REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS = 'REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS';
export const REMOVE_TASK_SELECTED_PRODUCTS_ERROR = 'REMOVE_TASK_SELECTED_PRODUCTS_ERROR';

export const REMOVE_TASK_SELECTED_TEMPLATES = 'REMOVE_TASK_SELECTED_TEMPLATES';
export const REMOVE_TASK_SELECTED_TEMPLATES_START = 'REMOVE_TASK_SELECTED_TEMPLATES_START';
export const REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS = 'REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS';
export const REMOVE_TASK_SELECTED_TEMPLATES_ERROR = 'REMOVE_TASK_SELECTED_TEMPLATES_ERROR';

export const REMOVE_TASK_SELECTED_MATERIALS = 'REMOVE_TASK_SELECTED_MATERIALS';
export const REMOVE_TASK_SELECTED_MATERIALS_START = 'REMOVE_TASK_SELECTED_MATERIALS_START';
export const REMOVE_TASK_SELECTED_MATERIALS_SUCCESS = 'REMOVE_TASK_SELECTED_MATERIALS_SUCCESS';
export const REMOVE_TASK_SELECTED_MATERIALS_ERROR = 'REMOVE_TASK_SELECTED_MATERIALS_ERROR';

export const CREATE_TASK_BILLS = 'CREATE_TASK_BILLS';
export const CREATE_TASK_BILLS_START = 'CREATE_TASK_BILLS_START';
export const CREATE_TASK_BILLS_SUCCESS = 'CREATE_TASK_BILLS_SUCCESS';
export const CREATE_TASK_BILLS_ERROR = 'CREATE_TASK_BILLS_ERROR';

export const CREATE_TASKS_BILLS = 'CREATE_TASKS_BILLS';
export const CREATE_TASKS_BILLS_START = 'CREATE_TASKS_BILLS_START';
export const CREATE_TASKS_BILLS_SUCCESS = 'CREATE_TASKS_BILLS_SUCCESS';
export const CREATE_TASKS_BILLS_ERROR = 'CREATE_TASKS_BILLS_ERROR';

export const UPDATE_TASKS_AMOUNTS = 'UPDATE_TASKS_AMOUNTS';
export const UPDATE_TASKS_AMOUNTS_START = 'UPDATE_TASKS_AMOUNTS_START';
export const UPDATE_TASKS_AMOUNTS_SUCCESS = 'UPDATE_TASKS_AMOUNTS_SUCCESS';
export const UPDATE_TASKS_AMOUNTS_ERROR = 'UPDATE_TASKS_AMOUNTS_ERROR';

export const UPDATE_TASKS_ESTIMATE_DATES = 'UPDATE_TASKS_ESTIMATE_DATES';
export const UPDATE_TASKS_ESTIMATE_DATES_START = 'UPDATE_TASKS_ESTIMATE_DATES_START';
export const UPDATE_TASKS_ESTIMATE_DATES_SUCCESS = 'UPDATE_TASKS_ESTIMATE_DATES_SUCCESS';
export const UPDATE_TASKS_ESTIMATE_DATES_ERROR = 'UPDATE_TASKS_ESTIMATE_DATES_ERROR';

export const ACCEPT_INVITE_TO_TASK = 'ACCEPT_INVITE_TO_TASK';
export const ACCEPT_INVITE_TO_TASK_START = 'ACCEPT_INVITE_TO_TASK_START';
export const ACCEPT_INVITE_TO_TASK_SUCCESS = 'ACCEPT_INVITE_TO_TASK_SUCCESS';
export const ACCEPT_INVITE_TO_TASK_ERROR = 'ACCEPT_INVITE_TO_TASK_ERROR';

export const ACCEPT_TASK_RESULTS = 'ACCEPT_TASK_RESULTS';
export const ACCEPT_TASK_RESULTS_START = 'ACCEPT_TASK_RESULTS_START';
export const ACCEPT_TASK_RESULTS_SUCCESS = 'ACCEPT_TASK_RESULTS_SUCCESS';
export const ACCEPT_TASK_RESULTS_ERROR = 'ACCEPT_TASK_RESULTS_ERROR';

export const ACCEPT_TASKS_RESULTS = 'ACCEPT_TASKS_RESULTS';
export const ACCEPT_TASKS_RESULTS_START = 'ACCEPT_TASKS_RESULTS_START';
export const ACCEPT_TASKS_RESULTS_SUCCESS = 'ACCEPT_TASKS_RESULTS_SUCCESS';
export const ACCEPT_TASKS_RESULTS_ERROR = 'ACCEPT_TASKS_RESULTS_ERROR';

export const APPROVE_TASK_RESULTS = 'APPROVE_TASK_RESULTS';
export const APPROVE_TASK_RESULTS_START = 'APPROVE_TASK_RESULTS_START';
export const APPROVE_TASK_RESULTS_SUCCESS = 'APPROVE_TASK_RESULTS_SUCCESS';
export const APPROVE_TASK_RESULTS_ERROR = 'APPROVE_TASK_RESULTS_ERROR';

export const REVISION_TASK_RESULTS = 'REVISION_TASK_RESULTS';
export const REVISION_TASK_RESULTS_START = 'REVISION_TASK_RESULTS_START';
export const REVISION_TASK_RESULTS_SUCCESS = 'REVISION_TASK_RESULTS_SUCCESS';
export const REVISION_TASK_RESULTS_ERROR = 'REVISION_TASK_RESULTS_ERROR';

export const INVITE_USERS_TO_TASK = 'INVITE_USERS_TO_TASK';
export const INVITE_USERS_TO_TASK_START = 'INVITE_USERS_TO_TASK_START';
export const INVITE_USERS_TO_TASK_SUCCESS = 'INVITE_USERS_TO_TASK_SUCCESS';
export const INVITE_USERS_TO_TASK_ERROR = 'INVITE_USERS_TO_TASK_ERROR';

export const INVITE_USERS_TO_TASKS = 'INVITE_USERS_TO_TASKS';
export const INVITE_USERS_TO_TASKS_START = 'INVITE_USERS_TO_TASKS_START';
export const INVITE_USERS_TO_TASKS_SUCCESS = 'INVITE_USERS_TO_TASKS_SUCCESS';
export const INVITE_USERS_TO_TASKS_ERROR = 'INVITE_USERS_TO_TASKS_ERROR';

export const ALLOCATE_FUNDS_TO_TASKS = 'ALLOCATE_FUNDS_TO_TASKS';
export const ALLOCATE_FUNDS_TO_TASKS_START = 'ALLOCATE_FUNDS_TO_TASKS_START';
export const ALLOCATE_FUNDS_TO_TASKS_SUCCESS = 'ALLOCATE_FUNDS_TO_TASKS_SUCCESS';
export const ALLOCATE_FUNDS_TO_TASKS_ERROR = 'ALLOCATE_FUNDS_TO_TASKS_ERROR';

export const UPDATE_TASK_PROJECT_ESTIMATE_COST = 'UPDATE_TASK_PROJECT_ESTIMATE_COST';
export const UPDATE_TASK_PROJECT_ESTIMATE_COST_START = 'UPDATE_TASK_PROJECT_ESTIMATE_COST_START';
export const UPDATE_TASK_PROJECT_ESTIMATE_COST_SUCCESS = 'UPDATE_TASK_PROJECT_ESTIMATE_COST_SUCCESS';
export const UPDATE_TASK_PROJECT_ESTIMATE_COST_ERROR = 'UPDATE_TASK_PROJECT_ESTIMATE_COST_ERROR';

export const CHANGE_TASKS_VISIBILITY = 'CHANGE_TASKS_VISIBILITY';
export const CHANGE_TASKS_VISIBILITY_START = 'CHANGE_TASKS_VISIBILITY_START';
export const CHANGE_TASKS_VISIBILITY_SUCCESS = 'CHANGE_TASKS_VISIBILITY_SUCCESS';
export const CHANGE_TASKS_VISIBILITY_ERROR = 'CHANGE_TASKS_VISIBILITY_ERROR';

export const CHANGE_TASKS_TAGS = 'CHANGE_TASKS_TAGS';
export const CHANGE_TASKS_TAGS_START = 'CHANGE_TASKS_TAGS_START';
export const CHANGE_TASKS_TAGS_SUCCESS = 'CHANGE_TASKS_TAGS_SUCCESS';
export const CHANGE_TASKS_TAGS_ERROR = 'CHANGE_TASKS_TAGS_ERROR';

export const CHANGE_TASKS_STATUS = 'CHANGE_TASKS_STATUS';
export const CHANGE_TASKS_STATUS_START = 'CHANGE_TASKS_STATUS_START';
export const CHANGE_TASKS_STATUS_SUCCESS = 'CHANGE_TASKS_STATUS_SUCCESS';
export const CHANGE_TASKS_STATUS_ERROR = 'CHANGE_TASKS_STATUS_ERROR';

export const DUPLICATE_TASKS = 'DUPLICATE_TASKS';
export const DUPLICATE_TASKS_START = 'DUPLICATE_TASKS_START';
export const DUPLICATE_TASKS_SUCCESS = 'DUPLICATE_TASKS_SUCCESS';
export const DUPLICATE_TASKS_ERROR = 'DUPLICATE_TASKS_ERROR';

export const REMOVE_USERS_FROM_TASK = 'REMOVE_USERS_FROM_TASK';
export const REMOVE_USERS_FROM_TASK_START = 'REMOVE_USERS_FROM_TASK_START';
export const REMOVE_USERS_FROM_TASK_SUCCESS = 'REMOVE_USERS_FROM_TASK_SUCCESS';
export const REMOVE_USERS_FROM_TASK_ERROR = 'REMOVE_USERS_FROM_TASK_ERROR';

export const ASSIGN_TASK_TO_USER = 'ASSIGN_TASK_TO_USER';
export const ASSIGN_TASK_TO_USER_START = 'ASSIGN_TASK_TO_USER_START';
export const ASSIGN_TASK_TO_USER_SUCCESS = 'ASSIGN_TASK_TO_USER_SUCCESS';
export const ASSIGN_TASK_TO_USER_ERROR = 'ASSIGN_TASK_TO_USER_ERROR';

export const RECALCULATE_TASK_ACCOUNT = 'RECALCULATE_TASK_ACCOUNT';
export const RECALCULATE_TASK_ACCOUNT_START = 'RECALCULATE_TASK_ACCOUNT_START';
export const RECALCULATE_TASK_ACCOUNT_SUCCESS = 'RECALCULATE_TASK_ACCOUNT_SUCCESS';
export const RECALCULATE_TASK_ACCOUNT_ERROR = 'RECALCULATE_TASK_ACCOUNT_ERROR';

export const DOWNLOAD_TASKS_XLS = 'DOWNLOAD_TASKS_XLS';
export const DOWNLOAD_TASKS_XLS_START = 'DOWNLOAD_TASKS_XLS_START';
export const DOWNLOAD_TASKS_XLS_SUCCESS = 'DOWNLOAD_TASKS_XLS_SUCCESS';
export const DOWNLOAD_TASKS_XLS_ERROR = 'DOWNLOAD_TASKS_XLS_ERROR';

export const TOGGLE_TASK_NOTIFICATIONS = 'TOGGLE_TASK_NOTIFICATIONS';
export const TOGGLE_TASK_NOTIFICATIONS_START = 'TOGGLE_TASK_NOTIFICATIONS_START';
export const TOGGLE_TASK_NOTIFICATIONS_SUCCESS = 'TOGGLE_TASK_NOTIFICATIONS_SUCCESS';
export const TOGGLE_TASK_NOTIFICATIONS_ERROR = 'TOGGLE_TASK_NOTIFICATIONS_ERROR';

export const CLEAR_TASK_FILTERS_START = 'CLEAR_TASK_FILTERS_START';
export const CLEAR_TASK_FILTERS_SUCCESS = 'CLEAR_TASK_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_TASKS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_TASKS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_TASKS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_TASKS_CHANNEL';
export const SOCKET_SUBSCRIBE_TO_TASK_VISIBILITY_STATUS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_TASK_VISIBILITY_STATUS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_TASK_VISIBILITY_STATUS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_TASK_VISIBILITY_STATUS_CHANNEL';

export const CLEAR_TASK_MODAL_VALUES = 'CLEAR_TASK_MODAL_VALUES';

export const CREATE_TASK_FORM = 'CREATE_TASK_FORM';
export const CREATE_MULTIPLE_TASKS_FORM = 'CREATE_MULTIPLE_TASKS_FORM';
export const BATCH_IMPORT_TASKS_FORM = 'BATCH_IMPORT_TASKS_FORM';
export const BATCH_TRACK_TIME_FORM = 'BATCH_TRACK_TIME_FORM';
export const BATCH_ACCEPT_RESULTS_FORM = 'BATCH_ACCEPT_RESULTS_FORM';
export const TASK_OVERVIEW_FORM = 'TASK_OVERVIEW_FORM';
export const TASKS_LIST_TABLE_FORM = 'TASKS_LIST_TABLE_FORM';
export const TASKS_UPDATE_ESTIMATE_DATE_FORM = 'TASKS_UPDATE_ESTIMATE_DATE_FORM';
export const TASKS_UPDATE_COST_FORM = 'TASKS_UPDATE_COST_FORM';
export const TASKS_ALLOCATE_FUNDS_FORM = 'TASKS_ALLOCATE_FUNDS_FORM';
export const SEND_TASKS_INVOICE_FORM = 'SEND_TASKS_INVOICE_FORM';
export const TASK_DETAILS_FORM = 'TASK_DETAILS_FORM';
export const SELECT_TASK_TEAM_MEMBER_FORM = 'SELECT_TASK_TEAM_MEMBER_FORM';
export const TASK_FILE_ATTACHMENTS_FORM = 'TASK_FILE_ATTACHMENTS_FORM';
export const TASK_SOURCE_FILES_FORM = 'TASK_SOURCE_FILES_FORM';
export const TASK_SELECTED_PRODUCTS_FORM = 'TASK_SELECTED_PRODUCTS_FORM';
export const TASK_SELECTED_PRODUCT_UPDATE_FORM = 'TASK_SELECTED_PRODUCT_UPDATE_FORM';
export const TASK_SELECTED_TEMPLATES_FORM = 'TASK_SELECTED_TEMPLATES_FORM';
export const TASK_SELECTED_TEMPLATE_UPDATE_FORM = 'TASK_SELECTED_TEMPLATE_UPDATE_FORM';
export const TASK_SELECTED_MATERIALS_FORM = 'TASK_SELECTED_MATERIALS_FORM';
export const TASK_SELECTED_MATERIAL_UPDATE_FORM = 'TASK_SELECTED_MATERIAL_UPDATE_FORM';
export const TASK_RESULTS_FORM = 'TASK_RESULTS_FORM';
export const TASK_CREATE_MESSAGE_FORM = 'TASK_CREATE_MESSAGE_FORM';
export const CHANGE_TASK_STATUS_FORM = 'CHANGE_TASK_STATUS_FORM';
export const CHANGE_TASK_TAGS_FORM = 'CHANGE_TASK_TAGS_FORM';
export const CHANGE_TASK_VISIBILITY_FORM = 'CHANGE_TASK_VISIBILITY_FORM';
export const INVITE_USERS_TO_TASKS_FORM = 'INVITE_USERS_TO_TASKS_FORM';
export const DUPLICATE_TASKS_FORM = 'DUPLICATE_TASKS_FORM';

export const TASK_DUPLICATE_SELECTED_SOURCE_FILES_FORM = 'TASK_DUPLICATE_SELECTED_SOURCE_FILES_FORM';
export const TASK_DUPLICATE_SELECTED_FILES_FORM = 'TASK_DUPLICATE_SELECTED_FILES_FORM';
export const TASK_DUPLICATE_SELECTED_IMAGES_FORM = 'TASK_DUPLICATE_SELECTED_IMAGES_FORM';
export const TASK_DUPLICATE_SELECTED_PRODUCTS_FORM = 'TASK_DUPLICATE_SELECTED_PRODUCTS_FORM';
export const TASK_DUPLICATE_SELECTED_TEMPLATES_FORM = 'TASK_DUPLICATE_SELECTED_TEMPLATES_FORM';
export const TASK_DUPLICATE_SELECTED_MATERIALS_FORM = 'TASK_DUPLICATE_SELECTED_MATERIALS_FORM';
export const TASK_DUPLICATE_SELECTED_DRAWINGS_FORM = 'TASK_DUPLICATE_SELECTED_DRAWINGS_FORM';
export const TASK_DUPLICATE_SELECTED_REFERENCES_FORM = 'TASK_DUPLICATE_SELECTED_REFERENCES_FORM';
export const TASK_DUPLICATE_SELECTED_TEXTURES_FORM = 'TASK_DUPLICATE_SELECTED_TEXTURES_FORM';

export const CHANGE_TASKS_PROJECT_ESTIMATE_COST_FORM = 'CHANGE_TASKS_PROJECT_ESTIMATE_COST_FORM';
export const SEND_INVOICE_TASK_FORM = 'SEND_INVOICE_TASK_FORM';
export const SUBMIT_TASK_RESULTS_FORM = 'SUBMIT_TASK_RESULTS_FORM';
export const SUBMIT_TASK_SOURCE_FILES_FORM = 'SUBMIT_TASK_SOURCE_FILES_FORM';
export const TRACK_TIME_TASK_FORM = 'TRACK_TIME_TASK_FORM';
export const CHECK_IN_TASK_FORM = 'CHECK_IN_TASK_FORM';
export const TASK_TODO_ITEMS_FORM = 'TASK_TODO_ITEMS_FORM';
export const SUBMIT_TASK_FILE_ATTACHMENTS_FORM = 'SUBMIT_TASK_FILE_ATTACHMENTS_FORM';

export const APPROVE_RESULTS_FORM = 'APPROVE_RESULTS_FORM';
export const REVISION_RESULTS_FORM = 'REVISION_RESULTS_FORM';
export const ACCEPT_TASK_RESULTS_FORM = 'ACCEPT_TASK_RESULTS_FORM';
export const APPROVE_TASK_RESULTS_FORM = 'APPROVE_TASK_RESULTS_FORM';
export const REVISION_TASK_RESULTS_FORM = 'REVISION_TASK_RESULTS_FORM';
export const UPDATE_TASK_ESTIMATE_DATE_FORM = 'UPDATE_TASK_ESTIMATE_DATE_FORM';

export const VALID_TASKS_TAB = 'VALID_TASKS_TAB';
export const NON_VALID_TASKS_TAB = 'NON_VALID_TASKS_TAB';
