import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cl from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import { CurrentUser } from '../../../../../types';

import { withCurrentUser } from '../../../../../main/common/HOCs/withCurrentUser';

import { LinkTo } from '../../../../../helpers/ts/LinkTo';
import { translate as t } from '../../../../../utils';

interface CozyUserMenuHocsProps extends RouteComponentProps {
  currentUser: CurrentUser;
}

interface CozyUserMenuProps {
  onLogout: () => void;
}

function CozyUserMenu({
  currentUser,
  onLogout,
  history
}: CozyUserMenuProps & CozyUserMenuHocsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
  const collapse = useCallback(() => isOpen && setIsOpen(false), [
    isOpen,
    setIsOpen
  ]);

  useEffect(() => {
    const unlisten = history.listen(collapse);
    return () => unlisten();
  }, [history, collapse]);

  return (
    <li
      className={cl('nav-item', 'dropdown', 'dropdown-user', {
        show: isOpen
      })}
    >
      <OutsideClickHandler onOutsideClick={collapse}>
        <LinkTo
          pureLink
          className="navbar-nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded={isOpen}
          onClick={toggle}
        >
          {currentUser.get('full_name') ? (
            <span>{currentUser.get('full_name')}</span>
          ) : (
            // Invisible text needed to prevent markup shifting
            <span className="invisible">U</span>
          )}
        </LinkTo>

        <div
          className={cl('dropdown-menu', 'dropdown-menu-right', {
            show: isOpen
          })}
        >
          <LinkTo href="profile" className="dropdown-item" icon="icon-cog5">
            {t('words.account_settings')}
          </LinkTo>
          <LinkTo
            pureLink
            className="dropdown-item"
            icon="icon-switch2"
            onClick={onLogout}
          >
            {t('words.logout')}
          </LinkTo>
        </div>
      </OutsideClickHandler>
    </li>
  );
}

export default withCurrentUser()(withRouter(CozyUserMenu));
