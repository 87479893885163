import React from 'react';
import Favicon from 'react-favicon';

import { COZY_FAVICON_URL, MAIN_FAVICON_URL } from '../../../config';

interface CustomFaviconProps {
  isCozy?: boolean;
}

function CustomFavicon({ isCozy = false }: CustomFaviconProps) {
  return <Favicon url={isCozy ? COZY_FAVICON_URL : MAIN_FAVICON_URL} />;
}

export default CustomFavicon;
