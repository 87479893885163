import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_CHANGES,
  FETCH_CHANGES_START,
  FETCH_CHANGES_SUCCESS,
  FETCH_CHANGES_ERROR
} from '../ChangeConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchChanges(params) {
  yield put(fetchItemsStart(FETCH_CHANGES_START, params));

  const fetchChangesAction = yield spawn(fetchItemsResource, 'changes', {
    success: FETCH_CHANGES_SUCCESS,
    error: FETCH_CHANGES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchChangesAction);
}

export default function* watchFetchChanges() {
  yield takeEvery(FETCH_CHANGES, fetchChanges);
}
