import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_SELECTED_PRODUCT,
  UPDATE_SELECTED_PRODUCT_START,
  UPDATE_SELECTED_PRODUCT_SUCCESS,
  UPDATE_SELECTED_PRODUCT_ERROR
} from '../SelectedProductConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateSelectedProduct({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_SELECTED_PRODUCT_START, id, options));

  return yield spawn(updateItemResource, 'selected_facilities', {
    success: UPDATE_SELECTED_PRODUCT_SUCCESS,
    error: UPDATE_SELECTED_PRODUCT_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateSelectedProduct() {
  yield takeEvery(UPDATE_SELECTED_PRODUCT, updateSelectedProduct);
}
