import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_TASK_FILE_ATTACHMENTS,
  SUBMIT_TASK_FILE_ATTACHMENTS_START,
  SUBMIT_TASK_FILE_ATTACHMENTS_SUCCESS,
  SUBMIT_TASK_FILE_ATTACHMENTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitTaskFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_TASK_FILE_ATTACHMENTS_START, id, options));

  const submitTaskFileAttachmentsAction = yield spawn(postItemResource, `discussions/${id}/submit_file_attachments`, {
    success: SUBMIT_TASK_FILE_ATTACHMENTS_SUCCESS,
    error: SUBMIT_TASK_FILE_ATTACHMENTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitTaskFileAttachmentsAction);
}

export default function* watchSubmitTaskFileAttachments() {
  yield takeEvery(SUBMIT_TASK_FILE_ATTACHMENTS, submitTaskFileAttachments);
}
