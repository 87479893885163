import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ATTACH_SELECTED_TEMPLATES,
  ATTACH_SELECTED_TEMPLATES_START,
  ATTACH_SELECTED_TEMPLATES_SUCCESS,
  ATTACH_SELECTED_TEMPLATES_ERROR
} from '../SelectedTemplateConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* attachSelectedTemplates({ values, options, cb }) {
  yield put(postItemsStart(ATTACH_SELECTED_TEMPLATES_START, options));

  return yield spawn(postItemsResource, 'selected_scenes/attach', {
    success: ATTACH_SELECTED_TEMPLATES_SUCCESS,
    error: ATTACH_SELECTED_TEMPLATES_ERROR
  }, { values, options, cb });
}

export default function* watchAttachSelectedTemplates() {
  yield takeEvery(ATTACH_SELECTED_TEMPLATES, attachSelectedTemplates);
}
