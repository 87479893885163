import {
  CLEAR_DOWNLOADS_FILTERS_START,
  CLEAR_DOWNLOADS_FILTERS_SUCCESS
} from '../DownloadConstants';

export function clearDownloadsFiltersStart(options = {}) {
  return {
    type: CLEAR_DOWNLOADS_FILTERS_START,
    options
  };
}

export function clearDownloadsFiltersSuccess(options = {}) {
  return {
    type: CLEAR_DOWNLOADS_FILTERS_SUCCESS,
    options
  };
}

export default function clearDownloadsFilters(options = {}) {
  return dispatch => {
    dispatch(clearDownloadsFiltersStart(options));
    setTimeout(() => dispatch(clearDownloadsFiltersSuccess(options)), 500);
  };
}
