import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CHECK_ORDER_PAYMENT,
  CHECK_ORDER_PAYMENT_START,
  CHECK_ORDER_PAYMENT_SUCCESS,
  CHECK_ORDER_PAYMENT_ERROR
} from '../BillConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* checkOrderPayment({ values, options, cb }) {
  yield put(postItemsStart(CHECK_ORDER_PAYMENT_START, options));

  const checkOrderPaymentAction = yield spawn(postItemsResource, 'bills/check_order', {
    success: CHECK_ORDER_PAYMENT_SUCCESS,
    error: CHECK_ORDER_PAYMENT_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(checkOrderPaymentAction);
}

export default function* watchCheckOrderPayment() {
  yield takeEvery(CHECK_ORDER_PAYMENT, checkOrderPayment);
}
