import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  CREATE_INTERNAL_TRANSFERS,
  CREATE_INTERNAL_TRANSFERS_START,
  CREATE_INTERNAL_TRANSFERS_SUCCESS,
  CREATE_INTERNAL_TRANSFERS_ERROR
} from '../PaymentConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* createInternalTransfers({ values, options, cb }) {
  yield put(postItemsStart(CREATE_INTERNAL_TRANSFERS_START, options));

  const createInternalTransfersAction = yield spawn(postItemsResource, 'transactions/internal_transfers', {
    success: CREATE_INTERNAL_TRANSFERS_SUCCESS,
    error: CREATE_INTERNAL_TRANSFERS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(createInternalTransfersAction);
}

export default function* watchCreateInternalTransfers() {
  yield takeEvery(CREATE_INTERNAL_TRANSFERS, createInternalTransfers);
}
