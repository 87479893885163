import { SOCKET_SUBSCRIBE_TO_MESSAGES_CHANNEL } from '../MessageConstants';

export default function subscribeToMessagesChannel(action, messageId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_SUBSCRIBE_TO_MESSAGES_CHANNEL,
    channel: 'Archi::Crm::DiscussionMessagesChannel',
    command: 'subscribe',
    action,
    params: { discussion_message_id: messageId, ...params }
  });
}
