import { SUBMIT_MESSAGE_FILE_ATTACHMENTS } from '../MessageConstants';

// submitMessageFileAttachments

export default function submitMessageFileAttachments(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: SUBMIT_MESSAGE_FILE_ATTACHMENTS,
    id,
    values,
    options,
    cb
  });
}
