import assign from 'lodash/assign';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';
import includes from 'lodash/includes';

export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];

export function empty(doc) {
  keys(doc).map(key => {
    delete doc[key];
    return null;
  });
}

export function getById(collection, id) {
  return find(collection, doc => {
    if (has(doc, 'id')) {
      return doc.id === id;
    }

    return null;
  });
}

export function updateById(collection, id, attrs) {
  const doc = getById(collection, id);

  if (doc) {
    assign(doc, attrs, { id: doc.id });
  }

  return doc;
}

export function removeById(collection, id) {
  return compact(collection.map(item => {
    if (item.id !== id) {
      return item;
    }

    return null;
  }));
}

export function removeByIds(collection, ids) {
  return compact(collection.map(item => {
    if (!includes(ids, item.id)) {
      return item;
    }

    return null;
  }));
}

export function replaceById(collection = [], id, attrsOrFunc) {
  if (!id || !includes(collection.map(i => i.id), id)) {
    return collection;
  }

  const index = findIndex(collection, { id });

  const result = [...collection];

  if (isFunction(attrsOrFunc)) {
    result.splice(index, 1, attrsOrFunc(get(result, index)));
  } else {
    result.splice(index, 1, attrsOrFunc);
  }

  return result;
}

export function updateWhere(collection, predicate, attrs) {
  const docs = filter(collection, predicate);

  docs.map(doc => assign(doc, attrs, { id: doc.id }));

  return docs;
}
