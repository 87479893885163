import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_REFILLS,
  FETCH_REFILLS_START,
  FETCH_REFILLS_SUCCESS,
  FETCH_REFILLS_ERROR
} from '../RefillConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchRefills(params) {
  yield put(fetchItemsStart(FETCH_REFILLS_START, params));

  const fetchRefillsAction = yield spawn(fetchItemsResource, 'refills', {
    success: FETCH_REFILLS_SUCCESS,
    error: FETCH_REFILLS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRefillsAction);
}

export default function* watchFetchRefills() {
  yield takeEvery(FETCH_REFILLS, fetchRefills);
}
