import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  uploadItemsStart, uploadItemsSuccess, uploadItemsError,
  uploadItemStart, uploadItemSuccess, uploadItemError,
  locationChange, clearModalValues
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_IMAGES_START: fetchItemsStart,
  FETCH_IMAGES_SUCCESS: fetchItemsSuccess,
  FETCH_IMAGES_ERROR: fetchItemsError,

  UPDATE_IMAGE_START: updateItemStart,
  UPDATE_IMAGE_SUCCESS: updateItemSuccess,
  UPDATE_IMAGE_ERROR: updateItemError,

  REMOVE_IMAGE_START: removeItemStart,
  REMOVE_IMAGE_SUCCESS: removeItemSuccess,
  REMOVE_IMAGE_ERROR: removeItemError,

  UPLOAD_IMAGES_START: uploadItemsStart,
  UPLOAD_IMAGES_SUCCESS: uploadItemsSuccess,
  UPLOAD_IMAGES_ERROR: uploadItemsError,

  UPLOAD_IMAGE_START: uploadItemStart,
  UPLOAD_IMAGE_SUCCESS: uploadItemSuccess,
  UPLOAD_IMAGE_ERROR: uploadItemError,

  CLEAR_IMAGE_MODAL_VALUES: clearModalValues
});
