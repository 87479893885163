import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_SELECTED_TEMPLATE,
  UPDATE_SELECTED_TEMPLATE_START,
  UPDATE_SELECTED_TEMPLATE_SUCCESS,
  UPDATE_SELECTED_TEMPLATE_ERROR
} from '../SelectedTemplateConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateSelectedTemplate({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_SELECTED_TEMPLATE_START, id, options));

  return yield spawn(updateItemResource, 'selected_scenes', {
    success: UPDATE_SELECTED_TEMPLATE_SUCCESS,
    error: UPDATE_SELECTED_TEMPLATE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateSelectedTemplate() {
  yield takeEvery(UPDATE_SELECTED_TEMPLATE, updateSelectedTemplate);
}
