import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function updateTaskSelectedTemplatesStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: replaceById(get(task, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function updateTaskSelectedTemplatesSuccess(state, { id, item, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: replaceById(get(task, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        ...item,
        error: null,
        loading: false
      }))
    }))
  }, options);
}

export function updateTaskSelectedTemplatesError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: replaceById(get(task, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
