export default {
  plural: 'Downloads',
  singular: 'Download',
  no: 'No downloads in queue',
  download_type: 'Download Type',
  archiving: 'Archiving files',
  archiving_complete: 'Archiving complete',
  statuses: {
    initialized: 'Initialized',
    completed: 'Completed',
    failed: 'Failed',
    processing: 'Processing'
  }
};
