import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHANGE_USERS_SELLER,
  CHANGE_USERS_SELLER_START,
  CHANGE_USERS_SELLER_SUCCESS,
  CHANGE_USERS_SELLER_ERROR
} from '../UserConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* changeUsersSeller({ values, options, cb }) {
  yield put(postItemsStart(CHANGE_USERS_SELLER_START, options));

  return yield spawn(
    postItemsResource,
    'users/update_seller',
    {
      success: CHANGE_USERS_SELLER_SUCCESS,
      error: CHANGE_USERS_SELLER_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchChangeUsersSeller() {
  yield takeEvery(CHANGE_USERS_SELLER, changeUsersSeller);
}
