import React from 'react';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { IsFetched, IsLoading } from '../../../../../../../../types';

import { BillType } from '../../../../../../../common/hooks/bills/types';

import {
  PureButton,
  PureButtonProps
} from '../../../../../../../../helpers/ts/PureButton';
import { ItemBillsModalSubmitButtons } from '../../components/ItemBillsModalSubmitButtons';

interface ItemBillsModalFooterProps {
  addClass?: string;
  bill: BillType | null;
  billFetched: IsFetched;
  billLoading: IsLoading;
  onClose: () => void;
  onPayment?: () => void;
  submitIcon?: string;
  type: string;
}

function ItemBillsModalFooter({
  addClass,
  bill,
  billFetched,
  billLoading,
  onClose,
  onPayment,
  submitIcon,
  type
}: ItemBillsModalFooterProps & PureButtonProps) {
  if (!bill) {
    return null;
  }

  return (
    <ModalFooter className="card-footer py-2 justify-content-between border-top">
      <div />
      <div className="btn-group">
        <PureButton
          addClass="mr-2"
          color="white"
          disabled={billLoading}
          i18nText="words.cancel"
          icon="times-circle"
          onClick={onClose}
          size="sm"
        />
        <ItemBillsModalSubmitButtons
          addClass={addClass}
          bill={bill}
          billFetched={billFetched}
          billLoading={billLoading}
          onClose={onClose}
          onPayment={onPayment}
          submitIcon={submitIcon}
          type={type}
        />
      </div>
    </ModalFooter>
  );
}

export default ItemBillsModalFooter;
