import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TRANSACTION_TAG,
  FETCH_TRANSACTION_TAG_START,
  FETCH_TRANSACTION_TAG_SUCCESS,
  FETCH_TRANSACTION_TAG_ERROR
} from '../TransactionTagConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTransactionTag({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TRANSACTION_TAG_START, id, options));

  const fetchTransactionTagAction = yield spawn(fetchItemResource, 'transaction_tags', {
    success: FETCH_TRANSACTION_TAG_SUCCESS,
    error: FETCH_TRANSACTION_TAG_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTransactionTagAction);
}

export default function* watchFetchTransactionTag() {
  yield takeEvery(FETCH_TRANSACTION_TAG, fetchTransactionTag);
}
