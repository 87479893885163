import { removeById, replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeItemStart(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemRemoveErrorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options, []), id, i => ({
      ...i,
      loading: true,
      error: null
    }))
  }, options);
}

export function removeItemSuccess(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemRemoveErrorMessage: null,
    item: {},
    items: removeById(currentStateValue(state, 'items', options, []), id)
  }, options);
}

export function removeItemError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemRemoveErrorMessage: errorMessage,
    items: replaceById(currentStateValue(state, 'items', options, []), id, i => ({
      ...i,
      loading: false,
      errorMessage: null
    }))
  }, options);
}
