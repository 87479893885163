import compact from 'lodash/compact';
import snakeCase from 'lodash/snakeCase';

import { fetchIndexData, fetchShowData, removeSubscriptions } from './index';

function provideLifecycle(options) {
  return options.fetchType ? {
    componentDidMount() {
      const { currentUser, id, filters, fetchOptions, params, serializer } = this.props;

      const action = options.indexAction || this.props.action || snakeCase(compact([
        options.scope,
        options.scope,
        options.fetchType,
        serializer
      ]).join('_'));

      if (options.fetchType !== 'ignore') {
        currentUser.hasPermissions(action);
        // throw new Error(`User #${currentUser.get('id')} has no permission ${this.action}`);
      }

      if (options.fetchType === 'show') {
        fetchShowData(this.props, options, id, {
          ...fetchOptions,
          params: params || this.props.showParams,
          namespace: options.namespace,
          serializer: options.serializer || serializer
        });
      }

      if (options.fetchType === 'index') {
        fetchIndexData(this.props, options, {
          filters: options.filters || filters,
          params: params || this.props.indexParams,
          pageSize: options.pageSize || this.props.pageSize,
          withoutPagination: options.withoutPagination || this.props.withoutPagination,
          withoutTotal: options.withoutTotal || this.props.withoutTotal,
          namespace: options.namespace,
          serializer: options.serializer || serializer,
          persistFilters: options.persistFilters
        });
      }
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      // if ((options.fetchType === 'index') && !options.filters && !isEqual(this.props.filters, nextProps.filters)) {
      //   handleFilterChange(this.props, options, nextProps.filters);
      // }

      if ((options.fetchType === 'index') && this.props.serializer !== nextProps.serializer) {
        fetchIndexData(this.props, options, {
          filters: options.filters || this.props.filters,
          params: this.props.params || this.props.indexParams,
          pageSize: options.pageSize || this.props.pageSize,
          withoutTotal: options.withoutTotal || this.props.withoutTotal,
          namespace: options.namespace,
          serializer: options.serializer || nextProps.serializer,
          persistFilters: options.persistFilters
        });
      }

      if (options.fetchType === 'show' && this.props.serializer !== nextProps.serializer) {
        fetchShowData(this.props, options, this.props.id, {
          ...this.props.fetchOptions,
          params: this.props.params || this.props.showParams,
          namespace: options.namespace,
          serializer: options.serializer || nextProps.serializer
        });
      }
    },
    componentWillUnmount() {
      if (options.fetchType !== 'ignore') {
        removeSubscriptions(this.props, options, options.fetchType === 'index' ? this.props[options.items] : [this.props[options.item]]);
      }
    }
  } : {};
}

export default provideLifecycle;
