export const FETCH_MATERIALS_SET = 'FETCH_MATERIALS_SET';
export const FETCH_MATERIALS_SET_START = 'FETCH_MATERIALS_SET_START';
export const FETCH_MATERIALS_SET_SUCCESS = 'FETCH_MATERIALS_SET_SUCCESS';
export const FETCH_MATERIALS_SET_ERROR = 'FETCH_MATERIALS_SET_ERROR';

export const DOWNLOAD_MATERIALS_SET = 'DOWNLOAD_MATERIALS_SET';
export const DOWNLOAD_MATERIALS_SET_START = 'DOWNLOAD_MATERIALS_SET_START';
export const DOWNLOAD_MATERIALS_SET_SUCCESS = 'DOWNLOAD_MATERIALS_SET_SUCCESS';
export const DOWNLOAD_MATERIALS_SET_ERROR = 'DOWNLOAD_MATERIALS_SET_ERROR';

export const EMPTY_MATERIALS_SET = 'EMPTY_MATERIALS_SET';
export const EMPTY_MATERIALS_SET_START = 'EMPTY_MATERIALS_SET_START';
export const EMPTY_MATERIALS_SET_SUCCESS = 'EMPTY_MATERIALS_SET_SUCCESS';
export const EMPTY_MATERIALS_SET_ERROR = 'EMPTY_MATERIALS_SET_ERROR';
