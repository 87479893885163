import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_EMAIL_TEMPLATES_START: fetchItemsStart,
  FETCH_EMAIL_TEMPLATES_SUCCESS: fetchItemsSuccess,
  FETCH_EMAIL_TEMPLATES_ERROR: fetchItemsError,

  CLEAR_EMAIL_TEMPLATE_FILTERS_START: clearItemsFiltersStart,
  CLEAR_EMAIL_TEMPLATE_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_EMAIL_TEMPLATE_START: fetchItemStart,
  FETCH_EMAIL_TEMPLATE_SUCCESS: fetchItemSuccess,
  FETCH_EMAIL_TEMPLATE_ERROR: fetchItemError,

  CREATE_EMAIL_TEMPLATE_START: createItemStart,
  CREATE_EMAIL_TEMPLATE_SUCCESS: createItemSuccess,
  CREATE_EMAIL_TEMPLATE_ERROR: createItemError,

  UPDATE_EMAIL_TEMPLATE_START: updateItemStart,
  UPDATE_EMAIL_TEMPLATE_SUCCESS: updateItemSuccess,
  UPDATE_EMAIL_TEMPLATE_ERROR: updateItemError,

  REMOVE_EMAIL_TEMPLATE_START: removeItemStart,
  REMOVE_EMAIL_TEMPLATE_SUCCESS: removeItemSuccess,
  REMOVE_EMAIL_TEMPLATE_ERROR: removeItemError
});
