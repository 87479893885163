import { updateState } from '../../../utils';

export function removeSourceFilesStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function removeSourceFilesSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    items: []
  }, options);
}

export function removeSourceFilesError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
