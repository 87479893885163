import { REMOVE_DOWNLOAD } from '../DownloadConstants';

// removeDownload

export default function removeDownload(id, options = {}, cb = null) {
  return dispatch => dispatch({
    type: REMOVE_DOWNLOAD,
    id,
    options,
    cb
  });
}
