import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_LINKED_ACCOUNT,
  FETCH_LINKED_ACCOUNT_START,
  FETCH_LINKED_ACCOUNT_SUCCESS,
  FETCH_LINKED_ACCOUNT_ERROR
} from '../LinkedAccountConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchLinkedAccount({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_LINKED_ACCOUNT_START, id, options));

  const fetchLinkedAccountAction = yield spawn(fetchItemResource, 'linked_users', {
    success: FETCH_LINKED_ACCOUNT_SUCCESS,
    error: FETCH_LINKED_ACCOUNT_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchLinkedAccountAction);
}

export default function* watchFetchLinkedAccount() {
  yield takeEvery(FETCH_LINKED_ACCOUNT, fetchLinkedAccount);
}
