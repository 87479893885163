import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TASK_MEMBER_INVITES,
  FETCH_TASK_MEMBER_INVITES_START,
  FETCH_TASK_MEMBER_INVITES_SUCCESS,
  FETCH_TASK_MEMBER_INVITES_ERROR
} from '../TaskMemberInviteConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTaskMemberInvites(params) {
  yield put(fetchItemsStart(FETCH_TASK_MEMBER_INVITES_START, params));

  const fetchTaskMemberInvitesAction = yield spawn(fetchItemsResource, 'task_member_invites', {
    success: FETCH_TASK_MEMBER_INVITES_SUCCESS,
    error: FETCH_TASK_MEMBER_INVITES_ERROR
  }, params);

  // yield take(FETCH_TASK_MEMBER_INVITES_START);
  // yield cancel(fetchTaskMemberInvitesAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTaskMemberInvitesAction);
}

export default function* watchFetchTaskMemberInvites() {
  yield takeEvery(FETCH_TASK_MEMBER_INVITES, fetchTaskMemberInvites);
}
