import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_PROJECT,
  TOGGLE_SELECTED_PROJECT_START,
  TOGGLE_SELECTED_PROJECT_SUCCESS,
  TOGGLE_SELECTED_PROJECT_ERROR
} from '../ProjectConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedProjectStart(id) {
  return {
    type: TOGGLE_SELECTED_PROJECT_START,
    id
  };
}

export function toggleSelectedProjectSuccess(id) {
  return {
    type: TOGGLE_SELECTED_PROJECT_SUCCESS,
    id
  };
}

export function toggleSelectedProjectError(id, error) {
  return {
    type: TOGGLE_SELECTED_PROJECT_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedProjectRequest(projectId) {
  return apiRequest.post(`tasks/${projectId}/toggle_selected`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedProjectResource({ projectId, cb }) {
  try {
    yield call(toggleSelectedProjectRequest, projectId);
    yield put(toggleSelectedProjectSuccess(projectId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedProjectError(projectId, error));
  }
}

function* toggleSelectedProject({ projectId, cb }) {
  yield put(toggleSelectedProjectStart(projectId));

  return yield spawn(toggleSelectedProjectResource, { projectId, cb });
}

export default function* watchToggleSelectedProject() {
  yield takeEvery(TOGGLE_SELECTED_PROJECT, toggleSelectedProject);
}
