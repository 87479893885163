import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_MESSAGE_SELECTED_TEMPLATES,
  SUBMIT_MESSAGE_SELECTED_TEMPLATES_START,
  SUBMIT_MESSAGE_SELECTED_TEMPLATES_SUCCESS,
  SUBMIT_MESSAGE_SELECTED_TEMPLATES_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitMessageSelectedTemplates({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_MESSAGE_SELECTED_TEMPLATES_START, id, options));

  const submitMessageSelectedTemplatesAction = yield spawn(postItemResource, `discussion_messages/${id}/submit_selected_scenes`, {
    success: SUBMIT_MESSAGE_SELECTED_TEMPLATES_SUCCESS,
    error: SUBMIT_MESSAGE_SELECTED_TEMPLATES_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitMessageSelectedTemplatesAction);
}

export default function* watchSubmitMessageSelectedTemplates() {
  yield takeEvery(SUBMIT_MESSAGE_SELECTED_TEMPLATES, submitMessageSelectedTemplates);
}
