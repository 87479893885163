import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_REVENUE_SHARE_ITEM,
  UPDATE_REVENUE_SHARE_ITEM_START,
  UPDATE_REVENUE_SHARE_ITEM_SUCCESS,
  UPDATE_REVENUE_SHARE_ITEM_ERROR
} from '../RevenueShareItemConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateRevenueShareItem({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_REVENUE_SHARE_ITEM_START, id, options));

  return yield spawn(updateItemResource, 'revenue_share_items', {
    success: UPDATE_REVENUE_SHARE_ITEM_SUCCESS,
    error: UPDATE_REVENUE_SHARE_ITEM_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateRevenueShareItem() {
  yield takeEvery(UPDATE_REVENUE_SHARE_ITEM, updateRevenueShareItem);
}
