import { POST_DATA_INITIALIZE } from '../../constants';

import { PostDataRequestAction } from '../../reducers/postDataRequestReducer';

export default function postDataInitialize<DataType>(): PostDataRequestAction<
  DataType
> {
  return {
    type: POST_DATA_INITIALIZE
  };
}
