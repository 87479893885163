import { useEffect, useRef } from 'react';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { IsFetched } from '../../../../../../types';

interface InitializeDownloadsBoxOpenedItem {
  progress: number;
}

interface InitializeDownloadsBoxOpenedOptions {
  downloads: InitializeDownloadsBoxOpenedItem[];
  downloadsFetched: IsFetched;
  setDownloadsBoxOpened: (opened: boolean) => void;
}

function useInitializeDownloadsBoxOpened({
  downloads,
  downloadsFetched,
  setDownloadsBoxOpened
}: InitializeDownloadsBoxOpenedOptions) {
  const boxStateInitialized = useRef(false);

  useEffect(() => {
    if (boxStateInitialized.current || !downloadsFetched) {
      return;
    }

    const initialProcessingDownloads = size(
      filter(downloads, download => download.progress < 100)
    );

    if (initialProcessingDownloads > 0) {
      setDownloadsBoxOpened(true);
    }

    boxStateInitialized.current = true;
  }, [downloads, downloadsFetched, setDownloadsBoxOpened]);
}

export default useInitializeDownloadsBoxOpened;
