export default {
  plural: 'File imports',
  plural_lower_case: 'file imports',
  singular: 'File import',
  singular_lower_case: 'file import',
  add: 'Add file import',
  select: 'Select file import',
  no: 'No file imports',
  upload: 'Upload new file import'
};
