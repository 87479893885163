import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_MESSAGE_FILE_ATTACHMENTS,
  SUBMIT_MESSAGE_FILE_ATTACHMENTS_START,
  SUBMIT_MESSAGE_FILE_ATTACHMENTS_SUCCESS,
  SUBMIT_MESSAGE_FILE_ATTACHMENTS_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitMessageFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_MESSAGE_FILE_ATTACHMENTS_START, id, options));

  const submitMessageFileAttachmentsAction = yield spawn(postItemResource, `discussion_messages/${id}/submit_file_attachments`, {
    success: SUBMIT_MESSAGE_FILE_ATTACHMENTS_SUCCESS,
    error: SUBMIT_MESSAGE_FILE_ATTACHMENTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitMessageFileAttachmentsAction);
}

export default function* watchSubmitMessageFileAttachments() {
  yield takeEvery(SUBMIT_MESSAGE_FILE_ATTACHMENTS, submitMessageFileAttachments);
}
