export default {
  plural: 'Client Profiles',
  updated: 'Client Profile Updated',
  tabs: {
    requirements: 'Requirements',
    favorite: 'Favorite',
    products: 'Products',
    templates: 'Templates',
    lifestyles: 'Lifestyles',
    files: 'Files',
    images: 'Images',
    my_library: 'My Library'
  }
};
