export const USD_UAH_RATE = 27;
export const USD_USD_RATE = 1;

export const localeCurrencies = {
  uk: 'models.currencies.uah',
  en: 'models.currencies.usd'
};

export const localeCurrencyRates = { uk: USD_UAH_RATE, en: USD_USD_RATE };

export const EMPTY_FUNCTION = () => null;

export const timeZones = [
  'UTC-12',
  'UTC-11',
  'UTC-10',
  'UTC-9:30',
  'UTC-9',
  'UTC-8',
  'UTC-7',
  'UTC-6',
  'UTC-5',
  'UTC-4',
  'UTC-3:30',
  'UTC-3',
  'UTC-2',
  'UTC-1',
  'UTC+0',
  'UTC+1',
  'UTC+2',
  'UTC+3',
  'UTC+3:30',
  'UTC+4',
  'UTC+4:30',
  'UTC+5',
  'UTC+5:30',
  'UTC+5:45',
  'UTC+6',
  'UTC+6:30',
  'UTC+7',
  'UTC+8',
  'UTC+8:45',
  'UTC+9',
  'UTC+9:30',
  'UTC+10',
  'UTC+10:30',
  'UTC+11',
  'UTC+12',
  'UTC+12:45',
  'UTC+13',
  'UTC+14'
];
