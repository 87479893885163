import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const IncomingMessageShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_incoming_messages" */ './pages/IncomingMessageShowPage/IncomingMessageShowPage'
    )
});
const IncomingMessagesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_incoming_messages" */ './pages/IncomingMessagesIndexPage/IncomingMessagesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/incoming_messages/:incomingMessageId(\d+)"
      component={IncomingMessageShowPage}
    />
    <Route
      exact
      path="/admin/incoming_messages"
      component={IncomingMessagesIndexPage}
    />
  </Fragment>
);
