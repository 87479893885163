import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_START,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_ERROR
} from '../PermissionConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchPermissions(params) {
  yield put(fetchItemsStart(FETCH_PERMISSIONS_START, params));

  const fetchPermissionsAction = yield spawn(fetchItemsResource, 'permissions', {
    success: FETCH_PERMISSIONS_SUCCESS,
    error: FETCH_PERMISSIONS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchPermissionsAction);
}

export default function* watchFetchPermissions() {
  yield takeEvery(FETCH_PERMISSIONS, fetchPermissions);
}
