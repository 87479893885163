import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  PAUSE_TASK,
  PAUSE_TASK_START,
  PAUSE_TASK_SUCCESS,
  PAUSE_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* pauseTask({ id, values, options, cb }) {
  yield put(postItemStart(PAUSE_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/pause`, {
    success: PAUSE_TASK_SUCCESS,
    error: PAUSE_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchPauseTask() {
  yield takeEvery(PAUSE_TASK, pauseTask);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   PAUSE_TASK,
//   PAUSE_TASK_START,
//   PAUSE_TASK_SUCCESS,
//   PAUSE_TASK_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function pauseTaskStart(id) {
//   return {
//     type: PAUSE_TASK_START,
//     id
//   };
// }
//
// export function pauseTaskSuccess(id) {
//   return {
//     type: PAUSE_TASK_SUCCESS,
//     id
//   };
// }
//
// export function pauseTaskError(id, error) {
//   return {
//     type: PAUSE_TASK_ERROR,
//     errorMessage: parseError(error),
//     id,
//     error
//   };
// }
//
// function pauseTaskRequest(taskId, task, options = {}) {
//   return apiRequest.post(`discussions/${taskId}/pause`, task, options);
// }
//
// function* pauseTaskResource({ taskId, task, options: { form, serializer }, cb }) {
//   try {
//     yield call(pauseTaskRequest, taskId, task, { serializer });
//     yield put(pauseTaskSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(pauseTaskError(taskId, error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* pauseTask({ taskId, options, cb }) {
//   yield put(pauseTaskStart(taskId));
//
//   return yield spawn(pauseTaskResource, { taskId, options, cb });
// }
//
// export default function* watchPauseTask() {
//   yield takeEvery(PAUSE_TASK, pauseTask);
// }
