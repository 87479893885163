import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  INVITE_USERS_TO_TASKS,
  INVITE_USERS_TO_TASKS_START,
  INVITE_USERS_TO_TASKS_SUCCESS,
  INVITE_USERS_TO_TASKS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* inviteUsersToTasksRequest({ values, options, cb }) {
  yield put(postItemsStart(INVITE_USERS_TO_TASKS_START, options));

  return yield spawn(postItemsResource, 'discussions/mass_invite_members', {
    success: INVITE_USERS_TO_TASKS_SUCCESS,
    error: INVITE_USERS_TO_TASKS_ERROR
  }, { values, options, cb });
}

export default function* watchInviteUsersToTasksRequest() {
  yield takeEvery(INVITE_USERS_TO_TASKS, inviteUsersToTasksRequest);
}
