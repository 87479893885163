import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_ROLE,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR
} from '../RoleConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createRole({ values, options, cb }) {
  yield put(createItemStart(CREATE_ROLE_START, options));

  return yield spawn(createItemResource, 'roles', {
    success: CREATE_ROLE_SUCCESS,
    error: CREATE_ROLE_ERROR
  }, { values, options, cb });
}

export default function* watchCreateRole() {
  yield takeEvery(CREATE_ROLE, createRole);
}
