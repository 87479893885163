import get from 'lodash/get';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import last from 'lodash/last';
import split from 'lodash/split';
import toUpper from 'lodash/toUpper';
import snakeCase from 'lodash/snakeCase';

export default ({ dispatch, getState }) => next => action => {
  // const socket$ = get(getState(), 'auth.socket$');
  const actionCable = get(getState(), 'auth.actionCable');

  // if (isObject(action) && has(action, 'channel') && socket$) {
  //   const { channel, command, params } = action;
  //
  //   socket$.send(JSON.stringify({
  //     command,
  //     identifier: JSON.stringify({ channel, action: action.action, ...params })
  //   }));
  // }

  if (isObject(action) && has(action, 'channel') && actionCable) {
    const { channel, command, params, onReceive } = action;

    const identifier = { channel, action: action.action, ...params };

    if (command === 'subscribe') {
      actionCable.subscriptions.create(identifier, {
        received: message => {
          const data = get(message, 'data');

          const channelName = last(split(channel, '::'))
            .replace(/Task/, 'Project')
            .replace(/Discussion/, 'Task')
            .replace(/DiscussionMessage/, 'Message');

          const type = toUpper(snakeCase(`${channelName}_${action.action}`));

          dispatch({
            identifier,
            type,
            data
          });

          onReceive && onReceive({ data });
        }
      });
    }

    if (command === 'unsubscribe') {
      const subscriptions = actionCable.subscriptions.findAll(JSON.stringify(identifier));

      subscriptions.map(subscription => subscription.unsubscribe());
    }
  }

  return next(action);
};
