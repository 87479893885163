import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';

import { AuthenticatedUser, User } from './models';
import { productionConsole } from '../../../utils/ts/productionConsole';

const anonymousUser = {
  id: 0,
  name: 'AnonymousUser',
  full_name: 'AnonymousUser',
  permissions: {},
  locale: 'en' as 'en',
  tasks_count: 0,
  google_tag_user_id: null
};

const defaultUser = new AuthenticatedUser(anonymousUser);

const authContext = createContext(defaultUser);
const authActionsContext = createContext({
  authUser: (updatedUser: User) => {
    productionConsole('error', 'authUser should be initialized', updatedUser);
  },
  logoutUser: () => {
    productionConsole('error', 'logoutUser should be initialized');
  }
});

interface ProvideAuthProps {
  children: ReactNode;
}

export function ProvideAuth({ children }: ProvideAuthProps) {
  const [user, setUser] = useState(defaultUser);

  const authActions = {
    authUser: useCallback(
      (updatedUser: User) => setUser(new AuthenticatedUser(updatedUser)),
      [setUser]
    ),
    logoutUser: useCallback(
      () => setUser(new AuthenticatedUser(anonymousUser)),
      [setUser]
    )
  };

  return (
    <authActionsContext.Provider value={authActions}>
      <authContext.Provider value={user}>{children}</authContext.Provider>
    </authActionsContext.Provider>
  );
}

export const useCurrentUser = () => {
  return useContext(authContext);
};

export const useAuth = () => {
  return useContext(authActionsContext);
};
