import get from 'lodash/get';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import upperFirst from 'lodash/upperFirst';

import { filterKeys } from './index';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../utils';

function provideProps(state, { fields, namespace, scope, singularScope }, additionalProps = {}) {
  return filterKeys([
    ...fields,
    ...(namespace ? fields.map(field => `${namespace}${upperFirst(field)}`) : [])
  ], {
    [scope]: get(state, `${scope}.items`, EMPTY_ARRAY),
    [`${scope}Meta`]: get(state, `${scope}.itemsMeta`, EMPTY_OBJECT),
    [`${scope}Loading`]: get(state, `${scope}.itemsLoading`, false),
    [`${scope}Fetched`]: get(state, `${scope}.itemsFetched`, false),
    [`${scope}SuccessMessage`]: get(state, `${scope}.itemsSuccessMessage`, null),
    [`${scope}ErrorMessage`]: get(state, `${scope}.itemsErrorMessage`, null),
    [`${scope}Uploading`]: get(state, `${scope}.itemsUploading`, false),
    [`${scope}UploadErrorMessage`]: get(state, `${scope}.itemsUploadErrorMessage`, null),
    [`${scope}ErrorMessage`]: get(state, `${scope}.itemsErrorMessage`, null),
    [`${scope}FiltersCleared`]: get(state, `${scope}.filtersCleared`, false),
    [singularScope]: get(state, `${scope}.item`, EMPTY_OBJECT),
    [`${singularScope}Loading`]: get(state, `${scope}.itemLoading`, false),
    [`${singularScope}Fetched`]: get(state, `${scope}.itemFetched`, false),
    [`${singularScope}ErrorMessage`]: get(state, `${scope}.itemErrorMessage`, null),
    [`${singularScope}CreateSuccessMessage`]: get(state, `${scope}.itemCreateSuccessMessage`, null),
    [`${singularScope}UpdateSuccessMessage`]: get(state, `${scope}.itemUpdateSuccessMessage`, null),
    [`${singularScope}RemoveSuccessMessage`]: get(state, `${scope}.itemRemoveSuccessMessage`, null),
    [`${singularScope}CreateErrorMessage`]: get(state, `${scope}.itemCreateErrorMessage`, null),
    [`${singularScope}DownloadErrorMessage`]: get(state, `${scope}.itemDownloadErrorMessage`, null),
    [`${singularScope}UpdateErrorMessage`]: get(state, `${scope}.itemUpdateErrorMessage`, null),
    [`${singularScope}RemoveErrorMessage`]: get(state, `${scope}.itemRemoveErrorMessage`, null),
    isLoading: get(state, `${scope}.isLoading`, false),
    errorMessage: get(state, `${scope}.errorMessage`, false),
    ...(namespace ? {
      [`${namespace}Items`]: get(state, `${scope}.${namespace}Items`, EMPTY_ARRAY),
      [`${namespace}ItemsMeta`]: get(state, `${scope}.${namespace}ItemsMeta`, EMPTY_OBJECT),
      [`${namespace}ItemsLoading`]: get(state, `${scope}.${namespace}ItemsLoading`, false),
      [`${namespace}ItemsFetched`]: get(state, `${scope}.${namespace}ItemsFetched`, false),
      [`${namespace}ItemsErrorMessage`]: get(state, `${scope}.${namespace}ItemsErrorMessage`, null),
      [`${namespace}ItemsUploading`]: get(state, `${scope}.${namespace}ItemsUploading`, false),
      [`${namespace}ItemsUploadErrorMessage`]: get(state, `${scope}.${namespace}ItemsUploadErrorMessage`, null),
      [`${namespace}FiltersCleared`]: get(state, `${scope}.${namespace}FiltersCleared`, false),
      [`${namespace}Item`]: get(state, `${scope}.${namespace}Item`, EMPTY_OBJECT),
      [`${namespace}ItemLoading`]: get(state, `${scope}.${namespace}ItemLoading`, false),
      [`${namespace}ItemFetched`]: get(state, `${scope}.${namespace}ItemFetched`, false),
      [`${namespace}ItemErrorMessage`]: get(state, `${scope}.${namespace}ItemErrorMessage`, null),
      [`${namespace}ItemCreateErrorMessage`]: get(state, `${scope}.${namespace}ItemCreateErrorMessage`, null),
      [`${namespace}ItemDownloadErrorMessage`]: get(state, `${scope}.${namespace}ItemDownloadErrorMessage`, null),
      [`${namespace}ItemUpdateErrorMessage`]: get(state, `${scope}.${namespace}ItemUpdateErrorMessage`, null),
      [`${namespace}ItemRemoveErrorMessage`]: get(state, `${scope}.${namespace}ItemRemoveErrorMessage`, null),
      [`${namespace}IsLoading`]: get(state, `${scope}.${namespace}IsLoading`, false),
      [`${namespace}ErrorMessage`]: get(state, `${scope}.${namespace}ErrorMessage`, null)
    } : {}),
    ...(additionalProps ? fromPairs(keys(additionalProps).map(key => [key, get(state, `${scope}.${additionalProps[key]}`)])) : {})
  });
}

export default provideProps;
