import accessDeniedErrors from './access_denied_errors';
import access from './access';
import accounts from './accounts';
import allocations from './allocations';
import attachments from './attachments';
import authControllerRequests from './auth_controller_requests';
import balances from './balances';
import batchOperations from './batch_operations';
import bills from './bills';
import billRefills from './bill_refills';
import emailTemplates from './email_templates';
import evaluations from './evaluations';
import categories from './categories';
import cells from './cells';
import clientFiles from './client_files';
import clients from './clients';
import contacts from './contacts';
import controllerRequests from './controller_requests';
import currencies from './currencies';
import downloads from './downloads';
import drawings from './drawings';
import evaluationSets from './evaluation_sets';
import facilities from './facilities';
import files from './files';
import file_imports from './file_imports';
import fileAttachments from './file_attachments';
import finance_tags from './finance_tags';
import groups from './groups';
import images from './images';
import imports from './imports';
import incomingMessages from './incoming_messages';
import lifestyles from './lifestyles';
import linkedAccounts from './linked_accounts';
import materials from './materials';
import maxFiles from './max_files';
import messages from './messages';
import notifications from './notifications';
import operations from './operations';
import pages from './pages';
import payments from './payments';
import payoneerRequests from './payoneer_requests';
import payouts from './payouts';
import pendingPayments from './pending_payments';
import permissions from './permissions';
import products from './products';
import projectProfiles from './project_profiles';
import projects from './projects';
import promoCodes from './promo_codes';
import references from './references';
import refills from './refills';
import reports from './reports';
import requests from './requests';
import results from './results';
import revenueShareItems from './revenue_share_items';
import roles from './roles';
import rooms from './rooms';
import s_3_buckets from './s3_buckets';
import s_3_objects from './s3_objects';
import s3_buckets from './s3_buckets';
import s3_objects from './s3_objects';
import scenes from './scenes';
import selectedFacilities from './selected_facilities';
import selectedMaterials from './selected_materials';
import selectedProducts from './selected_products';
import selectedScenes from './selected_scenes';
import selectedTemplates from './selected_templates';
import sections from './sections';
import sourceFiles from './source_files';
import sentMessages from './sent_messages';
import statuses from './statuses';
import tags from './tags';
import tasks from './tasks';
import teams from './teams';
import teams_users from './teams_users';
import templates from './templates';
import textureFiles from './texture_files';
import textures from './textures';
import todoItems from './todo_items';
import tracked_time from './tracked_time';
import transactions from './transactions';
import transactionTags from './transaction_tags';
import users from './users';
import userControllerRequests from './user_controller_requests';
import userDeletes from './user_deletes';
import userLogins from './user_logins';
import userRegistrations from './user_registrations';
import userResetPasswordRequests from './user_reset_password_requests';
import userWebsocketRequests from './user_websocket_requests';
import workers from './workers';

export default {
  access_denied_errors: accessDeniedErrors,
  access,
  accounts,
  allocations,
  attachments,
  auth_controller_requests: authControllerRequests,
  balances,
  batch_operations: batchOperations,
  bills,
  bill_refills: billRefills,
  categories,
  cells,
  client_files: clientFiles,
  clients,
  contacts,
  controller_requests: controllerRequests,
  currencies,
  downloads,
  drawings,
  email_templates: emailTemplates,
  evaluations,
  facilities,
  files,
  file_imports,
  file_attachments: fileAttachments,
  finance_tags,
  evaluation_sets: evaluationSets,
  groups,
  images,
  imports,
  incoming_messages: incomingMessages,
  lifestyles,
  linked_accounts: linkedAccounts,
  materials,
  max_files: maxFiles,
  messages,
  notifications,
  operations,
  pages,
  payments,
  payoneer_requests: payoneerRequests,
  payouts,
  pending_payments: pendingPayments,
  permissions,
  products,
  project_profiles: projectProfiles,
  projects,
  promo_codes: promoCodes,
  references,
  refills,
  reports,
  requests,
  results,
  revenue_share_items: revenueShareItems,
  roles,
  rooms,
  s_3_buckets,
  s_3_objects,
  s3_buckets,
  s3_objects,
  scenes,
  selected_facilities: selectedFacilities,
  selected_materials: selectedMaterials,
  selected_products: selectedProducts,
  selected_scenes: selectedScenes,
  selected_templates: selectedTemplates,
  sections,
  source_files: sourceFiles,
  sent_messages: sentMessages,
  statuses,
  tags,
  tasks,
  teams,
  teams_users,
  templates,
  texture_files: textureFiles,
  textures,
  todo_items: todoItems,
  tracked_time,
  transactions,
  transaction_tags: transactionTags,
  users,
  user_controller_requests: userControllerRequests,
  user_deletes: userDeletes,
  user_logins: userLogins,
  user_registrations: userRegistrations,
  user_reset_password_requests: userResetPasswordRequests,
  user_websocket_requests: userWebsocketRequests,
  workers
};
