import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_START,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_ERROR
} from '../PermissionConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updatePermission({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_PERMISSION_START, id, options));

  return yield spawn(updateItemResource, 'permissions', {
    success: UPDATE_PERMISSION_SUCCESS,
    error: UPDATE_PERMISSION_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdatePermission() {
  yield takeEvery(UPDATE_PERMISSION, updatePermission);
}
