export const FETCH_FINANCE_TAGS = 'FETCH_FINANCE_TAGS';
export const FETCH_FINANCE_TAGS_START = 'FETCH_FINANCE_TAGS_START';
export const FETCH_FINANCE_TAGS_SUCCESS = 'FETCH_FINANCE_TAGS_SUCCESS';
export const FETCH_FINANCE_TAGS_ERROR = 'FETCH_FINANCE_TAGS_ERROR';

export const FETCH_FINANCE_TAG = 'FETCH_FINANCE_TAG';
export const FETCH_FINANCE_TAG_START = 'FETCH_FINANCE_TAG_START';
export const FETCH_FINANCE_TAG_SUCCESS = 'FETCH_FINANCE_TAG_SUCCESS';
export const FETCH_FINANCE_TAG_ERROR = 'FETCH_FINANCE_TAG_ERROR';

export const CLEAR_FINANCE_TAGS_FILTERS_START = 'CLEAR_FINANCE_TAGS_FILTERS_START';
export const CLEAR_FINANCE_TAGS_FILTERS_SUCCESS = 'CLEAR_FINANCE_TAGS_FILTERS_SUCCESS';

export const CREATE_FINANCE_TAG = 'CREATE_FINANCE_TAG';
export const CREATE_FINANCE_TAG_START = 'CREATE_FINANCE_TAG_START';
export const CREATE_FINANCE_TAG_SUCCESS = 'CREATE_FINANCE_TAG_SUCCESS';
export const CREATE_FINANCE_TAG_ERROR = 'CREATE_FINANCE_TAG_ERROR';

export const UPDATE_FINANCE_TAG = 'UPDATE_FINANCE_TAG';
export const UPDATE_FINANCE_TAG_START = 'UPDATE_FINANCE_TAG_START';
export const UPDATE_FINANCE_TAG_SUCCESS = 'UPDATE_FINANCE_TAG_SUCCESS';
export const UPDATE_FINANCE_TAG_ERROR = 'UPDATE_FINANCE_TAG_ERROR';

export const REMOVE_FINANCE_TAG = 'REMOVE_FINANCE_TAG';
export const REMOVE_FINANCE_TAG_START = 'REMOVE_FINANCE_TAG_START';
export const REMOVE_FINANCE_TAG_SUCCESS = 'REMOVE_FINANCE_TAG_SUCCESS';
export const REMOVE_FINANCE_TAG_ERROR = 'REMOVE_FINANCE_TAG_ERROR';
