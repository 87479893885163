export const FETCH_TODO_ITEMS = 'FETCH_TODO_ITEMS';
export const FETCH_TODO_ITEMS_START = 'FETCH_TODO_ITEMS_START';
export const FETCH_TODO_ITEMS_SUCCESS = 'FETCH_TODO_ITEMS_SUCCESS';
export const FETCH_TODO_ITEMS_ERROR = 'FETCH_TODO_ITEMS_ERROR';

export const FETCH_TODO_ITEM = 'FETCH_TODO_ITEM';
export const FETCH_TODO_ITEM_START = 'FETCH_TODO_ITEM_START';
export const FETCH_TODO_ITEM_SUCCESS = 'FETCH_TODO_ITEM_SUCCESS';
export const FETCH_TODO_ITEM_ERROR = 'FETCH_TODO_ITEM_ERROR';

export const CREATE_TODO_ITEM = 'CREATE_TODO_ITEM';
export const CREATE_TODO_ITEM_START = 'CREATE_TODO_ITEM_START';
export const CREATE_TODO_ITEM_SUCCESS = 'CREATE_TODO_ITEM_SUCCESS';
export const CREATE_TODO_ITEM_ERROR = 'CREATE_TODO_ITEM_ERROR';

export const UPDATE_TODO_ITEM = 'UPDATE_TODO_ITEM';
export const UPDATE_TODO_ITEM_START = 'UPDATE_TODO_ITEM_START';
export const UPDATE_TODO_ITEM_SUCCESS = 'UPDATE_TODO_ITEM_SUCCESS';
export const UPDATE_TODO_ITEM_ERROR = 'UPDATE_TODO_ITEM_ERROR';

export const REMOVE_TODO_ITEM = 'REMOVE_TODO_ITEM';
export const REMOVE_TODO_ITEM_START = 'REMOVE_TODO_ITEM_START';
export const REMOVE_TODO_ITEM_SUCCESS = 'REMOVE_TODO_ITEM_SUCCESS';
export const REMOVE_TODO_ITEM_ERROR = 'REMOVE_TODO_ITEM_ERROR';

export const CLEAR_TODO_ITEM_FILTERS_START = 'CLEAR_TODO_ITEM_FILTERS_START';
export const CLEAR_TODO_ITEM_FILTERS_SUCCESS = 'CLEAR_TODO_ITEM_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_TODO_ITEMS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_TODO_ITEMS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_TODO_ITEMS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_TODO_ITEMS_CHANNEL';
