import some from 'lodash/some';
import trimStart from 'lodash/trimStart';

interface MenuLinkItem {
  type: 'link';
  href: string;
}

interface MenuDropdownItem {
  type: 'dropdown';
  links: Array<MenuLinkItem | MenuDropdownItem>;
}

type MixedMenuItem = MenuLinkItem | MenuDropdownItem;

interface IsMenuItemActiveOptions {
  pathname: string;
  item: MixedMenuItem;
}

function isMenuItemActive({
  pathname,
  item
}: IsMenuItemActiveOptions): boolean {
  if (item.type === 'link') {
    const { href } = item;
    const trimmedPathname = trimStart(pathname, '/');

    if (href === '') {
      return href === trimmedPathname;
    }

    const hrefRegexp = new RegExp(`^${href}`);

    return hrefRegexp.test(trimmedPathname);
  }

  return some(item.links || [], nestedItem =>
    isMenuItemActive({ pathname, item: nestedItem })
  );
}

export default isMenuItemActive;
