import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_BLOCKED_MATERIAL,
  TOGGLE_BLOCKED_MATERIAL_START,
  TOGGLE_BLOCKED_MATERIAL_SUCCESS,
  TOGGLE_BLOCKED_MATERIAL_ERROR
} from '../MaterialConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleBlockedMaterialStart(id) {
  return {
    type: TOGGLE_BLOCKED_MATERIAL_START,
    id
  };
}

export function toggleBlockedMaterialSuccess(id) {
  return {
    type: TOGGLE_BLOCKED_MATERIAL_SUCCESS,
    id
  };
}

export function toggleBlockedMaterialError(id, error) {
  return {
    type: TOGGLE_BLOCKED_MATERIAL_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleBlockedMaterialRequest(materialId) {
  return apiRequest.post(`materials/${materialId}/toggle_blocked`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleBlockedMaterialResource({ materialId, cb }) {
  try {
    yield call(toggleBlockedMaterialRequest, materialId);
    yield put(toggleBlockedMaterialSuccess(materialId));
    cb && cb();
  } catch (error) {
    yield put(toggleBlockedMaterialError(materialId, error));
  }
}

function* toggleBlockedMaterial({ materialId, cb }) {
  yield put(toggleBlockedMaterialStart(materialId));

  return yield spawn(toggleBlockedMaterialResource, { materialId, cb });
}

export default function* watchToggleBlockedMaterial() {
  yield takeEvery(TOGGLE_BLOCKED_MATERIAL, toggleBlockedMaterial);
}
