import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_DOWNLOAD,
  REMOVE_DOWNLOAD_START,
  REMOVE_DOWNLOAD_SUCCESS,
  REMOVE_DOWNLOAD_ERROR
} from '../DownloadConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeDownload({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_DOWNLOAD_START, id, options));

  return yield spawn(removeItemResource, 'downloads', {
    success: REMOVE_DOWNLOAD_SUCCESS,
    error: REMOVE_DOWNLOAD_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveDownload() {
  yield takeEvery(REMOVE_DOWNLOAD, removeDownload);
}
