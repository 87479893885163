import { FETCH_DOWNLOADS } from '../DownloadConstants';

// fetchDownloads

export default function fetchDownloads(params, cb) {
  return dispatch => dispatch({
    type: FETCH_DOWNLOADS,
    ...params,
    cb
  });
}
