import React, { MutableRefObject, useCallback } from 'react';

import { PureButton } from '../PureButton';

const buttonStyle = {
  position: 'fixed',
  right: 30,
  bottom: 20
};

interface ScrollToButtonProps {
  scrollToRef: MutableRefObject<HTMLDivElement>;
  style?: {};
}

function ScrollToButton({ scrollToRef, style }: ScrollToButtonProps) {
  const handleScrollToRef = useCallback(
    () =>
      scrollToRef.current &&
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      }),

    [scrollToRef]
  );

  return (
    <PureButton
      addClass="btn-icon btn-sm btn-light"
      icon="icon-arrow-up13"
      onClick={handleScrollToRef}
      style={style || buttonStyle}
    />
  );
}

export default ScrollToButton;
