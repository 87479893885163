import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PERMISSION_FROM_ROLE,
  REMOVE_PERMISSION_FROM_ROLE_START,
  REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSION_FROM_ROLE_ERROR
} from '../RoleConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removePermissionFromRole({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_PERMISSION_FROM_ROLE_START, id, options));

  return yield spawn(postItemResource, `roles/${id}/remove_permission`, {
    success: REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
    error: REMOVE_PERMISSION_FROM_ROLE_ERROR
  }, { id, values, options, cb });
}

export default function* watchRemovePermissionFromRole() {
  yield takeEvery(REMOVE_PERMISSION_FROM_ROLE, removePermissionFromRole);
}
