import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TASKS,
  CREATE_TASKS_START,
  CREATE_TASKS_SUCCESS,
  CREATE_TASKS_ERROR
} from '../TaskConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTasks({ values, options, cb }) {
  yield put(createItemStart(CREATE_TASKS_START, options));

  return yield spawn(
    createItemResource,
    'discussions/batch_create',
    {
      success: CREATE_TASKS_SUCCESS,
      error: CREATE_TASKS_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchCreateTasks() {
  yield takeEvery(CREATE_TASKS, createTasks);
}
