export default {
  download_excel: 'Download excel document with all evaluations',
  plural: 'Evaluations',
  set: 'Evaluations set',
  singular: 'Evaluation',
  no: 'No Evaluations',
  count: 'Evaluations Count',
  fields: {
    accuracy: 'Accuracy',
    communication: 'Communication',
    timing: 'Timing',
    quality: 'Quality',
    task_error: 'Task Error',
    project_error: 'Project Error',
    profile_error: 'Profile Error',
    brief_error: 'Brief Error',
    estimate_error: 'Estimate Error',
    style_error: 'Style Error',
    burning_tasks: 'Burning Tasks',
    respond_rate: 'Respond Rate',
    estimate_rate: 'Estimate Rate',
    quality_assurance: 'Quality Assurance',
    texture_lighting_camera: 'Texture/Lighting/Camera',
    composition_details_storytelling: 'Composition/Details/Storytelling'
  }
};
