import { useCallback, useReducer } from 'react';

import { PostRequestData } from '../../../../../types';
import {
  initialState,
  postDataRequestReducer,
  PostDataRequestReducerType
} from './reducers/postDataRequestReducer';
import { sendFinancePostDataRequest } from './utils/sendFinancePostDataRequest';
import { initializePostDataRequest } from './utils/initializePostDataRequest';

interface PostDataRequestOptions {
  url: string;
  initialData?: {};
}

export type PostDataHookOptions = Omit<
  PostDataRequestOptions,
  'url' | 'initialData'
>;

export function useFinancePostDataRequest<DataType extends PostRequestData>({
  url,
  initialData
}: PostDataRequestOptions) {
  const [{ data, dataErrorMessage, dataLoading }, dispatch] = useReducer<
    PostDataRequestReducerType<DataType>
  >(postDataRequestReducer, initialState);
  return {
    data,
    dataErrorMessage,
    dataLoading,
    postData: useCallback(
      (values: {}, params = {}) =>
        sendFinancePostDataRequest<DataType>(
          url,
          { ...params, values: { ...initialData, ...values } },
          dispatch
        ),
      [url, initialData, dispatch]
    ),
    initialize: useCallback(
      () => initializePostDataRequest<DataType>(dispatch),
      [dispatch]
    ),
    cancel: useCallback(() => console.log('cancel'), [])
  };
}
