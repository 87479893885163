import { DOWNLOAD_MESSAGE_FILE_ATTACHMENTS } from '../MessageConstants';

// downloadMessageFileAttachments

export default function downloadMessageFileAttachments(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: DOWNLOAD_MESSAGE_FILE_ATTACHMENTS,
    id,
    values,
    options,
    cb
  });
}
