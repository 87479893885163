import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

function AlertMessage({ rowClassName, color, children }) {
  return children ? (
    <div className={cl('row', rowClassName)}>
      <div className="col-md-12">
        <div className={cl('alert', `alert-${color}`)}>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

AlertMessage.propTypes = {
  color: PropTypes.string,
  rowClassName: PropTypes.string,
  children: PropTypes.node
};

AlertMessage.defaultProps = {
  color: 'danger',
  rowClassName: null,
  children: null
};

export default AlertMessage;
