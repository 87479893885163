import { getFromCookies, removeFromCookies, saveToCookies } from '../cookies';

const authKey = 'archi_auth_token';

let cachedAuthToken: string | null = null;

export function loadAuthToken() {
  return (
    cachedAuthToken ||
    getFromCookies(authKey) ||
    localStorage.getItem('auth_token')
  );
}

export function saveAuthToken(token: string) {
  localStorage.removeItem('auth_token');
  saveToCookies(authKey, token, {
    path: '/',
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14)
  });
  cachedAuthToken = token;
}

export function removeAuthToken() {
  localStorage.removeItem('auth_token');
  removeFromCookies(authKey);
  cachedAuthToken = null;
}
