import { SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_MESSAGES_CHANNEL } from '../MessageConstants';

export default function unsubscribeFromMessagesChannel(action, userId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_MESSAGES_CHANNEL,
    channel: 'Archi::Crm::DropdownMessagesChannel',
    command: 'unsubscribe',
    action,
    params
  });
}
