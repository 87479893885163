import { POST_DATA_ERROR } from '../../constants';

import { PostDataRequestAction } from '../../reducers/postDataRequestReducer';

function postDataError<DataType>(
  dataErrorMessage: string
): PostDataRequestAction<DataType> {
  return {
    type: POST_DATA_ERROR,
    dataErrorMessage
  };
}

export default postDataError;
