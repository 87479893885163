import { takeEvery } from 'redux-saga/effects';
import includes from 'lodash/includes';

import { DROPDOWN_DOWNLOADS_CHANNEL_CREATED } from '../DownloadConstants';

import { downloadFile } from '../../../utils/downloadFile';

const downloadIds = [];

function* dropdownDownloadsChannelCreated({ data }) {
  console.log(
    'dropdownDownloadsChannelCreated',
    { data },
    data.device_nano_id,
    data.progress,
    data.file
  );
  if (includes(downloadIds, data.id)) {
    console.log(
      `Prevented double download with id: ${data.id}. Used download ids: `,
      downloadIds
    );
    return;
  }
  if (window.downloadNanoId === data.device_nano_id) {
    console.log(
      `Starting download with id: ${data.id}. Used download ids at this moment: `,
      downloadIds
    );
    downloadIds.push(data.id);
    yield downloadFile(data.file, data.name, { withLink: true });
  }
}

export default function* watchDropdownDownloadsChannelCreated() {
  yield takeEvery(
    DROPDOWN_DOWNLOADS_CHANNEL_CREATED,
    dropdownDownloadsChannelCreated
  );
}
