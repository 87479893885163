export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const SOCKET_SUBSCRIBE_TO_CATEGORIES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_CATEGORIES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_CATEGORIES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_CATEGORIES_CHANNEL';
