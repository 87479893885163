import React from 'react';
import PropTypes from 'prop-types';
import breaks from 'remark-breaks';
import ReactMarkdown from 'react-markdown';

import replace from 'lodash/replace';

import { stripEmailText } from '../../utils/stripEmailText';

const plugins = [breaks];

function ArchiMarkdown({ id, className, escapeHtml, source, fromEmail }) {
  const msg = replace(source, /&gt;/g, '>');

  return (
    <ReactMarkdown
      id={id}
      className={className}
      escapeHtml={escapeHtml}
      source={fromEmail ? stripEmailText(msg) : msg}
      plugins={plugins}
    />
  );
}

ArchiMarkdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  escapeHtml: PropTypes.bool,
  fromEmail: PropTypes.bool,
  source: PropTypes.string
};

ArchiMarkdown.defaultProps = {
  id: null,
  className: null,
  escapeHtml: false,
  fromEmail: false,
  source: null
};

export default ArchiMarkdown;
