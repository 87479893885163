import { put, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import omit from 'lodash/omit';
import size from 'lodash/size';
import uniq from 'lodash/uniq';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function uploadItemsStart(type, files, options = {}) {
  return {
    type,
    files,
    options
  };
}

export function uploadItemStart(type, file, options = {}) {
  return {
    type,
    file,
    options
  };
}

export function uploadItemSuccess(type, item = {}, options = {}) {
  return {
    type,
    item,
    options
  };
}

export function uploadItemsSuccess(type, items = [], options = {}) {
  return {
    type,
    items,
    options
  };
}

export function uploadItemError(type, file, error, options = {}) {
  return {
    type,
    file,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function uploadItemsError(type, errors, options = {}) {
  return {
    type,
    errors,
    errorMessages: uniq(errors.map(error => parseError(error))),
    options
  };
}

export function uploadItemsRequest(path, values, options = {}) {
  return apiRequest.upload(path, values, omit(options, 'param')).then(({ data: { data: item } }) => ({ item }));
}

export function* uploadItemsResource(path, { start, eachStart, success, eachSuccess, error: errorConst, eachError, param }, { files, options, cb }) {
  yield put(uploadItemsStart(start, files, options));
  const items = [];
  const errors = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    try {
      yield put(uploadItemStart(eachStart, file, options));
      file.onStart && file.onStart();

      const { item } = yield call(uploadItemsRequest, path, file, {
        ...options,
        ...(param ? { param } : {}),
        onUploadProgress: progressEvent => file.onProgress && progressEvent.lengthComputable && file.onProgress(progressEvent.loaded / progressEvent.total * 100)
      });

      items.push(item);

      // file.onProgress && file.onProgress(50);

      file.onSuccess && file.onSuccess(item);

      yield put(uploadItemSuccess(eachSuccess, item, options));
    } catch (error) {
      errors.push(error);
      file.onError && file.onError(parseError(error));
      yield put(uploadItemError(eachError, file, error, options));
      yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
    }
  }

  if (size(errors) > 0) {
    yield put(uploadItemsError(errorConst, errors, options));
  } else {
    yield put(uploadItemsSuccess(success, items, options));
    cb && cb(items);
  }
}
