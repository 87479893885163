export const FETCH_PROJECT_PROFILES = 'FETCH_PROJECT_PROFILES';
export const FETCH_PROJECT_PROFILES_START = 'FETCH_PROJECT_PROFILES_START';
export const FETCH_PROJECT_PROFILES_SUCCESS = 'FETCH_PROJECT_PROFILES_SUCCESS';
export const FETCH_PROJECT_PROFILES_ERROR = 'FETCH_PROJECT_PROFILES_ERROR';

export const FETCH_PROJECT_PROFILE = 'FETCH_PROJECT_PROFILE';
export const FETCH_PROJECT_PROFILE_START = 'FETCH_PROJECT_PROFILE_START';
export const FETCH_PROJECT_PROFILE_SUCCESS = 'FETCH_PROJECT_PROFILE_SUCCESS';
export const FETCH_PROJECT_PROFILE_ERROR = 'FETCH_PROJECT_PROFILE_ERROR';

export const CREATE_PROJECT_PROFILE = 'CREATE_PROJECT_PROFILE';
export const CREATE_PROJECT_PROFILE_START = 'CREATE_PROJECT_PROFILE_START';
export const CREATE_PROJECT_PROFILE_SUCCESS = 'CREATE_PROJECT_PROFILE_SUCCESS';
export const CREATE_PROJECT_PROFILE_ERROR = 'CREATE_PROJECT_PROFILE_ERROR';

export const UPDATE_PROJECT_PROFILE = 'UPDATE_PROJECT_PROFILE';
export const UPDATE_PROJECT_PROFILE_START = 'UPDATE_PROJECT_PROFILE_START';
export const UPDATE_PROJECT_PROFILE_SUCCESS = 'UPDATE_PROJECT_PROFILE_SUCCESS';
export const UPDATE_PROJECT_PROFILE_ERROR = 'UPDATE_PROJECT_PROFILE_ERROR';

export const REMOVE_PROJECT_PROFILE = 'REMOVE_PROJECT_PROFILE';
export const REMOVE_PROJECT_PROFILE_START = 'REMOVE_PROJECT_PROFILE_START';
export const REMOVE_PROJECT_PROFILE_SUCCESS = 'REMOVE_PROJECT_PROFILE_SUCCESS';
export const REMOVE_PROJECT_PROFILE_ERROR = 'REMOVE_PROJECT_PROFILE_ERROR';

export const DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS = 'DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS';
export const DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_START = 'DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_START';
export const DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS = 'DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS';
export const DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR = 'DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR';

export const DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_START = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_START';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR';

export const DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_START = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_START';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_SUCCESS = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_SUCCESS';
export const DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_ERROR = 'DOWNLOAD_PROJECT_PROFILE_SELECTED_TEMPLATES_ERROR';

export const SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS = 'SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS';
export const SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_START = 'SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_START';
export const SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS = 'SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS';
export const SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR = 'SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR';

export const SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS = 'SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS';
export const SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_START = 'SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_START';
export const SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS = 'SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS';
export const SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR = 'SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR';

export const SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES = 'SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES';
export const SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_START = 'SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_START';
export const SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_SUCCESS = 'SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_SUCCESS';
export const SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_ERROR = 'SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_ERROR';

export const CLEAR_PROJECT_PROFILE_FILTERS_START = 'CLEAR_PROJECT_PROFILE_FILTERS_START';
export const CLEAR_PROJECT_PROFILE_FILTERS_SUCCESS = 'CLEAR_PROJECT_PROFILE_FILTERS_SUCCESS';

export const CLEAR_PROJECT_PROFILE_MODAL_VALUES = 'CLEAR_PROJECT_PROFILE_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_PROJECT_PROFILES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_PROJECT_PROFILES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_PROJECT_PROFILES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_PROJECT_PROFILES_CHANNEL';
