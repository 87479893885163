import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_OPERATIONS_START: fetchItemsStart,
  FETCH_OPERATIONS_SUCCESS: fetchItemsSuccess,
  FETCH_OPERATIONS_ERROR: fetchItemsError
});
