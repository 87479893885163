import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_FILE_ATTACHMENT,
  UPDATE_FILE_ATTACHMENT_START,
  UPDATE_FILE_ATTACHMENT_SUCCESS,
  UPDATE_FILE_ATTACHMENT_ERROR
} from '../FileAttachmentConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateFileAttachment({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_FILE_ATTACHMENT_START, id, options));

  return yield spawn(updateItemResource, 'file_attachments', {
    success: UPDATE_FILE_ATTACHMENT_SUCCESS,
    error: UPDATE_FILE_ATTACHMENT_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateFileAttachment() {
  yield takeEvery(UPDATE_FILE_ATTACHMENT, updateFileAttachment);
}
