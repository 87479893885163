import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_FILE_ATTACHMENTS_START,
  FETCH_FILE_ATTACHMENTS,
  FETCH_FILE_ATTACHMENTS_SUCCESS,
  FETCH_FILE_ATTACHMENTS_ERROR
} from '../FileAttachmentConstants';

import { fetchItemsResource, fetchItemsStart } from '../../common/sagas';

function* fetchFileAttachments(params) {
  yield put(fetchItemsStart(FETCH_FILE_ATTACHMENTS_START, params));

  const fetchFileAttachmentsAction = yield spawn(fetchItemsResource, 'file_attachments', {
    success: FETCH_FILE_ATTACHMENTS_SUCCESS,
    error: FETCH_FILE_ATTACHMENTS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchFileAttachmentsAction);
}

export default function* watchFetchFileAttachments() {
  yield takeEvery(FETCH_FILE_ATTACHMENTS, fetchFileAttachments);
}
