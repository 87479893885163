export const FETCH_PROJECT_NOTIFICATIONS = 'FETCH_PROJECT_NOTIFICATIONS';
export const FETCH_PROJECT_NOTIFICATIONS_START = 'FETCH_PROJECT_NOTIFICATIONS_START';
export const FETCH_PROJECT_NOTIFICATIONS_SUCCESS = 'FETCH_PROJECT_NOTIFICATIONS_SUCCESS';
export const FETCH_PROJECT_NOTIFICATIONS_ERROR = 'FETCH_PROJECT_NOTIFICATIONS_ERROR';

export const CLEAR_PROJECT_NOTIFICATIONS_FILTERS_START = 'CLEAR_PROJECT_NOTIFICATIONS_FILTERS_START';
export const CLEAR_PROJECT_NOTIFICATIONS_FILTERS_SUCCESS = 'CLEAR_PROJECT_NOTIFICATIONS_FILTERS_SUCCESS';

export const FETCH_PROJECT_NOTIFICATION = 'FETCH_PROJECT_NOTIFICATION';
export const FETCH_PROJECT_NOTIFICATION_START = 'FETCH_PROJECT_NOTIFICATION_START';
export const FETCH_PROJECT_NOTIFICATION_SUCCESS = 'FETCH_PROJECT_NOTIFICATION_SUCCESS';
export const FETCH_PROJECT_NOTIFICATION_ERROR = 'FETCH_PROJECT_NOTIFICATION_ERROR';

export const CREATE_PROJECT_NOTIFICATION = 'CREATE_PROJECT_NOTIFICATION';
export const CREATE_PROJECT_NOTIFICATION_START = 'CREATE_PROJECT_NOTIFICATION_START';
export const CREATE_PROJECT_NOTIFICATION_SUCCESS = 'CREATE_PROJECT_NOTIFICATION_SUCCESS';
export const CREATE_PROJECT_NOTIFICATION_ERROR = 'CREATE_PROJECT_NOTIFICATION_ERROR';

export const UPDATE_PROJECT_NOTIFICATION = 'UPDATE_PROJECT_NOTIFICATION';
export const UPDATE_PROJECT_NOTIFICATION_START = 'UPDATE_PROJECT_NOTIFICATION_START';
export const UPDATE_PROJECT_NOTIFICATION_SUCCESS = 'UPDATE_PROJECT_NOTIFICATION_SUCCESS';
export const UPDATE_PROJECT_NOTIFICATION_ERROR = 'UPDATE_PROJECT_NOTIFICATION_ERROR';

export const REMOVE_PROJECT_NOTIFICATION = 'REMOVE_PROJECT_NOTIFICATION';
export const REMOVE_PROJECT_NOTIFICATION_START = 'REMOVE_PROJECT_NOTIFICATION_START';
export const REMOVE_PROJECT_NOTIFICATION_SUCCESS = 'REMOVE_PROJECT_NOTIFICATION_SUCCESS';
export const REMOVE_PROJECT_NOTIFICATION_ERROR = 'REMOVE_PROJECT_NOTIFICATION_ERROR';
