import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import defaults from '../../../locales/en';

import { flattenMessages } from '../../../utils/ts/flattenMessages';

const messages = flattenMessages(defaults.en);

interface TranslateProps {
  id: string;
}

function Translate({ id }: TranslateProps) {
  if (id) {
    const defaultMessage = messages && messages[id];
    return (
      <FormattedMessage
        id={id}
        defaultMessage={
          typeof defaultMessage === 'string' ? defaultMessage : id
        }
        tagName={Fragment}
      />
    );
  }

  return null;
}

export default Translate;
