import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_LINKED_ACCOUNTS,
  FETCH_LINKED_ACCOUNTS_START,
  FETCH_LINKED_ACCOUNTS_SUCCESS,
  FETCH_LINKED_ACCOUNTS_ERROR
} from '../LinkedAccountConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchLinkedAccounts(params) {
  yield put(fetchItemsStart(FETCH_LINKED_ACCOUNTS_START, params));

  const fetchLinkedAccountsAction = yield spawn(fetchItemsResource, 'linked_users', {
    success: FETCH_LINKED_ACCOUNTS_SUCCESS,
    error: FETCH_LINKED_ACCOUNTS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchLinkedAccountsAction);
}

export default function* watchFetchLinkedAccounts() {
  yield takeEvery(FETCH_LINKED_ACCOUNTS, fetchLinkedAccounts);
}
