import get from 'lodash/get';

import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function reportsChannelUpdated(state, { data, options = {} }) {
  const optionsWithNamespace = { ...options, namespace: data.report_type };

  const currentItem = currentStateValue(state, 'item', optionsWithNamespace);
  const currentItems = currentStateValue(state, 'items', optionsWithNamespace);

  return updateState(state, {
    item: get(state, 'item.id') === data.id ? { ...currentItem, ...data } : currentItem,
    items: replaceById(currentItems, data.id, i => ({ ...i, ...data }))
  }, optionsWithNamespace);
}
