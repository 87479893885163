import React from 'react';

import {
  fetchContacts as fetchContactsAction,
  fetchContact as fetchContactAction,
  clearContactsFilters as clearContactsFiltersAction,
  clearContactModalValues as clearContactModalValuesAction,
  createContact as createContactAction
} from '../../../contacts/ContactActions';

import { baseHOC } from '../utils';

const withContacts = (options = {}) => WrappedComponent => {
  function ContactsHoc(props) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return baseHOC(options, {
    fetchContacts: fetchContactsAction,
    fetchContact: fetchContactAction,
    clearContactsFilters: clearContactsFiltersAction,
    clearContactModalValues: clearContactModalValuesAction,
    createContact: createContactAction
  })(ContactsHoc);
};

export default withContacts;
