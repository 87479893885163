import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_ALLOCATION,
  FETCH_ALLOCATION_START,
  FETCH_ALLOCATION_SUCCESS,
  FETCH_ALLOCATION_ERROR
} from '../AllocationConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchAllocation({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_ALLOCATION_START, id, options));

  const fetchAllocationAction = yield spawn(fetchItemResource, 'discussion_funds_allocations', {
    success: FETCH_ALLOCATION_SUCCESS,
    error: FETCH_ALLOCATION_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchAllocationAction);
}

export default function* watchFetchAllocation() {
  yield takeEvery(FETCH_ALLOCATION, fetchAllocation);
}
