import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const UserProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfilePage/UserProfilePage') });
const UserShowPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowPage/UserShowPage') });
const UserProfileFinancePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-finance" */'./pages/UserProfileFinancePage/UserProfileFinancePage') });
const UserProfileProjectProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-project-profile" */'./pages/UserProfileProjectProfilePage/UserProfileProjectProfilePage') });
const UserProfileSettingsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-profile-settings" */'./pages/UserProfileSettingsPage/UserProfileSettingsPage') });
const UserProfileAcceptInvitePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-accept-invite" */'./pages/UserProfileAcceptInvitePage/UserProfileAcceptInvitePage') });
const UserProfileAcceptRequestPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-accept-request" */'./pages/UserProfileAcceptRequestPage/UserProfileAcceptRequestPage') });
const UserShowProjectProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-project-profile" */'./pages/UserShowProjectProfilePage/UserShowProjectProfilePage') });
const UserShowFinancePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-finance" */'./pages/UserShowFinancePage/UserShowFinancePage') });
const UserShowSettingsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-settings" */'./pages/UserShowSettingsPage/UserShowSettingsPage') });
const UserProfileDebtPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-debt" */'./pages/UserProfileDebtPage/UserProfileDebtPage') });
const UserProfileBillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-bills" */'./pages/UserProfileBillsPage/UserProfileBillsPage') });
const UserProfileRefillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-refills" */'./pages/UserProfileRefillsPage/UserProfileRefillsPage') });
const UserProfilePendingPaymentsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-pending-payments" */'./pages/UserProfilePendingPaymentsPage/UserProfilePendingPaymentsPage') });
const UserProfileRevenueSharePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-revenue-share" */'./pages/UserProfileRevenueSharePage/UserProfileRevenueSharePage') });
const UserProfileAccessPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-access" */'./pages/UserProfileAccessPage/UserProfileAccessPage') });
const UserShowDebtPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-debt" */'./pages/UserShowDebtPage/UserShowDebtPage') });
const UserShowBillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-bills" */'./pages/UserShowBillsPage/UserShowBillsPage') });
const UserShowRefillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-refills" */'./pages/UserShowRefillsPage/UserShowRefillsPage') });
const UserShowPendingPaymentsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-pending-payments" */'./pages/UserShowPendingPaymentsPage/UserShowPendingPaymentsPage') });
const UserProfileRecordsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-records" */'./pages/UserProfileRecordsPage/UserProfileRecordsPage') });
const UserShowRecordsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-records" */'./pages/UserShowRecordsPage/UserShowRecordsPage') });
const UserShowRevenueSharePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-revenue-share" */'./pages/UserShowRevenueSharePage/UserShowRevenueSharePage') });
const UserShowAccessPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-access" */'./pages/UserShowAccessPage/UserShowAccessPage') });
const AccountsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-accounts" */'./pages/AccountsIndexPage/AccountsIndexPage') });
const WorkersIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-workers" */'./pages/WorkersIndexPage/WorkersIndexPage') });
const ClientsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-clients" */'./pages/ClientsIndexPage/ClientsIndexPage') });

const UserDocumentsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-documents" */ '../ts/users/pages/UserDocumentsIndexPage/UserDocumentsIndexPage') });
const UserProfileMyTasksPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-my-tasks" */ '../ts/users/pages/UserProfileMyTasksPage/UserProfileMyTasksPage') });
const UserShowMyTasksPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-my-tasks" */ '../ts/users/pages/UserShowMyTasksPage/UserShowMyTasksPage') });
const UserProfileStatisticPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile-statistic" */ '../ts/users/pages/UserProfileStatisticPage/UserProfileStatisticPage') });
const UserShowStatisticPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users-statistic" */ '../ts/users/pages/UserShowStatisticPage/UserShowStatisticPage') });


export default (
  <Fragment>
    <Route exact path="/profile/records" component={UserProfileRecordsPage} />
    <Route exact path="/profile/pending-payments/:tab(bad-debt|debt|underpayment)?" component={UserProfilePendingPaymentsPage} />
    <Route exact path="/profile/debt" component={UserProfileDebtPage} />
    <Route exact path="/profile/bills" component={UserProfileBillsPage} />
    <Route exact path="/profile/refills" component={UserProfileRefillsPage} />
    <Route exact path="/profile/revenue-share/:tab(worker)?" component={UserProfileRevenueSharePage} />
    <Route
      exact
      path="/profile/(project-profile|client-profile)/:tab(products|templates|lifestyles|files|requirements|library|favorite|description)?"
      render={({ location: { pathname } }) => (
        <Redirect
          to={pathname.replace(
            /project-profile|client-profile/,
            'requirements'
          )}
        />
      )}
    />
    <Route exact path="/profile/requirements/:tab(library|favorite|products|templates|lifestyles|files|requirements|description)?" component={UserProfileProjectProfilePage} />
    <Route exact path="/profile/my-tasks/:tab(tasks|projects|results)?" component={UserProfileMyTasksPage} />
    <Route exact path="/profile/finance/:tab(debit|credit|bonus|project|task|amount|prepaid)?" component={UserProfileFinancePage} />
    <Route
      exact
      path="/profile/settings/:tab(notifications|email|password|system|block|delete)?/:subTab(tasks|projects)?"
      component={UserProfileSettingsPage}
    />
    <Route exact path="/profile/statistic/:tab(time-by-date|timeline|results)?" component={UserProfileStatisticPage} />
    <Route exact path="/profile/access/:tab(received|team)?" component={UserProfileAccessPage} />
    <Route exact path="/profile/:linkedAccountInviteId/accept-invite" component={UserProfileAcceptInvitePage} />
    <Route exact path="/profile/:linkedAccountRequestId/accept-request" component={UserProfileAcceptRequestPage} />
    <Route exact path="/profile/:tab(description|fields|payoneer|airtable|documents)?" component={UserProfilePage} />
    <Route exact path="/users/:userId(\d+)/records" component={UserShowRecordsPage} />
    <Route exact path="/users/:userId(\d+)/pending-payments/:tab(bad-debt|debt|underpayment)?" component={UserShowPendingPaymentsPage} />
    <Route exact path="/users/:userId(\d+)/debt" component={UserShowDebtPage} />
    <Route exact path="/users/:userId(\d+)/bills" component={UserShowBillsPage} />
    <Route exact path="/users/:userId(\d+)/refills" component={UserShowRefillsPage} />
    <Route exact path="/users/:userId(\d+)/revenue-share/:tab(worker)?" component={UserShowRevenueSharePage} />
    <Route
      exact
      path="/users/:userId(\d+)/(project-profile|client-profile)/:tab(products|templates|lifestyles|files|requirements|library|favorite|description)?"
      render={({ location: { pathname } }) => (
        <Redirect
          to={pathname.replace(
            /project-profile|client-profile/,
            'requirements'
          )}
        />
      )}
    />
    <Route exact path="/users/:userId(\d+)/requirements/:tab(library|favorite|products|templates|lifestyles|files|requirements|description)?" component={UserShowProjectProfilePage} />
    <Route exact path="/users/:userId(\d+)/my-tasks/:tab(tasks|projects|results)?" component={UserShowMyTasksPage} />
    <Route exact path="/users/:userId(\d+)/finance/:tab(debit|credit|bonus|project|task|amount|prepaid)?" component={UserShowFinancePage} />
    <Route
      exact
      path="/users/:userId(\d+)/settings/:tab(notifications|email|password|system|block|delete)?/:subTab(tasks|projects)?"
      component={UserShowSettingsPage}
    />
    <Route exact path="/users/:userId(\d+)/statistic/:tab(time-by-date|timeline|results)?" component={UserShowStatisticPage} />
    <Route exact path="/users/:userId(\d+)/access/:tab(received|team)?" component={UserShowAccessPage} />
    <Route exact path="/users/:userId(\d+)/:tab(description|fields|payoneer|airtable|documents)?" component={UserShowPage} />
    <Route exact path="/accounts" component={AccountsIndexPage} />
    <Route exact path="/workers" component={WorkersIndexPage} />
    <Route exact path="/workers/register">
      <Redirect to="/profile/documents" />
    </Route>
    <Route exact path="/clients" component={ClientsIndexPage} />
    <Route exact path="/user-documents" component={UserDocumentsIndexPage} />
  </Fragment>
);
