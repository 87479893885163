import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_REVENUE_SHARE_ITEMS,
  CREATE_REVENUE_SHARE_ITEMS_START,
  CREATE_REVENUE_SHARE_ITEMS_SUCCESS,
  CREATE_REVENUE_SHARE_ITEMS_ERROR
} from '../RevenueShareItemConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createRevenueShareItems({ values, options, cb }) {
  yield put(createItemStart(CREATE_REVENUE_SHARE_ITEMS_START, options));

  return yield spawn(
    createItemResource,
    'revenue_share_items/batch_create',
    {
      success: CREATE_REVENUE_SHARE_ITEMS_SUCCESS,
      error: CREATE_REVENUE_SHARE_ITEMS_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchCreateRevenueShareItems() {
  yield takeEvery(CREATE_REVENUE_SHARE_ITEMS, createRevenueShareItems);
}
