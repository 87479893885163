import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPDATE_TASKS_AMOUNTS,
  UPDATE_TASKS_AMOUNTS_START,
  UPDATE_TASKS_AMOUNTS_SUCCESS,
  UPDATE_TASKS_AMOUNTS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* updateTasksAmounts({ values, options, cb }) {
  yield put(postItemsStart(UPDATE_TASKS_AMOUNTS_START, options));

  const updateTasksAmountsAction = yield spawn(postItemsResource, 'discussions/update_amounts', {
    success: UPDATE_TASKS_AMOUNTS_SUCCESS,
    error: UPDATE_TASKS_AMOUNTS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(updateTasksAmountsAction);
}

export default function* watchUpdateTasksAmounts() {
  yield takeEvery(UPDATE_TASKS_AMOUNTS, updateTasksAmounts);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   UPDATE_TASKS_AMOUNTS,
//   UPDATE_TASKS_AMOUNTS_START,
//   UPDATE_TASKS_AMOUNTS_SUCCESS,
//   UPDATE_TASKS_AMOUNTS_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function updateTasksAmountsStart() {
//   return {
//     type: UPDATE_TASKS_AMOUNTS_START
//   };
// }
//
// export function updateTasksAmountsSuccess(item = {}) {
//   return {
//     type: UPDATE_TASKS_AMOUNTS_SUCCESS,
//     item
//   };
// }
//
// export function updateTasksAmountsError(error) {
//   return {
//     type: UPDATE_TASKS_AMOUNTS_ERROR,
//     errorMessage: parseError(error),
//     error
//   };
// }
//
// function updateTasksAmountsRequest(values) {
//   return apiRequest.post('discussions/update_amounts', values);
// }
//
// function* updateTasksAmountsResource({ values, options: { form }, cb }) {
//   try {
//     yield call(updateTasksAmountsRequest, values);
//     yield put(updateTasksAmountsSuccess());
//     cb && cb();
//   } catch (error) {
//     yield put(updateTasksAmountsError(error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* updateTasksAmounts({ values, options, cb }) {
//   yield put(updateTasksAmountsStart());
//
//   return yield spawn(updateTasksAmountsResource, { values, options, cb });
// }
//
// export default function* watchUpdateTasksAmounts() {
//   yield takeEvery(UPDATE_TASKS_AMOUNTS, updateTasksAmounts);
// }
