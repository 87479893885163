import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_TASKS_RESULTS,
  DOWNLOAD_TASKS_RESULTS_START,
  DOWNLOAD_TASKS_RESULTS_SUCCESS,
  DOWNLOAD_TASKS_RESULTS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* downloadTasksResults({ values, options, cb }) {
  yield put(postItemsStart(DOWNLOAD_TASKS_RESULTS_START, options));

  return yield spawn(postItemsResource, 'discussions/results', {
    success: DOWNLOAD_TASKS_RESULTS_SUCCESS,
    error: DOWNLOAD_TASKS_RESULTS_ERROR
  }, { values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
}

export default function* watchDownloadTasksResults() {
  yield takeEvery(DOWNLOAD_TASKS_RESULTS, downloadTasksResults);
}
