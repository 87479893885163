import compact from 'lodash/compact';

import { ID } from '../../../../../types';

import { BillType } from '../types';

import {
  useFinancePostDataRequest,
  PostDataHookOptions
} from '../../base/useFinancePostDataRequest';

const url = 'stripe_bills';
const path = 'checkout';

interface StripePaymentOptions extends PostDataHookOptions {
  id: ID;
}

function useStripePayment({ id, ...options }: StripePaymentOptions) {
  const {
    dataErrorMessage,
    dataLoading,
    postData,
    cancel
  } = useFinancePostDataRequest<BillType>({
    url: compact([url, id, path]).join('/'),
    ...options
  });

  return {
    stripePaymentErrorMessage: dataErrorMessage,
    stripePaymentLoading: dataLoading,
    stripePayment: postData,
    cancel
  };
}

export default useStripePayment;
