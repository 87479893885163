import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ATTACH_SELECTED_MATERIALS,
  ATTACH_SELECTED_MATERIALS_START,
  ATTACH_SELECTED_MATERIALS_SUCCESS,
  ATTACH_SELECTED_MATERIALS_ERROR
} from '../SelectedMaterialConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* attachSelectedMaterials({ values, options, cb }) {
  yield put(postItemsStart(ATTACH_SELECTED_MATERIALS_START, options));

  return yield spawn(postItemsResource, 'selected_materials/attach', {
    success: ATTACH_SELECTED_MATERIALS_SUCCESS,
    error: ATTACH_SELECTED_MATERIALS_ERROR
  }, { values, options, cb });
}

export default function* watchAttachSelectedMaterials() {
  yield takeEvery(ATTACH_SELECTED_MATERIALS, attachSelectedMaterials);
}
