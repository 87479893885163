import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import pure from 'recompose/pure';

import { Files } from '../../utils/Files';

function Avatar({ className, addClass, image, style, version, width, height, alt, title, grayscale }) {
  return (
    <img
      className={className || cl('rounded-circle', { grayscale }, addClass)}
      src={Files.image(image, version)}
      alt={alt}
      title={title}
      style={style}
      width={width}
      height={height}
    />
  );
}

Avatar.propTypes = {
  addClass: PropTypes.string,
  className: PropTypes.string,
  grayscale: PropTypes.bool,
  image: PropTypes.shape({ file: PropTypes.string }),
  version: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string
};

Avatar.defaultProps = {
  addClass: null,
  className: null,
  grayscale: false,
  image: null,
  version: 'thumb_150x150',
  width: '36',
  height: '36',
  alt: '',
  title: null
};

export default pure(Avatar);
