import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_TASK_SELECTED_PRODUCTS,
  SUBMIT_TASK_SELECTED_PRODUCTS_START,
  SUBMIT_TASK_SELECTED_PRODUCTS_SUCCESS,
  SUBMIT_TASK_SELECTED_PRODUCTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitTaskSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_TASK_SELECTED_PRODUCTS_START, id, options));

  const submitTaskSelectedProductsAction = yield spawn(postItemResource, `discussions/${id}/submit_selected_facilities`, {
    success: SUBMIT_TASK_SELECTED_PRODUCTS_SUCCESS,
    error: SUBMIT_TASK_SELECTED_PRODUCTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitTaskSelectedProductsAction);
}

export default function* watchSubmitTaskSelectedProducts() {
  yield takeEvery(SUBMIT_TASK_SELECTED_PRODUCTS, submitTaskSelectedProducts);
}
