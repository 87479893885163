export default function prepareLocale(locale) {
  switch (locale) {
    case 'en':
    case 'en-US':
      return 'en';
    case 'ru':
    case 'ru-RU':
      return 'ru';
    case 'uk':
    case 'uk-UK':
    case 'uk-UA':
      return 'uk';
    default:
      return 'en';
  }
}
