import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeTaskSelectedTemplateStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: replaceById(get(task, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeTaskSelectedTemplateSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: removeById(get(task, 'selected_scenes', []), id)
    }))
  }, options);
}

export function removeTaskSelectedTemplateError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_scenes: replaceById(get(task, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
