import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES_START,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_ERROR
} from '../EmailTemplateConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchEmailTemplates(params) {
  yield put(fetchItemsStart(FETCH_EMAIL_TEMPLATES_START, params));

  const fetchEmailTemplatesAction = yield spawn(fetchItemsResource, 'email_templates', {
    success: FETCH_EMAIL_TEMPLATES_SUCCESS,
    error: FETCH_EMAIL_TEMPLATES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchEmailTemplatesAction);
}

export default function* watchFetchEmailTemplates() {
  yield takeEvery(FETCH_EMAIL_TEMPLATES, fetchEmailTemplates);
}
