export default {
  no: 'No controller requests',
  plural: 'Controller requests',
  refresh: 'Refresh controller requests list',
  singular: 'Controller request',
  method_names: {
    get: 'GET',
    post: 'POST',
    patch: 'PATCH',
    delete: 'DELETE'
  }
};
