import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_PROJECT_NOTIFICATION,
  CREATE_PROJECT_NOTIFICATION_START,
  CREATE_PROJECT_NOTIFICATION_SUCCESS,
  CREATE_PROJECT_NOTIFICATION_ERROR
} from '../ProjectNotificationConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createProjectNotification({ values, options, cb }) {
  yield put(createItemStart(CREATE_PROJECT_NOTIFICATION_START, options));

  return yield spawn(createItemResource, 'task_notifications', {
    success: CREATE_PROJECT_NOTIFICATION_SUCCESS,
    error: CREATE_PROJECT_NOTIFICATION_ERROR
  }, { values, options, cb });
}

export default function* watchCreateProjectNotification() {
  yield takeEvery(CREATE_PROJECT_NOTIFICATION, createProjectNotification);
}
