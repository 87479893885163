import { SET_DOWNLOADS_BOX_OPENED } from '../DownloadConstants';

// setDownloadsBoxOpened

export default function setDownloadsBoxOpened(downloadsBoxOpened) {
  return dispatch => dispatch({
    type: SET_DOWNLOADS_BOX_OPENED,
    downloadsBoxOpened
  });
}
