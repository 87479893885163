import { FETCH_ITEM_SUCCESS } from '../../constants';

import { ShowFinanceRequestAction } from '../../reducers/showFinanceRequestReducer';

export function fetchItemSuccess<ItemType>(
  item: ItemType
): ShowFinanceRequestAction<ItemType> {
  return {
    type: FETCH_ITEM_SUCCESS,
    item
  };
}
