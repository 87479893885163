import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE_START,
  FETCH_EMAIL_TEMPLATE_SUCCESS,
  FETCH_EMAIL_TEMPLATE_ERROR
} from '../EmailTemplateConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchEmailTemplate({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_EMAIL_TEMPLATE_START, id, options));

  const fetchEmailTemplateAction = yield spawn(fetchItemResource, 'email_templates', {
    success: FETCH_EMAIL_TEMPLATE_SUCCESS,
    error: FETCH_EMAIL_TEMPLATE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchEmailTemplateAction);
}

export default function* watchFetchEmailTemplate() {
  yield takeEvery(FETCH_EMAIL_TEMPLATE, fetchEmailTemplate);
}
