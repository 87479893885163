export default {
  'select_category...': 'Select template category...',
  add_new_to_the_library: 'Add new template to the library',
  add_plural: 'Add templates',
  add: 'Add a template',
  category: 'Template category',
  edit: 'Edit template',
  enter_title: 'Enter template title',
  image: 'Template image',
  library: 'Templates Library',
  model: 'Template 3d model',
  new: 'New template',
  no: 'No templates',
  own: 'Own Templates',
  my: 'My Templates',
  plural_lower_case: 'templates',
  plural: 'Templates',
  selected: 'Selected templates',
  singular: 'Template',
  stock: 'Templates Stock',
  title: 'Template title',
  filter: 'Filter templates'
};
