import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_FILE_ATTACHMENT,
  DOWNLOAD_FILE_ATTACHMENT_START,
  DOWNLOAD_FILE_ATTACHMENT_SUCCESS,
  DOWNLOAD_FILE_ATTACHMENT_ERROR
} from '../FileAttachmentConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadFileAttachment({ id, values, options, cb }) {
  // if (get(options, 'file')) {
  //   yield downloadFile(get(options, 'file'), get(options, 'file').substring(get(options, 'file').lastIndexOf('/') + 1));
  //   return;
  // }

  yield put(postItemStart(DOWNLOAD_FILE_ATTACHMENT_START, id, options));

  const downloadFileAttachmentAction = yield spawn(postItemResource, `file_attachments/${id}/download`, {
    success: DOWNLOAD_FILE_ATTACHMENT_SUCCESS,
    error: DOWNLOAD_FILE_ATTACHMENT_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadFileAttachmentAction);
}

export default function* watchDownloadFileAttachment() {
  yield takeEvery(DOWNLOAD_FILE_ATTACHMENT, downloadFileAttachment);
}
