import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TEXTURE_FILES,
  FETCH_TEXTURE_FILES_START,
  FETCH_TEXTURE_FILES_SUCCESS,
  FETCH_TEXTURE_FILES_ERROR
} from '../TextureFileConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTextureFiles(params) {
  yield put(fetchItemsStart(FETCH_TEXTURE_FILES_START, params));

  const fetchTextureFilesAction = yield spawn(fetchItemsResource, 'texture_files', {
    success: FETCH_TEXTURE_FILES_SUCCESS,
    error: FETCH_TEXTURE_FILES_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(fetchTextureFilesAction);
}

export default function* watchFetchTextureFiles() {
  yield takeEvery(FETCH_TEXTURE_FILES, fetchTextureFiles);
}
