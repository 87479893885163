import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function createMaterialsSetSelectedMaterialsSuccess(state, { item, options = {} }) {
  const currentMaterialsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentMaterialsSet,
      selected_materials: [
        ...get(currentMaterialsSet, 'selected_materials', []),
        item
      ]
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, materialsSet => ({
      ...materialsSet,
      selected_materials: [
        ...get(materialsSet, 'selected_materials', []),
        item
      ]
    }))
  }, options);
}
