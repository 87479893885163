import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const DownloadShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_downloads" */ './pages/DownloadShowPage/DownloadShowPage'
    )
});
const DownloadsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_downloads" */ './pages/DownloadsIndexPage/DownloadsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/downloads/:downloadId(\d+)"
      component={DownloadShowPage}
    />
    <Route exact path="/admin/downloads" component={DownloadsIndexPage} />
  </Fragment>
);
