export const UPLOAD_MAX_FILES = 'UPLOAD_MAX_FILES';
export const UPLOAD_MAX_FILES_START = 'UPLOAD_MAX_FILES_START';
export const UPLOAD_MAX_FILES_SUCCESS = 'UPLOAD_MAX_FILES_SUCCESS';
export const UPLOAD_MAX_FILES_ERROR = 'UPLOAD_MAX_FILES_ERROR';

export const UPLOAD_MAX_FILE_START = 'UPLOAD_MAX_FILE_START';
export const UPLOAD_MAX_FILE_SUCCESS = 'UPLOAD_MAX_FILE_SUCCESS';
export const UPLOAD_MAX_FILE_ERROR = 'UPLOAD_MAX_FILE_ERROR';

export const REMOVE_MAX_FILE = 'REMOVE_MAX_FILE';
export const REMOVE_MAX_FILE_START = 'REMOVE_MAX_FILE_START';
export const REMOVE_MAX_FILE_SUCCESS = 'REMOVE_MAX_FILE_SUCCESS';
export const REMOVE_MAX_FILE_ERROR = 'REMOVE_MAX_FILE_ERROR';

export const CLEAR_MAX_FILE_MODAL_VALUES = 'CLEAR_MAX_FILE_MODAL_VALUES';
