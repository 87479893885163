import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  TRACK_TASKS_TIME,
  TRACK_TASKS_TIME_START,
  TRACK_TASKS_TIME_SUCCESS,
  TRACK_TASKS_TIME_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* trackTasksTime({ values, options, cb }) {
  yield put(postItemsStart(TRACK_TASKS_TIME_START, options));

  const trackTasksTimeAction = yield spawn(
    postItemsResource,
    'discussions/batch_track_time',
    {
      success: TRACK_TASKS_TIME_SUCCESS,
      error: TRACK_TASKS_TIME_ERROR
    },
    { values, options, cb }
  );

  yield take(LOCATION_CHANGE);
  yield cancel(trackTasksTimeAction);
}

export default function* watchTrackTasksTime() {
  yield takeEvery(TRACK_TASKS_TIME, trackTasksTime);
}
