import React, { memo } from 'react';

import { TermsModalButton } from '../../../../main/common/components/buttons/TermsModalButton';

import { CRM_NAME } from '../../../../config';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-light border-top p-3">
      <div className="align-items-center d-flex flex-column-reverse flex-sm-row justify-content-between">
        <span className="text-center">
          ©&nbsp;
          {CRM_NAME === 'Archivizer' ? `2012-${currentYear}.` : currentYear}
          &nbsp;
          {CRM_NAME} CRM
        </span>
        <div className="align-items-center d-flex justify-content-center pb-2 py-sm-0">
          <TermsModalButton />
        </div>
      </div>
    </div>
  );
}

export default memo(Footer);
