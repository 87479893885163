import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  RECALCULATE_BALANCES,
  RECALCULATE_BALANCES_START,
  RECALCULATE_BALANCES_SUCCESS,
  RECALCULATE_BALANCES_ERROR
} from '../BalanceConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* recalculateBalances({ values, options, cb }) {
  yield put(postItemsStart(RECALCULATE_BALANCES_START, options));

  const recalculateBalancesAction = yield spawn(postItemsResource, 'balances/recalculate', {
    success: RECALCULATE_BALANCES_SUCCESS,
    error: RECALCULATE_BALANCES_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(recalculateBalancesAction);
}

export default function* watchRecalculateBalances() {
  yield takeEvery(RECALCULATE_BALANCES, recalculateBalances);
}
