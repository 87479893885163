import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_ALLOCATIONS,
  FETCH_ALLOCATIONS_START,
  FETCH_ALLOCATIONS_SUCCESS,
  FETCH_ALLOCATIONS_ERROR
} from '../AllocationConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchAllocations(params) {
  yield put(fetchItemsStart(FETCH_ALLOCATIONS_START, params));

  const fetchAllocationsAction = yield spawn(fetchItemsResource, 'discussion_funds_allocations', {
    success: FETCH_ALLOCATIONS_SUCCESS,
    error: FETCH_ALLOCATIONS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchAllocationsAction);
}

export default function* watchFetchAllocations() {
  yield takeEvery(FETCH_ALLOCATIONS, fetchAllocations);
}
