import React from 'react';

import {
  fetchMessages as fetchMessagesAction,
  createMessage as createMessageAction,
  updateMessage as updateMessageAction,
  clearMessageFilters as clearMessageFiltersAction,
  clearMessageModalValues as clearMessageModalValuesAction,
  toggleSelectedMessage as toggleSelectedMessageAction,
  readMessage as readMessageAction,
  unreadMessage as unreadMessageAction,
  forwardMessage as forwardMessageAction,
  readAllMessages as readAllMessagesAction,
  downloadMessageFileAttachments as downloadMessageFileAttachmentsAction,
  downloadMessageSelectedProducts as downloadMessageSelectedProductsAction,
  downloadMessageSelectedTemplates as downloadMessageSelectedTemplatesAction,
  downloadMessageSelectedMaterials as downloadMessageSelectedMaterialsAction,
  submitMessageFileAttachments as submitMessageFileAttachmentsAction,
  submitMessageSelectedProducts as submitMessageSelectedProductsAction,
  submitMessageSelectedTemplates as submitMessageSelectedTemplatesAction,
  downloadMessagesXls as downloadMessagesXlsAction,
} from '../../../messages/MessageActions';

import {
  subscribeToMessagesChannel as subscribeToMessagesChannelAction,
  unsubscribeFromMessagesChannel as unsubscribeFromMessagesChannelAction,
  subscribeToDropdownMessagesChannel as subscribeToDropdownMessagesChannelAction,
  unsubscribeFromDropdownMessagesChannel as unsubscribeFromDropdownMessagesChannelAction
} from '../../../messages/MessageSubscriptions';

import { baseHOC } from '../utils';

const withMessages = (options = {}) => WrappedComponent => {
  function MessagesHoc(props) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return baseHOC(options, {
    fetchMessages: fetchMessagesAction,
    createMessage: createMessageAction,
    updateMessage: updateMessageAction,
    unreadMessage: unreadMessageAction,
    forwardMessage: forwardMessageAction,
    clearMessagesFilters: clearMessageFiltersAction,
    clearMessageModalValues: clearMessageModalValuesAction,
    toggleSelectedMessage: toggleSelectedMessageAction,
    subscribeToMessagesChannel: subscribeToMessagesChannelAction,
    unsubscribeFromMessagesChannel: unsubscribeFromMessagesChannelAction,
    subscribeToDropdownMessagesChannel: subscribeToDropdownMessagesChannelAction,
    unsubscribeFromDropdownMessagesChannel: unsubscribeFromDropdownMessagesChannelAction,
    readMessage: readMessageAction,
    readAllMessages: readAllMessagesAction,
    downloadMessageFileAttachments: downloadMessageFileAttachmentsAction,
    downloadMessageSelectedProducts: downloadMessageSelectedProductsAction,
    downloadMessageSelectedTemplates: downloadMessageSelectedTemplatesAction,
    downloadMessageSelectedMaterials: downloadMessageSelectedMaterialsAction,
    submitMessageFileAttachments: submitMessageFileAttachmentsAction,
    submitMessageSelectedProducts: submitMessageSelectedProductsAction,
    submitMessageSelectedTemplates: submitMessageSelectedTemplatesAction,
    downloadMessagesXls: downloadMessagesXlsAction
  }, {
    toggleSelectedMessage: ({ toggleSelectedMessage }) => message => () => toggleSelectedMessage(message.id),
    readMessage: ({ namespace, readMessage }) => (message, opts, cb) => () => readMessage(message.id, { ...opts, namespace: namespace || options.namespace }, cb),
    unreadMessage: ({ namespace, unreadMessage }) => (message, opts, cb) => () => unreadMessage(message.id, { ...opts, namespace: namespace || options.namespace }, cb),
    forwardMessage: ({ namespace, forwardMessage }) => (message, cb, form) => values => forwardMessage(message.id, values, { pure: true, form, namespace }, cb),
    downloadMessageFileAttachments: ({ downloadMessageFileAttachments }) => message => () => downloadMessageFileAttachments(message.id),
    downloadMessageSelectedProducts: ({ downloadMessageSelectedProducts }) => message => () => downloadMessageSelectedProducts(message.id),
    downloadMessageSelectedTemplates: ({ downloadMessageSelectedTemplates }) => message => () => downloadMessageSelectedTemplates(message.id),
    downloadMessageSelectedMaterials: ({ downloadMessageSelectedMaterials }) => message => () => downloadMessageSelectedMaterials(message.id),
    submitMessageFileAttachments: ({ namespace, submitMessageFileAttachments }) => (message, cb, form) => values => submitMessageFileAttachments(message.id, values, { pure: true, form, namespace }, cb),
    submitMessageSelectedProducts: ({ namespace, submitMessageSelectedProducts }) => message => (values, cb) => submitMessageSelectedProducts(message.id, values, { param: 'selected_facility_ids', namespace }, cb),
    submitMessageSelectedTemplates: ({ namespace, submitMessageSelectedTemplates }) => message => (values, cb) => submitMessageSelectedTemplates(message.id, values, { param: 'selected_scene_ids', namespace }, cb),
    readAllMessages: ({ readAllMessages }) => cb => (opts = {}) => readAllMessages({ ...opts, namespace: options.namespace }, cb),
    downloadMessagesXls: ({ downloadMessagesXls }) => (params, cb, opts) => downloadMessagesXls(params, cb, opts),
  })(MessagesHoc);
};

export default withMessages;
