import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const MaxFileShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_max_files" */ './pages/MaxFileShowPage/MaxFileShowPage'
    )
});
const MaxFilesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_max_files" */ './pages/MaxFilesIndexPage/MaxFilesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/max_files/:maxFileId(\d+)"
      component={MaxFileShowPage}
    />
    <Route exact path="/admin/max_files" component={MaxFilesIndexPage} />
  </Fragment>
);
