import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PERMISSION,
  REMOVE_PERMISSION_START,
  REMOVE_PERMISSION_SUCCESS,
  REMOVE_PERMISSION_ERROR
} from '../PermissionConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removePermission({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_PERMISSION_START, id, options));

  return yield spawn(removeItemResource, 'permissions', {
    success: REMOVE_PERMISSION_SUCCESS,
    error: REMOVE_PERMISSION_ERROR
  }, { id, options, cb });
}

export default function* watchRemovePermission() {
  yield takeEvery(REMOVE_PERMISSION, removePermission);
}
