import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  PROVIDE_ACCESS_LINKED_ACCOUNTS,
  PROVIDE_ACCESS_LINKED_ACCOUNTS_START,
  PROVIDE_ACCESS_LINKED_ACCOUNTS_SUCCESS,
  PROVIDE_ACCESS_LINKED_ACCOUNTS_ERROR
} from '../LinkedAccountConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* provideAccessLinkedAccounts({ id, values, options, cb }) {
  yield put(postItemStart(PROVIDE_ACCESS_LINKED_ACCOUNTS_START, id, options));

  return yield spawn(postItemResource, `linked_users/${id}/provide_access`, {
    success: PROVIDE_ACCESS_LINKED_ACCOUNTS_SUCCESS,
    error: PROVIDE_ACCESS_LINKED_ACCOUNTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchProvideAccessLinkedAccount() {
  yield takeEvery(PROVIDE_ACCESS_LINKED_ACCOUNTS, provideAccessLinkedAccounts);
}
