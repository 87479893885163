import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  itemsChannelUpdated, clearModalValues
} from '../../common/reducers';

import {
  removeSelectedProductTextureFilesStart,
  removeSelectedProductTextureFilesSuccess,
  removeSelectedProductTextureFilesError
} from './removeSelectedProductTextureFiles';

import {
  attachSelectedProductsStart,
  attachSelectedProductsSuccess,
  attachSelectedProductsError
} from './attachSelectedProducts';

import {
  removeSelectedProductsStart,
  removeSelectedProductsSuccess,
  removeSelectedProductsError
} from './removeSelectedProducts';

export default createReducer(defaultState, {
  FETCH_SELECTED_PRODUCTS_START: fetchItemsStart,
  FETCH_SELECTED_PRODUCTS_SUCCESS: fetchItemsSuccess,
  FETCH_SELECTED_PRODUCTS_ERROR: fetchItemsError,

  FETCH_SELECTED_PRODUCT_START: fetchItemStart,
  FETCH_SELECTED_PRODUCT_SUCCESS: fetchItemSuccess,
  FETCH_SELECTED_PRODUCT_ERROR: fetchItemError,

  CREATE_SELECTED_PRODUCT_START: createItemStart,
  CREATE_SELECTED_PRODUCT_SUCCESS: createItemSuccess,
  CREATE_SELECTED_PRODUCT_ERROR: createItemError,

  UPDATE_SELECTED_PRODUCT_START: updateItemStart,
  UPDATE_SELECTED_PRODUCT_SUCCESS: updateItemSuccess,
  UPDATE_SELECTED_PRODUCT_ERROR: updateItemError,

  REMOVE_SELECTED_PRODUCT_START: removeItemStart,
  REMOVE_SELECTED_PRODUCT_SUCCESS: removeItemSuccess,
  REMOVE_SELECTED_PRODUCT_ERROR: removeItemError,

  ATTACH_SELECTED_PRODUCTS_START: attachSelectedProductsStart,
  ATTACH_SELECTED_PRODUCTS_SUCCESS: attachSelectedProductsSuccess,
  ATTACH_SELECTED_PRODUCTS_ERROR: attachSelectedProductsError,

  REMOVE_TASK_SELECTED_PRODUCTS_START: removeSelectedProductsStart,
  REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS: removeSelectedProductsSuccess,
  REMOVE_TASK_SELECTED_PRODUCTS_ERROR: removeSelectedProductsError,

  SELECTED_PRODUCTS_CHANNEL_UPDATED: itemsChannelUpdated,

  CLEAR_SELECTED_PRODUCT_MODAL_VALUES: clearModalValues,

  REMOVE_TEXTURE_FILE_START: removeSelectedProductTextureFilesStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeSelectedProductTextureFilesSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeSelectedProductTextureFilesError
});
