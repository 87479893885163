import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TEMPLATES_SET,
  DOWNLOAD_TEMPLATES_SET_START,
  DOWNLOAD_TEMPLATES_SET_SUCCESS,
  DOWNLOAD_TEMPLATES_SET_ERROR
} from '../TemplatesSetConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadTemplatesSets({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_TEMPLATES_SET_START, id, options));

  const downloadTemplatesSetsAction = yield spawn(postItemResource, `scenes_sets/${id}/download`, {
    success: DOWNLOAD_TEMPLATES_SET_SUCCESS,
    error: DOWNLOAD_TEMPLATES_SET_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTemplatesSetsAction);
}

export default function* watchDownloadTemplatesSets() {
  yield takeEvery(DOWNLOAD_TEMPLATES_SET, downloadTemplatesSets);
}
