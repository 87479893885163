import { LOCATION_CHANGE } from 'react-router-redux';
import get from 'lodash/get';

import { GET_REQUEST_ERROR } from './AppConstants'; // SHOW_MODAL, HIDE_MODAL, CLEAR_HIDE_MODAL,

const defaultState = {
  pathname: null,
  status: null,
  modalOpened: false,
  modalBody: null,
  modalOptions: {},
  // hiddenModal: false,
  getRequestError: null
};

export default function AppReducer(state = defaultState, { type, error, payload }) { // modalBody, modalOptions,
  if (type === GET_REQUEST_ERROR) {
    return {
      ...state,
      getRequestError: error
    };
  }

  if (type === LOCATION_CHANGE) {
    return {
      ...state,
      pathname: get(payload, 'pathname'),
      getRequestError: null
    };
  }

  if (/.*_ERROR$/.test(type)) {
    return { ...state, status: get(error, 'response.status') };
  }

  // if (type === SHOW_MODAL) {
  //   return { ...state, modalOpened: true, modalBody, modalOptions };
  // }
  //
  // if (type === HIDE_MODAL) {
  //   return { ...state, modalOpened: false, modalBody: null, modalOptions: {}, hiddenModal: true };
  // }
  //
  // if (type === CLEAR_HIDE_MODAL) {
  //   return { ...state, hiddenModal: false };
  // }

  return state;
}
