import { updateState } from '../../../utils';

export function overwatchUsersStart(state, { options = {} }) {
  return updateState(
    state,
    {
      isLoading: true,
      errorMessage: null
    },
    options
  );
}

export function overwatchUsersSuccess(state, { options = {} }) {
  return updateState(
    state,
    {
      isLoading: false,
      errorMessage: null
    },
    options
  );
}

export function overwatchUsersError(state, { errorMessage, options = {} }) {
  return updateState(
    state,
    {
      isLoading: false,
      errorMessage
    },
    options
  );
}
