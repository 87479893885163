import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* deleteAccount({ options, cb }) {
  yield put(postItemStart(DELETE_ACCOUNT_START, options));

  return yield spawn(postItemResource, 'auth/delete_account', {
    success: DELETE_ACCOUNT_SUCCESS,
    error: DELETE_ACCOUNT_ERROR
  }, { options: { ...options, param: 'auth' }, cb });
}

export default function* watchDeleteAccount() {
  yield takeEvery(DELETE_ACCOUNT, deleteAccount);
}
