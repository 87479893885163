import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsError,
  fetchItemStart,
  fetchItemSuccess,
  fetchItemError,
  clearItemsFiltersStart,
  clearItemsFiltersSuccess,
  locationChange,
  clearModalValues
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_ALLOCATIONS_START: fetchItemsStart,
  FETCH_ALLOCATIONS_SUCCESS: fetchItemsSuccess,
  FETCH_ALLOCATIONS_ERROR: fetchItemsError,

  FETCH_ALLOCATION_START: fetchItemStart,
  FETCH_ALLOCATION_SUCCESS: fetchItemSuccess,
  FETCH_ALLOCATION_ERROR: fetchItemError,

  CLEAR_ALLOCATIONS_FILTERS_START: clearItemsFiltersStart,
  CLEAR_ALLOCATIONS_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_ALLOCATION_MODAL_VALUES: clearModalValues
});
