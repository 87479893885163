import React, { Fragment } from 'react';

import { ID } from '../../../../../../../../types';

import { useBill } from '../../../../../../../common/hooks/bills/useBill';

import { ItemBillsModalBody } from '../../components/ItemBillsModalBody';
import { ItemBillsModalFooter } from '../../components/ItemBillsModalFooter';

interface ItemBillsModalContainerProps {
  addClass?: string;
  billId: ID;
  onClose: () => void;
  onPayment?: () => void;
  submitIcon: string;
  type: string;
}

function ItemBillsModalContainer({
  addClass,
  billId,
  onClose,
  onPayment,
  submitIcon,
  type
}: ItemBillsModalContainerProps) {
  const { bill, billFetched, billLoading, billErrorMessage } = useBill({
    id: billId
  });

  return (
    <Fragment>
      <ItemBillsModalBody
        bill={bill}
        billErrorMessage={billErrorMessage}
        billFetched={billFetched}
      />
      <ItemBillsModalFooter
        addClass={addClass}
        bill={bill}
        billFetched={billFetched}
        billLoading={billLoading}
        onClose={onClose}
        onPayment={onPayment}
        submitIcon={submitIcon}
        type={type}
      />
    </Fragment>
  );
}

export default ItemBillsModalContainer;
