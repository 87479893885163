import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_EMAIL_TEMPLATE,
  REMOVE_EMAIL_TEMPLATE_START,
  REMOVE_EMAIL_TEMPLATE_SUCCESS,
  REMOVE_EMAIL_TEMPLATE_ERROR
} from '../EmailTemplateConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeEmailTemplate({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_EMAIL_TEMPLATE_START, id, options));

  return yield spawn(removeItemResource, 'email_templates', {
    success: REMOVE_EMAIL_TEMPLATE_SUCCESS,
    error: REMOVE_EMAIL_TEMPLATE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveEmailTemplate() {
  yield takeEvery(REMOVE_EMAIL_TEMPLATE, removeEmailTemplate);
}
