import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';

import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';

import { withMessages } from '../../main/common/HOCs/withMessages';
import { withCurrentUser } from '../../main/common/HOCs/withCurrentUser';
import MessagesMenuContent from './MessagesMenuContent';
import { LinkTo } from '../../helpers';

// {currentUser.get('users_unread_discussion_messages_count') > 0 ? (
//   <div className="dropdown-content-header">
//             <span className="font-size-sm line-height-sm text-uppercase font-weight-semibold">
//               {t('models.messages.plural')}
//             </span>
//   </div>
// ) : null}

function MessagesMenu({
  currentUser,
  isOpen,
  collapse,
  toggleIsOpen,
  addTotalBadgeClassName
}) {
  return (
    <li className="nav-item dropdown">
      <OutsideClickHandler onOutsideClick={collapse}>
        <LinkTo
          pureLink
          className={cl('navbar-nav-link', 'position-relative', {
            show: isOpen
          })}
          data-toggle="dropdown"
          onClick={toggleIsOpen}
        >
          <i className="icon-bubble8" />
          {currentUser.get('users_unread_discussion_messages_count') > 0 ? (
            <span
              className={cl(
                'badge badge-warning badge-pill badge-float mt-1 mr-1',
                addTotalBadgeClassName
              )}
            >
              {currentUser.get('users_unread_discussion_messages_count')}
            </span>
          ) : null}
        </LinkTo>

        {isOpen ? (
          <MessagesMenuContent
            currentUser={currentUser}
            onNavigate={collapse}
          />
        ) : null}
      </OutsideClickHandler>
    </li>
  );
}

MessagesMenu.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    get: PropTypes.func
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
  addTotalBadgeClassName: PropTypes.string
};

export default compose(
  withCurrentUser(),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    toggleIsOpen: ({ isOpen, setIsOpen }) => () => setIsOpen(!isOpen),
    collapse: ({ isOpen, setIsOpen }) => () => isOpen && setIsOpen(false)
  }),
  withMessages({
    scope: 'messages',
    fields: [],
    actions: [
      'subscribeToDropdownMessagesChannel',
      'unsubscribeFromDropdownMessagesChannel'
    ]
  }),
  lifecycle({
    componentDidMount() {
      this.props.subscribeToDropdownMessagesChannel(
        'created',
        this.props.currentUser.get('id')
      );
    },
    componentWillUnmount() {
      this.props.unsubscribeFromDropdownMessagesChannel(
        'created',
        this.props.currentUser.get('id')
      );
    }
  })
)(MessagesMenu);
