import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const UserWebsocketRequestsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_user_websocket_requests" */ './pages/UserWebsocketRequestsIndexPage/UserWebsocketRequestsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/user_websocket_requests"
      component={UserWebsocketRequestsIndexPage}
    />
  </Fragment>
);
