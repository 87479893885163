import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TASK_SOURCE_FILES,
  DOWNLOAD_TASK_SOURCE_FILES_START,
  DOWNLOAD_TASK_SOURCE_FILES_SUCCESS,
  DOWNLOAD_TASK_SOURCE_FILES_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadTaskSourceFiles({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_TASK_SOURCE_FILES_START, id, options));

  const downloadTaskSourceFilesAction = yield spawn(postItemResource, `discussions/${id}/source_files`, {
    success: DOWNLOAD_TASK_SOURCE_FILES_SUCCESS,
    error: DOWNLOAD_TASK_SOURCE_FILES_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTaskSourceFilesAction);
}

export default function* watchDownloadTaskSourceFiles() {
  yield takeEvery(DOWNLOAD_TASK_SOURCE_FILES, downloadTaskSourceFiles);
}
