import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const UserRegistrationsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_user_registrations" */ './pages/UserRegistrationsIndexPage/UserRegistrationsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/user_registrations"
      component={UserRegistrationsIndexPage}
    />
  </Fragment>
);
