import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

import {
  createLinkedAccountsStart,
  createLinkedAccountsSuccess,
  createLinkedAccountsError
} from './createLinkedAccounts';

import {
  provideAccessLinkedAccountsStart,
  provideAccessLinkedAccountsSuccess,
  provideAccessLinkedAccountsError
} from './provideAccessLinkedAccounts';

import {
  requestAccessLinkedAccountsStart,
  requestAccessLinkedAccountsSuccess,
  requestAccessLinkedAccountsError
} from './requestAccessLinkedAccounts';

import { clearLinkedAccountModalValues } from './clearLinkedAccountModalValues';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_LINKED_ACCOUNTS_START: fetchItemsStart,
  FETCH_LINKED_ACCOUNTS_SUCCESS: fetchItemsSuccess,
  FETCH_LINKED_ACCOUNTS_ERROR: fetchItemsError,

  FETCH_LINKED_ACCOUNT_START: fetchItemStart,
  FETCH_LINKED_ACCOUNT_SUCCESS: fetchItemSuccess,
  FETCH_LINKED_ACCOUNT_ERROR: fetchItemError,

  CREATE_LINKED_ACCOUNT_START: createItemStart,
  CREATE_LINKED_ACCOUNT_SUCCESS: createItemSuccess,
  CREATE_LINKED_ACCOUNT_ERROR: createItemError,

  UPDATE_LINKED_ACCOUNT_START: updateItemStart,
  UPDATE_LINKED_ACCOUNT_SUCCESS: updateItemSuccess,
  UPDATE_LINKED_ACCOUNT_ERROR: updateItemError,

  CREATE_LINKED_ACCOUNTS_START: createLinkedAccountsStart,
  CREATE_LINKED_ACCOUNTS_SUCCESS: createLinkedAccountsSuccess,
  CREATE_LINKED_ACCOUNTS_ERROR: createLinkedAccountsError,

  REMOVE_LINKED_ACCOUNT_START: removeItemStart,
  REMOVE_LINKED_ACCOUNT_SUCCESS: removeItemSuccess,
  REMOVE_LINKED_ACCOUNT_ERROR: removeItemError,

  PROVIDE_ACCESS_LINKED_ACCOUNTS_START: provideAccessLinkedAccountsStart,
  PROVIDE_ACCESS_LINKED_ACCOUNTS_SUCCESS: provideAccessLinkedAccountsSuccess,
  PROVIDE_ACCESS_LINKED_ACCOUNTS_ERROR: provideAccessLinkedAccountsError,

  REQUEST_ACCESS_LINKED_ACCOUNTS_START: requestAccessLinkedAccountsStart,
  REQUEST_ACCESS_LINKED_ACCOUNTS_SUCCESS: requestAccessLinkedAccountsSuccess,
  REQUEST_ACCESS_LINKED_ACCOUNTS_ERROR: requestAccessLinkedAccountsError,

  CLEAR_LINKED_ACCOUNT_MODAL_VALUES: clearLinkedAccountModalValues
});
