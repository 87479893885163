import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeMessageSelectedProductsStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_facilities: replaceById(get(message, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeMessageSelectedProductsSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_facilities: removeById(get(message, 'selected_facilities', []), id)
    }))
  }, options);
}

export function removeMessageSelectedProductsError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_facilities: replaceById(get(message, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
