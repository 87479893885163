import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function toggleSelectedTaskStart(state, { options = {} }) {
  return updateState(state, {
    errorMessage: null
  }, options);
}

export function toggleSelectedTaskSuccess(state, { id, options = {} }) {
  return updateState(state, {
    errorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      selected: !i.selected
    }))
  }, options);
}

export function toggleSelectedTaskError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}

// import { replaceById } from '../../../utils';
//
// export function toggleSelectedTaskStart(state) {
//   return state;
// }
//
// export function toggleSelectedTaskSuccess(state, { id }) {
//   return {
//     ...state,
//     items: replaceById(state.items, id, i => ({ ...i, selected: !i.selected }))
//   };
// }
//
// export function toggleSelectedTaskError(state, { errorMessage }) {
//   return {
//     ...state,
//     errorMessage
//   };
// }
