import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  INVITE_USERS_TO_PROJECT,
  INVITE_USERS_TO_PROJECT_START,
  INVITE_USERS_TO_PROJECT_SUCCESS,
  INVITE_USERS_TO_PROJECT_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* inviteUsersToProject({ id, values, options, cb }) {
  yield put(postItemStart(INVITE_USERS_TO_PROJECT_START, id, options));

  const inviteUsersToProjectAction = yield spawn(postItemResource, `tasks/${id}/invite_members`, {
    success: INVITE_USERS_TO_PROJECT_SUCCESS,
    error: INVITE_USERS_TO_PROJECT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(inviteUsersToProjectAction);
}

export default function* watchInviteUsersToProject() {
  yield takeEvery(INVITE_USERS_TO_PROJECT, inviteUsersToProject);
}
