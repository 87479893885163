import { SOCKET_SUBSCRIBE_TO_DROPDOWN_DOWNLOADS_CHANNEL } from '../DownloadConstants';

export default function subscribeToDropdownDownloadsChannel(action, userId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_SUBSCRIBE_TO_DROPDOWN_DOWNLOADS_CHANNEL,
    channel: 'Archi::Crm::DropdownDownloadsChannel',
    command: 'subscribe',
    action,
    params
  });
}
