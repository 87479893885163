import { registerLocale } from 'react-datepicker';

import { loadLocale } from '../../../utils/loadLocale';
import { dateFnsLocales } from '../../../utils/ts/dateFnsLocales';

import { Locale } from '../../../types';

function loadAllLocales(locale: Locale): Promise<object> {
  // 'en' locale in date-fns loaded by default in react-datepicker
  // So only 'ru' need loading
  if (locale === 'ru') {
    import(
      /* webpackChunkName: "date_fns_locale_ru" */ `date-fns/locale/ru`
    ).then(loadedLocale => {
      registerLocale('ru', loadedLocale.default);
      dateFnsLocales.setLocale('ru', loadedLocale.default);
    });
  }

  if (locale === 'uk') {
    import(
      /* webpackChunkName: "date_fns_locale_ru" */ `date-fns/locale/uk`
    ).then(loadedLocale => {
      registerLocale('uk', loadedLocale.default);
      dateFnsLocales.setLocale('uk', loadedLocale.default);
    });
  }

  return loadLocale(locale);
}

export default loadAllLocales;
