import React, { Fragment } from 'react';
import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ResultsIndexRoute = LoadableWrapper({
  loader: () =>
    import(/* webpackChunkName: "new-results" */ '../../../pages/results')
});

export default (
  <Fragment>
    <Route exact path="/new-results" component={ResultsIndexRoute} />
  </Fragment>
);
