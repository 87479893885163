import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';

import filter from 'lodash/filter';
import size from 'lodash/size';
import memoize from 'lodash/memoize';

import { withCurrentUser } from '../../../common/HOCs';

import { LinkTo } from '../../../../helpers';
import { translate as t } from '../../../../utils';

const availableTabsCount = memoize((tabs, user) => size(filter(tabs, ({ value }) => user.hasPermissions(`read_${value}_tab`))));

/**
 * @return {null}
 */
function Tabs({ currentUser, tab, data, handleTabChange, className, withoutPermissions }) {
  return withoutPermissions || availableTabsCount(data, currentUser) > 1 ? (
    <ul className={className || 'nav nav-tabs nav-tabs-bottom mx-0'}>
      {data.map(({ value, label }) => (
        <Fragment key={`tab-${value}`}>
          {withoutPermissions || currentUser.hasPermissions(`read_${value}_tab`) ? (
            <li className="nav-item">
              <LinkTo
                pureLink
                href="#"
                className={cl('nav-link', { active: tab === value })}
                onClick={handleTabChange(value)}
              >
                {t(label)}
              </LinkTo>
            </li>
          ) : null}
        </Fragment>
      ))}
    </ul>
  ) : null;
}

Tabs.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  className: PropTypes.string,
  tab: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  withoutPermissions: PropTypes.bool,
  handleTabChange: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  className: null,
  withoutPermissions: false
};

export default compose(
  withCurrentUser(),
  withState('tab', 'setTab', ({ defaultTab }) => defaultTab),
  withHandlers({
    handleTabChange: ({ setTab, onChange }) => value => () => setTab(value, () => onChange && onChange(value))
  })
)(Tabs);
