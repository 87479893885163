import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_START,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_ERROR
} from '../ProductConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeProduct({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_PRODUCT_START, id, options));

  return yield spawn(removeItemResource, 'facilities', {
    success: REMOVE_PRODUCT_SUCCESS,
    error: REMOVE_PRODUCT_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveProduct() {
  yield takeEvery(REMOVE_PRODUCT, removeProduct);
}
