import { takeEvery, put, call, spawn } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import {
  NULLIFY_FUNDS_MEMBER,
  NULLIFY_FUNDS_MEMBER_START,
  NULLIFY_FUNDS_MEMBER_SUCCESS,
  NULLIFY_FUNDS_MEMBER_ERROR
} from '../FundsMemberConstants';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function nullifyFundsMemberStart(id, options = {}) {
  return {
    type: NULLIFY_FUNDS_MEMBER_START,
    id,
    options
  };
}

export function nullifyFundsMemberSuccess(id, options = {}) {
  return {
    type: NULLIFY_FUNDS_MEMBER_SUCCESS,
    id,
    options
  };
}

export function nullifyFundsMemberError(id, error, options = {}) {
  return {
    type: NULLIFY_FUNDS_MEMBER_ERROR,
    errorMessage: parseError(error),
    error,
    id,
    options
  };
}

function nullifyFundsMemberRequest(id) {
  return apiRequest.post(`funds_members/${id}/nullify`);
}

function* nullifyFundsMemberResource({ id, options, cb }) {
  try {
    yield call(nullifyFundsMemberRequest, id);
    yield put(nullifyFundsMemberSuccess(id, options));
    cb && cb();
  } catch (error) {
    yield put(nullifyFundsMemberError(id, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}

function* nullifyFundsMember({ id, options, cb }) {
  yield put(nullifyFundsMemberStart(id, options));

  return yield spawn(nullifyFundsMemberResource, { id, options, cb });
}

export default function* watchRemoveFundsMember() {
  yield takeEvery(NULLIFY_FUNDS_MEMBER, nullifyFundsMember);
}
