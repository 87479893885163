import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const EmailTemplatesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_email_templates" */ './pages/EmailTemplatesIndexPage/EmailTemplatesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/email_templates"
      component={EmailTemplatesIndexPage}
    />
  </Fragment>
);
