import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CREATE_TASK_BILLS,
  CREATE_TASK_BILLS_START,
  CREATE_TASK_BILLS_SUCCESS,
  CREATE_TASK_BILLS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* createTaskBills({ values, options, cb }) {
  yield put(postItemsStart(CREATE_TASK_BILLS_START, options));

  const createTaskBillsAction = yield spawn(postItemsResource, 'discussions/create_bills', {
    success: CREATE_TASK_BILLS_SUCCESS,
    error: CREATE_TASK_BILLS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(createTaskBillsAction);
}

export default function* watchCreateTaskBills() {
  yield takeEvery(CREATE_TASK_BILLS, createTaskBills);
}
