import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  DUPLICATE_TASKS,
  DUPLICATE_TASKS_START,
  DUPLICATE_TASKS_SUCCESS,
  DUPLICATE_TASKS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* duplicateTasks({ values, options, cb }) {
  yield put(postItemsStart(DUPLICATE_TASKS_START, options));

  return yield spawn(postItemsResource, 'discussions/duplicate_to_task', {
    success: DUPLICATE_TASKS_SUCCESS,
    error: DUPLICATE_TASKS_ERROR
  }, { values, options, cb });
}

export default function* watchDuplicateTasks() {
  yield takeEvery(DUPLICATE_TASKS, duplicateTasks);
}
