import { updateState } from '../../../utils';

export function clearMessageModalValues(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    itemErrorMessage: null,
    itemCreateErrorMessage: null,
    itemUpdateErrorMessage: null,
    itemRemoveErrorMessage: null
  }, options);
}
