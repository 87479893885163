import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const UserDeletesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_user_deletes" */ './pages/UserDeletesIndexPage/UserDeletesIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/user_deletes" component={UserDeletesIndexPage} />
  </Fragment>
);
