import { addSubscriptions } from './index';

function fetchShowData(props, options, id, params, cb = null) {
  return props[options.fetchItem](id, params, ({ item }) => {
    addSubscriptions(props, options, [item]);
    cb && cb();
  });
}

export default fetchShowData;
