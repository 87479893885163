import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_USER,
  TOGGLE_SELECTED_USER_START,
  TOGGLE_SELECTED_USER_SUCCESS,
  TOGGLE_SELECTED_USER_ERROR
} from '../UserConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedUserStart(id) {
  return {
    type: TOGGLE_SELECTED_USER_START,
    id
  };
}

export function toggleSelectedUserSuccess(id) {
  return {
    type: TOGGLE_SELECTED_USER_SUCCESS,
    id
  };
}

export function toggleSelectedUserError(id, error) {
  return {
    type: TOGGLE_SELECTED_USER_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedUserRequest(userId) {
  return apiRequest.post(`users/${userId}/toggle_selected`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedUserResource({ userId, cb }) {
  try {
    yield call(toggleSelectedUserRequest, userId);
    yield put(toggleSelectedUserSuccess(userId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedUserError(userId, error));
  }
}

function* toggleSelectedUser({ userId, cb }) {
  yield put(toggleSelectedUserStart(userId));

  return yield spawn(toggleSelectedUserResource, { userId, cb });
}

export default function* watchToggleSelectedUser() {
  yield takeEvery(TOGGLE_SELECTED_USER, toggleSelectedUser);
}
