import { takeEvery, put } from 'redux-saga/effects';

import { DOWNLOADS_CHANNEL_CREATED } from '../DownloadConstants';

import { setDownloadsBoxOpened } from '../DownloadActions';
import { subscribeToDownloadsChannel } from '../DownloadSubscriptions';

function* downloadsChannelCreated({ data }) {
  yield put(subscribeToDownloadsChannel('updated', data.id));
  yield put(subscribeToDownloadsChannel('destroyed', data.id));

  if (window.downloadNanoId === data.device_nano_id) {
    yield put(setDownloadsBoxOpened(true));
  }
}

export default function* watchDownloadsChannelCreated() {
  yield takeEvery(DOWNLOADS_CHANNEL_CREATED, downloadsChannelCreated);
}
