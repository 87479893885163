function flattenMessages(
  nestedMessages: { [t: string]: string | {} },
  prefix = ''
): { [t: string]: string | {} } | null {
  return Object.keys(nestedMessages).reduce(
    (messages: { [t: string]: string | {} }, key: string) => {
      const value: string | {} = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    },
    {}
  );
}

export default flattenMessages;
