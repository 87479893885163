import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ACCEPT_TASK,
  ACCEPT_TASK_START,
  ACCEPT_TASK_SUCCESS,
  ACCEPT_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* acceptTask({ id, values, options, cb }) {
  yield put(postItemStart(ACCEPT_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/accept`, {
    success: ACCEPT_TASK_SUCCESS,
    error: ACCEPT_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchAcceptTask() {
  yield takeEvery(ACCEPT_TASK, acceptTask);
}
