import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  PAY_BILL_FROM_ACCOUNT,
  PAY_BILL_FROM_ACCOUNT_START,
  PAY_BILL_FROM_ACCOUNT_SUCCESS,
  PAY_BILL_FROM_ACCOUNT_ERROR
} from '../BillConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* payBillFromAccount({ id, options, cb }) {
  yield put(postItemStart(PAY_BILL_FROM_ACCOUNT_START, id, options));

  const payBillFromAccountAction = yield spawn(postItemResource, `bills/${id}/pay_from_account`, {
    success: PAY_BILL_FROM_ACCOUNT_SUCCESS,
    error: PAY_BILL_FROM_ACCOUNT_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(payBillFromAccountAction);
}

export default function* watchPayBillFromAccount() {
  yield takeEvery(PAY_BILL_FROM_ACCOUNT, payBillFromAccount);
}
