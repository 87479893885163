import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_WORKER_DOCUMENTS,
  DOWNLOAD_WORKER_DOCUMENTS_START,
  DOWNLOAD_WORKER_DOCUMENTS_SUCCESS,
  DOWNLOAD_WORKER_DOCUMENTS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadWorkerDocuments({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_WORKER_DOCUMENTS_START, id, options));

  const downloadWorkerDocumentsAction = yield spawn(
    postItemResource,
    `users/${id}/worker_documents`,
    {
      success: DOWNLOAD_WORKER_DOCUMENTS_SUCCESS,
      error: DOWNLOAD_WORKER_DOCUMENTS_ERROR
    },
    {
      id,
      values: { ...values, device_nano_id: window.downloadNanoId },
      options: { ...options, pure: true },
      cb
    }
  );
  yield take(LOCATION_CHANGE);
  yield cancel(downloadWorkerDocumentsAction);
}

export default function* watchDownloadWorkerDocuments() {
  yield takeEvery(DOWNLOAD_WORKER_DOCUMENTS, downloadWorkerDocuments);
}
