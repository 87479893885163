import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PROJECT_PROFILE,
  REMOVE_PROJECT_PROFILE_START,
  REMOVE_PROJECT_PROFILE_SUCCESS,
  REMOVE_PROJECT_PROFILE_ERROR
} from '../ProjectProfileConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeProjectProfile({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_PROJECT_PROFILE_START, id, options));

  return yield spawn(removeItemResource, 'task_profiles', {
    success: REMOVE_PROJECT_PROFILE_SUCCESS,
    error: REMOVE_PROJECT_PROFILE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveProjectProfile() {
  yield takeEvery(REMOVE_PROJECT_PROFILE, removeProjectProfile);
}
