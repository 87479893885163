export const BASE_TITLE = process.env.REACT_APP_BASE_TITLE;

export const HOST = process.env.REACT_APP_HOST;
export const HOST_BASENAME = process.env.REACT_APP_HOST_BASENAME;
export const CRM_NAME = process.env.REACT_APP_CRM_NAME;
export const API_URI = process.env.REACT_APP_API_URI;
export const FILES_API_URI = process.env.REACT_APP_FILES_API_URI;
export const WS_API_URI = process.env.REACT_APP_WS_API_URI;

export const USE_CONSOLE = process.env.REACT_APP_USE_CONSOLE;

export const FINANCE_API_URI = process.env.REACT_APP_FINANCE_API_URI;
export const MARKETING_API_URL = process.env.REACT_APP_MARKETING_API_URL;

export const PAYMENT_POLICY_PAGE_ID =
  process.env.REACT_APP_PAYMENT_POLICY_PAGE_ID;
export const REFUND_POLICY_PAGE_ID =
  process.env.REACT_APP_REFUND_POLICY_PAGE_ID;
export const PRIVACY_POLICY_PAGE_ID =
  process.env.REACT_APP_PRIVACY_POLICY_PAGE_ID;
export const MARKDOWN_HELP_PAGE_ID =
  process.env.REACT_APP_MARKDOWN_HELP_PAGE_ID;
export const NON_DISCLOSURE_AGREEMENT_PAGE_ID =
  process.env.REACT_APP_NON_DISCLOSURE_AGREEMENT_PAGE_ID;
export const SERVICE_AGREEMENT_PAGE_ID =
  process.env.REACT_APP_SERVICE_AGREEMENT_PAGE_ID;
export const HELP_PAGE_ID = process.env.REACT_APP_HELP_PAGE_ID;
export const WELCOME_PAGE_ID = process.env.REACT_APP_WELCOME_PAGE_ID;
export const PARTNERSHIP_AGREEMENT_PAGE_ID =
  process.env.REACT_APP_PARTNERSHIP_AGREEMENT_PAGE_ID;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const GTM_CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID;
export const GTM_TRACKING_ID = process.env.REACT_APP_GTM_TRACKING_ID;

export const REVENUE_SHARE_BATCH_IMPORT_TEMPLATE_URL = `${process.env.PUBLIC_URL}/files/revenue_share_batch_import_template.csv`;
export const TRANSACTIONS_BATCH_IMPORT_TEMPLATE_URL = `${process.env.PUBLIC_URL}/files/transactions_batch_import_template.csv`;
export const PAYONEER_IDS_BATCH_IMPORT_TEMPLATE_URL = `${process.env.PUBLIC_URL}/files/payoneer_ids_batch_import_template.csv`;
export const PAYONEER_IDS_PE_BATCH_IMPORT_TEMPLATE_URL = `${process.env.PUBLIC_URL}/files/payoneer_ids_pe_batch_import_template.csv`;

export const COZY_FAVICON_URL = `${process.env.PUBLIC_URL}/cozy-favicon.ico`;
export const MAIN_FAVICON_URL = `${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_CRM_NAME}/main-favicon.ico`;
