import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_FINANCE_TAG,
  FETCH_FINANCE_TAG_START,
  FETCH_FINANCE_TAG_SUCCESS,
  FETCH_FINANCE_TAG_ERROR
} from '../FinanceTagConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchFinanceTag({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_FINANCE_TAG_START, id, options));

  const fetchFinanceTagAction = yield spawn(fetchItemResource, 'finance_user_tags', {
    success: FETCH_FINANCE_TAG_SUCCESS,
    error: FETCH_FINANCE_TAG_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchFinanceTagAction);
}

export default function* watchFetchFinanceTag() {
  yield takeEvery(FETCH_FINANCE_TAG, fetchFinanceTag);
}
