import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  createItemStart, createItemSuccess, createItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

import { reportsChannelUpdated } from './reportsChannelUpdated';

export default createReducer(defaultState, {
  FETCH_REPORTS_START: fetchItemsStart,
  FETCH_REPORTS_SUCCESS: fetchItemsSuccess,
  FETCH_REPORTS_ERROR: fetchItemsError,
  CREATE_REPORT_START: createItemStart,
  CREATE_REPORT_SUCCESS: createItemSuccess,
  CREATE_REPORT_ERROR: createItemError,
  REMOVE_REPORT_START: removeItemStart,
  REMOVE_REPORT_SUCCESS: removeItemSuccess,
  REMOVE_REPORT_ERROR: removeItemError,
  REPORTS_CHANNEL_UPDATED: reportsChannelUpdated
});
