export const FETCH_TRANSACTION_TAGS = 'FETCH_TRANSACTION_TAGS';
export const FETCH_TRANSACTION_TAGS_START = 'FETCH_TRANSACTION_TAGS_START';
export const FETCH_TRANSACTION_TAGS_SUCCESS = 'FETCH_TRANSACTION_TAGS_SUCCESS';
export const FETCH_TRANSACTION_TAGS_ERROR = 'FETCH_TRANSACTION_TAGS_ERROR';

export const FETCH_TRANSACTION_TAG = 'FETCH_TRANSACTION_TAG';
export const FETCH_TRANSACTION_TAG_START = 'FETCH_TRANSACTION_TAG_START';
export const FETCH_TRANSACTION_TAG_SUCCESS = 'FETCH_TRANSACTION_TAG_SUCCESS';
export const FETCH_TRANSACTION_TAG_ERROR = 'FETCH_TRANSACTION_TAG_ERROR';

export const CLEAR_TRANSACTION_TAGS_FILTERS_START = 'CLEAR_TRANSACTION_TAGS_FILTERS_START';
export const CLEAR_TRANSACTION_TAGS_FILTERS_SUCCESS = 'CLEAR_TRANSACTION_TAGS_FILTERS_SUCCESS';

export const CREATE_TRANSACTION_TAG = 'CREATE_TRANSACTION_TAG';
export const CREATE_TRANSACTION_TAG_START = 'CREATE_TRANSACTION_TAG_START';
export const CREATE_TRANSACTION_TAG_SUCCESS = 'CREATE_TRANSACTION_TAG_SUCCESS';
export const CREATE_TRANSACTION_TAG_ERROR = 'CREATE_TRANSACTION_TAG_ERROR';

export const UPDATE_TRANSACTION_TAG = 'UPDATE_TRANSACTION_TAG';
export const UPDATE_TRANSACTION_TAG_START = 'UPDATE_TRANSACTION_TAG_START';
export const UPDATE_TRANSACTION_TAG_SUCCESS = 'UPDATE_TRANSACTION_TAG_SUCCESS';
export const UPDATE_TRANSACTION_TAG_ERROR = 'UPDATE_TRANSACTION_TAG_ERROR';

export const REMOVE_TRANSACTION_TAG = 'REMOVE_TRANSACTION_TAG';
export const REMOVE_TRANSACTION_TAG_START = 'REMOVE_TRANSACTION_TAG_START';
export const REMOVE_TRANSACTION_TAG_SUCCESS = 'REMOVE_TRANSACTION_TAG_SUCCESS';
export const REMOVE_TRANSACTION_TAG_ERROR = 'REMOVE_TRANSACTION_TAG_ERROR';

export const SELECT_TRANSACTION_TAGS_FORM = 'SELECT_TRANSACTION_TAGS_FORM';
