import get from 'lodash/get';
import { CurrentUser } from '../../../types';

import { CozyStorage } from '../CozyStorage';

interface PlainUserData {
  google_tag_user_id?: string;
}

interface IsUserCozyOptions {
  currentUser?: CurrentUser;
  plainUserData?: PlainUserData;
}

function isUserCozy({
  currentUser,
  plainUserData
}: IsUserCozyOptions = {}): boolean {
  const googleTagUserId = currentUser
    ? currentUser.get('google_tag_user_id')
    : get(plainUserData, 'google_tag_user_id');

  if (googleTagUserId) {
    const isGoogleTagCozy = /^cozy/.test(googleTagUserId);
    return isGoogleTagCozy;
  } else {
    const isStoredCozy = CozyStorage.getIsUserCozy();
    return isStoredCozy;
  }
}

export default isUserCozy;
