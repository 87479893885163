import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeTemplatesSetSelectedTemplatesStart(state, { id, options = {} }) {
  const currentTemplatesSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentTemplatesSet,
      selected_scenes: replaceById(get(currentTemplatesSet, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: null,
        loading: true
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, templatesSet => ({
      ...templatesSet,
      selected_scenes: replaceById(get(templatesSet, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeTemplatesSetSelectedTemplatesSuccess(state, { id, options = {} }) {
  const currentTemplatesSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentTemplatesSet,
      selected_scenes: removeById(get(currentTemplatesSet, 'selected_scenes', []), id)
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, templatesSet => ({
      ...templatesSet,
      selected_scenes: removeById(get(templatesSet, 'selected_scenes', []), id)
    }))
  }, options);
}

export function removeTemplatesSetSelectedTemplatesError(state, { id, errorMessage, options = {} }) {
  const currentTemplatesSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentTemplatesSet,
      selected_scenes: replaceById(get(currentTemplatesSet, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: errorMessage,
        loading: false
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, templatesSet => ({
      ...templatesSet,
      selected_scenes: replaceById(get(templatesSet, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
