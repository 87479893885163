import { POST_ITEM_INITIALIZE } from '../../constants';

import { PostItemRequestAction } from '../../reducers/postItemRequestReducer';

export default function postItemInitialize<ItemType>(): PostItemRequestAction<
  ItemType
> {
  return {
    type: POST_ITEM_INITIALIZE
  };
}
