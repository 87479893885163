import React, { memo, ReactNode } from 'react';
import cl from 'classnames';

import { translate as t } from '../../../utils/ts/translate';

interface AlertMessageProps {
  color?: string;
  i18nMessage?: string | null;
  message?: string | ReactNode | null;
  rowClassName?: string;
  alertClassName?: string;
}

function AlertMessage({
  color = 'danger',
  i18nMessage = null,
  message = null,
  rowClassName,
  alertClassName
}: AlertMessageProps) {
  return i18nMessage || message ? (
    <div className={cl('row', rowClassName)}>
      <div className="col-md-12">
        <div className={cl('alert', `alert-${color}`, alertClassName)}>
          {i18nMessage ? t(i18nMessage) : message}
        </div>
      </div>
    </div>
  ) : null;
}

export default memo(AlertMessage);
