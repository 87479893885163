import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_FINANCE_TAG,
  CREATE_FINANCE_TAG_START,
  CREATE_FINANCE_TAG_SUCCESS,
  CREATE_FINANCE_TAG_ERROR
} from '../FinanceTagConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createFinanceTag({ values, options, cb }) {
  yield put(createItemStart(CREATE_FINANCE_TAG_START, options));

  return yield spawn(createItemResource, 'finance_user_tags', {
    success: CREATE_FINANCE_TAG_SUCCESS,
    error: CREATE_FINANCE_TAG_ERROR
  }, { values, options, cb });
}

export default function* watchCreateFinanceTag() {
  yield takeEvery(CREATE_FINANCE_TAG, createFinanceTag);
}
