export default {
  'select_category...': 'Select material category...',
  add_new_to_the_library: 'Add new material to the library',
  add_plural: 'Add materials',
  add: 'Add a material',
  category: 'Material category',
  details: 'Material details',
  edit: 'Edit material',
  enter_title: 'Enter material title',
  favorite: 'Favorite materials',
  file: 'Material file',
  filter: 'Filter materials',
  image: 'Material image',
  latest: 'Latest materials',
  library: 'Materials Library',
  model: 'Material 3d model',
  my: 'My Materials',
  new: 'New material',
  no: 'No materials',
  own: 'Own Materials',
  plural_lower_case: 'materials',
  plural: 'Materials',
  selected: 'Selected materials',
  singular: 'Material',
  stock: 'Materials Stock',
  title: 'Material title'
};
