import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UNREAD_MESSAGE,
  UNREAD_MESSAGE_START,
  UNREAD_MESSAGE_SUCCESS,
  UNREAD_MESSAGE_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* unreadMessage({ id, options, cb }) {
  yield put(postItemStart(UNREAD_MESSAGE_START, id, options));

  return yield spawn(postItemResource, `discussion_messages/${id}/unread`, {
    success: UNREAD_MESSAGE_SUCCESS,
    error: UNREAD_MESSAGE_ERROR
  }, { id, options, cb });
}

export default function* watchUnreadMessage() {
  yield takeEvery(UNREAD_MESSAGE, unreadMessage);
}
