import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ContactForm } from '../../forms/ContactForm';
import { ModalButton } from '../../../../../helpers/ModalButton';
import { CONTACT_FORM } from '../../../ContactConstants';

class ContactModal extends PureComponent {
  static propTypes = {
    modalTitle: PropTypes.node,
    addClass: PropTypes.string,
    className: PropTypes.string,
    buttonText: PropTypes.node,
    submitText: PropTypes.node,
    modalIcon: PropTypes.string,
    buttonIcon: PropTypes.string,
    color: PropTypes.string,
    buttonSize: PropTypes.string,
    modalSize: PropTypes.string,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    modalTitle: null,
    className: null,
    addClass: null,
    buttonText: null,
    submitText: null,
    modalIcon: null,
    buttonIcon: null,
    color: null,
    buttonSize: null,
    modalSize: null,
    isLoading: false,
    errorMessage: null,
    afterSubmit: null,
    onClose: null
  };

  renderEditFieldForm = ({ hideModal }) => {
    const { isLoading, errorMessage, onSubmit, afterSubmit } = this.props;

    return (
      <ContactForm
        withoutSubmit
        form={CONTACT_FORM}
        isLoading={isLoading}
        errorMessage={errorMessage}
        onSubmit={onSubmit(
          null,
          () => {
            hideModal();
            afterSubmit && afterSubmit();
          },
          CONTACT_FORM)}
      />
    );
  };

  render() {
    const { className, addClass, buttonText, submitText, buttonIcon, modalIcon, color, buttonSize, modalSize, isLoading, modalTitle, onClose } = this.props;

    return (
      <ModalButton
        withoutFooter
        func
        className={className}
        addClass={addClass}
        icon={buttonIcon}
        size={buttonSize}
        color={color}
        form={CONTACT_FORM}
        modalTitle={modalTitle}
        modalIcon={modalIcon}
        modalSize={modalSize}
        disabled={isLoading}
        isLoading={isLoading}
        submitText={submitText}
        buttonText={buttonText}
        onClose={onClose}
      >
        {this.renderEditFieldForm}
      </ModalButton>
    );
  }
}

export default ContactModal;
