import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  BLOCK_USER_ACCOUNT,
  BLOCK_USER_ACCOUNT_START,
  BLOCK_USER_ACCOUNT_SUCCESS,
  BLOCK_USER_ACCOUNT_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* blockUserAccount({ id, options, cb }) {
  yield put(postItemStart(BLOCK_USER_ACCOUNT_START, options));

  return yield spawn(postItemResource, `users/${id}/block`, {
    success: BLOCK_USER_ACCOUNT_SUCCESS,
    error: BLOCK_USER_ACCOUNT_ERROR
  }, { id, options, cb });
}

export default function* watchBlockUserAccount() {
  yield takeEvery(BLOCK_USER_ACCOUNT, blockUserAccount);
}
