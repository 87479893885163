import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REVISION_TASK,
  REVISION_TASK_START,
  REVISION_TASK_SUCCESS,
  REVISION_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* revisionTask({ id, values, options, cb }) {
  yield put(postItemStart(REVISION_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/revision`, {
    success: REVISION_TASK_SUCCESS,
    error: REVISION_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchRevisionTask() {
  yield takeEvery(REVISION_TASK, revisionTask);
}
