import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_FINANCE_TAG,
  REMOVE_FINANCE_TAG_START,
  REMOVE_FINANCE_TAG_SUCCESS,
  REMOVE_FINANCE_TAG_ERROR
} from '../FinanceTagConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeFinanceTag({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_FINANCE_TAG_START, id, options));

  return yield spawn(removeItemResource, 'finance_user_tags', {
    success: REMOVE_FINANCE_TAG_SUCCESS,
    error: REMOVE_FINANCE_TAG_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveFinanceTag() {
  yield takeEvery(REMOVE_FINANCE_TAG, removeFinanceTag);
}
