import { SOCKET_UNSUBSCRIBE_FROM_DOWNLOADS_CHANNEL } from '../DownloadConstants';

export default function unsubscribeFromDownloadsChannel(action, downloadId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_UNSUBSCRIBE_FROM_DOWNLOADS_CHANNEL,
    channel: 'Archi::Crm::DownloadsChannel',
    command: 'unsubscribe',
    action,
    params: { download_id: downloadId, ...params }
  });
}
