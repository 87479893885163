import React from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const AdminDashboardPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_dashboard" */ './pages/AdminDashboardPage/AdminDashboardPage'
    )
});

export default <Route exact path="/admin" component={AdminDashboardPage} />;
