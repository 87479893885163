import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PAGE,
  UPDATE_PAGE_START,
  UPDATE_PAGE_SUCCESS,
  UPDATE_PAGE_ERROR
} from '../PageConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updatePage({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_PAGE_START, id, options));

  return yield spawn(updateItemResource, 'pages', {
    success: UPDATE_PAGE_SUCCESS,
    error: UPDATE_PAGE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdatePage() {
  yield takeEvery(UPDATE_PAGE, updatePage);
}
