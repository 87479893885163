import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_USER,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR
} from '../UserConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchUser({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_USER_START, id, options));

  const fetchUserAction = yield spawn(fetchItemResource, 'users', {
    success: FETCH_USER_SUCCESS,
    error: FETCH_USER_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchUserAction);
}

export default function* watchFetchUser() {
  yield takeEvery(FETCH_USER, fetchUser);
}
