import Promise from 'bluebird';

import { flattenMessages } from '../../utils/flattenMessages';

export default function loadLocale(locale) {
  switch (locale) {
    case 'ru':
      return import(
        /* webpackChunkName: "locales-ru" */ '../../locales/ru'
      ).then(({ default: messages }) =>
        Promise.resolve(flattenMessages(messages[locale]))
      );
    case 'uk':
      return import(
        /* webpackChunkName: "locales-uk" */ '../../locales/uk'
      ).then(({ default: messages }) =>
        Promise.resolve(flattenMessages(messages[locale]))
      );
    default:
      return import(
        /* webpackChunkName: "locales-en" */ '../../locales/en'
      ).then(({ default: messages }) =>
        Promise.resolve(flattenMessages(messages[locale]))
      );
  }

  // return import(`../locales/${locale}`).then(({
  //   default: messages
  // }) => Promise.resolve(messages));
}
