import dashboard from './dashboard/routes';
import bills from './bills/routes';
import teams from './ts/teams/routes';
import products from './products/routes';
import projects from './projects/routes';
import tasks from './tasks/routes';
import templates from './templates/routes';
import materials from './materials/routes';
import messages from './messages/routes';
import users from './users/routes';
import results from './results/routes';
import resultsNew from './ts/results/routes';
import library from './ts/library/routes';

export default [
  dashboard,
  bills,
  teams,
  products,
  projects,
  tasks,
  templates,
  materials,
  messages,
  users,
  results,
  resultsNew,
  library
];
