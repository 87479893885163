import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  REMOVE_TASK_SOURCE_FILES,
  REMOVE_TASK_SOURCE_FILES_START,
  REMOVE_TASK_SOURCE_FILES_SUCCESS,
  REMOVE_TASK_SOURCE_FILES_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskSourceFiles({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_SOURCE_FILES_START, id, options));

  const removeTaskSourceFilesAction = yield spawn(postItemResource, `discussions/${id}/remove_source_files`, {
    success: REMOVE_TASK_SOURCE_FILES_SUCCESS,
    error: REMOVE_TASK_SOURCE_FILES_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskSourceFilesAction);
}

export default function* watchRemoveTaskSourceFiles() {
  yield takeEvery(REMOVE_TASK_SOURCE_FILES, removeTaskSourceFiles);
}
