import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  updateItemStart, updateItemSuccess, updateItemError,
  itemsChannelUpdated
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),

  FETCH_CATEGORIES_START: fetchItemsStart,
  FETCH_CATEGORIES_SUCCESS: fetchItemsSuccess,
  FETCH_CATEGORIES_ERROR: fetchItemsError,

  FETCH_CATEGORY_START: fetchItemStart,
  FETCH_CATEGORY_SUCCESS: fetchItemSuccess,
  FETCH_CATEGORY_ERROR: fetchItemError,

  UPDATE_CATEGORY_START: updateItemStart,
  UPDATE_CATEGORY_SUCCESS: updateItemSuccess,
  UPDATE_CATEGORY_ERROR: updateItemError,

  CATEGORIES_CHANNEL_UPDATED: itemsChannelUpdated
});
