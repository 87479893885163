import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPLOAD_FILE_ATTACHMENTS,
  UPLOAD_FILE_ATTACHMENTS_START,
  UPLOAD_FILE_ATTACHMENT_START,
  UPLOAD_FILE_ATTACHMENTS_SUCCESS,
  UPLOAD_FILE_ATTACHMENT_SUCCESS,
  UPLOAD_FILE_ATTACHMENT_ERROR,
  UPLOAD_FILE_ATTACHMENTS_ERROR
} from '../FileAttachmentConstants';

import { uploadItemsResource } from '../../common/sagas';

function* uploadFileAttachments({ files, options, cb }) {
  const uploadFileAttachmentsAction = yield spawn(uploadItemsResource, 'file_attachments', {
    start: UPLOAD_FILE_ATTACHMENTS_START,
    eachStart: UPLOAD_FILE_ATTACHMENT_START,
    success: UPLOAD_FILE_ATTACHMENTS_SUCCESS,
    eachSuccess: UPLOAD_FILE_ATTACHMENT_SUCCESS,
    eachError: UPLOAD_FILE_ATTACHMENT_ERROR,
    error: UPLOAD_FILE_ATTACHMENTS_ERROR,
    param: 'file_attachment'
  }, { files, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(uploadFileAttachmentsAction);
}

export default function* watchUploadFileAttachments() {
  yield takeEvery(UPLOAD_FILE_ATTACHMENTS, uploadFileAttachments);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   UPLOAD_FILE_ATTACHMENTS,
//   UPLOAD_FILE_ATTACHMENTS_START,
//   UPLOAD_FILE_ATTACHMENTS_SUCCESS,
//   UPLOAD_FILE_ATTACHMENTS_ERROR
// } from '../FileAttachmentConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function uploadFileAttachmentsStart() {
//   return {
//     type: UPLOAD_FILE_ATTACHMENTS_START
//   };
// }
//
// export function uploadFileAttachmentsSuccess(items) {
//   return {
//     type: UPLOAD_FILE_ATTACHMENTS_SUCCESS,
//     items
//   };
// }
//
// export function uploadFileAttachmentsError(error) {
//   return {
//     type: UPLOAD_FILE_ATTACHMENTS_ERROR,
//     errorMessage: parseError(error),
//     error
//   };
// }
//
// function uploadFileAttachmentRequest(fileAttachment) {
//   return apiRequest.post('file_attachments', fileAttachment).then(({ data: { data: item } }) => ({ item }));
// }
//
// function* uploadFileAttachmentsResource({ files, options: { form }, cb }) {
//   try {
//     const items = [];
//
//     for (let i = 0; i < files.length; i++) {
//       const { onSuccess } = files[i];
//
//       const { item } = yield call(uploadFileAttachmentRequest, files[i]);
//
//       onSuccess && onSuccess(item);
//
//       items.push(item);
//     }
//
//     yield put(uploadFileAttachmentsSuccess(items));
//     cb && cb(items);
//   } catch (error) {
//     yield put(uploadFileAttachmentsError(error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* uploadFileAttachments({ files, options, cb }) {
//   yield put(uploadFileAttachmentsStart());
//
//   return yield spawn(uploadFileAttachmentsResource, { files, options, cb });
// }
//
// export default function* watchUploadFileAttachments() {
//   yield takeEvery(UPLOAD_FILE_ATTACHMENTS, uploadFileAttachments);
// }
