import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateIntl as updateIntlAction } from 'react-intl-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import pure from 'recompose/pure';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import ScrollToTop from 'react-scroll-up';
import cl from 'classnames';
import get from 'lodash/get';
import Favicon from 'react-favicon';
import * as Sentry from '@sentry/browser';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { Footer } from '../../components/ts/Footer';
import { CozyHeader } from '../../components/CozyHeader';

import { Loading } from '../../../helpers';
import { pushToAction } from '../../../utils/pushToAction';
import { loadAllLocales } from '../../../utils/ts/loadAllLocales';
import { profileLocaleInStorage } from '../../../utils/ts/profileLocaleInStorage';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';

import { cozy } from '../../routes/cozy';
import { scrollToTopStyle } from '../../AppConstants';
import { COZY_FAVICON_URL } from '../../../config';

const ErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "errors" */ '../../components/ErrorPage/ErrorPage'
    )
});

function CozyAppContainer({
  pathname,
  authFetched,
  isSidebarHidden,
  isSidebarMobile,
  toggleSidebarHidden,
  toggleSidebarMobile,
  onLogout
}) {
  return (
    <Fragment>
      <Favicon url={COZY_FAVICON_URL} />
      <CozyHeader
        pathname={pathname}
        authFetched={authFetched}
        onToggleSidebarHidden={toggleSidebarHidden}
        onToggleSidebarMobile={toggleSidebarMobile}
        onLogout={onLogout}
      />
      <div
        className={cl('page-content', {
          'sidebar-hidden': isSidebarHidden,
          'sidebar-mobile-component': isSidebarMobile
        })}
      >
        <div className="content-wrapper">
          <div className="content content-boxed">
            <Loading loaded={authFetched}>
              <Switch>
                {cozy}
                <Route
                  key="error"
                  render={props => <ErrorPage statusCode={404} {...props} />}
                />
              </Switch>
              <ScrollToTop showUnder={160} style={scrollToTopStyle}>
                <span className="icon-arrow-up13 icon-2x" />
              </ScrollToTop>
            </Loading>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

CozyAppContainer.propTypes = {
  pathname: PropTypes.string.isRequired,
  authFetched: PropTypes.bool.isRequired,
  isSidebarHidden: PropTypes.bool.isRequired,
  isSidebarMobile: PropTypes.bool.isRequired,
  toggleSidebarHidden: PropTypes.func.isRequired,
  toggleSidebarMobile: PropTypes.func.isRequired,
  updateIntl: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  pushTo: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ app: { pathname } }) => ({ pathname }),
    {
      updateIntl: updateIntlAction,
      pushTo: pushToAction
    }
  ),
  withCurrentUser({
    fields: ['authFetched'],
    actions: ['validateToken', 'logoutUser']
  }),
  lifecycle({
    componentDidMount() {
      const {
        currentUser,
        authFetched,
        validateToken,
        logoutUser,
        updateIntl,
        pushTo
      } = this.props;

      if (authFetched) {
        if (process.env.NODE_ENV === 'production') {
          Sentry.configureScope(scope => {
            scope.setUser({ id: currentUser.get('id') });
          });
        }

        const locale = currentUser.get('locale') || 'en';

        return loadAllLocales(locale).then(messages => {
          updateIntl({
            locale,
            messages
          });

          profileLocaleInStorage.setLocale(locale);
        });
      } else {
        validateToken().then(({ currentUser: fetchedUser }) => {
          if (fetchedUser) {
            if (process.env.NODE_ENV === 'production') {
              Sentry.configureScope(scope => {
                scope.setUser({ id: get(fetchedUser, 'id') });
              });
            }

            const locale = get(fetchedUser, 'locale') || 'en';

            return loadAllLocales(locale).then(messages => {
              updateIntl({
                locale,
                messages
              });

              profileLocaleInStorage.setLocale(locale);
            });
          }

          logoutUser();
          return pushTo('auth/login');
        });
      }
    }
  }),
  withState('isSidebarHidden', 'setIsSidebarHidden', false),
  withState('isSidebarMobile', 'setIsSidebarMobile', false),
  withHandlers({
    toggleSidebarHidden: ({ isSidebarHidden, setIsSidebarHidden }) => () =>
      setIsSidebarHidden(!isSidebarHidden),
    toggleSidebarMobile: ({ isSidebarMobile, setIsSidebarMobile }) => () =>
      setIsSidebarMobile(!isSidebarMobile),
    onLogout: ({ logoutUser, pushTo }) => () => {
      logoutUser();
      pushTo('auth/login');
    }
  }),
  pure
)(CozyAppContainer);
