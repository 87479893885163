import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import cl from 'classnames';

import { withCurrentUser } from '../../../common/HOCs/withCurrentUser';
import { UserLink } from '../../../users/components/UserLink';
import { ArchiMarkdown } from '../../../../helpers/ArchiMarkdown';
import { renderDateTime } from '../../../../utils/renderDateTime';

/**
 * @return {null}
 */
function ForwardedMessageBody({ className, item, currentUser }) {
  if (!item) return null;

  const messageBody = get(item, 'body', '').replace(/\n/g, '\n>');

  const messageDate = `<p class="text-muted font-size-sm">${renderDateTime(get(item, 'created_at', null), currentUser.get('locale'))}</p>`;

  const forwardedMessageBody = `\n\n> ${messageBody}\n\n> ${messageDate}`;

  return (
    <Fragment>
      <div className="markdown-message">
        <blockquote className="mb-0">
          <UserLink user={get(item, 'user')} className="font-weight-semibold" />
        </blockquote>
      </div>
      <ArchiMarkdown
        id={get(item, 'id')}
        className={cl(className, 'markdown-message text-break-word', { 'archi-incoming-message-exist': get(item, 'incoming_messages_count') > 0 })}
        source={forwardedMessageBody}
        fromEmail={get(item, 'incoming_messages_count') > 0}
      />
    </Fragment>
  );
}

ForwardedMessageBody.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({ id: PropTypes.number })
};

ForwardedMessageBody.defaultProps = {
  className: null,
  item: null
};

export default withCurrentUser()(ForwardedMessageBody);
