import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TAGS,
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR
} from '../TagConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTags(params) {
  yield put(fetchItemsStart(FETCH_TAGS_START, params));

  const fetchTagsAction = yield spawn(fetchItemsResource, 'tags', {
    success: FETCH_TAGS_SUCCESS,
    error: FETCH_TAGS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTagsAction);
}

export default function* watchFetchTags() {
  yield takeEvery(FETCH_TAGS, fetchTags);
}
