import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const BillRefillsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_bill_refills" */ './pages/BillRefillsIndexPage/BillRefillsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/bill_refills" component={BillRefillsIndexPage} />
  </Fragment>
);
