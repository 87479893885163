import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TODO_ITEM,
  CREATE_TODO_ITEM_START,
  CREATE_TODO_ITEM_SUCCESS,
  CREATE_TODO_ITEM_ERROR
} from '../TodoItemConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTodoItem({ values, options, cb }) {
  yield put(createItemStart(CREATE_TODO_ITEM_START, options));

  return yield spawn(createItemResource, 'todo_items', {
    success: CREATE_TODO_ITEM_SUCCESS,
    error: CREATE_TODO_ITEM_ERROR
  }, { values, options, cb });
}

export default function* watchCreateTodoItem() {
  yield takeEvery(CREATE_TODO_ITEM, createTodoItem);
}
