import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_IMAGE,
  UPDATE_IMAGE_START,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_ERROR
} from '../ImageConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateImage({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_IMAGE_START, id, options));

  return yield spawn(updateItemResource, 'images', {
    success: UPDATE_IMAGE_SUCCESS,
    error: UPDATE_IMAGE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateImage() {
  yield takeEvery(UPDATE_IMAGE, updateImage);
}
