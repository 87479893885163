export const REMOVE_CLIENT_FILE = 'REMOVE_CLIENT_FILE';
export const REMOVE_CLIENT_FILE_START = 'REMOVE_CLIENT_FILE_START';
export const REMOVE_CLIENT_FILE_SUCCESS = 'REMOVE_CLIENT_FILE_SUCCESS';
export const REMOVE_CLIENT_FILE_ERROR = 'REMOVE_CLIENT_FILE_ERROR';

export const UPLOAD_CLIENT_FILES = 'UPLOAD_CLIENT_FILES';
export const UPLOAD_CLIENT_FILES_START = 'UPLOAD_CLIENT_FILES_START';
export const UPLOAD_CLIENT_FILES_SUCCESS = 'UPLOAD_CLIENT_FILES_SUCCESS';
export const UPLOAD_CLIENT_FILES_ERROR = 'UPLOAD_CLIENT_FILES_ERROR';

export const UPLOAD_CLIENT_FILE_START = 'UPLOAD_CLIENT_FILE_START';
export const UPLOAD_CLIENT_FILE_SUCCESS = 'UPLOAD_CLIENT_FILE_SUCCESS';
export const UPLOAD_CLIENT_FILE_ERROR = 'UPLOAD_CLIENT_FILE_ERROR';

export const CLEAR_CLIENT_FILE_MODAL_VALUES = 'CLEAR_CLIENT_FILE_MODAL_VALUES';
