import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_ROLE,
  FETCH_ROLE_START,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_ERROR
} from '../RoleConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchRole({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_ROLE_START, id, options));

  const fetchRoleAction = yield spawn(fetchItemResource, 'roles', {
    success: FETCH_ROLE_SUCCESS,
    error: FETCH_ROLE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRoleAction);
}

export default function* watchFetchRole() {
  yield takeEvery(FETCH_ROLE, fetchRole);
}
