import { READ_MESSAGE } from '../MessageConstants';

// readMessage

export default function readMessage(id, options = {}, cb) {
  return dispatch => dispatch({
    type: READ_MESSAGE,
    id,
    options,
    cb
  });
}
