import { setAuthenticatedUser, authError } from '../AuthActions';
import { apiRequest } from '../../utils/apiRequest';
import get from 'lodash/get';

function setGoogleAnalycsUserId(currentUser) {
  const userID = get(currentUser, 'google_tag_user_id');

  if (window.dataLayer) {
    window.dataLayer.push({
      userId: userID
    });
  }
}

export default function validateToken() {
  return dispatch => apiRequest.authToken()
    .then(({ data: { data: user } }) => {
      setGoogleAnalycsUserId(user);
      return dispatch(setAuthenticatedUser(user));
    })
    .catch(error => dispatch(authError(error)));
}
