import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TASKS,
  FETCH_TASKS_START,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_ERROR
} from '../TaskConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTasks(params) {
  yield put(fetchItemsStart(FETCH_TASKS_START, params));

  const fetchTasksAction = yield spawn(fetchItemsResource, 'discussions', {
    success: FETCH_TASKS_SUCCESS,
    error: FETCH_TASKS_ERROR
  }, params);

  // yield take(FETCH_TASKS_START);
  // yield cancel(fetchTasksAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTasksAction);
}

export default function* watchFetchTasks() {
  yield takeEvery(FETCH_TASKS, fetchTasks);
}
