import { POST_DATA_SUCCESS } from '../../constants';

import { PostDataRequestAction } from '../../reducers/postDataRequestReducer';

export function postDataSuccess<DataType>(
  data: DataType
): PostDataRequestAction<DataType> {
  return {
    type: POST_DATA_SUCCESS,
    data
  };
}
