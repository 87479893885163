import fromPairs from 'lodash/fromPairs';
import compact from 'lodash/compact';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

function filterKeys(fields, values) {
  if (isEmpty(fields)) {
    return {};
  }

  return fromPairs(compact(keys(values).map(key => includes(fields, key) ? [key, values[key]] : null)));
}

export default filterKeys;
