import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_PRODUCT,
  TOGGLE_SELECTED_PRODUCT_START,
  TOGGLE_SELECTED_PRODUCT_SUCCESS,
  TOGGLE_SELECTED_PRODUCT_ERROR
} from '../ProductConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedProductStart(id) {
  return {
    type: TOGGLE_SELECTED_PRODUCT_START,
    id
  };
}

export function toggleSelectedProductSuccess(id) {
  return {
    type: TOGGLE_SELECTED_PRODUCT_SUCCESS,
    id
  };
}

export function toggleSelectedProductError(id, error) {
  return {
    type: TOGGLE_SELECTED_PRODUCT_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedProductRequest(productId) {
  return apiRequest.post(`facilities/${productId}/toggle_selected`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedProductResource({ productId, cb }) {
  try {
    yield call(toggleSelectedProductRequest, productId);
    yield put(toggleSelectedProductSuccess(productId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedProductError(productId, error));
  }
}

function* toggleSelectedProduct({ productId, cb }) {
  yield put(toggleSelectedProductStart(productId));

  return yield spawn(toggleSelectedProductResource, { productId, cb });
}

export default function* watchToggleSelectedProduct() {
  yield takeEvery(TOGGLE_SELECTED_PRODUCT, toggleSelectedProduct);
}
