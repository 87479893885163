import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const AuthControllerRequestsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_auth_controller_requests" */ './pages/AuthControllerRequestsIndexPage/AuthControllerRequestsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/auth_controller_requests"
      component={AuthControllerRequestsIndexPage}
    />
  </Fragment>
);
