export const FETCH_PRODUCTS_SET = 'FETCH_PRODUCTS_SET';
export const FETCH_PRODUCTS_SET_START = 'FETCH_PRODUCTS_SET_START';
export const FETCH_PRODUCTS_SET_SUCCESS = 'FETCH_PRODUCTS_SET_SUCCESS';
export const FETCH_PRODUCTS_SET_ERROR = 'FETCH_PRODUCTS_SET_ERROR';

export const DOWNLOAD_PRODUCTS_SET = 'DOWNLOAD_PRODUCTS_SET';
export const DOWNLOAD_PRODUCTS_SET_START = 'DOWNLOAD_PRODUCTS_SET_START';
export const DOWNLOAD_PRODUCTS_SET_SUCCESS = 'DOWNLOAD_PRODUCTS_SET_SUCCESS';
export const DOWNLOAD_PRODUCTS_SET_ERROR = 'DOWNLOAD_PRODUCTS_SET_ERROR';

export const EMPTY_PRODUCTS_SET = 'EMPTY_PRODUCTS_SET';
export const EMPTY_PRODUCTS_SET_START = 'EMPTY_PRODUCTS_SET_START';
export const EMPTY_PRODUCTS_SET_SUCCESS = 'EMPTY_PRODUCTS_SET_SUCCESS';
export const EMPTY_PRODUCTS_SET_ERROR = 'EMPTY_PRODUCTS_SET_ERROR';
