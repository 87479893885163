import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_MATERIAL,
  REMOVE_MATERIAL_START,
  REMOVE_MATERIAL_SUCCESS,
  REMOVE_MATERIAL_ERROR
} from '../MaterialConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeMaterial({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_MATERIAL_START, id, options));

  return yield spawn(removeItemResource, 'materials', {
    success: REMOVE_MATERIAL_SUCCESS,
    error: REMOVE_MATERIAL_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveMaterial() {
  yield takeEvery(REMOVE_MATERIAL, removeMaterial);
}
