import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  BLOCK_USERS,
  BLOCK_USERS_START,
  BLOCK_USERS_SUCCESS,
  BLOCK_USERS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* blockUsers({ values, options, cb }) {
  yield put(postItemStart(BLOCK_USERS_START, options));

  return yield spawn(postItemResource, 'users/block_users', {
    success: BLOCK_USERS_SUCCESS,
    error: BLOCK_USERS_ERROR
  }, { values, options, cb });
}

export default function* watchBlockUsers() {
  yield takeEvery(BLOCK_USERS, blockUsers);
}
