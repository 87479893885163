import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_DOWNLOAD,
  FETCH_DOWNLOAD_START,
  FETCH_DOWNLOAD_SUCCESS,
  FETCH_DOWNLOAD_ERROR
} from '../DownloadConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchDownload({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_DOWNLOAD_START, id, options));

  const fetchDownloadAction = yield spawn(fetchItemResource, 'downloads', {
    success: FETCH_DOWNLOAD_SUCCESS,
    error: FETCH_DOWNLOAD_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchDownloadAction);
}

export default function* watchFetchDownload() {
  yield takeEvery(FETCH_DOWNLOAD, fetchDownload);
}
