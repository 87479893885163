import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_PRODUCT,
  DOWNLOAD_PRODUCT_START,
  DOWNLOAD_PRODUCT_SUCCESS,
  DOWNLOAD_PRODUCT_ERROR
} from '../ProductConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadProduct({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_PRODUCT_START, id, options));

  const downloadProductAction = yield spawn(postItemResource, `facilities/${id}/download`, {
    success: DOWNLOAD_PRODUCT_SUCCESS,
    error: DOWNLOAD_PRODUCT_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadProductAction);
}

export default function* watchDownloadProduct() {
  yield takeEvery(DOWNLOAD_PRODUCT, downloadProduct);
}
