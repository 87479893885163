import { useCallback, useReducer } from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';

import { ID, Item } from '../../../../../types';
import {
  initialState,
  postItemRequestReducer,
  PostItemRequestReducerType
} from './reducers/postItemRequestReducer';
import { sendPostItemRequest } from './utils/sendPostItemRequest';
import { initializePostItemRequest } from './utils/initializePostItemRequest';

interface PostItemRequestOptions {
  id?: ID;
  url: string;
  path?: string;
  values?: object;
}

export type PostItemHookOptions = Omit<PostItemRequestOptions, 'url'>;

export function usePostItemRequest<ItemType extends Item>({
  url,
  path,
  values
}: PostItemRequestOptions) {
  const [
    { item, itemPostErrorMessage, itemPostLoading },
    dispatch
  ] = useReducer<PostItemRequestReducerType<ItemType>>(
    postItemRequestReducer,
    initialState
  );
  return {
    item,
    itemPostErrorMessage,
    itemPostLoading,
    postItem: useCallback(
      (params = {}) => {
        const urlParts = [url, get(params, 'id'), path];

        return sendPostItemRequest<ItemType>(
          compact(urlParts).join('/'),
          { ...params, values },
          dispatch
        );
      },
      [url, dispatch, path, values]
    ),
    initialize: useCallback(
      () => initializePostItemRequest<ItemType>(dispatch),
      [dispatch]
    ),
    cancel: useCallback(() => console.log('cancel'), [])
  };
}
