import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_SELECTED_MATERIALS,
  FETCH_SELECTED_MATERIALS_START,
  FETCH_SELECTED_MATERIALS_SUCCESS,
  FETCH_SELECTED_MATERIALS_ERROR
} from '../SelectedMaterialConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchSelectedMaterials(params) {
  yield put(fetchItemsStart(FETCH_SELECTED_MATERIALS_START, params));

  const fetchSelectedMaterialsAction = yield spawn(fetchItemsResource, 'selected_materials', {
    success: FETCH_SELECTED_MATERIALS_SUCCESS,
    error: FETCH_SELECTED_MATERIALS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchSelectedMaterialsAction);
}

export default function* watchFetchSelectedMaterials() {
  yield takeEvery(FETCH_SELECTED_MATERIALS, fetchSelectedMaterials);
}
