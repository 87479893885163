import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const EvaluationShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_evaluations" */ './pages/EvaluationShowPage/EvaluationShowPage'
    )
});
const EvaluationsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_evaluations" */ './pages/EvaluationsIndexPage/EvaluationsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/evaluations/:evaluationId"
      component={EvaluationShowPage}
    />
    <Route exact path="/admin/evaluations" component={EvaluationsIndexPage} />
  </Fragment>
);
