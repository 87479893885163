import { POST_ITEM_ERROR } from '../../constants';

import { PostItemRequestAction } from '../../reducers/postItemRequestReducer';

function postItemError<ItemType>(
  itemPostErrorMessage: string
): PostItemRequestAction<ItemType> {
  return {
    type: POST_ITEM_ERROR,
    itemPostErrorMessage
  };
}

export default postItemError;
