import { updateState } from '../../../utils';

export function clearTaskModalValues(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    itemUpdateErrorMessage: null,
    itemsErrorMessage: null
  }, options);
}
