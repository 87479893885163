import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_TASK_SOURCE_FILES,
  SUBMIT_TASK_SOURCE_FILES_START,
  SUBMIT_TASK_SOURCE_FILES_SUCCESS,
  SUBMIT_TASK_SOURCE_FILES_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitTaskSourceFiles({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_TASK_SOURCE_FILES_START, id, options));

  const submitTaskSourceFilesAction = yield spawn(postItemResource, `discussions/${id}/submit_source_files`, {
    success: SUBMIT_TASK_SOURCE_FILES_SUCCESS,
    error: SUBMIT_TASK_SOURCE_FILES_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitTaskSourceFilesAction);
}

export default function* watchSubmitTaskSourceFiles() {
  yield takeEvery(SUBMIT_TASK_SOURCE_FILES, submitTaskSourceFiles);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   SUBMIT_TASK_SOURCE_FILES,
//   SUBMIT_TASK_SOURCE_FILES_START,
//   SUBMIT_TASK_SOURCE_FILES_SUCCESS,
//   SUBMIT_TASK_SOURCE_FILES_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function submitTaskSourceFilesStart(id) {
//   return {
//     type: SUBMIT_TASK_SOURCE_FILES_START,
//     id
//   };
// }
//
// export function submitTaskSourceFilesSuccess(id, item = {}) {
//   return {
//     type: SUBMIT_TASK_SOURCE_FILES_SUCCESS,
//     id,
//     item
//   };
// }
//
// export function submitTaskSourceFilesError(id, error) {
//   return {
//     type: SUBMIT_TASK_SOURCE_FILES_ERROR,
//     errorMessage: parseError(error),
//     id,
//     error
//   };
// }
//
// function submitTaskSourceFilesRequest(taskId, values, options = {}) {
//   return apiRequest.post(`discussions/${taskId}/submit_source_files`, values, options);
// }
//
// function* submitTaskSourceFilesResource({ taskId, values, options: { form, serializer }, cb }) {
//   try {
//     yield call(submitTaskSourceFilesRequest, taskId, values, { serializer });
//     yield put(submitTaskSourceFilesSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(submitTaskSourceFilesError(taskId, error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* submitTaskSourceFiles({ taskId, values, options, cb }) {
//   yield put(submitTaskSourceFilesStart(taskId));
//
//   return yield spawn(submitTaskSourceFilesResource, { taskId, values, options, cb });
// }
//
// export default function* watchSubmitTaskSourceFiles() {
//   yield takeEvery(SUBMIT_TASK_SOURCE_FILES, submitTaskSourceFiles);
// }
