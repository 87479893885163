import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import { addLocaleData } from 'react-intl';
import nanoid from 'nanoid';

import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import uk from 'react-intl/locale-data/uk';

import './styles/app.scss';

import App from './app/App';
import { configureStore, rootSaga } from './app/store';
import * as serviceWorker from './serviceWorker';
import messages from './locales/en';

import { flattenMessages } from './utils/flattenMessages';
import { apiRequest } from './utils/apiRequest';
import { GET_REQUEST_ERROR } from './app/AppConstants';
import { HOST_BASENAME } from './config';

const downloadNanoId = nanoid();

window.downloadNanoId = downloadNanoId;

const defaultLocale = 'en';

const history = HOST_BASENAME
  ? createHistory({ basename: HOST_BASENAME })
  : createHistory();

const store = configureStore({
  intl: {
    defaultLocale,
    locale: defaultLocale,
    messages: flattenMessages(messages[defaultLocale])
  },
  ...window.__INITIAL_STATE__
}, history);

store.runSaga(rootSaga);

apiRequest.onError(error => {
  if (!error) return;

  if (error.response.status === 500) throw new Error(error);

  store.dispatch({
    type: GET_REQUEST_ERROR,
    error
  });

  throw new Error(error);
});

const prevHistoryPush = history.push;
let lastLocation = history.location;

history.listen(location => {
  lastLocation = location;
});

history.push = (pathname, state = {}) => {
  if (lastLocation === null || pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash
    || JSON.stringify(state) !== JSON.stringify(lastLocation.state)) {
    prevHistoryPush(pathname, state);
  }
};

const dest = document.getElementById('root');

function runApp() {
  addLocaleData([...en, ...ru, ...uk]);

  ReactDOM.render(<App history={history} store={store} />, dest);
  serviceWorker.register();
}

if (!window.Intl) {
  Promise.all([
    import('intl'),
    import('intl/locale-data/jsonp/en.js'),
    import('intl/locale-data/jsonp/ru.js'),
    import('intl/locale-data/jsonp/uk.js')
  ]).then(runApp);
} else {
  runApp();
}

if (module.hot) {
  module.hot.accept('./app/App', () => {
    const NextApp = require('./app/App').default;

    ReactDOM.render(<NextApp history={history} store={store} />, dest);
  });
}
