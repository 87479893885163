import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const CategoryShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_categories" */ './pages/CategoryShowPage/CategoryShowPage'
    )
});
const CategoriesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_categories" */ './pages/CategoriesIndexPage/CategoriesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/categories/:categoryId(\d+)"
      component={CategoryShowPage}
    />
    <Route exact path="/admin/categories" component={CategoriesIndexPage} />
  </Fragment>
);
