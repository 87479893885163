export default {
  'select_category...': 'Select lifestyle category...',
  add_new_to_the_library: 'Add new lifestyle to the library',
  add_plural: 'Add lifestyles',
  add: 'Add a lifestyle',
  category: 'Lifestyle category',
  details: 'Lifestyle details',
  edit: 'Edit lifestyle',
  enter_title: 'Enter lifestyle title',
  image: 'Lifestyle image',
  library: 'Lifestyles Library',
  model: 'Lifestyle 3d model',
  new: 'New lifestyle',
  no: 'No lifestyles',
  plural_lower_case: 'lifestyles',
  plural: 'Lifestyles',
  selected: 'Selected lifestyles',
  singular_lower_case_accusative: 'lifestyle',
  singular: 'Lifestyle',
  title: 'Lifestyle title',
  select: 'Select lifestyle'
};
