import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_PERMISSION,
  CREATE_PERMISSION_START,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_ERROR
} from '../PermissionConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createPermission({ values, options, cb }) {
  yield put(createItemStart(CREATE_PERMISSION_START, options));

  return yield spawn(createItemResource, 'permissions', {
    success: CREATE_PERMISSION_SUCCESS,
    error: CREATE_PERMISSION_ERROR
  }, { values, options, cb });
}

export default function* watchCreatePermission() {
  yield takeEvery(CREATE_PERMISSION, createPermission);
}
