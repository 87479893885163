import React from 'react';

import { injectIntl } from '../../libs/react-intl';

interface IntlProps {
  formatMessage: ({ id }: { id: string }) => string;
}

export interface I18nProps {
  intl: IntlProps;
}

export const withI18n = <T extends {}>() => (
  WrappedComponent: React.ComponentType<T & I18nProps>
) => {
  const I18nHoc = (props: T & I18nProps) => <WrappedComponent {...props} />;

  return injectIntl(I18nHoc);
};
