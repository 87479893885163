import get from 'lodash/get';

import { USE_CONSOLE } from '../../../config';

type ConsoleActionType = 'log' | 'warn' | 'error';

function productionConsole(
  action: ConsoleActionType = 'log',
  ...args: any[]
): void | null {
  if (USE_CONSOLE) {
    return get(console, action)(...args);
  }

  return null;
}

export default productionConsole;
