import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import filter from 'lodash/filter';
import size from 'lodash/size';
import take from 'lodash/take';

import { withCurrentUser } from '../../../common/HOCs/withCurrentUser';
import { withDownloads } from '../../../common/HOCs/withDownloads';

import { AlertMessage } from '../../../../helpers/ts/AlertMessage';
import { Loading } from '../../../../helpers/ts/Loading';
import { PureButton } from '../../../../helpers/ts/PureButton';
import { PureLinkTo } from '../../../../helpers/ts/PureLinkTo';
import { Translate } from '../../../../helpers/ts/Translate';

import { useInitializeDownloadsBoxOpened } from './hooks/useInitializeDownloadsBoxOpened';

function DownloadsBlock({
  currentUser,
  downloadsBoxOpened,
  downloads,
  downloadsFetched,
  downloadsErrorMessage,
  removeDownload,
  setDownloadsBoxOpened
}) {
  const [downloadsBlockOpened, setDownloadsBlockOpened] = useState(true);
  const toggleDownloadsBlockOpened = useCallback(
    () => setDownloadsBlockOpened(prevState => !prevState),
    [setDownloadsBlockOpened]
  );
  const closeDownloadsBox = useCallback(
    () => setDownloadsBoxOpened(false),
    [setDownloadsBoxOpened]
  );

  useInitializeDownloadsBoxOpened({
    downloads,
    downloadsFetched,
    setDownloadsBoxOpened
  });

  if (!downloadsBoxOpened) {
    return null;
  }

  if (!downloadsFetched || size(downloads) === 0) {
    return null;
  }

  const processingDownloads = size(
    filter(downloads, download => download.progress < 100)
  );
  const completeDownloads = size(downloads);

  return (
    <div
      className="position-fixed bottom-0 right-0 mr-3"
      style={{ zIndex: 1020 }}
    >
      <div className="card rounded shadow-3" style={{ maxWidth: '350px' }}>
        <div className="card-header bg-dark d-flex align-items-center py-2">
          {downloadsBlockOpened ? (
            <div className="flex-1">
              <Translate
                id={
                  processingDownloads === 0
                    ? 'models.downloads.archiving_complete'
                    : 'models.downloads.archiving'
                }
              />{' '}
              {processingDownloads === 0 ? null : (
                <Fragment>
                  ({processingDownloads}/{completeDownloads})
                </Fragment>
              )}
            </div>
          ) : null}
          <div className="">
            <PureButton
              className="btn btn-icon rounded-circle btn-xs bg-light-alpha ml-1"
              icon={
                downloadsBlockOpened ? 'icon-arrow-down12' : 'icon-arrow-up12'
              }
              onClick={toggleDownloadsBlockOpened}
            />
            <PureButton
              className="btn btn-icon rounded-circle btn-xs bg-light-alpha ml-1"
              icon="icon-cross3"
              onClick={closeDownloadsBox}
            />
          </div>
        </div>
        <AlertMessage message={downloadsErrorMessage} />
        {downloadsBlockOpened ? (
          <Fragment>
            <Loading loaded={downloadsFetched}>
              {take(downloads, 10).map(download => (
                <div
                  key={download.id}
                  className="card-body d-flex overflow-hidden align-items-center py-2"
                >
                  <i className="icon-file-picture mr-2" />
                  <span className="flex-1 text-ellipsis">
                    {currentUser.hasPermissions('admin_read_any_id')
                      ? `#${download.id} `
                      : null}{' '}
                    {download.name}
                  </span>
                  {download.status === 'completed' ? (
                    <PureLinkTo
                      pureLink
                      className="btn btn-icon btn-link ml-2 p-0 text-muted"
                      icon="icon-download4"
                      href={download.file}
                      target="_blank"
                    />
                  ) : null}
                  {download.status === 'processing' ||
                  download.status === 'initialized' ? (
                    <span className="ml-2">{download.progress || 0}%</span>
                  ) : (
                    <i
                      className={cl('ml-1', {
                        'icon-checkmark-circle text-success':
                          download.status === 'completed',
                        'icon-cancel-circle2 text-danger':
                          download.status === 'failed'
                      })}
                    />
                  )}
                  <PureButton
                    className="btn btn-icon btn-link ml-1 p-0"
                    icon="icon-cross3 text-muted"
                    onClick={removeDownload(download)}
                  />
                </div>
              ))}
            </Loading>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
}

DownloadsBlock.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    hasPermissions: PropTypes.func
  }).isRequired,
  downloadsBoxOpened: PropTypes.bool.isRequired,
  downloads: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
  downloadsFetched: PropTypes.bool.isRequired,
  downloadsErrorMessage: PropTypes.string,
  removeDownload: PropTypes.func.isRequired,
  checkDownload: PropTypes.func.isRequired,
  setDownloadsBoxOpened: PropTypes.func.isRequired
};

DownloadsBlock.defaultProps = {
  downloadsErrorMessage: null
};

export default compose(
  withCurrentUser(),
  withDownloads({
    pageSize: 10,
    scope: 'downloads',
    fetchType: 'index',
    filters: { self: true, status: { ne: 'failed' } },
    subscriptions: ['created', 'updated', 'destroyed'],
    fields: ['downloads', 'downloadsFetched', 'downloadsBoxOpened'],
    actions: [
      'fetchDownloads',
      'updateDownload',
      'removeDownload',
      'checkDownload',
      'setDownloadsBoxOpened',
      'subscribeToDownloadsChannel',
      'unsubscribeFromDownloadsChannel',
      'subscribeToDropdownDownloadsChannel',
      'unsubscribeFromDropdownDownloadsChannel'
    ]
  }),
  // withProps(({ downloads }) => ({
  //   downloads: filter(downloads, download => download.progress < 100)
  // })),
  lifecycle({
    componentDidMount() {
      this.props.subscribeToDropdownDownloadsChannel(
        'created',
        this.props.currentUser.get('id')
      );
    },
    componentWillUnmount() {
      this.props.unsubscribeFromDropdownDownloadsChannel(
        'created',
        this.props.currentUser.get('id')
      );
    }
  })
)(DownloadsBlock);
