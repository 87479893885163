export default {
  download: 'Download bills',
  plural: 'Bills',
  singular: 'Bill',
  singular_downcase: 'bill',
  no: 'No bills',
  remove: 'Cancel bill',
  unpaid: 'Unpaid Bills',
  send: 'Send invoices',
  paid: 'Bill already paid',
  payer: 'Payer',
  refresh_payment_status: 'Refresh payment status',
  payment_processing: 'Payment processing..',
  payment_types: {
    card: 'Pay with credit card',
    credit_card: 'Pay with credit card',
    account: 'Pay with Archivizer account',
    swift: 'SWIFT',
    charge_back: 'Charge back',
    payout: 'Payout',
    other: 'Other',
    revenue_share_item_refill: 'Revenue share',
    cancel_revenue_share_item_refill: 'Cancel revenue share'
  },
  payment_methods: {
    credit_card: 'Credit card',
    account: 'Archivizer account',
    system_account_bill: 'System account',
    task_bill: 'Project account',
    debit_account_bill: 'Debit account',
    cancel_task_bill: 'Return to project account',
    cancel_bonus_bill: 'Return to bonus account',
    cancel_debit_account_bill: 'Return to debit account'
  },
  types: {
    rietumu: 'Rietumu',
    payoneer: 'Payoneer',
    '2checkout': '2checkout',
    fondy: 'Fondy.eu',
    stripe: 'Stripe',
    easypay: 'Easypay',
    invoice: 'Invoice',
    raiffeisen_bank: 'Raiffeisen bank',
    bank_account_andrey: 'Cash',
    system: 'Archivizer',
    client: 'Prepaid client\'s account',
    cancel_payment: 'Cancel payment',
    amount: 'From client account',
    cancel_amount: 'Return to client account',
    debit_account: 'Pay from CAD',
    cancel_debit_account: 'Return to CAD',
    bonus: 'Pay from CAB',
    cancel_bonus: 'Return to CAB',
    task: 'Project',
    cancel_task: 'Return to Project'
  },
  button_types: {
    account: 'Only account',
    card: 'Only card',
    card_account: 'Both buttons'
  },
  statuses: {
    paid: 'Paid',
    paying: 'Paying',
    not_paid: 'Not paid',
    non_paid: 'Not paid',
    canceled: 'Canceled'
  },
  errors: {
    already_paying: 'Current bill is already paying. Please, request a new one if needed.',
    canceled: 'Current bill is canceled. Please, request a new one if needed.'
  }
};
