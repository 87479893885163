import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ATTACH_SOURCE_FILES,
  ATTACH_SOURCE_FILES_START,
  ATTACH_SOURCE_FILES_SUCCESS,
  ATTACH_SOURCE_FILES_ERROR
} from '../SourceFileConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* attachSourceFiles({ values, options, cb }) {
  yield put(postItemsStart(ATTACH_SOURCE_FILES_START, options));

  return yield spawn(postItemsResource, 'source_files/attach', {
    success: ATTACH_SOURCE_FILES_SUCCESS,
    error: ATTACH_SOURCE_FILES_ERROR
  }, { values, options, cb });
}

export default function* watchAttachSourceFiles() {
  yield takeEvery(ATTACH_SOURCE_FILES, attachSourceFiles);
}
