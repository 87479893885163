export const FETCH_TASK_NOTIFICATIONS = 'FETCH_TASK_NOTIFICATIONS';
export const FETCH_TASK_NOTIFICATIONS_START = 'FETCH_TASK_NOTIFICATIONS_START';
export const FETCH_TASK_NOTIFICATIONS_SUCCESS = 'FETCH_TASK_NOTIFICATIONS_SUCCESS';
export const FETCH_TASK_NOTIFICATIONS_ERROR = 'FETCH_TASK_NOTIFICATIONS_ERROR';

export const CLEAR_TASK_NOTIFICATIONS_FILTERS_START = 'CLEAR_TASK_NOTIFICATIONS_FILTERS_START';
export const CLEAR_TASK_NOTIFICATIONS_FILTERS_SUCCESS = 'CLEAR_TASK_NOTIFICATIONS_FILTERS_SUCCESS';

export const FETCH_TASK_NOTIFICATION = 'FETCH_TASK_NOTIFICATION';
export const FETCH_TASK_NOTIFICATION_START = 'FETCH_TASK_NOTIFICATION_START';
export const FETCH_TASK_NOTIFICATION_SUCCESS = 'FETCH_TASK_NOTIFICATION_SUCCESS';
export const FETCH_TASK_NOTIFICATION_ERROR = 'FETCH_TASK_NOTIFICATION_ERROR';

export const CREATE_TASK_NOTIFICATION = 'CREATE_TASK_NOTIFICATION';
export const CREATE_TASK_NOTIFICATION_START = 'CREATE_TASK_NOTIFICATION_START';
export const CREATE_TASK_NOTIFICATION_SUCCESS = 'CREATE_TASK_NOTIFICATION_SUCCESS';
export const CREATE_TASK_NOTIFICATION_ERROR = 'CREATE_TASK_NOTIFICATION_ERROR';

export const UPDATE_TASK_NOTIFICATION = 'UPDATE_TASK_NOTIFICATION';
export const UPDATE_TASK_NOTIFICATION_START = 'UPDATE_TASK_NOTIFICATION_START';
export const UPDATE_TASK_NOTIFICATION_SUCCESS = 'UPDATE_TASK_NOTIFICATION_SUCCESS';
export const UPDATE_TASK_NOTIFICATION_ERROR = 'UPDATE_TASK_NOTIFICATION_ERROR';

export const REMOVE_TASK_NOTIFICATION = 'REMOVE_TASK_NOTIFICATION';
export const REMOVE_TASK_NOTIFICATION_START = 'REMOVE_TASK_NOTIFICATION_START';
export const REMOVE_TASK_NOTIFICATION_SUCCESS = 'REMOVE_TASK_NOTIFICATION_SUCCESS';
export const REMOVE_TASK_NOTIFICATION_ERROR = 'REMOVE_TASK_NOTIFICATION_ERROR';
