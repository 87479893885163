import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TASK,
  FETCH_TASK_START,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_ERROR
} from '../TaskConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTask({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TASK_START, id, options));

  const fetchTaskAction = yield spawn(fetchItemResource, 'discussions', {
    success: FETCH_TASK_SUCCESS,
    error: FETCH_TASK_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTaskAction);
}

export default function* watchFetchTask() {
  yield takeEvery(FETCH_TASK, fetchTask);
}
