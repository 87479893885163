import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_BILL_REFILLS_XLS,
  DOWNLOAD_BILL_REFILLS_XLS_START,
  DOWNLOAD_BILL_REFILLS_XLS_SUCCESS,
  DOWNLOAD_BILL_REFILLS_XLS_ERROR
} from '../BillRefillConstants';

import { downloadItemsStart, downloadItemsResource } from '../../common/sagas';

function* downloadBillRefillsXls(params) {
  yield put(downloadItemsStart(DOWNLOAD_BILL_REFILLS_XLS_START, params));

  const downloadBillRefillsXlsAction = yield spawn(downloadItemsResource, 'bill_refills.xlsx', {
    success: DOWNLOAD_BILL_REFILLS_XLS_SUCCESS,
    error: DOWNLOAD_BILL_REFILLS_XLS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(downloadBillRefillsXlsAction);
}

export default function* watchDownloadBillRefillsXls() {
  yield takeEvery(DOWNLOAD_BILL_REFILLS_XLS, downloadBillRefillsXls);
}
