import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import cl from 'classnames';
import get from 'lodash/get';
import isString from 'lodash/isString';
import omit from 'lodash/omit';

import { TOOLTIP_DELAY_SHOW, TOOLTIP_DELAY_HIDE } from '../constants';
import { translate as t } from '../../utils';

const tooltipDelay = { show: TOOLTIP_DELAY_SHOW, hide: TOOLTIP_DELAY_HIDE };

function Button({
  item,
  id,
  className,
  addClass,
  color,
  size,
  outline,
  rounded,
  block,
  icon,
  afterIcon,
  disabled,
  submit,
  onClick,
  children,
  style,
  tooltip,
  tooltipTarget,
  tooltipValue,
  tooltipPlacement,
  ...props
}) {
  const buttonClass =
    className ||
    cl(
      'btn',
      {
        [`btn-${color}`]: !!color,
        [`btn-${size}`]: !!size,
        'btn-outline': outline,
        'btn-rounded': rounded,
        'btn-block': block
      },
      addClass
    );

  const buttonIcon =
    icon &&
    (isString(icon) ? <i className={cl(icon, { 'mr-2': children })} /> : icon);
  const buttonAfterIcon =
    afterIcon &&
    (isString(afterIcon) ? (
      <i className={cl(afterIcon, { 'ml-2': children })} />
    ) : (
      afterIcon
    ));

  return (
    <Fragment>
      <button // eslint-disable-line
        id={id || tooltipTarget || get(tooltip, 'target')}
        type={submit ? 'submit' : 'button'}
        className={buttonClass}
        disabled={disabled}
        style={style}
        onClick={e => {
          if (!submit) {
            e.preventDefault();
          }
          if (onClick) {
            onClick(item || e);
          }
        }}
        {...props}
      >
        {buttonIcon}
        {buttonIcon && children ? ' ' : null}
        {children}
        {buttonAfterIcon && children ? ' ' : null}
        {buttonAfterIcon}
      </button>
      {tooltip || tooltipTarget ? (
        <UncontrolledTooltip
          placement={tooltipPlacement || get(tooltip, 'placement') || 'bottom'}
          delay={tooltipDelay}
          target={tooltipTarget || get(tooltip, 'target')}
          {...omit(tooltip, 'value', 'i18nText')}
        >
          {tooltipValue || tooltip.value || t(tooltip.i18nText)}
        </UncontrolledTooltip>
      ) : null}
    </Fragment>
  );
}

Button.propTypes = {
  item: PropTypes.shape(),
  id: PropTypes.string,
  className: PropTypes.string,
  addClass: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
    ),
    PropTypes.string
  ]),
  color: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  block: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  afterIcon: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.shape(),
  tooltip: PropTypes.shape({
    target: PropTypes.string.isRequired,
    value: PropTypes.node,
    i18nText: PropTypes.node,
    placement: PropTypes.string
  }),
  tooltipTarget: PropTypes.string,
  tooltipValue: PropTypes.node,
  tooltipPlacement: PropTypes.string
};

Button.defaultProps = {
  item: null,
  id: null,
  className: null,
  addClass: null,
  color: null,
  size: null,
  outline: null,
  rounded: null,
  block: null,
  icon: null,
  afterIcon: null,
  submit: false,
  disabled: null,
  children: null,
  onClick: null,
  style: null,
  tooltip: null,
  tooltipTarget: null,
  tooltipValue: null,
  tooltipPlacement: null
};

export default Button;
