import isNumber from 'lodash/isNumber';

import { renderCurrency } from '../../utils/renderCurrency';

export default function renderAmount(amount, currency = '$') {
  if (!isNumber(amount)) {
    return null;
  }

  if (amount >= 0) {
    return `${renderCurrency(currency)}${parseFloat(amount).toFixed(2)}`;
  }

  return `${renderCurrency(currency)}-${Math.abs(parseFloat(amount)).toFixed(2)}`;
}
