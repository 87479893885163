export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const CLEAR_TAGS_FILTERS_START = 'CLEAR_TAGS_FILTERS_START';
export const CLEAR_TAGS_FILTERS_SUCCESS = 'CLEAR_TAGS_FILTERS_SUCCESS';

export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_START = 'FETCH_TAG_START';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_ERROR = 'FETCH_TAG_ERROR';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_START = 'CREATE_TAG_START';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_START = 'UPDATE_TAG_START';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_ERROR = 'UPDATE_TAG_ERROR';

export const REMOVE_TAG = 'REMOVE_TAG';
export const REMOVE_TAG_START = 'REMOVE_TAG_START';
export const REMOVE_TAG_SUCCESS = 'REMOVE_TAG_SUCCESS';
export const REMOVE_TAG_ERROR = 'REMOVE_TAG_ERROR';
