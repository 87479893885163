import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_CONTACT,
  FETCH_CONTACT_START,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_ERROR
} from '../ContactConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchContact({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_CONTACT_START, id, options));

  const fetchContactAction = yield spawn(fetchItemResource, 'feed_backs', {
    success: FETCH_CONTACT_SUCCESS,
    error: FETCH_CONTACT_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchContactAction);
}

export default function* watchFetchContact() {
  yield takeEvery(FETCH_CONTACT, fetchContact);
}
