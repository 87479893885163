export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_START = 'CREATE_REPORT_START';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';

export const REMOVE_REPORT = 'REMOVE_REPORT';
export const REMOVE_REPORT_START = 'REMOVE_REPORT_START';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';
export const REMOVE_REPORT_ERROR = 'REMOVE_REPORT_ERROR';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_START = 'DOWNLOAD_REPORT_START';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';

export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GENERATE_REPORT_START = 'GENERATE_REPORT_START';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR';

export const SOCKET_SUBSCRIBE_TO_REPORTS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_REPORTS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_REPORTS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_REPORTS_CHANNEL';
