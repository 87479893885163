import get from 'lodash/get';

import { updateState, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function itemsChannelDestroyed(state, { data, options = {} }) {
  const currentItem = currentStateValue(state, 'item', options, {});
  const currentItems = currentStateValue(state, 'items', options, []);
  const currentItemsMeta = currentStateValue(state, 'itemsMeta', options, {});

  return updateState(state, {
    item: get(currentItem, 'id') === data.id ? {} : currentItem,
    items: removeById(currentItems, data.id),
    itemsMeta: { ...currentItemsMeta, total: get(currentItemsMeta, 'total', 0) - 1 }
  }, options);
}
