export default {
  plural: 'Allocations',
  singular: 'Allocation',
  actions: {
    share: 'Share',
    set_status_shared: 'Set status «Shared»',
    share_from_client_balance: 'Share from client balance',
    set_status_canceled: 'Set status «Canceled»',
    cancel_share: 'Cancel share',
    cancel_share_from_client_balance: 'Cancel share from client balance'
  },
  state: 'State',
  states: {
    allocated: 'Allocated',
    not_allocated: 'Not allocated',
    canceled: 'Canceled'
  },
  types: {
    prepayment: 'Prepayment'
  },
  download_excel: 'Download excel document with allocations'
};
