export const CREATE_INCOMING_PAYMENTS = 'CREATE_INCOMING_PAYMENTS';
export const CREATE_INCOMING_PAYMENTS_START = 'CREATE_INCOMING_PAYMENTS_START';
export const CREATE_INCOMING_PAYMENTS_SUCCESS = 'CREATE_INCOMING_PAYMENTS_SUCCESS';
export const CREATE_INCOMING_PAYMENTS_ERROR = 'CREATE_INCOMING_PAYMENTS_ERROR';

export const CREATE_OUTGOING_PAYMENTS = 'CREATE_OUTGOING_PAYMENTS';
export const CREATE_OUTGOING_PAYMENTS_START = 'CREATE_OUTGOING_PAYMENTS_START';
export const CREATE_OUTGOING_PAYMENTS_SUCCESS = 'CREATE_OUTGOING_PAYMENTS_SUCCESS';
export const CREATE_OUTGOING_PAYMENTS_ERROR = 'CREATE_OUTGOING_PAYMENTS_ERROR';

export const CREATE_INCOMING_TRANSFERS = 'CREATE_INCOMING_TRANSFERS';
export const CREATE_INCOMING_TRANSFERS_START = 'CREATE_INCOMING_TRANSFERS_START';
export const CREATE_INCOMING_TRANSFERS_SUCCESS = 'CREATE_INCOMING_TRANSFERS_SUCCESS';
export const CREATE_INCOMING_TRANSFERS_ERROR = 'CREATE_INCOMING_TRANSFERS_ERROR';

export const CREATE_OUTGOING_TRANSFERS = 'CREATE_OUTGOING_TRANSFERS';
export const CREATE_OUTGOING_TRANSFERS_START = 'CREATE_OUTGOING_TRANSFERS_START';
export const CREATE_OUTGOING_TRANSFERS_SUCCESS = 'CREATE_OUTGOING_TRANSFERS_SUCCESS';
export const CREATE_OUTGOING_TRANSFERS_ERROR = 'CREATE_OUTGOING_TRANSFERS_ERROR';

export const CREATE_INTERNAL_TRANSFERS = 'CREATE_INTERNAL_TRANSFERS';
export const CREATE_INTERNAL_TRANSFERS_START = 'CREATE_INTERNAL_TRANSFERS_START';
export const CREATE_INTERNAL_TRANSFERS_SUCCESS = 'CREATE_INTERNAL_TRANSFERS_SUCCESS';
export const CREATE_INTERNAL_TRANSFERS_ERROR = 'CREATE_INTERNAL_TRANSFERS_ERROR';

export const CREATE_INCOMING_PAYMENTS_FORM = 'CREATE_INCOMING_PAYMENTS_FORM';
export const CREATE_OUTGOING_PAYMENTS_FORM = 'CREATE_OUTGOING_PAYMENTS_FORM';

export const CREATE_INCOMING_TRANSFERS_FORM = 'CREATE_INCOMING_TRANSFERS_FORM';
export const CREATE_OUTGOING_TRANSFERS_FORM = 'CREATE_OUTGOING_TRANSFERS_FORM';
export const CREATE_INTERNAL_TRANSFERS_FORM = 'CREATE_INTERNAL_TRANSFERS_FORM';

export const CLEAR_PAYMENT_MODAL_VALUES = 'CLEAR_PAYMENT_MODAL_VALUES';
