import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_PROJECT,
  CREATE_PROJECT_START,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR
} from '../ProjectConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createProject({ values, options, cb }) {
  yield put(createItemStart(CREATE_PROJECT_START, options));

  return yield spawn(createItemResource, 'tasks', {
    success: CREATE_PROJECT_SUCCESS,
    error: CREATE_PROJECT_ERROR
  }, { values, options, cb });
}

export default function* watchCreateProject() {
  yield takeEvery(CREATE_PROJECT, createProject);
}
