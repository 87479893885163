import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_BILLS,
  FETCH_BILLS_START,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_ERROR
} from '../BillConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchBills(params) {
  yield put(fetchItemsStart(FETCH_BILLS_START, params));

  const fetchBillsAction = yield spawn(fetchItemsResource, 'bills', {
    success: FETCH_BILLS_SUCCESS,
    error: FETCH_BILLS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchBillsAction);
}

export default function* watchFetchBills() {
  yield takeEvery(FETCH_BILLS, fetchBills);
}
