import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_ROLES,
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR
} from '../RoleConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchRoles(params) {
  yield put(fetchItemsStart(FETCH_ROLES_START, params));

  const fetchRolesAction = yield spawn(fetchItemsResource, 'roles', {
    success: FETCH_ROLES_SUCCESS,
    error: FETCH_ROLES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRolesAction);
}

export default function* watchFetchRoles() {
  yield takeEvery(FETCH_ROLES, fetchRoles);
}
