import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ClientFileShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_client_files" */ './pages/ClientFileShowPage/ClientFileShowPage'
    )
});
const ClientFilesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_client_files" */ './pages/ClientFilesIndexPage/ClientFilesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/client_files/:clientFileId(\d+)"
      component={ClientFileShowPage}
    />
    <Route exact path="/admin/client_files" component={ClientFilesIndexPage} />
  </Fragment>
);
