import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  INVITE_USERS_TO_TASK,
  INVITE_USERS_TO_TASK_START,
  INVITE_USERS_TO_TASK_SUCCESS,
  INVITE_USERS_TO_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* inviteUsersToTaskRequest({ id, values, options, cb }) {
  yield put(postItemStart(INVITE_USERS_TO_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/invite_members`, {
    success: INVITE_USERS_TO_TASK_SUCCESS,
    error: INVITE_USERS_TO_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchInviteUsersToTaskRequest() {
  yield takeEvery(INVITE_USERS_TO_TASK, inviteUsersToTaskRequest);
}
