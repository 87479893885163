import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const MaterialEditPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "materials-edit" */'./pages/MaterialEditPage/MaterialEditPage') });
const MaterialsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "materials" */'./pages/MaterialsIndexPage/MaterialsIndexPage') });

export default (
  <Fragment>
    <Route exact path="/materials/:materialId" component={MaterialEditPage} />
    <Route exact path="/materials" component={MaterialsIndexPage} />
  </Fragment>
);
