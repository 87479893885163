export default {
  plural: 'Balances',
  singular: 'Balance',
  my: 'My balance',
  recalculate: 'Recalculate balances',
  tabs: {
    amount: 'Old',
    prepaid: 'Prepaid',
    bonus: 'Bonus',
    debit: 'Debit',
    credit: 'Credit',
    project: 'Projects',
    task: 'Tasks'
  },
  types: {
    amount_tab: 'Old',
    amount: 'Old Account',
    balance_amount: 'Old Account',
    bonus_account: 'Bonus Account',
    bonus_amount: 'Bonus Account',
    bonus: 'Bonus',
    credit_account: 'Credit Account',
    credit: 'Credit',
    debit_account: 'Debit Account',
    debit: 'Debit',
    discussion_account: 'Task Account',
    prepaid_balance_amount: 'Prepaid Account',
    prepaid: 'Prepaid',
    prepaid_amount: 'Prepaid Account',
    project: 'Projects',
    task_account: 'Project Account',
    task: 'Tasks'
  }
};
