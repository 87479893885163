import { takeEvery, put, call, spawn } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import {
  UPDATE_PROJECTS_AMOUNTS,
  UPDATE_PROJECTS_AMOUNTS_START,
  UPDATE_PROJECTS_AMOUNTS_SUCCESS,
  UPDATE_PROJECTS_AMOUNTS_ERROR
} from '../ProjectConstants';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function updateProjectsAmountsStart() {
  return {
    type: UPDATE_PROJECTS_AMOUNTS_START
  };
}

export function updateProjectsAmountsSuccess(item = {}) {
  return {
    type: UPDATE_PROJECTS_AMOUNTS_SUCCESS,
    item
  };
}

export function updateProjectsAmountsError(error) {
  return {
    type: UPDATE_PROJECTS_AMOUNTS_ERROR,
    errorMessage: parseError(error),
    error
  };
}

function updateProjectsAmountsRequest(values) {
  return apiRequest.post('tasks/update_amounts', values);
}

function* updateProjectsAmountsResource({ values, options: { form }, cb }) {
  try {
    yield call(updateProjectsAmountsRequest, values);
    yield put(updateProjectsAmountsSuccess());
    cb && cb();
  } catch (error) {
    yield put(updateProjectsAmountsError(error));
    yield form && put(stopSubmit(form, prepareErrors(error)));
  }
}

function* updateProjectsAmounts({ values, options, cb }) {
  yield put(updateProjectsAmountsStart());

  return yield spawn(updateProjectsAmountsResource, { values, options, cb });
}

export default function* watchUpdateProjectsAmounts() {
  yield takeEvery(UPDATE_PROJECTS_AMOUNTS, updateProjectsAmounts);
}
