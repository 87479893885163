import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import { CheckPermissions, PureButton } from '../../../../helpers';
import { translate as t } from '../../../../utils';

function BillCheckPaymentButton({ item, onCheckPayment }) {
  if (item.status === 'paid') {
    return null;
  }

  return (
    <CheckPermissions action="read_bill_refresh_status_button">
      <PureButton
        addClass="btn-icon btn-light btn-ladda btn-ladda-spinner"
        size="sm"
        icon="icon-spinner11"
        tooltipValue={t('models.bills.refresh_payment_status')}
        tooltipTarget="bottom"
        onClick={onCheckPayment(item)}
      />
    </CheckPermissions>
  );
}

BillCheckPaymentButton.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onCheckPayment: PropTypes.func.isRequired
};

export default pure(BillCheckPaymentButton);
