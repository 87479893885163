export default {
  no: 'No batch operations',
  plural: 'Batch operations',
  refresh: 'Refresh batch operations list',
  singular: 'Batch operation',
  statuses: {
    initialized: 'Initialized',
    completed: 'Completed',
    failed: 'Failed',
    processing: 'Processing'
  },
  types: {
    batch_task_creation: 'Batch task creation',
    batch_allocate_funds: 'Batch allocate funds',
    multiple_task_creation: 'Multiple task creation',
    batch_revenue_shares_import: 'Batch Revenue Share Import',
    batch_revenue_share_items_import: 'Batch Revenue Share Import',
    batch_transactions_import: 'Batch Transactions Import',
    batch_users_payoneer_ids_import: 'Batch Users Payoneer ID Import',
    batch_users_payoneer_ids_pe_import: 'Batch Users Payoneer ID PE Import'
  }
};
