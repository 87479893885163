import { updateState } from '../../../utils';

export function createTasksBillsStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function createTasksBillsSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function createTasksBillsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}

// export function createTasksBillsStart(state) {
//   return {
//     ...state,
//     isLoading: true
//   };
// }
//
// export function createTasksBillsSuccess(state) {
//   return {
//     ...state,
//     isLoading: false
//   };
// }
//
// export function createTasksBillsError(state, { errorMessage }) {
//   return {
//     ...state,
//     isLoading: false,
//     errorMessage
//   };
// }
