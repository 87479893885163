import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cl from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { withState, withHandlers, compose, pure } from 'recompose';

import { withCurrentUser } from '../../../../../main/common/HOCs/withCurrentUser';

import { LinkTo } from '../../../../../helpers';
import { Avatar } from '../../../../../helpers/Avatar';
import { translate as t } from '../../../../../utils';
import { renderAmount } from '../../../../../utils/renderAmount';

function UserMenu({
  currentUser,
  isOpen,
  collapse,
  toggle,
  onLogout,
  history
}) {
  useEffect(() => {
    const unlisten = history.listen(collapse);
    return () => unlisten();
  }, [history, collapse]);

  return (
    <li
      className={cl('nav-item', 'dropdown', 'dropdown-user', { show: isOpen })}
    >
      <OutsideClickHandler onOutsideClick={collapse}>
        <LinkTo
          pureLink
          className="navbar-nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded={isOpen}
          onClick={toggle}
        >
          <Avatar image={currentUser.get('image')} />
          <span>{renderAmount(currentUser.get('user_balance'))}</span>
        </LinkTo>

        <div
          className={cl('dropdown-menu', 'dropdown-menu-right', {
            show: isOpen
          })}
        >
          <LinkTo
            href="profile/finance"
            className="dropdown-item"
            icon="icon-coins"
          >
            {t('models.balances.my')}
          </LinkTo>
          {currentUser.hasPermissions('read_user_menu_time_report_button') ? (
            <LinkTo href="#" className="dropdown-item" icon="icon-alarm">
              {t('words.time_report')}
            </LinkTo>
          ) : null}
          <div className="dropdown-divider" />
          <LinkTo href="profile" className="dropdown-item" icon="icon-cog5">
            {t('words.account_settings')}
          </LinkTo>
          <LinkTo
            pureLink
            className="dropdown-item"
            icon="icon-switch2"
            onClick={onLogout}
          >
            {t('words.logout')}
          </LinkTo>
        </div>
      </OutsideClickHandler>
    </li>
  );
}

UserMenu.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  collapse: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired
  }).isRequired
};

export default compose(
  withRouter,
  withCurrentUser(),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    toggle: ({ isOpen, setIsOpen }) => () => setIsOpen(!isOpen),
    collapse: ({ isOpen, setIsOpen }) => () => isOpen && setIsOpen(false)
  }),
  pure
)(UserMenu);
