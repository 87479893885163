import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ACCEPT_INVITE_TO_TASK,
  ACCEPT_INVITE_TO_TASK_START,
  ACCEPT_INVITE_TO_TASK_SUCCESS,
  ACCEPT_INVITE_TO_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* acceptInviteToTask({ id, values, options, cb }) {
  yield put(postItemStart(ACCEPT_INVITE_TO_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/accept_invite`, {
    success: ACCEPT_INVITE_TO_TASK_SUCCESS,
    error: ACCEPT_INVITE_TO_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchAcceptInviteToTask() {
  yield takeEvery(ACCEPT_INVITE_TO_TASK, acceptInviteToTask);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
//
// import {
//   ACCEPT_INVITE_TO_TASK,
//   ACCEPT_INVITE_TO_TASK_START,
//   ACCEPT_INVITE_TO_TASK_SUCCESS,
//   ACCEPT_INVITE_TO_TASK_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, parseError } from '../../../utils';
//
// export function acceptInviteToTaskStart(id) {
//   return {
//     type: ACCEPT_INVITE_TO_TASK_START,
//     id
//   };
// }
//
// export function acceptInviteToTaskSuccess(id) {
//   return {
//     type: ACCEPT_INVITE_TO_TASK_SUCCESS,
//     id
//   };
// }
//
// export function acceptInviteToTaskError(id, error) {
//   return {
//     type: ACCEPT_INVITE_TO_TASK_ERROR,
//     errorMessage: parseError(error),
//     error,
//     id
//   };
// }
//
// function acceptInviteToTaskRequest(taskId) {
//   return apiRequest.post(`discussions/${taskId}/accept_invite`).then(({ data: { data: item } }) => ({ item }));
// }
//
// function* acceptInviteToTaskResource({ taskId, cb }) {
//   try {
//     yield call(acceptInviteToTaskRequest, taskId);
//     yield put(acceptInviteToTaskSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(acceptInviteToTaskError(taskId, error));
//   }
// }
//
// function* acceptInviteToTask({ taskId, cb }) {
//   yield put(acceptInviteToTaskStart(taskId));
//
//   return yield spawn(acceptInviteToTaskResource, { taskId, cb });
// }
//
// export default function* watchAcceptInviteToTask() {
//   yield takeEvery(ACCEPT_INVITE_TO_TASK, acceptInviteToTask);
// }
