import get from 'lodash/get';

import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function approveTaskResultsStart(state, { id, options = {} }) {
  const currentValue = currentStateValue(state, 'item', options);

  return updateState(state, {
    isLoading: true,
    errorMessage: null,
    item: get(currentValue, 'id', null) === id ? { ...currentValue, loading: true, error: null } : currentValue,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: true,
      error: null
    }))
  }, options);
}

export function approveTaskResultsSuccess(state, { id, values, options = {} }) {
  const currentValue = currentStateValue(state, 'item', options);

  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    item: get(currentValue, 'id', null) === id ? { ...currentValue, ...values, loading: false, error: null } : currentValue,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      ...values,
      loading: false,
      error: null
    }))
  }, options);
}

export function approveTaskResultsError(state, { id, errorMessage, options = {} }) {
  const currentValue = currentStateValue(state, 'item', options);

  return updateState(state, {
    isLoading: false,
    errorMessage,
    item: get(currentValue, 'id', null) === id ? { ...currentValue, loading: false, error: errorMessage } : currentValue,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      error: errorMessage
    }))
  }, options);
}
