import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  RECALCULATE_PROJECT_ACCOUNT,
  RECALCULATE_PROJECT_ACCOUNT_START,
  RECALCULATE_PROJECT_ACCOUNT_SUCCESS,
  RECALCULATE_PROJECT_ACCOUNT_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* recalculateProjectAccount({ id, values, options, cb }) {
  yield put(postItemStart(RECALCULATE_PROJECT_ACCOUNT_START, id, options));

  const recalculateProjectAccountAction = yield spawn(postItemResource, `tasks/${id}/recalculate_account`, {
    success: RECALCULATE_PROJECT_ACCOUNT_SUCCESS,
    error: RECALCULATE_PROJECT_ACCOUNT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(recalculateProjectAccountAction);
}

export default function* watchRecalculateProjectAccount() {
  yield takeEvery(RECALCULATE_PROJECT_ACCOUNT, recalculateProjectAccount);
}
