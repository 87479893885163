import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function createProductsSetSelectedProductsSuccess(state, { item, options = {} }) {
  const currentProductsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentProductsSet,
      selected_facilities: [
        ...get(currentProductsSet, 'selected_facilities', []),
        item
      ]
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, productsSet => ({
      ...productsSet,
      selected_facilities: [
        ...get(productsSet, 'selected_facilities', []),
        item
      ]
    }))
  }, options);
}
