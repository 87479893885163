import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  REMOVE_TASK_SELECTED_PRODUCTS,
  REMOVE_TASK_SELECTED_PRODUCTS_START,
  REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS,
  REMOVE_TASK_SELECTED_PRODUCTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_SELECTED_PRODUCTS_START, id, options));

  const removeTaskSelectedProductsAction = yield spawn(postItemResource, `discussions/${id}/remove_selected_facilities`, {
    success: REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS,
    error: REMOVE_TASK_SELECTED_PRODUCTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskSelectedProductsAction);
}

export default function* watchRemoveTaskSelectedProducts() {
  yield takeEvery(REMOVE_TASK_SELECTED_PRODUCTS, removeTaskSelectedProducts);
}
