import { LinkMenuItems } from '../../../../helpers/LinkMenu/types';

export const links: LinkMenuItems = [
  {
    type: 'dropdown',
    namespace:
      'reports|downloads|sent_messages|incoming_messages|batch_operations|products|lifestyles|materials',
    label: 'models.reports.plural',
    links: [
      {
        type: 'link',
        namespace: 'reports',
        href: 'reports',
        label: 'models.reports.general'
      },
      {
        type: 'link',
        namespace: 'downloads',
        href: 'downloads',
        label: 'models.downloads.plural'
      },
      {
        type: 'link',
        namespace: 'sent_messages',
        href: 'sent_messages',
        label: 'models.sent_messages.plural'
      },
      {
        type: 'link',
        namespace: 'incoming_messages',
        href: 'incoming_messages',
        label: 'models.incoming_messages.plural'
      },
      {
        type: 'link',
        namespace: 'batch_operations',
        href: 'batch_operations',
        label: 'models.batch_operations.plural'
      },
      {
        type: 'link',
        namespace: 'products',
        href: 'products',
        label: 'models.products.plural'
      },
      {
        type: 'link',
        namespace: 'lifestyles',
        href: 'lifestyles',
        label: 'models.lifestyles.plural'
      },
      {
        type: 'link',
        namespace: 'materials',
        href: 'materials',
        label: 'models.materials.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace: 'transactions|allocations|bills|refills|bill_refills',
    label: 'words.finance',
    links: [
      {
        type: 'link',
        namespace: 'transactions',
        href: 'transactions',
        label: 'models.transactions.plural'
      },
      {
        type: 'link',
        namespace: 'allocations',
        href: 'allocations',
        label: 'models.allocations.plural'
      },
      {
        type: 'link',
        namespace: 'bills',
        href: 'bills',
        label: 'models.bills.plural'
      },
      {
        type: 'link',
        namespace: 'refills',
        href: 'refills',
        label: 'models.refills.plural'
      },
      {
        type: 'link',
        namespace: 'bill_refills',
        href: 'bill_refills',
        label: 'models.bill_refills.plural'
      },
      {
        type: 'link',
        namespace: 'promo_codes',
        href: 'promo_codes',
        label: 'models.promo_codes.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace: 'roles|permissions',
    label: 'words.access',
    links: [
      {
        type: 'link',
        namespace: 'roles',
        href: 'roles',
        label: 'models.roles.plural'
      },
      {
        type: 'link',
        namespace: 'permissions',
        href: 'permissions',
        label: 'models.permissions.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace:
      'pages|email_templates|tags|transaction_tags|sections|categories|evaluation_sets|evaluations',
    label: 'words.content',
    links: [
      {
        type: 'link',
        namespace: 'pages',
        href: 'pages',
        label: 'models.pages.plural'
      },
      {
        type: 'link',
        namespace: 'email_templates',
        href: 'email_templates',
        label: 'models.email_templates.plural'
      },
      {
        type: 'link',
        namespace: 'tags',
        href: 'tags',
        label: 'models.tags.plural'
      },
      {
        type: 'link',
        namespace: 'transaction_tags',
        href: 'transaction_tags',
        label: 'models.transaction_tags.plural'
      },
      {
        type: 'link',
        namespace: 'sections',
        href: 'sections',
        label: 'models.sections.plural'
      },
      {
        type: 'link',
        namespace: 'categories',
        href: 'categories',
        label: 'models.categories.plural'
      },
      {
        type: 'link',
        namespace: 'evaluation_sets',
        href: 'evaluation_sets',
        label: 'models.evaluation_sets.plural'
      },
      {
        type: 'link',
        namespace: 'evaluations',
        href: 'evaluations',
        label: 'models.evaluations.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace:
      'file_attachments|client_files|images|source_files|max_files|texture_files',
    label: 'models.files.plural',
    links: [
      {
        type: 'link',
        namespace: 'client_files',
        href: 'client_files',
        label: 'models.client_files.plural'
      },
      {
        type: 'link',
        namespace: 'file_attachments',
        href: 'file_attachments',
        label: 'models.file_attachments.plural'
      },
      {
        type: 'link',
        namespace: 'file_imports',
        href: 'file_imports',
        label: 'models.file_imports.plural'
      },
      {
        type: 'link',
        namespace: 'images',
        href: 'images',
        label: 'models.images.plural'
      },
      {
        type: 'link',
        namespace: 'max_files',
        href: 'max_files',
        label: 'models.max_files.plural'
      },
      {
        type: 'link',
        namespace: 'source_files',
        href: 'source_files',
        label: 'models.source_files.plural'
      },
      {
        type: 'link',
        namespace: 'texture_files',
        href: 'texture_files',
        label: 'models.texture_files.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace: 's3_buckets|s3_objects',
    label: 'words.s3',
    links: [
      {
        type: 'link',
        namespace: 's3_buckets',
        href: 's3_buckets',
        label: 'models.s3_buckets.plural'
      },
      {
        type: 'link',
        namespace: 's3_objects',
        href: 's3_objects',
        label: 'models.s3_objects.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace:
      'controller_requests|user_controller_requests|user_websocket_requests|payoneer_requests|user_logins|user_registrations|user_reset_password_requests|user_deletes|auth_controller_requests|access_denied_errors',
    label: 'words.logs',
    links: [
      {
        type: 'link',
        namespace: 'controller_requests',
        href: 'controller_requests',
        label: 'models.controller_requests.plural'
      },
      {
        type: 'link',
        namespace: 'user_controller_requests',
        href: 'user_controller_requests',
        label: 'models.user_controller_requests.plural'
      },
      {
        type: 'link',
        namespace: 'user_websocket_requests',
        href: 'user_websocket_requests',
        label: 'models.user_websocket_requests.plural'
      },
      {
        type: 'link',
        namespace: 'payoneer_requests',
        href: 'payoneer_requests',
        label: 'models.payoneer_requests.plural'
      },
      {
        type: 'link',
        namespace: 'user_logins',
        href: 'user_logins',
        label: 'models.user_logins.plural'
      },
      {
        type: 'link',
        namespace: 'user_registrations',
        href: 'user_registrations',
        label: 'models.user_registrations.plural'
      },
      {
        type: 'link',
        namespace: 'user_reset_password_requests',
        href: 'user_reset_password_requests',
        label: 'models.user_reset_password_requests.plural'
      },
      {
        type: 'link',
        namespace: 'user_deletes',
        href: 'user_deletes',
        label: 'models.user_deletes.plural'
      },
      {
        type: 'link',
        namespace: 'auth_controller_requests',
        href: 'auth_controller_requests',
        label: 'models.auth_controller_requests.plural'
      },
      {
        type: 'link',
        namespace: 'access_denied_errors',
        href: 'access_denied_errors',
        label: 'models.access_denied_errors.plural'
      }
    ]
  },
  {
    type: 'link',
    namespace: 'contacts',
    href: 'contacts',
    label: 'models.contacts.plural'
  },
  {
    type: 'link',
    namespace: 'imports',
    href: 'imports',
    label: 'models.imports.plural'
  }
];
