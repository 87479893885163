import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import get from 'lodash/get';

import { translate as t } from '../../utils';
import { Button, LinkTo, Loading } from '../../helpers';

const loadingIndicator = showIndicator => (showIndicator ? <Loading loaded={false} /> : t('words.processing'));

function MoreButton({ type, wrapperClass, withoutWrapper, className, addClass, parenthesized, size, color, icon, isLoading, label, showRest, showIndicator, meta, onClick, children }) {
  const C = type === 'link' ? LinkTo : Button;

  const rest = meta.total ? (meta.total - meta.page * meta.pageSize) : null;

  const restTitle = parenthesized ? ` (${rest})` : ` ${rest}`;

  const elem = (
    <C
      disabled={isLoading}
      className={className}
      addClass={addClass}
      size={size}
      color={color}
      icon={icon}
      onClick={onClick}
      {...(type === 'link' ? { pureLink: true, href: '#' } : {})}
    >
      {isLoading ? loadingIndicator(showIndicator) : children || (
        <Fragment>
          {label || t('words.show_more')}
          {showRest && (rest ? restTitle : null)}
        </Fragment>
      )}
    </C>
  );

  if (withoutWrapper) {
    return get(meta, 'has_more') || (meta.page * meta.pageSize < meta.total) ? elem : null;
  }

  return (
    <Fragment>
      {get(meta, 'has_more') || (meta.page * meta.pageSize < meta.total) ? (
        <div className={wrapperClass}>
          {elem}
        </div>
      ) : null}
    </Fragment>
  );
}

MoreButton.propTypes = {
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  withoutWrapper: PropTypes.bool,
  parenthesized: PropTypes.bool,
  className: PropTypes.string,
  addClass: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.node,
  showRest: PropTypes.bool,
  showIndicator: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

MoreButton.defaultProps = {
  type: 'button',
  wrapperClass: 'text-center mt-3',
  withoutWrapper: false,
  parenthesized: true,
  className: null,
  addClass: 'legitRipple',
  size: 'sm',
  color: null,
  label: null,
  icon: null,
  showRest: true,
  showIndicator: false
};

export default pure(MoreButton);
