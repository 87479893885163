import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPLOAD_CLIENT_FILES,
  UPLOAD_CLIENT_FILES_START,
  UPLOAD_CLIENT_FILE_START,
  UPLOAD_CLIENT_FILES_SUCCESS,
  UPLOAD_CLIENT_FILE_SUCCESS,
  UPLOAD_CLIENT_FILE_ERROR,
  UPLOAD_CLIENT_FILES_ERROR
} from '../ClientFileConstants';

import { uploadItemsResource } from '../../common/sagas';

function* uploadClientFiles({ files, options, cb }) {
  const uploadClientFilesAction = yield spawn(uploadItemsResource, 'client_files', {
    start: UPLOAD_CLIENT_FILES_START,
    eachStart: UPLOAD_CLIENT_FILE_START,
    success: UPLOAD_CLIENT_FILES_SUCCESS,
    eachSuccess: UPLOAD_CLIENT_FILE_SUCCESS,
    eachError: UPLOAD_CLIENT_FILE_ERROR,
    error: UPLOAD_CLIENT_FILES_ERROR,
    param: 'clientFile'
  }, { files, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(uploadClientFilesAction);
}

export default function* watchUploadClientFiles() {
  yield takeEvery(UPLOAD_CLIENT_FILES, uploadClientFiles);
}
