import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_PROJECT_SELECTED_MATERIALS,
  DOWNLOAD_PROJECT_SELECTED_MATERIALS_START,
  DOWNLOAD_PROJECT_SELECTED_MATERIALS_SUCCESS,
  DOWNLOAD_PROJECT_SELECTED_MATERIALS_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadProjectSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_PROJECT_SELECTED_MATERIALS_START, id, options));

  const downloadProjectSelectedMaterialsAction = yield spawn(postItemResource, `tasks/${id}/selected_materials`, {
    success: DOWNLOAD_PROJECT_SELECTED_MATERIALS_SUCCESS,
    error: DOWNLOAD_PROJECT_SELECTED_MATERIALS_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadProjectSelectedMaterialsAction);
}

export default function* watchDownloadProjectSelectedMaterials() {
  yield takeEvery(DOWNLOAD_PROJECT_SELECTED_MATERIALS, downloadProjectSelectedMaterials);
}
