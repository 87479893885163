import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ImageShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_images" */ './pages/ImageShowPage/ImageShowPage'
    )
});
const ImagesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_images" */ './pages/ImagesIndexPage/ImagesIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/images/:imageId(\d+)" component={ImageShowPage} />
    <Route exact path="/admin/images" component={ImagesIndexPage} />
  </Fragment>
);
