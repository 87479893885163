import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR
} from '../ProductConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateProduct({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_PRODUCT_START, id, options));

  return yield spawn(updateItemResource, 'facilities', {
    success: UPDATE_PRODUCT_SUCCESS,
    error: UPDATE_PRODUCT_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
}
