export const FETCH_REVENUE_SHARE_ITEMS = 'FETCH_REVENUE_SHARE_ITEMS';
export const FETCH_REVENUE_SHARE_ITEMS_START = 'FETCH_REVENUE_SHARE_ITEMS_START';
export const FETCH_REVENUE_SHARE_ITEMS_SUCCESS = 'FETCH_REVENUE_SHARE_ITEMS_SUCCESS';
export const FETCH_REVENUE_SHARE_ITEMS_ERROR = 'FETCH_REVENUE_SHARE_ITEMS_ERROR';

export const FETCH_REVENUE_SHARE_ITEM = 'FETCH_REVENUE_SHARE_ITEM';
export const FETCH_REVENUE_SHARE_ITEM_START = 'FETCH_REVENUE_SHARE_ITEM_START';
export const FETCH_REVENUE_SHARE_ITEM_SUCCESS = 'FETCH_REVENUE_SHARE_ITEM_SUCCESS';
export const FETCH_REVENUE_SHARE_ITEM_ERROR = 'FETCH_REVENUE_SHARE_ITEM_ERROR';

export const CREATE_REVENUE_SHARE_ITEM = 'CREATE_REVENUE_SHARE_ITEM';
export const CREATE_REVENUE_SHARE_ITEM_START = 'CREATE_REVENUE_SHARE_ITEM_START';
export const CREATE_REVENUE_SHARE_ITEM_SUCCESS = 'CREATE_REVENUE_SHARE_ITEM_SUCCESS';
export const CREATE_REVENUE_SHARE_ITEM_ERROR = 'CREATE_REVENUE_SHARE_ITEM_ERROR';

export const CREATE_REVENUE_SHARE_ITEMS = 'CREATE_REVENUE_SHARE_ITEMS';
export const CREATE_REVENUE_SHARE_ITEMS_START = 'CREATE_REVENUE_SHARE_ITEMS_START';
export const CREATE_REVENUE_SHARE_ITEMS_SUCCESS = 'CREATE_REVENUE_SHARE_ITEMS_SUCCESS';
export const CREATE_REVENUE_SHARE_ITEMS_ERROR = 'CREATE_REVENUE_SHARE_ITEMS_ERROR';

export const UPDATE_REVENUE_SHARE_ITEM = 'UPDATE_REVENUE_SHARE_ITEM';
export const UPDATE_REVENUE_SHARE_ITEM_START = 'UPDATE_REVENUE_SHARE_ITEM_START';
export const UPDATE_REVENUE_SHARE_ITEM_SUCCESS = 'UPDATE_REVENUE_SHARE_ITEM_SUCCESS';
export const UPDATE_REVENUE_SHARE_ITEM_ERROR = 'UPDATE_REVENUE_SHARE_ITEM_ERROR';

export const UPDATE_REVENUE_SHARE_ITEMS = 'UPDATE_REVENUE_SHARE_ITEMS';
export const UPDATE_REVENUE_SHARE_ITEMS_START = 'UPDATE_REVENUE_SHARE_ITEMS_START';
export const UPDATE_REVENUE_SHARE_ITEMS_SUCCESS = 'UPDATE_REVENUE_SHARE_ITEMS_SUCCESS';
export const UPDATE_REVENUE_SHARE_ITEMS_ERROR = 'UPDATE_REVENUE_SHARE_ITEMS_ERROR';

export const REMOVE_REVENUE_SHARE_ITEM = 'REMOVE_REVENUE_SHARE_ITEM';
export const REMOVE_REVENUE_SHARE_ITEM_START = 'REMOVE_REVENUE_SHARE_ITEM_START';
export const REMOVE_REVENUE_SHARE_ITEM_SUCCESS = 'REMOVE_REVENUE_SHARE_ITEM_SUCCESS';
export const REMOVE_REVENUE_SHARE_ITEM_ERROR = 'REMOVE_REVENUE_SHARE_ITEM_ERROR';

export const CLEAR_REVENUE_SHARE_ITEM_FILTERS_START = 'CLEAR_REVENUE_SHARE_ITEM_FILTERS_START';
export const CLEAR_REVENUE_SHARE_ITEM_FILTERS_SUCCESS = 'CLEAR_REVENUE_SHARE_ITEM_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_REVENUE_SHARE_ITEMS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_REVENUE_SHARE_ITEMS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_REVENUE_SHARE_ITEMS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_REVENUE_SHARE_ITEMS_CHANNEL';

export const CREATE_REVENUE_SHARE_ITEM_FORM = 'CREATE_REVENUE_SHARE_ITEM_FORM';
export const UPDATE_REVENUE_SHARE_ITEMS_FORM = 'UPDATE_REVENUE_SHARE_ITEMS_FORM';
