import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  uploadItemsStart, uploadItemsSuccess, uploadItemsError,
  uploadItemStart, uploadItemSuccess, uploadItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  clearModalValues, setItemsFetched
} from '../../common/reducers';

import {
  attachFileAttachmentsError,
  attachFileAttachmentsStart,
  attachFileAttachmentsSuccess
} from './attachFileAttachments';

import {
  removeFileAttachmentsError,
  removeFileAttachmentsStart,
  removeFileAttachmentsSuccess
} from './removeFileAttachments';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_FILE_ATTACHMENTS_START: fetchItemsStart,
  FETCH_FILE_ATTACHMENTS_SUCCESS: fetchItemsSuccess,
  FETCH_FILE_ATTACHMENTS_ERROR: fetchItemsError,

  SET_FILE_ATTACHMENTS_FETCHED: setItemsFetched,

  UPDATE_FILE_ATTACHMENT_START: updateItemStart,
  UPDATE_FILE_ATTACHMENT_SUCCESS: updateItemSuccess,
  UPDATE_FILE_ATTACHMENT_ERROR: updateItemError,

  REMOVE_FILE_ATTACHMENT_START: removeItemStart,
  REMOVE_FILE_ATTACHMENT_SUCCESS: removeItemSuccess,
  REMOVE_FILE_ATTACHMENT_ERROR: removeItemError,

  UPLOAD_FILE_ATTACHMENTS_START: uploadItemsStart,
  UPLOAD_FILE_ATTACHMENTS_SUCCESS: uploadItemsSuccess,
  UPLOAD_FILE_ATTACHMENTS_ERROR: uploadItemsError,
  UPLOAD_FILE_ATTACHMENT_START: uploadItemStart,
  UPLOAD_FILE_ATTACHMENT_SUCCESS: uploadItemSuccess,
  UPLOAD_FILE_ATTACHMENT_ERROR: uploadItemError,

  ATTACH_FILE_ATTACHMENTS_START: attachFileAttachmentsStart,
  ATTACH_FILE_ATTACHMENTS_SUCCESS: attachFileAttachmentsSuccess,
  ATTACH_FILE_ATTACHMENTS_ERROR: attachFileAttachmentsError,

  REMOVE_TASK_FILE_ATTACHMENTS_START: removeFileAttachmentsStart,
  REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS: removeFileAttachmentsSuccess,
  REMOVE_TASK_FILE_ATTACHMENTS_ERROR: removeFileAttachmentsError,

  CLEAR_FILE_ATTACHMENTS_FILTERS_START: clearItemsFiltersStart,
  CLEAR_FILE_ATTACHMENTS_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_FILE_ATTACHMENT_MODAL_VALUES: clearModalValues
});
