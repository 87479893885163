import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '../utils';

export default function providePropsToContainer(scope, { namespace, ...props }) {
  return {
    currentUser: get(props, 'currentUser'),
    scope,
    action: get(props, 'action'),
    fetchType: get(props, 'fetchType'),
    id: get(props, 'id'),
    // subscriptions: get(props, 'subscriptions'),
    namespace,
    pageSize: get(props, 'pageSize'),
    withoutPagination: get(props, 'withoutPagination'),
    filters: get(props, 'filters', EMPTY_OBJECT),
    item: get(props, 'item', EMPTY_OBJECT),
    items: get(props, 'items', EMPTY_ARRAY),
    itemsMeta: namespace ? get(props, `${namespace}.itemsMeta`, EMPTY_OBJECT) : get(props, 'itemsMeta', EMPTY_OBJECT),
    fetchItemsCallback: get(props, `fetch${capitalize(scope)}Callback`),
    persistFilters: get(props, 'persistFilters'),
    serializer: get(props, 'serializer'),
    fetchOptions: get(props, 'fetchOptions'),
    createOptions: get(props, 'createOptions'),
    updateOptions: get(props, 'updateOptions'),
    removeOptions: get(props, 'removeOptions'),
    afterCreate: get(props, 'afterCreate'),
    afterUpdate: get(props, 'afterUpdate'),
    afterRemove: get(props, 'afterRemove')
  };
}
