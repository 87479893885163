import get from 'lodash/get';

import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function toggleProjectNotificationsStart(state, { id, options = {} }) {
  const currentItem = currentStateValue(state, 'item', options);

  return updateState(state, {
    // itemLoading: true,
    // itemErrorMessage: null,
    item: {
      ...currentItem,
      updating_notifications: true,
      error: null
    },
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      updating_notifications: true,
      error: null
    }))
  }, options);
}

export function toggleProjectNotificationsSuccess(state, { id, options = {} }) {
  const currentItem = currentStateValue(state, 'item', options);

  return updateState(state, {
    // itemLoading: false,
    // itemErrorMessage: null,
    item: {
      ...currentItem,
      has_notifications: !get(currentItem, 'has_notifications'),
      updating_notifications: false,
      error: null
    },
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      updating_notifications: false,
      error: null
    }))
  }, options);
}

export function toggleProjectNotificationsError(state, { id, errorMessage, options = {} }) {
  const currentItem = currentStateValue(state, 'item', options);

  return updateState(state, {
    // itemLoading: false,
    // itemErrorMessage: errorMessage,
    item: {
      ...currentItem,
      updating_notifications: false,
      error: errorMessage
    },
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      updating_notifications: false,
      error: errorMessage
    }))
  }, options);
}
