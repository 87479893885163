import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CREATE_PROJECTS_BILLS,
  CREATE_PROJECTS_BILLS_START,
  CREATE_PROJECTS_BILLS_SUCCESS,
  CREATE_PROJECTS_BILLS_ERROR
} from '../ProjectConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* createProjectsBills({ values, options, cb }) {
  yield put(postItemsStart(CREATE_PROJECTS_BILLS_START, options));

  const createProjectsBillsAction = yield spawn(postItemsResource, 'tasks/create_bills', {
    success: CREATE_PROJECTS_BILLS_SUCCESS,
    error: CREATE_PROJECTS_BILLS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(createProjectsBillsAction);
}

export default function* watchCreateProjectsBills() {
  yield takeEvery(CREATE_PROJECTS_BILLS, createProjectsBills);
}
