export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_START = 'FETCH_ROLE_START';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_ERROR = 'FETCH_ROLE_ERROR';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const REMOVE_ROLE = 'REMOVE_ROLE';
export const REMOVE_ROLE_START = 'REMOVE_ROLE_START';
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_ERROR = 'REMOVE_ROLE_ERROR';

export const ADD_PERMISSION_TO_ROLE = 'ADD_PERMISSION_TO_ROLE';
export const ADD_PERMISSION_TO_ROLE_START = 'ADD_PERMISSION_TO_ROLE_START';
export const ADD_PERMISSION_TO_ROLE_SUCCESS = 'ADD_PERMISSION_TO_ROLE_SUCCESS';
export const ADD_PERMISSION_TO_ROLE_ERROR = 'ADD_PERMISSION_TO_ROLE_ERROR';

export const REMOVE_PERMISSION_FROM_ROLE = 'REMOVE_PERMISSION_FROM_ROLE';
export const REMOVE_PERMISSION_FROM_ROLE_START = 'REMOVE_PERMISSION_FROM_ROLE_START';
export const REMOVE_PERMISSION_FROM_ROLE_SUCCESS = 'REMOVE_PERMISSION_FROM_ROLE_SUCCESS';
export const REMOVE_PERMISSION_FROM_ROLE_ERROR = 'REMOVE_PERMISSION_FROM_ROLE_ERROR';

export const CLEAR_ROLE_FILTERS_START = 'CLEAR_ROLE_FILTERS_START';
export const CLEAR_ROLE_FILTERS_SUCCESS = 'CLEAR_ROLE_FILTERS_SUCCESS';
