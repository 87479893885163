import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { updateIntl as updateIntlAction } from 'react-intl-redux';
import ScrollToTop from 'react-scroll-up';
import cl from 'classnames';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';

import { scrollToTopStyle } from '../../AppConstants';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';
import { useAuth } from '../../../common/hooks/useAuth';

import { Footer } from '../../components/ts/Footer';
import { AdminHeader } from '../../components/ts/AdminHeader';
import { admin } from '../../routes/admin';

import { Loading } from '../../../helpers/ts/Loading';
import { loadAllLocales } from '../../../utils/ts/loadAllLocales';
import { profileLocaleInStorage } from '../../../utils/ts/profileLocaleInStorage';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "errors" */ '../../components/ErrorPage/ErrorPage'
    )
});

function AdminAppContainer({
  pathname,
  authFetched,
  websocketsFetched,
  currentUser,
  validateToken,
  initWebsockets,
  logoutUser,
  updateIntl,
  history
}) {
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [isSidebarMobile, setIsSidebarMobile] = useState(false);
  const { authUser, logoutUser: authLogoutUser } = useAuth();

  const toggleSidebarHidden = useCallback(
    () => setIsSidebarHidden(prevState => !prevState),
    [setIsSidebarHidden]
  );
  const toggleSidebarMobile = useCallback(
    () => setIsSidebarMobile(prevState => !prevState),
    [setIsSidebarMobile]
  );

  const handleLogout = useCallback(() => {
    logoutUser();
    authLogoutUser();
    history.push('/auth/login');
  }, [logoutUser, authLogoutUser, history]);

  useEffect(() => {
    validateToken().then(({ currentUser: loadedCurrentUser }) => {
      if (loadedCurrentUser) {
        authUser(loadedCurrentUser);
        initWebsockets();

        if (process.env.NODE_ENV === 'production') {
          Sentry.configureScope(scope => {
            scope.setUser({ id: get(loadedCurrentUser, 'id') });
          });
        }

        const locale = get(loadedCurrentUser, 'locale') || 'en';

        return loadAllLocales(locale).then(messages => {
          updateIntl({
            locale,
            messages
          });

          profileLocaleInStorage.setLocale(locale);
        });
      }

      logoutUser();
      authLogoutUser();
      return history.push('/auth/login');
    });
  }, [
    history,
    initWebsockets,
    logoutUser,
    updateIntl,
    validateToken,
    authUser,
    authLogoutUser
  ]);

  return (
    <Fragment>
      <AdminHeader
        pathname={pathname}
        currentUser={currentUser}
        onToggleSidebarHidden={toggleSidebarHidden}
        onToggleSidebarMobile={toggleSidebarMobile}
        onLogout={handleLogout}
      />
      <div
        className={cl('page-content', {
          'sidebar-hidden': isSidebarHidden,
          'sidebar-mobile': isSidebarMobile
        })}
      >
        <div className="content-wrapper">
          <div className="content content-boxed">
            <Loading loaded={authFetched && websocketsFetched}>
              <Switch>
                {admin}
                <Route
                  key="error"
                  render={props => <ErrorPage statusCode={404} {...props} />}
                />
              </Switch>
              <ScrollToTop showUnder={160} style={scrollToTopStyle}>
                <span className="icon-arrow-up13 icon-2x" />
              </ScrollToTop>
            </Loading>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

AdminAppContainer.propTypes = {
  pathname: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  authFetched: PropTypes.bool.isRequired,
  websocketsFetched: PropTypes.bool.isRequired,
  updateIntl: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  initWebsockets: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default compose(
  connect(
    ({ app: { pathname } }) => ({ pathname }),
    {
      updateIntl: updateIntlAction
    }
  ),
  withCurrentUser({
    fields: ['authFetched', 'websocketsFetched'],
    actions: ['validateToken', 'initWebsockets', 'logoutUser']
  }),
  withRouter
)(AdminAppContainer);
