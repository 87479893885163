import { UPDATE_MESSAGE } from '../MessageConstants';

// createMessage

export default function updateMessage(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: UPDATE_MESSAGE,
    id,
    values,
    options,
    cb
  });
}
