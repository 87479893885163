export default {
  select: 'Select teams',
  plural: 'Teams',
  plural_downcase: 'teams',
  singular: 'Team',
  add: 'Add team',
  'add...': 'Add teams...',
  change: 'Change teams',
  change_creator: 'Change team creator',
  create: 'Create team',
  creator: 'Team creator',
  create_and_view: 'Create and view team',
  'select...': 'Select teams...',
  selected: 'Selected teams',
  name: 'Team name',
  no: 'No teams',
  of: 'Team of',
  type: 'Team type',
  select_type: 'Select team type',
  view: 'View team',
  members: 'Team members',
  select_user: 'Select user',
  users: 'Team users',
  profile: 'Team profile',
  delete: 'Delete Team',
  deleted: 'Team Deleted'
};
