import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_LINKED_ACCOUNT,
  CREATE_LINKED_ACCOUNT_START,
  CREATE_LINKED_ACCOUNT_SUCCESS,
  CREATE_LINKED_ACCOUNT_ERROR
} from '../LinkedAccountConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createLinkedAccount({ values, options, cb }) {
  yield put(createItemStart(CREATE_LINKED_ACCOUNT_START, options));

  return yield spawn(createItemResource, 'linked_users', {
    success: CREATE_LINKED_ACCOUNT_SUCCESS,
    error: CREATE_LINKED_ACCOUNT_ERROR
  }, { values, options, cb });
}

export default function* watchCreateLinkedAccount() {
  yield takeEvery(CREATE_LINKED_ACCOUNT, createLinkedAccount);
}
