import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  RECALCULATE_BALANCE_ACCOUNT,
  RECALCULATE_BALANCE_ACCOUNT_START,
  RECALCULATE_BALANCE_ACCOUNT_SUCCESS,
  RECALCULATE_BALANCE_ACCOUNT_ERROR
} from '../BalanceConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* recalculateBalanceAccount({ id, values, options, cb }) {
  yield put(postItemStart(RECALCULATE_BALANCE_ACCOUNT_START, id, options));

  const recalculateBalanceAccountAction = yield spawn(postItemResource, `balances/${id}/recalculate_account`, {
    success: RECALCULATE_BALANCE_ACCOUNT_SUCCESS,
    error: RECALCULATE_BALANCE_ACCOUNT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(recalculateBalanceAccountAction);
}

export default function* watchRecalculateBalanceAccount() {
  yield takeEvery(RECALCULATE_BALANCE_ACCOUNT, recalculateBalanceAccount);
}
