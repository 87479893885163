import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const PromoCodeShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_promo_codes" */ './pages/PromoCodeShowPage/PromoCodeShowPage'
    )
});
const PromoCodesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_promo_codes" */ './pages/PromoCodesIndexPage/PromoCodesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/promo_codes/:promoCodeId(\d+)"
      component={PromoCodeShowPage}
    />
    <Route exact path="/admin/promo_codes" component={PromoCodesIndexPage} />
  </Fragment>
);
