import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PROJECT_NOTIFICATION,
  REMOVE_PROJECT_NOTIFICATION_START,
  REMOVE_PROJECT_NOTIFICATION_SUCCESS,
  REMOVE_PROJECT_NOTIFICATION_ERROR
} from '../ProjectNotificationConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeProjectNotification({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_PROJECT_NOTIFICATION_START, id, options));

  return yield spawn(removeItemResource, 'task_notifications', {
    success: REMOVE_PROJECT_NOTIFICATION_SUCCESS,
    error: REMOVE_PROJECT_NOTIFICATION_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveProjectNotification() {
  yield takeEvery(REMOVE_PROJECT_NOTIFICATION, removeProjectNotification);
}
