import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { translate as t } from '../../../utils/ts/translate';

function parseError(error: {} | [] | string) {
  const descriptionErrors = get(error, 'response.data.errors.full_messages');

  if (isArray(descriptionErrors)) {
    return descriptionErrors.join(', ');
  }

  const errors = get(error, 'response.data.errors');

  if (isArray(errors)) {
    return errors.join(', ');
  }

  if (isString(errors)) {
    return errors;
  }

  const error500 = get(error, 'response.data.error');

  if (error500) {
    return error500;
  }

  if (isString(get(error, 'response.data.errors.0'))) {
    return get(error, 'response.data.errors.0');
  }

  if (get(error, 'response.status') === 403) {
    return t('words.forbidden_error');
  }

  console.log('error', { error }, error);

  if (get(error, 'response')) {
    return 'Invalid server response';
  } else {
    return 'Request Timeout';
  }
}

export default parseError;
