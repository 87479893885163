import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeTaskSelectedProductStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_facilities: replaceById(get(task, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeTaskSelectedProductSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_facilities: removeById(get(task, 'selected_facilities', []), id)
    }))
  }, options);
}

export function removeTaskSelectedProductError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_facilities: replaceById(get(task, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
