import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';
import trimStart from 'lodash/trimStart';

import { CurrentUser } from '../../../../../types';

import { PureLinkTo } from '../../../../../helpers/ts/PureLinkTo';

interface LinkItemType {
  type: 'link';
  namespace: string;
  href: string;
  label: string;
  icon?: string;
}

const dropdownLinks: LinkItemType[] = [
  {
    type: 'link',
    namespace: 'products',
    href: 'products',
    label: 'models.products.plural',
    icon: 'icon-cube4'
  },
  {
    type: 'link',
    namespace: 'lifestyles',
    href: 'lifestyles',
    label: 'models.lifestyles.plural',
    icon: 'icon-design'
  },
  {
    type: 'link',
    namespace: 'materials',
    href: 'materials',
    label: 'models.materials.plural',
    icon: 'icon-chess'
  }
];

interface StockDropdownProps {
  currentUser: CurrentUser;
}

function StockDropdown({
  currentUser,
  location: { pathname },
  history
}: StockDropdownProps & RouteComponentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setIsOpen(value => !value), []);
  const handleCollapse = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const unlisten = history.listen(handleCollapse);
    return unlisten;
  }, [history, handleCollapse]);

  const linksWithPermission = useMemo((): LinkItemType[] => {
    return dropdownLinks.filter(link =>
      currentUser.hasPermissions(`read_header_${link.namespace}_link`)
    );
  }, [currentUser]);

  const isDropdownActive = useMemo(() => {
    return linksWithPermission.some(
      ({ namespace }) => namespace === trimStart(pathname, '/')
    );
  }, [linksWithPermission, pathname]);

  if (isEmpty(linksWithPermission)) {
    return null;
  }

  return (
    <OutsideClickHandler onOutsideClick={handleCollapse}>
      <div className={cl('dropdown', { show: isOpen })}>
        <PureLinkTo
          pureLink
          className={cl('navbar-nav-link dropdown-toggle bg-indigo-700 pr-2', {
            active: isDropdownActive
          })}
          aria-expanded={isOpen}
          onClick={handleToggleOpen}
          i18nText="words.stock_3d"
        />
        <div
          className={cl('dropdown-menu dropdown-menu-right', { show: isOpen })}
        >
          {linksWithPermission.map(link => (
            <PureLinkTo
              key={`link-menu-${link.namespace}-item`}
              className={cl('dropdown-item', {
                active: new RegExp(`^/${link.namespace}`).test(pathname)
              })}
              href={link.href}
              icon={link.icon}
              i18nText={link.label}
            />
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default withRouter(memo(StockDropdown));
