import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  ASSIGN_PROJECT_TO_USER,
  ASSIGN_PROJECT_TO_USER_START,
  ASSIGN_PROJECT_TO_USER_SUCCESS,
  ASSIGN_PROJECT_TO_USER_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* assignProjectToUser({ id, values, options, cb }) {
  yield put(postItemStart(ASSIGN_PROJECT_TO_USER_START, id, options));

  const assignProjectToUserAction = yield spawn(postItemResource, `tasks/${id}/assign_to_user`, {
    success: ASSIGN_PROJECT_TO_USER_SUCCESS,
    error: ASSIGN_PROJECT_TO_USER_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(assignProjectToUserAction);
}

export default function* watchAssignProjectToUser() {
  yield takeEvery(ASSIGN_PROJECT_TO_USER, assignProjectToUser);
}
