import { SUBMIT_MESSAGE_SELECTED_TEMPLATES } from '../MessageConstants';

// submitMessageSelectedTemplates

export default function submitMessageSelectedTemplates(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: SUBMIT_MESSAGE_SELECTED_TEMPLATES,
    id,
    values,
    options,
    cb
  });
}
