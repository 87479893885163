import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_MESSAGES_XLS,
  DOWNLOAD_MESSAGES_XLS_START,
  DOWNLOAD_MESSAGES_XLS_SUCCESS,
  DOWNLOAD_MESSAGES_XLS_ERROR
} from '../MessageConstants';

import { downloadItemsStart, downloadItemsResource } from '../../common/sagas';

function* downloadMessagesXls(params) {
  yield put(downloadItemsStart(DOWNLOAD_MESSAGES_XLS_START, params));

  const downloadMessagesXlsAction = yield spawn(
    downloadItemsResource,
    'discussion_messages.xlsx',
    {
      success: DOWNLOAD_MESSAGES_XLS_SUCCESS,
      error: DOWNLOAD_MESSAGES_XLS_ERROR
    },
    params
  );
  yield take(LOCATION_CHANGE);
  yield cancel(downloadMessagesXlsAction);
}

export default function* watchDownloadMessages() {
  yield takeEvery(DOWNLOAD_MESSAGES_XLS, downloadMessagesXls);
}
