import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const SentMessageShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_sent_messages" */ './pages/SentMessageShowPage/SentMessageShowPage'
    )
});
const SentMessagesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_sent_messages" */ './pages/SentMessagesIndexPage/SentMessagesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/sent_messages/:sentMessageId(\d+)"
      component={SentMessageShowPage}
    />
    <Route
      exact
      path="/admin/sent_messages"
      component={SentMessagesIndexPage}
    />
  </Fragment>
);
