import uniqBy from 'lodash/uniqBy';

import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function tasksChannelMessages(state, { data, options = {} }) {
  return updateState(state, {
    items: uniqBy([data, ...currentStateValue(state, 'items', options, [])], i => i.id)
  }, options);
}
