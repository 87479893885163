import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  TRACK_TASK_TIME,
  TRACK_TASK_TIME_START,
  TRACK_TASK_TIME_SUCCESS,
  TRACK_TASK_TIME_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* trackTaskTime({ id, values, options, cb }) {
  yield put(postItemStart(TRACK_TASK_TIME_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/track_time`, {
    success: TRACK_TASK_TIME_SUCCESS,
    error: TRACK_TASK_TIME_ERROR
  }, { id, values, options, cb });
}

export default function* watchTrackTaskTime() {
  yield takeEvery(TRACK_TASK_TIME, trackTaskTime);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   TRACK_TASK_TIME,
//   TRACK_TASK_TIME_START,
//   TRACK_TASK_TIME_SUCCESS,
//   TRACK_TASK_TIME_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function trackTaskTimeStart(id) {
//   return {
//     type: TRACK_TASK_TIME_START,
//     id
//   };
// }
//
// export function trackTaskTimeSuccess(id, item = {}) {
//   return {
//     type: TRACK_TASK_TIME_SUCCESS,
//     id,
//     item
//   };
// }
//
// export function trackTaskTimeError(id, error) {
//   return {
//     type: TRACK_TASK_TIME_ERROR,
//     errorMessage: parseError(error),
//     id,
//     error
//   };
// }
//
// function trackTaskTimeRequest(taskId, values, options = {}) {
//   return apiRequest.post(`discussions/${taskId}/track_time`, { discussion: values }, options);
// }
//
// function* trackTaskTimeResource({ taskId, values, options: { form, serializer }, cb }) {
//   try {
//     yield call(trackTaskTimeRequest, taskId, values, { serializer });
//     yield put(trackTaskTimeSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(trackTaskTimeError(taskId, error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* trackTaskTime({ taskId, values, options, cb }) {
//   yield put(trackTaskTimeStart(taskId));
//
//   return yield spawn(trackTaskTimeResource, { taskId, values, options, cb });
// }
//
// export default function* watchTrackTaskTime() {
//   yield takeEvery(TRACK_TASK_TIME, trackTaskTime);
// }
