import { SUBMIT_MESSAGE_SELECTED_MATERIALS } from '../MessageConstants';

// submitMessageSelectedMaterials

export default function submitMessageSelectedMaterials(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: SUBMIT_MESSAGE_SELECTED_MATERIALS,
    id,
    values,
    options,
    cb
  });
}
