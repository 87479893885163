import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import size from 'lodash/size';

import { PureLinkTo } from '../../helpers/ts/PureLinkTo';
import { PureButton } from '../../helpers/ts/PureButton';

import { isMenuItemActive } from './utils/isMenuItemActive';

function LinkMenuNestedDropdown({
  dropdownItem,
  toggle,
  dropdownState,
  collapse,
  pathname
}) {
  return (
    <OutsideClickHandler onOutsideClick={collapse}>
      {dropdownItem.links && size(dropdownItem.links) > 0 ? (
        <div className={cl('dropleft', { show: dropdownState })}>
          <PureButton
            className={cl('dropdown-item dropdown-toggle pr-1', {
              active: isMenuItemActive({ pathname, item: dropdownItem })
            })}
            aria-expanded={dropdownState}
            onClick={toggle}
            i18nText={dropdownItem.label}
          />
          <div className={cl('dropdown-menu', { show: dropdownState })}>
            {dropdownItem.links.map(link => (
              <Fragment key={`link-menu-${link.namespace}-item`}>
                <PureLinkTo
                  className={cl('dropdown-item', {
                    active: isMenuItemActive({ pathname, item: link })
                  })}
                  href={link.href}
                  icon={link.icon}
                  i18nText={link.label}
                />
              </Fragment>
            ))}
          </div>
        </div>
      ) : null}
    </OutsideClickHandler>
  );
}

LinkMenuNestedDropdown.propTypes = {
  dropdownItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        ref: PropTypes.string,
        namespace: PropTypes.string,
        label: PropTypes.string
      })
    )
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  dropdownState: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired
};

export default compose(
  withState('dropdownState', 'changeDropdownState', false),
  withHandlers({
    toggle: ({ dropdownState, changeDropdownState }) => () =>
      changeDropdownState(!dropdownState),
    collapse: ({ dropdownState, changeDropdownState }) => () =>
      dropdownState && changeDropdownState(false)
  }),
  pure
)(LinkMenuNestedDropdown);
