import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_SELECTED_PRODUCT,
  REMOVE_SELECTED_PRODUCT_START,
  REMOVE_SELECTED_PRODUCT_SUCCESS,
  REMOVE_SELECTED_PRODUCT_ERROR
} from '../SelectedProductConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeSelectedProduct({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_SELECTED_PRODUCT_START, id, options));

  return yield spawn(removeItemResource, 'selected_facilities', {
    success: REMOVE_SELECTED_PRODUCT_SUCCESS,
    error: REMOVE_SELECTED_PRODUCT_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveSelectedProduct() {
  yield takeEvery(REMOVE_SELECTED_PRODUCT, removeSelectedProduct);
}
