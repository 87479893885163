import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit as submitAction } from 'redux-form';
import { compose, withState, withHandlers } from 'recompose';

import get from 'lodash/get';
import pickBy from 'lodash/pickBy';

import { ModalHelper } from '../../../../helpers/ModalHelper';
import { Button, LinkTo } from '../../../../helpers';

const withModalButton = params => WrappedComponent => {
  function ModalButtonHoc({
    addClass, className, link, pureLink, icon, color, buttonText, modalTitle, modalIcon, modalSize, withoutCancel,
    withoutSubmit, disabled, fetched, isLoading, autoFocus, form, withoutFooter, cancelAddClass, submitAddClass,
    submitDisabled, submitColor, submitI18nText, submitText, cancelI18nText, cancelText, submitIcon, size, submitTooltip,
    tooltip, tooltipTarget, tooltipValue, tooltipPlacement, beforeButton, afterButton, opened, hideModal, renderSubmit,
    handleSubmit, handleButtonClick, handleSave, toggleOpened, onEnter, onExit, onOpened, onClosed, onRemove, handleRemove,
    removeAddClass, removeI18nText, removeText, withRemove, backdrop, toggleBackdrop, href, scrollToTop, scrollButtonStyle, ...props
  }) {
    const C = link ? LinkTo : Button;

    return (
      <Fragment>
        {beforeButton}
        <C
          id={tooltipTarget || get(tooltip, 'target')}
          addClass={addClass}
          className={className}
          icon={icon}
          tooltip={tooltip}
          tooltipTarget={tooltipTarget}
          tooltipValue={tooltipValue}
          tooltipPlacement={tooltipPlacement}
          color={color}
          size={link ? null : size}
          disabled={disabled}
          onClick={handleButtonClick}
          {...pickBy({ href: (link ? href || '#' : null), pureLink: (link || pureLink) })}
        >
          {buttonText}
        </C>
        {afterButton}
        <ModalHelper
          backdrop={backdrop}
          opened={opened}
          fetched={fetched}
          form={form}
          disabled={disabled}
          isLoading={isLoading}
          autoFocus={autoFocus}
          withoutToggle={params && params.withoutToggle}
          withoutCancel={withoutCancel}
          withoutSubmit={withoutSubmit}
          withRemove={withRemove}
          withoutFooter={withoutFooter}
          modalIcon={modalIcon}
          modalTitle={modalTitle}
          modalSize={modalSize}
          cancelAddClass={cancelAddClass}
          submitTooltip={submitTooltip}
          submitAddClass={submitAddClass}
          submitColor={submitColor}
          submitIcon={submitIcon}
          submitI18nText={submitI18nText}
          submitText={submitText}
          cancelI18nText={cancelI18nText}
          cancelText={cancelText}
          removeAddClass={removeAddClass}
          removeI18nText={removeI18nText}
          removeText={removeText}
          submitDisabled={submitDisabled}
          toggleOpened={toggleOpened}
          hideModal={hideModal}
          renderSubmit={renderSubmit}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleRemove={handleRemove}
          onEnter={onEnter}
          onExit={onExit}
          onOpened={onOpened}
          onClosed={onClosed}
          scrollToTop={scrollToTop}
          scrollButtonStyle={scrollButtonStyle}
        >
          <WrappedComponent
            form={form}
            fetched={fetched}
            isLoading={isLoading}
            hideModal={hideModal}
            toggleBackdrop={toggleBackdrop}
            toggleOpened={toggleOpened}
            buttonText={buttonText}
            {...props}
          />
        </ModalHelper>
      </Fragment>
    );
  }

  ModalButtonHoc.propTypes = {
    className: PropTypes.string,
    addClass: PropTypes.string,
    cancelAddClass: PropTypes.string,
    submitAddClass: PropTypes.string,
    link: PropTypes.bool,
    pureLink: PropTypes.bool,
    buttonText: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.string,
    modalTitle: PropTypes.node,
    beforeButton: PropTypes.node,
    afterButton: PropTypes.node,
    modalIcon: PropTypes.string,
    submitIcon: PropTypes.string,
    modalSize: PropTypes.string,
    size: PropTypes.string,
    form: PropTypes.string,
    withoutFooter: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    submitColor: PropTypes.string,
    submitI18nText: PropTypes.string,
    submitText: PropTypes.node,
    cancelI18nText: PropTypes.string,
    cancelText: PropTypes.node,
    removeAddClass: PropTypes.string,
    removeI18nText: PropTypes.string,
    removeText: PropTypes.node,
    opened: PropTypes.bool.isRequired,
    backdrop: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    withoutSubmit: PropTypes.bool,
    fetched: PropTypes.bool,
    isLoading: PropTypes.bool,
    autoFocus: PropTypes.bool,
    data: PropTypes.shape(),
    tooltip: PropTypes.shape({
      target: PropTypes.string.isRequired,
      value: PropTypes.node,
      i18nText: PropTypes.node,
      placement: PropTypes.string
    }),
    submitTooltip: PropTypes.shape({
      target: PropTypes.string.isRequired,
      value: PropTypes.node,
      i18nText: PropTypes.node,
      placement: PropTypes.string
    }),
    tooltipTarget: PropTypes.string,
    tooltipValue: PropTypes.node,
    tooltipPlacement: PropTypes.string,
    preventClose: PropTypes.bool,
    withoutCancel: PropTypes.bool,
    withRemove: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    onRemove: PropTypes.func,
    handleRemove: PropTypes.func,
    submit: PropTypes.func,
    renderSubmit: PropTypes.func,
    handleButtonClick: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    toggleOpened: PropTypes.func.isRequired,
    toggleBackdrop: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    href: PropTypes.string,
    scrollToTop: PropTypes.bool,
    scrollButtonStyle: PropTypes.shape()
  };

  ModalButtonHoc.defaultProps = {
    className: null,
    addClass: null,
    cancelAddClass: null,
    submitAddClass: null,
    beforeButton: null,
    afterButton: null,
    link: null,
    pureLink: null,
    buttonText: null,
    icon: null,
    color: null,
    modalTitle: null,
    modalIcon: null,
    submitIcon: null,
    modalSize: 'sm',
    size: 'sm',
    disabled: false,
    withoutSubmit: false,
    fetched: true,
    isLoading: false,
    autoFocus: false,
    form: null,
    withoutFooter: false,
    submitDisabled: null,
    submitColor: 'primary',
    submitI18nText: null,
    submitText: null,
    cancelI18nText: null,
    cancelText: null,
    removeAddClass: null,
    removeI18nText: null,
    removeText: null,
    preventClose: false,
    data: {},
    withoutCancel: false,
    withRemove: false,
    onOpen: null,
    onClose: null,
    onEnter: null,
    onExit: null,
    onOpened: null,
    onClosed: null,
    onSave: null,
    onRemove: null,
    onToggle: null,
    submit: null,
    renderSubmit: null,
    tooltip: null,
    submitTooltip: null,
    tooltipTarget: null,
    tooltipValue: null,
    tooltipPlacement: null,
    handleRemove: null,
    scrollToTop: false,
    scrollButtonStyle: null
  };

  return compose(
    connect(null, { submit: submitAction }),
    withState('opened', 'setOpened', false),
    withState('backdrop', 'setBackdrop', true),
    withHandlers({
      handleAfterOpen: ({ opened, data, onOpen, onToggle }) => () => {
        onToggle && onToggle(opened, data);
        onOpen && onOpen(data);
      },
      handleAfterToggleOpened: ({ opened, data, onClose, onOpen, onToggle }) => () => {
        onToggle && onToggle(opened, data);

        if (opened && onOpen) {
          return onOpen(data);
        }

        return onClose && onClose(data);
      },
      handleAfterHide: ({ opened, data, onClose, onToggle }) => () => {
        onToggle && onToggle(opened, data);
        onClose && onClose(data);
      },
      handleAfterSave: ({ opened, data, onSave, onToggle }) => () => {
        onToggle && onToggle(opened, data);
        onSave && onSave(data);
      },
      handleAfterRemove: ({ setOpened }) => () => setOpened(false)
    }),
    withHandlers({
      // handleSubmit: ({ form, submit, setOpened }) => () => setOpened(false, () => {
      //   submit && submit(form);
      // }),
      handleSubmit: ({ form, submit }) => () => submit(form),
      handleButtonClick: ({ setOpened, handleAfterOpen }) => () => setOpened(true, handleAfterOpen),
      toggleOpened: ({ opened, preventClose, setOpened, handleAfterToggleOpened }) => () => !preventClose && setOpened(!opened, handleAfterToggleOpened),
      toggleBackdrop: ({ backdrop, setBackdrop }) => () => setBackdrop(!backdrop),
      hideModal: ({ setOpened, handleAfterHide }) => () => setOpened(false, handleAfterHide),
      handleSave: ({ setOpened, handleAfterSave }) => () => setOpened(false, handleAfterSave),
      handleRemove: ({ handleAfterRemove, onRemove, data }) => onRemove && onRemove(data, handleAfterRemove)
    })
  )(ModalButtonHoc);
};

export default withModalButton;

// import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { submit as submitAction } from 'redux-form';
// import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import { compose, withState, withHandlers, withProps } from 'recompose';
//
// import get from 'lodash/get';
// import pickBy from 'lodash/pickBy';
//
// import { Button, LinkTo } from '../../../helpers';
// import { translate as t } from '../../../utils';
//
// const withModalButton = params => WrappedComponent => {
//   function ModalButtonHoc({
//     addClass, className, link, pureLink, icon, color, buttonText, modalTitle, modalIcon, modalSize, withoutCancel, withoutSubmit,
//     disabled, fetched, isLoading, autoFocus, form, withoutFooter, cancelAddClass, submitAddClass, submitDisabled, submitColor, submitI18nText, submitIcon, size, children,
//     submitTooltip, tooltip, beforeButton, afterButton, opened, hideModal, renderSubmit, handleSubmit, handleButtonClick, handleSave, toggleOpened, ...props
//   }) {
//     const C = link ? LinkTo : Button;
//
//     return (
//       <Fragment>
//         {beforeButton}
//         <C
//           id={get(tooltip, 'target')}
//           addClass={addClass}
//           className={className}
//           icon={icon}
//           tooltip={tooltip}
//           color={color}
//           size={link ? null : size}
//           disabled={disabled}
//           onClick={handleButtonClick}
//           {...pickBy({ href: (link ? '#' : null), pureLink: (link || pureLink) })}
//         >
//           {buttonText}
//         </C>
//         {afterButton}
//         <Modal isOpen={opened} size={modalSize} toggle={params && params.withoutToggle ? null : toggleOpened} autoFocus={autoFocus}>
//           <ModalHeader toggle={toggleOpened}>
//             <div className="modal-title text-muted font-size-base">
//               <i className={modalIcon} />
//               {modalTitle}
//             </div>
//           </ModalHeader>
//           <ModalBody>
//             <WrappedComponent
//               form={form}
//               fetched={fetched}
//               isLoading={isLoading}
//               hideModal={hideModal}
//               toggleOpened={toggleOpened}
//               {...props}
//             />
//           </ModalBody>
//           {renderSubmit ? (
//             <ModalFooter className="card-footer py-2 text-right border-top">
//               {withoutCancel ? null : (
//                 <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={toggleOpened}>
//                   {t('words.cancel')}
//                 </Button>
//               )}
//               {renderSubmit({ hideModal })}
//             </ModalFooter>
//           ) : null}
//           {fetched && form && !withoutSubmit ? (
//             <ModalFooter className="card-footer py-2 text-right border-top">
//               {withoutCancel ? null : (
//                 <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={toggleOpened}>
//                   {t('words.cancel')}
//                 </Button>
//               )}
//               {withoutSubmit ? null : (
//                 <Button
//                   id={get(submitTooltip, 'target')}
//                   addClass={submitAddClass}
//                   disabled={disabled}
//                   size="sm"
//                   color={submitColor}
//                   icon={submitIcon}
//                   tooltip={submitTooltip}
//                   onClick={handleSubmit}
//                 >
//                   {isLoading ? t('words.processing') : (submitI18nText && t(submitI18nText)) || t('words.save')}
//                 </Button>
//               )}
//             </ModalFooter>
//           ) : null}
//           {withoutFooter ? null : (
//             <ModalFooter className="card-footer py-2 text-right border-top">
//               {withoutCancel ? null : (
//                 <Button addClass={cancelAddClass} disabled={submitDisabled} size="sm" onClick={toggleOpened}>
//                   {t('words.cancel')}
//                 </Button>
//               )}
//               <Button
//                 id={get(submitTooltip, 'target')}
//                 addClass={submitAddClass}
//                 disabled={submitDisabled}
//                 size="sm"
//                 color={submitColor}
//                 icon={submitIcon}
//                 tooltip={submitTooltip}
//                 onClick={handleSave}
//               >
//                 {isLoading ? t('words.processing') : (submitI18nText && t(submitI18nText)) || t('words.save')}
//               </Button>
//             </ModalFooter>
//           )}
//         </Modal>
//       </Fragment>
//     );
//   }
//
//   ModalButtonHoc.propTypes = {
//     className: PropTypes.string,
//     addClass: PropTypes.string,
//     link: PropTypes.bool,
//     pureLink: PropTypes.bool,
//     buttonText: PropTypes.node,
//     icon: PropTypes.string,
//     color: PropTypes.string,
//     modalTitle: PropTypes.node,
//     modalIcon: PropTypes.string,
//     modalSize: PropTypes.string,
//     size: PropTypes.string,
//     form: PropTypes.string,
//     withoutFooter: PropTypes.bool,
//     submitDisabled: PropTypes.bool,
//     submitColor: PropTypes.string,
//     submitI18nText: PropTypes.string,
//     disabled: PropTypes.bool,
//     fetched: PropTypes.bool,
//     isLoading: PropTypes.bool,
//     autoFocus: PropTypes.bool,
//     data: PropTypes.shape(),
//     tooltip: PropTypes.shape({
//       target: PropTypes.string.isRequired,
//       value: PropTypes.node.isRequired,
//       placement: PropTypes.string
//     }),
//     preventClose: PropTypes.bool,
//     withoutCancel: PropTypes.bool,
//     onOpen: PropTypes.func,
//     onClose: PropTypes.func,
//     onToggle: PropTypes.func,
//     onSave: PropTypes.func,
//     submit: PropTypes.func,
//     renderSubmit: PropTypes.func
//   };
//
//   ModalButtonHoc.defaultProps = {
//     className: null,
//     addClass: null,
//     link: null,
//     pureLink: null,
//     buttonText: null,
//     icon: null,
//     color: null,
//     modalTitle: null,
//     modalIcon: null,
//     modalSize: 'sm',
//     size: 'sm',
//     disabled: false,
//     fetched: true,
//     isLoading: false,
//     autoFocus: false,
//     form: null,
//     withoutFooter: false,
//     submitDisabled: null,
//     submitColor: 'primary',
//     submitI18nText: null,
//     preventClose: false,
//     data: {},
//     withoutCancel: false,
//     onOpen: null,
//     onClose: null,
//     onSave: null,
//     onToggle: null,
//     submit: null,
//     renderSubmit: null,
//     tooltip: null
//   };
//
//   return compose(
//     connect(null, { submit: submitAction }),
//     withState('opened', 'setOpened', false),
//     withHandlers({
//       handleAfterOpen: ({ opened, data, onOpen, onToggle }) => () => {
//         console.log('handleAfterOpen', { opened, onToggle });
//         onToggle && onToggle(opened, data);
//         onOpen && onOpen(data);
//       },
//       handleAfterToggleOpened: ({ opened, data, onClose, onOpen, onToggle }) => () => {
//         onToggle && onToggle(opened, data);
//
//         if (opened && onOpen) {
//           return onOpen(data);
//         }
//
//         return onClose && onClose(data);
//       },
//       handleAfterHide: ({ opened, data, onClose, onToggle }) => () => {
//         onToggle && onToggle(opened, data);
//         onClose && onClose(data);
//       },
//       handleAfterSave: ({ opened, data, onSave, onToggle }) => () => {
//         onToggle && onToggle(opened, data);
//         onSave && onSave(data);
//       }
//     }),
//     withHandlers({
//       // handleSubmit: ({ form, submit, setOpened }) => () => setOpened(false, () => {
//       //   submit && submit(form);
//       // }),
//       handleSubmit: ({ form, submit }) => () => submit(form),
//       handleButtonClick: ({ setOpened, handleAfterOpen }) => () => setOpened(true, handleAfterOpen),
//       toggleOpened: ({ opened, preventClose, setOpened, handleAfterToggleOpened }) => () => !preventClose && setOpened(!opened, handleAfterToggleOpened),
//       hideModal: ({ setOpened, handleAfterHide }) => () => setOpened(false, handleAfterHide),
//       handleSave: ({ setOpened, handleAfterSave }) => () => setOpened(false, handleAfterSave)
//     })
//   )(ModalButtonHoc);
// };
//
// export default withModalButton;
