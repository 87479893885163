import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';

export default function prepareErrors(error) {
  return fromPairs(keys(get(error, 'response.data.errors', {})).map(key => {
    const errorMessages = get(error, `response.data.errors.${key}`, []);

    return [
      key,
      isArray(errorMessages) ? errorMessages.join(', ') : errorMessages
    ];
  }));
}
