import { push } from 'react-router-redux';

function pushTo(location = '') {
  if (/^auth/.test(location)) {
    return dispatch => dispatch(push(`/${location}`));
  }

  return dispatch => dispatch(push(`${/^\/admin.+/.test(window.location.pathname) ? '/admin' : ''}/${location}`));
}

export default pushTo;
