import { createReducer } from 'redux-create-reducer';

import { initTemplatesFilters } from './common';
import {
  toggleSelectedTemplateStart,
  toggleSelectedTemplateSuccess,
  toggleSelectedTemplateError
} from './toggleSelectedTemplate';
import {
  toggleBlockedTemplateStart,
  toggleBlockedTemplateSuccess,
  toggleBlockedTemplateError
} from './toggleBlockedTemplate';

import {
  defaultState,
  downloadItemStart, downloadItemSuccess, downloadItemError,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  itemsChannelUpdated, locationChange, clearModalValues,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  AUTH_USER: initTemplatesFilters,

  DOWNLOAD_TEMPLATE_START: downloadItemStart,
  DOWNLOAD_TEMPLATE_SUCCESS: downloadItemSuccess,
  DOWNLOAD_TEMPLATE_ERROR: downloadItemError,

  FETCH_TEMPLATES_START: fetchItemsStart,
  FETCH_TEMPLATES_SUCCESS: fetchItemsSuccess,
  FETCH_TEMPLATES_ERROR: fetchItemsError,

  FETCH_TEMPLATE_START: fetchItemStart,
  FETCH_TEMPLATE_SUCCESS: fetchItemSuccess,
  FETCH_TEMPLATE_ERROR: fetchItemError,

  CREATE_TEMPLATE_START: createItemStart,
  CREATE_TEMPLATE_SUCCESS: createItemSuccess,
  CREATE_TEMPLATE_ERROR: createItemError,

  UPDATE_TEMPLATE_START: updateItemStart,
  UPDATE_TEMPLATE_SUCCESS: updateItemSuccess,
  UPDATE_TEMPLATE_ERROR: updateItemError,

  TOGGLE_SELECTED_TEMPLATE_START: toggleSelectedTemplateStart,
  TOGGLE_SELECTED_TEMPLATE_SUCCESS: toggleSelectedTemplateSuccess,
  TOGGLE_SELECTED_TEMPLATE_ERROR: toggleSelectedTemplateError,

  TOGGLE_BLOCKED_TEMPLATE_START: toggleBlockedTemplateStart,
  TOGGLE_BLOCKED_TEMPLATE_SUCCESS: toggleBlockedTemplateSuccess,
  TOGGLE_BLOCKED_TEMPLATE_ERROR: toggleBlockedTemplateError,

  REMOVE_TEMPLATE_START: removeItemStart,
  REMOVE_TEMPLATE_SUCCESS: removeItemSuccess,
  REMOVE_TEMPLATE_ERROR: removeItemError,

  CLEAR_TEMPLATE_FILTERS_START: clearItemsFiltersStart,
  CLEAR_TEMPLATE_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  TEMPLATES_CHANNEL_UPDATED: itemsChannelUpdated,

  CLEAR_TEMPLATE_MODAL_VALUES: clearModalValues
});
