import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SHARE_ALLOCATION_FROM_CLIENT_BALANCE,
  SHARE_ALLOCATION_FROM_CLIENT_BALANCE_START,
  SHARE_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS,
  SHARE_ALLOCATION_FROM_CLIENT_BALANCE_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* shareAllocationFromClientBalance({ id, options, cb }) {
  yield put(postItemStart(SHARE_ALLOCATION_FROM_CLIENT_BALANCE_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/allocate_from_client_balance`, {
    success: SHARE_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS,
    error: SHARE_ALLOCATION_FROM_CLIENT_BALANCE_ERROR
  }, { id, options, cb });
}

export default function* watchShareAllocationFromClientBalance() {
  yield takeEvery(SHARE_ALLOCATION_FROM_CLIENT_BALANCE, shareAllocationFromClientBalance);
}
