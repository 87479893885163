import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const BillShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_bills" */ './pages/BillShowPage/BillShowPage'
    )
});
const BillsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_bills" */ './pages/BillsIndexPage/BillsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/bills/:billId(\d+)" component={BillShowPage} />
    <Route exact path="/admin/bills" component={BillsIndexPage} />
  </Fragment>
);
