import { LocalStorage } from '../LocalStorage';

const IS_USER_COZY_KEY = 'is_user_cozy';

class CozyStorage {
  static getIsUserCozy(): boolean {
    const localStorageValue = LocalStorage.loadItem<boolean>(IS_USER_COZY_KEY);

    return Boolean(localStorageValue);
  }

  static setIsUserCozy(value: boolean): void {
    LocalStorage.saveItem<boolean>(IS_USER_COZY_KEY, value);
  }
}

export default CozyStorage;
