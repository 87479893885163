import { FETCH_ITEM_ERROR } from '../../constants';

import { ShowFinanceRequestAction } from '../../reducers/showFinanceRequestReducer';

function fetchItemError<ItemType>(
  itemErrorMessage: string
): ShowFinanceRequestAction<ItemType> {
  return {
    type: FETCH_ITEM_ERROR,
    itemErrorMessage
  };
}

export default fetchItemError;
