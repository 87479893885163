import isNumber from 'lodash/isNumber';

import { renderCurrency } from '../renderCurrency';

export default function renderAmount(
  amount: number | undefined,
  currency: string | boolean = '$',
  withSuffix = false,
  maximumFractionDigits: number | undefined = 2,
  minimumFractionDigits: number | undefined = 2
): string | null {
  if (!isNumber(amount)) {
    return null;
  }

  const toLocaleStringOptions = {
    minimumFractionDigits,
    maximumFractionDigits
  };

  if (amount >= 0) {
    return withSuffix
      ? `${amount.toLocaleString(
          'en-US',
          toLocaleStringOptions
        )}${renderCurrency(currency)}`
      : `${renderCurrency(currency)}${amount.toLocaleString(
          'en-US',
          toLocaleStringOptions
        )}`;
  }

  return withSuffix
    ? `-${Math.abs(amount).toLocaleString(
        'en-US',
        toLocaleStringOptions
      )}${renderCurrency(currency)}`
    : `${renderCurrency(currency)}-${Math.abs(amount).toLocaleString(
        'en-US',
        toLocaleStringOptions
      )}`;
}
