import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ADD_PERMISSION_TO_ROLE,
  ADD_PERMISSION_TO_ROLE_START,
  ADD_PERMISSION_TO_ROLE_SUCCESS,
  ADD_PERMISSION_TO_ROLE_ERROR
} from '../RoleConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* addPermissionToRole({ id, values, options, cb }) {
  yield put(postItemStart(ADD_PERMISSION_TO_ROLE_START, id, options));

  return yield spawn(postItemResource, `roles/${id}/add_permission`, {
    success: ADD_PERMISSION_TO_ROLE_SUCCESS,
    error: ADD_PERMISSION_TO_ROLE_ERROR
  }, { id, values, options, cb });
}

export default function* watchAddPermissionToRole() {
  yield takeEvery(ADD_PERMISSION_TO_ROLE, addPermissionToRole);
}
