import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function updateTaskSelectedMaterialsStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_materials: replaceById(get(task, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function updateTaskSelectedMaterialsSuccess(state, { id, item, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_materials: replaceById(get(task, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        ...item,
        error: null,
        loading: false
      }))
    }))
  }, options);
}

export function updateTaskSelectedMaterialsError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      selected_materials: replaceById(get(task, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
