import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const UserResetPasswordRequestsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_user_reset_password_requests" */ './pages/UserResetPasswordRequestsIndexPage/UserResetPasswordRequestsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/user_reset_password_requests"
      component={UserResetPasswordRequestsIndexPage}
    />
  </Fragment>
);
