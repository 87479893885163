import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPLOAD_SOURCE_FILES,
  UPLOAD_SOURCE_FILES_START,
  UPLOAD_SOURCE_FILE_START,
  UPLOAD_SOURCE_FILES_SUCCESS,
  UPLOAD_SOURCE_FILE_SUCCESS,
  UPLOAD_SOURCE_FILE_ERROR,
  UPLOAD_SOURCE_FILES_ERROR
} from '../SourceFileConstants';

import { uploadItemsResource } from '../../common/sagas';

function* uploadSourceFiles({ files, options, cb }) {
  const uploadSourceFilesAction = yield spawn(uploadItemsResource, 'source_files', {
    start: UPLOAD_SOURCE_FILES_START,
    eachStart: UPLOAD_SOURCE_FILE_START,
    success: UPLOAD_SOURCE_FILES_SUCCESS,
    eachSuccess: UPLOAD_SOURCE_FILE_SUCCESS,
    eachError: UPLOAD_SOURCE_FILE_ERROR,
    error: UPLOAD_SOURCE_FILES_ERROR,
    param: 'source_file'
  }, { files, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(uploadSourceFilesAction);
}

export default function* watchUploadSourceFiles() {
  yield takeEvery(UPLOAD_SOURCE_FILES, uploadSourceFiles);
}
