import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  CREATE_INCOMING_TRANSFERS,
  CREATE_INCOMING_TRANSFERS_START,
  CREATE_INCOMING_TRANSFERS_SUCCESS,
  CREATE_INCOMING_TRANSFERS_ERROR
} from '../PaymentConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* createIncomingTransfers({ values, options, cb }) {
  yield put(postItemsStart(CREATE_INCOMING_TRANSFERS_START, options));

  const createIncomingTransfersAction = yield spawn(postItemsResource, 'transactions/incoming_transfers', {
    success: CREATE_INCOMING_TRANSFERS_SUCCESS,
    error: CREATE_INCOMING_TRANSFERS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(createIncomingTransfersAction);
}

export default function* watchCreateIncomingTransfers() {
  yield takeEvery(CREATE_INCOMING_TRANSFERS, createIncomingTransfers);
}
