import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  INCOMPLETE_SPECIFICATION_TASK,
  INCOMPLETE_SPECIFICATION_TASK_START,
  INCOMPLETE_SPECIFICATION_TASK_SUCCESS,
  INCOMPLETE_SPECIFICATION_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* incompleteSpecificationTask({ id, values, options, cb }) {
  yield put(postItemStart(INCOMPLETE_SPECIFICATION_TASK_START, id, options));

  return yield spawn(
    postItemResource,
    `discussions/${id}/incomplete_specification`,
    {
      success: INCOMPLETE_SPECIFICATION_TASK_SUCCESS,
      error: INCOMPLETE_SPECIFICATION_TASK_ERROR
    },
    { id, values, options, cb }
  );
}

export default function* watchIncompleteSpecificationTask() {
  yield takeEvery(INCOMPLETE_SPECIFICATION_TASK, incompleteSpecificationTask);
}
