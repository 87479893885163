import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import keys from 'lodash/keys';
import upperFirst from 'lodash/upperFirst';

export default function updateState(state, data, options = {}) {
  return {
    ...state,
    ...(options.namespace ? {
      [options.namespace]: {
        ...get(state, options.namespace),
        ...data
      }
    } : data),
    ...(options.namespace ? fromPairs(keys(data).map(key => [
      `${options.namespace}-${key}`,
      data[key]
    ])) : {}),
    ...(options.namespace ? fromPairs(keys(data).map(key => [
      `${options.namespace}${upperFirst(key)}`,
      data[key]
    ])) : {})
  };
}
