import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemStart, fetchItemSuccess, fetchItemError
} from '../../common/reducers';

import {
  createMaterialsSetSelectedMaterialsSuccess
} from './createMaterialsSetSelectedMaterials';

import {
  emptyMaterialsSetStart,
  emptyMaterialsSetSuccess,
  emptyMaterialsSetError
} from './emptyMaterialsSet';

import {
  removeMaterialsSetSelectedMaterialsError,
  removeMaterialsSetSelectedMaterialsStart,
  removeMaterialsSetSelectedMaterialsSuccess
} from './removeMaterialsSetSelectedMaterials';

export default createReducer(defaultState, {
  FETCH_MATERIALS_SET_START: fetchItemStart,
  FETCH_MATERIALS_SET_SUCCESS: fetchItemSuccess,
  FETCH_MATERIALS_SET_ERROR: fetchItemError,

  CREATE_SELECTED_MATERIAL_SUCCESS: createMaterialsSetSelectedMaterialsSuccess,

  REMOVE_SELECTED_MATERIAL_START: removeMaterialsSetSelectedMaterialsStart,
  REMOVE_SELECTED_MATERIAL_SUCCESS: removeMaterialsSetSelectedMaterialsSuccess,
  REMOVE_SELECTED_MATERIAL_ERROR: removeMaterialsSetSelectedMaterialsError,

  EMPTY_MATERIALS_SET_START: emptyMaterialsSetStart,
  EMPTY_MATERIALS_SET_SUCCESS: emptyMaterialsSetSuccess,
  EMPTY_MATERIALS_SET_ERROR: emptyMaterialsSetError
});
