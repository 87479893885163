import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_DOWNLOADS_START,
  FETCH_DOWNLOADS,
  FETCH_DOWNLOADS_SUCCESS,
  FETCH_DOWNLOADS_ERROR
} from '../DownloadConstants';

import { fetchItemsResource, fetchItemsStart } from '../../common/sagas';

function* fetchDownloads(params) {
  yield put(fetchItemsStart(FETCH_DOWNLOADS_START, params));

  const fetchDownloadsAction = yield spawn(fetchItemsResource, 'downloads', {
    success: FETCH_DOWNLOADS_SUCCESS,
    error: FETCH_DOWNLOADS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchDownloadsAction);
}

export default function* watchFetchDownloads() {
  yield takeEvery(FETCH_DOWNLOADS, fetchDownloads);
}
