import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR
} from '../ProjectConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchProjects(params) {
  yield put(fetchItemsStart(FETCH_PROJECTS_START, params));

  const fetchProjectsAction = yield spawn(fetchItemsResource, 'tasks', {
    success: FETCH_PROJECTS_SUCCESS,
    error: FETCH_PROJECTS_ERROR
  }, params);
  // yield take(FETCH_PROJECTS_START);
  // yield cancel(fetchProjectsAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchProjectsAction);
}

export default function* watchFetchProjects() {
  yield takeEvery(FETCH_PROJECTS, fetchProjects);
}
