import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_PAGE,
  FETCH_PAGE_START,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_ERROR
} from '../PageConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchPage({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_PAGE_START, id, options));

  const fetchPageAction = yield spawn(fetchItemResource, 'pages', {
    success: FETCH_PAGE_SUCCESS,
    error: FETCH_PAGE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchPageAction);
}

export default function* watchFetchPage() {
  yield takeEvery(FETCH_PAGE, fetchPage);
}
