import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  itemsChannelUpdated, clearModalValues
} from '../../common/reducers';

import {
  removeSelectedMaterialTextureFilesStart,
  removeSelectedMaterialTextureFilesSuccess,
  removeSelectedMaterialTextureFilesError
} from './removeSelectedMaterialTextureFiles';

import {
  attachSelectedMaterialsStart,
  attachSelectedMaterialsSuccess,
  attachSelectedMaterialsError
} from './attachSelectedMaterials';

import {
  removeSelectedMaterialsStart,
  removeSelectedMaterialsSuccess,
  removeSelectedMaterialsError
} from './removeSelectedMaterials';

export default createReducer(defaultState, {
  FETCH_SELECTED_MATERIALS_START: fetchItemsStart,
  FETCH_SELECTED_MATERIALS_SUCCESS: fetchItemsSuccess,
  FETCH_SELECTED_MATERIALS_ERROR: fetchItemsError,

  FETCH_SELECTED_MATERIAL_START: fetchItemStart,
  FETCH_SELECTED_MATERIAL_SUCCESS: fetchItemSuccess,
  FETCH_SELECTED_MATERIAL_ERROR: fetchItemError,

  CREATE_SELECTED_MATERIAL_START: createItemStart,
  CREATE_SELECTED_MATERIAL_SUCCESS: createItemSuccess,
  CREATE_SELECTED_MATERIAL_ERROR: createItemError,

  UPDATE_SELECTED_MATERIAL_START: updateItemStart,
  UPDATE_SELECTED_MATERIAL_SUCCESS: updateItemSuccess,
  UPDATE_SELECTED_MATERIAL_ERROR: updateItemError,

  REMOVE_SELECTED_MATERIAL_START: removeItemStart,
  REMOVE_SELECTED_MATERIAL_SUCCESS: removeItemSuccess,
  REMOVE_SELECTED_MATERIAL_ERROR: removeItemError,

  ATTACH_SELECTED_MATERIALS_START: attachSelectedMaterialsStart,
  ATTACH_SELECTED_MATERIALS_SUCCESS: attachSelectedMaterialsSuccess,
  ATTACH_SELECTED_MATERIALS_ERROR: attachSelectedMaterialsError,

  REMOVE_TASK_SELECTED_MATERIALS_START: removeSelectedMaterialsStart,
  REMOVE_TASK_SELECTED_MATERIALS_SUCCESS: removeSelectedMaterialsSuccess,
  REMOVE_TASK_SELECTED_MATERIALS_ERROR: removeSelectedMaterialsError,

  SELECTED_MATERIALS_CHANNEL_UPDATED: itemsChannelUpdated,

  CLEAR_SELECTED_MATERIAL_MODAL_VALUES: clearModalValues,

  REMOVE_TEXTURE_FILE_START: removeSelectedMaterialTextureFilesStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeSelectedMaterialTextureFilesSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeSelectedMaterialTextureFilesError
});
