import {
  // IO_RECEIVED_DATA,
  // INIT_WEBSOCKETS_START,
  // INIT_WEBSOCKETS_SUCCESS,
  // INIT_WEBSOCKETS_ERROR,
  // INIT_WEBSOCKETS_OPEN,
  // INIT_WEBSOCKETS_CLOSE,
  INIT_ACTION_CABLE_SUCCESS
} from '../AuthConstants';

// import { parseError } from '../../utils/parseError';
// import { apiWebsockets } from '../../utils/apiWebsockets';
import { apiActionCable } from '../../utils/ts/apiActionCable';

// initWebsockets

// export function initWebsocketsOpen(options = {}) {
//   return {
//     type: INIT_WEBSOCKETS_OPEN,
//     options
//   };
// }
//
// export function initWebsocketsStart(options = {}) {
//   return {
//     type: INIT_WEBSOCKETS_START,
//     options
//   };
// }
//
// export function initWebsocketsSuccess(socket$, options = {}) {
//   return {
//     type: INIT_WEBSOCKETS_SUCCESS,
//     socket$,
//     options
//   };
// }
//
// export function initWebsocketsError(error) {
//   return {
//     type: INIT_WEBSOCKETS_ERROR,
//     errorMessage: parseError(error),
//     error
//   };
// }
//
// export function initWebsocketsClose(options = {}) {
//   return {
//     type: INIT_WEBSOCKETS_CLOSE,
//     options
//   };
// }

// initActionCable

export function initActionCableSuccess(actionCable, options = {}) {
  return {
    type: INIT_ACTION_CABLE_SUCCESS,
    actionCable,
    options
  };
}

export default function initWebsockets() {
  return dispatch => {
    // dispatch(initWebsocketsStart());

    const actionCable = apiActionCable();

    // openConnection(actionCable);

    dispatch(initActionCableSuccess(actionCable));

    // const cable = apiWebsockets();
    //
    // cable.addEventListener('open', () => {
    //   dispatch(initWebsocketsOpen());
    //
    // });
    //
    // cable.addEventListener('message', ({ data }) => {
    //   const payload = JSON.parse(data);
    //
    //   if (payload.type === 'welcome') {
    //     return dispatch(initWebsocketsSuccess(cable));
    //   }
    //
    //   if (payload.identifier && payload.message) {
    //     const identifier = JSON.parse(payload.identifier);
    //
    //     const channel = last(split(get(identifier, 'channel'), '::'))
    //       .replace(/Task/, 'Project')
    //       .replace(/Discussion/, 'Task')
    //       .replace(/DiscussionMessage/, 'Message');
    //
    //     const type = toUpper(snakeCase(`${channel}_${get(identifier, 'action')}`));
    //
    //     console.log('addEventListener', { payload, channel, identifier, type, cable });
    //
    //     return dispatch({
    //       identifier,
    //       type,
    //       data: get(payload, 'message.data'),
    //       cable
    //     });
    //   }
    //
    //   return dispatch({ type: IO_RECEIVED_DATA, payload });
    // });
    //
    // cable.addEventListener('error', error => dispatch(initWebsocketsError(error)));
    // cable.addEventListener('close', () => dispatch(initWebsocketsClose()));
  };
}
