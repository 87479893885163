import { updateState } from '../../../utils';

export function updateTasksAmountsStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function updateTasksAmountsSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function updateTasksAmountsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}

// export function updateTasksAmountsStart(state) {
//   return {
//     ...state,
//     isLoading: true
//   };
// }
//
// export function updateTasksAmountsSuccess(state) {
//   return {
//     ...state,
//     isLoading: false
//   };
// }
//
// export function updateTasksAmountsError(state, { errorMessage }) {
//   return {
//     ...state,
//     isLoading: false,
//     errorMessage
//   };
// }
