import { createReducer } from 'redux-create-reducer';

import { defaultState, clearModalValues, fetchItemsStart, fetchItemsSuccess, fetchItemsError } from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_TRANSACTIONS_START: fetchItemsStart,
  FETCH_TRANSACTIONS_SUCCESS: fetchItemsSuccess,
  FETCH_TRANSACTIONS_ERROR: fetchItemsError,

  CLEAR_TRANSACTION_MODAL_VALUES: clearModalValues
});
