import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import defaults from '../locales/en';

import { flattenMessages } from '../utils/flattenMessages';

const messages = flattenMessages(defaults.en);

export default function translate(id, values = {}) {
  if (id) {
    return (
      <FormattedMessage
        id={id}
        values={values}
        defaultMessage={messages[id]}
        tagName={Fragment}
      />
    );
  }

  return null;
}
