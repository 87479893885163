import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHANGE_TASKS_VISIBILITY,
  CHANGE_TASKS_VISIBILITY_START,
  CHANGE_TASKS_VISIBILITY_SUCCESS,
  CHANGE_TASKS_VISIBILITY_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* changeTasksVisibility({ values, options, cb }) {
  yield put(postItemsStart(CHANGE_TASKS_VISIBILITY_START, options));

  return yield spawn(postItemsResource, 'discussions/update_visibility', {
    success: CHANGE_TASKS_VISIBILITY_SUCCESS,
    error: CHANGE_TASKS_VISIBILITY_ERROR
  }, { values, options, cb });
}

export default function* watchChangeTasksVisibility() {
  yield takeEvery(CHANGE_TASKS_VISIBILITY, changeTasksVisibility);
}
