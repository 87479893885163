import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_MESSAGE,
  FETCH_MESSAGE_START,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_ERROR
} from '../MessageConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchMessage({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_MESSAGE_START, id, options));

  const fetchMessageAction = yield spawn(fetchItemResource, 'discussion_messages', {
    success: FETCH_MESSAGE_SUCCESS,
    error: FETCH_MESSAGE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchMessageAction);
}

export default function* watchFetchMessage() {
  yield takeEvery(FETCH_MESSAGE, fetchMessage);
}
