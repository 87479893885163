import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CANCEL_BILL,
  CANCEL_BILL_START,
  CANCEL_BILL_SUCCESS,
  CANCEL_BILL_ERROR
} from '../BillConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* cancelBill({ id, options, cb }) {
  yield put(postItemStart(CANCEL_BILL_START, id, options));

  const cancelBillAction = yield spawn(postItemResource, `bills/${id}/cancel`, {
    success: CANCEL_BILL_SUCCESS,
    error: CANCEL_BILL_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(cancelBillAction);
}

export default function* watchCancelBill() {
  yield takeEvery(CANCEL_BILL, cancelBill);
}
