export const FETCH_BILL_REFILLS = 'FETCH_BILL_REFILLS';
export const FETCH_BILL_REFILLS_START = 'FETCH_BILL_REFILLS_START';
export const FETCH_BILL_REFILLS_SUCCESS = 'FETCH_BILL_REFILLS_SUCCESS';
export const FETCH_BILL_REFILLS_ERROR = 'FETCH_BILL_REFILLS_ERROR';

export const DOWNLOAD_BILL_REFILLS_XLS = 'DOWNLOAD_BILL_REFILLS_XLS';
export const DOWNLOAD_BILL_REFILLS_XLS_START = 'DOWNLOAD_BILL_REFILLS_XLS_START';
export const DOWNLOAD_BILL_REFILLS_XLS_SUCCESS = 'DOWNLOAD_BILL_REFILLS_XLS_SUCCESS';
export const DOWNLOAD_BILL_REFILLS_XLS_ERROR = 'DOWNLOAD_BILL_REFILLS_XLS_ERROR';

export const CLEAR_BILL_REFILLS_FILTERS_START = 'CLEAR_BILL_REFILLS_FILTERS_START';
export const CLEAR_BILL_REFILLS_FILTERS_SUCCESS = 'CLEAR_BILL_REFILLS_FILTERS_SUCCESS';
