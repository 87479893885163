import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  TOGGLE_PROJECT_NOTIFICATIONS,
  TOGGLE_PROJECT_NOTIFICATIONS_START,
  TOGGLE_PROJECT_NOTIFICATIONS_SUCCESS,
  TOGGLE_PROJECT_NOTIFICATIONS_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* toggleProjectNotifications({ id, options, cb }) {
  yield put(postItemStart(TOGGLE_PROJECT_NOTIFICATIONS_START, id, options));

  const toggleProjectNotificationsAction = yield spawn(postItemResource, `tasks/${id}/toggle_notifications`, {
    success: TOGGLE_PROJECT_NOTIFICATIONS_SUCCESS,
    error: TOGGLE_PROJECT_NOTIFICATIONS_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(toggleProjectNotificationsAction);
}

export default function* watchToggleProjectNotifications() {
  yield takeEvery(TOGGLE_PROJECT_NOTIFICATIONS, toggleProjectNotifications);
}
