import React, { memo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LinkMenu } from '../../../../../../helpers/LinkMenu';

import { useCurrentUser } from '../../../../../../common/hooks/useAuth';

import { links } from '../../links';

function AdminLinkMenu({ location: { pathname } }: RouteComponentProps) {
  const currentUser = useCurrentUser();

  return (
    <LinkMenu
      links={links}
      currentUser={currentUser}
      pathname={pathname}
      linkPrefix="admin/"
    />
  );
}

export default withRouter(memo(AdminLinkMenu));
