import { takeEvery, put, spawn, cancel, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  REMOVE_TASK_FILE_ATTACHMENTS,
  REMOVE_TASK_FILE_ATTACHMENTS_START,
  REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS,
  REMOVE_TASK_FILE_ATTACHMENTS_ERROR
} from '../TaskConstants';
import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_FILE_ATTACHMENTS_START, id, options));

  const removeTaskFileAttachmentsAction = yield spawn(postItemResource, `discussions/${id}/remove_file_attachments`, {
    success: REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS,
    error: REMOVE_TASK_FILE_ATTACHMENTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskFileAttachmentsAction);
}

export default function* watchRemoveTaskFileAttachments() {
  yield takeEvery(REMOVE_TASK_FILE_ATTACHMENTS, removeTaskFileAttachments);
}
