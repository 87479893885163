import format from 'date-fns/format';

import compact from 'lodash/compact';
import get from 'lodash/get';

import { dateFnsLocales } from '../../utils/ts/dateFnsLocales';

function renderDateTime(
  date,
  locale = 'en',
  options = { withDayOfWeek: false }
) {
  const dateObj = new Date(date);
  const localeObj = dateFnsLocales.getLocale(locale);
  const formatOptions = localeObj ? { locale: localeObj } : {};

  return date
    ? compact([
        get(options, 'withDayOfWeek')
          ? format(dateObj, 'EEEE', formatOptions)
          : null,
        format(dateObj, 'dd MMM yyyy', formatOptions),
        format(dateObj, 'HH:mm', formatOptions)
      ]).join(', ')
    : null;
}

export default renderDateTime;
