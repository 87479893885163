import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeMessageSelectedMaterialsStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_materials: replaceById(get(message, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeMessageSelectedMaterialsSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_materials: removeById(get(message, 'selected_materials', []), id)
    }))
  }, options);
}

export function removeMessageSelectedMaterialsError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_materials: replaceById(get(message, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
