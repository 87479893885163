import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { PureLinkTo } from '../../helpers/ts/PureLinkTo';

import { isMenuItemActive } from './utils/isMenuItemActive';

function LinkMenuItem({ link, pathname }) {
  const { href, label } = link;

  return (
    <Fragment>
      <PureLinkTo
        className={cl('navbar-nav-link', {
          active: isMenuItemActive({ pathname, item: link })
        })}
        href={href}
        i18nText={label}
      />
    </Fragment>
  );
}

LinkMenuItem.propTypes = {
  pathname: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  linkPrefix: PropTypes.string.isRequired
};

export default memo(LinkMenuItem);
