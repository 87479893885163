import React from 'react';
import { connect } from 'react-redux';

import {
  validateToken as validateTokenAction,
  initWebsockets as initWebsocketsAction,
  logoutUser as logoutUserAction
} from '../../../../auth/AuthActions';

import {
  currentUserSelector,
  authFetchedSelector,
  isAuthenticatedSelector,
  websocketsFetchedSelector,
  currentUserProjectsFiltersSelector,
  currentUserTasksFiltersSelector,
  currentUserResultsFiltersSelector,
  currentUserMessagesFiltersSelector,
  currentUserProductsFiltersSelector,
  currentUserLibraryProductsFiltersSelector,
  currentUserTemplatesFiltersSelector,
  currentUserLibraryTemplatesFiltersSelector,
  currentUserMaterialsFiltersSelector,
  currentUserLibraryMaterialsFiltersSelector,
  currentUserAccountsFiltersSelector,
  currentUserWorkersFiltersSelector,
  currentUserClientsFiltersSelector,
  currentUserUsersFiltersSelector
} from '../../../../auth/AuthSelectors';

import { filterKeys } from '../utils';

const withCurrentUser = (options = {}) => WrappedComponent => {
  function CurrentUserHoc(props) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return connect(state => ({
    currentUser: currentUserSelector(state),
    ...(options.fields ? filterKeys(options.fields, {
      authFetched: authFetchedSelector(state),
      isAuthenticated: isAuthenticatedSelector(state),
      websocketsFetched: websocketsFetchedSelector(state),
      currentUserProjectsFilters: currentUserProjectsFiltersSelector(state),
      currentUserTasksFilters: currentUserTasksFiltersSelector(state),
      currentUserResultsFilters: currentUserResultsFiltersSelector(state),
      currentUserMessagesFilters: currentUserMessagesFiltersSelector(state),
      currentUserProductsFilters: currentUserProductsFiltersSelector(state),
      currentUserLibraryProductsFilters: currentUserLibraryProductsFiltersSelector(state),
      currentUserTemplatesFilters: currentUserTemplatesFiltersSelector(state),
      currentUserLibraryTemplatesFilters: currentUserLibraryTemplatesFiltersSelector(state),
      currentUserMaterialsFilters: currentUserMaterialsFiltersSelector(state),
      currentUserLibraryMaterialsFilters: currentUserLibraryMaterialsFiltersSelector(state),
      currentUserAccountsFilters: currentUserAccountsFiltersSelector(state),
      currentUserWorkersFilters: currentUserWorkersFiltersSelector(state),
      currentUserClientsFilters: currentUserClientsFiltersSelector(state),
      currentUserUsersFilters: currentUserUsersFiltersSelector(state)
    }) : {})
  }), options.actions ? filterKeys(options.actions, {
    validateToken: validateTokenAction,
    initWebsockets: initWebsocketsAction,
    logoutUser: logoutUserAction
  }) : {})(CurrentUserHoc);
};

export default withCurrentUser;
