import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHANGE_TASKS_TAGS,
  CHANGE_TASKS_TAGS_START,
  CHANGE_TASKS_TAGS_SUCCESS,
  CHANGE_TASKS_TAGS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* changeTasksTags({ values, options, cb }) {
  yield put(postItemsStart(CHANGE_TASKS_TAGS_START, options));

  return yield spawn(postItemsResource, 'discussions/update_tags', {
    success: CHANGE_TASKS_TAGS_SUCCESS,
    error: CHANGE_TASKS_TAGS_ERROR
  }, { values, options, cb });
}

export default function* watchChangeTasksTags() {
  yield takeEvery(CHANGE_TASKS_TAGS, changeTasksTags);
}
