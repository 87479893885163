import { CREATE_MESSAGE } from '../MessageConstants';

// createMessage

export default function createMessage(values, options = {}, cb) {
  return dispatch => dispatch({
    type: CREATE_MESSAGE,
    values,
    options,
    cb
  });
}
