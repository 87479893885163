import React from 'react';
import PropTypes from 'prop-types';

import { LinkMenu } from '../../../../../helpers/LinkMenu';

const links = [
  { type: 'link', namespace: 'dashboard', href: '', label: 'words.dashboard' },
  { type: 'link', namespace: 'projects', href: 'projects', label: 'models.projects.plural' },
  { type: 'link', namespace: 'tasks', href: 'tasks', label: 'models.tasks.plural' },
  { type: 'link', namespace: 'messages', href: 'messages', label: 'models.messages.plural' },
  { type: 'link', namespace: 'results', href: 'results', label: 'words.results' },
  { type: 'dropdown',
    namespace: 'products_library|lifestyles_library|materials_library',
    label: 'words.my_library',
    links: [
      { type: 'link', namespace: 'products_library', href: 'library/products', label: 'models.products.plural', icon: 'icon-cube4' },
      { type: 'link', namespace: 'lifestyles_library', href: 'library/lifestyles', label: 'models.lifestyles.plural', icon: 'icon-design' },
      { type: 'link', namespace: 'materials_library', href: 'library/materials', label: 'models.materials.plural', icon: 'icon-chess' }
    ]
  },
  { type: 'link', namespace: 'teams', href: 'teams', label: 'models.teams.plural' },
  // { type: 'dropdown',
  //   namespace: 'products|templates',
  //   label: 'words.stock',
  //   links: [
  //     { type: 'link', namespace: 'products', href: 'products', label: 'models.products.plural', icon: 'icon-cube4' },
  //     { type: 'link', namespace: 'templates', href: 'templates', label: 'models.templates.plural', icon: 'icon-design' }
  //   ]
  // },
  { type: 'dropdown',
    namespace: 'accounts|workers|clients',
    label: 'models.users.plural',
    links: [
      { type: 'link', namespace: 'accounts', href: 'accounts', label: 'models.accounts.plural', icon: 'icon-user' },
      { type: 'link', namespace: 'workers', href: 'workers', label: 'models.workers.plural', icon: 'icon-user' },
      { type: 'link', namespace: 'clients', href: 'clients', label: 'models.clients.plural', icon: 'icon-user' },
      { type: 'link', namespace: 'user_documents', href: 'user-documents', label: 'words.documents', icon: 'icon-file-text' }
    ]
  },
  { type: 'link', namespace: 'admin', href: 'admin', label: 'words.admin' }
];

function MainLinkMenu({ currentUser, pathname }) {
  return (
    <LinkMenu links={links} currentUser={currentUser} pathname={pathname} />
  );
}

MainLinkMenu.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  pathname: PropTypes.string.isRequired
};

export default React.memo(MainLinkMenu);
