import { POST_ITEM_SUCCESS } from '../../constants';

import { PostItemRequestAction } from '../../reducers/postItemRequestReducer';

export function postItemSuccess<ItemType>(
  item: ItemType
): PostItemRequestAction<ItemType> {
  return {
    type: POST_ITEM_SUCCESS,
    item
  };
}
