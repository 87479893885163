import { addSubscriptions } from './index';

import { prepareRequest } from '../../../../utils/prepareRequest';

function fetchIndexData(props, options, params, cb = null) {
  return prepareRequest(props[options.fetchItems], props[options.itemsMeta], params, ({ items }) => {
    addSubscriptions(props, options, items);
    cb && cb();
  });
}

export default fetchIndexData;
