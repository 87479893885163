import { updateState } from '../../../utils';

export function removeFileAttachmentsStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function removeFileAttachmentsSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    items: []
  }, options);
}

export function removeFileAttachmentsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
