import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ReportsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_reports" */ './pages/ReportsIndexPage/ReportsIndexPage'
    )
});
const ReportsPMPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_reports" */ './pages/ReportsPMPage/ReportsPMPage'
    )
});
const ReportsCMPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_reports" */ './pages/ReportsCMPage/ReportsCMPage'
    )
});
const ReportsErrorsPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_reports" */ './pages/ReportsErrorsPage/ReportsErrorsPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/reports" component={ReportsIndexPage} />
    <Route exact path="/admin/reports-pm" component={ReportsPMPage} />
    <Route exact path="/admin/reports-cm" component={ReportsCMPage} />
    <Route exact path="/admin/reports-errors" component={ReportsErrorsPage} />
  </Fragment>
);
