import get from 'lodash/get';

import { pushToDataLayer } from '../../../../../utils/ts/pushToDataLayer';
import { BillCheckoutDataType } from '../../../../../utils/ts/pushToDataLayer/types';

import { BillType } from '../../../../common/hooks/bills/types';

interface AnalyticsSetBillCheckoutOptions {
  bill: BillType;
  step: string;
}

function analyticsSetBillCheckout({
  bill,
  step
}: AnalyticsSetBillCheckoutOptions) {
  const projectName = get(bill, 'task.name');
  const projectId = String(get(bill, 'task.id'));
  const amount = get(bill, 'amount');

  const dataObject: BillCheckoutDataType = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step },
        products: [
          {
            name: projectName,
            id: projectId,
            price: amount
          }
        ]
      }
    }
  };

  pushToDataLayer(dataObject);
}

export default analyticsSetBillCheckout;
