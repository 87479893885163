import { CHECK_DOWNLOAD } from '../DownloadConstants';

// checkDownload

export default function checkDownload(id, options = {}, cb = null) {
  return dispatch => dispatch({
    type: CHECK_DOWNLOAD,
    id,
    options,
    cb
  });
}
