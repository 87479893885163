import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_IMAGES_START,
  FETCH_IMAGES,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_ERROR
} from '../ImageConstants';

import { fetchItemsResource, fetchItemsStart } from '../../common/sagas';

function* fetchImages(params) {
  yield put(fetchItemsStart(FETCH_IMAGES_START, params));

  const fetchImagesAction = yield spawn(fetchItemsResource, 'images', {
    success: FETCH_IMAGES_SUCCESS,
    error: FETCH_IMAGES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchImagesAction);
}

export default function* watchFetchImages() {
  yield takeEvery(FETCH_IMAGES, fetchImages);
}
