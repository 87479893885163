import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_TASK,
  TOGGLE_SELECTED_TASK_START,
  TOGGLE_SELECTED_TASK_SUCCESS,
  TOGGLE_SELECTED_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* toggleSelectedTask({ id, values, options, cb }) {
  yield put(postItemStart(TOGGLE_SELECTED_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/toggle_selected`, {
    success: TOGGLE_SELECTED_TASK_SUCCESS,
    error: TOGGLE_SELECTED_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchToggleSelectedTask() {
  yield takeEvery(TOGGLE_SELECTED_TASK, toggleSelectedTask);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
//
// import {
//   TOGGLE_SELECTED_TASK,
//   TOGGLE_SELECTED_TASK_START,
//   TOGGLE_SELECTED_TASK_SUCCESS,
//   TOGGLE_SELECTED_TASK_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, parseError } from '../../../utils';
//
// export function toggleSelectedTaskStart(id) {
//   return {
//     type: TOGGLE_SELECTED_TASK_START,
//     id
//   };
// }
//
// export function toggleSelectedTaskSuccess(id) {
//   return {
//     type: TOGGLE_SELECTED_TASK_SUCCESS,
//     id
//   };
// }
//
// export function toggleSelectedTaskError(id, error) {
//   return {
//     type: TOGGLE_SELECTED_TASK_ERROR,
//     errorMessage: parseError(error),
//     error,
//     id
//   };
// }
//
// function toggleSelectedTaskRequest(taskId) {
//   return apiRequest.post(`discussions/${taskId}/toggle_selected`);
// }
//
// function* toggleSelectedTaskResource({ taskId, cb }) {
//   try {
//     yield call(toggleSelectedTaskRequest, taskId);
//     yield put(toggleSelectedTaskSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(toggleSelectedTaskError(taskId, error));
//   }
// }
//
// function* toggleSelectedTask({ taskId, cb }) {
//   yield put(toggleSelectedTaskStart(taskId));
//
//   return yield spawn(toggleSelectedTaskResource, { taskId, cb });
// }
//
// export default function* watchToggleSelectedTask() {
//   yield takeEvery(TOGGLE_SELECTED_TASK, toggleSelectedTask);
// }
