import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR
} from '../ProductConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchProducts(params) {
  yield put(fetchItemsStart(FETCH_PRODUCTS_START, params));

  const fetchProductsAction = yield spawn(fetchItemsResource, 'facilities', {
    success: FETCH_PRODUCTS_SUCCESS,
    error: FETCH_PRODUCTS_ERROR
  }, params);

  // yield take(FETCH_PRODUCTS_START);
  // yield cancel(fetchProductsAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchProductsAction);
}

export default function* watchFetchProducts() {
  yield takeEvery(FETCH_PRODUCTS, fetchProducts);
}
