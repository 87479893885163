import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REALLOCATE_TASK,
  REALLOCATE_TASK_START,
  REALLOCATE_TASK_SUCCESS,
  REALLOCATE_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* reallocateTask({ id, options, cb }) {
  yield put(postItemStart(REALLOCATE_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/reallocate`, {
    success: REALLOCATE_TASK_SUCCESS,
    error: REALLOCATE_TASK_ERROR
  }, { id, options, cb });
}

export default function* watchReallocateTask() {
  yield takeEvery(REALLOCATE_TASK, reallocateTask);
}
