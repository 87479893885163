import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const TextureFileShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_texture_files" */ './pages/TextureFileShowPage/TextureFileShowPage'
    )
});
const TextureFilesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_texture_files" */ './pages/TextureFilesIndexPage/TextureFilesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/texture_files/:textureFileId(\d+)"
      component={TextureFileShowPage}
    />
    <Route
      exact
      path="/admin/texture_files"
      component={TextureFilesIndexPage}
    />
  </Fragment>
);
