import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_START,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* deleteUserAccount({ id, options, cb }) {
  yield put(postItemStart(DELETE_USER_ACCOUNT_START, options));

  return yield spawn(postItemResource, `users/${id}/delete_account`, {
    success: DELETE_USER_ACCOUNT_SUCCESS,
    error: DELETE_USER_ACCOUNT_ERROR
  }, { id, options: { ...options, param: 'user' }, cb });
}

export default function* watchDeleteUserAccount() {
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccount);
}
