import {
  POST_ITEM_ERROR,
  POST_ITEM_INITIALIZE,
  POST_ITEM_START,
  POST_ITEM_SUCCESS
} from '../../constants';

interface PostItemRequestState<ItemType> {
  item: ItemType | null;
  itemPostErrorMessage: string | null;
  itemPostLoading: boolean;
}

export const initialState = {
  item: null,
  itemPostErrorMessage: null,
  itemPostLoading: false
};

export type PostItemRequestAction<ItemType> =
  | {
      type: 'POST_ITEM_INITIALIZE';
    }
  | {
      type: 'POST_ITEM_START';
    }
  | {
      type: 'POST_ITEM_SUCCESS';
      item: ItemType;
    }
  | {
      type: 'POST_ITEM_ERROR';
      itemPostErrorMessage: string | null;
    };

export type PostItemRequestReducerType<ItemType> = (
  prevState: PostItemRequestState<ItemType>,
  action: PostItemRequestAction<ItemType>
) => PostItemRequestState<ItemType>;

export function postItemRequestReducer<ItemType>(
  state: PostItemRequestState<ItemType>,
  action: PostItemRequestAction<ItemType>
): PostItemRequestState<ItemType> {
  switch (action.type) {
    case POST_ITEM_INITIALIZE:
      return {
        ...initialState
      };

    case POST_ITEM_START:
      return {
        ...state,
        itemPostLoading: true,
        itemPostErrorMessage: null
      };

    case POST_ITEM_SUCCESS:
      return {
        ...state,
        itemPostLoading: false,
        itemPostErrorMessage: null,
        item: action.item
      };

    case POST_ITEM_ERROR:
      return {
        ...state,
        itemPostLoading: false,
        itemPostErrorMessage: action.itemPostErrorMessage
      };

    default:
      return state;
  }
}
