import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* updatePassword({ values, options, cb }) {
  yield put(postItemStart(UPDATE_PASSWORD_START, options));

  return yield spawn(postItemResource, 'auth/update_password', {
    success: UPDATE_PASSWORD_SUCCESS,
    error: UPDATE_PASSWORD_ERROR
  }, { values, options, cb });
}

export default function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}
