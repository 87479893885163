import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const FileAttachmentShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_file_attachments" */ './pages/FileAttachmentShowPage/FileAttachmentShowPage'
    )
});
const FileAttachmentsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_file_attachments" */ './pages/FileAttachmentsIndexPage/FileAttachmentsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/file_attachments/:fileAttachmentId(\d+)"
      component={FileAttachmentShowPage}
    />
    <Route
      exact
      path="/admin/file_attachments"
      component={FileAttachmentsIndexPage}
    />
  </Fragment>
);
