import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  clearModalValues
} from '../../common/reducers';

import {
  toggleSelectedUserError,
  toggleSelectedUserStart,
  toggleSelectedUserSuccess
} from './toggleSelectedUser';

import {
  blockUsersStart,
  blockUsersSuccess,
  blockUsersError
} from './blockUsers';

import {
  overwatchUsersStart,
  overwatchUsersSuccess,
  overwatchUsersError
} from './overwatchUsers';

import {
  cancelOverwatchUsersStart,
  cancelOverwatchUsersSuccess,
  cancelOverwatchUsersError
} from './cancelOverwatchUsers';

import {
  changeUsersSellerStart,
  changeUsersSellerSuccess,
  changeUsersSellerError
} from './changeUsersSeller';

import {
  changeUsersUpsellerStart,
  changeUsersUpsellerSuccess,
  changeUsersUpsellerError
} from './changeUsersUpseller';

import {
  unblockUsersStart,
  unblockUsersSuccess,
  unblockUsersError
} from './unblockUsers';

import {
  addUsersGroupsStart,
  addUsersGroupsSuccess,
  addUsersGroupsError
} from './addUsersGroups';

import {
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError
} from './updatePassword';

const defaultUsersState = {
  ...defaultState,

  statuses: [
    'new',
    'active',
    'paused',
    'declined',
    'real_decline',
    'no_payments'
  ]
};

export default createReducer(defaultUsersState, {
  // '@@router/LOCATION_CHANGE': locationChange(defaultUsersState),
  FETCH_USERS_START: fetchItemsStart,
  FETCH_USERS_SUCCESS: fetchItemsSuccess,
  FETCH_USERS_ERROR: fetchItemsError,

  FETCH_USER_START: fetchItemStart,
  FETCH_USER_SUCCESS: fetchItemSuccess,
  FETCH_USER_ERROR: fetchItemError,

  UPDATE_USER_START: updateItemStart,
  UPDATE_USER_SUCCESS: updateItemSuccess,
  UPDATE_USER_ERROR: updateItemError,

  CLEAR_USER_FILTERS_START: clearItemsFiltersStart,
  CLEAR_USER_FILTERS_SUCCESS: clearItemsFiltersSuccess,
  CLEAR_USER_MODAL_VALUES: clearModalValues,

  TOGGLE_SELECTED_USER_START: toggleSelectedUserStart,
  TOGGLE_SELECTED_USER_SUCCESS: toggleSelectedUserSuccess,
  TOGGLE_SELECTED_USER_ERROR: toggleSelectedUserError,

  BLOCK_USERS_START: blockUsersStart,
  BLOCK_USERS_SUCCESS: blockUsersSuccess,
  BLOCK_USERS_ERROR: blockUsersError,

  OVERWATCH_USERS_START: overwatchUsersStart,
  OVERWATCH_USERS_SUCCESS: overwatchUsersSuccess,
  OVERWATCH_USERS_ERROR: overwatchUsersError,

  CANCEL_OVERWATCH_USERS_START: cancelOverwatchUsersStart,
  CANCEL_OVERWATCH_USERS_SUCCESS: cancelOverwatchUsersSuccess,
  CANCEL_OVERWATCH_USERS_ERROR: cancelOverwatchUsersError,

  CHANGE_USERS_SELLER_START: changeUsersSellerStart,
  CHANGE_USERS_SELLER_SUCCESS: changeUsersSellerSuccess,
  CHANGE_USERS_SELLER_ERROR: changeUsersSellerError,

  CHANGE_USERS_UPSELLER_START: changeUsersUpsellerStart,
  CHANGE_USERS_UPSELLER_SUCCESS: changeUsersUpsellerSuccess,
  CHANGE_USERS_UPSELLER_ERROR: changeUsersUpsellerError,

  UNBLOCK_USERS_START: unblockUsersStart,
  UNBLOCK_USERS_SUCCESS: unblockUsersSuccess,
  UNBLOCK_USERS_ERROR: unblockUsersError,

  ADD_USERS_GROUPS_START: addUsersGroupsStart,
  ADD_USERS_GROUPS_SUCCESS: addUsersGroupsSuccess,
  ADD_USERS_GROUPS_ERROR: addUsersGroupsError,

  UPDATE_PASSWORD_START: updatePasswordStart,
  UPDATE_PASSWORD_SUCCESS: updatePasswordSuccess,
  UPDATE_PASSWORD_ERROR: updatePasswordError
});
