import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import Validators from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { IntlProvider, updateIntl as updateIntlAction } from 'react-intl-redux';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import * as Sentry from '@sentry/browser';
import replace from 'lodash/replace';
import { hot } from 'react-hot-loader';
import compose from 'recompose/compose';

import { SENTRY_DSN } from '../config';

import { ProvideAuth } from '../common/hooks/useAuth';
import { withCurrentUser } from '../main/common/HOCs/withCurrentUser';

import { AuthAppContainer } from './AppContainers/ts/AuthAppContainer';
import { AdminAppContainer } from './AppContainers/AdminAppContainer';
import { MainAppContainer } from './AppContainers/ts/MainAppContainer';
import { AccountAppContainer } from './AppContainers/AccountAppContainer';
import { ErrorAppContainer } from './AppContainers/ErrorAppContainer';
import { CozyAppContainer } from './AppContainers/CozyAppContainer';
import { CozyAuthAppContainer } from './AppContainers/CozyAuthAppContainer';

import { prepareLocale } from '../utils/prepareLocale';
import { loadAllLocales } from '../utils/ts/loadAllLocales';
import { profileLocaleInStorage } from '../utils/ts/profileLocaleInStorage';

const ToastContainer = props => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 9999 }}
    {...props}
  />
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: SENTRY_DSN });
}

Validators.formatMessage = msg => {
  if (msg && msg.id) {
    msg.id = replace(msg.id, 'form.errors', 'forms.errors');
  }
  return <FormattedMessage {...(msg.props || msg)} />;
};

class App extends PureComponent {
  static propTypes = {
    getRequestError: PropTypes.shape(),
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired
    }).isRequired,
    updateIntl: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired
  };

  static defaultProps = {
    getRequestError: null
  };

  componentDidMount() {
    const { updateIntl } = this.props;

    const locale = profileLocaleInStorage.getLocale() || prepareLocale(
      (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator.userLanguage
      || 'en'
    );

    loadAllLocales(locale).then(messages => {
      const profileLocale = this.props.currentUser.get('locale');
      if (profileLocale && profileLocale !== locale) return;

      updateIntl({
        locale,
        messages
      });
    });
  }

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch', { error, errorInfo });
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        console.log('componentDidCatch', { eventId, scope, errorInfo });
      });
    }
  }

  render() {
    const { history, store, getRequestError } = this.props;
    return (
      <Provider store={store}>
        <IntlProvider>
          <ToastProvider
            placement="top-right"
            components={{ ToastContainer }}
          >
            <ConnectedRouter history={history}>
              <ProvideAuth>
                {getRequestError ? (<Route key="error" component={ErrorAppContainer} />) : (
                  <Switch>
                    <Route path="/pages" component={AuthAppContainer} />
                    <Route path="/register" component={AuthAppContainer} />
                    <Route exact path="/auth/cozy" component={CozyAuthAppContainer} />
                    <Route path="/auth" component={AuthAppContainer} />
                    <Route path="/account" component={AccountAppContainer} />
                    <Route path="/admin" component={AdminAppContainer} />
                    <Route path="/cozy" component={CozyAppContainer} />
                    <Route path="/makeover" component={CozyAppContainer} />
                    <Route path="/" component={MainAppContainer} />
                  </Switch>
                )}
              </ProvideAuth>
            </ConnectedRouter>
          </ToastProvider>
        </IntlProvider>
      </Provider>
    );
  }
}

export default compose(
  hot(module),
  connect(
    ({ app: { currentLocale, locales, status, getRequestError } }) => ({
      currentLocale,
      locales,
      status,
      getRequestError
    }),
    {
      updateIntl: updateIntlAction
    }
  ),
  withCurrentUser()
)(App);
