export const FETCH_SENT_MESSAGES = 'FETCH_SENT_MESSAGES';
export const FETCH_SENT_MESSAGES_START = 'FETCH_SENT_MESSAGES_START';
export const FETCH_SENT_MESSAGES_SUCCESS = 'FETCH_SENT_MESSAGES_SUCCESS';
export const FETCH_SENT_MESSAGES_ERROR = 'FETCH_SENT_MESSAGES_ERROR';

export const FETCH_SENT_MESSAGE = 'FETCH_SENT_MESSAGE';
export const FETCH_SENT_MESSAGE_START = 'FETCH_SENT_MESSAGE_START';
export const FETCH_SENT_MESSAGE_SUCCESS = 'FETCH_SENT_MESSAGE_SUCCESS';
export const FETCH_SENT_MESSAGE_ERROR = 'FETCH_SENT_MESSAGE_ERROR';

export const CLEAR_SENT_MESSAGES_FILTERS_START = 'CLEAR_SENT_MESSAGES_FILTERS_START';
export const CLEAR_SENT_MESSAGES_FILTERS_SUCCESS = 'CLEAR_SENT_MESSAGES_FILTERS_SUCCESS';

export const CLEAR_SENT_MESSAGE_MODAL_VALUES = 'CLEAR_SENT_MESSAGE_MODAL_VALUES';

export const SENT_MESSAGE_FORM = 'SENT_MESSAGE_FORM';
