import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsError,
  fetchItemStart,
  fetchItemSuccess,
  fetchItemError,
  createItemStart,
  createItemSuccess,
  createItemError,
  updateItemStart,
  updateItemSuccess, updateItemError, removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_TASK_NOTIFICATIONS_START: fetchItemsStart,
  FETCH_TASK_NOTIFICATIONS_SUCCESS: fetchItemsSuccess,
  FETCH_TASK_NOTIFICATIONS_ERROR: fetchItemsError,

  FETCH_TASK_NOTIFICATION_START: fetchItemStart,
  FETCH_TASK_NOTIFICATION_SUCCESS: fetchItemSuccess,
  FETCH_TASK_NOTIFICATION_ERROR: fetchItemError,

  CREATE_TASK_NOTIFICATION_START: createItemStart,
  CREATE_TASK_NOTIFICATION_SUCCESS: createItemSuccess,
  CREATE_TASK_NOTIFICATION_ERROR: createItemError,

  UPDATE_TASK_NOTIFICATION_START: updateItemStart,
  UPDATE_TASK_NOTIFICATION_SUCCESS: updateItemSuccess,
  UPDATE_TASK_NOTIFICATION_ERROR: updateItemError,

  REMOVE_TASK_NOTIFICATION_START: removeItemStart,
  REMOVE_TASK_NOTIFICATION_SUCCESS: removeItemSuccess,
  REMOVE_TASK_NOTIFICATION_ERROR: removeItemError
});
