import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHANGE_TASKS_STATUS,
  CHANGE_TASKS_STATUS_START,
  CHANGE_TASKS_STATUS_SUCCESS,
  CHANGE_TASKS_STATUS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* changeTasksStatus({ values, options, cb }) {
  yield put(postItemsStart(CHANGE_TASKS_STATUS_START, options));

  return yield spawn(postItemsResource, 'discussions/update_status', {
    success: CHANGE_TASKS_STATUS_SUCCESS,
    error: CHANGE_TASKS_STATUS_ERROR
  }, { values, options, cb });
}

export default function* watchChangeTasksStatus() {
  yield takeEvery(CHANGE_TASKS_STATUS, changeTasksStatus);
}
