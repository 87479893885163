import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const RefillShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_refills" */ './pages/RefillShowPage/RefillShowPage'
    )
});
const RefillsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_refills" */ './pages/RefillsIndexPage/RefillsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/refills/:refillId(\d+)"
      component={RefillShowPage}
    />
    <Route exact path="/admin/refills" component={RefillsIndexPage} />
  </Fragment>
);
