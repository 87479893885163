import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_DISCUSSION_MEMBER_INVITES,
  FETCH_DISCUSSION_MEMBER_INVITES_START,
  FETCH_DISCUSSION_MEMBER_INVITES_SUCCESS,
  FETCH_DISCUSSION_MEMBER_INVITES_ERROR
} from '../DiscussionMemberInviteConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchDiscussionMemberInvites(params) {
  yield put(fetchItemsStart(FETCH_DISCUSSION_MEMBER_INVITES_START, params));

  const fetchDiscussionMemberInvitesAction = yield spawn(fetchItemsResource, 'discussion_member_invites', {
    success: FETCH_DISCUSSION_MEMBER_INVITES_SUCCESS,
    error: FETCH_DISCUSSION_MEMBER_INVITES_ERROR
  }, params);

  // yield take(FETCH_DISCUSSION_MEMBER_INVITES_START);
  // yield cancel(fetchDiscussionMemberInvitesAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchDiscussionMemberInvitesAction);
}

export default function* watchFetchDiscussionMemberInvites() {
  yield takeEvery(FETCH_DISCUSSION_MEMBER_INVITES, fetchDiscussionMemberInvites);
}
