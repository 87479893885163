import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_BLOCKED_PRODUCT,
  TOGGLE_BLOCKED_PRODUCT_START,
  TOGGLE_BLOCKED_PRODUCT_SUCCESS,
  TOGGLE_BLOCKED_PRODUCT_ERROR
} from '../ProductConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleBlockedProductStart(id) {
  return {
    type: TOGGLE_BLOCKED_PRODUCT_START,
    id
  };
}

export function toggleBlockedProductSuccess(id) {
  return {
    type: TOGGLE_BLOCKED_PRODUCT_SUCCESS,
    id
  };
}

export function toggleBlockedProductError(id, error) {
  return {
    type: TOGGLE_BLOCKED_PRODUCT_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleBlockedProductRequest(productId) {
  return apiRequest.post(`facilities/${productId}/toggle_blocked`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleBlockedProductResource({ productId, cb }) {
  try {
    yield call(toggleBlockedProductRequest, productId);
    yield put(toggleBlockedProductSuccess(productId));
    cb && cb();
  } catch (error) {
    yield put(toggleBlockedProductError(productId, error));
  }
}

function* toggleBlockedProduct({ productId, cb }) {
  yield put(toggleBlockedProductStart(productId));

  return yield spawn(toggleBlockedProductResource, { productId, cb });
}

export default function* watchToggleBlockedProduct() {
  yield takeEvery(TOGGLE_BLOCKED_PRODUCT, toggleBlockedProduct);
}
