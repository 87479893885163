import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeMaterialsSetSelectedMaterialsStart(state, { id, options = {} }) {
  const currentMaterialsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentMaterialsSet,
      selected_materials: replaceById(get(currentMaterialsSet, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: null,
        loading: true
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, materialsSet => ({
      ...materialsSet,
      selected_materials: replaceById(get(materialsSet, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeMaterialsSetSelectedMaterialsSuccess(state, { id, options = {} }) {
  const currentMaterialsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentMaterialsSet,
      selected_materials: removeById(get(currentMaterialsSet, 'selected_materials', []), id)
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, materialsSet => ({
      ...materialsSet,
      selected_materials: removeById(get(materialsSet, 'selected_materials', []), id)
    }))
  }, options);
}

export function removeMaterialsSetSelectedMaterialsError(state, { id, errorMessage, options = {} }) {
  const currentMaterialsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentMaterialsSet,
      selected_materials: replaceById(get(currentMaterialsSet, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: errorMessage,
        loading: false
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, materialsSet => ({
      ...materialsSet,
      selected_materials: replaceById(get(materialsSet, 'selected_materials', []), id, selectedMaterial => ({
        ...selectedMaterial,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
