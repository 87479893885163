import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_REPORT,
  REMOVE_REPORT_START,
  REMOVE_REPORT_SUCCESS,
  REMOVE_REPORT_ERROR
} from '../ReportConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeReport({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_REPORT_START, id, options));

  return yield spawn(removeItemResource, 'reports', {
    success: REMOVE_REPORT_SUCCESS,
    error: REMOVE_REPORT_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveReport() {
  yield takeEvery(REMOVE_REPORT, removeReport);
}
