import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_SELECTED_MATERIAL,
  FETCH_SELECTED_MATERIAL_START,
  FETCH_SELECTED_MATERIAL_SUCCESS,
  FETCH_SELECTED_MATERIAL_ERROR
} from '../SelectedMaterialConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchSelectedMaterial({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_SELECTED_MATERIAL_START, id, options));

  const fetchSelectedMaterialAction = yield spawn(fetchItemResource, 'selected_materials', {
    success: FETCH_SELECTED_MATERIAL_SUCCESS,
    error: FETCH_SELECTED_MATERIAL_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchSelectedMaterialAction);
}

export default function* watchFetchSelectedMaterial() {
  yield takeEvery(FETCH_SELECTED_MATERIAL, fetchSelectedMaterial);
}
