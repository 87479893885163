import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_START,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* updateUserPassword({ id, values, options, cb }) {
  yield put(postItemStart(UPDATE_USER_PASSWORD_START, options));

  return yield spawn(postItemResource, `users/${id}/update_password`, {
    success: UPDATE_USER_PASSWORD_SUCCESS,
    error: UPDATE_USER_PASSWORD_ERROR
  }, { id, values, options: { ...options, param: 'user' }, cb });
}

export default function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
}
