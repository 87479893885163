import React, { Fragment, useCallback } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import get from 'lodash/get';

import { IsLoading, ID } from '../../../types';
import { BillType } from '../../../main/common/hooks/bills/types';

import { I18nProps, withI18n } from '../../../adapters/HOCs/withI18n';

import { useStripePayment } from '../../../main/common/hooks/bills/useStripePayment';

import { analyticsSetBillCheckout } from '../../../main/ts/bills/utils/analyticsSetBillCheckout';
import { Button } from '../../ts/Button';
import { renderAmount } from '../../../utils/ts/renderAmount';
import { Translate } from '../../ts/Translate';

import { STRIPE_API_KEY } from '../../../config';

interface TokenType {
  id: ID;
}

interface StripeCheckoutButtonProps {
  addClass?: string;
  bill: BillType;
  className?: string;
  icon?: string;
  isLoading: IsLoading;
  disabled?: boolean;
  onSuccess?: () => void;
}

function StripeCheckoutButton({
  className,
  addClass,
  icon,
  bill,
  isLoading,
  disabled,
  onSuccess,
  intl
}: StripeCheckoutButtonProps & I18nProps) {
  const handlePayButtonClick = useCallback(() => {
    analyticsSetBillCheckout({ bill, step: 'click to pay' });
  }, [bill]);

  const { stripePayment, stripePaymentLoading } = useStripePayment({
    id: get<BillType, 'id'>(bill, 'id')
  });

  const handleStripePayment = useCallback<(token: TokenType) => void>(
    (token: TokenType) =>
      stripePayment({ source: get<TokenType, 'id'>(token, 'id') }).then(
        onSuccess
      ),
    [onSuccess, stripePayment]
  );

  return (
    <StripeCheckout
      reconfigureOnUpdate
      name={`${intl.formatMessage({ id: 'words.pay' })} $${get<
        BillType,
        'amount'
      >(bill, 'amount')}`}
      description={`${intl.formatMessage({
        id: 'models.bills.singular'
      })} #${get<BillType, 'id'>(bill, 'id')}`}
      amount={get<BillType, 'amount'>(bill, 'amount') * 100}
      token={handleStripePayment}
      currency="USD"
      stripeKey={STRIPE_API_KEY as string}
    >
      <Button
        addClass={addClass}
        className={className}
        color="primary"
        disabled={disabled || isLoading || stripePaymentLoading}
        onClick={handlePayButtonClick}
      >
        <b className="mr-2">
          <i className={icon} />
        </b>

        {isLoading || stripePaymentLoading ? (
          <Translate id="words.processing" />
        ) : (
          <Fragment>
            <Translate id="words.pay" />
            &nbsp;
            {renderAmount(get<BillType, 'amount'>(bill, 'amount'), false)}
            &nbsp;
            {get<BillType, 'currency'>(bill, 'currency')}
          </Fragment>
        )}
      </Button>
    </StripeCheckout>
  );
}

export default withI18n<StripeCheckoutButtonProps & I18nProps>()(
  StripeCheckoutButton
);
