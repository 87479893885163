import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_TODO_ITEMS_START: fetchItemsStart,
  FETCH_TODO_ITEMS_SUCCESS: fetchItemsSuccess,
  FETCH_TODO_ITEMS_ERROR: fetchItemsError,

  CLEAR_TODO_ITEM_FILTERS_START: clearItemsFiltersStart,
  CLEAR_TODO_ITEM_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_TODO_ITEM_START: fetchItemStart,
  FETCH_TODO_ITEM_SUCCESS: fetchItemSuccess,
  FETCH_TODO_ITEM_ERROR: fetchItemError,

  CREATE_TODO_ITEM_START: createItemStart,
  CREATE_TODO_ITEM_SUCCESS: createItemSuccess,
  CREATE_TODO_ITEM_ERROR: createItemError,

  UPDATE_TODO_ITEM_START: updateItemStart,
  UPDATE_TODO_ITEM_SUCCESS: updateItemSuccess,
  UPDATE_TODO_ITEM_ERROR: updateItemError,

  REMOVE_TODO_ITEM_START: removeItemStart,
  REMOVE_TODO_ITEM_SUCCESS: removeItemSuccess,
  REMOVE_TODO_ITEM_ERROR: removeItemError
});
