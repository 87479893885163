export default {
  add: 'Add room',
  name: 'Room name',
  no: 'No rooms',
  plural: 'Rooms',
  singular_downcase: 'room',
  your: 'Your rooms',
  remove: 'Remove room',
  category: 'Room category'
};
