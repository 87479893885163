import get from 'lodash/get';

import { Promise } from '../../../../../../../adapters/libs/bluebird';

import { postDataStart } from '../../actions/postDataStart';
import { postDataSuccess } from '../../actions/postDataSuccess';
import { postDataError } from '../../actions/postDataError';

import { PostDataRequestAction } from '../../reducers/postDataRequestReducer';

import { apiRequest } from '../../../../../../../utils/apiRequest';
import { parseError } from '../../../../../../../utils/ts/parseError';

interface RequestConfig {
  serializer?: string;
  values: object;
}

export default function sendPostDataRequest<DataType>(
  url: string,
  requestConfig: RequestConfig,
  dispatch: (action: PostDataRequestAction<DataType>) => void
) {
  dispatch(postDataStart<DataType>());
  return new Promise<DataType>((resolve, reject) =>
    apiRequest
      .financePost(url, get(requestConfig, 'values', {}))
      .then(successResponse => {
        const data = get(successResponse, 'data.data');
        dispatch(postDataSuccess<DataType>(data));
        resolve(data);
      })
      .catch(errorResponse => {
        dispatch(postDataError<DataType>(parseError(errorResponse)));
        reject();
      })
  );
}
