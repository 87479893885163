import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  TRACK_PROJECT_TIME,
  TRACK_PROJECT_TIME_START,
  TRACK_PROJECT_TIME_SUCCESS,
  TRACK_PROJECT_TIME_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* trackProjectTime({ id, values, options, cb }) {
  yield put(postItemStart(TRACK_PROJECT_TIME_START, id, options));

  return yield spawn(
    postItemResource,
    `tasks/${id}/track_time`,
    {
      success: TRACK_PROJECT_TIME_SUCCESS,
      error: TRACK_PROJECT_TIME_ERROR
    },
    { id, values, options, cb }
  );
}

export default function* watchTrackProjectTime() {
  yield takeEvery(TRACK_PROJECT_TIME, trackProjectTime);
}
