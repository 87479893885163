export default {
  actions: {
    repeat_sharing: 'Repeat sharing'
  },
  types: {
    undefined: 'Undefined',
    accrualled: 'Accrualled',
    credit_commission: 'Credit commission',
    cancel_credit_commission: 'Cancel credit commission',
    task_commission: 'Project commission',
    cancel_task_commission: 'Cancel project commission',
    hosting_commission: 'Hosting commission',
    cancel_hosting_commission: 'Cancel hosting commission',
    cancel: 'Cancel',
    revenue_share: 'Revenue share',
    cancel_revenue_share: 'Cancel revenue share',
    hold_rate_commission: 'Hold rate',
    cancel_hold_rate_commission: 'Cancel hold rate',
    reducer_commission: 'Reducer commission',
    cancel_reducer_commission: 'Cancel reducer commission',
    client_revenue_share: 'Client Revenue Share',
    cancel_client_revenue_share: 'Cancel Client Revenue Share',
    worker_revenue_share: 'Worker Revenue Share',
    cancel_worker_revenue_share: 'Cancel Worker Revenue Share'
  },
  states: {
    allocated: 'Allocated'
  },
  words: {
    client_revenue_share_items_sum: 'Client Revenue Share Sum',
    worker_revenue_share_items_sum: 'Worker Revenue Share Sum'
  }
};
