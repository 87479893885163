import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_MESSAGE_SELECTED_MATERIALS,
  DOWNLOAD_MESSAGE_SELECTED_MATERIALS_START,
  DOWNLOAD_MESSAGE_SELECTED_MATERIALS_SUCCESS,
  DOWNLOAD_MESSAGE_SELECTED_MATERIALS_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadMessageSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_MESSAGE_SELECTED_MATERIALS_START, id, options));

  const downloadMessageSelectedMaterialsAction = yield spawn(postItemResource, `discussion_messages/${id}/selected_materials`, {
    success: DOWNLOAD_MESSAGE_SELECTED_MATERIALS_SUCCESS,
    error: DOWNLOAD_MESSAGE_SELECTED_MATERIALS_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadMessageSelectedMaterialsAction);
}

export default function* watchDownloadMessageSelectedMaterials() {
  yield takeEvery(DOWNLOAD_MESSAGE_SELECTED_MATERIALS, downloadMessageSelectedMaterials);
}
