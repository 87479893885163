import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function updateTaskFileAttachmentsStart(state, { id, options = {} }) {
  const scope = get(options, 'scope') || 'file_attachments';

  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      [scope]: replaceById(get(task, scope, []), id, fileAttachment => ({
        ...fileAttachment,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function updateTaskFileAttachmentsSuccess(state, { id, item, options = {} }) {
  const scope = get(options, 'scope') || 'file_attachments';

  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      [scope]: replaceById(get(task, scope, []), id, fileAttachment => ({
        ...fileAttachment,
        ...item,
        error: null,
        loading: false
      }))
    }))
  }, options);
}

export function updateTaskFileAttachmentsError(state, { id, errorMessage, options = {} }) {
  const scope = get(options, 'scope') || 'file_attachments';

  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      [scope]: replaceById(get(task, scope, []), id, fileAttachment => ({
        ...fileAttachment,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
