export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_ERROR = 'FETCH_TEMPLATES_ERROR';

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';
export const FETCH_TEMPLATE_START = 'FETCH_TEMPLATE_START';
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_ERROR = 'FETCH_TEMPLATE_ERROR';

export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const CREATE_TEMPLATE_START = 'CREATE_TEMPLATE_START';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';

export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_START = 'UPDATE_TEMPLATE_START';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR';

export const DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE';
export const DOWNLOAD_TEMPLATE_START = 'DOWNLOAD_TEMPLATE_START';
export const DOWNLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_TEMPLATE_SUCCESS';
export const DOWNLOAD_TEMPLATE_ERROR = 'DOWNLOAD_TEMPLATE_ERROR';

export const TOGGLE_SELECTED_TEMPLATE = 'TOGGLE_SELECTED_TEMPLATE';
export const TOGGLE_SELECTED_TEMPLATE_START = 'TOGGLE_SELECTED_TEMPLATE_START';
export const TOGGLE_SELECTED_TEMPLATE_SUCCESS = 'TOGGLE_SELECTED_TEMPLATE_SUCCESS';
export const TOGGLE_SELECTED_TEMPLATE_ERROR = 'TOGGLE_SELECTED_TEMPLATE_ERROR';

export const TOGGLE_BLOCKED_TEMPLATE = 'TOGGLE_BLOCKED_TEMPLATE';
export const TOGGLE_BLOCKED_TEMPLATE_START = 'TOGGLE_BLOCKED_TEMPLATE_START';
export const TOGGLE_BLOCKED_TEMPLATE_SUCCESS = 'TOGGLE_BLOCKED_TEMPLATE_SUCCESS';
export const TOGGLE_BLOCKED_TEMPLATE_ERROR = 'TOGGLE_BLOCKED_TEMPLATE_ERROR';

export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const REMOVE_TEMPLATE_START = 'REMOVE_TEMPLATE_START';
export const REMOVE_TEMPLATE_SUCCESS = 'REMOVE_TEMPLATE_SUCCESS';
export const REMOVE_TEMPLATE_ERROR = 'REMOVE_TEMPLATE_ERROR';

export const CLEAR_TEMPLATE_FILTERS_START = 'CLEAR_TEMPLATE_FILTERS_START';
export const CLEAR_TEMPLATE_FILTERS_SUCCESS = 'CLEAR_TEMPLATE_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_TEMPLATES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_TEMPLATES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_TEMPLATES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_TEMPLATES_CHANNEL';

export const CLEAR_TEMPLATE_MODAL_VALUES = 'CLEAR_TEMPLATE_MODAL_VALUES';

export const CREATE_TEMPLATE_FORM = 'CREATE_TEMPLATE_FORM';
export const UPDATE_TEMPLATE_FORM = 'UPDATE_TEMPLATE_FORM';
export const SELECT_TEMPLATE_CLIENTS_FORM = 'SELECT_TEMPLATE_CLIENTS_FORM';
export const SELECTED_TEMPLATES_FORM = 'SELECTED_TEMPLATES_FORM';
