export default {
  plural_downcase: 'lifestyles',
  select: 'Attach lifestyle',
  singular_lower_case_accusative: 'lifestyle',
  no: 'No lifestyles',
  add: 'Add a lifestyle',
  details: 'Lifestyle details',
  library: 'Lifestyles library',
  singular: 'Selected Lifestyle',
  show: 'Show selected lifestyles'
};
