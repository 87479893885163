import { READ_ALL_MESSAGES } from '../MessageConstants';

// readAllMessages

export default function readAllMessages(options = {}, cb) {
  return dispatch => dispatch({
    type: READ_ALL_MESSAGES,
    options,
    cb
  });
}
