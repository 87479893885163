import messages from './messages';
import words from './words';
import letters from './letters';
import forms from './forms/index';
import models from './models';
import pages from './pages';
import httpErrors from './httpErrors';

export default {
  en: {
    forms,
    letters,
    messages,
    models,
    pages,
    words,
    httpErrors
  }
};
