import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_IMAGE,
  REMOVE_IMAGE_START,
  REMOVE_IMAGE_SUCCESS,
  REMOVE_IMAGE_ERROR
} from '../ImageConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeImage({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_IMAGE_START, id, options));

  return yield spawn(removeItemResource, 'images', {
    success: REMOVE_IMAGE_SUCCESS,
    error: REMOVE_IMAGE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveImage() {
  yield takeEvery(REMOVE_IMAGE, removeImage);
}
