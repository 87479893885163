import get from 'lodash/get';

import { updateState } from '../../../utils';

export function fetchItemsStart(state, { options = {} }) {
  const currentItemsFetched = options.namespace ? get(state, `${options.namespace}.itemsFetched`, false) : state.itemsFetched;
  return updateState(state, {
    itemsLoading: true,
    itemsFetched: options.loadMore ? currentItemsFetched : false
  }, options);
}

export function fetchItemsSuccess(state, { items, meta, options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsErrorMessage: null,
    itemsFetched: true,
    items: options.loadMore ? [...(options.namespace ? get(state, `${options.namespace}.items`, []) : state.items), ...items] : items,
    itemsMeta: meta
  }, options);
}

export function fetchItemsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsFetched: true,
    itemsErrorMessage: errorMessage
  }, options);
}
