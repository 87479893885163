export const FETCH_PAGES = 'FETCH_PAGES';
export const FETCH_PAGES_START = 'FETCH_PAGES_START';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_ERROR = 'FETCH_PAGES_ERROR';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_START = 'FETCH_PAGE_START';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_ERROR = 'FETCH_PAGE_ERROR';

export const CREATE_PAGE = 'CREATE_PAGE';
export const CREATE_PAGE_START = 'CREATE_PAGE_START';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_ERROR = 'CREATE_PAGE_ERROR';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_PAGE_START = 'UPDATE_PAGE_START';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_ERROR = 'UPDATE_PAGE_ERROR';

export const REMOVE_PAGE = 'REMOVE_PAGE';
export const REMOVE_PAGE_START = 'REMOVE_PAGE_START';
export const REMOVE_PAGE_SUCCESS = 'REMOVE_PAGE_SUCCESS';
export const REMOVE_PAGE_ERROR = 'REMOVE_PAGE_ERROR';

export const CLEAR_PAGES_FILTERS_START = 'CLEAR_PAGES_FILTERS_START';
export const CLEAR_PAGES_FILTERS_SUCCESS = 'CLEAR_PAGES_FILTERS_SUCCESS';
