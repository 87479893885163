export default {
  'select...': 'Select groups',
  plural: 'Groups',
  singular: 'Group',
  add: 'Add group',
  'add...': 'Add groups...',
  change: 'Change groups',
  select: 'Select groups...',
  selected: 'Selected groups',
  no: 'No groups'
};
