export default {
  path: null,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  itemsSuccessMessage: null,
  itemsErrorMessage: null,
  itemsMeta: {
    page: 1,
    pageSize: 50,
    filters: {}
  },

  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null,
  itemCreateSuccessMessage: null,
  itemDownloadErrorMessage: null,
  itemUpdateSuccessMessage: null,
  itemRemoveSuccessMessage: null,
  itemCreateErrorMessage: null,
  itemUpdateErrorMessage: null,
  itemRemoveErrorMessage: null,

  itemUploading: false,
  itemUploadErrorMessage: null,

  itemsUploading: false,
  itemsUploadErrorMessage: null,

  isLoading: false,
  successMessage: null,
  errorMessage: null,

  filtersCleared: false
};
