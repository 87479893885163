import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function emptyTemplatesSetStart(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: null,
      loading: true
    }
  }, options);
}

export function emptyTemplatesSetSuccess(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      selected_scenes: [],
      error: null,
      loading: false
    }
  }, options);
}

export function emptyTemplatesSetError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: errorMessage,
      loading: false
    }
  }, options);
}
