import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_REVENUE_SHARE_ITEM,
  REMOVE_REVENUE_SHARE_ITEM_START,
  REMOVE_REVENUE_SHARE_ITEM_SUCCESS,
  REMOVE_REVENUE_SHARE_ITEM_ERROR
} from '../RevenueShareItemConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeRevenueShareItem({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_REVENUE_SHARE_ITEM_START, id, options));

  return yield spawn(removeItemResource, 'revenue_share_items', {
    success: REMOVE_REVENUE_SHARE_ITEM_SUCCESS,
    error: REMOVE_REVENUE_SHARE_ITEM_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveRevenueShareItem() {
  yield takeEvery(REMOVE_REVENUE_SHARE_ITEM, removeRevenueShareItem);
}
