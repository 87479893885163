import { replaceById } from '../../../utils';

export function toggleSelectedTemplateStart(state) {
  return state;
}

export function toggleSelectedTemplateSuccess(state, { id }) {
  return {
    ...state,
    items: replaceById(state.items, id, i => ({ ...i, selected: !i.selected }))
  };
}

export function toggleSelectedTemplateError(state, { errorMessage }) {
  return {
    ...state,
    errorMessage
  };
}
