export default {
  plural: 'Drawings',
  plural_lower_case: 'drawings',
  singular: 'Drawing',
  singular_lower_case: 'drawing',
  add: 'Add drawing',
  no: 'No drawings',
  upload: 'Upload new drawing',
  upload2: 'Upload drawings'
};
