import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_ERROR
} from '../TransactionConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTransactions(params) {
  yield put(fetchItemsStart(FETCH_TRANSACTIONS_START, params));

  const fetchTransactionsAction = yield spawn(fetchItemsResource, 'transactions', {
    success: FETCH_TRANSACTIONS_SUCCESS,
    error: FETCH_TRANSACTIONS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(fetchTransactionsAction);
}

export default function* watchFetchTransactions() {
  yield takeEvery(FETCH_TRANSACTIONS, fetchTransactions);
}
