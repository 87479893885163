import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

// const TaskNewPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskNewPage/TaskNewPage') });
const TaskShowPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskShowPage/TaskShowPage') });
const TaskAcceptPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskAcceptPage/TaskAcceptPage') });
const TaskDescriptionPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskDescriptionPage/TaskDescriptionPage') });
// const TaskAttachmentsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskAttachmentsPage/TaskAttachmentsPage') });
const TaskFilesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskFilesPage/TaskFilesPage') });
const TaskImagesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskImagesPage/TaskImagesPage') });
const TaskProductsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskProductsPage/TaskProductsPage') });
const TaskTemplatesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskTemplatesPage/TaskTemplatesPage') });
const TaskMaterialsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskMaterialsPage/TaskMaterialsPage') });
const TaskSourceFilesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks-source-files" */'./pages/TaskSourceFilesPage/TaskSourceFilesPage') });
const TaskTimelinePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks-timeline" */'./pages/TaskTimelinePage/TaskTimelinePage') });
const TaskTransactionsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskTransactionsPage/TaskTransactionsPage') });
const TaskPayoutsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "task-payouts" */'./pages/TaskPayoutsPage/TaskPayoutsPage') });
const TaskResultsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskResultsPage/TaskResultsPage') });
const TaskNotificationsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TaskNotificationsPage/TaskNotificationsPage') });
const TasksIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'./pages/TasksIndexPage/TasksIndexPage') });
const TaskDrawingsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'../ts/tasks/pages/TaskDrawingsPage/TaskDrawingsPage') });
const TaskReferencesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'../ts/tasks/pages/TaskReferencesPage/TaskReferencesPage') });
const TaskTexturesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "tasks" */'../ts/tasks/pages/TaskTexturesPage/TaskTexturesPage') });

// <Route exact key="tasks-attachments" path="/tasks/:taskId(\d+)/attachments" component={TaskAttachmentsPage} />,

export default (
  <Fragment>
    <Route exact path="/tasks/:taskId(\d+)/accept" component={TaskAcceptPage} />
    <Route exact path="/tasks/:taskId(\d+)/description" component={TaskDescriptionPage} />
    <Route exact path="/tasks/:taskId(\d+)/files" component={TaskFilesPage} />
    <Route exact path="/tasks/:taskId(\d+)/images" component={TaskImagesPage} />
    <Route exact path="/tasks/:taskId(\d+)/products" component={TaskProductsPage} />
    <Route exact path="/tasks/:taskId(\d+)/templates" component={TaskTemplatesPage} />
    <Route exact path="/tasks/:taskId(\d+)/materials" component={TaskMaterialsPage} />
    <Route exact path="/tasks/:taskId(\d+)/source-files" component={TaskSourceFilesPage} />
    <Route exact path="/tasks/:taskId(\d+)/drawings" component={TaskDrawingsPage} />
    <Route exact path="/tasks/:taskId(\d+)/references" component={TaskReferencesPage} />
    <Route exact path="/tasks/:taskId(\d+)/textures" component={TaskTexturesPage} />
    <Route exact path="/tasks/:taskId(\d+)/timeline" component={TaskTimelinePage} />
    <Route exact path="/tasks/:taskId(\d+)/payouts" component={TaskPayoutsPage} />
    <Route exact path="/tasks/:taskId(\d+)/transactions" component={TaskTransactionsPage} />
    <Route exact path="/tasks/:taskId(\d+)/results" component={TaskResultsPage} />
    <Route exact path="/tasks/:taskId(\d+)/notifications" component={TaskNotificationsPage} />
    <Route exact path="/tasks/:taskId(\d+)" component={TaskShowPage} />
    <Route exact path="/tasks" component={TasksIndexPage} />
  </Fragment>
);
