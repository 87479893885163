export default {
  singular: 'Access',
  types: {
    provided: 'Provided',
    received: 'Received',
    team: 'Team'
  },
  deny: 'Deny access',
  read_user_profile: 'Access Profile',
  no: 'No users with access'
};
