import { replaceById } from '../../../utils';

export function acceptInviteToProjectStart(state, { id }) {
  return {
    ...state,
    isLoading: true,
    errorMessage: null,
    items: replaceById(state.items, id, i => ({ ...i, loading: false }))
  };
}

export function acceptInviteToProjectSuccess(state, { id }) {
  return {
    ...state,
    isLoading: false,
    errorMessage: null,
    items: replaceById(state.items, id, i => ({ ...i, selected: !i.selected }))
  };
}

export function acceptInviteToProjectError(state, { id, errorMessage }) {
  return {
    ...state,
    isLoading: false,
    items: replaceById(state.items, id, i => ({ ...i, loading: false })),
    errorMessage
  };
}
