export default {
  plural: 'References',
  plural_lower_case: 'references',
  singular: 'Reference',
  singular_lower_case: 'reference',
  add: 'Add reference',
  no: 'No references',
  upload: 'Upload new reference',
  upload2: 'Upload references'
};
