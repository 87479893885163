import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function inviteUsersToTaskStart(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null,
    isLoading: true, // deprecated
    errorMessage: null, // deprecated
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: true,
      error: null
    }))
  }, options);
}

export function inviteUsersToTaskSuccess(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null,
    isLoading: false, // deprecated
    errorMessage: null, // deprecated
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      error: null
    }))
  }, options);
}

export function inviteUsersToTaskError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage,
    isLoading: false, // deprecated
    errorMessage, // deprecated
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      error: errorMessage
    }))
  }, options);
}
