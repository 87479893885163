import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const SectionShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_sections" */ './pages/SectionShowPage/SectionShowPage'
    )
});
const SectionsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_sections" */ './pages/SectionsIndexPage/SectionsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/sections/:sectionId(\d+)"
      component={SectionShowPage}
    />
    <Route exact path="/admin/sections" component={SectionsIndexPage} />
  </Fragment>
);
