import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function uploadItemsStart(state, { options = {} }) {
  return updateState(state, {
    itemsUploading: true
  }, options);
}

export function uploadItemsSuccess(state, { options = {} }) {
  return updateState(state, {
    itemsUploading: false,
    itemsUploadErrorMessage: null
    // items: [...currentStateValue(state, 'items', options, []), ...items]
  }, options);
}

export function uploadItemsError(state, { errorMessages, options = {} }) {
  return updateState(state, {
    itemsUploading: false,
    itemsUploadErrorMessage: errorMessages.join(', ')
  }, options);
}

export function uploadItemStart(state, { options = {} }) {
  return updateState(state, {
    itemUploading: true,
    itemUploadErrorMessage: null
  }, options);
}

export function uploadItemSuccess(state, { item, options = {} }) {
  return updateState(state, {
    item,
    itemUploading: false,
    itemUploadErrorMessage: null,
    items: [...currentStateValue(state, 'items', options, []), item]
  }, options);
}

export function uploadItemError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemUploading: false,
    itemUploadErrorMessage: errorMessage
  }, options);
}
