import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
// import createLogger from 'redux-logger';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { intlReducer } from 'react-intl-redux';
import createSagaMiddleware, { END } from 'redux-saga';

import { actionsLogger, cableMiddleware } from './middlewares';
import * as rootReducer from './reducers';

export { default as rootSaga } from './sagas';

export function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */
  const store = createStore(combineReducers({
    ...rootReducer,
    routing: routerReducer,
    intl: intlReducer
  }),
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      sagaMiddleware,
      actionsLogger,
      cableMiddleware,
      // createLogger(),
      routerMiddleware(history)
    )
  ));

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;

  // const finalCreateStore = applyMiddleware(thunk, sagaMiddleware, actionsLogger, routerMiddleware(history))(createStore);

  // return finalCreateStore(combineReducers({
  //   ...rootReducer,
  //   routing: routerReducer,
  //   intl: intlReducer
  // }), initialState);
}
