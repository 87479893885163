import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const PayoneerRequestsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_payoneer_requests" */ './pages/PayoneerRequestsIndexPage/PayoneerRequestsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/payoneer_requests"
      component={PayoneerRequestsIndexPage}
    />
  </Fragment>
);
