import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

import {
  createRevenueShareItemsError,
  createRevenueShareItemsStart,
  createRevenueShareItemsSuccess
} from './createRevenueShareItems';

import {
  updateRevenueShareItemsError,
  updateRevenueShareItemsStart,
  updateRevenueShareItemsSuccess
} from './updateRevenueShareItems';

export default createReducer(defaultState, {
  FETCH_REVENUE_SHARE_ITEMS_START: fetchItemsStart,
  FETCH_REVENUE_SHARE_ITEMS_SUCCESS: fetchItemsSuccess,
  FETCH_REVENUE_SHARE_ITEMS_ERROR: fetchItemsError,

  CLEAR_REVENUE_SHARE_ITEM_FILTERS_START: clearItemsFiltersStart,
  CLEAR_REVENUE_SHARE_ITEM_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_REVENUE_SHARE_ITEM_START: fetchItemStart,
  FETCH_REVENUE_SHARE_ITEM_SUCCESS: fetchItemSuccess,
  FETCH_REVENUE_SHARE_ITEM_ERROR: fetchItemError,

  UPDATE_REVENUE_SHARE_ITEMS_START: updateRevenueShareItemsStart,
  UPDATE_REVENUE_SHARE_ITEMS_SUCCESS: updateRevenueShareItemsSuccess,
  UPDATE_REVENUE_SHARE_ITEMS_ERROR: updateRevenueShareItemsError,

  CREATE_REVENUE_SHARE_ITEM_START: createItemStart,
  CREATE_REVENUE_SHARE_ITEM_SUCCESS: createItemSuccess,
  CREATE_REVENUE_SHARE_ITEM_ERROR: createItemError,

  CREATE_REVENUE_SHARE_ITEMS_START: createRevenueShareItemsStart,
  CREATE_REVENUE_SHARE_ITEMS_SUCCESS: createRevenueShareItemsSuccess,
  CREATE_REVENUE_SHARE_ITEMS_ERROR: createRevenueShareItemsError,

  UPDATE_REVENUE_SHARE_ITEM_START: updateItemStart,
  UPDATE_REVENUE_SHARE_ITEM_SUCCESS: updateItemSuccess,
  UPDATE_REVENUE_SHARE_ITEM_ERROR: updateItemError,

  REMOVE_REVENUE_SHARE_ITEM_START: removeItemStart,
  REMOVE_REVENUE_SHARE_ITEM_SUCCESS: removeItemSuccess,
  REMOVE_REVENUE_SHARE_ITEM_ERROR: removeItemError
});
