import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loaders';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import 'loaders.css/loaders.min.css';
import './Loading.scss';

function Loading({ className, loaded, item, ignoreLoader, type, withoutLoader, children }) {
  if (!loaded && withoutLoader) {
    return null;
  }

  return (
    <Fragment>
      {ignoreLoader}
      {loaded ? (item ? !isEmpty(item) : true) && children : (
        <div className={cl('loader-wrapper', className)}>
          <Loader type={type} />
        </div>
      )}
    </Fragment>
  );
}

Loading.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number }),
  className: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  withoutLoader: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  ignoreLoader: PropTypes.node
};

Loading.defaultProps = {
  item: null,
  className: null,
  children: null,
  type: 'ball-pulse',
  withoutLoader: false,
  ignoreLoader: null
};

export default Loading;
