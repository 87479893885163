import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_TEMPLATE,
  REMOVE_TEMPLATE_START,
  REMOVE_TEMPLATE_SUCCESS,
  REMOVE_TEMPLATE_ERROR
} from '../TemplateConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeTemplate({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_TEMPLATE_START, id, options));

  return yield spawn(removeItemResource, 'scenes', {
    success: REMOVE_TEMPLATE_SUCCESS,
    error: REMOVE_TEMPLATE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveTemplate() {
  yield takeEvery(REMOVE_TEMPLATE, removeTemplate);
}
