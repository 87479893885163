import React, { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import defaults from '../../../locales/en';

import { flattenMessages } from '../../../utils/ts/flattenMessages';

const messages = flattenMessages(defaults.en);

function translate(id: string, values = {}): ReactNode | string {
  if (id) {
    const defaultMessage = messages && messages[id];
    return (
      <FormattedMessage
        id={id}
        values={values}
        defaultMessage={
          typeof defaultMessage === 'string' ? defaultMessage : id
        }
        tagName={Fragment}
      />
    );
  }

  return null;
}

export default translate;
