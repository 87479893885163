import { updateState } from '../../../utils';

export function createRevenueShareItemsStart(state, { options = {} }) {
  return updateState(state, {
    itemsLoading: true,
    itemsErrorMessage: null
  }, options);
}

export function createRevenueShareItemsSuccess(state, { options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsErrorMessage: null
  }, options);
}

export function createRevenueShareItemsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsErrorMessage: errorMessage
  }, options);
}
