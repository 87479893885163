import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ATTACH_FILE_ATTACHMENTS,
  ATTACH_FILE_ATTACHMENTS_START,
  ATTACH_FILE_ATTACHMENTS_SUCCESS,
  ATTACH_FILE_ATTACHMENTS_ERROR
} from '../FileAttachmentConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* attachFileAttachments({ values, options, cb }) {
  yield put(postItemsStart(ATTACH_FILE_ATTACHMENTS_START, options));

  return yield spawn(postItemsResource, 'file_attachments/attach', {
    success: ATTACH_FILE_ATTACHMENTS_SUCCESS,
    error: ATTACH_FILE_ATTACHMENTS_ERROR
  }, { values, options, cb });
}

export default function* watchAttachFileAttachments() {
  yield takeEvery(ATTACH_FILE_ATTACHMENTS, attachFileAttachments);
}
