export default {
  plural: 'Imports',
  plural_lower_case: 'imports',
  singular: 'Import',
  no: 'No imports',
  types: {
    batch_revenue_share_items_import: 'Revenue share batch imports',
    batch_transactions_import: 'Transactions batch imports',
    batch_users_payoneer_ids_import: 'Users payoneer ID batch imports',
    batch_users_payoneer_ids_pe_import: 'Users payoneer ID PE batch imports'
  }
};
