import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_TASK_SELECTED_MATERIALS,
  SUBMIT_TASK_SELECTED_MATERIALS_START,
  SUBMIT_TASK_SELECTED_MATERIALS_SUCCESS,
  SUBMIT_TASK_SELECTED_MATERIALS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitTaskSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_TASK_SELECTED_MATERIALS_START, id, options));

  const submitTaskSelectedMaterialsAction = yield spawn(postItemResource, `discussions/${id}/submit_selected_materials`, {
    success: SUBMIT_TASK_SELECTED_MATERIALS_SUCCESS,
    error: SUBMIT_TASK_SELECTED_MATERIALS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitTaskSelectedMaterialsAction);
}

export default function* watchSubmitTaskSelectedMaterials() {
  yield takeEvery(SUBMIT_TASK_SELECTED_MATERIALS, submitTaskSelectedMaterials);
}
