import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SEND_TASK_RESULTS_TO_EMAIL,
  SEND_TASK_RESULTS_TO_EMAIL_START,
  SEND_TASK_RESULTS_TO_EMAIL_SUCCESS,
  SEND_TASK_RESULTS_TO_EMAIL_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* sendTaskResultsToEmail({ id, values, options, cb }) {
  yield put(postItemStart(SEND_TASK_RESULTS_TO_EMAIL_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/send_results`, {
    success: SEND_TASK_RESULTS_TO_EMAIL_SUCCESS,
    error: SEND_TASK_RESULTS_TO_EMAIL_ERROR
  }, { id, values, options, cb });
}

export default function* watchSendTaskResultsToEmail() {
  yield takeEvery(SEND_TASK_RESULTS_TO_EMAIL, sendTaskResultsToEmail);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   SEND_TASK_RESULTS_TO_EMAIL,
//   SEND_TASK_RESULTS_TO_EMAIL_START,
//   SEND_TASK_RESULTS_TO_EMAIL_SUCCESS,
//   SEND_TASK_RESULTS_TO_EMAIL_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function sendTaskResultsToEmailStart(id) {
//   return {
//     type: SEND_TASK_RESULTS_TO_EMAIL_START,
//     id
//   };
// }
//
// export function sendTaskResultsToEmailSuccess(id) {
//   return {
//     type: SEND_TASK_RESULTS_TO_EMAIL_SUCCESS,
//     id
//   };
// }
//
// export function sendTaskResultsToEmailError(id, error) {
//   return {
//     type: SEND_TASK_RESULTS_TO_EMAIL_ERROR,
//     errorMessage: parseError(error),
//     id,
//     error
//   };
// }
//
// function sendTaskResultsToEmailRequest(taskId, options = {}) {
//   return apiRequest.post(`discussions/${taskId}/send_results`, {}, options);
// }
//
// function* sendTaskResultsToEmailResource({ taskId, options: { form, serializer }, cb }) {
//   try {
//     yield call(sendTaskResultsToEmailRequest, taskId, { serializer });
//     yield put(sendTaskResultsToEmailSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(sendTaskResultsToEmailError(taskId, error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* sendTaskResultsToEmail({ taskId, options, cb }) {
//   yield put(sendTaskResultsToEmailStart(taskId));
//
//   return yield spawn(sendTaskResultsToEmailResource, { taskId, options, cb });
// }
//
// export default function* watchSendTaskResultsToEmail() {
//   yield takeEvery(SEND_TASK_RESULTS_TO_EMAIL, sendTaskResultsToEmail);
// }
