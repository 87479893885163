import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_MATERIAL,
  DOWNLOAD_MATERIAL_START,
  DOWNLOAD_MATERIAL_SUCCESS,
  DOWNLOAD_MATERIAL_ERROR
} from '../MaterialConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadMaterial({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_MATERIAL_START, id, options));

  const downloadMaterialAction = yield spawn(postItemResource, `materials/${id}/download`, {
    success: DOWNLOAD_MATERIAL_SUCCESS,
    error: DOWNLOAD_MATERIAL_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadMaterialAction);
}

export default function* watchDownloadMaterial() {
  yield takeEvery(DOWNLOAD_MATERIAL, downloadMaterial);
}
