import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_SELECTED_MATERIAL,
  REMOVE_SELECTED_MATERIAL_START,
  REMOVE_SELECTED_MATERIAL_SUCCESS,
  REMOVE_SELECTED_MATERIAL_ERROR
} from '../SelectedMaterialConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeSelectedMaterial({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_SELECTED_MATERIAL_START, id, options));

  return yield spawn(removeItemResource, 'selected_materials', {
    success: REMOVE_SELECTED_MATERIAL_SUCCESS,
    error: REMOVE_SELECTED_MATERIAL_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveSelectedMaterial() {
  yield takeEvery(REMOVE_SELECTED_MATERIAL, removeSelectedMaterial);
}
