import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, pure } from 'recompose';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import cl from 'classnames';
import get from 'lodash/get';
import isString from 'lodash/isString';
import omit from 'lodash/omit';

import { TOOLTIP_DELAY_SHOW, TOOLTIP_DELAY_HIDE } from '../constants';

import { translate as t } from '../../utils';

const tooltipDelay = { show: TOOLTIP_DELAY_SHOW, hide: TOOLTIP_DELAY_HIDE };

function PureButton({
  id,
  className,
  addClass,
  color,
  size,
  outline,
  rounded,
  block,
  icon,
  disabled,
  submit,
  style,
  tooltip,
  tooltipTarget,
  tooltipValue,
  tooltipPlacement,
  i18nText,
  handleClick
}) {
  const buttonClass = className
    || cl('btn', {
      [`btn-${color}`]: !!color,
      [`btn-${size}`]: !!size,
      'btn-outline': outline,
      'btn-rounded': rounded,
      'btn-block': block
    }, addClass);

  const buttonIcon = icon && (isString(icon) ? <i className={cl(icon)} /> : icon);

  return (
    <Fragment>
      <button // eslint-disable-line
        id={id || tooltipTarget || get(tooltip, 'target')}
        type={submit ? 'submit' : 'button'}
        className={buttonClass}
        disabled={disabled}
        style={style}
        onClick={handleClick}
      >
        {buttonIcon}
        {buttonIcon && i18nText ? ' ' : null}
        {i18nText ? t(i18nText) : null}
      </button>
      {tooltip || tooltipTarget ? (
        <UncontrolledTooltip
          placement={tooltipPlacement || get(tooltip, 'placement') || 'bottom'}
          delay={tooltipDelay}
          target={tooltipTarget || get(tooltip, 'target')}
          {...omit(tooltip, 'value', 'i18nText')}
        >
          {tooltipValue || tooltip.value}
        </UncontrolledTooltip>
      ) : null}
    </Fragment>
  );
}

PureButton.propTypes = {
  item: PropTypes.shape(),
  id: PropTypes.string,
  className: PropTypes.string,
  addClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
    PropTypes.string
  ]),
  color: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  block: PropTypes.bool,
  icon: PropTypes.string,
  afterIcon: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  handleClick: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  i18nText: PropTypes.string,
  tooltip: PropTypes.shape({
    target: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    placement: PropTypes.string
  }),
  tooltipTarget: PropTypes.string,
  tooltipValue: PropTypes.node,
  tooltipPlacement: PropTypes.string
};

PureButton.defaultProps = {
  item: null,
  id: null,
  className: null,
  addClass: null,
  color: null,
  size: null,
  outline: null,
  rounded: null,
  block: null,
  icon: null,
  afterIcon: null,
  submit: false,
  disabled: null,
  onClick: null,
  style: null,
  i18nText: null,
  tooltip: null,
  tooltipTarget: null,
  tooltipValue: null,
  tooltipPlacement: null
};

export default compose(
  pure,
  withHandlers({
    handleClick: ({ item, submit, onClick }) => e => {
      if (!submit) {
        e.preventDefault();
      }
      if (onClick) {
        onClick(item || e);
      }
    }
  })
)(PureButton);
