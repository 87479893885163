import { updateState } from '../../../utils';

export function changeTasksVisibilityStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function changeTasksVisibilitySuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function changeTasksVisibilityError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
