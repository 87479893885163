import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_REVENUE_SHARE_ITEMS,
  FETCH_REVENUE_SHARE_ITEMS_START,
  FETCH_REVENUE_SHARE_ITEMS_SUCCESS,
  FETCH_REVENUE_SHARE_ITEMS_ERROR
} from '../RevenueShareItemConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchRevenueShareItems(params) {
  yield put(fetchItemsStart(FETCH_REVENUE_SHARE_ITEMS_START, params));

  const fetchRevenueShareItemsAction = yield spawn(fetchItemsResource, 'revenue_share_items', {
    success: FETCH_REVENUE_SHARE_ITEMS_SUCCESS,
    error: FETCH_REVENUE_SHARE_ITEMS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRevenueShareItemsAction);
}

export default function* watchFetchRevenueShareItems() {
  yield takeEvery(FETCH_REVENUE_SHARE_ITEMS, fetchRevenueShareItems);
}
