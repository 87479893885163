import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TASK_NOTIFICATION,
  CREATE_TASK_NOTIFICATION_START,
  CREATE_TASK_NOTIFICATION_SUCCESS,
  CREATE_TASK_NOTIFICATION_ERROR
} from '../TaskNotificationConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTaskNotification({ values, options, cb }) {
  yield put(createItemStart(CREATE_TASK_NOTIFICATION_START, options));

  return yield spawn(createItemResource, 'discussion_notifications', {
    success: CREATE_TASK_NOTIFICATION_SUCCESS,
    error: CREATE_TASK_NOTIFICATION_ERROR
  }, { values, options, cb });
}

export default function* watchCreateTaskNotification() {
  yield takeEvery(CREATE_TASK_NOTIFICATION, createTaskNotification);
}
