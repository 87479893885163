import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_PERMISSIONS_START: fetchItemsStart,
  FETCH_PERMISSIONS_SUCCESS: fetchItemsSuccess,
  FETCH_PERMISSIONS_ERROR: fetchItemsError,

  CLEAR_PERMISSION_FILTERS_START: clearItemsFiltersStart,
  CLEAR_PERMISSION_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_PERMISSION_START: fetchItemStart,
  FETCH_PERMISSION_SUCCESS: fetchItemSuccess,
  FETCH_PERMISSION_ERROR: fetchItemError,

  CREATE_PERMISSION_START: createItemStart,
  CREATE_PERMISSION_SUCCESS: createItemSuccess,
  CREATE_PERMISSION_ERROR: createItemError,

  UPDATE_PERMISSION_START: updateItemStart,
  UPDATE_PERMISSION_SUCCESS: updateItemSuccess,
  UPDATE_PERMISSION_ERROR: updateItemError,

  REMOVE_PERMISSION_START: removeItemStart,
  REMOVE_PERMISSION_SUCCESS: removeItemSuccess,
  REMOVE_PERMISSION_ERROR: removeItemError
});
