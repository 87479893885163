import get from 'lodash/get';

function sendGoogleAnalyticsEvents(currentUser: {
  google_tag_user_id?: string;
}) {
  const userID = get(currentUser, 'google_tag_user_id');

  if (window.dataLayer && userID) {
    // window.dataLayer.push({
    //   event: 'userIdUpdated',
    //   userID
    // });

    window.dataLayer.push({
      event: 'login',
      userId: userID
    });
  }
}

export default sendGoogleAnalyticsEvents;
