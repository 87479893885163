export default {
  no: 'No auth controller requests',
  plural: 'Auth controller requests',
  refresh: 'Refresh auth controller requests list',
  singular: 'Auth controller request',
  method_names: {
    get: 'GET',
    post: 'POST',
    patch: 'PATCH',
    delete: 'DELETE'
  }
};
