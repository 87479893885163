import { useMemo } from 'react';

import { LinkMenuItems } from '../../types';

function useLinksWithPrefix(
  links: LinkMenuItems,
  linkPrefix: string
): [LinkMenuItems] {
  const linksWithPrefix = useMemo(() => {
    // If linkPrefix is empty string - just return initial links
    if (linkPrefix === '') return links;

    // Return new links array with added prefix in href (without mutations)
    return links.map(item => {
      if (item.type === 'link') {
        return { ...item, href: linkPrefix + item.href };
      } else {
        // if item.type === 'dropdown'
        const newItemLinks = item.links.map(nestedItem => {
          return { ...nestedItem, href: linkPrefix + nestedItem.href };
        });

        return { ...item, links: newItemLinks };
      }
    });
  }, [links, linkPrefix]);

  // Return links with prefixes
  return [linksWithPrefix];
}

export default useLinksWithPrefix;
