import React, { PureComponent, Fragment } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateIntl as updateIntlAction } from 'react-intl-redux';
import cl from 'classnames';
import get from 'lodash/get';
import Favicon from 'react-favicon';
import * as Sentry from '@sentry/browser';

import {
  validateToken as validateTokenAction,
  initWebsockets as initWebsocketsAction,
  logoutUser as logoutUserAction
} from '../../../auth/AuthActions';

import { Header } from '../../components';
import { Footer } from '../../components/ts/Footer';
import { CozyHeader } from '../../components/CozyHeader';
import { pushToAction } from '../../../utils/pushToAction';
import { loadAllLocales } from '../../../utils/ts/loadAllLocales';
import { profileLocaleInStorage } from '../../../utils/ts/profileLocaleInStorage';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';
import { isUserCozy } from '../../../utils/ts/isUserCozy';
import { CozyStorage } from '../../../utils/ts/CozyStorage';
import { COZY_FAVICON_URL, MAIN_FAVICON_URL } from '../../../config';

const ErrorPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "errors" */'../../components/ErrorPage/ErrorPage') });

class ErrorAppContainer extends PureComponent {
  static propTypes = {
    getRequestError: PropTypes.shape(),
    currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
    authFetched: PropTypes.bool.isRequired,
    updateIntl: PropTypes.func.isRequired,
    validateToken: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    pushTo: PropTypes.func.isRequired
  };

  static defaultProps = {
    getRequestError: null
  };

  state = {
    isSidebarHidden: false,
    isSidebarMobile: false
  };

  componentDidMount() {
    const { validateToken, logoutUser, updateIntl, pushTo } = this.props;

    // document.querySelector('body').classList.add('sidebar-xs');

    validateToken().then(({ currentUser }) => {
      if (currentUser) {
        CozyStorage.setIsUserCozy(isUserCozy({ plainUserData: currentUser }));

        if (process.env.NODE_ENV === 'production') {
          Sentry.configureScope(scope => {
            scope.setUser({ id: get(currentUser, 'id') });
          });
        }

        const locale = get(currentUser, 'locale') || 'en';

        return loadAllLocales(locale).then(messages => {
          updateIntl({
            locale,
            messages
          });

          profileLocaleInStorage.setLocale(locale);
        });
      }

      logoutUser();
      return pushTo('auth/login');
    });
  }

  toggleSidebarHidden = () => this.setState(prevState => ({ isSidebarHidden: !prevState.isSidebarHidden }));

  toggleSidebarMobile = () => this.setState(prevState => ({ isSidebarMobile: !prevState.isSidebarMobile }));

  handleLogout = () => {
    this.props.logoutUser();
    this.props.pushTo('auth/login');
  };

  render() {
    const { authFetched, getRequestError, currentUser } = this.props;
    const { isSidebarHidden, isSidebarMobile } = this.state;

    const code = get(getRequestError, 'response.status');

    return (
      <Fragment>
        {isUserCozy({ currentUser }) ? (
          <Fragment>
            <Favicon url={COZY_FAVICON_URL} />
            <CozyHeader
              pathname={get(this.props, 'location.pathname')}
              authFetched={authFetched}
              onToggleSidebarHidden={this.toggleSidebarHidden}
              onToggleSidebarMobile={this.toggleSidebarMobile}
              onLogout={this.handleLogout}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Favicon url={MAIN_FAVICON_URL} />
            <Header
              pathname={get(this.props, 'location.pathname')}
              authFetched={authFetched}
              onToggleSidebarHidden={this.toggleSidebarHidden}
              onToggleSidebarMobile={this.toggleSidebarMobile}
              onLogout={this.handleLogout}
            />
          </Fragment>
        )}
        <div
          className={cl('page-content', {
            'sidebar-hidden': isSidebarHidden,
            'sidebar-mobile': isSidebarMobile
          })}
        >
          <div className="content-wrapper">
            <Route key="error" render={props => (<ErrorPage statusCode={code} {...props} />)} />
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(({
  app: {
    getRequestError
  },
  auth: {
    authFetched,
    currentUser
  }
}) => ({ authFetched, currentUser, getRequestError }), {
  updateIntl: updateIntlAction,
  validateToken: validateTokenAction,
  initWebsockets: initWebsocketsAction,
  logoutUser: logoutUserAction,
  pushTo: pushToAction
})(ErrorAppContainer);
