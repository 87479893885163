import { updateState } from '../../../utils';

export function cancelOverwatchUsersStart(state, { options = {} }) {
  return updateState(
    state,
    {
      isLoading: true,
      errorMessage: null
    },
    options
  );
}

export function cancelOverwatchUsersSuccess(state, { options = {} }) {
  return updateState(
    state,
    {
      isLoading: false,
      errorMessage: null
    },
    options
  );
}

export function cancelOverwatchUsersError(
  state,
  { errorMessage, options = {} }
) {
  return updateState(
    state,
    {
      isLoading: false,
      errorMessage
    },
    options
  );
}
