import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPDATE_TASKS_ESTIMATE_DATES,
  UPDATE_TASKS_ESTIMATE_DATES_START,
  UPDATE_TASKS_ESTIMATE_DATES_SUCCESS,
  UPDATE_TASKS_ESTIMATE_DATES_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* updateTasksEstimateDates({ values, options, cb }) {
  yield put(postItemsStart(UPDATE_TASKS_ESTIMATE_DATES_START, options));

  const updateTasksEstimateDatesAction = yield spawn(postItemsResource, 'discussions/update_implementation_date', {
    success: UPDATE_TASKS_ESTIMATE_DATES_SUCCESS,
    error: UPDATE_TASKS_ESTIMATE_DATES_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(updateTasksEstimateDatesAction);
}

export default function* watchUpdateTasksEstimateDates() {
  yield takeEvery(UPDATE_TASKS_ESTIMATE_DATES, updateTasksEstimateDates);
}
