import { FETCH_CONTACT } from '../ContactConstants';

// fetchContact

export default function fetchContact(id, options = {}, cb) {
  return dispatch => dispatch({
    type: FETCH_CONTACT,
    id,
    options,
    cb
  });
}
