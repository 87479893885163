import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ControllerRequestsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_controller_requests" */ './pages/ControllerRequestsIndexPage/ControllerRequestsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/controller_requests"
      component={ControllerRequestsIndexPage}
    />
  </Fragment>
);
