import { put, call } from 'redux-saga/effects';
import omit from 'lodash/omit';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function fetchItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function fetchItemSuccess(type, id, item = {}, options = {}) {
  return {
    type,
    id,
    item,
    options
  };
}

export function fetchItemError(type, id, error, options = {}) {
  return {
    type,
    id,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function fetchItemRequest(path, id, options = {}) {
  return apiRequest.get(`${path}/${id}`, omit(options, 'namespace'))
    .then(({ data: { data: item } }) => ({ item }));
}

export function* fetchItemResource(path, { success, error: errorConst }, { id, options, cb }) {
  try {
    const { item } = yield call(fetchItemRequest, path, id, options);
    cb && cb({ item });
    yield put(fetchItemSuccess(success, id, item, options));
  } catch (error) {
    yield put(fetchItemError(errorConst, id, error, options));
  }
}
