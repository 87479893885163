export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const FETCH_EMAIL_TEMPLATES_START = 'FETCH_EMAIL_TEMPLATES_START';
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_TEMPLATES_ERROR = 'FETCH_EMAIL_TEMPLATES_ERROR';

export const FETCH_EMAIL_TEMPLATE = 'FETCH_EMAIL_TEMPLATE';
export const FETCH_EMAIL_TEMPLATE_START = 'FETCH_EMAIL_TEMPLATE_START';
export const FETCH_EMAIL_TEMPLATE_SUCCESS = 'FETCH_EMAIL_TEMPLATE_SUCCESS';
export const FETCH_EMAIL_TEMPLATE_ERROR = 'FETCH_EMAIL_TEMPLATE_ERROR';

export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';
export const CREATE_EMAIL_TEMPLATE_START = 'CREATE_EMAIL_TEMPLATE_START';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_ERROR = 'CREATE_EMAIL_TEMPLATE_ERROR';

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE_START = 'UPDATE_EMAIL_TEMPLATE_START';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_ERROR = 'UPDATE_EMAIL_TEMPLATE_ERROR';

export const REMOVE_EMAIL_TEMPLATE = 'REMOVE_EMAIL_TEMPLATE';
export const REMOVE_EMAIL_TEMPLATE_START = 'REMOVE_EMAIL_TEMPLATE_START';
export const REMOVE_EMAIL_TEMPLATE_SUCCESS = 'REMOVE_EMAIL_TEMPLATE_SUCCESS';
export const REMOVE_EMAIL_TEMPLATE_ERROR = 'REMOVE_EMAIL_TEMPLATE_ERROR';

export const ADD_PERMISSION_TO_EMAIL_TEMPLATE = 'ADD_PERMISSION_TO_EMAIL_TEMPLATE';
export const ADD_PERMISSION_TO_EMAIL_TEMPLATE_START = 'ADD_PERMISSION_TO_EMAIL_TEMPLATE_START';
export const ADD_PERMISSION_TO_EMAIL_TEMPLATE_SUCCESS = 'ADD_PERMISSION_TO_EMAIL_TEMPLATE_SUCCESS';
export const ADD_PERMISSION_TO_EMAIL_TEMPLATE_ERROR = 'ADD_PERMISSION_TO_EMAIL_TEMPLATE_ERROR';

export const REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE = 'REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE';
export const REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_START = 'REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_START';
export const REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_SUCCESS = 'REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_SUCCESS';
export const REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_ERROR = 'REMOVE_PERMISSION_FROM_EMAIL_TEMPLATE_ERROR';

export const CLEAR_EMAIL_TEMPLATES_FILTERS_START = 'CLEAR_EMAIL_TEMPLATES_FILTERS_START';
export const CLEAR_EMAIL_TEMPLATES_FILTERS_SUCCESS = 'CLEAR_EMAIL_TEMPLATES_FILTERS_SUCCESS';
