import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_SELECTED_MATERIAL,
  DOWNLOAD_SELECTED_MATERIAL_START,
  DOWNLOAD_SELECTED_MATERIAL_SUCCESS,
  DOWNLOAD_SELECTED_MATERIAL_ERROR
} from '../SelectedMaterialConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_SELECTED_MATERIAL_START, id, options));

  const downloadSelectedMaterialsAction = yield spawn(postItemResource, `selected_materials/${id}/download`, {
    success: DOWNLOAD_SELECTED_MATERIAL_SUCCESS,
    error: DOWNLOAD_SELECTED_MATERIAL_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadSelectedMaterialsAction);
}

export default function* watchDownloadSelectedMaterials() {
  yield takeEvery(DOWNLOAD_SELECTED_MATERIAL, downloadSelectedMaterials);
}
