import get from 'lodash/get';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';
import { CurrentUser } from '../../../types';
import { localeCurrencies, localeCurrencyRates } from '../../../constants';
import { isUserCozy } from '../../../utils/ts/isUserCozy';
import { renderAmount } from '../../../utils/ts/renderAmount';
import { I18nProps, withI18n } from '../../../adapters/HOCs/withI18n';

export interface CurrencyAmountProps {
  locale?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  usdAmount: number;
}

interface CurrentUserProps {
  currentUser: CurrentUser;
}

function CurrencyAmount({
  currentUser,
  intl,
  locale,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  usdAmount
}: CurrencyAmountProps & I18nProps & CurrentUserProps) {
  const currencyLocale =
    locale || isUserCozy({ currentUser }) ? currentUser.get('locale') : 'en';

  const currentAmount = usdAmount * get(localeCurrencyRates, currencyLocale, 1);

  const currentCurrency = intl.formatMessage({
    id: get(localeCurrencies, currencyLocale, 'models.currencies.usd')
  });

  return renderAmount(
    currentAmount,
    currentCurrency,
    currentCurrency !== '$',
    maximumFractionDigits,
    minimumFractionDigits
  );
}

export default withI18n<CurrencyAmountProps>()(
  withCurrentUser()(CurrencyAmount)
);
