import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_PROJECT_PROFILE,
  FETCH_PROJECT_PROFILE_START,
  FETCH_PROJECT_PROFILE_SUCCESS,
  FETCH_PROJECT_PROFILE_ERROR
} from '../ProjectProfileConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchProjectProfile({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_PROJECT_PROFILE_START, id, options));

  const fetchProjectProfileAction = yield spawn(fetchItemResource, 'task_profiles', {
    success: FETCH_PROJECT_PROFILE_SUCCESS,
    error: FETCH_PROJECT_PROFILE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchProjectProfileAction);
}

export default function* watchFetchProjectProfile() {
  yield takeEvery(FETCH_PROJECT_PROFILE, fetchProjectProfile);
}
