export const FETCH_LINKED_ACCOUNTS = 'FETCH_LINKED_ACCOUNTS';
export const FETCH_LINKED_ACCOUNTS_START = 'FETCH_LINKED_ACCOUNTS_START';
export const FETCH_LINKED_ACCOUNTS_SUCCESS = 'FETCH_LINKED_ACCOUNTS_SUCCESS';
export const FETCH_LINKED_ACCOUNTS_ERROR = 'FETCH_LINKED_ACCOUNTS_ERROR';

export const CLEAR_LINKED_ACCOUNTS_FILTERS_START = 'CLEAR_LINKED_ACCOUNTS_FILTERS_START';
export const CLEAR_LINKED_ACCOUNTS_FILTERS_SUCCESS = 'CLEAR_LINKED_ACCOUNTS_FILTERS_SUCCESS';

export const FETCH_LINKED_ACCOUNT = 'FETCH_LINKED_ACCOUNT';
export const FETCH_LINKED_ACCOUNT_START = 'FETCH_LINKED_ACCOUNT_START';
export const FETCH_LINKED_ACCOUNT_SUCCESS = 'FETCH_LINKED_ACCOUNT_SUCCESS';
export const FETCH_LINKED_ACCOUNT_ERROR = 'FETCH_LINKED_ACCOUNT_ERROR';

export const CREATE_LINKED_ACCOUNT = 'CREATE_LINKED_ACCOUNT';
export const CREATE_LINKED_ACCOUNT_START = 'CREATE_LINKED_ACCOUNT_START';
export const CREATE_LINKED_ACCOUNT_SUCCESS = 'CREATE_LINKED_ACCOUNT_SUCCESS';
export const CREATE_LINKED_ACCOUNT_ERROR = 'CREATE_LINKED_ACCOUNT_ERROR';

export const UPDATE_LINKED_ACCOUNT = 'UPDATE_LINKED_ACCOUNT';
export const UPDATE_LINKED_ACCOUNT_START = 'UPDATE_LINKED_ACCOUNT_START';
export const UPDATE_LINKED_ACCOUNT_SUCCESS = 'UPDATE_LINKED_ACCOUNT_SUCCESS';
export const UPDATE_LINKED_ACCOUNT_ERROR = 'UPDATE_LINKED_ACCOUNT_ERROR';

export const CREATE_LINKED_ACCOUNTS = 'CREATE_LINKED_ACCOUNTS';
export const CREATE_LINKED_ACCOUNTS_START = 'CREATE_LINKED_ACCOUNTS_START';
export const CREATE_LINKED_ACCOUNTS_SUCCESS = 'CREATE_LINKED_ACCOUNTS_SUCCESS';
export const CREATE_LINKED_ACCOUNTS_ERROR = 'CREATE_LINKED_ACCOUNTS_ERROR';

export const REMOVE_LINKED_ACCOUNT = 'REMOVE_LINKED_ACCOUNT';
export const REMOVE_LINKED_ACCOUNT_START = 'REMOVE_LINKED_ACCOUNT_START';
export const REMOVE_LINKED_ACCOUNT_SUCCESS = 'REMOVE_LINKED_ACCOUNT_SUCCESS';
export const REMOVE_LINKED_ACCOUNT_ERROR = 'REMOVE_LINKED_ACCOUNT_ERROR';

export const LINKED_ACCOUNT_LINK_FORM = 'LINKED_ACCOUNT_LINK_FORM';

export const LINKED_ACCOUNT_PROVIDE_ACCESS_FORM = 'LINKED_ACCOUNT_PROVIDE_ACCESS_FORM';
export const LINKED_ACCOUNT_REQUEST_ACCESS_FORM = 'LINKED_ACCOUNT_REQUEST_ACCESS_FORM';

export const PROVIDE_ACCESS_LINKED_ACCOUNTS = 'PROVIDE_ACCESS_LINKED_ACCOUNTS';
export const PROVIDE_ACCESS_LINKED_ACCOUNTS_START = 'PROVIDE_ACCESS_LINKED_ACCOUNTS_START';
export const PROVIDE_ACCESS_LINKED_ACCOUNTS_SUCCESS = 'PROVIDE_ACCESS_LINKED_ACCOUNTS_SUCCESS';
export const PROVIDE_ACCESS_LINKED_ACCOUNTS_ERROR = 'PROVIDE_ACCESS_LINKED_ACCOUNTS_ERROR';

export const REQUEST_ACCESS_LINKED_ACCOUNTS = 'REQUEST_ACCESS_LINKED_ACCOUNTS';
export const REQUEST_ACCESS_LINKED_ACCOUNTS_START = 'REQUEST_ACCESS_LINKED_ACCOUNTS_START';
export const REQUEST_ACCESS_LINKED_ACCOUNTS_SUCCESS = 'REQUEST_ACCESS_LINKED_ACCOUNTS_SUCCESS';
export const REQUEST_ACCESS_LINKED_ACCOUNTS_ERROR = 'REQUEST_ACCESS_LINKED_ACCOUNTS_ERROR';

export const CLEAR_LINKED_ACCOUNT_MODAL_VALUES = 'CLEAR_LINKED_ACCOUNT_MODAL_VALUES';
