import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange
} from '../../common/reducers';

import {
  acceptLinkedAccountRequestStart,
  acceptLinkedAccountRequestSuccess,
  acceptLinkedAccountRequestError
} from './acceptLinkedAccountRequest';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),

  ACCEPT_LINKED_ACCOUNT_REQUEST_START: acceptLinkedAccountRequestStart,
  ACCEPT_LINKED_ACCOUNT_REQUEST_SUCCESS: acceptLinkedAccountRequestSuccess,
  ACCEPT_LINKED_ACCOUNT_REQUEST_ERROR: acceptLinkedAccountRequestError
});
