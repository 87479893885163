import { UPDATE_DOWNLOAD } from '../DownloadConstants';

// updateDownload

export default function updateDownload(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: UPDATE_DOWNLOAD,
    id,
    values,
    options,
    cb
  });
}
