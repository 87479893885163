import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  removeItemStart, removeItemSuccess, removeItemError,
  uploadItemsStart, uploadItemsSuccess, uploadItemsError,
  uploadItemStart, uploadItemSuccess, uploadItemError,
  clearModalValues
} from '../../common/reducers';

import {
  attachSourceFilesStart,
  attachSourceFilesSuccess,
  attachSourceFilesError
} from './attachSourceFiles';

import {
  removeSourceFilesStart,
  removeSourceFilesSuccess,
  removeSourceFilesError
} from './removeSourceFiles';

export default createReducer(defaultState, {
  FETCH_SOURCE_FILES_START: fetchItemsStart,
  FETCH_SOURCE_FILES_SUCCESS: fetchItemsSuccess,
  FETCH_SOURCE_FILES_ERROR: fetchItemsError,

  REMOVE_SOURCE_FILE_START: removeItemStart,
  REMOVE_SOURCE_FILE_SUCCESS: removeItemSuccess,
  REMOVE_SOURCE_FILE_ERROR: removeItemError,

  UPLOAD_SOURCE_FILES_START: uploadItemsStart,
  UPLOAD_SOURCE_FILES_SUCCESS: uploadItemsSuccess,
  UPLOAD_SOURCE_FILES_ERROR: uploadItemsError,
  UPLOAD_SOURCE_FILE_START: uploadItemStart,
  UPLOAD_SOURCE_FILE_SUCCESS: uploadItemSuccess,
  UPLOAD_SOURCE_FILE_ERROR: uploadItemError,

  ATTACH_SOURCE_FILES_START: attachSourceFilesStart,
  ATTACH_SOURCE_FILES_SUCCESS: attachSourceFilesSuccess,
  ATTACH_SOURCE_FILES_ERROR: attachSourceFilesError,

  REMOVE_TASK_SOURCE_FILES_START: removeSourceFilesStart,
  REMOVE_TASK_SOURCE_FILES_SUCCESS: removeSourceFilesSuccess,
  REMOVE_TASK_SOURCE_FILES_ERROR: removeSourceFilesError,

  CLEAR_SOURCE_FILE_MODAL_VALUES: clearModalValues
});
