import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TAG,
  UPDATE_TAG_START,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_ERROR
} from '../TagConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTag({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TAG_START, id, options));

  return yield spawn(updateItemResource, 'tags', {
    success: UPDATE_TAG_SUCCESS,
    error: UPDATE_TAG_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTag() {
  yield takeEvery(UPDATE_TAG, updateTag);
}
