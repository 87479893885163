import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TODO_ITEMS,
  FETCH_TODO_ITEMS_START,
  FETCH_TODO_ITEMS_SUCCESS,
  FETCH_TODO_ITEMS_ERROR
} from '../TodoItemConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTodoItems(params) {
  yield put(fetchItemsStart(FETCH_TODO_ITEMS_START, params));

  const fetchTodoItemsAction = yield spawn(fetchItemsResource, 'todo_items', {
    success: FETCH_TODO_ITEMS_SUCCESS,
    error: FETCH_TODO_ITEMS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTodoItemsAction);
}

export default function* watchFetchTodoItems() {
  yield takeEvery(FETCH_TODO_ITEMS, fetchTodoItems);
}
