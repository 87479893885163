import { updateState } from '../../../utils';

export function updateRevenueShareItemsStart(state, { options = {} }) {
  return updateState(state, {
    itemsLoading: true,
    itemsErrorMessage: null,
    itemsSuccessMessage: null
  }, options);
}

export function updateRevenueShareItemsSuccess(state, { successMessage, options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsErrorMessage: null,
    itemsSuccessMessage: successMessage
  }, options);
}

export function updateRevenueShareItemsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemsLoading: false,
    itemsErrorMessage: errorMessage
  }, options);
}
