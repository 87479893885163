import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_MESSAGE_SELECTED_TEMPLATES,
  DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_START,
  DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_SUCCESS,
  DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadMessageSelectedTemplates({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_START, id, options));

  const downloadMessageSelectedTemplatesAction = yield spawn(postItemResource, `discussion_messages/${id}/selected_scenes`, {
    success: DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_SUCCESS,
    error: DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadMessageSelectedTemplatesAction);
}

export default function* watchDownloadMessageSelectedTemplates() {
  yield takeEvery(DOWNLOAD_MESSAGE_SELECTED_TEMPLATES, downloadMessageSelectedTemplates);
}
