import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  READ_ALL_MESSAGES,
  READ_ALL_MESSAGES_START,
  READ_ALL_MESSAGES_SUCCESS,
  READ_ALL_MESSAGES_ERROR
} from '../MessageConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* readAllMessages({ options, cb }) {
  yield put(postItemsStart(READ_ALL_MESSAGES_START, options));

  return yield spawn(postItemsResource, 'discussion_messages/read_all', {
    success: READ_ALL_MESSAGES_SUCCESS,
    error: READ_ALL_MESSAGES_ERROR
  }, { options, cb });
}

export default function* watchReadAllMessages() {
  yield takeEvery(READ_ALL_MESSAGES, readAllMessages);
}
