import { put, call } from 'redux-saga/effects';
import omit from 'lodash/omit';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function fetchFinanceItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function fetchFinanceItemSuccess(type, id, item = {}, options = {}) {
  return {
    type,
    id,
    item,
    options
  };
}

export function fetchFinanceItemError(type, id, error, options = {}) {
  return {
    type,
    id,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function fetchFinanceItemRequest(path, id, options = {}) {
  return apiRequest
    .financeGet(`${path}/${id}`, omit(options, 'namespace'))
    .then(({ data: { data: item } }) => ({ item }));
}

export function* fetchFinanceItemResource(
  path,
  { success, error: errorConst },
  { id, options, cb }
) {
  try {
    const { item } = yield call(fetchFinanceItemRequest, path, id, options);
    cb && cb({ item });
    yield put(fetchFinanceItemSuccess(success, id, item, options));
  } catch (error) {
    yield put(fetchFinanceItemError(errorConst, id, error, options));
  }
}
