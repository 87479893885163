export default {
  plural: 'Projects',
  singular: 'Project',
  singular_downcase: 'project',
  create_new: 'Create a new project',
  create: 'Create project',
  download_excel: 'Download excel document with all projects',
  download_selected_excel: 'Download excel document with selected projects',
  favorite: 'Favorite projects',
  new: 'New Project',
  no: 'No projects',
  status: 'Project Status',
  name: 'Project name',
  details: 'Project Details',
  edit_details: 'Edit project details',
  set_cost: 'Set project cost',
  account: 'Project account',
  add_description: 'Add project description',
  edit_description: 'Edit project description',
  enter_name: 'Enter the project name',
  team: 'Project team',
  members: 'Project members',
  select_user: 'Select project member',
  selected_users: 'Selected project members',
  unallocated_funds: 'Project unallocated funds',
  select: 'Select project...',
  destination: 'Destination project',
  profile: 'Task Profile',
  root: 'Project root',
  change_estimate_cost: 'Change project\'s estimate cost',
  estimate_cost: 'Project\'s estimate cost',
  messages: 'Project Messages',
  plural_genitive_lower_case: 'projects',
  name_a_z: 'Project name (A-Z)',
  name_z_a: 'Project name (Z-A)',
  track_time: 'Track project time'
};
