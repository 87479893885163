import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';

import { parseError } from '../../../utils/parseError';

import { maskEmail } from './utils/maskEmail';

interface AuthData {
  email?: string;
}

interface SendAuthErrorToSentryOptions {
  error: AxiosError;
  auth?: AuthData;
  tag: string;
  frRequestId: string;
}

function sendAuthErrorToSentry({
  error,
  auth,
  tag,
  frRequestId
}: SendAuthErrorToSentryOptions) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope(scope => {
      const extras = pickBy({
        authEmail: maskEmail(get(auth, 'email')),
        responseData: JSON.stringify(get(error, 'response.data'), null, 2),
        frRequestId,
        parsedError: parseError(error)
      });

      scope.setExtras(extras);
      scope.setTag('api-request', tag);

      const eventId = Sentry.captureException(error);

      console.log('Auth error', { eventId, scope, extras });
    });
  }
}

export default sendAuthErrorToSentry;
