import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import size from 'lodash/size';

import { PureLinkTo } from '../../helpers/ts/PureLinkTo';

import LinkMenuNestedDropdown from './LinkMenuNestedDropdown';

import { isMenuItemActive } from './utils/isMenuItemActive';

function LinkMenuDropdown({
  dropdownItem,
  toggle,
  dropdownState,
  pathname,
  collapse,
  history
}) {
  useEffect(() => {
    const unlisten = history.listen(collapse);
    return () => unlisten();
  }, [history, collapse]);

  return (
    <OutsideClickHandler onOutsideClick={collapse}>
      {dropdownItem.links && size(dropdownItem.links) > 0 ? (
        <div className={cl('dropdown', { show: dropdownState })}>
          <PureLinkTo
            pureLink
            className={cl('navbar-nav-link dropdown-toggle pr-1', {
              active: isMenuItemActive({ pathname, item: dropdownItem })
            })}
            aria-expanded={dropdownState}
            onClick={toggle}
            i18nText={dropdownItem.label}
          />
          <div className={cl('dropdown-menu', { show: dropdownState })}>
            {dropdownItem.links.map(link =>
              link.type === 'link' ? (
                <Fragment key={`link-menu-${link.namespace}-item`}>
                  <PureLinkTo
                    className={cl('dropdown-item', {
                      active: isMenuItemActive({ pathname, item: link })
                    })}
                    href={link.href}
                    icon={link.icon}
                    onClick={collapse}
                    i18nText={link.label}
                  />
                </Fragment>
              ) : (
                <Fragment key={`link-menu-${link.namespace}-item`}>
                  <LinkMenuNestedDropdown
                    dropdownItem={link}
                    pathname={pathname}
                  />
                </Fragment>
              )
            )}
          </div>
        </div>
      ) : null}
    </OutsideClickHandler>
  );
}

LinkMenuDropdown.propTypes = {
  dropdownItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          type: PropTypes.string,
          ref: PropTypes.string,
          namespace: PropTypes.string,
          label: PropTypes.string
        }),
        PropTypes.shape({
          type: PropTypes.string,
          namespace: PropTypes.string,
          label: PropTypes.string,
          links: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              ref: PropTypes.string,
              namespace: PropTypes.string,
              label: PropTypes.string
            })
          )
        })
      ])
    )
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  dropdownState: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired
  }).isRequired
};

export default compose(
  withRouter,
  withState('dropdownState', 'changeDropdownState', false),
  withHandlers({
    toggle: ({ dropdownState, changeDropdownState }) => () =>
      changeDropdownState(!dropdownState),
    collapse: ({ dropdownState, changeDropdownState }) => () =>
      dropdownState && changeDropdownState(false)
  }),
  pure
)(LinkMenuDropdown);
