import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeProductsSetSelectedProductsStart(state, { id, options = {} }) {
  const currentProductsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentProductsSet,
      selected_facilities: replaceById(get(currentProductsSet, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: null,
        loading: true
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, productsSet => ({
      ...productsSet,
      selected_facilities: replaceById(get(productsSet, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeProductsSetSelectedProductsSuccess(state, { id, options = {} }) {
  const currentProductsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentProductsSet,
      selected_facilities: removeById(get(currentProductsSet, 'selected_facilities', []), id)
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, productsSet => ({
      ...productsSet,
      selected_facilities: removeById(get(productsSet, 'selected_facilities', []), id)
    }))
  }, options);
}

export function removeProductsSetSelectedProductsError(state, { id, errorMessage, options = {} }) {
  const currentProductsSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentProductsSet,
      selected_facilities: replaceById(get(currentProductsSet, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: errorMessage,
        loading: false
      }))
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, productsSet => ({
      ...productsSet,
      selected_facilities: replaceById(get(productsSet, 'selected_facilities', []), id, selectedProduct => ({
        ...selectedProduct,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
