import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const MessagesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "messages" */ './pages/MessagesIndexPage/MessagesIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/messages" component={MessagesIndexPage} />
  </Fragment>
);
