import { put, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import pluralize from 'pluralize';

import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function postItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function postItemSuccess(type, id, item = {}, options = {}, values) {
  return {
    type,
    item,
    id,
    options,
    values
  };
}

export function postItemError(type, id, error, options = {}) {
  return {
    type,
    id,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function postItemRequest(path, values, options = {}) {
  return apiRequest
    .post(path, options.pure ? values : { [options.param || pluralize.singular(path)]: values }, omit(options, 'param'))
    .then(({ data: { data: item } }) => ({ item }));
}

export function* postItemResource(path, { success, error: errorConst, param }, { id, values, options, cb }) {
  try {
    const { item } = yield call(postItemRequest, path, values, { ...options, ...(param ? { param } : {}) });
    yield put(postItemSuccess(success, id, item, options, values));
    cb && isFunction(cb) && cb(item);
  } catch (error) {
    yield put(postItemError(errorConst, id, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}
