export default {
  plural: 'Promo Codes',
  plural_downcase: 'promo codes',
  singular: 'Promo Code',
  new: 'New Promo Code',
  'add...': 'Add Promo Codes',
  change: 'Change Promo Codes',
  'select...': 'Select Promo Codes...',
  no: 'No Promo Codes',
  type: 'Promo code type',
  types: {
    fixed: 'fixed',
    percent: 'percent'
  },
  fields: {
    project_briefs_count: 'Project briefs',
    current_uses_count: 'Current uses',
    max_uses_count: 'Max uses'
  }
};
