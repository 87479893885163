import { Locale } from '../../../types';

type ValueFromStorage = Locale | null;

const STORAGE_KEY = 'profile_locale';

function getLocale(): ValueFromStorage {
  return window.localStorage.getItem(STORAGE_KEY) as ValueFromStorage;
}

function setLocale(locale: Locale): void {
  window.localStorage.setItem(STORAGE_KEY, locale);
}

export default { getLocale, setLocale };
