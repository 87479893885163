import React, { Fragment, ReactNode } from 'react';

import { PureButton, PureButtonProps } from '../PureButton';
import { TooltipProps } from '../Tooltip';

import {
  SimpleModal,
  SimpleModalOptions,
  SimpleModalProps,
  useSimpleModal
} from '../SimpleModal';

export interface ModalButtonProps extends SimpleModalProps, SimpleModalOptions {
  i18nButtonText?: string;
  beforeButton?: ReactNode;
  afterButton?: ReactNode;
  disabledButton?: boolean;
  modalOpened?: boolean;
}

function ModalButton({
  modalBodyAddClass,
  modalIcon,
  modalSize,
  modalTitle,
  i18nModalTitle,
  isLoading,
  disabled,
  cancelColor = 'white',
  cancelI18nText = 'words.cancel',
  cancelDisabled,
  i18nButtonText,
  disabledButton,
  submitColor = 'primary',
  i18nSubmitText = 'words.save',
  submitAddClass,
  submitDisabled,
  submitIcon,
  submitSize = 'sm',
  submitText,
  submitTooltipI18nValue,
  submitTooltipTarget,
  onOpen,
  onClose,
  onToggle,
  onSubmit,
  afterSubmit,
  children,
  beforeButton,
  afterButton,
  withoutCancel,
  withoutBody,
  withoutFooter,
  modalOpened = true,
  ...buttonProps
}: ModalButtonProps & PureButtonProps & TooltipProps) {
  const { isOpen, showModal, hideModal, handleSubmit } = useSimpleModal({
    onOpen,
    onClose,
    onToggle,
    onSubmit,
    afterSubmit
  });

  return (
    <Fragment>
      {beforeButton}
      <PureButton
        onClick={showModal}
        i18nText={i18nButtonText}
        disabled={disabledButton}
        {...buttonProps}
      />
      {afterButton}

      <SimpleModal
        modalBodyAddClass={modalBodyAddClass}
        modalIcon={modalIcon}
        modalSize={modalSize}
        modalTitle={modalTitle}
        i18nModalTitle={i18nModalTitle}
        isLoading={isLoading}
        disabled={disabled}
        cancelColor={cancelColor}
        cancelI18nText={cancelI18nText}
        cancelDisabled={cancelDisabled}
        submitColor={submitColor}
        i18nSubmitText={i18nSubmitText}
        submitAddClass={submitAddClass}
        submitDisabled={submitDisabled}
        submitIcon={submitIcon}
        submitSize={submitSize}
        submitText={submitText}
        submitTooltipI18nValue={submitTooltipI18nValue}
        submitTooltipTarget={submitTooltipTarget}
        isOpen={isOpen && modalOpened}
        hideModal={hideModal}
        handleSubmit={handleSubmit}
        withoutCancel={withoutCancel}
        withoutBody={withoutBody}
        withoutFooter={withoutFooter}
      >
        {children}
      </SimpleModal>
    </Fragment>
  );
}

export default ModalButton;
