import React, { memo, useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cl from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import { useCurrentUser } from '../../../../../../common/hooks/useAuth';

import { PureButton } from '../../../../../../helpers/ts/PureButton';
import { LinkTo } from '../../../../../../helpers/ts/LinkTo';
import { Avatar } from '../../../../../../helpers/ts/Avatar';

interface AdminUserMenuProps {
  onLogout: () => void;
}

function AdminUserMenu({
  onLogout,
  history
}: AdminUserMenuProps & RouteComponentProps) {
  const currentUser = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setIsOpen(value => !value), []);
  const handleCollapse = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const unlisten = history.listen(handleCollapse);
    return () => unlisten();
  }, [history, handleCollapse]);

  return (
    <li
      className={cl('nav-item dropdown dropdown-user', {
        show: isOpen
      })}
    >
      <OutsideClickHandler onOutsideClick={handleCollapse}>
        <LinkTo
          pureLink
          className="navbar-nav-link dropdown-toggle"
          aria-expanded={isOpen}
          onClick={handleToggleOpen}
        >
          <Avatar image={currentUser.image} />
          <span>{currentUser.get('full_name')}</span>
        </LinkTo>

        <div
          className={cl('dropdown-menu dropdown-menu-right', {
            show: isOpen
          })}
        >
          <PureButton
            className="dropdown-item"
            icon="icon-switch2"
            onClick={onLogout}
            i18nText="words.logout"
          />
        </div>
      </OutsideClickHandler>
    </li>
  );
}

export default withRouter(memo(AdminUserMenu));
