import { CREATE_CONTACT } from '../ContactConstants';

export default function createContact(values, options = {}, cb) {
  return dispatch => dispatch({
    type: CREATE_CONTACT,
    values,
    options,
    cb
  });
}
