import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const S3ObjectShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_s3_objects" */ './pages/S3ObjectShowPage/S3ObjectShowPage'
    )
});
const S3ObjectsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_s3_objects" */ './pages/S3ObjectsIndexPage/S3ObjectsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/s3_objects/:s3ObjectId"
      component={S3ObjectShowPage}
    />
    <Route exact path="/admin/s3_objects" component={S3ObjectsIndexPage} />
  </Fragment>
);
