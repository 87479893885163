import get from 'lodash/get';

import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function itemsChannelCreated(state, { data, options = {} }) {
  const currentItem = currentStateValue(state, 'item', options, {});
  const currentItems = currentStateValue(state, 'items', options, []);
  const currentItemsMeta = currentStateValue(state, 'itemsMeta', options, {});

  return updateState(state, {
    item: get(currentItem, 'id') === data.id ? { ...currentItem, ...data } : currentItem,
    items: [data, ...currentItems],
    itemsMeta: { ...currentItemsMeta, total: get(currentItemsMeta, 'total', 0) + 1 }
  }, options);
}
