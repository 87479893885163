import { UNREAD_MESSAGE } from '../MessageConstants';

// unreadMessage

export default function unreadMessage(id, options = {}, cb) {
  return dispatch => dispatch({
    type: UNREAD_MESSAGE,
    id,
    options,
    cb
  });
}
