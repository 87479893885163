import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_START,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_ERROR
} from '../ContactConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchContacts(params) {
  yield put(fetchItemsStart(FETCH_CONTACTS_START, params));

  const fetchContactsAction = yield spawn(fetchItemsResource, 'feed_backs', {
    success: FETCH_CONTACTS_SUCCESS,
    error: FETCH_CONTACTS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchContactsAction);
}

export default function* watchFetchContacts() {
  yield takeEvery(FETCH_CONTACTS, fetchContacts);
}
