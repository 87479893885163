import get from 'lodash/get';

const templatesRegExp = /(^\/templates\/?(\d+\/?|.*\/(asc|desc)\/?)?$)/;

/**
 * @deprecated Use main/common/reducers/locationChange instead.
 */
export function locationChange(defaultState) {
  return function locationChangeResource(state, { payload }) {
    return templatesRegExp.test(state.path) && templatesRegExp.test(payload.pathname) ? { ...defaultState } : {
      ...state,
      path: payload.pathname,
      itemErrorMessage: null,
      itemCreateErrorMessage: null,
      itemDownloadErrorMessage: null,
      itemUpdateErrorMessage: null,
      itemRemoveErrorMessage: null,
      itemUploadErrorMessage: null,
      itemsErrorMessage: null,
      itemsUploadErrorMessage: null,
      errorMessage: null
    };
  };
}

export function initTemplatesFilters(state, { currentUser }) {
  return {
    ...state,
    itemsMeta: {
      ...state.itemsMeta,
      filters: get(currentUser, 'scene_search.data')
    }
  };
}
