import { updateState } from '../../../utils';

export function changeTasksStatusStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function changeTasksStatusSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function changeTasksStatusError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
