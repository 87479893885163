import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REQUEST_ACCESS_LINKED_ACCOUNTS,
  REQUEST_ACCESS_LINKED_ACCOUNTS_START,
  REQUEST_ACCESS_LINKED_ACCOUNTS_SUCCESS,
  REQUEST_ACCESS_LINKED_ACCOUNTS_ERROR
} from '../LinkedAccountConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* requestAccessLinkedAccounts({ id, values, options, cb }) {
  yield put(postItemStart(REQUEST_ACCESS_LINKED_ACCOUNTS_START, id, options));

  return yield spawn(postItemResource, `linked_users/${id}/request_access`, {
    success: REQUEST_ACCESS_LINKED_ACCOUNTS_SUCCESS,
    error: REQUEST_ACCESS_LINKED_ACCOUNTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchRequestAccessLinkedAccount() {
  yield takeEvery(REQUEST_ACCESS_LINKED_ACCOUNTS, requestAccessLinkedAccounts);
}
