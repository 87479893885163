import { SOCKET_UNSUBSCRIBE_FROM_MESSAGES_CHANNEL } from '../MessageConstants';

export default function unsubscribeFromMessagesChannel(action, messageId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_UNSUBSCRIBE_FROM_MESSAGES_CHANNEL,
    channel: 'Archi::Crm::DiscussionMessagesChannel',
    command: 'unsubscribe',
    action,
    params: { discussion_message_id: messageId, ...params }
  });
}
