import { SUBMIT_MESSAGE_SELECTED_PRODUCTS } from '../MessageConstants';

// submitMessageSelectedProducts

export default function submitMessageSelectedProducts(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: SUBMIT_MESSAGE_SELECTED_PRODUCTS,
    id,
    values,
    options,
    cb
  });
}
