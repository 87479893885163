import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHECK_DOWNLOAD,
  CHECK_DOWNLOAD_START,
  CHECK_DOWNLOAD_SUCCESS,
  CHECK_DOWNLOAD_ERROR
} from '../DownloadConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* checkDownload({ id, options, cb }) {
  yield put(postItemStart(CHECK_DOWNLOAD_START, id, options));

  return yield spawn(postItemResource, `downloads/${id}/check`, {
    success: CHECK_DOWNLOAD_SUCCESS,
    error: CHECK_DOWNLOAD_ERROR
  }, { id, options, cb });
}

export default function* watchCheckDownload() {
  yield takeEvery(CHECK_DOWNLOAD, checkDownload);
}
