import React from 'react';

import {
  fetchDownloads as fetchDownloadsAction,
  fetchDownload as fetchDownloadAction,
  clearDownloadsFilters as clearDownloadsFiltersAction,
  clearDownloadModalValues as clearDownloadModalValuesAction,
  updateDownload as updateDownloadAction,
  removeDownload as removeDownloadAction,
  checkDownload as checkDownloadAction,
  setDownloadsBoxOpened as setDownloadsBoxOpenedAction
} from '../../../downloads/DownloadActions';

import {
  subscribeToDownloadsChannel as subscribeToDownloadsChannelAction,
  unsubscribeFromDownloadsChannel as unsubscribeFromDownloadsChannelAction,
  subscribeToDropdownDownloadsChannel as subscribeToDropdownDownloadsChannelAction,
  unsubscribeFromDropdownDownloadsChannel as unsubscribeFromDropdownDownloadsChannelAction
} from '../../../downloads/DownloadSubscriptions';

import { baseHOC } from '../utils';

const withDownloads = (options = {}) => WrappedComponent => {
  function DownloadsHoc(props) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return baseHOC(options, {
    fetchDownloads: fetchDownloadsAction,
    fetchDownload: fetchDownloadAction,
    clearDownloadsFilters: clearDownloadsFiltersAction,
    clearDownloadModalValues: clearDownloadModalValuesAction,
    updateDownload: updateDownloadAction,
    removeDownload: removeDownloadAction,
    checkDownload: checkDownloadAction,
    setDownloadsBoxOpened: setDownloadsBoxOpenedAction,
    subscribeToDownloadsChannel: subscribeToDownloadsChannelAction,
    unsubscribeFromDownloadsChannel: unsubscribeFromDownloadsChannelAction,
    subscribeToDropdownDownloadsChannel: subscribeToDropdownDownloadsChannelAction,
    unsubscribeFromDropdownDownloadsChannel: unsubscribeFromDropdownDownloadsChannelAction
  }, {
    checkDownload: ({ checkDownload }) => download => () => checkDownload(download.id, {}, () => window.open(download.file))
  }, {
    downloadsBoxOpened: 'downloadsBoxOpened'
  })(DownloadsHoc);
};

export default withDownloads;
