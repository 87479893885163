import { updateState } from '../../../utils';

export function submitProjectProfileSelectedProductsStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function submitProjectProfileSelectedProductsSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function submitProjectProfileSelectedProductsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
