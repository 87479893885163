import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../utils/LoadableWrapper';

const ProjectBriefPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "makeover" */ './projectBrief/pages/ProjectBriefPage/ProjectBriefPage'
    )
});

const RoomBriefPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "makeover_v2" */ './roomBrief/pages/RoomBriefPage/RoomBriefPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/cozy" component={ProjectBriefPage} />
    <Route exact path="/makeover" component={RoomBriefPage} />
  </Fragment>
);
