import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError
} from '../../common/reducers';

import { clearContactModalValues } from './clearContactModalValues';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_CONTACTS_START: fetchItemsStart,
  FETCH_CONTACTS_SUCCESS: fetchItemsSuccess,
  FETCH_CONTACTS_ERROR: fetchItemsError,

  FETCH_CONTACT_START: fetchItemStart,
  FETCH_CONTACT_SUCCESS: fetchItemSuccess,
  FETCH_CONTACT_ERROR: fetchItemError,

  CREATE_CONTACT_START: createItemStart,
  CREATE_CONTACT_SUCCESS: createItemSuccess,
  CREATE_CONTACT_ERROR: createItemError,

  CLEAR_CONTACT_MODAL_VALUES: clearContactModalValues
});
