export default {
  plural: 'Messages',
  write: 'Write a message',
  'write...': 'Write a message...',
  post: 'Post message',
  select_user: 'Select author',
  selected_users: 'Selected authors',
  members: 'Message authors',
  latest: 'Latest messages',
  favorite: 'Favorite messages',
  new: 'New',
  read: 'Mark as read',
  unread: 'Mark as unread',
  reply: 'Reply',
  results: {
    send: 'Send results'
  },
  no: 'No messages',
  subject: 'Message subject',
  text: 'Message text',
  write_us: 'Write us a message',
  download_excel: 'Download excel document with messages',
  download: 'Download messages'
};
