import { POST_ITEM_START } from '../../constants';

import { PostItemRequestAction } from '../../reducers/postItemRequestReducer';

export default function postItemStart<ItemType>(): PostItemRequestAction<
  ItemType
> {
  return {
    type: POST_ITEM_START
  };
}
