import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS,
  DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_START,
  DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS,
  DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR
} from '../ProjectProfileConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadProjectProfileFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_START, id, options));

  const downloadProjectProfileFileAttachmentsAction = yield spawn(postItemResource, `task_profiles/${id}/file_attachments`, {
    success: DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS,
    error: DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadProjectProfileFileAttachmentsAction);
}

export default function* watchDownloadProjectProfileFileAttachments() {
  yield takeEvery(DOWNLOAD_PROJECT_PROFILE_FILE_ATTACHMENTS, downloadProjectProfileFileAttachments);
}
