import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_PAGE,
  REMOVE_PAGE_START,
  REMOVE_PAGE_SUCCESS,
  REMOVE_PAGE_ERROR
} from '../PageConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removePage({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_PAGE_START, id, options));

  return yield spawn(removeItemResource, 'pages', {
    success: REMOVE_PAGE_SUCCESS,
    error: REMOVE_PAGE_ERROR
  }, { id, options, cb });
}

export default function* watchRemovePage() {
  yield takeEvery(REMOVE_PAGE, removePage);
}
