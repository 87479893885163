import get from 'lodash/get';
import without from 'lodash/without';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeSelectedTemplateTextureFilesStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.selected_scene_id, selectedTemplate => ({
      ...selectedTemplate,
      texture_files: replaceById(get(selectedTemplate, 'texture_files', []), id, textureFile => ({
        ...textureFile,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeSelectedTemplateTextureFilesSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.selected_scene_id, selectedTemplate => ({
      ...selectedTemplate,
      texture_files: removeById(get(selectedTemplate, 'texture_files', []), id),
      texture_file_ids: without(get(selectedTemplate, 'texture_file_ids', []), id)
    }))
  }, options);
}

export function removeSelectedTemplateTextureFilesError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.selected_scene_id, selectedTemplate => ({
      ...selectedTemplate,
      texture_files: replaceById(get(selectedTemplate, 'texture_files', []), id, textureFile => ({
        ...textureFile,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
