export default {
  'select_category...': 'Select lifestyle category...',
  add_new_to_the_library: 'Add new lifestyle to the library',
  add_plural: 'Add lifestyles',
  add: 'Add a lifestyle',
  category: 'Lifestyle category',
  edit: 'Edit lifestyle',
  enter_title: 'Enter lifestyle title',
  image: 'Lifestyle image',
  library: 'Lifestyles Library',
  model: 'Lifestyle 3d model',
  new: 'New lifestyle',
  no: 'No lifestyles',
  own: 'Own Lifestyles',
  my: 'My Lifestyles',
  plural_lower_case: 'lifestyles',
  plural: 'Lifestyles',
  selected: 'Selected lifestyles',
  singular: 'Lifestyle',
  stock: 'Lifestyles Stock',
  title: 'Lifestyle title',
  filter: 'Filter lifestyles'
};
