import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  GENERATE_REPORT,
  GENERATE_REPORT_START,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR
} from '../ReportConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* generateReport({ id, options, cb }) {
  yield put(postItemStart(GENERATE_REPORT_START, id, options));

  const generateReportAction = yield spawn(postItemResource, `reports/${id}/generate`, {
    success: GENERATE_REPORT_SUCCESS,
    error: GENERATE_REPORT_ERROR
  }, { id, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(generateReportAction);
}

export default function* watchGenerateReport() {
  yield takeEvery(GENERATE_REPORT, generateReport);
}
