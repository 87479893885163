import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_TASKS_XLS,
  DOWNLOAD_TASKS_XLS_START,
  DOWNLOAD_TASKS_XLS_SUCCESS,
  DOWNLOAD_TASKS_XLS_ERROR
} from '../TaskConstants';

import { downloadItemsStart, downloadItemsResource } from '../../common/sagas';

function* downloadTasksXls(params) {
  yield put(downloadItemsStart(DOWNLOAD_TASKS_XLS_START, params));

  const downloadTasksXlsAction = yield spawn(downloadItemsResource, 'discussions.xlsx', {
    success: DOWNLOAD_TASKS_XLS_SUCCESS,
    error: DOWNLOAD_TASKS_XLS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTasksXlsAction);
}

export default function* watchDownloadTasks() {
  yield takeEvery(DOWNLOAD_TASKS_XLS, downloadTasksXls);
}
