import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_ROLE,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR
} from '../RoleConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateRole({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_ROLE_START, id, options));

  return yield spawn(updateItemResource, 'roles', {
    success: UPDATE_ROLE_SUCCESS,
    error: UPDATE_ROLE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLE, updateRole);
}
