import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_ERROR
} from '../MessageConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateMessage({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_MESSAGE_START, id, options));

  return yield spawn(updateItemResource, 'discussion_messages', {
    success: UPDATE_MESSAGE_SUCCESS,
    error: UPDATE_MESSAGE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateMessage() {
  yield takeEvery(UPDATE_MESSAGE, updateMessage);
}
