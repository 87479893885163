import { useMemo } from 'react';

import { LinkMenuItem, LinkMenuItems } from '../../types';

import { CurrentUser } from '../../../../types';

// Filtrate existing links array depending on current user permissions
// Check first layer links and nested links in first layer dropdown (second layer)
function useLinksWithPermission(
  links: LinkMenuItems,
  currentUser: CurrentUser
): [LinkMenuItems] {
  const linksWithPermission = useMemo(() => {
    // Fiter for first layer links and dropdowns (links in dropdown not checked)
    const filteredLinks = links.filter(item => {
      if (item.type === 'link') {
        // Check permission if link
        return currentUser.hasPermissions(`read_header_${item.namespace}_link`);
      } else if (item.type === 'dropdown') {
        // Check any of permissions if dropdown
        return item.namespace
          .split('|')
          .some(namespace =>
            currentUser.hasPermissions(`read_header_${namespace}_link`)
          );
      }

      // Skip if type not stated
      return false;
    });

    // Filter for links nested in dropdowns, second layer
    filteredLinks.forEach(item => {
      if (item.type === 'dropdown') {
        // Filter nested in dropdown links
        item.links = item.links.filter((nestedItem: LinkMenuItem) =>
          currentUser.hasPermissions(`read_header_${nestedItem.namespace}_link`)
        );
      }
    });

    return filteredLinks;
  }, [links, currentUser]);

  // Return filtered links with permissions
  return [linksWithPermission];
}

export default useLinksWithPermission;
