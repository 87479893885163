import React, { memo, useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';

import { HELP_PAGE_ID, HOST_BASENAME } from '../../../../config';

import { ContactModalButton } from '../../../../main/contacts/components/buttons/ContactModalButton';

import { PureLinkTo } from '../../../../helpers/ts/PureLinkTo';

import { LoadableWrapper } from '../../../../utils/LoadableWrapper';

const PageModalLink = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "page-modal-link" */ './../../../../main/ts/pages/helpers/PageModalLink/PageModalLink'
    ),
  withoutLoading: true
});

interface HelpDropdownProps {
  withGuideButton?: boolean;
}

function HelpDropdown({ withGuideButton }: HelpDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setIsOpen(value => !value), []);
  const handleCollapse = useCallback(() => setIsOpen(false), []);

  return (
    <OutsideClickHandler onOutsideClick={handleCollapse}>
      <div className={cl('dropdown', { show: isOpen })}>
        <PureLinkTo
          pureLink
          className="navbar-nav-link dropdown-toggle pr-1"
          aria-expanded={isOpen}
          onClick={handleToggleOpen}
          i18nText="words.help"
        />
        <div
          className={cl('dropdown-menu dropdown-menu-right', { show: isOpen })}
        >
          {withGuideButton ? (
            <PageModalLink
              pageId={HELP_PAGE_ID as string}
              href={
                HOST_BASENAME
                  ? `${HOST_BASENAME}/pages/${HELP_PAGE_ID}`
                  : `/pages/${HELP_PAGE_ID}`
              }
              pureLink
              withoutCancel
              className="dropdown-item"
              submitColor="light"
              i18nText="words.workflow_guide"
              i18nModalTitle="words.workflow_guide"
              i18nSubmitText="words.close"
            />
          ) : null}
          <ContactModalButton
            className="dropdown-item"
            i18nText="words.ask_question"
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default memo(HelpDropdown);
