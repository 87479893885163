import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_PROJECT_SELECTED_MATERIALS,
  SUBMIT_PROJECT_SELECTED_MATERIALS_START,
  SUBMIT_PROJECT_SELECTED_MATERIALS_SUCCESS,
  SUBMIT_PROJECT_SELECTED_MATERIALS_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitProjectSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_PROJECT_SELECTED_MATERIALS_START, id, options));

  const submitProjectSelectedMaterialsAction = yield spawn(postItemResource, `tasks/${id}/submit_selected_materials`, {
    success: SUBMIT_PROJECT_SELECTED_MATERIALS_SUCCESS,
    error: SUBMIT_PROJECT_SELECTED_MATERIALS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitProjectSelectedMaterialsAction);
}

export default function* watchSubmitProjectSelectedMaterials() {
  yield takeEvery(SUBMIT_PROJECT_SELECTED_MATERIALS, submitProjectSelectedMaterials);
}
