import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper/index';

const TeamsIndexRoute = LoadableWrapper({
  loader: () => import(/* webpackChunkName: "teams" */ '../../../pages/teams')
});

const TeamUsersRoute = LoadableWrapper({
  loader: () =>
    import(/* webpackChunkName: "teams" */ '../../../pages/teams/[id]/users')
});

const TeamProfileRoute = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "teams" */ '../../../pages/teams/[teamId]/profile'
    )
});

const TeamSettingsRoute = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "teams" */ '../../../pages/teams/[teamId]/settings'
    )
});

const TeamRequirementsRoute = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "teams" */ '../../../pages/teams/[teamId]/requirements'
    )
});

export default (
  <Fragment>
    <Route exact path="/teams" component={TeamsIndexRoute} />
    <Route exact path="/teams/:teamId/(users)?" component={TeamUsersRoute} />
    <Route
      exact
      path="/teams/:teamId/profile/:tab(info|description)?"
      component={TeamProfileRoute}
    />
    <Route path="/teams/:teamId/settings" component={TeamSettingsRoute} />
    <Route
      path="/teams/:teamId/requirements/:tab(details|library|products|lifestyles|files)?"
      component={TeamRequirementsRoute}
    />
  </Fragment>
);
