import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TODO_ITEM,
  UPDATE_TODO_ITEM_START,
  UPDATE_TODO_ITEM_SUCCESS,
  UPDATE_TODO_ITEM_ERROR
} from '../TodoItemConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTodoItem({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TODO_ITEM_START, id, options));

  return yield spawn(updateItemResource, 'todo_items', {
    success: UPDATE_TODO_ITEM_SUCCESS,
    error: UPDATE_TODO_ITEM_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTodoItem() {
  yield takeEvery(UPDATE_TODO_ITEM, updateTodoItem);
}
