import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TASK_RESULTS,
  DOWNLOAD_TASK_RESULTS_START,
  DOWNLOAD_TASK_RESULTS_SUCCESS,
  DOWNLOAD_TASK_RESULTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadTaskResults({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_TASK_RESULTS_START, id, options));

  const downloadTaskResultsAction = yield spawn(postItemResource, `discussions/${id}/results`, {
    success: DOWNLOAD_TASK_RESULTS_SUCCESS,
    error: DOWNLOAD_TASK_RESULTS_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTaskResultsAction);
}

export default function* watchDownloadTaskResults() {
  yield takeEvery(DOWNLOAD_TASK_RESULTS, downloadTaskResults);
}
