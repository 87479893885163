import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CANCEL_OVERWATCH_USERS,
  CANCEL_OVERWATCH_USERS_START,
  CANCEL_OVERWATCH_USERS_SUCCESS,
  CANCEL_OVERWATCH_USERS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* cancelCancelOverwatchUsers({ values, options, cb }) {
  yield put(postItemStart(CANCEL_OVERWATCH_USERS_START, options));

  return yield spawn(
    postItemResource,
    'users/cancel_overwatch_users',
    {
      success: CANCEL_OVERWATCH_USERS_SUCCESS,
      error: CANCEL_OVERWATCH_USERS_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchCancelOverwatchUsers() {
  yield takeEvery(CANCEL_OVERWATCH_USERS, cancelCancelOverwatchUsers);
}
