import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UNBLOCK_USERS,
  UNBLOCK_USERS_START,
  UNBLOCK_USERS_SUCCESS,
  UNBLOCK_USERS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* unblockUsers({ values, options, cb }) {
  yield put(postItemStart(UNBLOCK_USERS_START, options));

  return yield spawn(postItemResource, 'users/unblock_users', {
    success: UNBLOCK_USERS_SUCCESS,
    error: UNBLOCK_USERS_ERROR
  }, { values, options, cb });
}

export default function* watchUnblockUsers() {
  yield takeEvery(UNBLOCK_USERS, unblockUsers);
}
