export const FETCH_REFILLS = 'FETCH_REFILLS';
export const FETCH_REFILLS_START = 'FETCH_REFILLS_START';
export const FETCH_REFILLS_SUCCESS = 'FETCH_REFILLS_SUCCESS';
export const FETCH_REFILLS_ERROR = 'FETCH_REFILLS_ERROR';

export const FETCH_REFILL = 'FETCH_REFILL';
export const FETCH_REFILL_START = 'FETCH_REFILL_START';
export const FETCH_REFILL_SUCCESS = 'FETCH_REFILL_SUCCESS';
export const FETCH_REFILL_ERROR = 'FETCH_REFILL_ERROR';

export const CLEAR_REFILL_MODAL_VALUES = 'CLEAR_REFILL_MODAL_VALUES';

export const CLEAR_REFILLS_FILTERS_START = 'CLEAR_REFILLS_FILTERS_START';
export const CLEAR_REFILLS_FILTERS_SUCCESS = 'CLEAR_REFILLS_FILTERS_SUCCESS';

export const PAY_REFILL_FROM_ACCOUNT = 'PAY_REFILL_FROM_ACCOUNT';
export const PAY_REFILL_FROM_ACCOUNT_START = 'PAY_REFILL_FROM_ACCOUNT_START';
export const PAY_REFILL_FROM_ACCOUNT_SUCCESS = 'PAY_REFILL_FROM_ACCOUNT_SUCCESS';
export const PAY_REFILL_FROM_ACCOUNT_ERROR = 'PAY_REFILL_FROM_ACCOUNT_ERROR';

export const CHECK_REFILL_PAYMENT = 'CHECK_REFILL_PAYMENT';
export const CHECK_REFILL_PAYMENT_START = 'CHECK_REFILL_PAYMENT_START';
export const CHECK_REFILL_PAYMENT_SUCCESS = 'CHECK_REFILL_PAYMENT_SUCCESS';
export const CHECK_REFILL_PAYMENT_ERROR = 'CHECK_REFILL_PAYMENT_ERROR';

export const STRIPE_PAYMENT = 'STRIPE_PAYMENT';
export const STRIPE_PAYMENT_START = 'STRIPE_PAYMENT_START';
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_ERROR = 'STRIPE_PAYMENT_ERROR';

export const CANCEL_REFILL = 'CANCEL_REFILL';
export const CANCEL_REFILL_START = 'CANCEL_REFILL_START';
export const CANCEL_REFILL_SUCCESS = 'CANCEL_REFILL_SUCCESS';
export const CANCEL_REFILL_ERROR = 'CANCEL_REFILL_ERROR';

export const CHECK_ORDER_PAYMENT = 'CHECK_ORDER_PAYMENT';
export const CHECK_ORDER_PAYMENT_START = 'CHECK_ORDER_PAYMENT_START';
export const CHECK_ORDER_PAYMENT_SUCCESS = 'CHECK_ORDER_PAYMENT_SUCCESS';
export const CHECK_ORDER_PAYMENT_ERROR = 'CHECK_ORDER_PAYMENT_ERROR';
