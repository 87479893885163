import defaults from 'lodash/defaults';

function prepareParams(fetchAction, meta, defaultOptions = {}, cb = null) {
  const {
    page,
    pageSize,
    withoutPagination,
    withoutTotal,
    sortKey,
    sortDirection,
    persistFilters,
    serializer,
    params,
    namespace,
    loadMore,
    selectedFields,
    filters
  } = defaults(defaultOptions, {
    page: 1,
    pageSize: 50,
    withoutPagination: false,
    sortKey: 'created_at',
    sortDirection: 'desc',
    persistFilters: false,
    loadMore: false
  });

  return fetchAction({
    ...meta,
    page: parseInt(page, 10),
    pageSize,
    withoutPagination,
    withoutTotal,
    persistFilters,
    serializer,
    params,
    namespace,
    loadMore,
    filters: {
      ...(filters || {
        ...meta.filters,
        order: {
          [sortKey]: sortDirection
        }
      }),
      ...(selectedFields ? { fields: selectedFields } : {})
    }
  }, cb);
}

export default prepareParams;
