import { takeEvery } from 'redux-saga/effects';

import { DOWNLOADS_CHANNEL_UPDATED } from '../DownloadConstants';

// import { downloadFile } from '../../../utils/downloadFile';

function* downloadsChannelUpdated() {
  // if (window.downloadNanoId === data.device_nano_id && data.progress === 100) {
  //   yield downloadFile(data.file, data.name, { withLink: true });
  // }
}

export default function* watchDownloadsChannelUpdated() {
  yield takeEvery(DOWNLOADS_CHANNEL_UPDATED, downloadsChannelUpdated);
}
