import { createReducer } from 'redux-create-reducer';

import { initProductsFilters } from './common';
import {
  toggleSelectedProductStart,
  toggleSelectedProductSuccess,
  toggleSelectedProductError
} from './toggleSelectedProduct';
import {
  toggleBlockedProductStart,
  toggleBlockedProductSuccess,
  toggleBlockedProductError
} from './toggleBlockedProduct';

import {
  defaultState,
  downloadItemStart, downloadItemSuccess, downloadItemError,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  itemsChannelUpdated, locationChange, clearModalValues,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  AUTH_USER: initProductsFilters,

  DOWNLOAD_PRODUCT_START: downloadItemStart,
  DOWNLOAD_PRODUCT_SUCCESS: downloadItemSuccess,
  DOWNLOAD_PRODUCT_ERROR: downloadItemError,

  FETCH_PRODUCTS_START: fetchItemsStart,
  FETCH_PRODUCTS_SUCCESS: fetchItemsSuccess,
  FETCH_PRODUCTS_ERROR: fetchItemsError,

  FETCH_PRODUCT_START: fetchItemStart,
  FETCH_PRODUCT_SUCCESS: fetchItemSuccess,
  FETCH_PRODUCT_ERROR: fetchItemError,

  CREATE_PRODUCT_START: createItemStart,
  CREATE_PRODUCT_SUCCESS: createItemSuccess,
  CREATE_PRODUCT_ERROR: createItemError,

  UPDATE_PRODUCT_START: updateItemStart,
  UPDATE_PRODUCT_SUCCESS: updateItemSuccess,
  UPDATE_PRODUCT_ERROR: updateItemError,

  TOGGLE_SELECTED_PRODUCT_START: toggleSelectedProductStart,
  TOGGLE_SELECTED_PRODUCT_SUCCESS: toggleSelectedProductSuccess,
  TOGGLE_SELECTED_PRODUCT_ERROR: toggleSelectedProductError,

  TOGGLE_BLOCKED_PRODUCT_START: toggleBlockedProductStart,
  TOGGLE_BLOCKED_PRODUCT_SUCCESS: toggleBlockedProductSuccess,
  TOGGLE_BLOCKED_PRODUCT_ERROR: toggleBlockedProductError,

  REMOVE_PRODUCT_START: removeItemStart,
  REMOVE_PRODUCT_SUCCESS: removeItemSuccess,
  REMOVE_PRODUCT_ERROR: removeItemError,

  CLEAR_PRODUCT_FILTERS_START: clearItemsFiltersStart,
  CLEAR_PRODUCT_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_PRODUCT_MODAL_VALUES: clearModalValues,

  PRODUCTS_CHANNEL_UPDATED: itemsChannelUpdated
});
