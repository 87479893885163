import { useCallback, useState } from 'react';

export interface SimpleModalOptions {
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (isOpen: boolean) => void;
  onSubmit?: () => Promise<void>;
  afterSubmit?: () => void | Promise<void>;
}

function useSimpleModal({
  onOpen,
  onClose,
  onToggle,
  onSubmit,
  afterSubmit
}: SimpleModalOptions = {}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setIsOpen(true);
    onOpen && onOpen();
    onToggle && onToggle(true);
  }, [setIsOpen, onOpen, onToggle]);

  const hideModal = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
    onToggle && onToggle(false);
  }, [setIsOpen, onClose, onToggle]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit().then(() => {
        hideModal();
        afterSubmit && afterSubmit();
      });
    } else {
      hideModal();
      afterSubmit && afterSubmit();
    }
  }, [hideModal, onSubmit, afterSubmit]);

  return { isOpen, showModal, hideModal, handleSubmit };
}

export default useSimpleModal;
