import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  TOGGLE_TASK_NOTIFICATIONS,
  TOGGLE_TASK_NOTIFICATIONS_START,
  TOGGLE_TASK_NOTIFICATIONS_SUCCESS,
  TOGGLE_TASK_NOTIFICATIONS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* toggleTaskNotifications({ id, options, cb }) {
  yield put(postItemStart(TOGGLE_TASK_NOTIFICATIONS_START, id, options));

  const toggleTaskNotificationsAction = yield spawn(postItemResource, `discussions/${id}/toggle_notifications`, {
    success: TOGGLE_TASK_NOTIFICATIONS_SUCCESS,
    error: TOGGLE_TASK_NOTIFICATIONS_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(toggleTaskNotificationsAction);
}

export default function* watchToggleTaskNotifications() {
  yield takeEvery(TOGGLE_TASK_NOTIFICATIONS, toggleTaskNotifications);
}
