export const GET_REQUEST_ERROR = 'GET_REQUEST_ERROR';

export const scrollToTopStyle = {
  position: 'fixed',
  bottom: 95,
  right: 30,
  color: '#999',
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s'
};

export const newLayoutRoutes = ['/new-results'];
