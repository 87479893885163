import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_BLOCKED_TEMPLATE,
  TOGGLE_BLOCKED_TEMPLATE_START,
  TOGGLE_BLOCKED_TEMPLATE_SUCCESS,
  TOGGLE_BLOCKED_TEMPLATE_ERROR
} from '../TemplateConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleBlockedTemplateStart(id) {
  return {
    type: TOGGLE_BLOCKED_TEMPLATE_START,
    id
  };
}

export function toggleBlockedTemplateSuccess(id) {
  return {
    type: TOGGLE_BLOCKED_TEMPLATE_SUCCESS,
    id
  };
}

export function toggleBlockedTemplateError(id, error) {
  return {
    type: TOGGLE_BLOCKED_TEMPLATE_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleBlockedTemplateRequest(templateId) {
  return apiRequest.post(`scenes/${templateId}/toggle_blocked`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleBlockedTemplateResource({ templateId, cb }) {
  try {
    yield call(toggleBlockedTemplateRequest, templateId);
    yield put(toggleBlockedTemplateSuccess(templateId));
    cb && cb();
  } catch (error) {
    yield put(toggleBlockedTemplateError(templateId, error));
  }
}

function* toggleBlockedTemplate({ templateId, cb }) {
  yield put(toggleBlockedTemplateStart(templateId));

  return yield spawn(toggleBlockedTemplateResource, { templateId, cb });
}

export default function* watchToggleBlockedTemplate() {
  yield takeEvery(TOGGLE_BLOCKED_TEMPLATE, toggleBlockedTemplate);
}
