export function updateProjectsAmountsStart(state) {
  return {
    ...state,
    isLoading: true
  };
}

export function updateProjectsAmountsSuccess(state) {
  return {
    ...state,
    isLoading: false
  };
}

export function updateProjectsAmountsError(state, { errorMessage }) {
  return {
    ...state,
    isLoading: false,
    errorMessage
  };
}
