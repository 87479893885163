export default {
  plural: 'Tags',
  plural_downcase: 'tags',
  singular: 'Tag',
  new: 'New Tag',
  'add...': 'Add tags',
  change: 'Change tags',
  'select...': 'Select tags...',
  no: 'No tags'
};
