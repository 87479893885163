import { put, call } from 'redux-saga/effects';
import { reset, stopSubmit } from 'redux-form';
import pluralize from 'pluralize';
import omit from 'lodash/omit';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function updateItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function updateItemSuccess(type, id, item = {}, options = {}) {
  return {
    type,
    id,
    item,
    successMessage: 'words.record_updated',
    options
  };
}

export function updateItemError(type, id, error, options = {}) {
  return {
    type,
    errorMessage: parseError(error),
    id,
    error,
    options
  };
}

export function updateItemRequest(path, id, values, options = {}) {
  return apiRequest.patch(`${path}/${id}`, { [options.param || pluralize.singular(path)]: values }, omit(options, 'param'))
    .then(({ data: { data: item } }) => ({ item }));
}

export function* updateItemResource(path, { success, error: errorConst, param }, { id, values, options, cb }) {
  try {
    const { item } = yield call(updateItemRequest, path, id, values, { ...options, ...(param ? { param } : {}) });
    yield put(updateItemSuccess(success, id, item, options));
    yield options.form && put(reset(options.form));
    cb && cb(item);
  } catch (error) {
    yield put(updateItemError(errorConst, id, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}
