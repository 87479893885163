import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError
} from '../../common/reducers';

import { recalculateBalancesStart, recalculateBalancesSuccess, recalculateBalancesError } from './recalculateBalances';

export default createReducer(defaultState, {
  FETCH_BALANCES_START: fetchItemsStart,
  FETCH_BALANCES_SUCCESS: fetchItemsSuccess,
  FETCH_BALANCES_ERROR: fetchItemsError,

  CLEAR_BALANCE_FILTERS_START: clearItemsFiltersStart,
  CLEAR_BALANCE_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_BALANCE_START: fetchItemStart,
  FETCH_BALANCE_SUCCESS: fetchItemSuccess,
  FETCH_BALANCE_ERROR: fetchItemError,

  RECALCULATE_BALANCES_START: recalculateBalancesStart,
  RECALCULATE_BALANCES_SUCCESS: recalculateBalancesSuccess,
  RECALCULATE_BALANCES_ERROR: recalculateBalancesError
});
