import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

import { withCurrentUser } from '../../../common/HOCs';
import { CheckPermissions, LinkTo } from '../../../../helpers';
import { copyToClipboard } from '../../../../utils/copyToClipboard';

/**
 * @return {null}
 */
function MessageId({ currentUser, item, handleClick }) {
  if (!currentUser.hasPermissions('read_message_id')) {
    return null;
  }

  return (
    <Fragment>
      <div className="mr-2 mb-1">
        <LinkTo pureLink href="#" onClick={handleClick}>
          {item.id}
        </LinkTo>
      </div>
      {item.bill ? (
        <CheckPermissions action="admin_bills_show_page">
          <div className="mr-2 mb-1">
            <LinkTo href={`admin/bills/${get(item, 'bill.id')}`}>
              {upperFirst(camelCase(get(item, 'bill.type')))}
            </LinkTo>
          </div>
        </CheckPermissions>
      ) : null}
    </Fragment>
  );
}

MessageId.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    hasPermissions: PropTypes.func.isRequired
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    bill: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  handleClick: PropTypes.func.isRequired
};

export default compose(
  withCurrentUser(),
  withHandlers({
    handleClick: ({ item }) => () => copyToClipboard(item.id)
  })
)(MessageId);
