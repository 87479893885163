import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_MESSAGE_SELECTED_PRODUCTS,
  SUBMIT_MESSAGE_SELECTED_PRODUCTS_START,
  SUBMIT_MESSAGE_SELECTED_PRODUCTS_SUCCESS,
  SUBMIT_MESSAGE_SELECTED_PRODUCTS_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitMessageSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_MESSAGE_SELECTED_PRODUCTS_START, id, options));

  const submitMessageSelectedProductsAction = yield spawn(postItemResource, `discussion_messages/${id}/submit_selected_facilities`, {
    success: SUBMIT_MESSAGE_SELECTED_PRODUCTS_SUCCESS,
    error: SUBMIT_MESSAGE_SELECTED_PRODUCTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitMessageSelectedProductsAction);
}

export default function* watchSubmitMessageSelectedProducts() {
  yield takeEvery(SUBMIT_MESSAGE_SELECTED_PRODUCTS, submitMessageSelectedProducts);
}
