import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  uploadItemStart, uploadItemSuccess, uploadItemError,
  uploadItemsStart, uploadItemsSuccess, uploadItemsError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_TEXTURE_FILES_START: fetchItemsStart,
  FETCH_TEXTURE_FILES_SUCCESS: fetchItemsSuccess,
  FETCH_TEXTURE_FILES_ERROR: fetchItemsError,

  FETCH_TEXTURE_FILE_START: fetchItemStart,
  FETCH_TEXTURE_FILE_SUCCESS: fetchItemSuccess,
  FETCH_TEXTURE_FILE_ERROR: fetchItemError,

  UPDATE_TEXTURE_FILE_START: updateItemStart,
  UPDATE_TEXTURE_FILE_SUCCESS: updateItemSuccess,
  UPDATE_TEXTURE_FILE_ERROR: updateItemError,

  REMOVE_TEXTURE_FILE_START: removeItemStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeItemSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeItemError,

  UPLOAD_TEXTURE_FILE_START: uploadItemStart,
  UPLOAD_TEXTURE_FILE_SUCCESS: uploadItemSuccess,
  UPLOAD_TEXTURE_FILE_ERROR: uploadItemError,
  UPLOAD_TEXTURE_FILES_START: uploadItemsStart,
  UPLOAD_TEXTURE_FILES_SUCCESS: uploadItemsSuccess,
  UPLOAD_TEXTURE_FILES_ERROR: uploadItemsError
});
