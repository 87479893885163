import { put, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import { pushToAction } from '../../../utils/pushToAction';
import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function removeItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function removeItemSuccess(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function removeItemError(type, id, error, options = {}) {
  return {
    type,
    id,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function removeItemRequest(path, id, options) {
  return apiRequest.delete(`${path}/${id}`, options);
}

export function* removeItemResource(path, { success, error: errorConst }, { id, options, cb }) {
  try {
    yield call(removeItemRequest, path, id, options);
    yield put(removeItemSuccess(success, id, options));
    yield options.successPath && put(pushToAction(options.successPath));
    cb && cb();
  } catch (error) {
    yield put(removeItemError(errorConst, id, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}
