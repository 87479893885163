import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const UserLoginsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_user_logins" */ './pages/UserLoginsIndexPage/UserLoginsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/user_logins" component={UserLoginsIndexPage} />
  </Fragment>
);
