import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, pure } from 'recompose';
import get from 'lodash/get';

import { ConfirmModal } from '../../../common/components/modals/ConfirmModal';

import { CheckPermissions, LinkTo } from '../../../../helpers';
import { translate as t } from '../../../../utils';
import { renderAmount } from '../../../../utils/renderAmount';

function BillCancelButton({ addClass, buttonText, item, onClearModalValues, handleSubmit }) {
  if (item.status === 'canceled' || item.status === 'paid') {
    return null;
  }

  return (
    <CheckPermissions action="read_bill_cancel_button">
      <ConfirmModal
        addClass={addClass}
        modalTitle={t('models.bills.remove')}
        modalIcon="icon-cross2 mr-2"
        buttonIcon="icon-cross2"
        buttonText={buttonText}
        submitColor="danger"
        submitText={t('models.bills.remove')}
        bodyText={(
          <div className="row">
            <div className="col-sm-12 text-center">
              {t('messages.are_you_sure_you_want_to_remove')}&nbsp;
              <span className="badge badge-danger rounded-round px-2 font-size-sm">
                {renderAmount(item.amount)}
              </span>
              &nbsp;
              {t('models.bills.singular_downcase')}&nbsp;
              {t('words.in')}&nbsp;
              {get(item, 'discussion') ? (
                <LinkTo className="text-default font-weight-semibold" href={`tasks/${get(item, 'discussion.id')}`}>
                  {get(item, 'discussion.name')}&nbsp;
                  {t('models.tasks.singular_downcase')}
                </LinkTo>
              ) : null}
              {get(item, 'task') ? (
                <LinkTo className="text-default font-weight-semibold" href={`projects/${get(item, 'task.id')}`}>
                  {get(item, 'task.name')}&nbsp;
                  {t('models.projects.singular_downcase')}
                </LinkTo>
              ) : null}
            </div>
          </div>
        )}
        isLoading={item.loading}
        errorMessage={item.errorMessage}
        onSubmit={handleSubmit}
        onClose={onClearModalValues}
      />
    </CheckPermissions>
  );
}

BillCancelButton.propTypes = {
  addClass: PropTypes.string,
  buttonText: PropTypes.node,
  item: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onClearModalValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

BillCancelButton.defaultProps = {
  addClass: null,
  buttonText: null
};

export default compose(
  withHandlers({
    handleSubmit: ({ item, onCancel, afterCancel }) => hideModal => onCancel(item, () => {
      hideModal();
      afterCancel && afterCancel();
    })
  }),
  pure
)(BillCancelButton);
