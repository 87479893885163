import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TASK,
  CREATE_TASK_START,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR
} from '../TaskConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTask({ values, options, cb }) {
  yield put(createItemStart(CREATE_TASK_START, options));

  return yield spawn(createItemResource, 'discussions', {
    success: CREATE_TASK_SUCCESS,
    error: CREATE_TASK_ERROR
  }, { values, options, cb });
}

export default function* watchCreateTask() {
  yield takeEvery(CREATE_TASK, createTask);
}
