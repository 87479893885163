import { USE_CONSOLE } from '../../config';

function productionConsole(action = 'log', ...args) {
  if (USE_CONSOLE) {
    return console[action](...args);
  }

  return null;
}

export default productionConsole;
