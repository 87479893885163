import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_TASK_NOTIFICATION,
  REMOVE_TASK_NOTIFICATION_START,
  REMOVE_TASK_NOTIFICATION_SUCCESS,
  REMOVE_TASK_NOTIFICATION_ERROR
} from '../TaskNotificationConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeTaskNotification({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_TASK_NOTIFICATION_START, id, options));

  return yield spawn(removeItemResource, 'discussion_notifications', {
    success: REMOVE_TASK_NOTIFICATION_SUCCESS,
    error: REMOVE_TASK_NOTIFICATION_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveTaskNotification() {
  yield takeEvery(REMOVE_TASK_NOTIFICATION, removeTaskNotification);
}
