import users from './users';

export default {
  action: 'Action',
  action_name: 'Action name',
  add_comment: 'Add comment...',
  additional_discount: 'Additional discount',
  additional_email1: 'Additional email #1',
  additional_email2: 'Additional email #2',
  additional_information: 'Enter additional information here...',
  address: 'Address',
  agreed_hourly_rate: 'Agreed hourly rate',
  amount: 'Amount',
  android: 'Android',
  aws: 'AWS',
  balance_amount: 'Old Account',
  bill: 'Bill',
  birth_date: 'Birth date',
  blocked: 'Blocked',
  body_en: 'Body (En)',
  body: 'Body',
  bonus_account: 'Bonus Account',
  category_id: 'Сategory',
  cell_name: 'Cell Name',
  cells: 'Cells',
  channel_name: 'Channel name',
  chief_id: 'Master user',
  city: 'City',
  client_payment: 'Client payment',
  client_prepayment: 'Client prepayment',
  client_status: 'Client status',
  last_client_status_updated_at: 'Client status updated',
  co: 'Application UAC CGI',
  co_pages: 'Application UAC CGI',
  cold_source: 'Cold source',
  comment: 'Comments',
  commission: 'Commission',
  company_size: 'Company size',
  content_length: 'Content length',
  content_type: 'Content type',
  controller_name: 'Controller name',
  cost: 'Cost',
  country_id: 'Country',
  county_state: 'State',
  coworking: 'Coworking',
  coworking_pages: 'Coworking',
  created_at: 'Created',
  created: 'Created',
  creation_date: 'Creation date',
  credit_account: 'Credit Account',
  reducer_commission: 'Reducer Commission',
  task_commission: 'Project Commission',
  credit_commission: 'Credit commission',
  hosting_commission: 'Hosting commission',
  currency: 'Preferable currency',
  current_cost: 'Current cost',
  current_password: 'Current password',
  danger: 'Danger',
  date_format: 'Date format',
  date: 'Date',
  debit_account: 'Debit Account',
  debt_limit: 'Debt limit',
  debt: 'Debt',
  deferred_payment_days: 'Deferred payment (days)',
  default_discussion_price: 'Default task price',
  default_task_price: 'Default project price',
  delivered: 'Delivered',
  description: 'Description',
  direction: 'Direction',
  discount: 'Discount',
  discussion_account: 'Task account',
  due_date: 'Due date',
  email_confirmation: 'Confirm email',
  email: 'Email',
  end_date: 'End Date',
  end_time: 'End Time',
  endDatePlaceholderText: ' To',
  enter_estimate_date: 'Enter estimate date',
  enter_estimate_cost: 'Enter estimate cost',
  enter_implementation_date: 'Enter estimate date',
  estimate_cost: 'Estimate cost',
  estimate_date: 'Estimate date',
  etag: 'Etag',
  expected_time: 'Expected Work Time',
  experience_in_months: 'Experience (in months)',
  implemented_at: 'Implemented',
  is_mentor: 'Is Mentor',
  facebook: 'Facebook',
  factor: 'Factor',
  file_version_tags: 'File version tags',
  file: 'File',
  finance_role_id: 'Finance role',
  finance_role: 'Finance Role',
  finance_tags: 'Finance tags',
  first_name_en: 'First name (En)',
  first_name_uk: 'First name (Uk)',
  first_name: 'First name',
  first_payment_date: 'First Payment Date',
  flagged: 'Flagged',
  followers: 'Followers',
  frontend_request_id: 'Frontend request ID',
  frontend: 'Frontend',
  full_name: 'Full Name',
  full_path: 'Full path',
  google_plus: 'Google+',
  group_ids: 'Groups',
  groups: 'Groups',
  headers: 'Headers',
  hold_rate: 'Hold rate',
  host: 'Host',
  id: 'ID',
  image: 'Image',
  implementation_date: 'Estimate date',
  itn_pages: 'ITN',
  only_in_projects_where_i_am_a_member: 'Only in projects where I am a member',
  only_in_tasks_where_i_am_a_member: 'Only in tasks where I am a member',
  instagram: 'Instagram',
  inTrash: 'In trash',
  itn: 'ITN',
  ios: 'iOS',
  keys: 'Keys',
  key: 'Key',
  last_payment_date: 'Last Payment Date',
  client_sigma_payments: 'Σ Payments',
  sigma_task_account: 'Σ TA',
  rate_education_point: 'K edp',
  wildly_important_goals_count: 'WIGs',
  reasons_of_fail: 'Reasons of fail',
  registration: 'Registration',
  turn_around_time: '∑ TAT',
  paid_turn_around_time: '∑ Paid TAT',
  client_rate_median: 'K med',
  client_rate_average: 'K avr',
  worker_rate_median: 'K med',
  worker_rate_average: 'K avr',
  user_rate_median: 'K med',
  user_rate_average: 'K avr',
  last_modified: 'Last modified',
  last_name_en: 'Last name (En)',
  last_name_uk: 'Last name (Uk)',
  last_name: 'Last name',
  last_sign_in_at: 'Last sign in',
  last_unpaid_bill: 'Last unpaid bill',
  leaders: 'Leaders',
  linked_in: 'LinkedIn',
  locale: 'Language',
  manual_client_status: 'Manual client status',
  massive_average_revenue: 'MAR',
  marketing_source: 'Marketing source',
  members: 'Members',
  mentor: 'Mentor',
  method_name: 'Method name',
  md5: 'MD5',
  multiplier: 'Multiplier',
  name_en: 'Name (En)',
  name_uk: 'Name (Uk)',
  name: 'Name',
  nature_of_lead: 'Nature of Lead',
  nda: 'NDA',
  nda_pages: 'NDA',
  negative_reviews: 'Negative reviews',
  new_email: 'New email',
  new_password: 'New password',
  overwatch: 'Overwatch',
  pa_pages: 'Partnership Agreement',
  params: 'Params',
  parent_category: 'Parent category',
  parsed_body: 'Parsed body',
  passport_first_page: 'Passport (1-st page)',
  passport_pages: 'Passport',
  passport_second_page: 'Passport (2-nd page)',
  passport_number: 'Passport series and number',
  password_confirmation: 'Password Confirmation',
  password: 'Password',
  pay_time: 'Pay Time',
  payment_type: 'Payment type',
  payment: 'Payment',
  payoneer_id_pe: 'Payoneer ID PE',
  payoneer_id_type: 'Payoneer ID type',
  payoneer_id: 'Payoneer ID',
  pe_pages: 'Private entrepreneur',
  permission_type: 'Type',
  permissions_count: 'Permissions',
  permission_name: 'Permission name',
  personal_channel: 'Personal channel',
  personal_email: 'Personal email',
  phone: 'Phone',
  pinterest: 'Pinterest',
  plain_body: 'Plain body',
  position: 'Position',
  positive_reviews: 'Positive reviews',
  prepaid_balance_amount: 'Prepaid Account',
  prepayment: 'Prepayment',
  preview: 'Preview',
  price: 'Price',
  proficiency_level: 'Proficiency level',
  progress: 'Progress',
  project_account: 'Project account',
  project_manager: 'Project manager',
  project_managers: 'Project managers',
  public_url: 'Public url',
  qualification: 'Qualification',
  rating_corrected_manually: 'Use custom rating',
  rating: 'Rating',
  ratio: 'Ratio',
  real_decline_reasons: 'Real decline reasons',
  red: 'Visible for Worker',
  reducer: 'Reducer',
  referer: 'Referrer',
  registration_date: 'Registration date',
  response_body: 'Response body',
  response_status: 'Response status',
  result_sum: 'Result sum',
  request_type: 'Request type',
  role_ids: 'Roles',
  roles: 'Roles',
  s3_objects_count: 'S3 objects count',
  school_agreement_pages: 'School agreement',
  search_by_email: 'Search by email',
  search_by_message_body: 'Search by message body',
  search_by_name: 'Search by name',
  search_by_product_name: 'Search by product name',
  search_by_material_name: 'Search by material name',
  search_by_project_id: 'Search by project ID',
  search_by_project_name: 'Search by project name',
  search_by_sku: 'Search by SKU',
  search_by_group_name: 'Search by group name',
  search_by_team_name: 'Search by team name',
  search_by_task_name: 'Search by task name',
  search_by_template_name: 'Search by template name',
  search_by_lifestyle_name: 'Search by lifestyle name',
  search_by_user_name: 'Search by user name',
  search: 'Search...',
  selected: 'Favorite',
  seller_id: 'Sales Manager',
  seller: 'Sales Manager',
  set_date: 'Created',
  site: 'Website',
  size: 'Size',
  skype: 'Skype',
  social_networks: 'Other',
  source_files_count: 'Source files',
  source_id: 'Source',
  specialization: 'Specialization',
  start_date: 'Start Date',
  start_time: 'Start Time',
  startDatePlaceholderText: 'From',
  status: 'Status',
  storage_class: 'Storage class',
  studio: 'Studio',
  sub_category: 'Subcategory',
  subject_en: 'Subject (En)',
  subject: 'Subject',
  sum_for_allocation: 'Sum for allocation',
  surname_en: 'Surname (En)',
  surname: 'Surname',
  tag_ids: 'Tags',
  tags: 'Tags',
  task_account: 'Project account',
  tasks: 'Tasks',
  time_format: 'Time format',
  time_zone: 'Time zone',
  time: 'Time',
  transaction_description: 'Transaction description',
  transaction_id: 'Transaction ID',
  transaction_tag_ids: 'Transaction tags',
  tumblr: 'Tumblr',
  twitter: 'Twitter',
  type: 'Type',
  updated_at: 'Updated',
  updated: 'Updated',
  upsaler_id: 'Client Manager',
  upsaler: 'Client Manager',
  url: 'Url',
  user_balance: 'Balance',
  user_id: 'User ID',
  user_name: 'Name',
  user_rating: 'Ratio',
  user: 'User',
  user_agent: 'User agent',
  users_count: 'Users',
  uuid: 'UUID',
  version: 'Version',
  visible_for_client: 'Visible for Client',
  visible_for_clients: 'Visible for Clients',
  with_attachments: 'With attachments',
  without_blocked: 'Without blocking',
  without_delivered: 'Without delivery',
  without_user: 'Without user',
  your_email: 'Your Email',
  your_phone: 'Your phone',
  zip_code: 'Zip code',
  code: 'Code',
  value: 'Value',
  ip: 'IP',

  filter_parts: 'Filter parts',
  categories_count: 'Categories',
  order: 'Order',
  pdf_export: 'PDF export',

  expected_payment_date: 'Expected payment date',
  expected_payment_amount: 'Expected payment amount',

  image_id: 'Image',
  source: 'Source',
  sku: 'SKU',
  state: 'State',

  width: 'Width',
  height: 'Height',
  dpi: 'DPI',
  file_extension: 'File Extension',

  accuracy: 'Accuracy',
  communication: 'Communication',
  timing: 'Timing',
  quality: 'Quality',
  task_error: 'Task error',
  project_error: 'Project error',
  profile_error: 'Profile error',
  brief_error: 'Brief error',
  estimate_error: 'Estimate error',
  style_error: 'Style error',
  burning_tasks: 'Burning tasks',
  respond_rate: 'Respond_rate',
  estimate_rate: 'Estimate rate',
  quality_assurance: 'Quality assurance',
  texture_lighting_camera: 'Texture lighting camera',
  composition_details_storytelling: 'Composition details storytelling',

  integration: 'Integration',
  standard_technical_assignment: 'STA',
  no_go: 'No Go',
  business_email: 'Business Email',
  operation_hours_start: 'Operation hours (from)',
  operation_hours_end: 'Operation hours (to)',

  cdn: 'CDN',
  system_account: 'System account',

  air_table_email: 'Airtable email',
  air_table_password: 'Airtable password',
  air_table_api_key: 'Airtable API key',
  air_table_base_id: 'Airtable Base ID',
  air_table_table_name: 'Airtable Table Name',

  users
};
