import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_PROJECT_SELECTED_PRODUCTS,
  SUBMIT_PROJECT_SELECTED_PRODUCTS_START,
  SUBMIT_PROJECT_SELECTED_PRODUCTS_SUCCESS,
  SUBMIT_PROJECT_SELECTED_PRODUCTS_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitProjectSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_PROJECT_SELECTED_PRODUCTS_START, id, options));

  const submitProjectSelectedProductsAction = yield spawn(postItemResource, `tasks/${id}/submit_selected_facilities`, {
    success: SUBMIT_PROJECT_SELECTED_PRODUCTS_SUCCESS,
    error: SUBMIT_PROJECT_SELECTED_PRODUCTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitProjectSelectedProductsAction);
}

export default function* watchSubmitProjectSelectedProducts() {
  yield takeEvery(SUBMIT_PROJECT_SELECTED_PRODUCTS, submitProjectSelectedProducts);
}
