import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_PAGES,
  FETCH_PAGES_START,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_ERROR
} from '../PageConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchPages(params) {
  yield put(fetchItemsStart(FETCH_PAGES_START, params));

  const fetchPagesAction = yield spawn(fetchItemsResource, 'pages', {
    success: FETCH_PAGES_SUCCESS,
    error: FETCH_PAGES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchPagesAction);
}

export default function* watchFetchPages() {
  yield takeEvery(FETCH_PAGES, fetchPages);
}
