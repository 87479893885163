import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_SELECTED_TEMPLATE,
  FETCH_SELECTED_TEMPLATE_START,
  FETCH_SELECTED_TEMPLATE_SUCCESS,
  FETCH_SELECTED_TEMPLATE_ERROR
} from '../SelectedTemplateConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchSelectedTemplate({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_SELECTED_TEMPLATE_START, id, options));

  const fetchSelectedTemplateAction = yield spawn(fetchItemResource, 'selected_scenes', {
    success: FETCH_SELECTED_TEMPLATE_SUCCESS,
    error: FETCH_SELECTED_TEMPLATE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchSelectedTemplateAction);
}

export default function* watchFetchSelectedTemplate() {
  yield takeEvery(FETCH_SELECTED_TEMPLATE, fetchSelectedTemplate);
}
