import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Favicon from 'react-favicon';
import get from 'lodash/get';
import pure from 'recompose/pure';

import { CozyHeader } from '../../components/CozyHeader';
import { CozyStorage } from '../../../utils/ts/CozyStorage';
import { Footer } from '../../components/ts/Footer';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';
import { parseReferer } from '../../../utils/parseReferer';
import { COZY_FAVICON_URL } from '../../../config';

const RegisterCozyPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth_cozy" */ '../../../auth/pages/RegisterCozyPage/RegisterCozyPage'
    )
});

const ErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "errors" */ '../../components/ErrorPage/ErrorPage'
    )
});

function CozyAuthAppContainer({ location }) {
  const referer = parseReferer(get(location, 'search'));

  const pathName = get(location, 'pathname');

  useEffect(() => {
    if (referer === 'cozymakeover.com' || /auth\/cozy/.test(pathName)) {
      CozyStorage.setIsUserCozy(true);
    }
  });

  return (
    <Fragment>
      <Favicon url={COZY_FAVICON_URL} />
      <CozyHeader auth />
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content content-boxed">
            <Switch>
              <Route exact path="/auth/cozy" component={RegisterCozyPage} />
              <Route
                key="error"
                render={props => <ErrorPage statusCode={404} {...props} />}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

CozyAuthAppContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default pure(CozyAuthAppContainer);
