export const FETCH_TEXTURE_FILES = 'FETCH_TEXTURE_FILES';
export const FETCH_TEXTURE_FILES_START = 'FETCH_TEXTURE_FILES_START';
export const FETCH_TEXTURE_FILES_SUCCESS = 'FETCH_TEXTURE_FILES_SUCCESS';
export const FETCH_TEXTURE_FILES_ERROR = 'FETCH_TEXTURE_FILES_ERROR';

export const FETCH_TEXTURE_FILE = 'FETCH_TEXTURE_FILE';
export const FETCH_TEXTURE_FILE_START = 'FETCH_TEXTURE_FILE_START';
export const FETCH_TEXTURE_FILE_SUCCESS = 'FETCH_TEXTURE_FILE_SUCCESS';
export const FETCH_TEXTURE_FILE_ERROR = 'FETCH_TEXTURE_FILE_ERROR';

export const UPDATE_TEXTURE_FILE = 'UPDATE_TEXTURE_FILE';
export const UPDATE_TEXTURE_FILE_START = 'UPDATE_TEXTURE_FILE_START';
export const UPDATE_TEXTURE_FILE_SUCCESS = 'UPDATE_TEXTURE_FILE_SUCCESS';
export const UPDATE_TEXTURE_FILE_ERROR = 'UPDATE_TEXTURE_FILE_ERROR';

export const DOWNLOAD_TEXTURE_FILE = 'DOWNLOAD_TEXTURE_FILE';
export const DOWNLOAD_TEXTURE_FILE_START = 'DOWNLOAD_TEXTURE_FILE_START';
export const DOWNLOAD_TEXTURE_FILE_SUCCESS = 'DOWNLOAD_TEXTURE_FILE_SUCCESS';
export const DOWNLOAD_TEXTURE_FILE_ERROR = 'DOWNLOAD_TEXTURE_FILE_ERROR';

export const UPLOAD_TEXTURE_FILES = 'UPLOAD_TEXTURE_FILES';
export const UPLOAD_TEXTURE_FILES_START = 'UPLOAD_TEXTURE_FILES_START';
export const UPLOAD_TEXTURE_FILE_START = 'UPLOAD_TEXTURE_FILE_START';
export const UPLOAD_TEXTURE_FILES_SUCCESS = 'UPLOAD_TEXTURE_FILES_SUCCESS';
export const UPLOAD_TEXTURE_FILE_SUCCESS = 'UPLOAD_TEXTURE_FILE_SUCCESS';
export const UPLOAD_TEXTURE_FILE_ERROR = 'UPLOAD_TEXTURE_FILE_ERROR';
export const UPLOAD_TEXTURE_FILES_ERROR = 'UPLOAD_TEXTURE_FILES_ERROR';

export const REMOVE_TEXTURE_FILE = 'REMOVE_TEXTURE_FILE';
export const REMOVE_TEXTURE_FILE_START = 'REMOVE_TEXTURE_FILE_START';
export const REMOVE_TEXTURE_FILE_SUCCESS = 'REMOVE_TEXTURE_FILE_SUCCESS';
export const REMOVE_TEXTURE_FILE_ERROR = 'REMOVE_TEXTURE_FILE_ERROR';

export const SOCKET_SUBSCRIBE_TO_TEXTURE_FILES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_TEXTURE_FILES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_TEXTURE_FILES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_TEXTURE_FILES_CHANNEL';
