import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_IMAGE,
  DOWNLOAD_IMAGE_START,
  DOWNLOAD_IMAGE_SUCCESS,
  DOWNLOAD_IMAGE_ERROR
} from '../ImageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadImage({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_IMAGE_START, id, options));

  const downloadImageAction = yield spawn(postItemResource, `images/${id}/download`, {
    success: DOWNLOAD_IMAGE_SUCCESS,
    error: DOWNLOAD_IMAGE_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadImageAction);
}

export default function* watchDownloadImage() {
  yield takeEvery(DOWNLOAD_IMAGE, downloadImage);
}
