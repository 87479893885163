export default {
  'loading...': 'Searching...',
  'processing...': 'Processing...',
  about: 'About',
  accept_invite: 'Accept Invite',
  accept_results: 'Accept results',
  accept: 'Accept',
  access: 'Access',
  account_balance: 'Account balance',
  account_settings: 'Profile',
  accounts: 'Accounts',
  activate: 'Activate',
  add_extra_views: 'Add extra views',
  add_features: 'Add features',
  add_feedback: 'Add your feedback',
  add_floor_plan: 'Add floor plan',
  add_island: 'Add Island',
  add_more: 'Add more',
  add_tags: 'Select tags...',
  add_to_description: 'Add to description',
  add_to_my_library: 'Add to My Library',
  add_your_comment: 'Add your comment (optional)...',
  add: 'Add',
  address: 'Address',
  admin: 'Admin',
  all: 'All',
  all_messages: 'All messages',
  allocate_funds: 'Allocate funds',
  allocate: 'Allocate',
  amount: 'Amount',
  and: 'and',
  any: 'Any',
  apply_to_all: 'Apply to all',
  apply_to_each_sender: 'Apply to each sender',
  apply: 'Apply',
  approve_results: 'Approve results',
  approve: 'Approve',
  archive: 'Archive',
  ask_question: 'Ask Question',
  assign_type: 'Assign type',
  attach: 'Attach',
  attachments: 'Attachments',
  auto_allocate: 'Auto allocate',
  avatar_update: 'Avatar update',
  average_hourly_rate: 'Average Hourly Rate',
  average: 'Average',
  back: 'Back',
  bad_debt: 'Bad debt',
  basic: 'Basic',
  bathroom: 'Bathroom',
  bedroom: 'Bedroom',
  block: 'Block',
  blocked: 'Blocked',
  bonus_account: 'Bonus account',
  browse_library: 'Browse Library',
  burning: 'Burning',
  cabinets_style: 'Cabinets style',
  call: 'Call',
  cancel: 'Cancel',
  cancel_overwatch: 'Cancel overwatch',
  change_estimate_cost: 'Change estimate cost',
  change: 'Change',
  change_client_manager: 'Change client manager',
  change_sales_manager: 'Change sales manager',
  change_cm: 'Change CM',
  change_sm: 'Change SM',
  chat: 'Chat',
  check_in: 'Check in',
  checked: 'Checked',
  checklist: 'Checklist',
  choose_price_plan: 'Choose price plan',
  choose_plan: 'Choose plan',
  classic: 'Classic',
  clear_selection: 'Clear selection',
  clear: 'Clear',
  clearFilters: 'Clear Filters',
  client_files: "Client's files",
  client: 'Client',
  clients: 'Clients',
  client_profile: 'Client Profile',
  client_managers: 'Client managers',
  close: 'Close',
  closed_shelves: 'Closed shelves',
  closing_tasks: 'Closing tasks',
  colorful: 'Colorful',
  create_multiple_revenue_share_items: 'Create multiple revenue share items',
  cm: 'CM',
  color_of_cabinets: 'Color of cabinets',
  commission: 'Commission',
  contact_info: 'Contact Info',
  contact: 'Contact',
  content: 'Сontent',
  continue: 'Continue',
  cost: 'Cost',
  create: 'Create',
  created: 'Сreated',
  critical_requirements: 'Critical requirements',
  custom: 'Custom',
  dark: 'Dark',
  deselect: 'Deselect',
  design_template: 'Design template',
  design_reference: 'Design reference',
  describe_room: 'Describe room',
  details: 'Details',
  dpi: 'dpi',
  reducer_commission: 'Reducer commission',
  task_commission: 'Project commission',
  credit_account: 'Credit account',
  credit_commission: 'Credit commission',
  hosting_commission: 'Hosting commission',
  currency: 'Currency',
  dashboard: 'Dashboard',
  date: 'Date',
  days: 'days',
  debit_account: 'Debit account',
  debt: 'Debt',
  default_task_and_discussion_prices: 'Default project and task prices',
  delete: 'Delete',
  depth: 'Depth',
  description_optional: 'Description (optional)',
  description: 'Description',
  detailed: 'Detailed',
  discount: 'discount',
  documents: 'Documents',
  download_all: 'Download all',
  download_report: 'Download report',
  download_selected: 'Download selected',
  download_source_files: 'Download source files',
  download_all_worker_documents: 'Download all worker documents',
  download_template: 'Download template',
  download: 'Download',
  drop_archive_here: 'Drop archive here',
  drop_image_here: 'Drop image here',
  drop_file_here: 'Drop file here',
  duplicate: 'Duplicate',
  edit: 'Edit',
  email_address: 'Email address',
  email: 'Email',
  en: 'English',
  enter_details_here: 'Enter details here',
  enter_your_room_category: 'Enter your room category',
  enter_your_room_type: 'Enter your room type',
  errors: 'Errors',
  EUR: 'EUR',
  event: 'Event',
  export_pdf: 'Export to PDF',
  extended: 'Extended',
  factor: 'Factor',
  favorite: 'Favorite',
  fields: 'Fields',
  filters: 'Filters',
  finance: 'Finance',
  flagged: 'Flagged',
  forbidden_error: 'You do not have permission for this action',
  format12h: '12h',
  format24h: '24h',
  format: 'Format',
  forward_message: 'Forward message',
  forward_to_another_project_task: 'Forward to another project/task',
  from_sender: 'From',
  from_size: 'from',
  from: 'from',
  fully_paid: 'Fully Paid',
  gb: 'GB',
  general: 'General',
  generate: 'Generate',
  go: 'Go',
  groups: 'Groups',
  height: 'Height',
  help_with_text_formatting: 'Help with text formatting',
  help: 'Help',
  hidden: 'Hidden',
  hide_filters: 'Hide filters',
  homepage: 'Go to the home page',
  hold_rate_commission: 'Hold rate commission',
  revenue_share_items_sum: 'Revenue shares sum',
  hours: 'hours',
  Hours: 'Hours',
  ID: 'ID',
  in: 'in',
  include_attachments_if_any: 'Include attachments (if any)',
  incoming_payments: 'Incoming payments',
  incoming_transfer: 'Incoming transfer',
  info: 'Info',
  internal_transfer: 'Internal transfer',
  invisible_for_client: 'Invisible for client',
  invisible_for_worker: 'Invisible for worker',
  import_allocate: 'Import allocate',
  kb: 'KB',
  kitchen: 'Kitchen',
  kitchen_details: 'Kitchen Details',
  kitchen_shape: 'Kitchen Shape',
  l_shaped: 'L-Shaped',
  last_payment: 'Last payment',
  less_than_1_cent: 'Less than 1 cent',
  letter: 'Letter',
  library: 'Library',
  light: 'Light',
  living: 'Living',
  leave_your_message: 'Leave your message',
  logs: 'Logs',
  load_previous: 'Load previous',
  locale: 'Locale',
  login_page: 'Go to the login page',
  login: 'Login',
  logOut: 'Log Out',
  logout: 'Logout',
  make_a_creator: 'Assign as creator',
  make_incoming_payment: 'Make incoming payment',
  make_incoming_transfer: 'Make incoming transfer',
  make_internal_transfer: 'Make internal transfer',
  make_outgoing_payment: 'Make outgoing payment',
  make_outgoing_transfer: 'Make outgoing transfer',
  make_transfer_from_selected_users: 'Make transfer from selected users',
  make_transfer_to_selected_users: 'Make transfer to selected users',
  make_transfer: 'Make transfer',
  makeover: 'Makeover',
  manager: 'Manager',
  mark_all_as_read: 'Mark all as read',
  monthly_average_revenue: 'Monthly Average Revenue',
  mb: 'MB',
  messages_in_projects: 'Messages in projects',
  messages_in_tasks: 'Messages in tasks',
  method: 'Payment method',
  minutes: 'minutes',
  Minutes: 'Minutes',
  modern: 'Modern',
  more: 'More',
  my_invitations: 'My invitations',
  name: 'Name',
  new: 'New',
  newest: 'Newest',
  next: 'Next',
  new_design: 'New design',
  no_go: 'No Go',
  non_disclosure_agreement: 'Privacy Policy',
  non_valid_tasks: 'Non valid tasks',
  no_file_selected: 'No file selected',
  no_options: 'No search results',
  no_records: 'No Records',
  no_results_yet: 'No results yet',
  no: 'No',
  notifications: 'Notifications',
  of: 'of',
  ok: 'OK',
  open_shelves: 'Open shelves',
  open_and_closed_shelves: 'Open and closed shelves',
  open: 'Open',
  or: 'Or',
  order: 'Sort order',
  order_id: 'Order ID',
  other: 'Other',
  outgoing_payments: 'Outgoing payments',
  outgoing_transfer: 'Outgoing transfer',
  overplus: 'Overpayment',
  overwatch: 'Overwatch',
  own: 'Own',
  paid: 'Paid',
  partnership_agreement: 'Partnership Agreement',
  password: 'Password',
  password_update: 'Password update',
  past_results: 'Past results',
  pay: 'Pay',
  payment_amount: 'Payment Amount',
  payment_terms: 'Payment Terms',
  payment: 'Payment',
  payoneer: 'Payoneer',
  payouts: 'Payouts',
  pending_more_info: 'Pending more info',
  pending_payments: 'Pending Payments',
  pending_payouts: 'Pending Payouts',
  per_1_mln_reqs: 'Per 1 mln reqs',
  per_gb: 'Per GB',
  phone: 'Phone',
  pinned: 'Pinned',
  please_evaluate_the_project: 'Please, evaluate the project',
  pm: 'PM',
  pm_section_test_project_info: 'PM section(Test project info)',
  policy: 'Policy',
  popular: 'Popular',
  portion: 'Underpayment',
  preview_text_formatting: 'Preview text formatting',
  price_plan: 'Price plan',
  privacy_policy: 'Privacy Policy',
  privacy: 'Privacy',
  processing: 'Processing...',
  products_selected: 'product(s) selected',
  profile: 'Profile',
  provide_access: 'Provide access',
  project_brief: 'Project brief',
  px: 'px',
  rate_results: 'Rate the results',
  rate: 'Rate',
  rating: 'Rating',
  ratio: 'Ratio',
  receive_email_notifications: 'Receive email notifications',
  recent_activity: 'Recent activity',
  recent_results: 'Recent results',
  recently_selected: 'Recently selected',
  record_updated: 'Record updated',
  records_updated: 'Records updated',
  record: 'Record',
  records: 'Records',
  refresh: 'Refresh',
  refund: 'Refund',
  register: 'Register',
  remember: 'Remember',
  remind_tomorrow: 'Remind tomorrow',
  remove_all: 'Remove all',
  remove_file: 'Remove file',
  remove_from_library: 'Remove from library',
  remove_from_results: 'Remove from results',
  remove_from_team: 'Remove from team',
  remove_teams: 'Remove teams',
  remove: 'Remove',
  report: 'Report',
  request_access: 'Request access',
  resolution: 'Resolution',
  rest: 'Remainder',
  restore: 'Restore',
  results: 'Results',
  revenue_share: 'Revenue share',
  revision_results: 'Send results on revision',
  revision: 'Revision',
  requirements: 'Requirements',
  reqs: 'Reqs',
  role: 'Role',
  room_name: 'Room name',
  room_sizes: 'Room sizes',
  room_size: 'Room size',
  room_photos_and_floor_plan: 'Room Photos & Floor Plan',
  room_type: 'Room type',
  ru: 'Русский',
  uk: 'Українська',
  RUB: 'RUB',
  s3: 'S3',
  sales_managers: 'Sales managers',
  save_changes: 'Save changes',
  save: 'Save',
  scenes_selected: 'template(s) selected',
  search: 'Search',
  search_results: 'Search results',
  search_user_by_name: 'Search user by name',
  seconds: 'seconds',
  second_design_concept: 'Second Design Concept',
  see_more: 'See more...',
  see_results: 'See results',
  select_account: 'Select account...',
  select_all_available_funds_from_each_sender: 'Select all available funds from each sender',
  select_cabinets_style: 'Select cabinets style',
  select_client: 'Select client...',
  select_client_manager: 'Select client manager...',
  select_color: 'Select color',
  select_color_of_cabinets: 'Select color of cabinets',
  select_due_date: 'Select due date',
  select_kitchen_shape: 'Select kitchen shape',
  select_style: 'Select style',
  select_payment_type: 'Select payment type...',
  select_project_manager: 'Select project manager...',
  select_sales_manager: 'Select sales manager...',
  select_shelves_type: 'Select shelves type',
  select_state: 'Select state...',
  select_tags: 'Select tags...',
  select_team_member: 'Select team member',
  select: 'Select',
  selected_users: 'Selected users',
  selected: 'Selected',
  send_invoice: 'Send Invoice',
  send_new_message_to: 'Send new message to',
  send_on_revision: 'Send on revision',
  send_to_all_tasks: 'Send to all tasks',
  send_via_email: 'Send via email',
  send: 'Send',
  send_to_client: 'Send to client',
  sending: 'Sending...',
  service_agreement: 'Service agreement',
  set_as_team_requirements: 'Set as team requirements',
  settings: 'Settings',
  shelves_type: 'Shelves type',
  show_all: 'Show all',
  show_full_description: 'Show full description',
  show_less: 'Show less',
  show_more: 'Show more',
  show: 'Show',
  social_networks: 'Social Networks',
  start: 'Start',
  statistic: 'Statistic',
  status: 'Status',
  stock_3d: '3D stock',
  straight: 'Straight',
  studio: 'Studio',
  submit_incoming_payment: 'Submit incoming payment',
  submit_incoming_transfer: 'Submit incoming transfer',
  submit_internal_transfer: 'Submit internal transfer',
  submit_outgoing_payment: 'Submit outgoing payment',
  submit_outgoing_transfer: 'Submit outgoing transfer',
  submit_results: 'Submit results',
  submit_source_files: 'Upload source files',
  submit_transfer: 'Submit transfer',
  submit: 'Submit',
  sum: 'Sum',
  system: 'System',
  team_and_position: 'Team & Position',
  team: 'Team',
  terms_of_service: 'Terms of Service',
  terms: 'Terms',
  their: 'Their',
  time_report: 'Time report',
  time: 'Time',
  timeline: 'Timeline',
  to_size: 'to',
  to_recipient: 'to',
  to: 'to',
  total_cost: 'Total Cost',
  total_debt: 'Total debt',
  total_working_hours: 'Total Working Hours',
  total: 'Total',
  track_time: 'Track time',
  transfers: 'Transfers',
  type: 'Type',
  u_shaped: 'U-Shaped',
  UAH: 'UAH',
  ultra_modern: 'Ultra modern',
  unblock: 'Unblock',
  unchecked: 'Unchecked',
  units_cm: 'cm',
  units_ft: 'ft',
  units_ft_in: 'ft, in',
  units_in: 'in',
  units_m: 'm',
  units_m_cm: 'm, cm',
  units: 'Units',
  update_cost: 'Update cost',
  update_estimate_date: 'Update estimate date',
  update_multiple_users_payoneer_id_pe: 'Update multiple users payoneer ID PE',
  update_multiple_users_payoneer_id: 'Update multiple users payoneer ID',
  update: 'Update',
  upload_3d_model: 'Upload 3D model',
  upload_file: 'Upload file',
  upload: 'Upload',
  utility: 'Utility',
  USD: 'USD',
  valid_tasks: 'Valid tasks',
  view_profile: 'View profile',
  view_result: 'View result',
  view_tasks: 'View tasks',
  view_accounts: 'View accounts',
  view: 'View',
  visibility_for_client: 'Visibility for client',
  visibility_for_worker: 'Visibility for worker',
  visibility: 'Visibility',
  visible_for_client: 'Visible for client',
  visible_for_worker: 'Visible for worker',
  visible: 'Visible',
  what_improved: 'What would you like to be improved?',
  width: 'Width',
  with_source_files: 'With source files',
  without_source_files: 'Without source files',
  wood: 'Wood',
  partners: 'Partners',
  welcome: 'Welcome',
  workers: 'Workers',
  worker: 'Worker',
  workflow_guide: 'Workflow Guide',
  yes: 'Yes',
  your_furniture: 'Your furniture',
  you: 'You',
  author: 'Author',
  pay_to_start: 'Pay to start',
  design: 'design',
  default_renders: 'Default renders',
  promocode: 'Promocode',
  enter_promocode_here: 'Enter promocode here',
  your_comments: 'Your comments',
  upload_examples: 'Upload examples',
  my_library: 'My library',
  airtable: 'Airtable'
};
