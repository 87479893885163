import { SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_DOWNLOADS_CHANNEL } from '../DownloadConstants';

export default function unsubscribeFromDownloadsChannel(action, userId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_DOWNLOADS_CHANNEL,
    channel: 'Archi::Crm::DropdownDownloadsChannel',
    command: 'unsubscribe',
    action,
    params
  });
}
