import { updateState } from '../../../utils';

export function downloadItemStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemDownloadErrorMessage: null
  }, options);
}

export function downloadItemSuccess(state, { options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemDownloadErrorMessage: null
  }, options);
}

export function downloadItemError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemDownloadErrorMessage: errorMessage
  }, options);
}
