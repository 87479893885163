import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CHANGE_USERS_UPSELLER,
  CHANGE_USERS_UPSELLER_START,
  CHANGE_USERS_UPSELLER_SUCCESS,
  CHANGE_USERS_UPSELLER_ERROR
} from '../UserConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* changeUsersUpseller({ values, options, cb }) {
  yield put(postItemsStart(CHANGE_USERS_UPSELLER_START, options));

  return yield spawn(
    postItemsResource,
    'users/update_upsaler',
    {
      success: CHANGE_USERS_UPSELLER_SUCCESS,
      error: CHANGE_USERS_UPSELLER_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchChangeUsersUpseller() {
  yield takeEvery(CHANGE_USERS_UPSELLER, changeUsersUpseller);
}
