import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_MESSAGE,
  TOGGLE_SELECTED_MESSAGE_START,
  TOGGLE_SELECTED_MESSAGE_SUCCESS,
  TOGGLE_SELECTED_MESSAGE_ERROR
} from '../MessageConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedMessageStart(id) {
  return {
    type: TOGGLE_SELECTED_MESSAGE_START,
    id
  };
}

export function toggleSelectedMessageSuccess(id) {
  return {
    type: TOGGLE_SELECTED_MESSAGE_SUCCESS,
    id
  };
}

export function toggleSelectedMessageError(id, error) {
  return {
    type: TOGGLE_SELECTED_MESSAGE_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedMessageRequest(messageId) {
  return apiRequest.post(`discussion_messages/${messageId}/toggle_selected`)
    .then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedMessageResource({ messageId, cb }) {
  try {
    yield call(toggleSelectedMessageRequest, messageId);
    yield put(toggleSelectedMessageSuccess(messageId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedMessageError(messageId, error));
  }
}

function* toggleSelectedMessage({ messageId, cb }) {
  yield put(toggleSelectedMessageStart(messageId));

  return yield spawn(toggleSelectedMessageResource, { messageId, cb });
}

export default function* watchToggleSelectedMessage() {
  yield takeEvery(TOGGLE_SELECTED_MESSAGE, toggleSelectedMessage);
}
