import React, { Fragment } from 'react';
import get from 'lodash/get';

import { BillType } from '../../../../../../../common/hooks/bills/types';

import { CurrencyAmount } from '../../../../../../../../helpers/ts/CurrencyAmount';
import { PureLinkTo } from '../../../../../../../../helpers/ts/PureLinkTo';
import { Translate } from '../../../../../../../../helpers/ts/Translate';

interface ItemBillsModalTableProps {
  bill: BillType | null;
}

function ItemBillsModalTable({ bill }: ItemBillsModalTableProps) {
  return (
    <table className="table table-xs">
      <thead>
        <tr>
          <th className="col-sm-12">
            <Translate
              id={`models.${
                get<BillType, 'task_id'>(bill, 'task_id') ? 'projects' : 'tasks'
              }.name`}
            />
          </th>
          <th>
            <Translate id="words.cost" />
          </th>
          <th>
            <Translate id="words.paid" />
          </th>
          <th>
            <Translate id="words.debt" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p className="mb-0 font-weight-semibold">
              {get<BillType, 'task'>(bill, 'task') ? (
                <PureLinkTo
                  className="text-default font-weight-semibold"
                  href={`projects/${get(bill, 'task.id')}`}
                  i18nText={get(bill, 'task.name')}
                />
              ) : (
                <Fragment>
                  {get(bill, 'discussion.task') ? (
                    <PureLinkTo
                      className="text-default font-weight-semibold"
                      href={`projects/${get(bill, 'discussion.task.id')}`}
                      i18nText={get(bill, 'discussion.task.name')}
                    />
                  ) : null}
                  {get<BillType, 'discussion'>(bill, 'discussion') ? (
                    <PureLinkTo
                      className="d-block text-muted font-size-sm"
                      href={`tasks/${get(bill, 'discussion.id')}`}
                      i18nText={get(bill, 'discussion.name')}
                    />
                  ) : null}
                </Fragment>
              )}
            </p>
            <p className="text-muted mb-0">
              #
              {get<BillType, 'discussion_id'>(bill, 'discussion_id') ||
                get<BillType, 'task_id'>(bill, 'task_id')}
            </p>
          </td>
          <td>
            <CurrencyAmount
              usdAmount={get<BillType, 'amount', number>(bill, 'amount', 0)}
            />
          </td>
          <td>
            <CurrencyAmount usdAmount={0} />
          </td>
          <td className="font-weight-semibold font-size-lg text-primary">
            <CurrencyAmount
              usdAmount={get<BillType, 'amount', number>(bill, 'amount', 0)}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ItemBillsModalTable;
