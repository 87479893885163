import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CANCEL_ALLOCATION,
  CANCEL_ALLOCATION_START,
  CANCEL_ALLOCATION_SUCCESS,
  CANCEL_ALLOCATION_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* cancelAllocation({ id, options, cb }) {
  yield put(postItemStart(CANCEL_ALLOCATION_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/cancel_allocate`, {
    success: CANCEL_ALLOCATION_SUCCESS,
    error: CANCEL_ALLOCATION_ERROR
  }, { id, options, cb });
}

export default function* watchCancelAllocation() {
  yield takeEvery(CANCEL_ALLOCATION, cancelAllocation);
}
