import login from './login';
import register from './register';
import resetPassword from './reset_password';
import updatePassword from './update_password';

export default {
  login,
  register,
  reset_password: resetPassword,
  update_password: updatePassword
};
