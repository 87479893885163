export default {
  plural: 'Textures',
  plural_lower_case: 'textures',
  singular: 'Texture',
  singular_lower_case: 'texture',
  add: 'Add a texture',
  no: 'No textures',
  selected: 'Selected textures',
  add_plural: 'Add textures',
  upload: 'Upload new texture',
  upload2: 'Upload textures'
};
