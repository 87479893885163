import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_SELECTED_MATERIAL,
  CREATE_SELECTED_MATERIAL_START,
  CREATE_SELECTED_MATERIAL_SUCCESS,
  CREATE_SELECTED_MATERIAL_ERROR
} from '../SelectedMaterialConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createSelectedMaterial({ values, options, cb }) {
  yield put(createItemStart(CREATE_SELECTED_MATERIAL_START, options));

  return yield spawn(createItemResource, 'selected_materials', {
    success: CREATE_SELECTED_MATERIAL_SUCCESS,
    error: CREATE_SELECTED_MATERIAL_ERROR
  }, { values, options, cb });
}

export default function* watchCreateSelectedMaterial() {
  yield takeEvery(CREATE_SELECTED_MATERIAL, createSelectedMaterial);
}
