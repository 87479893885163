import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const ResultsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "results" */'./pages/ResultsIndexPage/ResultsIndexPage') });

export default (
  <Fragment>
    <Route exact path="/results" component={ResultsIndexPage} />
  </Fragment>
);
