export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const DOWNLOAD_PRODUCT = 'DOWNLOAD_PRODUCT';
export const DOWNLOAD_PRODUCT_START = 'DOWNLOAD_PRODUCT_START';
export const DOWNLOAD_PRODUCT_SUCCESS = 'DOWNLOAD_PRODUCT_SUCCESS';
export const DOWNLOAD_PRODUCT_ERROR = 'DOWNLOAD_PRODUCT_ERROR';

export const TOGGLE_SELECTED_PRODUCT = 'TOGGLE_SELECTED_PRODUCT';
export const TOGGLE_SELECTED_PRODUCT_START = 'TOGGLE_SELECTED_PRODUCT_START';
export const TOGGLE_SELECTED_PRODUCT_SUCCESS = 'TOGGLE_SELECTED_PRODUCT_SUCCESS';
export const TOGGLE_SELECTED_PRODUCT_ERROR = 'TOGGLE_SELECTED_PRODUCT_ERROR';

export const TOGGLE_BLOCKED_PRODUCT = 'TOGGLE_BLOCKED_PRODUCT';
export const TOGGLE_BLOCKED_PRODUCT_START = 'TOGGLE_BLOCKED_PRODUCT_START';
export const TOGGLE_BLOCKED_PRODUCT_SUCCESS = 'TOGGLE_BLOCKED_PRODUCT_SUCCESS';
export const TOGGLE_BLOCKED_PRODUCT_ERROR = 'TOGGLE_BLOCKED_PRODUCT_ERROR';

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_PRODUCT_START = 'REMOVE_PRODUCT_START';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_ERROR = 'REMOVE_PRODUCT_ERROR';

export const CLEAR_PRODUCT_FILTERS_START = 'CLEAR_PRODUCT_FILTERS_START';
export const CLEAR_PRODUCT_FILTERS_SUCCESS = 'CLEAR_PRODUCT_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_PRODUCTS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_PRODUCTS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_PRODUCTS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_PRODUCTS_CHANNEL';

export const CLEAR_PRODUCT_MODAL_VALUES = 'CLEAR_PRODUCT_MODAL_VALUES';

export const CREATE_PRODUCT_FORM = 'CREATE_PRODUCT_FORM';
export const UPDATE_PRODUCT_FORM = 'UPDATE_PRODUCT_FORM';
export const SELECT_PRODUCT_CLIENTS_FORM = 'SELECT_PRODUCT_CLIENTS_FORM';
export const SELECTED_PRODUCTS_FORM = 'SELECTED_PRODUCTS_FORM';
