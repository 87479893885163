import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function cancelBillStart(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: true,
      errorMessage: null
    }))
  }, options);
}

export function cancelBillSuccess(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      errorMessage: null
    }))
  }, options);
}

export function cancelBillError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      errorMessage
    }))
  }, options);
}
