export default {
  plural: 'Permissions',
  plural_downcase: 'permissions',
  new: 'New permission',
  no: 'No permissions',
  scopes: {
    index: 'Index',
    create: 'Create',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
    download: 'Download'
  }
};
