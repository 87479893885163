import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const EvaluationSetShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_evaluation_sets" */ './pages/EvaluationSetShowPage/EvaluationSetShowPage'
    )
});
const EvaluationSetsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_evaluation_sets" */ './pages/EvaluationSetsIndexPage/EvaluationSetsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/evaluation_sets/:evaluationSetId"
      component={EvaluationSetShowPage}
    />
    <Route
      exact
      path="/admin/evaluation_sets"
      component={EvaluationSetsIndexPage}
    />
  </Fragment>
);
