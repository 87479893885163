import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TASK_NOTIFICATION,
  UPDATE_TASK_NOTIFICATION_START,
  UPDATE_TASK_NOTIFICATION_SUCCESS,
  UPDATE_TASK_NOTIFICATION_ERROR
} from '../TaskNotificationConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTaskNotification({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TASK_NOTIFICATION_START, id, options));

  return yield spawn(updateItemResource, 'discussion_notifications', {
    success: UPDATE_TASK_NOTIFICATION_SUCCESS,
    error: UPDATE_TASK_NOTIFICATION_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTaskNotification() {
  yield takeEvery(UPDATE_TASK_NOTIFICATION, updateTaskNotification);
}
