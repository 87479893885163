import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_SOURCE_FILE,
  DOWNLOAD_SOURCE_FILE_START,
  DOWNLOAD_SOURCE_FILE_SUCCESS,
  DOWNLOAD_SOURCE_FILE_ERROR
} from '../SourceFileConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadSourceFile({ id, values, options, cb }) {
  // if (get(options, 'file')) {
  //   yield downloadFile(get(options, 'file'), get(options, 'file').substring(get(options, 'file').lastIndexOf('/') + 1));
  //   return;
  // }

  yield put(postItemStart(DOWNLOAD_SOURCE_FILE_START, id, options));

  const downloadSourceFileAction = yield spawn(postItemResource, `source_files/${id}/download`, {
    success: DOWNLOAD_SOURCE_FILE_SUCCESS,
    error: DOWNLOAD_SOURCE_FILE_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadSourceFileAction);
}

export default function* watchDownloadSourceFile() {
  yield takeEvery(DOWNLOAD_SOURCE_FILE, downloadSourceFile);
}
