import { updateState } from '../../../utils';

export function createTasksStart(state, { options = {} }) {
  return updateState(
    state,
    {
      itemsLoading: true,
      itemsErrorMessage: null
    },
    options
  );
}

export function createTasksSuccess(state, { options = {} }) {
  return updateState(
    state,
    {
      itemsLoading: false,
      itemsErrorMessage: null
    },
    options
  );
}

export function createTasksError(state, { errorMessage, options = {} }) {
  return updateState(
    state,
    {
      itemsLoading: false,
      itemsErrorMessage: errorMessage
    },
    options
  );
}
