import flow from 'lodash/flow';
import replace from 'lodash/replace';

export default flow([
  msg => replace(msg, /&lt;/g, '<'),
  msg => replace(msg, /&nbsp;/g, ' '),
  msg => replace(msg, /(?=<!--)([\s\S]*?)-->/g, ''),
  msg => replace(msg, /(?=-------- Original message --------)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=Von meinem iPhone gesendet)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=Sent from my iPhone)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=Sent from my Huawei Mobile)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=On )([\s\S]*?)(?= CGICRM)([\s\S]*?)> wrote:([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=Am )([\s\S]*?)(?= um )([\s\S]*?)(?= schrieb)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=Sent:)([\s\S]*?)(?=To:)([\s\S]*?)(?=Subject:)([\s\S]*?)$/g, ''),
  msg => replace(msg, /(?=From:)([\s\S]*?)(?=To:)([\s\S]*?)(?=Subject:)([\s\S]*?)$/g, '')
]);
