import Promise from 'bluebird';
import nanoid from 'nanoid';

import { authLoading, authUser, authError } from '../AuthActions';

import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';
import { sendAuthErrorToSentry } from '../../utils/ts/sendAuthErrorToSentry';

// registerWorker

export default function registerWorker(auth) {
  return dispatch => {
    dispatch(authLoading());

    const frRequestId = nanoid();

    return apiRequest
      .authPost(
        'auth/sign_up_worker',
        { auth, serializer: 'sufficient' },
        { headers: { 'fr-request-id': frRequestId } }
      )
      .then(({ data: { data: currentUser, token } }) => {
        return Promise.resolve(dispatch(authUser({ currentUser, token })));
      })
      .catch(error => {
        sendAuthErrorToSentry({
          error,
          auth,
          tag: 'register-worker',
          frRequestId
        });
        return mapErrorsToForm(dispatch, error, authError(error));
      });
  };
}
