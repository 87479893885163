import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_SELECTED_PRODUCT,
  CREATE_SELECTED_PRODUCT_START,
  CREATE_SELECTED_PRODUCT_SUCCESS,
  CREATE_SELECTED_PRODUCT_ERROR
} from '../SelectedProductConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createSelectedProduct({ values, options, cb }) {
  yield put(createItemStart(CREATE_SELECTED_PRODUCT_START, options));

  return yield spawn(createItemResource, 'selected_facilities', {
    success: CREATE_SELECTED_PRODUCT_SUCCESS,
    error: CREATE_SELECTED_PRODUCT_ERROR
  }, { values, options, cb });
}

export default function* watchCreateSelectedProduct() {
  yield takeEvery(CREATE_SELECTED_PRODUCT, createSelectedProduct);
}
