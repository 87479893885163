import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_TASK_RESULTS,
  SUBMIT_TASK_RESULTS_START,
  SUBMIT_TASK_RESULTS_SUCCESS,
  SUBMIT_TASK_RESULTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitTaskResults({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_TASK_RESULTS_START, id, options));

  const submitTaskResultsAction = yield spawn(postItemResource, `discussions/${id}/submit_results`, {
    success: SUBMIT_TASK_RESULTS_SUCCESS,
    error: SUBMIT_TASK_RESULTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitTaskResultsAction);
}

export default function* watchSubmitTaskSelectedProducts() {
  yield takeEvery(SUBMIT_TASK_RESULTS, submitTaskResults);
}
