export const FETCH_DOWNLOADS = 'FETCH_DOWNLOADS';
export const FETCH_DOWNLOADS_START = 'FETCH_DOWNLOADS_START';
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS';
export const FETCH_DOWNLOADS_ERROR = 'FETCH_DOWNLOADS_ERROR';

export const FETCH_DOWNLOAD = 'FETCH_DOWNLOAD';
export const FETCH_DOWNLOAD_START = 'FETCH_DOWNLOAD_START';
export const FETCH_DOWNLOAD_SUCCESS = 'FETCH_DOWNLOAD_SUCCESS';
export const FETCH_DOWNLOAD_ERROR = 'FETCH_DOWNLOAD_ERROR';

export const UPDATE_DOWNLOAD = 'UPDATE_DOWNLOAD';
export const UPDATE_DOWNLOAD_START = 'UPDATE_DOWNLOAD_START';
export const UPDATE_DOWNLOAD_SUCCESS = 'UPDATE_DOWNLOAD_SUCCESS';
export const UPDATE_DOWNLOAD_ERROR = 'UPDATE_DOWNLOAD_ERROR';

export const REMOVE_DOWNLOAD = 'REMOVE_DOWNLOAD';
export const REMOVE_DOWNLOAD_START = 'REMOVE_DOWNLOAD_START';
export const REMOVE_DOWNLOAD_SUCCESS = 'REMOVE_DOWNLOAD_SUCCESS';
export const REMOVE_DOWNLOAD_ERROR = 'REMOVE_DOWNLOAD_ERROR';

export const CHECK_DOWNLOAD = 'CHECK_DOWNLOAD';
export const CHECK_DOWNLOAD_START = 'CHECK_DOWNLOAD_START';
export const CHECK_DOWNLOAD_SUCCESS = 'CHECK_DOWNLOAD_SUCCESS';
export const CHECK_DOWNLOAD_ERROR = 'CHECK_DOWNLOAD_ERROR';

export const DOWNLOADS_CHANNEL_CREATED = 'DOWNLOADS_CHANNEL_CREATED';
export const DOWNLOADS_CHANNEL_UPDATED = 'DOWNLOADS_CHANNEL_UPDATED';

export const DROPDOWN_DOWNLOADS_CHANNEL_CREATED = 'DROPDOWN_DOWNLOADS_CHANNEL_CREATED';

export const CLEAR_DOWNLOADS_FILTERS_START = 'CLEAR_DOWNLOADS_FILTERS_START';
export const CLEAR_DOWNLOADS_FILTERS_SUCCESS = 'CLEAR_DOWNLOADS_FILTERS_SUCCESS';

export const CLEAR_DOWNLOAD_MODAL_VALUES = 'CLEAR_DOWNLOAD_MODAL_VALUES';

export const SET_DOWNLOADS_BOX_OPENED = 'SET_DOWNLOADS_BOX_OPENED';

export const SOCKET_SUBSCRIBE_TO_DOWNLOADS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_DOWNLOADS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_DOWNLOADS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_DOWNLOADS_CHANNEL';
export const SOCKET_SUBSCRIBE_TO_DROPDOWN_DOWNLOADS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_DROPDOWN_DOWNLOADS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_DOWNLOADS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_DOWNLOADS_CHANNEL';
