import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_CLIENT_FILE,
  REMOVE_CLIENT_FILE_START,
  REMOVE_CLIENT_FILE_SUCCESS,
  REMOVE_CLIENT_FILE_ERROR
} from '../ClientFileConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeClientFile({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_CLIENT_FILE_START, id, options));

  return yield spawn(removeItemResource, 'client_files', {
    success: REMOVE_CLIENT_FILE_SUCCESS,
    error: REMOVE_CLIENT_FILE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveClientFile() {
  yield takeEvery(REMOVE_CLIENT_FILE, removeClientFile);
}
