import uniqBy from 'lodash/uniqBy';

import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function createItemStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemCreateErrorMessage: null
  }, options);
}

export function createItemSuccess(state, { item, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemCreateErrorMessage: null,
    item,
    items: uniqBy([item, ...currentStateValue(state, 'items', options, [])], i => i.id)
  }, options);
}

export function createItemError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemCreateErrorMessage: errorMessage
  }, options);
}
