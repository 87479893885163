export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_ERROR = 'FETCH_PERMISSIONS_ERROR';

export const FETCH_PERMISSION = 'FETCH_PERMISSION';
export const FETCH_PERMISSION_START = 'FETCH_PERMISSION_START';
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS';
export const FETCH_PERMISSION_ERROR = 'FETCH_PERMISSION_ERROR';

export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const CREATE_PERMISSION_START = 'CREATE_PERMISSION_START';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_ERROR = 'CREATE_PERMISSION_ERROR';

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const UPDATE_PERMISSION_START = 'UPDATE_PERMISSION_START';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_ERROR = 'UPDATE_PERMISSION_ERROR';

export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const REMOVE_PERMISSION_START = 'REMOVE_PERMISSION_START';
export const REMOVE_PERMISSION_SUCCESS = 'REMOVE_PERMISSION_SUCCESS';
export const REMOVE_PERMISSION_ERROR = 'REMOVE_PERMISSION_ERROR';

export const CLEAR_PERMISSION_FILTERS_START = 'CLEAR_PERMISSION_FILTERS_START';
export const CLEAR_PERMISSION_FILTERS_SUCCESS = 'CLEAR_PERMISSION_FILTERS_SUCCESS';
