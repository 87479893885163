export const FETCH_SELECTED_PRODUCTS = 'FETCH_SELECTED_PRODUCTS';
export const FETCH_SELECTED_PRODUCTS_START = 'FETCH_SELECTED_PRODUCTS_START';
export const FETCH_SELECTED_PRODUCTS_SUCCESS = 'FETCH_SELECTED_PRODUCTS_SUCCESS';
export const FETCH_SELECTED_PRODUCTS_ERROR = 'FETCH_SELECTED_PRODUCTS_ERROR';

export const FETCH_SELECTED_PRODUCT = 'FETCH_SELECTED_PRODUCT';
export const FETCH_SELECTED_PRODUCT_START = 'FETCH_SELECTED_PRODUCT_START';
export const FETCH_SELECTED_PRODUCT_SUCCESS = 'FETCH_SELECTED_PRODUCT_SUCCESS';
export const FETCH_SELECTED_PRODUCT_ERROR = 'FETCH_SELECTED_PRODUCT_ERROR';

export const CREATE_SELECTED_PRODUCT = 'CREATE_SELECTED_PRODUCT';
export const CREATE_SELECTED_PRODUCT_START = 'CREATE_SELECTED_PRODUCT_START';
export const CREATE_SELECTED_PRODUCT_SUCCESS = 'CREATE_SELECTED_PRODUCT_SUCCESS';
export const CREATE_SELECTED_PRODUCT_ERROR = 'CREATE_SELECTED_PRODUCT_ERROR';

export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export const UPDATE_SELECTED_PRODUCT_START = 'UPDATE_SELECTED_PRODUCT_START';
export const UPDATE_SELECTED_PRODUCT_SUCCESS = 'UPDATE_SELECTED_PRODUCT_SUCCESS';
export const UPDATE_SELECTED_PRODUCT_ERROR = 'UPDATE_SELECTED_PRODUCT_ERROR';

export const DOWNLOAD_SELECTED_PRODUCT = 'DOWNLOAD_SELECTED_PRODUCT';
export const DOWNLOAD_SELECTED_PRODUCT_START = 'DOWNLOAD_SELECTED_PRODUCT_START';
export const DOWNLOAD_SELECTED_PRODUCT_SUCCESS = 'DOWNLOAD_SELECTED_PRODUCT_SUCCESS';
export const DOWNLOAD_SELECTED_PRODUCT_ERROR = 'DOWNLOAD_SELECTED_PRODUCT_ERROR';

export const REMOVE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT';
export const REMOVE_SELECTED_PRODUCT_START = 'REMOVE_SELECTED_PRODUCT_START';
export const REMOVE_SELECTED_PRODUCT_SUCCESS = 'REMOVE_SELECTED_PRODUCT_SUCCESS';
export const REMOVE_SELECTED_PRODUCT_ERROR = 'REMOVE_SELECTED_PRODUCT_ERROR';

export const CLEAR_SELECTED_PRODUCT_MODAL_VALUES = 'CLEAR_SELECTED_PRODUCT_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_SELECTED_PRODUCTS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_SELECTED_PRODUCTS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_SELECTED_PRODUCTS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_SELECTED_PRODUCTS_CHANNEL';

export const ATTACH_SELECTED_PRODUCTS_FORM = 'ATTACH_SELECTED_PRODUCTS_FORM';

export const ATTACH_SELECTED_PRODUCTS = 'ATTACH_SELECTED_PRODUCTS';
export const ATTACH_SELECTED_PRODUCTS_START = 'ATTACH_SELECTED_PRODUCTS_START';
export const ATTACH_SELECTED_PRODUCTS_SUCCESS = 'ATTACH_SELECTED_PRODUCTS_SUCCESS';
export const ATTACH_SELECTED_PRODUCTS_ERROR = 'ATTACH_SELECTED_PRODUCTS_ERROR';
