import { FETCH_MESSAGES } from '../MessageConstants';

// fetchMessages

export default function fetchMessages(params, cb) {
  return dispatch => dispatch({
    type: FETCH_MESSAGES,
    ...params,
    cb
  });
}
