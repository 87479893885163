import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_LINKED_ACCOUNT,
  UPDATE_LINKED_ACCOUNT_START,
  UPDATE_LINKED_ACCOUNT_SUCCESS,
  UPDATE_LINKED_ACCOUNT_ERROR
} from '../LinkedAccountConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateLinkedAccount({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_LINKED_ACCOUNT_START, id, options));

  return yield spawn(updateItemResource, 'linked_users', {
    success: UPDATE_LINKED_ACCOUNT_SUCCESS,
    error: UPDATE_LINKED_ACCOUNT_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateLinkedAccount() {
  yield takeEvery(UPDATE_LINKED_ACCOUNT, updateLinkedAccount);
}
