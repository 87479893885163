import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  clearModalValues, locationChange
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_REFILLS_START: fetchItemsStart,
  FETCH_REFILLS_SUCCESS: fetchItemsSuccess,
  FETCH_REFILLS_ERROR: fetchItemsError,

  FETCH_REFILL_START: fetchItemStart,
  FETCH_REFILL_SUCCESS: fetchItemSuccess,
  FETCH_REFILL_ERROR: fetchItemError,

  CLEAR_REFILL_MODAL_VALUES: clearModalValues
});
