import { replaceById } from '../../../utils';

export function toggleBlockedProductStart(state) {
  return state;
}

export function toggleBlockedProductSuccess(state, { id }) {
  return {
    ...state,
    items: replaceById(state.items, id, i => ({ ...i, blocked: !i.blocked }))
  };
}

export function toggleBlockedProductError(state, { errorMessage }) {
  return {
    ...state,
    errorMessage
  };
}
