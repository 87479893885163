import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_MATERIALS_SET,
  FETCH_MATERIALS_SET_START,
  FETCH_MATERIALS_SET_SUCCESS,
  FETCH_MATERIALS_SET_ERROR
} from '../MaterialsSetConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchMaterialsSet({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_MATERIALS_SET_START, id, options));

  const fetchMaterialsSetAction = yield spawn(fetchItemResource, 'materials_sets', {
    success: FETCH_MATERIALS_SET_SUCCESS,
    error: FETCH_MATERIALS_SET_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchMaterialsSetAction);
}

export default function* watchFetchMaterialsSet() {
  yield takeEvery(FETCH_MATERIALS_SET, fetchMaterialsSet);
}
