import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_MATERIAL,
  UPDATE_MATERIAL_START,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_ERROR
} from '../MaterialConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateMaterial({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_MATERIAL_START, id, options));

  return yield spawn(updateItemResource, 'materials', {
    success: UPDATE_MATERIAL_SUCCESS,
    error: UPDATE_MATERIAL_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateMaterial() {
  yield takeEvery(UPDATE_MATERIAL, updateMaterial);
}
