import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function emptyProductsSetStart(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: null,
      loading: true
    }
  }, options);
}

export function emptyProductsSetSuccess(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      selected_facilities: [],
      error: null,
      loading: false
    }
  }, options);
}

export function emptyProductsSetError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: errorMessage,
      loading: false
    }
  }, options);
}
