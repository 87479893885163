import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SET_CANCELED_ALLOCATION,
  SET_CANCELED_ALLOCATION_START,
  SET_CANCELED_ALLOCATION_SUCCESS,
  SET_CANCELED_ALLOCATION_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* setCanceledAllocation({ id, options, cb }) {
  yield put(postItemStart(SET_CANCELED_ALLOCATION_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/set_cancel_allocated`, {
    success: SET_CANCELED_ALLOCATION_SUCCESS,
    error: SET_CANCELED_ALLOCATION_ERROR
  }, { id, options, cb });
}

export default function* watchSetCanceledAllocation() {
  yield takeEvery(SET_CANCELED_ALLOCATION, setCanceledAllocation);
}
