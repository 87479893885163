import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  REMOVE_TASK_SELECTED_MATERIALS,
  REMOVE_TASK_SELECTED_MATERIALS_START,
  REMOVE_TASK_SELECTED_MATERIALS_SUCCESS,
  REMOVE_TASK_SELECTED_MATERIALS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskSelectedMaterials({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_SELECTED_MATERIALS_START, id, options));

  const removeTaskSelectedMaterialsAction = yield spawn(postItemResource, `discussions/${id}/remove_selected_materials`, {
    success: REMOVE_TASK_SELECTED_MATERIALS_SUCCESS,
    error: REMOVE_TASK_SELECTED_MATERIALS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskSelectedMaterialsAction);
}

export default function* watchRemoveTaskSelectedMaterials() {
  yield takeEvery(REMOVE_TASK_SELECTED_MATERIALS, removeTaskSelectedMaterials);
}
