import get from 'lodash/get';

import { removeByIds, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeUsersFromProjectStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null
  }, options);
}

export function removeUsersFromProjectSuccess(state, { values, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null,
    item: {
      ...state.item,
      members: removeByIds(get(currentStateValue(state, 'item', options), 'members'), get(values, 'user_ids'))
    }
  }, options);
}

export function removeUsersFromProjectError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage
  }, options);
}
