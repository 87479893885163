export const FETCH_SOURCE_FILES = 'FETCH_SOURCE_FILES';
export const FETCH_SOURCE_FILES_START = 'FETCH_SOURCE_FILES_START';
export const FETCH_SOURCE_FILES_SUCCESS = 'FETCH_SOURCE_FILES_SUCCESS';
export const FETCH_SOURCE_FILES_ERROR = 'FETCH_SOURCE_FILES_ERROR';

export const UPDATE_SOURCE_FILE = 'UPDATE_SOURCE_FILE';
export const UPDATE_SOURCE_FILE_START = 'UPDATE_SOURCE_FILE_START';
export const UPDATE_SOURCE_FILE_SUCCESS = 'UPDATE_SOURCE_FILE_SUCCESS';
export const UPDATE_SOURCE_FILE_ERROR = 'UPDATE_SOURCE_FILE_ERROR';

export const DOWNLOAD_SOURCE_FILE = 'DOWNLOAD_SOURCE_FILE';
export const DOWNLOAD_SOURCE_FILE_START = 'DOWNLOAD_SOURCE_FILE_START';
export const DOWNLOAD_SOURCE_FILE_SUCCESS = 'DOWNLOAD_SOURCE_FILE_SUCCESS';
export const DOWNLOAD_SOURCE_FILE_ERROR = 'DOWNLOAD_SOURCE_FILE_ERROR';

export const UPLOAD_SOURCE_FILES = 'UPLOAD_SOURCE_FILES';
export const UPLOAD_SOURCE_FILES_START = 'UPLOAD_SOURCE_FILES_START';
export const UPLOAD_SOURCE_FILES_SUCCESS = 'UPLOAD_SOURCE_FILES_SUCCESS';
export const UPLOAD_SOURCE_FILES_ERROR = 'UPLOAD_SOURCE_FILES_ERROR';
export const UPLOAD_SOURCE_FILE_START = 'UPLOAD_SOURCE_FILE_START';
export const UPLOAD_SOURCE_FILE_SUCCESS = 'UPLOAD_SOURCE_FILE_SUCCESS';
export const UPLOAD_SOURCE_FILE_ERROR = 'UPLOAD_SOURCE_FILE_ERROR';

export const REMOVE_SOURCE_FILE = 'REMOVE_SOURCE_FILE';
export const REMOVE_SOURCE_FILE_START = 'REMOVE_SOURCE_FILE_START';
export const REMOVE_SOURCE_FILE_SUCCESS = 'REMOVE_SOURCE_FILE_SUCCESS';
export const REMOVE_SOURCE_FILE_ERROR = 'REMOVE_SOURCE_FILE_ERROR';

export const ATTACH_SOURCE_FILES = 'ATTACH_SOURCE_FILES';
export const ATTACH_SOURCE_FILES_START = 'ATTACH_SOURCE_FILES_START';
export const ATTACH_SOURCE_FILES_SUCCESS = 'ATTACH_SOURCE_FILES_SUCCESS';
export const ATTACH_SOURCE_FILES_ERROR = 'ATTACH_SOURCE_FILES_ERROR';

export const SOCKET_SUBSCRIBE_TO_SOURCE_FILES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_SOURCE_FILES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_SOURCE_FILES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_SOURCE_FILES_CHANNEL';

export const CLEAR_SOURCE_FILE_MODAL_VALUES = 'CLEAR_SOURCE_FILE_MODAL_VALUES';
