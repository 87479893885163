import React, { Fragment } from 'react';

// import dashboard from './dashboard/routes';
// import allocations from './allocations/routes';
// import bills from './bills/routes';
// import billRefills from './billRefills/routes';
// import refills from './refills/routes';
// import tags from './tags/routes';
// import transactions from './transactions/routes';
// import transactionTags from './transactionTags/routes';
// import contacts from './contacts/routes';
// import emailTemplates from './emailTemplates/routes';
// import sentMessages from './sentMessages/routes';
// import downloads from './downloads/routes';
// import reports from './reports/routes';
// import pages from './pages/routes';
// import roles from './roles/routes';
// import permissions from './permissions/routes';

import accessDeniedErrors from './ts/accessDeniedErrors/routes';
import allocations from './ts/allocations/routes';
import authControllerRequests from './ts/authControllerRequests/routes';
import batchOperations from './ts/batchOperations/routes';
import billRefills from './ts/billRefills/routes';
import bills from './ts/bills/routes';
import categories from './ts/categories/routes';
import clientFiles from './ts/clientFiles/routes';
import contacts from './ts/contacts/routes';
import controllerRequests from './ts/controllerRequests/routes';
import dashboard from './ts/dashboard/routes';
import downloads from './ts/downloads/routes';
import emailTemplates from './ts/emailTemplates/routes';
import evaluations from './ts/evaluations/routes';
import evaluationSets from './ts/evaluationSets/routes';
import fileAttachments from './ts/fileAttachments/routes';
import fileImports from './ts/fileImports/routes';
import images from './ts/images/routes';
import imports from './ts/imports/routes';
import incomingMessages from './ts/incomingMessages/routes';
import lifestyles from './ts/lifestyles/routes';
import materials from './ts/materials/routes';
import maxFiles from './ts/maxFiles/routes';
import pages from './ts/pages/routes';
import payoneerRequests from './ts/payoneerRequests/routes';
import permissions from './ts/permissions/routes';
import products from './ts/products/routes';
import promoCodes from './ts/promoCodes/routes';
import refills from './ts/refills/routes';
import reports from './ts/reports/routes';
import roles from './ts/roles/routes';
import s3Buckets from './ts/s3Buckets/routes';
import s3Objects from './ts/s3Objects/routes';
import sections from './ts/sections/routes';
import sentMessages from './ts/sentMessages/routes';
import sourceFiles from './ts/sourceFiles/routes';
import tags from './ts/tags/routes';
import textureFiles from './ts/textureFiles/routes';
import transactions from './ts/transactions/routes';
import transactionTags from './ts/transactionTags/routes';
import userControllerRequests from './ts/userControllerRequests/routes';
import userDeletes from './ts/userDeletes/routes';
import userLogins from './ts/userLogins/routes';
import userRegistrations from './ts/userRegistrations/routes';
import userResetPasswordRequests from './ts/userResetPasswordRequests/routes';
import userWebsocketRequests from './ts/userWebsocketRequests/routes';

export default (
  <Fragment>
    {accessDeniedErrors}
    {allocations}
    {authControllerRequests}
    {batchOperations}
    {bills}
    {billRefills}
    {categories}
    {clientFiles}
    {contacts}
    {controllerRequests}
    {dashboard}
    {downloads}
    {emailTemplates}
    {evaluations}
    {evaluationSets}
    {fileAttachments}
    {fileImports}
    {images}
    {imports}
    {incomingMessages}
    {lifestyles}
    {materials}
    {maxFiles}
    {pages}
    {payoneerRequests}
    {permissions}
    {promoCodes}
    {products}
    {refills}
    {roles}
    {s3Buckets}
    {s3Objects}
    {reports}
    {sections}
    {sentMessages}
    {sourceFiles}
    {tags}
    {textureFiles}
    {transactions}
    {transactionTags}
    {userControllerRequests}
    {userDeletes}
    {userLogins}
    {userRegistrations}
    {userResetPasswordRequests}
    {userWebsocketRequests}
  </Fragment>
);
