import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_REVENUE_SHARE_ITEM,
  FETCH_REVENUE_SHARE_ITEM_START,
  FETCH_REVENUE_SHARE_ITEM_SUCCESS,
  FETCH_REVENUE_SHARE_ITEM_ERROR
} from '../RevenueShareItemConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchRevenueShareItem({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_REVENUE_SHARE_ITEM_START, id, options));

  const fetchRevenueShareItemAction = yield spawn(fetchItemResource, 'revenue_share_items', {
    success: FETCH_REVENUE_SHARE_ITEM_SUCCESS,
    error: FETCH_REVENUE_SHARE_ITEM_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRevenueShareItemAction);
}

export default function* watchFetchRevenueShareItem() {
  yield takeEvery(FETCH_REVENUE_SHARE_ITEM, fetchRevenueShareItem);
}
