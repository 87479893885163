import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_SELECTED_PRODUCT,
  DOWNLOAD_SELECTED_PRODUCT_START,
  DOWNLOAD_SELECTED_PRODUCT_SUCCESS,
  DOWNLOAD_SELECTED_PRODUCT_ERROR
} from '../SelectedProductConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_SELECTED_PRODUCT_START, id, options));

  const downloadSelectedProductsAction = yield spawn(postItemResource, `selected_facilities/${id}/download`, {
    success: DOWNLOAD_SELECTED_PRODUCT_SUCCESS,
    error: DOWNLOAD_SELECTED_PRODUCT_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadSelectedProductsAction);
}

export default function* watchDownloadSelectedProducts() {
  yield takeEvery(DOWNLOAD_SELECTED_PRODUCT, downloadSelectedProducts);
}
