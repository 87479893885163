import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CHECK_BILL_PAYMENT,
  CHECK_BILL_PAYMENT_START,
  CHECK_BILL_PAYMENT_SUCCESS,
  CHECK_BILL_PAYMENT_ERROR
} from '../BillConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* checkBillPayment({ id, options, cb }) {
  yield put(postItemStart(CHECK_BILL_PAYMENT_START, id, options));

  const checkBillPaymentAction = yield spawn(postItemResource, `bills/${id}/check_and_pay`, {
    success: CHECK_BILL_PAYMENT_SUCCESS,
    error: CHECK_BILL_PAYMENT_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(checkBillPaymentAction);
}

export default function* watchCheckBillPayment() {
  yield takeEvery(CHECK_BILL_PAYMENT, checkBillPayment);
}
