import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_BALANCE,
  FETCH_BALANCE_START,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_ERROR
} from '../BalanceConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchBalance({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_BALANCE_START, id, options));

  const fetchBalanceAction = yield spawn(fetchItemResource, 'balances', {
    success: FETCH_BALANCE_SUCCESS,
    error: FETCH_BALANCE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchBalanceAction);
}

export default function* watchFetchBalance() {
  yield takeEvery(FETCH_BALANCE, fetchBalance);
}
