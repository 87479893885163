import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_TEMPLATE,
  TOGGLE_SELECTED_TEMPLATE_START,
  TOGGLE_SELECTED_TEMPLATE_SUCCESS,
  TOGGLE_SELECTED_TEMPLATE_ERROR
} from '../TemplateConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedTemplateStart(id) {
  return {
    type: TOGGLE_SELECTED_TEMPLATE_START,
    id
  };
}

export function toggleSelectedTemplateSuccess(id) {
  return {
    type: TOGGLE_SELECTED_TEMPLATE_SUCCESS,
    id
  };
}

export function toggleSelectedTemplateError(id, error) {
  return {
    type: TOGGLE_SELECTED_TEMPLATE_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedTemplateRequest(templateId) {
  return apiRequest.post(`scenes/${templateId}/toggle_selected`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedTemplateResource({ templateId, cb }) {
  try {
    yield call(toggleSelectedTemplateRequest, templateId);
    yield put(toggleSelectedTemplateSuccess(templateId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedTemplateError(templateId, error));
  }
}

function* toggleSelectedTemplate({ templateId, cb }) {
  yield put(toggleSelectedTemplateStart(templateId));

  return yield spawn(toggleSelectedTemplateResource, { templateId, cb });
}

export default function* watchToggleSelectedTemplate() {
  yield takeEvery(TOGGLE_SELECTED_TEMPLATE, toggleSelectedTemplate);
}
