import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function revisionTaskStart(state, { id, options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: true,
      error: null
    }))
  }, options);
}

export function revisionTaskSuccess(state, { id, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      error: null
    }))
  }, options);
}

export function revisionTaskError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({
      ...i,
      loading: false,
      error: errorMessage
    }))
  }, options);
}
