import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_TRANSACTION_TAG,
  REMOVE_TRANSACTION_TAG_START,
  REMOVE_TRANSACTION_TAG_SUCCESS,
  REMOVE_TRANSACTION_TAG_ERROR
} from '../TransactionTagConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeTransactionTag({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_TRANSACTION_TAG_START, id, options));

  return yield spawn(removeItemResource, 'transaction_tags', {
    success: REMOVE_TRANSACTION_TAG_SUCCESS,
    error: REMOVE_TRANSACTION_TAG_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveTransactionTag() {
  yield takeEvery(REMOVE_TRANSACTION_TAG, removeTransactionTag);
}
