import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_PAGE,
  CREATE_PAGE_START,
  CREATE_PAGE_SUCCESS,
  CREATE_PAGE_ERROR
} from '../PageConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createPage({ values, options, cb }) {
  yield put(createItemStart(CREATE_PAGE_START, options));

  return yield spawn(createItemResource, 'pages', {
    success: CREATE_PAGE_SUCCESS,
    error: CREATE_PAGE_ERROR
  }, { values, options, cb });
}

export default function* watchCreatePage() {
  yield takeEvery(CREATE_PAGE, createPage);
}
