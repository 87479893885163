import React, { memo, Fragment, useCallback } from 'react';
import get from 'lodash/get';

import { IsLoading } from '../../../../../types';
import { BillType } from '../../../../common/hooks/bills/types';

import { Button } from '../../../../../helpers/ts/Button';
import { Translate } from '../../../../../helpers/ts/Translate';

import { analyticsSetBillCheckout } from '../../../../ts/bills/utils/analyticsSetBillCheckout';
import { renderAmount } from '../../../../../utils/ts/renderAmount';

interface TwoCheckoutButtonProps {
  bill: BillType;
  disabled?: boolean;
  isLoading?: IsLoading;
  icon?: string;
  className?: string;
  addClass?: string;
}

function TwoCheckoutButton({
  className,
  addClass,
  icon,
  bill,
  isLoading,
  disabled
}: TwoCheckoutButtonProps) {
  const handlePaymentProcess = useCallback(() => {
    analyticsSetBillCheckout({ bill, step: 'click to pay' });
    window.open(`/2checkout/${get<BillType, 'id'>(bill, 'id')}/payment`);
  }, [bill]);

  return (
    <Button
      color="primary"
      className={className}
      addClass={addClass}
      disabled={disabled || isLoading}
      onClick={handlePaymentProcess}
    >
      <b className="mr-2">
        <i className={icon} />
      </b>
      {isLoading ? (
        <Translate id="words.processing" />
      ) : (
        <Fragment>
          <Translate id="words.pay" />
          &nbsp;
          {renderAmount(get(bill, 'amount'), false)}
          &nbsp;
          {get(bill, 'currency')}
        </Fragment>
      )}
    </Button>
  );
}

export default memo(TwoCheckoutButton);
