import Promise from 'bluebird';

import { authLoading, authUser, authError } from '../AuthActions';

import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';

import { sendGoogleAnalyticsEvents } from './utils';

// loginUser

export default function facebookLoginUser(accessToken, referer) {
  return dispatch => {
    dispatch(authLoading());

    return apiRequest
      .authPost('auth/facebook', {
        auth: { facebook_auth_token: accessToken, referer },
        serializer: 'sufficient'
      })
      .then(({ data: { data: currentUser, token } }) => {
        sendGoogleAnalyticsEvents(currentUser);
        return Promise.resolve(dispatch(authUser({ currentUser, token })));
      })
      .catch(error =>
        mapErrorsToForm(dispatch, error, dispatch(authError(error)))
      );
  };
}
