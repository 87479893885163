export default {
  singular: 'Bill & Refill',
  plural: 'Bills & Refills',
  no: 'No bills & refills',
  payer: 'Payer',
  payer_upsaler: 'CM',
  types: {
    rietumu: 'Rietumu',
    payoneer: 'Payoneer',
    '2checkout': '2checkout',
    fondy: 'Fondy.eu',
    stripe: 'Stripe',
    easypay: 'Easypay',
    invoice_bill: 'Invoice',
    raiffeisen_bank: 'Raiffeisen bank',
    bank_account_andrey: 'Cash',
    system: 'Archivizer',
    client: 'Prepaid client\'s account',
    cancel_payment: 'Cancel payment',
    amount: 'From client account',
    cancel_amount: 'Return to client account',
    debit_account: 'Pay from CAD',
    cancel_debit_account: 'Return to CAD',
    bonus: 'Pay from CAB',
    cancel_bonus: 'Return to CAB',
    task: 'Project',
    cancel_task: 'Return to Project',

    liqpay: 'Liqpay',
    interkassa: 'Interkassa',
    bank_account_bill: 'Р/С',
    two_checkout_bill: '2checkout',
    fondy_bill: 'Fondy.eu',

    client_account_bill: 'Client',
    two_checkout_refill: '2checkout refill',
    rietumu_bill: 'Rietumu',
    payoneer_bill: 'Payoneer',
    payoneer_checkout_bill: 'Payoneer Checkout',
    system_account_bill: 'System',
    eway: 'Eway',

    amount_bill: 'Amount Bill',
    bonus_bill: 'Bonus Bill',
    cancel_amount_bill: 'Cancel Amount Bill',
    cancel_bonus_bill: 'Cancel Bonus Bill',
    cancel_debit_account_bill: 'Cancel DebitAccount Bill',
    cancel_payment_bill: 'Cancel PaymentBill',
    cancel_task_bill: 'Cancel TaskBill',
    charge_back_refill: 'ChargeBack Refill',
    debit_account_bill: 'DebitAccount Bill',
    easypay_bill: 'Easypay Bill',
    eway_bill: 'EwayBill',
    eway_refill: 'EwayRefill',
    interkassa_bill: 'Interkassa Bill',
    liqpay_bill: 'Liqpay Bill',
    payoneer_refill: 'Payoneer Refill',
    raiffeisen_bank_bill: 'RaiffeisenBank Bill',
    raiffeisen_bank_refill: 'RaiffeisenBank Refill',
    rietumu_refill: 'Rietumu Refill',
    stripe_bill: 'Stripe Bill',
    swift_refill: 'Swift Refill',
    task_bill: 'TaskBill'
  }
};
