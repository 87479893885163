import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UNBLOCK_USER_ACCOUNT,
  UNBLOCK_USER_ACCOUNT_START,
  UNBLOCK_USER_ACCOUNT_SUCCESS,
  UNBLOCK_USER_ACCOUNT_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* unblockUserAccount({ id, options, cb }) {
  yield put(postItemStart(UNBLOCK_USER_ACCOUNT_START, options));

  return yield spawn(postItemResource, `users/${id}/unblock`, {
    success: UNBLOCK_USER_ACCOUNT_SUCCESS,
    error: UNBLOCK_USER_ACCOUNT_ERROR
  }, { id, options, cb });
}

export default function* watchUnblockUserAccount() {
  yield takeEvery(UNBLOCK_USER_ACCOUNT, unblockUserAccount);
}
