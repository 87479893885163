import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_PROJECTS_XLS,
  DOWNLOAD_PROJECTS_XLS_START,
  DOWNLOAD_PROJECTS_XLS_SUCCESS,
  DOWNLOAD_PROJECTS_XLS_ERROR
} from '../ProjectConstants';

import { downloadItemsStart, downloadItemsResource } from '../../common/sagas';

function* downloadProjectsXls(params) {
  yield put(downloadItemsStart(DOWNLOAD_PROJECTS_XLS_START, params));

  const downloadProjectsXlsAction = yield spawn(downloadItemsResource, 'tasks.xlsx', {
    success: DOWNLOAD_PROJECTS_XLS_SUCCESS,
    error: DOWNLOAD_PROJECTS_XLS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(downloadProjectsXlsAction);
}

export default function* watchDownloadProjects() {
  yield takeEvery(DOWNLOAD_PROJECTS_XLS, downloadProjectsXls);
}
