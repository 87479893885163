import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function updateItemStart(state, { id, options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemUpdateErrorMessage: null,
    itemUpdateSuccessMessage: null,
    items: replaceById(currentStateValue(state, 'items', options, []), id, i => ({ ...i, loading: true }))
  }, options);
}

export function updateItemSuccess(state, { id, item, successMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemUpdateSuccessMessage: successMessage,
    itemUpdateErrorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options, []), id, item, i => ({
      ...i,
      ...item,
      successMessage,
      loading: false
    })),
    item
  }, options);
}

export function updateItemError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemUpdateErrorMessage: errorMessage,
    itemUpdateSuccessMessage: null,
    items: replaceById(currentStateValue(state, 'items', options, []), id, i => ({
      ...i,
      errorMessage,
      error: errorMessage,
      loading: false
    }))
  }, options);
}
