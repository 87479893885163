import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_ROLE,
  REMOVE_ROLE_START,
  REMOVE_ROLE_SUCCESS,
  REMOVE_ROLE_ERROR
} from '../RoleConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeRole({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_ROLE_START, id, options));

  return yield spawn(removeItemResource, 'roles', {
    success: REMOVE_ROLE_SUCCESS,
    error: REMOVE_ROLE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveRole() {
  yield takeEvery(REMOVE_ROLE, removeRole);
}
