import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_START,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_ERROR
} from '../TemplateConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTemplate({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TEMPLATE_START, id, options));

  return yield spawn(updateItemResource, 'scenes', {
    success: UPDATE_TEMPLATE_SUCCESS,
    error: UPDATE_TEMPLATE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTemplate() {
  yield takeEvery(UPDATE_TEMPLATE, updateTemplate);
}
