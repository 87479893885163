import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  REMOVE_TASK_SELECTED_TEMPLATES,
  REMOVE_TASK_SELECTED_TEMPLATES_START,
  REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS,
  REMOVE_TASK_SELECTED_TEMPLATES_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskSelectedTemplates({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_SELECTED_TEMPLATES_START, id, options));

  const removeTaskSelectedTemplatesAction = yield spawn(postItemResource, `discussions/${id}/remove_selected_scenes`, {
    success: REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS,
    error: REMOVE_TASK_SELECTED_TEMPLATES_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskSelectedTemplatesAction);
}

export default function* watchRemoveTaskSelectedTemplates() {
  yield takeEvery(REMOVE_TASK_SELECTED_TEMPLATES, removeTaskSelectedTemplates);
}
