import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_SELECTED_TEMPLATES,
  FETCH_SELECTED_TEMPLATES_START,
  FETCH_SELECTED_TEMPLATES_SUCCESS,
  FETCH_SELECTED_TEMPLATES_ERROR
} from '../SelectedTemplateConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchSelectedTemplates(params) {
  yield put(fetchItemsStart(FETCH_SELECTED_TEMPLATES_START, params));

  const fetchSelectedTemplatesAction = yield spawn(fetchItemsResource, 'selected_scenes', {
    success: FETCH_SELECTED_TEMPLATES_SUCCESS,
    error: FETCH_SELECTED_TEMPLATES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchSelectedTemplatesAction);
}

export default function* watchFetchSelectedTemplates() {
  yield takeEvery(FETCH_SELECTED_TEMPLATES, fetchSelectedTemplates);
}
