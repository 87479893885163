import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const MaterialsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_materials" */ './pages/MaterialsIndexPage/MaterialsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/materials" component={MaterialsIndexPage} />
  </Fragment>
);
