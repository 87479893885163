import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ASSIGN_TASK_TO_USER,
  ASSIGN_TASK_TO_USER_START,
  ASSIGN_TASK_TO_USER_SUCCESS,
  ASSIGN_TASK_TO_USER_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* assignTaskToUserRequest({ id, values, options, cb }) {
  yield put(postItemStart(ASSIGN_TASK_TO_USER_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/assign_to_user`, {
    success: ASSIGN_TASK_TO_USER_SUCCESS,
    error: ASSIGN_TASK_TO_USER_ERROR
  }, { id, values, options, cb });
}

export default function* watchAssignTaskToUserRequest() {
  yield takeEvery(ASSIGN_TASK_TO_USER, assignTaskToUserRequest);
}
