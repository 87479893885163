import React from 'react';
import { Redirect } from 'react-router-dom';
import flatten from 'lodash/flatten';

const discussions = [
  <Redirect key="account-discussions-clusters" from="/account/discussions/:id/clusters" to="/profile" />,
  <Redirect key="account-discussions-time" from="/account/discussions/:id/time" to="/profile" />,
  <Redirect key="account-discussions-checkup" from="/account/discussions/checkup" to="/profile" />,
  <Redirect key="account-discussions-results" from="/account/discussions/results" to="/profile" />,
  <Redirect key="account-discussions-source" from="/account/discussions/:id/source" to="/tasks/:id/source-files" />,
  <Redirect key="account-discussions-allocations" from="/account/discussions/:id/allocations" to="/tasks/:id/payouts" />,
  <Redirect key="account-discussions-samples" from="/account/discussions/:id/samples" to="/tasks/:id/templates" />,
  <Redirect key="account-discussions-items" from="/account/discussions/:id/items" to="/tasks/:id/products" />,
  <Redirect key="account-discussions-files" from="/account/discussions/:id/files" to="/tasks/:id/files" />,
  <Redirect key="account-discussions-todos" from="/account/discussions/:id/todos" to="/tasks/:id" />,
  <Redirect key="account-discussions-id" from="/account/discussions/:id?/:tab?" to="/tasks/:id?/:tab?" />
];

const tasks = [
  <Redirect key="account-tasks-new" from="/account/tasks/new" to="/projects" />,
  <Redirect key="account-tasks-discussions" from="/account/tasks/:id/discussions" to="/projects/:id" />,
  <Redirect key="account-tasks-items" from="/account/tasks/:id/items" to="/projects/:id/products" />,
  <Redirect key="account-tasks-finance-report" from="/account/tasks/:id/finance-report" to="/projects/:id/transactions" />,
  <Redirect key="account-tasks-samples" from="/account/tasks/:id/samples" to="/projects/:id/templates" />,
  <Redirect key="account-tasks-files" from="/account/tasks/:id/files" to="/projects/:id/files" />,
  <Redirect key="account-tasks-todos" from="/account/tasks/:id/todos" to="/projects/:id" />,
  <Redirect key="account-tasks-support" from="/account/tasks/:id/support" to="/projects/:id/messages" />,
  <Redirect key="account-tasks-id" from="/account/tasks/:id?/:tab?" to="/projects/:id?/:tab?" />
];

const auth = [
  <Redirect key="account-auth" from="/account/auth/:page" to="/auth/:page" />
];

const items = [
  <Redirect key="account-items" from="/account/items" to="/products" />
];

const messages = [
  <Redirect key="account-messages" from="/account/messages" to="/messages" />
];

const samples = [
  <Redirect key="account-samples" from="/account/samples" to="/templates" />
];

const others = [
  <Redirect key="account-finance-workers" from="/account/finance/workers" to="/profile" />,
  <Redirect key="account-balance-amount" from="/account/balance/amount" to="/profile" />,
  <Redirect key="account-payouts" from="/account/payouts" to="/profile" />,
  <Redirect key="account-charts-time" from="/account/charts/time" to="/profile" />,
  <Redirect key="account-charts-discussions" from="/account/charts/discussions" to="/profile" />
];

export default flatten([discussions, tasks, auth, items, messages, samples, others]);
