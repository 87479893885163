import { put, call } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';
import pluralize from 'pluralize';
import omit from 'lodash/omit';

import { pushToAction } from '../../../utils/pushToAction';
import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function createItemStart(type, options = {}) {
  return {
    type,
    options
  };
}

export function createItemSuccess(type, item = {}, options = {}) {
  return {
    type,
    item,
    options
  };
}

export function createItemError(type, error, options = {}) {
  return {
    type,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function createItemRequest(path, values, options = {}) {
  return apiRequest.post(path, options.pure ? values : { [options.param || pluralize.singular(path)]: values }, omit(options, 'param'))
    .then(({ data: { data: item } }) => ({ item }));
}

export function* createItemResource(path, { success, error: errorConst, param }, { values, options, cb }) {
  try {
    const { item } = yield call(createItemRequest, path, values, { ...options, ...(param ? { param } : {}) });
    yield put(createItemSuccess(success, item, options));
    yield options.form && put(reset(options.form));
    cb && cb(item);
    yield options.successPath && put(pushToAction(options.successPath));
  } catch (error) {
    yield put(createItemError(errorConst, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}
