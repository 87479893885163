import {
  FETCH_POLICY_PAGE_START,
  FETCH_POLICY_PAGE_SUCCESS,
  FETCH_POLICY_PAGE_ERROR,
  CLEAR_POLICY_PAGE
} from './PolicyPageConstants';

const defaultState = {
  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null
};

export default function PolicyPageReducer(state = defaultState, { type, errorMessage, item }) {
  switch (type) {
    case FETCH_POLICY_PAGE_START:
      return {
        ...state,
        itemLoading: true,
        itemFetched: false
      };

    case FETCH_POLICY_PAGE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        itemErrorMessage: null,
        item
      };

    case FETCH_POLICY_PAGE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: false,
        itemErrorMessage: errorMessage
      };

    case CLEAR_POLICY_PAGE:
      return defaultState;

    default:
      return state;
  }
}
