import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_FINANCE_TAG,
  UPDATE_FINANCE_TAG_START,
  UPDATE_FINANCE_TAG_SUCCESS,
  UPDATE_FINANCE_TAG_ERROR
} from '../FinanceTagConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateFinanceTag({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_FINANCE_TAG_START, id, options));

  return yield spawn(updateItemResource, 'finance_user_tags', {
    success: UPDATE_FINANCE_TAG_SUCCESS,
    error: UPDATE_FINANCE_TAG_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateFinanceTag() {
  yield takeEvery(UPDATE_FINANCE_TAG, updateFinanceTag);
}
