import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import get from 'lodash/get';

import { withModalButton } from '../../../HOCs/withModalButton';
import {
  clearPolicyPage as clearPolicyPageAction,
  fetchPolicyPage as fetchPolicyPageAction
} from '../../../../../auth/PolicyPageActions';
import { AlertMessage, Loading } from '../../../../../helpers';
import { Tabs } from '../../../helpers/Tabs';
import { translate as t } from '../../../../../utils';

import {
  PAYMENT_POLICY_PAGE_ID,
  PRIVACY_POLICY_PAGE_ID,
  REFUND_POLICY_PAGE_ID
} from '../../../../../config';

const tabs = [
  { value: REFUND_POLICY_PAGE_ID, label: 'words.terms_of_service' },
  { value: PAYMENT_POLICY_PAGE_ID, label: 'words.payment_terms' },
  { value: PRIVACY_POLICY_PAGE_ID, label: 'words.privacy_policy' }
];

function TermsModalButton({ handleTabChange, policyPageLoading, policyPageErrorMessage, policyPage }) {
  return (
    <Fragment>
      <Tabs
        withoutPermissions
        defaultTab={REFUND_POLICY_PAGE_ID}
        data={tabs}
        className="nav nav-tabs nav-justified nav-tabs-bottom"
        onChange={handleTabChange}
      />
      <div className="tab-content">
        <Loading loaded={!policyPageLoading}>
          <AlertMessage>{policyPageErrorMessage}</AlertMessage>
          <div dangerouslySetInnerHTML={{ __html: get(policyPage, 'body') }} />
        </Loading>
      </div>
    </Fragment>
  );
}

TermsModalButton.propTypes = {
  policyPage: PropTypes.shape({ name: PropTypes.string, body: PropTypes.string }).isRequired,
  policyPageLoading: PropTypes.bool.isRequired,
  policyPageErrorMessage: PropTypes.string,
  handleTabChange: PropTypes.func.isRequired
};

TermsModalButton.defaultProps = {
  policyPageErrorMessage: null
};

export default compose(
  connect(({
    policyPages: {
      item: policyPage,
      itemLoading: policyPageLoading,
      itemErrorMessage: policyPageErrorMessage
    }
  }) => ({
    policyPage,
    policyPageLoading,
    policyPageErrorMessage
  }), {
    fetchPolicyPage: fetchPolicyPageAction,
    clearPolicyPage: clearPolicyPageAction
  }),
  withHandlers({
    handleTabChange: ({ fetchPolicyPage }) => tab => fetchPolicyPage(tab),
    handleToggleOpened: ({ fetchPolicyPage, clearPolicyPage }) => opened => {
      if (opened) {
        return fetchPolicyPage(REFUND_POLICY_PAGE_ID);
      }
      return clearPolicyPage();
    }
  }),
  withProps(({ handleToggleOpened }) => ({
    withoutCancel: true,
    modalTitle: t('words.terms'),
    modalIcon: 'icon-certificate mr-2',
    submitI18nText: 'words.ok',
    submitColor: 'text-secondary',
    buttonText: t('words.terms'),
    buttonSize: 'sm',
    modalSize: 'lg',
    addClass: 'btn-link text-secondary',
    onToggle: handleToggleOpened
  })),
  withModalButton()
)(TermsModalButton);
