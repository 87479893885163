import { updateState } from '../../../utils';

export function clearModalValues(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    itemLoading: false,
    itemErrorMessage: null,
    itemCreateErrorMessage: null,
    itemDownloadErrorMessage: null,
    itemUpdateErrorMessage: null,
    itemRemoveErrorMessage: null,
    itemUploadErrorMessage: null,
    itemsErrorMessage: null,
    itemsUploadErrorMessage: null
  }, options);
}
