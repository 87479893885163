import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR
} from '../CategoryConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchCategories(params) {
  yield put(fetchItemsStart(FETCH_CATEGORIES_START, params));

  const fetchCategoriesAction = yield spawn(fetchItemsResource, 'categories', {
    success: FETCH_CATEGORIES_SUCCESS,
    error: FETCH_CATEGORIES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchCategoriesAction);
}

export default function* watchFetchCategories() {
  yield takeEvery(FETCH_CATEGORIES, fetchCategories);
}
