import get from 'lodash/get';
import has from 'lodash/has';

import {
  CurrentUser,
  ID,
  Permission,
  Permissions,
  Locale,
  Image
} from '../../../../types';

import { productionConsole } from '../../../../utils/ts/productionConsole';

export interface User {
  id: ID;
  name: string;
  full_name: string;
  permissions: Permissions;
  locale: Locale;
  image?: Image | null;
  tasks_count: number;
  google_tag_user_id: string | null;
}

class AuthenticatedUser {
  id: ID;
  name: string;
  full_name: string;
  permissions: Permissions;
  shownPermissions: Permissions;
  locale: Locale;
  image: Image | null;
  tasks_count: number;
  google_tag_user_id: string | null;

  constructor(values: User) {
    this.id = values.id;
    this.name = values.name;
    this.full_name = values.full_name;
    this.permissions = values.permissions;
    this.shownPermissions = {};
    this.locale = values.locale;
    this.image = values.image || null;
    this.tasks_count = values.tasks_count;
    this.google_tag_user_id = values.google_tag_user_id;
  }

  get(field: string, defaultValue = null) {
    return get(this, field, defaultValue);
  }

  hasPermissions(
    action: Permission,
    ignoreSelfAction: Permission | null = null,
    userId: ID | null = null
  ): boolean {
    const actionResult = has(this.permissions || {}, action) || false;

    if (ignoreSelfAction && userId) {
      return this.get('id') === userId
        ? actionResult
        : has(this.permissions || {}, ignoreSelfAction) || false;
    }

    if (!actionResult && !this.shownPermissions[action]) {
      this.shownPermissions[action] = action;
      productionConsole('log', 'hasPermissions', this.get('id'), action, {
        actionResult,
        client: this.get('client'),
        ignoreSelfAction,
        userId,
        user: this
      });
    }

    if (!this.shownPermissions[action]) {
      this.shownPermissions[action] = action;
      productionConsole('warn', 'hasPermissions', this.get('id'), action, {
        actionResult,
        client: this.get('client'),
        ignoreSelfAction,
        userId,
        user: this
      });
    }

    return actionResult;
  }

  isClient() {
    return this.get('client');
  }

  isEn() {
    return this.get('locale') === 'en';
  }

  isRu() {
    return this.get('locale') === 'ru';
  }

  isUk() {
    return this.get('locale') === 'ru';
  }

  canChange(user: CurrentUser, action: string) {
    if (this.get('id') === user.id) {
      return true;
    }

    return this.hasPermissions(action);
  }
}

export default AuthenticatedUser;
