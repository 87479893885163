import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TASK_PROJECT_ESTIMATE_COST,
  UPDATE_TASK_PROJECT_ESTIMATE_COST_START,
  UPDATE_TASK_PROJECT_ESTIMATE_COST_SUCCESS,
  UPDATE_TASK_PROJECT_ESTIMATE_COST_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* updateTaskProjectEstimateCost({ id, values, options, cb }) {
  yield put(postItemStart(UPDATE_TASK_PROJECT_ESTIMATE_COST_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/update_task_estimate_cost`, {
    success: UPDATE_TASK_PROJECT_ESTIMATE_COST_SUCCESS,
    error: UPDATE_TASK_PROJECT_ESTIMATE_COST_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTaskProjectEstimateCost() {
  yield takeEvery(UPDATE_TASK_PROJECT_ESTIMATE_COST, updateTaskProjectEstimateCost);
}
