import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  EMPTY_PRODUCTS_SET,
  EMPTY_PRODUCTS_SET_START,
  EMPTY_PRODUCTS_SET_SUCCESS,
  EMPTY_PRODUCTS_SET_ERROR
} from '../ProductsSetConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* emptyProductsSet({ id, options, cb }) {
  yield put(postItemStart(EMPTY_PRODUCTS_SET_START, id, options));

  const emptyProductsSetAction = yield spawn(postItemResource, `facilities_sets/${id}/empty`, {
    success: EMPTY_PRODUCTS_SET_SUCCESS,
    error: EMPTY_PRODUCTS_SET_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(emptyProductsSetAction);
}

export default function* watchEmptyProductsSet() {
  yield takeEvery(EMPTY_PRODUCTS_SET, emptyProductsSet);
}
