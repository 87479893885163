import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SHARE_ALLOCATION,
  SHARE_ALLOCATION_START,
  SHARE_ALLOCATION_SUCCESS,
  SHARE_ALLOCATION_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* shareAllocation({ id, options, cb }) {
  yield put(postItemStart(SHARE_ALLOCATION_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/allocate`, {
    success: SHARE_ALLOCATION_SUCCESS,
    error: SHARE_ALLOCATION_ERROR
  }, { id, options, cb });
}

export default function* watchShareAllocation() {
  yield takeEvery(SHARE_ALLOCATION, shareAllocation);
}
