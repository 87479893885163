import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeMessageSelectedTemplatesStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_scenes: replaceById(get(message, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeMessageSelectedTemplatesSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_scenes: removeById(get(message, 'selected_scenes', []), id)
    }))
  }, options);
}

export function removeMessageSelectedTemplatesError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), options.discussion_message_id, message => ({
      ...message,
      selected_scenes: replaceById(get(message, 'selected_scenes', []), id, selectedTemplate => ({
        ...selectedTemplate,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
