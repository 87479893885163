import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CANCEL_PAYOUT,
  CANCEL_PAYOUT_START,
  CANCEL_PAYOUT_SUCCESS,
  CANCEL_PAYOUT_ERROR
} from '../PayoutConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* cancelPayout({ id, options, cb }) {
  yield put(postItemStart(CANCEL_PAYOUT_START, id, options));

  return yield spawn(postItemResource, `funds_allocations/${id}/cancel`, {
    success: CANCEL_PAYOUT_SUCCESS,
    error: CANCEL_PAYOUT_ERROR
  }, { id, options, cb });
}

export default function* watchCancelPayout() {
  yield takeEvery(CANCEL_PAYOUT, cancelPayout);
}
