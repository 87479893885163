import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_START,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR
} from '../ProductConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createProduct({ values, options, cb }) {
  yield put(createItemStart(CREATE_PRODUCT_START, options));

  return yield spawn(createItemResource, 'facilities', {
    success: CREATE_PRODUCT_SUCCESS,
    error: CREATE_PRODUCT_ERROR
  }, { values, options, cb });
}

export default function* watchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}
