export default {
  no: 'No user controller requests',
  plural: 'User controller requests',
  refresh: 'Refresh user controller requests list',
  singular: 'User controller request',
  method_names: {
    get: 'GET',
    post: 'POST',
    patch: 'PATCH',
    delete: 'DELETE'
  }
};
