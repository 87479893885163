import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsError,
  locationChange,
  fetchItemStart,
  fetchItemSuccess,
  fetchItemError,
  createItemStart,
  createItemSuccess,
  createItemError,
  updateItemStart,
  updateItemSuccess, updateItemError, removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_FINANCE_TAGS_START: fetchItemsStart,
  FETCH_FINANCE_TAGS_SUCCESS: fetchItemsSuccess,
  FETCH_FINANCE_TAGS_ERROR: fetchItemsError,

  FETCH_FINANCE_TAG_START: fetchItemStart,
  FETCH_FINANCE_TAG_SUCCESS: fetchItemSuccess,
  FETCH_FINANCE_TAG_ERROR: fetchItemError,

  CREATE_FINANCE_TAG_START: createItemStart,
  CREATE_FINANCE_TAG_SUCCESS: createItemSuccess,
  CREATE_FINANCE_TAG_ERROR: createItemError,

  UPDATE_FINANCE_TAG_START: updateItemStart,
  UPDATE_FINANCE_TAG_SUCCESS: updateItemSuccess,
  UPDATE_FINANCE_TAG_ERROR: updateItemError,

  REMOVE_FINANCE_TAG_START: removeItemStart,
  REMOVE_FINANCE_TAG_SUCCESS: removeItemSuccess,
  REMOVE_FINANCE_TAG_ERROR: removeItemError
});
