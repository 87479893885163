import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_START,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR
} from '../TemplateConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTemplate({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TEMPLATE_START, id, options));

  const fetchTemplateAction = yield spawn(fetchItemResource, 'scenes', {
    success: FETCH_TEMPLATE_SUCCESS,
    error: FETCH_TEMPLATE_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTemplateAction);
}

export default function* watchFetchTemplate() {
  yield takeEvery(FETCH_TEMPLATE, fetchTemplate);
}
