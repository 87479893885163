import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_SOURCE_FILES,
  FETCH_SOURCE_FILES_START,
  FETCH_SOURCE_FILES_SUCCESS,
  FETCH_SOURCE_FILES_ERROR
} from '../SourceFileConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchSourceFiles(params) {
  yield put(fetchItemsStart(FETCH_SOURCE_FILES_START, params));

  const fetchSourceFilesAction = yield spawn(fetchItemsResource, 'source_files', {
    success: FETCH_SOURCE_FILES_SUCCESS,
    error: FETCH_SOURCE_FILES_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchSourceFilesAction);
}

export default function* watchFetchSourceFiles() {
  yield takeEvery(FETCH_SOURCE_FILES, fetchSourceFiles);
}
