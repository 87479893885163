import Promise from 'bluebird';
import nanoid from 'nanoid';

import { authLoading, authUser, authError } from '../AuthActions';

import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';
import { sendAuthErrorToSentry } from '../../utils/ts/sendAuthErrorToSentry';

import { sendGoogleAnalyticsEvents } from './utils';

// loginUser

export default function loginUser(auth) {
  return dispatch => {
    dispatch(authLoading());

    const frRequestId = nanoid();

    return apiRequest
      .authPost(
        'auth/sign_in',
        {
          auth
        },
        { headers: { 'fr-request-id': frRequestId } }
      )
      .then(({ data: { data: currentUser, token } }) => {
        sendGoogleAnalyticsEvents(currentUser);
        return Promise.resolve(dispatch(authUser({ currentUser, token })));
      })
      .catch(error => {
        sendAuthErrorToSentry({ error, auth, tag: 'login-user', frRequestId });
        return mapErrorsToForm(dispatch, error, dispatch(authError(error)));
      });
  };
}
