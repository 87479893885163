export const FETCH_ALLOCATIONS = 'FETCH_ALLOCATIONS';
export const FETCH_ALLOCATIONS_START = 'FETCH_ALLOCATIONS_START';
export const FETCH_ALLOCATIONS_SUCCESS = 'FETCH_ALLOCATIONS_SUCCESS';
export const FETCH_ALLOCATIONS_ERROR = 'FETCH_ALLOCATIONS_ERROR';

export const FETCH_ALLOCATION = 'FETCH_ALLOCATION';
export const FETCH_ALLOCATION_START = 'FETCH_ALLOCATION_START';
export const FETCH_ALLOCATION_SUCCESS = 'FETCH_ALLOCATION_SUCCESS';
export const FETCH_ALLOCATION_ERROR = 'FETCH_ALLOCATION_ERROR';

export const CLEAR_ALLOCATIONS_FILTERS_START = 'CLEAR_ALLOCATIONS_FILTERS_START';
export const CLEAR_ALLOCATIONS_FILTERS_SUCCESS = 'CLEAR_ALLOCATIONS_FILTERS_SUCCESS';

export const CLEAR_ALLOCATION_MODAL_VALUES = 'CLEAR_ALLOCATION_MODAL_VALUES';

export const SET_CANCELED_ALLOCATION = 'SET_CANCELED_ALLOCATION';
export const SET_CANCELED_ALLOCATION_START = 'SET_CANCELED_ALLOCATION_START';
export const SET_CANCELED_ALLOCATION_SUCCESS = 'SET_CANCELED_ALLOCATION_SUCCESS';
export const SET_CANCELED_ALLOCATION_ERROR = 'SET_CANCELED_ALLOCATION_ERROR';

export const CANCEL_ALLOCATION = 'CANCEL_ALLOCATION';
export const CANCEL_ALLOCATION_START = 'CANCEL_ALLOCATION_START';
export const CANCEL_ALLOCATION_SUCCESS = 'CANCEL_ALLOCATION_SUCCESS';
export const CANCEL_ALLOCATION_ERROR = 'CANCEL_ALLOCATION_ERROR';

export const CANCEL_ALLOCATION_FROM_CLIENT_BALANCE = 'CANCEL_ALLOCATION_FROM_CLIENT_BALANCE';
export const CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_START = 'CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_START';
export const CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS = 'CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS';
export const CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_ERROR = 'CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_ERROR';

export const SET_SHARED_ALLOCATION = 'SET_SHARED_ALLOCATION';
export const SET_SHARED_ALLOCATION_START = 'SET_SHARED_ALLOCATION_START';
export const SET_SHARED_ALLOCATION_SUCCESS = 'SET_SHARED_ALLOCATION_SUCCESS';
export const SET_SHARED_ALLOCATION_ERROR = 'SET_SHARED_ALLOCATION_ERROR';

export const SHARE_ALLOCATION = 'SHARE_ALLOCATION';
export const SHARE_ALLOCATION_START = 'SHARE_ALLOCATION_START';
export const SHARE_ALLOCATION_SUCCESS = 'SHARE_ALLOCATION_SUCCESS';
export const SHARE_ALLOCATION_ERROR = 'SHARE_ALLOCATION_ERROR';

export const SHARE_ALLOCATION_FROM_CLIENT_BALANCE = 'SHARE_ALLOCATION_FROM_CLIENT_BALANCE';
export const SHARE_ALLOCATION_FROM_CLIENT_BALANCE_START = 'SHARE_ALLOCATION_FROM_CLIENT_BALANCE_START';
export const SHARE_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS = 'SHARE_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS';
export const SHARE_ALLOCATION_FROM_CLIENT_BALANCE_ERROR = 'SHARE_ALLOCATION_FROM_CLIENT_BALANCE_ERROR';

export const SOCKET_SUBSCRIBE_TO_ALLOCATIONS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_ALLOCATIONS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_ALLOCATIONS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_ALLOCATIONS_CHANNEL';
