import React, { Fragment, ReactNode } from 'react';
import Loader, { LoaderType } from 'react-loaders';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Item } from '../../../types';

import 'loaders.css/loaders.min.css';
import './Loading.scss';

interface LoadingProps {
  addClass?: string;
  item?: Item | null;
  className?: string;
  loaded?: boolean;
  withoutLoader?: boolean;
  ignoreLoader?: ReactNode;
  type?: LoaderType;
  children?: ReactNode;
}

function Loading({
  addClass,
  className,
  loaded = false,
  item,
  ignoreLoader,
  type = 'ball-pulse',
  withoutLoader = false,
  children
}: LoadingProps) {
  if (!loaded && withoutLoader) {
    return null;
  }

  return (
    <Fragment>
      {ignoreLoader}
      {loaded ? (
        (item ? !isEmpty(item) : true) && children
      ) : (
        <div className={className || cl('loader-wrapper', addClass)}>
          <Loader active type={type} />
        </div>
      )}
    </Fragment>
  );
}

export default Loading;
