import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import cl from 'classnames';

import get from 'lodash/get';

import { TOOLTIP_DELAY_HIDE, TOOLTIP_DELAY_SHOW } from '../constants';
import { translate as t } from '../../utils';

const tooltipDelay = { show: TOOLTIP_DELAY_SHOW, hide: TOOLTIP_DELAY_HIDE };

function LinkTo({
  id,
  data,
  className,
  addClass,
  url,
  href,
  button,
  color,
  size,
  icon,
  buttonFix,
  xsButtonFix,
  buttonTopFix,
  outline,
  rounded,
  flat,
  active,
  pureLink,
  disabled,
  onClick,
  onMouseDown,
  children,
  target,
  tabIndex,
  tooltip,
  tooltipTarget,
  tooltipValue,
  tooltipPlacement,
  style,
  ...props
}) {
  const linkUrl = url || href;

  const buttonClass = className || cl({
    btn: button,
    [`btn-${color}`]: !!color,
    [`btn-${size}`]: !!size,
    [addClass]: !!addClass,
    'b-m-b-xs': xsButtonFix,
    'b-button-top-fix': buttonTopFix,
    'btn-outline': outline,
    'btn-rounded': rounded,
    'btn-flat': flat,
    active
  });

  const linkIcon = icon ? <i className={cl(icon, { 'mr-2': children })} /> : null;

  if (pureLink) {
    return (
      <Fragment>
        <a
          id={id || tooltipTarget || get(tooltip, 'target')}
          className={cl(buttonClass)}
          href={linkUrl}
          target={target}
          disabled={disabled}
          onMouseDown={onMouseDown}
          onClick={e => {
            if (disabled) {
              e.preventDefault();
              return;
            }

            if (onClick && !disabled) {
              e.preventDefault();
              onClick(data || e);
            }
          }}
          tabIndex={tabIndex}
          style={style}
          {...props}
        >
          {linkIcon}
          {children}
        </a>
        {tooltip || tooltipTarget ? (
          <UncontrolledTooltip
            delay={tooltipDelay}
            placement={tooltipPlacement || get(tooltip, 'placement') || 'bottom'}
            target={tooltipTarget || get(tooltip, 'target')}
          >
            {tooltipValue || tooltip.value || t(tooltip.i18nText)}
          </UncontrolledTooltip>
        ) : null}
      </Fragment>
    );
  }

  return (
    <Link
      id={id || tooltipTarget || get(tooltip, 'target')}
      className={cl(buttonClass)}
      disabled={disabled}
      to={`/${linkUrl}`}
      target={target}
      tabIndex={tabIndex}
      style={style}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
        }
      }}
      {...props}
    >
      {linkIcon}
      {linkIcon && children ? ' ' : null}
      {children}
      {tooltip || tooltipTarget ? (
        <UncontrolledTooltip
          placement={tooltipPlacement || get(tooltip, 'placement') || 'bottom'}
          target={tooltipTarget || get(tooltip, 'target')}
          delay={tooltipDelay}
        >
          {tooltipValue || tooltip.value}
        </UncontrolledTooltip>
      ) : null}
    </Link>
  );
}

LinkTo.propTypes = {
  data: PropTypes.shape({}),
  id: PropTypes.string,
  className: PropTypes.string,
  addClass: PropTypes.string,
  url: PropTypes.string,
  href: PropTypes.string,
  button: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  buttonFix: PropTypes.bool,
  xsButtonFix: PropTypes.bool,
  buttonTopFix: PropTypes.bool,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  flat: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  pureLink: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  target: PropTypes.string,
  tooltip: PropTypes.shape({
    placement: PropTypes.string,
    target: PropTypes.string,
    value: PropTypes.node,
    i18nText: PropTypes.node
  }),
  tooltipTarget: PropTypes.string,
  tooltipValue: PropTypes.node,
  tooltipPlacement: PropTypes.string,
  tabIndex: PropTypes.number
};

LinkTo.defaultProps = {
  id: null,
  data: null,
  className: null,
  addClass: null,
  url: null,
  href: null,
  button: null,
  color: null,
  size: null,
  icon: null,
  buttonFix: null,
  xsButtonFix: null,
  buttonTopFix: null,
  outline: null,
  rounded: null,
  flat: null,
  disabled: null,
  active: null,
  pureLink: null,
  children: null,
  onClick: null,
  onMouseDown: null,
  target: null,
  tooltip: null,
  tooltipTarget: null,
  tooltipValue: null,
  tooltipPlacement: null,
  tabIndex: null
};

export default LinkTo;
