import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange
} from '../../common/reducers';

import {
  acceptLinkedAccountInviteStart,
  acceptLinkedAccountInviteSuccess,
  acceptLinkedAccountInviteError
} from './acceptLinkedAccountInvite';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),

  ACCEPT_LINKED_ACCOUNT_INVITE_START: acceptLinkedAccountInviteStart,
  ACCEPT_LINKED_ACCOUNT_INVITE_SUCCESS: acceptLinkedAccountInviteSuccess,
  ACCEPT_LINKED_ACCOUNT_INVITE_ERROR: acceptLinkedAccountInviteError
});
