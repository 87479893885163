import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const RoleShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_roles" */ './pages/RoleShowPage/RoleShowPage'
    )
});

const RolesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_roles" */ './pages/RolesIndexPage/RolesIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/roles/:roleId(\d+)" component={RoleShowPage} />
    <Route exact path="/admin/roles" component={RolesIndexPage} />
  </Fragment>
);
