import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS,
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_START,
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS,
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR
} from '../ProjectProfileConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitProjectProfileFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_START, id, options));

  return yield spawn(postItemResource, `task_profiles/${id}/submit_file_attachments`, {
    success: SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS,
    error: SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchSubmitProjectProfileFileAttachments() {
  yield takeEvery(SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS, submitProjectProfileFileAttachments);
}
