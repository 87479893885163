import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_PROJECT_SOURCE_FILES,
  DOWNLOAD_PROJECT_SOURCE_FILES_START,
  DOWNLOAD_PROJECT_SOURCE_FILES_SUCCESS,
  DOWNLOAD_PROJECT_SOURCE_FILES_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadProjectSourceFiles({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_PROJECT_SOURCE_FILES_START, id, options));

  const downloadProjectSourceFilesAction = yield spawn(postItemResource, `tasks/${id}/source_files`, {
    success: DOWNLOAD_PROJECT_SOURCE_FILES_SUCCESS,
    error: DOWNLOAD_PROJECT_SOURCE_FILES_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadProjectSourceFilesAction);
}

export default function* watchDownloadProjectSourceFiles() {
  yield takeEvery(DOWNLOAD_PROJECT_SOURCE_FILES, downloadProjectSourceFiles);
}
