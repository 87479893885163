import { parse } from 'qs';
import get from 'lodash/get';

import { getFromCookies } from '../ts/cookies';

export default function parseReferer(search) {
  return (
    getFromCookies('referer') ||
    (search && get(parse(`${search}`.replace(/\?/, '')), 'referer')) ||
    window.location.href
  );
}
