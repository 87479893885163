import compact from 'lodash/compact';

import { ID } from '../../../../../types';

import { BillType } from '../types';

import {
  usePostItemRequest,
  PostItemHookOptions
} from '../../base/usePostItemRequest';

const url = 'bills';
const path = 'pay_from_account';

interface PayBillFromAccountOptions extends PostItemHookOptions {
  id: ID;
}

function usePayBillFromAccount({ id, ...options }: PayBillFromAccountOptions) {
  const {
    itemPostErrorMessage,
    itemPostLoading,
    postItem,
    cancel
  } = usePostItemRequest<BillType>({
    id,
    url: compact([url, id, path]).join('/'),
    ...options
  });

  return {
    payBillFromAccountErrorMessage: itemPostErrorMessage,
    payBillFromAccountLoading: itemPostLoading,
    payBillFromAccount: postItem,
    cancel
  };
}

export default usePayBillFromAccount;
