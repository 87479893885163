import React from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const ImportsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_imports" */ './pages/ImportsIndexPage/ImportsIndexPage'
    )
});

export default (
  <Route exact path="/admin/imports" component={ImportsIndexPage} />
);
