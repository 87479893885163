export const FETCH_MATERIALS = 'FETCH_MATERIALS';
export const FETCH_MATERIALS_START = 'FETCH_MATERIALS_START';
export const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS';
export const FETCH_MATERIALS_ERROR = 'FETCH_MATERIALS_ERROR';

export const FETCH_MATERIAL = 'FETCH_MATERIAL';
export const FETCH_MATERIAL_START = 'FETCH_MATERIAL_START';
export const FETCH_MATERIAL_SUCCESS = 'FETCH_MATERIAL_SUCCESS';
export const FETCH_MATERIAL_ERROR = 'FETCH_MATERIAL_ERROR';

export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const CREATE_MATERIAL_START = 'CREATE_MATERIAL_START';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const CREATE_MATERIAL_ERROR = 'CREATE_MATERIAL_ERROR';

export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const UPDATE_MATERIAL_START = 'UPDATE_MATERIAL_START';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL_ERROR = 'UPDATE_MATERIAL_ERROR';

export const DOWNLOAD_MATERIAL = 'DOWNLOAD_MATERIAL';
export const DOWNLOAD_MATERIAL_START = 'DOWNLOAD_MATERIAL_START';
export const DOWNLOAD_MATERIAL_SUCCESS = 'DOWNLOAD_MATERIAL_SUCCESS';
export const DOWNLOAD_MATERIAL_ERROR = 'DOWNLOAD_MATERIAL_ERROR';

export const TOGGLE_SELECTED_MATERIAL = 'TOGGLE_SELECTED_MATERIAL';
export const TOGGLE_SELECTED_MATERIAL_START = 'TOGGLE_SELECTED_MATERIAL_START';
export const TOGGLE_SELECTED_MATERIAL_SUCCESS = 'TOGGLE_SELECTED_MATERIAL_SUCCESS';
export const TOGGLE_SELECTED_MATERIAL_ERROR = 'TOGGLE_SELECTED_MATERIAL_ERROR';

export const TOGGLE_BLOCKED_MATERIAL = 'TOGGLE_BLOCKED_MATERIAL';
export const TOGGLE_BLOCKED_MATERIAL_START = 'TOGGLE_BLOCKED_MATERIAL_START';
export const TOGGLE_BLOCKED_MATERIAL_SUCCESS = 'TOGGLE_BLOCKED_MATERIAL_SUCCESS';
export const TOGGLE_BLOCKED_MATERIAL_ERROR = 'TOGGLE_BLOCKED_MATERIAL_ERROR';

export const REMOVE_MATERIAL = 'REMOVE_MATERIAL';
export const REMOVE_MATERIAL_START = 'REMOVE_MATERIAL_START';
export const REMOVE_MATERIAL_SUCCESS = 'REMOVE_MATERIAL_SUCCESS';
export const REMOVE_MATERIAL_ERROR = 'REMOVE_MATERIAL_ERROR';

export const CLEAR_MATERIAL_FILTERS_START = 'CLEAR_MATERIAL_FILTERS_START';
export const CLEAR_MATERIAL_FILTERS_SUCCESS = 'CLEAR_MATERIAL_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_MATERIALS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_MATERIALS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_MATERIALS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_MATERIALS_CHANNEL';

export const CLEAR_MATERIAL_MODAL_VALUES = 'CLEAR_MATERIAL_MODAL_VALUES';

export const CREATE_MATERIAL_FORM = 'CREATE_MATERIAL_FORM';
export const UPDATE_MATERIAL_FORM = 'UPDATE_MATERIAL_FORM';
export const SELECT_MATERIAL_CLIENTS_FORM = 'SELECT_MATERIAL_CLIENTS_FORM';
export const SELECTED_MATERIALS_FORM = 'SELECTED_MATERIALS_FORM';
