import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_SELECTED_TEMPLATE,
  CREATE_SELECTED_TEMPLATE_START,
  CREATE_SELECTED_TEMPLATE_SUCCESS,
  CREATE_SELECTED_TEMPLATE_ERROR
} from '../SelectedTemplateConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createSelectedTemplate({ values, options, cb }) {
  yield put(createItemStart(CREATE_SELECTED_TEMPLATE_START, options));

  return yield spawn(createItemResource, 'selected_scenes', {
    success: CREATE_SELECTED_TEMPLATE_SUCCESS,
    error: CREATE_SELECTED_TEMPLATE_ERROR
  }, { values, options, cb });
}

export default function* watchCreateSelectedTemplate() {
  yield takeEvery(CREATE_SELECTED_TEMPLATE, createSelectedTemplate);
}
