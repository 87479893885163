import { Locale } from 'date-fns';

const locales: { [key: string]: any } = {};

function setLocale(localeName: string, locale: Locale): void {
  locales[localeName] = locale;
}

function getLocale(localeName: string): Locale | null {
  return locales[localeName] || null;
}

const dateFnsLocales = { setLocale, getLocale };

export default dateFnsLocales;
