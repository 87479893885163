import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_START,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_ERROR
} from '../CategoryConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchCategory({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_CATEGORY_START, id, options));

  const fetchCategoryAction = yield spawn(fetchItemResource, 'categories', {
    success: FETCH_CATEGORY_SUCCESS,
    error: FETCH_CATEGORY_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchCategoryAction);
}

export default function* watchFetchCategory() {
  yield takeEvery(FETCH_CATEGORY, fetchCategory);
}
