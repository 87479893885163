import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  TOGGLE_SELECTED_MATERIAL,
  TOGGLE_SELECTED_MATERIAL_START,
  TOGGLE_SELECTED_MATERIAL_SUCCESS,
  TOGGLE_SELECTED_MATERIAL_ERROR
} from '../MaterialConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function toggleSelectedMaterialStart(id) {
  return {
    type: TOGGLE_SELECTED_MATERIAL_START,
    id
  };
}

export function toggleSelectedMaterialSuccess(id) {
  return {
    type: TOGGLE_SELECTED_MATERIAL_SUCCESS,
    id
  };
}

export function toggleSelectedMaterialError(id, error) {
  return {
    type: TOGGLE_SELECTED_MATERIAL_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function toggleSelectedMaterialRequest(materialId) {
  return apiRequest.post(`materials/${materialId}/toggle_selected`).then(({ data: { data: item } }) => ({ item }));
}

function* toggleSelectedMaterialResource({ materialId, cb }) {
  try {
    yield call(toggleSelectedMaterialRequest, materialId);
    yield put(toggleSelectedMaterialSuccess(materialId));
    cb && cb();
  } catch (error) {
    yield put(toggleSelectedMaterialError(materialId, error));
  }
}

function* toggleSelectedMaterial({ materialId, cb }) {
  yield put(toggleSelectedMaterialStart(materialId));

  return yield spawn(toggleSelectedMaterialResource, { materialId, cb });
}

export default function* watchToggleSelectedMaterial() {
  yield takeEvery(TOGGLE_SELECTED_MATERIAL, toggleSelectedMaterial);
}
