import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  locationChange,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),

  FETCH_DISCUSSION_MEMBER_INVITES_START: fetchItemsStart,
  FETCH_DISCUSSION_MEMBER_INVITES_SUCCESS: fetchItemsSuccess,
  FETCH_DISCUSSION_MEMBER_INVITES_ERROR: fetchItemsError
});
