export default {
  change_storage_class: 'Change storage class',
  glacier: 'Glacier',
  no: 'No s3 objects',
  plural: 'S3 objects',
  singular: 'S3 object',
  storage_pricing: 'Storage pricing',
  storage_class: 'Storage class',
  storage_classes: {
    STANDARD: 'S3 Standard',
    STANDARD_IA: 'S3 Standard - Infrequent Access',
    ONEZONE_IA: 'S3 One Zone - Infrequent Access',
    GLACIER: 'S3 Glacier',
    INTELLIGENT_TIERING: 'S3 Intelligent - Tiering',
    DEEP_ARCHIVE: 'S3 Glacier Deep Archive'
  },
  update_s3: 'Update s3'
};
