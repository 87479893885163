import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  REMOVE_USERS_FROM_PROJECT,
  REMOVE_USERS_FROM_PROJECT_START,
  REMOVE_USERS_FROM_PROJECT_SUCCESS,
  REMOVE_USERS_FROM_PROJECT_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* removeUsersFromProject({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_USERS_FROM_PROJECT_START, id, options));

  const removeUsersFromProjectAction = yield spawn(postItemResource, `tasks/${id}/remove_members`, {
    success: REMOVE_USERS_FROM_PROJECT_SUCCESS,
    error: REMOVE_USERS_FROM_PROJECT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeUsersFromProjectAction);
}

export default function* watchRemoveUsersFromProject() {
  yield takeEvery(REMOVE_USERS_FROM_PROJECT, removeUsersFromProject);
}
