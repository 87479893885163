import React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import insertText from 'insert-text-textarea';
import TextareaAutosize from 'react-textarea-autosize';
import TurndownService from 'turndown';

import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withHandlers from 'recompose/withHandlers';

import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import memoize from 'lodash/memoize';
import replace from 'lodash/replace';

import { translate as t } from '../../utils';

const hiddenStyleMapper = memoize(hidden => ({ display: hidden ? 'none' : null }));

const turndownService = new TurndownService().addRule('strikethrough', {
  filter: ['del', 's', 'strike'],
  replacement: content => `~~${content}~~`
});

function renderTextareaField({
  input, label, placeholder, max, cols, rows, maxRows, helpBlock, required, hidden, hideError,
  id, className, labelClass, addFormGroupClass, autoFocus, disabled, handleChange,
  meta: { touched, error, warning }, handlePasteAsMarkdown, pasteAsMarkdown
}) {
  return (
    <div
      className={cl('form-group', addFormGroupClass, {
        'has-success': touched && !error && !warning,
        'has-danger': touched && error,
        'has-warning': touched && warning
      })}
    >
      {label ? <label className={labelClass} htmlFor={`form-input-${id || input.name}`}>{label}</label> : null}
      <TextareaAutosize
        id={`form-input-${id || input.name}`}
        className={className || 'form-control'}
        disabled={disabled}
        required={required}
        style={hiddenStyleMapper(hidden)}
        {...input}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        cols={cols}
        minRows={rows}
        maxRows={maxRows}
        onPaste={pasteAsMarkdown ? handlePasteAsMarkdown : null}
      />
      {max ? (
        <div className="help-block">
          {`${input.value ? input.value.length : 0} of ${max}${helpBlock ? helpBlock(input.value) : ''}`}
        </div>
      ) : null}
      {touched && error && !hideError && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          {/^forms\.errors+/.test(error) ? t(error) : error}
        </span>
      )}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderTextareaField.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  addFormGroupClass: PropTypes.string,
  autoFocus: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  id: PropTypes.string,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  hideError: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  cols: PropTypes.number,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  pasteAsMarkdown: PropTypes.bool,
  helpBlock: PropTypes.func,
  afterChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handlePasteAsMarkdown: PropTypes.func.isRequired
};

renderTextareaField.defaultProps = {
  id: null,
  autoFocus: false,
  className: null,
  labelClass: null,
  addFormGroupClass: null,
  label: null,
  hidden: false,
  disabled: false,
  required: false,
  placeholder: null,
  max: null,
  cols: null,
  rows: 2,
  maxRows: null,
  helpBlock: null,
  pasteAsMarkdown: false,
  afterChange: null,
  hideError: false
};

export default compose(
  withHandlers({
    handleChange: ({ max, input, afterChange }) => e => {
      const value = isNumber(max) ? e.target.value.slice(0, max) : e.target.value;
      input.onChange(value);
      if (afterChange) {
        afterChange(value);
      }
    },
    handlePasteAsMarkdown: ({ input, id }) => e => {
      if (includes(e.clipboardData.types, 'text/rtf')) {
        return;
      }

      const htmlToPaste = e.clipboardData.getData('text/html');

      if (htmlToPaste) {
        e.preventDefault();
        const markdownToPaste = replace(turndownService.turndown(htmlToPaste), /(?=<!--)([\s\S]*?)-->/g, '');
        // document.execCommand('insertText', false, markdownToPaste); // doesn't work in Firefox
        insertText(document.querySelector(`#form-input-${id || input.name}`), markdownToPaste);
      }
    }
  }),
  pure
)(renderTextareaField);
