import get from 'lodash/get';

export function taskVisibilityStatusChannelHidden(state, { data: hidden }) {
  return {
    ...state,
    item:
      get(state, 'item.visible_for_client') === !hidden
        ? state.item
        : { ...state.item, visible_for_client: !hidden }
  };
}

export function taskVisibilityStatusChannelRed(state, { data: red }) {
  return {
    ...state,
    item:
      get(state, 'item.red') === red ? state.item : { ...state.item, red: red }
  };
}
