import { takeEvery, put, spawn, cancel, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  REMOVE_TASK_RESULTS,
  REMOVE_TASK_RESULTS_START,
  REMOVE_TASK_RESULTS_SUCCESS,
  REMOVE_TASK_RESULTS_ERROR
} from '../TaskConstants';
import { postItemStart, postItemResource } from '../../common/sagas';

function* removeTaskResults({ id, values, options, cb }) {
  yield put(postItemStart(REMOVE_TASK_RESULTS_START, id, options));

  const removeTaskResultsAction = yield spawn(postItemResource, `discussions/${id}/remove_results`, {
    success: REMOVE_TASK_RESULTS_SUCCESS,
    error: REMOVE_TASK_RESULTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(removeTaskResultsAction);
}

export default function* watchRemoveTaskResults() {
  yield takeEvery(REMOVE_TASK_RESULTS, removeTaskResults);
}
