import { updateState } from '../../../utils';

export function acceptLinkedAccountRequestStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null
  }, options);
}

export function acceptLinkedAccountRequestSuccess(state, { options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null
  }, options);
}

export function acceptLinkedAccountRequestError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage
  }, options);
}
