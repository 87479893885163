import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const TagShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_tags" */ './pages/TagShowPage/TagShowPage'
    )
});
const TagsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_tags" */ './pages/TagsIndexPage/TagsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/admin/tags/:tagId(\d+)" component={TagShowPage} />
    <Route exact path="/admin/tags" component={TagsIndexPage} />
  </Fragment>
);
