import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const AllocationShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_allocations" */ './pages/AllocationShowPage/AllocationShowPage'
    )
});
const AllocationsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_allocations" */ './pages/AllocationsIndexPage/AllocationsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/allocations/:allocationId(\d+)"
      component={AllocationShowPage}
    />
    <Route exact path="/admin/allocations" component={AllocationsIndexPage} />
  </Fragment>
);
