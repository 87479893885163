import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  clearModalValues
} from '../../common/reducers';

import {
  submitProjectProfileFileAttachmentsStart,
  submitProjectProfileFileAttachmentsSuccess,
  submitProjectProfileFileAttachmentsError
} from './submitProjectProfileFileAttachments';

import {
  submitProjectProfileSelectedProductsStart,
  submitProjectProfileSelectedProductsSuccess,
  submitProjectProfileSelectedProductsError
} from './submitProjectProfileSelectedProducts';

import {
  submitProjectProfileSelectedTemplatesStart,
  submitProjectProfileSelectedTemplatesSuccess,
  submitProjectProfileSelectedTemplatesError
} from './submitProjectProfileSelectedTemplates';

export default createReducer(defaultState, {
  FETCH_PROJECT_PROFILES_START: fetchItemsStart,
  FETCH_PROJECT_PROFILES_SUCCESS: fetchItemsSuccess,
  FETCH_PROJECT_PROFILES_ERROR: fetchItemsError,

  FETCH_PROJECT_PROFILE_START: fetchItemStart,
  FETCH_PROJECT_PROFILE_SUCCESS: fetchItemSuccess,
  FETCH_PROJECT_PROFILE_ERROR: fetchItemError,

  UPDATE_PROJECT_PROFILE_START: updateItemStart,
  UPDATE_PROJECT_PROFILE_SUCCESS: updateItemSuccess,
  UPDATE_PROJECT_PROFILE_ERROR: updateItemError,

  REMOVE_PROJECT_PROFILE_START: removeItemStart,
  REMOVE_PROJECT_PROFILE_SUCCESS: removeItemSuccess,
  REMOVE_PROJECT_PROFILE_ERROR: removeItemError,

  CLEAR_PROJECT_PROFILE_MODAL_VALUES: clearModalValues,

  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_START: submitProjectProfileFileAttachmentsStart,
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_SUCCESS: submitProjectProfileFileAttachmentsSuccess,
  SUBMIT_PROJECT_PROFILE_FILE_ATTACHMENTS_ERROR: submitProjectProfileFileAttachmentsError,

  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_START: submitProjectProfileSelectedProductsStart,
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS: submitProjectProfileSelectedProductsSuccess,
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR: submitProjectProfileSelectedProductsError,

  SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_START: submitProjectProfileSelectedTemplatesStart,
  SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_SUCCESS: submitProjectProfileSelectedTemplatesSuccess,
  SUBMIT_PROJECT_PROFILE_SELECTED_TEMPLATES_ERROR: submitProjectProfileSelectedTemplatesError
});
