import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const ProductEditPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "products-edit" */ './pages/ProductEditPage/ProductEditPage'
    )
});
const ProductsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "products" */ './pages/ProductsIndexPage/ProductsIndexPage'
    )
});

export default (
  <Fragment>
    <Route exact path="/products/:productId(\d+)" component={ProductEditPage} />
    <Route exact path="/products" component={ProductsIndexPage} />
  </Fragment>
);
