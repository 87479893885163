import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPLOAD_MAX_FILES,
  UPLOAD_MAX_FILES_START,
  UPLOAD_MAX_FILE_START,
  UPLOAD_MAX_FILES_SUCCESS,
  UPLOAD_MAX_FILE_SUCCESS,
  UPLOAD_MAX_FILE_ERROR,
  UPLOAD_MAX_FILES_ERROR
} from '../MaxFileConstants';

import { uploadItemsResource } from '../../common/sagas';

function* uploadMaxFiles({ files, options, cb }) {
  const uploadMaxFilesAction = yield spawn(uploadItemsResource, 'max_files', {
    start: UPLOAD_MAX_FILES_START,
    eachStart: UPLOAD_MAX_FILE_START,
    success: UPLOAD_MAX_FILES_SUCCESS,
    eachSuccess: UPLOAD_MAX_FILE_SUCCESS,
    eachError: UPLOAD_MAX_FILE_ERROR,
    error: UPLOAD_MAX_FILES_ERROR,
    param: 'max_file'
  }, { files, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(uploadMaxFilesAction);
}

export default function* watchUploadMaxFiles() {
  yield takeEvery(UPLOAD_MAX_FILES, uploadMaxFiles);
}
