import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { useLinksWithPrefix } from './hooks/useLinksWithPrefix';
import { useLinksWithPermission } from './hooks/useLinksWithPermission';
import { useLinksWrapMore } from './hooks/useLinksWrapMore';

import LinkMenuItem from './LinkMenuItem';
import LinkMenuDropdown from './LinkMenuDropdown';

function LinkMenu({ currentUser, pathname, links, linkPrefix }) {
  const [linksWithPrefix] = useLinksWithPrefix(links, linkPrefix);
  const [linksWithPermission] = useLinksWithPermission(
    linksWithPrefix,
    currentUser
  );
  const [linksWithMore] = useLinksWrapMore(linksWithPermission);

  return (
    <ul className="navbar-nav navbar-nav-highlight">
      {linksWithMore.map(item => (
        <Fragment key={`link-menu-${item.namespace}-item`}>
          <li className="nav-item">
            {item.type === 'link' ? (
              <LinkMenuItem
                link={item}
                pathname={pathname}
                linkPrefix={linkPrefix}
              />
            ) : (
              <LinkMenuDropdown
                dropdownItem={item}
                pathname={pathname}
                linkPrefix={linkPrefix}
              />
            )}
          </li>
        </Fragment>
      ))}
    </ul>
  );
}

LinkMenu.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        ref: PropTypes.string,
        namespace: PropTypes.string,
        label: PropTypes.string
      }),
      PropTypes.shape({
        type: PropTypes.string,
        namespace: PropTypes.string,
        label: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            ref: PropTypes.string,
            namespace: PropTypes.string,
            label: PropTypes.string
          })
        )
      })
    ])
  ).isRequired,
  linkPrefix: PropTypes.string
};

LinkMenu.defaultProps = {
  linkPrefix: ''
};

export default memo(LinkMenu);
