import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_FINANCE_TAGS,
  FETCH_FINANCE_TAGS_START,
  FETCH_FINANCE_TAGS_SUCCESS,
  FETCH_FINANCE_TAGS_ERROR
} from '../FinanceTagConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchFinanceTags(params) {
  yield put(fetchItemsStart(FETCH_FINANCE_TAGS_START, params));

  const fetchFinanceTagsAction = yield spawn(fetchItemsResource, 'finance_user_tags', {
    success: FETCH_FINANCE_TAGS_SUCCESS,
    error: FETCH_FINANCE_TAGS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchFinanceTagsAction);
}

export default function* watchFetchFinanceTags() {
  yield takeEvery(FETCH_FINANCE_TAGS, fetchFinanceTags);
}
