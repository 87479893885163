import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ADD_USERS_GROUPS,
  ADD_USERS_GROUPS_START,
  ADD_USERS_GROUPS_SUCCESS,
  ADD_USERS_GROUPS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* addUsersGroups({ values, options, cb }) {
  yield put(postItemStart(ADD_USERS_GROUPS_START, options));

  return yield spawn(postItemResource, 'users/add_groups', {
    success: ADD_USERS_GROUPS_SUCCESS,
    error: ADD_USERS_GROUPS_ERROR
  }, { values, options, cb });
}

export default function* watchAddUsersGroups() {
  yield takeEvery(ADD_USERS_GROUPS, addUsersGroups);
}
