export default {
  no: 'No access denied errors',
  plural: 'Access denied errors',
  refresh: 'Refresh access denied errors list',
  singular: 'Access denied error',
  method_names: {
    get: 'GET',
    post: 'POST',
    patch: 'PATCH',
    delete: 'DELETE'
  }
};
