import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TEMPLATES_SET,
  FETCH_TEMPLATES_SET_START,
  FETCH_TEMPLATES_SET_SUCCESS,
  FETCH_TEMPLATES_SET_ERROR
} from '../TemplatesSetConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTemplatesSet({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TEMPLATES_SET_START, id, options));

  const fetchTemplatesSetAction = yield spawn(fetchItemResource, 'scenes_sets', {
    success: FETCH_TEMPLATES_SET_SUCCESS,
    error: FETCH_TEMPLATES_SET_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTemplatesSetAction);
}

export default function* watchFetchTemplatesSet() {
  yield takeEvery(FETCH_TEMPLATES_SET, fetchTemplatesSet);
}
