import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { withRouter } from 'react-router-dom';
import includes from 'lodash/includes';

import { BASE_TITLE, CRM_NAME } from '../../../config';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { DownloadsBlock } from '../../../main/downloads/components/DownloadsBlock';

import { HelpDropdown } from '../ts/HelpDropdown';
import { MainLinkMenu, UserMenu } from './components';
import { MessagesMenu } from '../../../helpers/MessagesMenu';
import { StockDropdown } from './components/StockDropdown';
import { LinkTo, PureButton } from '../../../helpers';

import { newLayoutRoutes } from '../../AppConstants';

const workerRegisterPath = '/workers/register';

function Header({
  auth,
  currentUser,
  authFetched,
  pathname,
  onToggleSidebarHidden,
  onToggleSidebarMobile,
  onLogout,
  history
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
  const collapse = useCallback(() => setIsOpen(false), []);
  const isNewLayout = includes(newLayoutRoutes, pathname);

  useEffect(() => {
    if (isOpen) {
      const unlisten = history.listen(collapse);
      return unlisten;
    }
  }, [history, collapse, isOpen]);

  return (
    <Fragment>
      <div className="navbar navbar-expand-md navbar-dark bg-indigo z-index-1030 overflow-anchor-none">
        <div className="navbar-brand wmin-0 p-0 mr-2">
          <LinkTo className="d-inline-block" title={BASE_TITLE} href="">
            {BASE_TITLE}
            {CRM_NAME === 'Cgise' ? (
              <svg width="50px" height="50px" viewBox="-32 -32 320 320">
                <g fill="#fff">
                  <polygon points="206.7,45.4 95.4,109.2 82.6,101.8 193.9,38 128,0 17.1,64 17.1,192 49.3,210.6 160.6,146.8 173.4,154.2 62.1,218 128,256 238.9,192 238.9,64"></polygon>
                </g>
              </svg>
            ) : null}
            {CRM_NAME === 'SmashingCGI' ? (
              <svg width="50px" height="50px" viewBox="-32 -32 320 320">
                <g fill="#fff">
                  <polygon points="206.7,45.4 95.4,109.2 82.6,101.8 193.9,38 128,0 17.1,64 17.1,192 49.3,210.6 160.6,146.8 173.4,154.2 62.1,218 128,256 238.9,192 238.9,64"></polygon>
                </g>
              </svg>
            ) : null}
            {CRM_NAME === 'Archivizer' ? (
              <svg id="av-logo" width="50" height="50">
                <g fillRule="evenodd" clipRule="evenodd" fill="#fff">
                  <path d="M11.077,36.786c-0.061,0.396-0.403,0.673-0.799,0.665l-3.546,0 c-0.323,0-0.595-0.245-0.628-0.566c-0.003-0.023-0.005-0.047-0.003-0.07c0-0.066,0.011-0.129,0.03-0.188l5.444-20.388 c0.579-2.168,2.537-3.692,4.789-3.692c2.252,0,4.21,1.524,4.789,3.692l5.443,20.384c0.021,0.063,0.031,0.126,0.031,0.192 c0.002,0.023,0,0.047-0.003,0.07c-0.033,0.321-0.305,0.568-0.629,0.566l-3.543,0c-0.399,0.008-0.741-0.269-0.802-0.665 L16.83,17.491c-0.004-0.012-0.007-0.023-0.012-0.035c-0.003-0.009-0.005-0.017-0.007-0.026c-0.034-0.221-0.224-0.384-0.448-0.384 c-0.223,0-0.414,0.163-0.448,0.384c-0.001,0.009-0.004,0.017-0.007,0.026c-0.004,0.012-0.008,0.023-0.011,0.032L11.077,36.786z" />
                  <path d="M28.349,13.214c-0.061-0.396-0.403-0.673-0.799-0.665l-3.546,0 c-0.323,0-0.595,0.245-0.628,0.566c-0.004,0.023-0.005,0.047-0.003,0.07c0,0.066,0.011,0.129,0.03,0.188l5.444,20.388 c0.579,2.168,2.537,3.692,4.789,3.692c2.252,0,4.21-1.524,4.789-3.692l5.443-20.384c0.021-0.063,0.031-0.126,0.031-0.192 c0.002-0.023,0-0.047-0.003-0.07c-0.033-0.321-0.305-0.568-0.629-0.566l-3.543,0c-0.399-0.008-0.741,0.269-0.802,0.665 l-4.821,19.295c-0.004,0.012-0.007,0.023-0.012,0.035c-0.003,0.009-0.005,0.017-0.007,0.026c-0.034,0.221-0.224,0.384-0.448,0.384 c-0.223,0-0.414-0.163-0.448-0.384c-0.001-0.009-0.004-0.017-0.007-0.026c-0.004-0.012-0.008-0.023-0.011-0.032L28.349,13.214z" />
                </g>
              </svg>
            ) : null}
          </LinkTo>
        </div>

        {auth || pathname === workerRegisterPath ? (
          <Fragment>
            <div className="d-md-none">
              <PureButton
                className="navbar-toggler"
                icon="icon-paragraph-justify3"
                onClick={toggle}
              />
            </div>
            <div
              className={cl(
                'collapse',
                'navbar-collapse',
                isOpen ? 'show' : null
              )}
              id="navbar-mobile"
            >
              <ul className="navbar-nav navbar-nav-highlight ml-md-auto">
                <li className="nav-item">
                  <HelpDropdown withGuideButton />
                </li>
              </ul>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="d-md-none">
              <PureButton
                className="navbar-toggler"
                icon="icon-paragraph-justify3"
                onClick={toggle}
              />
              {isNewLayout ? null : (
                <PureButton
                  className={cl(
                    'navbar-toggler sidebar-mobile-component-toggle',
                    {
                      collapsed: !isOpen
                    }
                  )}
                  icon="icon-unfold"
                  onClick={onToggleSidebarMobile}
                />
              )}
            </div>
            {authFetched ? (
              <div
                className={cl(
                  'collapse',
                  'navbar-collapse',
                  isOpen ? 'show' : null
                )}
                id="navbar-mobile"
              >
                <MainLinkMenu currentUser={currentUser} pathname={pathname} />
                <LinkTo
                  pureLink
                  href="#"
                  className="navbar-nav-link sidebar-control sidebar-component-toggle d-none d-md-block"
                  icon="icon-transmission"
                  onClick={onToggleSidebarHidden}
                />

                <ul className="navbar-nav navbar-nav-highlight ml-md-auto">
                  <li className="nav-item">
                    <StockDropdown
                      currentUser={currentUser}
                      pathname={pathname}
                    />
                  </li>
                  <li className="nav-item">
                    <HelpDropdown withGuideButton />
                  </li>
                  <MessagesMenu />
                  <UserMenu onLogout={onLogout} />
                </ul>
              </div>
            ) : null}
          </Fragment>
        )}
      </div>
      {auth || pathname === workerRegisterPath ? null : (
        <Fragment>
          {authFetched &&
          currentUser.hasPermissions('io_dropdown_downloads_created') ? (
            <DownloadsBlock />
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
}

Header.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  auth: PropTypes.bool,
  authFetched: PropTypes.bool,
  pathname: PropTypes.string,
  onToggleSidebarHidden: PropTypes.func,
  onToggleSidebarMobile: PropTypes.func,
  onLogout: PropTypes.func,
  history: PropTypes.shape({ listen: PropTypes.func.isRequired }).isRequired
};

Header.defaultProps = {
  pathname: null,
  auth: false,
  authFetched: false,
  onToggleSidebarHidden: null,
  onToggleSidebarMobile: null,
  onLogout: null
};

export default withCurrentUser()(withRouter(Header));
