import Promise from 'bluebird';
import nanoid from 'nanoid';

import { authLoading, authStopLoading, authSuccessMessage, authError } from '../AuthActions';

import { translate as t } from '../../utils';
import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';
import { removeAuthToken } from '../../utils/ts/authToken';
import { sendAuthErrorToSentry } from '../../utils/ts/sendAuthErrorToSentry';

// sendPassword

export default function sendPassword(auth) {
  removeAuthToken();

  return dispatch => {
    dispatch(authLoading());

    const frRequestId = nanoid();

    return apiRequest
      .authPost(
        'auth/forgot_password',
        { auth },
        { headers: { 'fr-request-id': frRequestId } }
      )
      .then(() => {
        dispatch(authStopLoading());
        dispatch(authSuccessMessage(t('pages.reset_password.success_message')));
        setTimeout(() => dispatch(authSuccessMessage()), 5000);
        return Promise.resolve();
      })
      .catch(error => {
        sendAuthErrorToSentry({
          error,
          auth,
          tag: 'send-password',
          frRequestId
        });
        return mapErrorsToForm(dispatch, error, authError(error));
      });
  };
}
