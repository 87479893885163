import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_START,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_ERROR
} from '../EmailTemplateConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateEmailTemplate({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_EMAIL_TEMPLATE_START, id, options));

  return yield spawn(updateItemResource, 'email_templates', {
    success: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    error: UPDATE_EMAIL_TEMPLATE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateEmailTemplate() {
  yield takeEvery(UPDATE_EMAIL_TEMPLATE, updateEmailTemplate);
}
