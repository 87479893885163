import { replaceById, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function acceptInviteToTaskStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function acceptInviteToTaskSuccess(state, { id, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    items: replaceById(currentStateValue(state, 'items', options), id, i => ({ ...i, loading: false }))
  }, options);
}

export function acceptInviteToTaskError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}

// import { replaceById } from '../../../utils';
//
// export function acceptInviteToTaskStart(state, { id }) {
//   return {
//     ...state,
//     isLoading: false,
//     items: replaceById(state.items, id, i => ({ ...i, loading: false }))
//   };
// }
//
// export function acceptInviteToTaskSuccess(state, { id }) {
//   return {
//     ...state,
//     items: replaceById(state.items, id, i => ({ ...i, selected: !i.selected }))
//   };
// }
//
// export function acceptInviteToTaskError(state, { id, errorMessage }) {
//   return {
//     ...state,
//     isLoading: false,
//     items: replaceById(state.items, id, i => ({ ...i, loading: false })),
//     errorMessage
//   };
// }
