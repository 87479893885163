export default {
  'select_category...': 'Select product category...',
  add_new_to_the_library: 'Add new product to the library',
  add_plural: 'Add products',
  add: 'Add a product',
  category: 'Product category',
  details: 'Product details',
  edit: 'Edit product',
  enter_title: 'Enter product title',
  image: 'Product image',
  library: 'Products Library',
  model: 'Product 3d model',
  new: 'New product',
  no: 'No products',
  plural_lower_case: 'products',
  plural: 'Products',
  selected: 'Selected products',
  singular_lower_case_accusative: 'product',
  singular: 'Product',
  title: 'Product title'
};
