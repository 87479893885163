import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPDATE_REVENUE_SHARE_ITEMS,
  UPDATE_REVENUE_SHARE_ITEMS_START,
  UPDATE_REVENUE_SHARE_ITEMS_SUCCESS,
  UPDATE_REVENUE_SHARE_ITEMS_ERROR
} from '../RevenueShareItemConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* updateRevenueShareItems({ values, options, cb }) {
  yield put(postItemsStart(UPDATE_REVENUE_SHARE_ITEMS_START, options));

  const updateRevenueShareItemsAction = yield spawn(postItemsResource, 'revenue_share_items/update_percentages', {
    success: UPDATE_REVENUE_SHARE_ITEMS_SUCCESS,
    error: UPDATE_REVENUE_SHARE_ITEMS_ERROR
  }, {
    values,
    options: { successMessage: 'words.records_updated', ...options },
    cb
  });

  yield take(LOCATION_CHANGE);
  yield cancel(updateRevenueShareItemsAction);
}

export default function* watchUpdateRevenueShareItems() {
  yield takeEvery(UPDATE_REVENUE_SHARE_ITEMS, updateRevenueShareItems);
}
