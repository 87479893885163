import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REVISION_TASK_RESULTS,
  REVISION_TASK_RESULTS_START,
  REVISION_TASK_RESULTS_SUCCESS,
  REVISION_TASK_RESULTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* revisionTaskResults({ id, values, options, cb }) {
  yield put(postItemStart(REVISION_TASK_RESULTS_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/revision_results`, {
    success: REVISION_TASK_RESULTS_SUCCESS,
    error: REVISION_TASK_RESULTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchRevisionTaskResults() {
  yield takeEvery(REVISION_TASK_RESULTS, revisionTaskResults);
}
