import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { LinkTo } from '../../../../helpers/LinkTo';
import { Avatar } from '../../../../helpers/Avatar';
import { withCurrentUser } from '../../../common/HOCs/withCurrentUser';
import { translate as t } from '../../../../utils';

/**
 * @return {null}
 */
function UserLink({ icon, currentUser, user, avatar, className, avatarClassName, avatarStyle, i18nText, hideIfNoPermission }) {
  const systemProfileWithoutPermissions =
    get(user, 'system_account', false) &&
    !currentUser.hasPermissions('read_system_profile_link');

  if (
    currentUser.hasPermissions('read_user_profile') &&
    !systemProfileWithoutPermissions
  ) {
    return (
      <LinkTo
        href={`users/${get(user, 'id')}`}
        className={className}
        icon={icon}
        target={
          currentUser.hasPermissions('open_user_profile_in_new_tab')
            ? '_blank'
            : undefined
        }
      >
        {avatar ? (
          <Avatar
            className={avatarClassName || 'rounded-circle'}
            width="36"
            height="36"
            version="thumb_150x150"
            title={get(user, 'full_name')}
            image={get(user, 'image')}
            style={avatarStyle}
          />
        ) : (
          <Fragment>
            {i18nText ? t(i18nText) : get(user, 'full_name')}
          </Fragment>
        )}
      </LinkTo>
    );
  }

  if (hideIfNoPermission) {
    return null;
  }

  if (avatar) {
    return (
      <Avatar
        className={avatarClassName || 'rounded-circle'}
        width="36"
        height="36"
        version="thumb_150x150"
        title={get(user, 'full_name')}
        image={get(user, 'image')}
        style={avatarStyle}
      />
    );
  }

  return i18nText ? t(i18nText) : get(user, 'full_name');
}

UserLink.propTypes = {
  hideIfNoPermission: PropTypes.bool,
  i18nText: PropTypes.string,
  icon: PropTypes.string,
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.string.isRequired
    })
  }).isRequired,
  avatar: PropTypes.bool,
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  avatarStyle: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired
  })
};

UserLink.defaultProps = {
  hideIfNoPermission: false,
  i18nText: null,
  icon: null,
  avatar: false,
  className: null,
  avatarClassName: null,
  avatarStyle: null
};

export default withCurrentUser()(UserLink);
