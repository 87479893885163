export const FETCH_BALANCES = 'FETCH_BALANCES';
export const FETCH_BALANCES_START = 'FETCH_BALANCES_START';
export const FETCH_BALANCES_SUCCESS = 'FETCH_BALANCES_SUCCESS';
export const FETCH_BALANCES_ERROR = 'FETCH_BALANCES_ERROR';

export const FETCH_BALANCE = 'FETCH_BALANCE';
export const FETCH_BALANCE_START = 'FETCH_BALANCE_START';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_ERROR = 'FETCH_BALANCE_ERROR';

export const RECALCULATE_BALANCE_ACCOUNT = 'RECALCULATE_BALANCE_ACCOUNT';
export const RECALCULATE_BALANCE_ACCOUNT_START = 'RECALCULATE_BALANCE_ACCOUNT_START';
export const RECALCULATE_BALANCE_ACCOUNT_SUCCESS = 'RECALCULATE_BALANCE_ACCOUNT_SUCCESS';
export const RECALCULATE_BALANCE_ACCOUNT_ERROR = 'RECALCULATE_BALANCE_ACCOUNT_ERROR';

export const RECALCULATE_BALANCES = 'RECALCULATE_BALANCES';
export const RECALCULATE_BALANCES_START = 'RECALCULATE_BALANCES_START';
export const RECALCULATE_BALANCES_SUCCESS = 'RECALCULATE_BALANCES_SUCCESS';
export const RECALCULATE_BALANCES_ERROR = 'RECALCULATE_BALANCES_ERROR';

export const CLEAR_BALANCE_FILTERS_START = 'CLEAR_BALANCE_FILTERS_START';
export const CLEAR_BALANCE_FILTERS_SUCCESS = 'CLEAR_BALANCE_FILTERS_SUCCESS';
