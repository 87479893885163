import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_MATERIALS,
  FETCH_MATERIALS_START,
  FETCH_MATERIALS_SUCCESS,
  FETCH_MATERIALS_ERROR
} from '../MaterialConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchMaterials(params) {
  yield put(fetchItemsStart(FETCH_MATERIALS_START, params));

  const fetchMaterialsAction = yield spawn(fetchItemsResource, 'materials', {
    success: FETCH_MATERIALS_SUCCESS,
    error: FETCH_MATERIALS_ERROR
  }, params);

  // yield take(FETCH_MATERIALS_START);
  // yield cancel(fetchMaterialsAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchMaterialsAction);
}

export default function* watchFetchMaterials() {
  yield takeEvery(FETCH_MATERIALS, fetchMaterials);
}
