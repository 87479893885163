export const AUTH_USER = 'AUTH_USER';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_STOP_LOADING = 'AUTH_STOP_LOADING';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUCCESS_MESSAGE = 'AUTH_SUCCESS_MESSAGE';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// export const INIT_WEBSOCKETS = 'INIT_WEBSOCKETS';
// export const INIT_WEBSOCKETS_START = 'INIT_WEBSOCKETS_START';
// export const INIT_WEBSOCKETS_SUCCESS = 'INIT_WEBSOCKETS_SUCCESS';
// export const INIT_WEBSOCKETS_ERROR = 'INIT_WEBSOCKETS_ERROR';
// export const INIT_WEBSOCKETS_OPEN = 'INIT_WEBSOCKETS_OPEN';
// export const INIT_WEBSOCKETS_CLOSE = 'INIT_WEBSOCKETS_CLOSE';
// export const IO_RECEIVED_DATA = 'IO_RECEIVED_DATA';

export const INIT_ACTION_CABLE_SUCCESS = 'INIT_ACTION_CABLE_SUCCESS';

export const REGISTER_WORKER_FIRST_STAGE_FORM = 'REGISTER_WORKER_FIRST_STAGE_FORM';
