import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SUBMIT_PROJECT_FILE_ATTACHMENTS,
  SUBMIT_PROJECT_FILE_ATTACHMENTS_START,
  SUBMIT_PROJECT_FILE_ATTACHMENTS_SUCCESS,
  SUBMIT_PROJECT_FILE_ATTACHMENTS_ERROR
} from '../ProjectConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitProjectFileAttachments({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_PROJECT_FILE_ATTACHMENTS_START, id, options));

  const submitProjectFileAttachmentsAction = yield spawn(postItemResource, `tasks/${id}/submit_file_attachments`, {
    success: SUBMIT_PROJECT_FILE_ATTACHMENTS_SUCCESS,
    error: SUBMIT_PROJECT_FILE_ATTACHMENTS_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(submitProjectFileAttachmentsAction);
}

export default function* watchSubmitProjectFileAttachments() {
  yield takeEvery(SUBMIT_PROJECT_FILE_ATTACHMENTS, submitProjectFileAttachments);
}
