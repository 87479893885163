import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR
} from '../MessageConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchMessages(params) {
  yield put(fetchItemsStart(FETCH_MESSAGES_START, params));

  const fetchMessagesAction = yield spawn(fetchItemsResource, 'discussion_messages', {
    success: FETCH_MESSAGES_SUCCESS,
    error: FETCH_MESSAGES_ERROR
  }, params);

  // yield take(FETCH_MESSAGES_START);
  // yield cancel(fetchMessagesAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchMessagesAction);
}

export default function* watchFetchMessages() {
  yield takeEvery(FETCH_MESSAGES, fetchMessages);
}
