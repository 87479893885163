import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { AlertMessage } from '../../../../../helpers';
import { ModalButton } from '../../../../../helpers/ModalButton';

class ConfirmModal extends PureComponent {
  static propTypes = {
    afterSubmit: PropTypes.func,
    modalTitle: PropTypes.node,
    className: PropTypes.string,
    addClass: PropTypes.string,
    buttonText: PropTypes.node,
    submitText: PropTypes.node,
    cancelI18nText: PropTypes.string,
    bodyText: PropTypes.node,
    modalIcon: PropTypes.string,
    buttonIcon: PropTypes.string,
    buttonColor: PropTypes.string,
    submitColor: PropTypes.string,
    buttonSize: PropTypes.string,
    modalSize: PropTypes.string,
    tooltip: PropTypes.shape({
      target: PropTypes.string.isRequired,
      value: PropTypes.node,
      i18nText: PropTypes.node,
      placement: PropTypes.string
    }),
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    link: PropTypes.bool
  };

  static defaultProps = {
    afterSubmit: null,
    modalTitle: null,
    className: null,
    addClass: null,
    buttonText: null,
    submitText: null,
    cancelI18nText: null,
    bodyText: null,
    modalIcon: null,
    buttonIcon: null,
    buttonColor: null,
    submitColor: null,
    buttonSize: null,
    modalSize: null,
    tooltip: null,
    isLoading: false,
    errorMessage: null,
    onClose: null,
    link: false
  };

  handleSubmit = ({ hideModal }) => () =>
    this.props.onSubmit(() => {
      hideModal();
      this.props.afterSubmit && this.props.afterSubmit();
    });

  render() {
    const {
      className, addClass, buttonText, submitText, cancelI18nText, bodyText, buttonIcon, modalIcon, buttonColor,
      submitColor, buttonSize, modalSize, tooltip, isLoading, modalTitle, onClose, link
    } = this.props;

    return (
      <ModalButton
        className={className}
        addClass={addClass}
        size={buttonSize}
        modalSize={modalSize}
        modalTitle={modalTitle}
        modalIcon={modalIcon}
        icon={buttonIcon}
        submitColor={submitColor}
        tooltip={tooltip}
        isLoading={isLoading}
        disabled={isLoading}
        submitDisabled={isLoading}
        buttonText={buttonText}
        submitText={submitText}
        cancelI18nText={cancelI18nText}
        color={buttonColor}
        onSubmit={this.handleSubmit}
        onClose={onClose}
        link={link}
      >
        {bodyText}
        <AlertMessage>{this.props.errorMessage}</AlertMessage>
      </ModalButton>
    );
  }
}

export default ConfirmModal;
