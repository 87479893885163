import { put, call } from 'redux-saga/effects';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function downloadItemsStart(type, options = {}) {
  return {
    type,
    options
  };
}

export function downloadItemsSuccess(type, options = {}) {
  return {
    type,
    options
  };
}

export function downloadItemsError(type, error, options = {}) {
  return {
    type,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function downloadItemsRequest(path, { page, pageSize, filters, persistFilters, serializer, withoutPagination, withoutTotal }) {
  return apiRequest.download(path, {
    page: page || 1,
    page_size: (withoutPagination ? 1000 : pageSize) || 50,
    filters,
    persist_filters: persistFilters,
    serializer,
    ...(withoutTotal ? { without_total: true } : {})
  }, null, { method: 'GET' });
}

export function* downloadItemsResource(path, { success, error: errorConst }, {
  page, pageSize, filters, persistFilters, withoutPagination, withoutTotal, serializer, namespace, loadMore, cb
}) {
  try {
    yield call(downloadItemsRequest, path, { page, pageSize, filters, persistFilters, withoutPagination, withoutTotal, serializer });
    yield put(downloadItemsSuccess(success, { persistFilters, namespace, loadMore, serializer }));
    cb && cb();
  } catch (error) {
    yield put(downloadItemsError(errorConst, error, { namespace }));
  }
}
