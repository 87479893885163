import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function checkDownloadStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), id, download => ({
      ...download,
      warningMessage: null,
      loading: true
    }))
  }, options);
}

export function checkDownloadSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), id, download => ({
      ...download,
      warningMessage: null,
      loading: false
    }))
  }, options);
}

export function checkDownloadError(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), id, download => ({
      ...download,
      warningMessage: 'models.downloads.warnings.file_not_found',
      loading: false
    }))
  }, options);
}
