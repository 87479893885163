import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CREATE_REPORT,
  CREATE_REPORT_START,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR
} from '../ReportConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createReport({ values, options, cb }) {
  yield put(createItemStart(CREATE_REPORT_START, options));

  const createReportAction = yield spawn(createItemResource, 'reports', {
    success: CREATE_REPORT_SUCCESS,
    error: CREATE_REPORT_ERROR
  }, { values, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(createReportAction);
}

export default function* watchCreateReport() {
  yield takeEvery(CREATE_REPORT, createReport);
}
