import React, { useCallback } from 'react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { IsFetched, IsLoading } from '../../../../../../../../types';
import { BillType } from '../../../../../../../common/hooks/bills/types';

import { usePayBillFromAccount } from '../../../../../../../common/hooks/bills/usePayBillFromAccount';

import { CurrencyAmount } from '../../../../../../../../helpers/ts/CurrencyAmount';
import { PureButton } from '../../../../../../../../helpers/ts/PureButton';
import { StripeCheckoutButton } from '../../../../../../../../helpers/ts/StripeCheckoutButton';
import { TwoCheckoutButton } from '../../../../../../../bills/helpers/ts/TwoCheckoutButton';
import { analyticsSetBillCheckout } from '../../../../../../bills/utils/analyticsSetBillCheckout';

interface ItemBillsModalSubmitButtonsProps {
  addClass?: string;
  bill: BillType;
  billFetched: IsFetched;
  billLoading: IsLoading;
  onClose: () => void;
  onPayment?: () => void;
  submitIcon?: string;
  type: string;
}

function ItemBillsModalSubmitButtons({
  addClass,
  bill,
  billFetched,
  billLoading,
  onClose,
  onPayment,
  submitIcon,
  type
}: ItemBillsModalSubmitButtonsProps) {
  const {
    payBillFromAccount,
    payBillFromAccountLoading
  } = usePayBillFromAccount({
    id: get<BillType, 'id'>(bill, 'id')
  });

  const handlePayment = useCallback<() => void>(() => {
    if (type === 'card') {
      analyticsSetBillCheckout({ bill, step: 'click to pay' });
      return (
        get<BillType, 'payment_url'>(bill, 'payment_url') &&
        window.open(get<BillType, 'payment_url'>(bill, 'payment_url'))
      );
    }

    return payBillFromAccount().then(() => {
      onClose();
      onPayment && onPayment();
    });
  }, [bill, onClose, onPayment, payBillFromAccount, type]);

  if (
    type === 'card' &&
    get<BillType, 'button_type'>(bill, 'button_type') === 'twocheckout_button'
  ) {
    return (
      <TwoCheckoutButton
        addClass={addClass}
        bill={bill}
        disabled={!billFetched || billLoading}
        icon={submitIcon}
        isLoading={billLoading}
      />
    );
  }

  if (
    type === 'card' &&
    get<BillType, 'button_type'>(bill, 'button_type') === 'stripe_button'
  ) {
    return (
      <StripeCheckoutButton
        addClass={addClass}
        bill={bill}
        disabled={!billFetched || billLoading}
        icon={submitIcon}
        isLoading={billLoading}
        onSuccess={onClose}
      />
    );
  }

  return (
    <PureButton
      addClass={addClass}
      afterIcon={
        <CurrencyAmount usdAmount={get<BillType, 'amount'>(bill, 'amount')} />
      }
      color="primary"
      disabled={
        billLoading ||
        payBillFromAccountLoading ||
        (get<BillType, 'type'>(bill, 'type') === 'payoneer_bill' &&
          isNull(get<BillType, 'payment_url'>(bill, 'payment_url')))
      }
      i18nText={
        billLoading || payBillFromAccountLoading
          ? 'messages.processing'
          : 'words.pay'
      }
      icon={
        <b className="mr-2">
          <i className={submitIcon} />
        </b>
      }
      size="sm"
      onClick={handlePayment}
    />
  );
}

export default ItemBillsModalSubmitButtons;
