import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CANCEL_ALLOCATION_FROM_CLIENT_BALANCE,
  CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_START,
  CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS,
  CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* cancelAllocationFromClientBalance({ id, options, cb }) {
  yield put(postItemStart(CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/cancel_allocate_from_client_balance`, {
    success: CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_SUCCESS,
    error: CANCEL_ALLOCATION_FROM_CLIENT_BALANCE_ERROR
  }, { id, options, cb });
}

export default function* watchCancelAllocationFromClientBalance() {
  yield takeEvery(CANCEL_ALLOCATION_FROM_CLIENT_BALANCE, cancelAllocationFromClientBalance);
}
