export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_START = 'FETCH_CONTACTS_START';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FETCH_CONTACT_START = 'FETCH_CONTACT_START';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_ERROR = 'FETCH_CONTACT_ERROR';

export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_START = 'CREATE_CONTACT_START';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const CLEAR_CONTACTS_FILTERS_START = 'CLEAR_CONTACTS_FILTERS_START';
export const CLEAR_CONTACTS_FILTERS_SUCCESS = 'CLEAR_CONTACTS_FILTERS_SUCCESS';

export const CLEAR_CONTACT_MODAL_VALUES = 'CLEAR_CONTACT_MODAL_VALUES';

export const CONTACT_FORM = 'CONTACT_FORM';
