import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  APPROVE_TASK_RESULTS,
  APPROVE_TASK_RESULTS_START,
  APPROVE_TASK_RESULTS_SUCCESS,
  APPROVE_TASK_RESULTS_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* approveTaskResults({ id, values, options, cb }) {
  yield put(postItemStart(APPROVE_TASK_RESULTS_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/approve_results`, {
    success: APPROVE_TASK_RESULTS_SUCCESS,
    error: APPROVE_TASK_RESULTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchApproveTaskResults() {
  yield takeEvery(APPROVE_TASK_RESULTS, approveTaskResults);
}
