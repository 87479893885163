import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cl from 'classnames';

import { withCurrentUser } from '../../../common/HOCs/withCurrentUser';

import { ItemBillButtons } from '../../../common/components/buttons/ItemBillButtons';

import { ArchiMarkdown } from '../../../../helpers/ArchiMarkdown';

function MessageBody({ currentUser, className, item, onPayment, onCheckPayment, onCancel, onClearModalValues, afterCancel }) {
  if (get(item, 'bill')) {
    return (
      <div>
        <ItemBillButtons
          item={item.bill}
          onPayment={onPayment}
          onCheckPayment={onCheckPayment}
          onCancel={onCancel}
          onClearModalValues={onClearModalValues}
          afterCancel={afterCancel}
        />
      </div>
    );
  }

  const body = currentUser.hasPermissions('read_message_hash_tags') ? get(item, 'body') : get(item, 'body').replace(/##([^#\s]+)/g, '');

  return (
    <ArchiMarkdown
      id={get(item, 'id')}
      className={cl(className, 'markdown-message text-break-word', { 'archi-incoming-message-exist': get(item, 'incoming_messages_count') > 0 })}
      source={body}
      fromEmail={get(item, 'incoming_messages_count') > 0}
    />
  );
}

MessageBody.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onPayment: PropTypes.func.isRequired,
  onCheckPayment: PropTypes.func,
  onCancel: PropTypes.func,
  onClearModalValues: PropTypes.func,
  afterCancel: PropTypes.func
};

MessageBody.defaultProps = {
  className: null,
  onCheckPayment: null,
  onCancel: null,
  onClearModalValues: null,
  afterCancel: null
};

export default withCurrentUser()(MessageBody);
