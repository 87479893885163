import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS,
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_START,
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS,
  SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR
} from '../ProjectProfileConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* submitProjectProfileSelectedProducts({ id, values, options, cb }) {
  yield put(postItemStart(SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_START, id, options));

  return yield spawn(postItemResource, `task_profiles/${id}/submit_selected_facilities`, {
    success: SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_SUCCESS,
    error: SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchSubmitProjectProfileSelectedProducts() {
  yield takeEvery(SUBMIT_PROJECT_PROFILE_SELECTED_PRODUCTS, submitProjectProfileSelectedProducts);
}
