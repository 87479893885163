import { BillType } from '../types';

import {
  ShowFinanceHookOptions,
  useShowFinanceRequest
} from '../../base/useShowFinanceRequest';

const url = 'bills';

function useBill(options: ShowFinanceHookOptions) {
  const {
    item,
    itemFetched,
    itemErrorMessage,
    itemLoading,
    fetchItem,
    refetch,
    cancel
  } = useShowFinanceRequest<BillType>({ url, ...options });

  return {
    bill: item,
    billFetched: itemFetched,
    billErrorMessage: itemErrorMessage,
    billLoading: itemLoading,
    fetchBill: fetchItem,
    refetch,
    cancel
  };
}

export default useBill;
