import {
  CLEAR_CONTACTS_FILTERS_START,
  CLEAR_CONTACTS_FILTERS_SUCCESS
} from '../ContactConstants';

export function clearContactsFiltersStart(options = {}) {
  return {
    type: CLEAR_CONTACTS_FILTERS_START,
    options
  };
}

export function clearContactsFiltersSuccess(options = {}) {
  return {
    type: CLEAR_CONTACTS_FILTERS_SUCCESS,
    options
  };
}

export default function clearContactsFilters(options = {}) {
  return dispatch => {
    dispatch(clearContactsFiltersStart(options));
    setTimeout(() => dispatch(clearContactsFiltersSuccess(options)), 500);
  };
}
