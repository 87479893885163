import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TASK,
  UPDATE_TASK_START,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR
} from '../TaskConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTask({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TASK_START, id, options));

  return yield spawn(updateItemResource, 'discussions', {
    success: UPDATE_TASK_SUCCESS,
    error: UPDATE_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTask() {
  yield takeEvery(UPDATE_TASK, updateTask);
}
