import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getFromCookies(key: string) {
  return cookies.get(key);
}

export function saveToCookies(key: string, value: string, opts = {}) {
  cookies.set(key, value, opts);
}

export function removeFromCookies(key: string) {
  cookies.remove(key, { path: '/' });
}
