import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TASK_ALLOCATIONS,
  DOWNLOAD_TASK_ALLOCATIONS_START,
  DOWNLOAD_TASK_ALLOCATIONS_SUCCESS,
  DOWNLOAD_TASK_ALLOCATIONS_ERROR
} from '../TaskConstants';

import { downloadItemStart, downloadItemResource } from '../../common/sagas';

function* downloadTaskAllocations({ id, options, cb }) {
  yield put(downloadItemStart(DOWNLOAD_TASK_ALLOCATIONS_START, id, options));

  const downloadTaskAllocationsAction = yield spawn(downloadItemResource, `discussions/${id}/allocations`, {
    success: DOWNLOAD_TASK_ALLOCATIONS_SUCCESS,
    error: DOWNLOAD_TASK_ALLOCATIONS_ERROR
  }, { id, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTaskAllocationsAction);
}

export default function* watchDownloadTaskAllocations() {
  yield takeEvery(DOWNLOAD_TASK_ALLOCATIONS, downloadTaskAllocations);
}
