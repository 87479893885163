import {
  POST_DATA_ERROR,
  POST_DATA_INITIALIZE,
  POST_DATA_START,
  POST_DATA_SUCCESS
} from '../../constants';

interface PostDataRequestState<DataType> {
  data: DataType | null;
  dataErrorMessage: string | null;
  dataLoading: boolean;
}

export const initialState = {
  data: null,
  dataErrorMessage: null,
  dataLoading: false
};

export type PostDataRequestAction<DataType> =
  | {
      type: 'POST_DATA_INITIALIZE';
    }
  | {
      type: 'POST_DATA_START';
    }
  | {
      type: 'POST_DATA_SUCCESS';
      data: DataType;
    }
  | {
      type: 'POST_DATA_ERROR';
      dataErrorMessage: string | null;
    };

export type PostDataRequestReducerType<DataType> = (
  prevState: PostDataRequestState<DataType>,
  action: PostDataRequestAction<DataType>
) => PostDataRequestState<DataType>;

export function postDataRequestReducer<DataType>(
  state: PostDataRequestState<DataType>,
  action: PostDataRequestAction<DataType>
): PostDataRequestState<DataType> {
  switch (action.type) {
    case POST_DATA_INITIALIZE:
      return {
        ...initialState
      };

    case POST_DATA_START:
      return {
        ...state,
        dataLoading: true,
        dataErrorMessage: null
      };

    case POST_DATA_SUCCESS:
      return {
        ...state,
        dataLoading: false,
        dataErrorMessage: null,
        data: action.data
      };

    case POST_DATA_ERROR:
      return {
        ...state,
        dataLoading: false,
        dataErrorMessage: action.dataErrorMessage
      };

    default:
      return state;
  }
}
