import { updateState } from '../../../utils';

export function createLinkedAccountsStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null
  }, options);
}

export function createLinkedAccountsSuccess(state, { options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null
  }, options);
}

export function createLinkedAccountsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage
  }, options);
}
