import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function dropdownDownloadsChannelCreated(state, { data, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options, []), data.id, download => ({
      ...download,
      ...data
    }))
  }, options);
}

