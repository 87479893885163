import { useEffect, useCallback, useReducer, useState } from 'react';

import { Item } from '../../../../../types';

import {
  initialState,
  showFinanceRequestReducer,
  ShowFinanceRequestReducerType
} from './reducers/showFinanceRequestReducer';

import { sendShowFinanceRequest } from './utils/sendShowFinanceRequest';

interface ShowFinanceRequestOptions {
  id: string | number;
  url: string;
  serializer?: string;
  initFetch?: boolean;
}

export type ShowFinanceHookOptions = Omit<ShowFinanceRequestOptions, 'url'>;

interface ShowFinanceRequestConfig {
  serializer?: string;
}

export function useShowFinanceRequest<ItemType extends Item>({
  id,
  url,
  serializer,
  initFetch = true
}: ShowFinanceRequestOptions) {
  const [
    { item, itemErrorMessage, itemFetched, itemLoading },
    dispatch
  ] = useReducer<ShowFinanceRequestReducerType<ItemType>>(
    showFinanceRequestReducer,
    initialState
  );
  const [requestConfig] = useState<ShowFinanceRequestConfig>({
    serializer
  });

  const handleInitFetch = useCallback(
    () => sendShowFinanceRequest(`${url}/${id}`, requestConfig, dispatch),
    [url, id, requestConfig, dispatch]
  );

  useEffect(() => {
    if (initFetch) {
      handleInitFetch();
    }
  }, [initFetch, handleInitFetch]);

  return {
    item,
    itemFetched,
    itemErrorMessage,
    itemLoading,
    fetchItem: useCallback(
      (params = {}) =>
        sendShowFinanceRequest<ItemType>(
          `${url}/${id}`,
          {
            ...requestConfig,
            ...params
          },
          dispatch
        ),
      [url, id, requestConfig, dispatch]
    ),
    refetch: useCallback(
      (meta = {}) =>
        sendShowFinanceRequest<ItemType>(
          url,
          { ...requestConfig, ...meta },
          dispatch
        ),
      [url, requestConfig]
    ),
    cancel: useCallback(() => console.log('cancel'), [])
  };
}
