import React, { Fragment, useState, useCallback, useEffect } from 'react';
import cl from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { BASE_TITLE } from '../../../config';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { CurrentUser } from '../../../types';

import { CozyUserMenu } from './components/CozyUserMenu';
import { CozyLinkMenu } from './components/CozyLinkMenu';
import { HelpDropdown } from '../ts/HelpDropdown';

import { LinkTo } from '../../../helpers/ts/LinkTo';
import { PureButton } from '../../../helpers/ts/PureButton';

import cozyLogo from '../../../styles/base/images/icons/cozymakeover-logo-av.png';

interface CozyHeaderHocsProps {
  currentUser: CurrentUser;
}

interface CozyHeaderProps {
  auth?: boolean;
  authFetched?: boolean;
  pathname: string;
  onToggleSidebarHidden: () => void;
  onToggleSidebarMobile: () => void;
  onLogout: () => void;
}

function CozyHeader({
  auth,
  currentUser,
  authFetched,
  pathname,
  onToggleSidebarMobile,
  onLogout,
  history
}: CozyHeaderProps & CozyHeaderHocsProps & RouteComponentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
  const collapse = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (isOpen) {
      const unlisten = history.listen(collapse);
      return unlisten;
    }
  }, [history, collapse, isOpen]);

  return (
    <div className="navbar navbar-expand-md navbar-light shadow navbar-static">
      <div className="align-items-center d-flex mr-2 p-0 wmin-0">
        <LinkTo href="" className="d-inline-block" title={BASE_TITLE}>
          <img
            id="cozy-logo"
            src={cozyLogo}
            alt={BASE_TITLE}
            className="py-2"
            height="50"
          />
        </LinkTo>
      </div>

      {auth ? (
        <Fragment>
          <div className="d-md-none">
            <PureButton
              className="navbar-toggler"
              icon="icon-paragraph-justify3"
              onClick={toggle}
            />
          </div>
          <div
            className={cl(
              'collapse',
              'navbar-collapse',
              isOpen ? 'show' : null
            )}
            id="navbar-mobile"
          >
            <ul className="navbar-nav ml-md-auto">
              <li className="nav-item text-uppercase">
                <HelpDropdown />
              </li>
            </ul>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-md-none">
            <PureButton
              className="navbar-toggler"
              icon="icon-paragraph-justify3"
              onClick={toggle}
            />
            <PureButton
              className={cl('navbar-toggler sidebar-mobile-component-toggle', {
                collapsed: !isOpen
              })}
              icon="icon-unfold"
              onClick={onToggleSidebarMobile}
            />
          </div>
          {authFetched ? (
            <div
              className={cl(
                'collapse',
                'navbar-collapse',
                isOpen ? 'show' : null
              )}
              id="navbar-mobile"
            >
              <CozyLinkMenu currentUser={currentUser} pathname={pathname} />
              <ul className="navbar-nav ml-md-auto">
                <li className="nav-item text-uppercase">
                  <HelpDropdown />
                </li>
                <CozyUserMenu onLogout={onLogout} />
              </ul>
            </div>
          ) : null}
        </Fragment>
      )}
    </div>
  );
}

export default withCurrentUser()(withRouter(CozyHeader));
