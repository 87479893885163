import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  RESTORE_TASK,
  RESTORE_TASK_START,
  RESTORE_TASK_SUCCESS,
  RESTORE_TASK_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* restoreTask({ id, values, options, cb }) {
  yield put(postItemStart(RESTORE_TASK_START, id, options));

  return yield spawn(postItemResource, `discussions/${id}/restore`, {
    success: RESTORE_TASK_SUCCESS,
    error: RESTORE_TASK_ERROR
  }, { id, values, options, cb });
}

export default function* watchRestoreTask() {
  yield takeEvery(RESTORE_TASK, restoreTask);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   RESTORE_TASK,
//   RESTORE_TASK_START,
//   RESTORE_TASK_SUCCESS,
//   RESTORE_TASK_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function restoreTaskStart(id) {
//   return {
//     type: RESTORE_TASK_START,
//     id
//   };
// }
//
// export function restoreTaskSuccess(id) {
//   return {
//     type: RESTORE_TASK_SUCCESS,
//     id
//   };
// }
//
// export function restoreTaskError(id, error) {
//   return {
//     type: RESTORE_TASK_ERROR,
//     errorMessage: parseError(error),
//     id,
//     error
//   };
// }
//
// function restoreTaskRequest(taskId, task, options = {}) {
//   return apiRequest.post(`discussions/${taskId}/restore`, task, options);
// }
//
// function* restoreTaskResource({ taskId, task, options: { form, serializer }, cb }) {
//   try {
//     yield call(restoreTaskRequest, taskId, task, { serializer });
//     yield put(restoreTaskSuccess(taskId));
//     cb && cb();
//   } catch (error) {
//     yield put(restoreTaskError(taskId, error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* restoreTask({ taskId, options, cb }) {
//   yield put(restoreTaskStart(taskId));
//
//   return yield spawn(restoreTaskResource, { taskId, options, cb });
// }
//
// export default function* watchRestoreTask() {
//   yield takeEvery(RESTORE_TASK, restoreTask);
// }
