import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_USERS,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR
} from '../UserConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchUsers(params) {
  yield put(fetchItemsStart(FETCH_USERS_START, params));

  const fetchUsersAction = yield spawn(fetchItemsResource, 'users', {
    success: FETCH_USERS_SUCCESS,
    error: FETCH_USERS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(fetchUsersAction);
}

export default function* watchFetchUsers() {
  yield takeEvery(FETCH_USERS, fetchUsers);
}
