import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function CheckPermissions({ currentUser, action, children }) {
  if (currentUser.hasPermissions(action)) {
    return children;
  }

  return null;
}

CheckPermissions.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  action: PropTypes.string.isRequired
};

export default connect(({ auth: { currentUser } }) => ({ currentUser }))(CheckPermissions);
