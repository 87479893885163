import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import split from 'lodash/split';

import { BillCancelButton } from '../../../../bills/helpers/BillCancelButton';
import { BillCheckPaymentButton } from '../../../../bills/helpers/BillCheckPaymentButton';
import { ItemBillsModal } from '../../../../ts/common/components/modals/ItemBillsModal';

import { CurrencyAmount } from '../../../../../helpers/ts/CurrencyAmount';
import { Translate } from '../../../../../helpers/ts/Translate';

const buttons = [
  {
    type: 'card',
    icon: 'icon-credit-card',
    label: 'models.bills.payment_types.credit_card'
  },
  {
    type: 'account',
    icon: 'icon-coin-dollar',
    label: 'models.bills.payment_types.account'
  }
];

class ItemBillButtons extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      buttons: PropTypes.string,
      id: PropTypes.number,
      paid: PropTypes.bool,
      payable: PropTypes.bool,
      status: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    onPayment: PropTypes.func,
    onCancel: PropTypes.func,
    onCheckPayment: PropTypes.func,
    onClearModalValues: PropTypes.func,
    afterCancel: PropTypes.func
  };

  static defaultProps = {
    onPayment: null,
    onCheckPayment: null,
    onCancel: null,
    onClearModalValues: null,
    afterCancel: null
  };

  renderPaymentMethod() {
    switch (this.props.item.type) {
      case 'bonus_bill':
        return <Translate id="models.bills.payment_methods.bonus_bill" />;
      case 'cancel_bonus_bill':
        return (
          <Translate id="models.bills.payment_methods.cancel_bonus_bill" />
        );
      case 'debit_account_bill':
        return (
          <Translate id="models.bills.payment_methods.debit_account_bill" />
        );
      case 'cancel_debit_account_bill':
        return (
          <Translate id="models.bills.payment_methods.cancel_debit_account_bill" />
        );
      case 'system_account_bill':
        return (
          <Translate id="models.bills.payment_methods.system_account_bill" />
        );
      case 'task_bill':
        return <Translate id="models.bills.payment_methods.task_bill" />;
      case 'cancel_task_bill':
        return <Translate id="models.bills.payment_methods.cancel_task_bill" />;
      case 'client_account_bill':
        return <Translate id="models.bills.payment_methods.account" />;
      default:
        return <Translate id="models.bills.payment_methods.credit_card" />;
    }
  }

  renderSuccessMessage() {
    const { item } = this.props;

    return (
      <div className="card d-inline-block mb-2 mt-1">
        <div className="bg-light border-bottom card-header px-2 py-1">
          <i className="icon-checkmark mr-2 font-size-xs" />
          <Translate id="models.transactions.successful" />
        </div>
        <div className="card-body p-0">
          <table className="bg-white table table-borderless table-xs">
            <tbody>
              <tr>
                <td className="">
                  <span className="font-weight-bold">
                    <Translate id="words.paid" />:
                  </span>
                </td>
                <td className="">
                  <div className="font-weight-bold">
                    <CurrencyAmount usdAmount={get(item, 'amount', 0)} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="font-weight-semibold">
                    <Translate id="words.method" />:
                  </span>
                </td>
                <td>{this.renderPaymentMethod()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const {
      item,
      onPayment,
      onCheckPayment,
      onCancel,
      onClearModalValues,
      afterCancel
    } = this.props;

    if (item.paid && item.status === 'paid') {
      return this.renderSuccessMessage();
    }

    return (
      <div>
        {filter(buttons, button =>
          includes(split(item.buttons, '_'), button.type)
        ).map(paymentButton => (
          <ItemBillsModal
            addClass={cl('btn-sm btn-labeled btn-labeled-left my-1 mr-1', {
              'btn-light': item.status === 'canceled'
            })}
            afterIcon={<CurrencyAmount usdAmount={get(item, 'amount', 0)} />}
            billId={item.id}
            disabled={!item.payable}
            disabledButton={!item.payable}
            i18nButtonText="words.pay"
            icon={
              <b className="mr-2">
                <i className={paymentButton.icon} />
              </b>
            }
            key={`payment-button-${paymentButton.type}-${item.id}`}
            modalIcon={paymentButton.icon}
            onPayment={onPayment}
            submitIcon={paymentButton.icon}
            type={paymentButton.type}
            tooltipI18nValue={`models.bills.payment_types.${paymentButton.type}`}
            tooltipTarget={`bill-button-${paymentButton.type}-${item.id}`}
          />
        ))}
        {onCheckPayment ? (
          <BillCheckPaymentButton item={item} onCheckPayment={onCheckPayment} />
        ) : null}
        {onCancel ? (
          <BillCancelButton
            addClass="btn-light btn-sm btn-icon"
            item={item}
            onCancel={onCancel}
            afterCancel={afterCancel}
            onClearModalValues={onClearModalValues}
          />
        ) : null}
      </div>
    );
  }
}

export default ItemBillButtons;
