import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_LINKED_ACCOUNT,
  REMOVE_LINKED_ACCOUNT_START,
  REMOVE_LINKED_ACCOUNT_SUCCESS,
  REMOVE_LINKED_ACCOUNT_ERROR
} from '../LinkedAccountConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeLinkedAccount({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_LINKED_ACCOUNT_START, id, options));

  return yield spawn(removeItemResource, 'linked_users', {
    success: REMOVE_LINKED_ACCOUNT_SUCCESS,
    error: REMOVE_LINKED_ACCOUNT_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveLinkedAccount() {
  yield takeEvery(REMOVE_LINKED_ACCOUNT, removeLinkedAccount);
}
