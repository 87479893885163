import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_CONTACT,
  CREATE_CONTACT_START,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_ERROR
} from '../ContactConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createContact({ values, options, cb }) {
  yield put(createItemStart(CREATE_CONTACT_START, options));

  return yield spawn(createItemResource, 'feed_backs', {
    success: CREATE_CONTACT_SUCCESS,
    error: CREATE_CONTACT_ERROR
  }, { values, options, cb });
}

export default function* watchCreateContact() {
  yield takeEvery(CREATE_CONTACT, createContact);
}
