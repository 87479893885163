import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  READ_MESSAGE,
  READ_MESSAGE_START,
  READ_MESSAGE_SUCCESS,
  READ_MESSAGE_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* readMessage({ id, options, cb }) {
  yield put(postItemStart(READ_MESSAGE_START, id, options));

  return yield spawn(postItemResource, `discussion_messages/${id}/read`, {
    success: READ_MESSAGE_SUCCESS,
    error: READ_MESSAGE_ERROR
  }, { id, options, cb });
}

export default function* watchReadMessage() {
  yield takeEvery(READ_MESSAGE, readMessage);
}
