import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_MAX_FILE,
  REMOVE_MAX_FILE_START,
  REMOVE_MAX_FILE_SUCCESS,
  REMOVE_MAX_FILE_ERROR
} from '../MaxFileConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeMaxFile({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_MAX_FILE_START, id, options));

  return yield spawn(removeItemResource, 'max_files', {
    success: REMOVE_MAX_FILE_SUCCESS,
    error: REMOVE_MAX_FILE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveMaxFile() {
  yield takeEvery(REMOVE_MAX_FILE, removeMaxFile);
}
