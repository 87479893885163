import { takeEvery, put, call, spawn } from 'redux-saga/effects';

import {
  ACCEPT_INVITE_TO_PROJECT,
  ACCEPT_INVITE_TO_PROJECT_START,
  ACCEPT_INVITE_TO_PROJECT_SUCCESS,
  ACCEPT_INVITE_TO_PROJECT_ERROR
} from '../ProjectConstants';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function acceptInviteToProjectStart(id) {
  return {
    type: ACCEPT_INVITE_TO_PROJECT_START,
    id
  };
}

export function acceptInviteToProjectSuccess(id) {
  return {
    type: ACCEPT_INVITE_TO_PROJECT_SUCCESS,
    id
  };
}

export function acceptInviteToProjectError(id, error) {
  return {
    type: ACCEPT_INVITE_TO_PROJECT_ERROR,
    errorMessage: parseError(error),
    error,
    id
  };
}

function acceptInviteToProjectRequest(projectId) {
  return apiRequest.post(`tasks/${projectId}/accept_invite`).then(({ data: { data: item } }) => ({ item }));
}

function* acceptInviteToProjectResource({ projectId, cb }) {
  try {
    yield call(acceptInviteToProjectRequest, projectId);
    yield put(acceptInviteToProjectSuccess(projectId));
    cb && cb();
  } catch (error) {
    yield put(acceptInviteToProjectError(projectId, error));
  }
}

function* acceptInviteToProject({ projectId, cb }) {
  yield put(acceptInviteToProjectStart(projectId));

  return yield spawn(acceptInviteToProjectResource, { projectId, cb });
}

export default function* watchAcceptInviteToProject() {
  yield takeEvery(ACCEPT_INVITE_TO_PROJECT, acceptInviteToProject);
}
