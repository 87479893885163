export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';

export const CLEAR_TRANSACTIONS_FILTERS_START = 'CLEAR_TRANSACTIONS_FILTERS_START';
export const CLEAR_TRANSACTIONS_FILTERS_SUCCESS = 'CLEAR_TRANSACTIONS_FILTERS_SUCCESS';

export const DOWNLOAD_TRANSACTIONS = 'DOWNLOAD_TRANSACTIONS';
export const DOWNLOAD_TRANSACTIONS_START = 'DOWNLOAD_TRANSACTIONS_START';
export const DOWNLOAD_TRANSACTIONS_SUCCESS = 'DOWNLOAD_TRANSACTIONS_SUCCESS';
export const DOWNLOAD_TRANSACTIONS_ERROR = 'DOWNLOAD_TRANSACTIONS_ERROR';

export const CLEAR_TRANSACTION_MODAL_VALUES = 'CLEAR_TRANSACTION_MODAL_VALUES';
