import { DOWNLOAD_MESSAGES_XLS } from '../MessageConstants';

// downloadMessagesXls

export default function downloadMessagesXls(params, cb) {
  return dispatch =>
    dispatch({
      type: DOWNLOAD_MESSAGES_XLS,
      ...params,
      cb
    });
}
