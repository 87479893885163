import { put, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import get from 'lodash/get';

import { parseError } from '../../../utils/parseError';
import { prepareErrors } from '../../../utils/prepareErrors';
import { apiRequest } from '../../../utils/apiRequest';

export function downloadItemStart(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function downloadItemSuccess(type, id, options = {}) {
  return {
    type,
    id,
    options
  };
}

export function downloadItemError(type, id, error, options = {}) {
  return {
    type,
    id,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function downloadItemRequest(path, id, options) {
  return apiRequest.download(path, get(options, 'values', {}), get(options, 'name'));
}

export function* downloadItemResource(path, { success, error: errorConst }, { id, options, cb }) {
  try {
    yield call(downloadItemRequest, path, id, options);
    yield put(downloadItemSuccess(success, id, options));
    cb && cb();
  } catch (error) {
    yield put(downloadItemError(errorConst, id, error, options));
    yield options.form && put(stopSubmit(options.form, prepareErrors(error)));
  }
}
