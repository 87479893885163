import React, { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';

import { main } from '../../../routes/main';

import { LoadableWrapper } from '../../../../utils/LoadableWrapper';
import { MainAppLayout } from '../../../AppLayouts/MainAppLayout';

const ErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "errors" */ '../../../components/ErrorPage/ErrorPage'
    )
});

interface MainAppContainerProps {
  location: { search: string; pathname: string };
}

const routes: ReactNode[] = [];

flatten(main).forEach(route => {
  if (isArray(route.props.children)) {
    route.props.children.forEach((child: ReactNode) => {
      routes.push(child);
    });
  } else {
    routes.push(route.props.children);
  }
});

function MainAppContainer({
  location: { search, pathname }
}: MainAppContainerProps) {
  return (
    <MainAppLayout search={search} pathname={pathname}>
      <Switch>
        {routes}
        <Route
          key="error"
          render={props => <ErrorPage statusCode={404} {...props} />}
        />
      </Switch>
    </MainAppLayout>
  );
}

export default MainAppContainer;
