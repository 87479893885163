import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function createTemplatesSetSelectedTemplatesSuccess(state, { item, options = {} }) {
  const currentTemplatesSet = currentStateValue(state, 'item', options);
  return updateState(state, {
    item: {
      ...currentTemplatesSet,
      selected_scenes: [
        ...get(currentTemplatesSet, 'selected_scenes', []),
        item
      ]
    },
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, templatesSet => ({
      ...templatesSet,
      selected_scenes: [
        ...get(templatesSet, 'selected_scenes', []),
        item
      ]
    }))
  }, options);
}
