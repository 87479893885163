import { POST_DATA_START } from '../../constants';

import { PostDataRequestAction } from '../../reducers/postDataRequestReducer';

export default function postDataStart<DataType>(): PostDataRequestAction<
  DataType
> {
  return {
    type: POST_DATA_START
  };
}
