import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const TransactionTagShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_transaction_tags" */ './pages/TransactionTagShowPage/TransactionTagShowPage'
    )
});
const TransactionTagsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_transaction_tags" */ './pages/TransactionTagsIndexPage/TransactionTagsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/transaction_tags/:transactionTagId(\d+)"
      component={TransactionTagShowPage}
    />
    <Route
      exact
      path="/admin/transaction_tags"
      component={TransactionTagsIndexPage}
    />
  </Fragment>
);
