import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import without from 'lodash/without';

function addSubscriptions(props, options, items) {
  if (!(options.subscriptions || props.subscriptions)) {
    return null;
  }

  if (isArray((options.subscriptions || props.subscriptions))) {
    if (includes((options.subscriptions || props.subscriptions), 'created')) {
      props[options.subscribeToItemsChannel]('created', null, { user_id: props.currentUser.get('id') });
    }

    return without((options.subscriptions || props.subscriptions), 'created').map(action => items.map(item => props[options.subscribeToItemsChannel](action, item.id)));
  }

  return items.map(item => props[options.subscribeToItemsChannel]('updated', item.id));
}

export default addSubscriptions;
