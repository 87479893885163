import get from 'lodash/get';

import { removeByIds, updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeUsersFromTaskStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null
  }, options);
}

export function removeUsersFromTaskSuccess(state, { values, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null,
    item: {
      ...state.item,
      members: removeByIds(get(currentStateValue(state, 'item', options), 'members'), get(values, 'user_ids'))
    }
  }, options);
}

export function removeUsersFromTaskError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage
  }, options);
}

// import get from 'lodash/get';
//
// import { removeByIds } from '../../../utils';
//
// export function removeUsersFromTaskStart(state) {
//   return {
//     ...state,
//     itemLoading: true
//   };
// }
//
// export function removeUsersFromTaskSuccess(state, { values }) {
//   return {
//     ...state,
//     itemLoading: false,
//     item: {
//       ...state.item,
//       members: removeByIds(get(state, 'item.members'), get(values, 'user_ids'))
//     }
//   };
// }
//
// export function removeUsersFromTaskError(state, { errorMessage }) {
//   return {
//     ...state,
//     itemLoading: false,
//     errorMessage
//   };
// }
