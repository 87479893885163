import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  clearModalValues, locationChange
} from '../../common/reducers';

import { downloadBillsXlsStart, downloadBillsXlsSuccess, downloadBillsXlsError } from './downloadBillsXls';
import { cancelBillStart, cancelBillSuccess, cancelBillError } from './cancelBill';
import { setBillPayingStart, setBillPayingSuccess, setBillPayingError } from './setBillPaying';
import { stripePaymentStart, stripePaymentSuccess, stripePaymentError } from './stripePayment';
import { payBillFromAccountStart, payBillFromAccountSuccess, payBillFromAccountError } from './payBillFromAccount';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_BILLS_START: fetchItemsStart,
  FETCH_BILLS_SUCCESS: fetchItemsSuccess,
  FETCH_BILLS_ERROR: fetchItemsError,

  FETCH_BILL_START: fetchItemStart,
  FETCH_BILL_SUCCESS: fetchItemSuccess,
  FETCH_BILL_ERROR: fetchItemError,

  CLEAR_BILL_MODAL_VALUES: clearModalValues,

  DOWNLOAD_BILLS_XLS_START: downloadBillsXlsStart,
  DOWNLOAD_BILLS_XLS_SUCCESS: downloadBillsXlsSuccess,
  DOWNLOAD_BILLS_XLS_ERROR: downloadBillsXlsError,

  CANCEL_BILL_START: cancelBillStart,
  CANCEL_BILL_SUCCESS: cancelBillSuccess,
  CANCEL_BILL_ERROR: cancelBillError,

  SET_BILL_PAYING_START: setBillPayingStart,
  SET_BILL_PAYING_SUCCESS: setBillPayingSuccess,
  SET_BILL_PAYING_ERROR: setBillPayingError,

  STRIPE_PAYMENT_START: stripePaymentStart,
  STRIPE_PAYMENT_SUCCESS: stripePaymentSuccess,
  STRIPE_PAYMENT_ERROR: stripePaymentError,

  PAY_BILL_FROM_ACCOUNT_START: payBillFromAccountStart,
  PAY_BILL_FROM_ACCOUNT_SUCCESS: payBillFromAccountSuccess,
  PAY_BILL_FROM_ACCOUNT_ERROR: payBillFromAccountError
});
