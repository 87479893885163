import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  STRIPE_PAYMENT,
  STRIPE_PAYMENT_START,
  STRIPE_PAYMENT_SUCCESS,
  STRIPE_PAYMENT_ERROR
} from '../BillConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* stripePayment({ id, values, options, cb }) {
  yield put(postItemStart(STRIPE_PAYMENT_START, id, options));

  const stripePaymentAction = yield spawn(postItemResource, `stripe_bills/${id}/checkout`, {
    success: STRIPE_PAYMENT_SUCCESS,
    error: STRIPE_PAYMENT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(stripePaymentAction);
}

export default function* watchStripePayment() {
  yield takeEvery(STRIPE_PAYMENT, stripePayment);
}
