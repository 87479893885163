import { DOWNLOAD_MESSAGE_SELECTED_TEMPLATES } from '../MessageConstants';

// downloadMessageSelectedTemplates

export default function downloadMessageSelectedTemplates(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: DOWNLOAD_MESSAGE_SELECTED_TEMPLATES,
    id,
    values,
    options,
    cb
  });
}
