import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ACCEPT_LINKED_ACCOUNT_INVITE,
  ACCEPT_LINKED_ACCOUNT_INVITE_START,
  ACCEPT_LINKED_ACCOUNT_INVITE_SUCCESS,
  ACCEPT_LINKED_ACCOUNT_INVITE_ERROR
} from '../LinkedAccountInviteConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* acceptLinkedAccountInvite({ id, options, cb }) {
  yield put(postItemStart(ACCEPT_LINKED_ACCOUNT_INVITE_START, id, options));

  return yield spawn(postItemResource, `linked_account_invites/${id}/accept`, {
    success: ACCEPT_LINKED_ACCOUNT_INVITE_SUCCESS,
    error: ACCEPT_LINKED_ACCOUNT_INVITE_ERROR
  }, { id, options, cb });
}

export default function* watchAcceptLinkedAccountInvite() {
  yield takeEvery(ACCEPT_LINKED_ACCOUNT_INVITE, acceptLinkedAccountInvite);
}
