import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ACCEPT_LINKED_ACCOUNT_REQUEST,
  ACCEPT_LINKED_ACCOUNT_REQUEST_START,
  ACCEPT_LINKED_ACCOUNT_REQUEST_SUCCESS,
  ACCEPT_LINKED_ACCOUNT_REQUEST_ERROR
} from '../LinkedAccountRequestConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* acceptLinkedAccountRequest({ id, options, cb }) {
  yield put(postItemStart(ACCEPT_LINKED_ACCOUNT_REQUEST_START, id, options));

  return yield spawn(postItemResource, `linked_account_requests/${id}/accept`, {
    success: ACCEPT_LINKED_ACCOUNT_REQUEST_SUCCESS,
    error: ACCEPT_LINKED_ACCOUNT_REQUEST_ERROR
  }, { id, options, cb });
}

export default function* watchAcceptLinkedAccountRequest() {
  yield takeEvery(ACCEPT_LINKED_ACCOUNT_REQUEST, acceptLinkedAccountRequest);
}
