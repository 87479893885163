import { updateState } from '../../../utils';

export function fetchItemStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemFetched: false,
    itemErrorMessage: null,
    itemCreateErrorMessage: null,
    itemUpdateErrorMessage: null,
    itemRemoveErrorMessage: null
  }, options);
}

export function fetchItemSuccess(state, { item, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemErrorMessage: null,
    itemCreateErrorMessage: null,
    itemUpdateErrorMessage: null,
    itemRemoveErrorMessage: null,
    item
  }, options);
}

export function fetchItemError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemFetched: true,
    itemErrorMessage: errorMessage
  }, options);
}
