import { FETCH_DOWNLOAD } from '../DownloadConstants';

// fetchDownload

export default function fetchDownload(id, options = {}, cb) {
  return dispatch => dispatch({
    type: FETCH_DOWNLOAD,
    id,
    options,
    cb
  });
}
