import React from 'react';

import { ID } from '../../../../../../types';

import { useSimpleModal } from '../../../../../../helpers/ts/SimpleModal';

import { ItemBillsModalContainer } from './components/ItemBillsModalContainer';

import { ModalButton } from '../../../../../../helpers/ts/ModalButton';
import { PureButtonProps } from '../../../../../../helpers/ts/PureButton';
import { TooltipProps } from '../../../../../../helpers/ts/Tooltip';

interface ItemBillsModalProps {
  addClass?: string;
  billId: ID;
  className?: string;
  disabled?: boolean;
  disabledButton?: boolean;
  i18nButtonText?: string;
  modalButtonAddClass?: string;
  modalButtonColor?: string;
  modalIcon: string;
  onPayment?: () => void;
  submitIcon: string;
  type: string;
}

function ItemBillsModal({
  addClass,
  afterIcon,
  billId,
  className,
  disabled = false,
  disabledButton = false,
  i18nButtonText,
  icon,
  modalButtonAddClass,
  modalButtonColor = 'primary',
  modalIcon,
  onPayment,
  submitIcon,
  tooltipTarget,
  tooltipI18nValue,
  type
}: ItemBillsModalProps & PureButtonProps & TooltipProps) {
  const { isOpen, showModal, hideModal } = useSimpleModal();

  return (
    <ModalButton
      addClass={modalButtonAddClass || addClass}
      afterIcon={afterIcon}
      className={className}
      color={modalButtonColor}
      disabled={disabled}
      disabledButton={disabledButton}
      i18nButtonText={i18nButtonText}
      i18nModalTitle={`models.bills.payment_types.${type}`}
      icon={icon}
      isLoading={false}
      modalOpened={isOpen}
      modalIcon={modalIcon}
      modalSize="lg"
      onClose={hideModal}
      onOpen={showModal}
      tooltipI18nValue={tooltipI18nValue}
      tooltipTarget={tooltipTarget}
      withoutBody
      withoutFooter
    >
      <ItemBillsModalContainer
        addClass="btn-sm btn-labeled btn-labeled-left"
        billId={billId}
        onClose={hideModal}
        onPayment={onPayment}
        submitIcon={submitIcon}
        type={type}
      />
    </ModalButton>
  );
}

export default ItemBillsModal;
