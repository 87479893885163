import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  SET_BILL_PAYING,
  SET_BILL_PAYING_START,
  SET_BILL_PAYING_SUCCESS,
  SET_BILL_PAYING_ERROR
} from '../BillConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* setBillPaying({ id, options, cb }) {
  yield put(postItemStart(SET_BILL_PAYING_START, id, options));

  const setBillPayingAction = yield spawn(postItemResource, `bills/${id}/set_paying`, {
    success: SET_BILL_PAYING_SUCCESS,
    error: SET_BILL_PAYING_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(setBillPayingAction);
}

export default function* watchSetBillPaying() {
  yield takeEvery(SET_BILL_PAYING, setBillPaying);
}
