import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF,
  DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_START,
  DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_SUCCESS,
  DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadTaskSelectedTemplatesPdf({ id, values, options, cb }) {
  yield put(postItemStart(DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_START, id, options));

  const downloadTaskSelectedTemplatesPdfAction = yield spawn(postItemResource, `discussions/${id}/selected_scenes_pdf`, {
    success: DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_SUCCESS,
    error: DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF_ERROR
  }, { id, values: { ...values, device_nano_id: window.downloadNanoId }, options: { ...options, pure: true }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTaskSelectedTemplatesPdfAction);
}

export default function* watchDownloadTaskSelectedTemplatesPdf() {
  yield takeEvery(DOWNLOAD_TASK_SELECTED_TEMPLATES_PDF, downloadTaskSelectedTemplatesPdf);
}
