import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_MESSAGE,
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR
} from '../MessageConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createMessage({ values, options, cb }) {
  yield put(createItemStart(CREATE_MESSAGE_START, options));

  return yield spawn(createItemResource, 'discussion_messages', {
    success: CREATE_MESSAGE_SUCCESS,
    error: CREATE_MESSAGE_ERROR
  }, { values, options, cb });
}

export default function* watchCreateMessage() {
  yield takeEvery(CREATE_MESSAGE, createMessage);
}
