export default {
  plural: 'Roles',
  singular: 'Role',
  new: 'Create Role',
  'add...': 'Select roles...',
  no: 'No roles',
  finance: 'Finance Role',
  'select_finance...': 'Select finance roles...',
  'select...': 'Select roles...',
  types: {
    client: 'Client',
    manager: 'Manager',
    worker: 'Worker',
    art_director: 'Art director',
    support: 'Support',
    system: 'System',
    qa: 'Quality control',
    admin: 'Administrator',
    project_manager: 'Project manager',
    mentor: 'Mentor',
    student: 'Student',
    finance: 'Finance',
    Superadmin: 'Superadmin',
    remote_worker: 'Remote Worker',
    base: 'Base',
    archivizer: 'Archivizer',
    upsale_manager: 'Client Manager',
    sale_manager: 'Sales Manager',
    content: 'Content Manager',
    HR: 'Human Resource',
    developer: 'Developer',
    superadmin: 'Superadmin'
  }
};
