export const FETCH_SELECTED_MATERIALS = 'FETCH_SELECTED_MATERIALS';
export const FETCH_SELECTED_MATERIALS_START = 'FETCH_SELECTED_MATERIALS_START';
export const FETCH_SELECTED_MATERIALS_SUCCESS = 'FETCH_SELECTED_MATERIALS_SUCCESS';
export const FETCH_SELECTED_MATERIALS_ERROR = 'FETCH_SELECTED_MATERIALS_ERROR';

export const FETCH_SELECTED_MATERIAL = 'FETCH_SELECTED_MATERIAL';
export const FETCH_SELECTED_MATERIAL_START = 'FETCH_SELECTED_MATERIAL_START';
export const FETCH_SELECTED_MATERIAL_SUCCESS = 'FETCH_SELECTED_MATERIAL_SUCCESS';
export const FETCH_SELECTED_MATERIAL_ERROR = 'FETCH_SELECTED_MATERIAL_ERROR';

export const CREATE_SELECTED_MATERIAL = 'CREATE_SELECTED_MATERIAL';
export const CREATE_SELECTED_MATERIAL_START = 'CREATE_SELECTED_MATERIAL_START';
export const CREATE_SELECTED_MATERIAL_SUCCESS = 'CREATE_SELECTED_MATERIAL_SUCCESS';
export const CREATE_SELECTED_MATERIAL_ERROR = 'CREATE_SELECTED_MATERIAL_ERROR';

export const UPDATE_SELECTED_MATERIAL = 'UPDATE_SELECTED_MATERIAL';
export const UPDATE_SELECTED_MATERIAL_START = 'UPDATE_SELECTED_MATERIAL_START';
export const UPDATE_SELECTED_MATERIAL_SUCCESS = 'UPDATE_SELECTED_MATERIAL_SUCCESS';
export const UPDATE_SELECTED_MATERIAL_ERROR = 'UPDATE_SELECTED_MATERIAL_ERROR';

export const DOWNLOAD_SELECTED_MATERIAL = 'DOWNLOAD_SELECTED_MATERIAL';
export const DOWNLOAD_SELECTED_MATERIAL_START = 'DOWNLOAD_SELECTED_MATERIAL_START';
export const DOWNLOAD_SELECTED_MATERIAL_SUCCESS = 'DOWNLOAD_SELECTED_MATERIAL_SUCCESS';
export const DOWNLOAD_SELECTED_MATERIAL_ERROR = 'DOWNLOAD_SELECTED_MATERIAL_ERROR';

export const REMOVE_SELECTED_MATERIAL = 'REMOVE_SELECTED_MATERIAL';
export const REMOVE_SELECTED_MATERIAL_START = 'REMOVE_SELECTED_MATERIAL_START';
export const REMOVE_SELECTED_MATERIAL_SUCCESS = 'REMOVE_SELECTED_MATERIAL_SUCCESS';
export const REMOVE_SELECTED_MATERIAL_ERROR = 'REMOVE_SELECTED_MATERIAL_ERROR';

export const CLEAR_SELECTED_MATERIAL_MODAL_VALUES = 'CLEAR_SELECTED_MATERIAL_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_SELECTED_MATERIALS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_SELECTED_MATERIALS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_SELECTED_MATERIALS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_SELECTED_MATERIALS_CHANNEL';

export const ATTACH_SELECTED_MATERIALS_FORM = 'ATTACH_SELECTED_MATERIALS_FORM';

export const ATTACH_SELECTED_MATERIALS = 'ATTACH_SELECTED_MATERIALS';
export const ATTACH_SELECTED_MATERIALS_START = 'ATTACH_SELECTED_MATERIALS_START';
export const ATTACH_SELECTED_MATERIALS_SUCCESS = 'ATTACH_SELECTED_MATERIALS_SUCCESS';
export const ATTACH_SELECTED_MATERIALS_ERROR = 'ATTACH_SELECTED_MATERIALS_ERROR';
