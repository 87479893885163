import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  itemsChannelUpdated,
  clearModalValues
} from '../../common/reducers';

import {
  removeSelectedTemplateTextureFilesStart,
  removeSelectedTemplateTextureFilesSuccess,
  removeSelectedTemplateTextureFilesError
} from './removeSelectedTemplateTextureFiles';

import {
  attachSelectedTemplatesStart,
  attachSelectedTemplatesSuccess,
  attachSelectedTemplatesError
} from './attachSelectedTemplates';

import {
  removeSelectedTemplatesStart,
  removeSelectedTemplatesSuccess,
  removeSelectedTemplatesError
} from './removeSelectedTemplates';

export default createReducer(defaultState, {
  FETCH_SELECTED_TEMPLATES_START: fetchItemsStart,
  FETCH_SELECTED_TEMPLATES_SUCCESS: fetchItemsSuccess,
  FETCH_SELECTED_TEMPLATES_ERROR: fetchItemsError,

  FETCH_SELECTED_TEMPLATE_START: fetchItemStart,
  FETCH_SELECTED_TEMPLATE_SUCCESS: fetchItemSuccess,
  FETCH_SELECTED_TEMPLATE_ERROR: fetchItemError,

  CREATE_SELECTED_TEMPLATE_START: createItemStart,
  CREATE_SELECTED_TEMPLATE_SUCCESS: createItemSuccess,
  CREATE_SELECTED_TEMPLATE_ERROR: createItemError,

  UPDATE_SELECTED_TEMPLATE_START: updateItemStart,
  UPDATE_SELECTED_TEMPLATE_SUCCESS: updateItemSuccess,
  UPDATE_SELECTED_TEMPLATE_ERROR: updateItemError,

  REMOVE_SELECTED_TEMPLATE_START: removeItemStart,
  REMOVE_SELECTED_TEMPLATE_SUCCESS: removeItemSuccess,
  REMOVE_SELECTED_TEMPLATE_ERROR: removeItemError,

  ATTACH_SELECTED_TEMPLATES_START: attachSelectedTemplatesStart,
  ATTACH_SELECTED_TEMPLATES_SUCCESS: attachSelectedTemplatesSuccess,
  ATTACH_SELECTED_TEMPLATES_ERROR: attachSelectedTemplatesError,

  REMOVE_TASK_SELECTED_TEMPLATES_START: removeSelectedTemplatesStart,
  REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS: removeSelectedTemplatesSuccess,
  REMOVE_TASK_SELECTED_TEMPLATES_ERROR: removeSelectedTemplatesError,

  SELECTED_TEMPLATES_CHANNEL_UPDATED: itemsChannelUpdated,

  CLEAR_SELECTED_TEMPLATE_MODAL_VALUES: clearModalValues,

  REMOVE_TEXTURE_FILE_START: removeSelectedTemplateTextureFilesStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeSelectedTemplateTextureFilesSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeSelectedTemplateTextureFilesError
});
