import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import compact from 'lodash/compact';

import { translate as t } from '../../utils';

class renderField extends PureComponent {
  static propTypes = {
    formInputId: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    suffixClass: PropTypes.string,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string
    }).isRequired,
    label: PropTypes.node,
    labelClass: PropTypes.string,
    inputWrapperClass: PropTypes.string,
    tooltip: PropTypes.string,
    type: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    afterChange: PropTypes.func
  };

  static defaultProps = {
    formInputId: null,
    className: null,
    inputClassName: null,
    suffix: null,
    suffixClass: null,
    icon: null,
    label: null,
    labelClass: null,
    inputWrapperClass: null,
    tooltip: null,
    type: 'text',
    min: null,
    max: null,
    step: null,
    placeholder: null,
    required: null,
    disabled: null,
    autoFocus: null,
    afterChange: null
  };

  render() {
    const {
      formInputId,
      className,
      inputClassName,
      suffix,
      suffixClass,
      icon,
      input,
      label,
      labelClass,
      inputWrapperClass,
      tooltip,
      type,
      placeholder,
      required,
      disabled,
      min,
      max,
      step,
      meta: { touched, error },
      afterChange,
      autoFocus
    } = this.props;

    const inputView = (
      <input
        autoFocus={autoFocus}
        id={`form-input-${compact([formInputId, input.name]).join('-')}`}
        className={cl(inputClassName || 'form-control px-2')}
        {...input}
        type={type}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onChange={({ target: { value } }) => {
          input.onChange(value === '' ? null : value);
          if (afterChange) {
            afterChange(value === '' ? null : value);
          }
        }}
      />
    );

    if (inputWrapperClass) {
      return (
        <Fragment>
          {label ? (
            <label
              id={`form-input-${compact([formInputId, input.name]).join('-')}`}
              htmlFor={`form-input-${compact([formInputId, input.name]).join('-')}`}
              className={labelClass}
            >
              {label}
            </label>
          ) : null}
          <div className={inputWrapperClass}>
            {inputView}
            {touched && error && (
              <span className="form-text text-danger">
                <i className="icon-cancel-circle2 mr-2" />
                {/^forms\.errors+/.test(error) ? t(error) : error}
              </span>
            )}
          </div>
        </Fragment>
      );
    }

    return (
      <div
        className={cl(className || 'form-group')}
      >
        {label ? (
          <label
            className={labelClass || 'form-control-label'}
            htmlFor={`form-input-${compact([formInputId, input.name]).join('-')}`}
          >
            {label} {tooltip}
          </label>
        ) : null}
        {inputView}
        {suffix ? (
          <div className={suffixClass || 'form-control-feedback'}>
            {suffix}
          </div>
        ) : null}
        {icon ? (
          <div className="form-control-feedback">
            {icon ? <i className={cl(icon)} /> : null}
          </div>
        ) : null}
        {touched && error && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" />
            {/^forms\.errors+/.test(error) ? t(error) : error}
          </span>
        )}
      </div>
    );
  }
}

export default renderField;
