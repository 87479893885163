import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';
import { account } from '../../routes/account';

const ErrorPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "errors" */'../../components/ErrorPage/ErrorPage') });

function AccountAppContainer() {
  return (
    <Switch>
      {account}
      <Route key="error" render={props => <ErrorPage statusCode={404} {...props} />} />
    </Switch>
  );
}

export default AccountAppContainer;
