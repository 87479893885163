import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR
} from '../TemplateConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTemplates(params) {
  yield put(fetchItemsStart(FETCH_TEMPLATES_START, params));

  const fetchTemplatesAction = yield spawn(fetchItemsResource, 'scenes', {
    success: FETCH_TEMPLATES_SUCCESS,
    error: FETCH_TEMPLATES_ERROR
  }, params);

  // yield take(FETCH_TEMPLATES_START);
  // yield cancel(fetchTemplatesAction);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTemplatesAction);
}

export default function* watchFetchTemplates() {
  yield takeEvery(FETCH_TEMPLATES, fetchTemplates);
}
