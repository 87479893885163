import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const FileImportShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_file_imports" */ './pages/FileImportShowPage/FileImportShowPage'
    )
});
const FileImportsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_file_imports" */ './pages/FileImportsIndexPage/FileImportsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/file_imports/:fileImportId(\d+)"
      component={FileImportShowPage}
    />
    <Route exact path="/admin/file_imports" component={FileImportsIndexPage} />
  </Fragment>
);
