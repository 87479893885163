import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, spawn, take, cancel } from 'redux-saga/effects';

import {
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_START,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGES_ERROR
} from '../ImageConstants';

import { uploadItemsResource } from '../../common/sagas';

function* uploadImages({ files, options, cb }) {
  const uploadImagesAction = yield spawn(uploadItemsResource, 'images', {
    start: UPLOAD_IMAGES_START,
    eachStart: UPLOAD_IMAGE_START,
    success: UPLOAD_IMAGES_SUCCESS,
    eachSuccess: UPLOAD_IMAGE_SUCCESS,
    eachError: UPLOAD_IMAGE_ERROR,
    error: UPLOAD_IMAGES_ERROR,
    param: 'image'
  }, { files, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(uploadImagesAction);
}

export default function* watchUploadImages() {
  yield takeEvery(UPLOAD_IMAGES, uploadImages);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   UPLOAD_IMAGES,
//   UPLOAD_IMAGES_START,
//   UPLOAD_IMAGES_SUCCESS,
//   UPLOAD_IMAGES_ERROR
// } from '../ImageConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function uploadImagesStart() {
//   return {
//     type: UPLOAD_IMAGES_START
//   };
// }
//
// export function uploadImagesSuccess(items) {
//   return {
//     type: UPLOAD_IMAGES_SUCCESS,
//     items
//   };
// }
//
// export function uploadImagesError(error) {
//   return {
//     type: UPLOAD_IMAGES_ERROR,
//     errorMessage: parseError(error),
//     error
//   };
// }
//
// function uploadImageRequest(image) {
//   return apiRequest.post('images', image).then(({ data: { data: item } }) => ({ item }));
// }
//
// function* uploadImagesResource({ files, options: { form }, cb }) {
//   try {
//     const items = [];
//
//     for (let i = 0; i < files.length; i++) {
//       const { onSuccess } = files[i];
//
//       const { item } = yield call(uploadImageRequest, files[i]);
//
//       onSuccess && onSuccess(item);
//
//       items.push(item);
//     }
//
//     yield put(uploadImagesSuccess(items));
//     cb && cb(items);
//   } catch (error) {
//     yield put(uploadImagesError(error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* uploadImages({ files, options, cb }) {
//   yield put(uploadImagesStart());
//
//   return yield spawn(uploadImagesResource, { files, options, cb });
// }
//
// export default function* watchUploadImages() {
//   yield takeEvery(UPLOAD_IMAGES, uploadImages);
// }
