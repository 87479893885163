import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_PROJECT_NOTIFICATION,
  FETCH_PROJECT_NOTIFICATION_START,
  FETCH_PROJECT_NOTIFICATION_SUCCESS,
  FETCH_PROJECT_NOTIFICATION_ERROR
} from '../ProjectNotificationConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchProjectNotification({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_PROJECT_NOTIFICATION_START, id, options));

  const fetchProjectNotificationAction = yield spawn(fetchItemResource, 'task_notifications', {
    success: FETCH_PROJECT_NOTIFICATION_SUCCESS,
    error: FETCH_PROJECT_NOTIFICATION_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchProjectNotificationAction);
}

export default function* watchFetchProjectNotification() {
  yield takeEvery(FETCH_PROJECT_NOTIFICATION, fetchProjectNotification);
}
