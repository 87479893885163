import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PROJECT,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR
} from '../ProjectConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateProject({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_PROJECT_START, id, options));

  return yield spawn(updateItemResource, 'tasks', {
    success: UPDATE_PROJECT_SUCCESS,
    error: UPDATE_PROJECT_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateProject() {
  yield takeEvery(UPDATE_PROJECT, updateProject);
}
