import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function unreadMessageStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), id, message => ({
      ...message,
      error: null,
      loading: true
    }))
  }, options);
}

export function unreadMessageSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), id, message => ({
      ...message,
      read: false,
      error: null,
      loading: false
    }))
  }, options);
}

export function unreadMessageError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), id, message => ({
      ...message,
      error: errorMessage,
      loading: false
    }))
  }, options);
}
