import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_MATERIAL,
  FETCH_MATERIAL_START,
  FETCH_MATERIAL_SUCCESS,
  FETCH_MATERIAL_ERROR
} from '../MaterialConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchMaterial({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_MATERIAL_START, id, options));

  const fetchMaterialAction = yield spawn(fetchItemResource, 'materials', {
    success: FETCH_MATERIAL_SUCCESS,
    error: FETCH_MATERIAL_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchMaterialAction);
}

export default function* watchFetchMaterial() {
  yield takeEvery(FETCH_MATERIAL, fetchMaterial);
}
