import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_MATERIAL,
  CREATE_MATERIAL_START,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_ERROR
} from '../MaterialConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createMaterial({ values, options, cb }) {
  yield put(createItemStart(CREATE_MATERIAL_START, options));

  return yield spawn(createItemResource, 'materials', {
    success: CREATE_MATERIAL_SUCCESS,
    error: CREATE_MATERIAL_ERROR
  }, { values, options, cb });
}

export default function* watchCreateMaterial() {
  yield takeEvery(CREATE_MATERIAL, createMaterial);
}
