import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_REFILL,
  FETCH_REFILL_START,
  FETCH_REFILL_SUCCESS,
  FETCH_REFILL_ERROR
} from '../RefillConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchRefill({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_REFILL_START, id, options));

  const fetchRefillAction = yield spawn(fetchItemResource, 'refills', {
    success: FETCH_REFILL_SUCCESS,
    error: FETCH_REFILL_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchRefillAction);
}

export default function* watchFetchRefill() {
  yield takeEvery(FETCH_REFILL, fetchRefill);
}
