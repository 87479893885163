import { updateState } from '../../../utils';

export function clearItemsFiltersStart(state, { options = {} }) {
  return updateState(state, {
    filtersCleared: true
  }, options);
}

export function clearItemsFiltersSuccess(state, { options = {} }) {
  return updateState(state, {
    filtersCleared: false
  }, options);
}
