import React, { Fragment, ReactNode, useEffect } from 'react';

import { Header } from '../../components';
import { Footer } from '../../components/ts/Footer';
import { CozyHeader } from '../../components/CozyHeader';

import { CustomFavicon } from '../../../helpers/ts/CustomFavicon';
import { CozyStorage } from '../../../utils/ts/CozyStorage';
import { isUserCozy } from '../../../utils/ts/isUserCozy';
import { parseReferer } from '../../../utils/parseReferer';

interface AuthAppContainerProps {
  search: string;
  pathname: string;
  children: ReactNode;
}

function AuthAppLayout({ search, pathname, children }: AuthAppContainerProps) {
  useEffect(() => {
    const referer = parseReferer(search);

    if (referer === 'cozymakeover.com' || /auth\/cozy/.test(pathname)) {
      CozyStorage.setIsUserCozy(true);
    }
  }, [search, pathname]);

  const referer = parseReferer(search);
  const isCozy = referer === 'cozymakeover.com' || isUserCozy();

  return (
    <Fragment>
      {isCozy ? (
        <Fragment>
          <CustomFavicon isCozy />
          <CozyHeader auth />
        </Fragment>
      ) : (
        <Fragment>
          <CustomFavicon />
          <Header auth />
        </Fragment>
      )}
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content d-flex justify-content-center align-items-center">
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default AuthAppLayout;
