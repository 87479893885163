import { updateState } from '../../../utils';

export function requestAccessLinkedAccountsStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    itemErrorMessage: null
  }, options);
}

export function requestAccessLinkedAccountsSuccess(state, { options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: null
  }, options);
}

export function requestAccessLinkedAccountsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    itemErrorMessage: errorMessage
  }, options);
}
