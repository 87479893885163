import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { productionConsole } from '../../utils/productionConsole';

const anonymousPermissions = {
  public_access: true,
  read_project_show_page: true,
  read_project_messages_tab: true,
  read_project_attachments_tab: true,
  read_project_results_tab: true
};

class User {
  constructor(values = {}) {
    this.currentUser = values;
    this.shownPermissions = {};
  }

  get(field, defaultValue = null) {
    return get(this.currentUser, field, defaultValue);
  }

  // updateFilter(name, values = {}) {
  //   this.currentUser = {
  //     ...this.currentUser,
  //     [name]: {
  //       ...get(this.currentUser, name, {}),
  //       data: {
  //         ...get(this.currentUser, `${name}.data`, {}),
  //         ...values
  //       }
  //     }
  //   };
  //
  //   return this;
  // }

  hasPermissions(action, ignoreSelfAction = null, userId = null) {
    const actionResult = (this.currentUser.permissions || {})[action] || (isNull(this.get('id')) && anonymousPermissions[action]) || false;

    if (userId) {
      return this.get('id') === userId ? actionResult : (this.currentUser.permissions || {})[ignoreSelfAction] || false;
    }

    if (!actionResult && !this.shownPermissions[action]) {
      this.shownPermissions[action] = action;
      productionConsole('log', 'hasPermissions', this.get('id'), action, { actionResult, client: this.get('client'), ignoreSelfAction, userId, user: this });
    }

    if (!this.shownPermissions[action]) {
      this.shownPermissions[action] = action;
      productionConsole('warn', 'hasPermissions', this.get('id'), action, { actionResult, client: this.get('client'), ignoreSelfAction, userId, user: this });
    }

    return actionResult;
  }

  // hasPermissions(action, ignoreSelfAction = null, userId = null) {
  //   const actionResult = includes(
  //     (this.currentUser.permissions || []),
  //     action
  //       .replace(/read_projects/, 'read_task')
  //       .replace(/change_projects/, 'change_task')
  //       .replace(/read_tasks/, 'read_discussion')
  //       .replace(/change_tasks/, 'change_discussion')
  //       .replace(/read_discussions/, 'read_discussion')
  //       .replace(/change_discussions/, 'change_discussion')
  //   );
  //
  //   if (userId) {
  //     return this.get('id') === userId ? actionResult : includes(
  //       (this.currentUser.permissions || []),
  //       ignoreSelfAction
  //         .replace(/read_projects/, 'read_task')
  //         .replace(/change_projects/, 'change_task')
  //         .replace(/read_tasks/, 'read_discussion')
  //         .replace(/change_tasks/, 'change_discussion')
  //         .replace(/read_discussions/, 'read_discussion')
  //         .replace(/change_discussions/, 'change_discussion')
  //     );
  //   }
  //
  //   if (!actionResult && !this.shownPermissions[action]) {
  //     this.shownPermissions[action] = action;
  //   }
  //
  //   return actionResult;
  // }

  isClient() {
    return this.get('client');
  }

  isEn() {
    return this.get('locale') === 'en';
  }

  isRu() {
    return this.get('locale') === 'ru';
  }

  isUk() {
    return this.get('locale') === 'uk';
  }

  canChange(user, action) {
    if (this.get('id') === user.id) {
      return true;
    }

    return this.hasPermissions(action);
  }
}

export default User;
