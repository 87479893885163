import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_TAG,
  REMOVE_TAG_START,
  REMOVE_TAG_SUCCESS,
  REMOVE_TAG_ERROR
} from '../TagConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeTag({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_TAG_START, id, options));

  return yield spawn(removeItemResource, 'tags', {
    success: REMOVE_TAG_SUCCESS,
    error: REMOVE_TAG_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveTag() {
  yield takeEvery(REMOVE_TAG, removeTag);
}
