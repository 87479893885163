export const FETCH_SELECTED_TEMPLATES = 'FETCH_SELECTED_TEMPLATES';
export const FETCH_SELECTED_TEMPLATES_START = 'FETCH_SELECTED_TEMPLATES_START';
export const FETCH_SELECTED_TEMPLATES_SUCCESS = 'FETCH_SELECTED_TEMPLATES_SUCCESS';
export const FETCH_SELECTED_TEMPLATES_ERROR = 'FETCH_SELECTED_TEMPLATES_ERROR';

export const FETCH_SELECTED_TEMPLATE = 'FETCH_SELECTED_TEMPLATE';
export const FETCH_SELECTED_TEMPLATE_START = 'FETCH_SELECTED_TEMPLATE_START';
export const FETCH_SELECTED_TEMPLATE_SUCCESS = 'FETCH_SELECTED_TEMPLATE_SUCCESS';
export const FETCH_SELECTED_TEMPLATE_ERROR = 'FETCH_SELECTED_TEMPLATE_ERROR';

export const CREATE_SELECTED_TEMPLATE = 'CREATE_SELECTED_TEMPLATE';
export const CREATE_SELECTED_TEMPLATE_START = 'CREATE_SELECTED_TEMPLATE_START';
export const CREATE_SELECTED_TEMPLATE_SUCCESS = 'CREATE_SELECTED_TEMPLATE_SUCCESS';
export const CREATE_SELECTED_TEMPLATE_ERROR = 'CREATE_SELECTED_TEMPLATE_ERROR';

export const UPDATE_SELECTED_TEMPLATE = 'UPDATE_SELECTED_TEMPLATE';
export const UPDATE_SELECTED_TEMPLATE_START = 'UPDATE_SELECTED_TEMPLATE_START';
export const UPDATE_SELECTED_TEMPLATE_SUCCESS = 'UPDATE_SELECTED_TEMPLATE_SUCCESS';
export const UPDATE_SELECTED_TEMPLATE_ERROR = 'UPDATE_SELECTED_TEMPLATE_ERROR';

export const DOWNLOAD_SELECTED_TEMPLATE = 'DOWNLOAD_SELECTED_TEMPLATE';
export const DOWNLOAD_SELECTED_TEMPLATE_START = 'DOWNLOAD_SELECTED_TEMPLATE_START';
export const DOWNLOAD_SELECTED_TEMPLATE_SUCCESS = 'DOWNLOAD_SELECTED_TEMPLATE_SUCCESS';
export const DOWNLOAD_SELECTED_TEMPLATE_ERROR = 'DOWNLOAD_SELECTED_TEMPLATE_ERROR';

export const REMOVE_SELECTED_TEMPLATE = 'REMOVE_SELECTED_TEMPLATE';
export const REMOVE_SELECTED_TEMPLATE_START = 'REMOVE_SELECTED_TEMPLATE_START';
export const REMOVE_SELECTED_TEMPLATE_SUCCESS = 'REMOVE_SELECTED_TEMPLATE_SUCCESS';
export const REMOVE_SELECTED_TEMPLATE_ERROR = 'REMOVE_SELECTED_TEMPLATE_ERROR';

export const CLEAR_SELECTED_TEMPLATE_MODAL_VALUES = 'CLEAR_SELECTED_TEMPLATE_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_SELECTED_TEMPLATES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_SELECTED_TEMPLATES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_SELECTED_TEMPLATES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_SELECTED_TEMPLATES_CHANNEL';

export const ATTACH_SELECTED_TEMPLATES_FORM = 'ATTACH_SELECTED_TEMPLATES_FORM';

export const ATTACH_SELECTED_TEMPLATES = 'ATTACH_SELECTED_TEMPLATES';
export const ATTACH_SELECTED_TEMPLATES_START = 'ATTACH_SELECTED_TEMPLATES_START';
export const ATTACH_SELECTED_TEMPLATES_SUCCESS = 'ATTACH_SELECTED_TEMPLATES_SUCCESS';
export const ATTACH_SELECTED_TEMPLATES_ERROR = 'ATTACH_SELECTED_TEMPLATES_ERROR';

export const DUPLICATE_SELECTED_TEMPLATES = 'DUPLICATE_SELECTED_TEMPLATES';
export const DUPLICATE_SELECTED_TEMPLATES_START = 'DUPLICATE_SELECTED_TEMPLATES_START';
export const DUPLICATE_SELECTED_TEMPLATES_SUCCESS = 'DUPLICATE_SELECTED_TEMPLATES_SUCCESS';
export const DUPLICATE_SELECTED_TEMPLATES_ERROR = 'DUPLICATE_SELECTED_TEMPLATES_ERROR';
