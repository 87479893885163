import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_TASK_NOTIFICATIONS,
  FETCH_TASK_NOTIFICATIONS_START,
  FETCH_TASK_NOTIFICATIONS_SUCCESS,
  FETCH_TASK_NOTIFICATIONS_ERROR
} from '../TaskNotificationConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchTaskNotifications(params) {
  yield put(fetchItemsStart(FETCH_TASK_NOTIFICATIONS_START, params));

  const fetchTaskNotificationsAction = yield spawn(fetchItemsResource, 'discussion_notifications', {
    success: FETCH_TASK_NOTIFICATIONS_SUCCESS,
    error: FETCH_TASK_NOTIFICATIONS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTaskNotificationsAction);
}

export default function* watchFetchTaskNotifications() {
  yield takeEvery(FETCH_TASK_NOTIFICATIONS, fetchTaskNotifications);
}
