import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ATTACH_SELECTED_PRODUCTS,
  ATTACH_SELECTED_PRODUCTS_START,
  ATTACH_SELECTED_PRODUCTS_SUCCESS,
  ATTACH_SELECTED_PRODUCTS_ERROR
} from '../SelectedProductConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* attachSelectedProducts({ values, options, cb }) {
  yield put(postItemsStart(ATTACH_SELECTED_PRODUCTS_START, options));

  return yield spawn(postItemsResource, 'selected_facilities/attach', {
    success: ATTACH_SELECTED_PRODUCTS_SUCCESS,
    error: ATTACH_SELECTED_PRODUCTS_ERROR
  }, { values, options, cb });
}

export default function* watchAttachSelectedProducts() {
  yield takeEvery(ATTACH_SELECTED_PRODUCTS, attachSelectedProducts);
}
