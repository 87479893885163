import {
  CLEAR_MESSAGE_FILTERS_START,
  CLEAR_MESSAGE_FILTERS_SUCCESS
} from '../MessageConstants';

export function clearMessageFiltersStart(options = {}) {
  return {
    type: CLEAR_MESSAGE_FILTERS_START,
    options
  };
}

export function clearMessageFiltersSuccess(options = {}) {
  return {
    type: CLEAR_MESSAGE_FILTERS_SUCCESS,
    options
  };
}

export default function clearMessageFilters(options = {}) {
  return dispatch => {
    dispatch(clearMessageFiltersStart(options));
    setTimeout(() => dispatch(clearMessageFiltersSuccess(options)), 500);
  };
}
