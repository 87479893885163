import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import { LoadableWrapper } from '../../../../utils/LoadableWrapper';
//
// const LoginRoute = LoadableWrapper({
//   loader: () =>
//     import(/* webpackChunkName: "auth" */ '../../../../pages/auth/login')
// });
// const RegisterRoute = LoadableWrapper({
//   loader: () =>
//     import(/* webpackChunkName: "auth" */ '../../../../pages/auth/register')
// });
// const ForgotRoute = LoadableWrapper({
//   loader: () =>
//     import(/* webpackChunkName: "auth" */ '../../../../pages/auth/forgot')
// });
//
// const ErrorPage = LoadableWrapper({
//   loader: () =>
//     import(
//       /* webpackChunkName: "errors" */ '../../../components/ErrorPage/ErrorPage'
//     )
// });

import { LoadableWrapper } from '../../../../utils/LoadableWrapper';
import { AuthAppLayout } from '../../../AppLayouts/AuthAppLayout';

const LoginPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth" */ '../../../../auth/pages/LoginPage/LoginPage'
    )
});
const AuthTokenLoginPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-token-login" */ '../../../../auth/pages/AuthTokenLoginPage/AuthTokenLoginPage'
    )
});
const FacebookTokenLoginPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-facebook-login" */ '../../../../auth//pages/FacebookTokenLoginPage/FacebookTokenLoginPage'
    )
});
const GoogleLoginPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-google-login" */ '../../../../auth//pages/GoogleLoginPage/GoogleLoginPage'
    )
});
const PolicyPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-policy-page" */ '../../../../auth//pages/PolicyPage/PolicyPage'
    )
});
const RegisterPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth" */ '../../../../auth//pages/RegisterPage/RegisterPage'
    )
});
const RegisterWorkerPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-register-worker" */ '../../../../auth//pages/RegisterWorkerPage/RegisterWorkerPage'
    )
});
const ResetPasswordPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-reset-password" */ '../../../../auth//pages/ResetPasswordPage/ResetPasswordPage'
    )
});
const UpdatePasswordPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "auth-reset-password" */ '../../../../auth//pages/UpdatePasswordPage/UpdatePasswordPage'
    )
});

interface AuthAppContainerProps {
  location: { search: string; pathname: string };
}

function AuthAppContainer({
  location: { search, pathname }
}: AuthAppContainerProps) {
  return (
    <AuthAppLayout search={search} pathname={pathname}>
      <Switch>
        <Route exact path="/auth/login" component={LoginPage} />
        <Route exact path="/auth/facebook" component={FacebookTokenLoginPage} />
        <Route exact path="/auth/token/:token" component={AuthTokenLoginPage} />
        <Route exact path="/auth/google" component={GoogleLoginPage} />
        <Route exact path="/auth/register" component={RegisterPage} />
        <Route
          exact
          path="/auth/register/worker"
          component={RegisterWorkerPage}
        />
        <Route exact path="/auth/forgot" component={ResetPasswordPage} />
        <Route
          exact
          path="/auth/update/:token"
          component={UpdatePasswordPage}
        />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/pages/:pageId(\d+)" component={PolicyPage} />
      </Switch>
    </AuthAppLayout>
  );

  // return (
  //   <Switch>
  //     <Route exact path="/auth/login" component={LoginRoute} />
  //     <Route exact path="/auth/register" component={RegisterRoute} />
  //     <Route exact path="/auth/forgot" component={ForgotRoute} />
  //     <Route
  //       key="error"
  //       render={props => <ErrorPage statusCode={404} {...props} />}
  //     />
  //   </Switch>
  // );
}

export default AuthAppContainer;
