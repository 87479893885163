import Promise from 'bluebird';
import { SubmissionError } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import { prepareErrors } from '../../utils/prepareErrors';

export default function mapErrorsToForm(dispatch, error, fn) {
  dispatch(fn);

  const errors = prepareErrors(error);

  if (!isEmpty(errors)) {
    throw new SubmissionError(errors);
  }

  return Promise.reject(error);
}
