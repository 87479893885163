import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const TemplateEditPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "templates-edit" */ './pages/TemplateEditPage/TemplateEditPage'
    )
});
const TemplatesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "templates" */ './pages/TemplatesIndexPage/TemplatesIndexPage'
    )
});
const LifestyleEditPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "lifestyles-edit" */ './pages/LifestyleEditPage/LifestyleEditPage'
    )
});
const LifestylesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "lifestyles" */ './pages/LifestylesIndexPage/LifestylesIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/templates/:templateId(\d+)"
      component={TemplateEditPage}
    />
    <Route exact path="/templates" component={TemplatesIndexPage} />
    <Route
      exact
      path="/lifestyles/:lifestyleId(\d+)"
      component={LifestyleEditPage}
    />
    <Route exact path="/lifestyles" component={LifestylesIndexPage} />
  </Fragment>
);
