import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  RECALCULATE_TASK_ACCOUNT,
  RECALCULATE_TASK_ACCOUNT_START,
  RECALCULATE_TASK_ACCOUNT_SUCCESS,
  RECALCULATE_TASK_ACCOUNT_ERROR
} from '../TaskConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* recalculateTaskAccount({ id, values, options, cb }) {
  yield put(postItemStart(RECALCULATE_TASK_ACCOUNT_START, id, options));

  const recalculateTaskAccountAction = yield spawn(postItemResource, `discussions/${id}/recalculate_account`, {
    success: RECALCULATE_TASK_ACCOUNT_SUCCESS,
    error: RECALCULATE_TASK_ACCOUNT_ERROR
  }, { id, values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(recalculateTaskAccountAction);
}

export default function* watchRecalculateTaskAccount() {
  yield takeEvery(RECALCULATE_TASK_ACCOUNT, recalculateTaskAccount);
}
