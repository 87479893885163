import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_TODO_ITEM,
  REMOVE_TODO_ITEM_START,
  REMOVE_TODO_ITEM_SUCCESS,
  REMOVE_TODO_ITEM_ERROR
} from '../TodoItemConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeTodoItem({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_TODO_ITEM_START, id, options));

  return yield spawn(removeItemResource, 'todo_items', {
    success: REMOVE_TODO_ITEM_SUCCESS,
    error: REMOVE_TODO_ITEM_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveTodoItem() {
  yield takeEvery(REMOVE_TODO_ITEM, removeTodoItem);
}
