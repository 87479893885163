import Promise from 'bluebird';

import { parseError } from '../../utils/parseError';
import { apiRequest } from '../../utils/apiRequest';

import {
  FETCH_POLICY_PAGE_START,
  FETCH_POLICY_PAGE_SUCCESS,
  FETCH_POLICY_PAGE_ERROR
} from '../PolicyPageConstants';

// fetchPolicyPage

export function fetchPolicyPageStart() {
  return {
    type: FETCH_POLICY_PAGE_START
  };
}

export function fetchPolicyPageSuccess(item = {}) {
  return {
    type: FETCH_POLICY_PAGE_SUCCESS,
    item
  };
}

export function fetchPolicyPageError(error) {
  return {
    type: FETCH_POLICY_PAGE_ERROR,
    errorMessage: parseError(error)
  };
}

export default function fetchPolicyPage(policyPageId) {
  return dispatch => {
    dispatch(fetchPolicyPageStart());

    return apiRequest.get(`pages/${policyPageId}`)
      .then(({ data: { data } }) => Promise.resolve(dispatch(fetchPolicyPageSuccess(data))))
      .catch(error => Promise.reject(dispatch(fetchPolicyPageError(error))));
  };
}
