import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_START,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_ERROR
} from '../TemplateConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTemplate({ values, options, cb }) {
  yield put(createItemStart(CREATE_TEMPLATE_START, options));

  return yield spawn(createItemResource, 'scenes', {
    success: CREATE_TEMPLATE_SUCCESS,
    error: CREATE_TEMPLATE_ERROR
  }, { values, options, cb });
}

export default function* watchCreateTemplate() {
  yield takeEvery(CREATE_TEMPLATE, createTemplate);
}
