// import Downloader from 'js-file-downloader';
// import FileSaver from 'file-saver';

// function customDownloadFile(sUrl) {
//   const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
//   const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
//   //iOS devices do not support downloading. We have to inform user about this.
//   if (/(iP)/g.test(navigator.userAgent)) {
//     window.open(sUrl, '_blank');
//     return false;
//   }

//   //If in Chrome or Safari - download via virtual link click
//   if (isChrome || isSafari) {
//     //Creating new link node.
//     const link = document.createElement('a');
//     link.href = sUrl;
//     link.target = '_blank';

//     if (link.download !== undefined) {
//       //Set HTML5 download attribute. This will prevent file from opening if supported.
//       const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
//       link.setAttribute('download', fileName);
//     }

//     //Dispatching click event.
//     if (document.createEvent) {
//       const e = document.createEvent('MouseEvents');
//       e.initEvent('click', true, true);
//       link.dispatchEvent(e);
//       return true;
//     }
//   }

//   // Force file download (whether supported by server).
//   if (sUrl.indexOf('?') === -1) {
//     sUrl += '?download';
//   }

//   window.open(sUrl, '_blank');
//   return true;
// }

export default function downloadFile(
  file,
  name = null,
  options = { withLink: false }
) {
  // new Downloader({
  //   url: file,
  //   autoStart: true
  // })
  //   .then(function(...args) {
  //     // console.log('downloadFile then', { args });
  //   })
  //   .catch(function(error) {
  //     console.log('downloadFile catch', { error });
  //   });
  // customDownloadFile(file);
  // if (options.withLink) {
    const link = document.createElement('a');
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  //   return file;
  // }
  //
  // return FileSaver.saveAs(file, name);
}
