import { updateState } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function emptyMaterialsSetStart(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: null,
      loading: true
    }
  }, options);
}

export function emptyMaterialsSetSuccess(state, { options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      selected_materials: [],
      error: null,
      loading: false
    }
  }, options);
}

export function emptyMaterialsSetError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    item: {
      ...currentStateValue(state, 'item', options),
      error: errorMessage,
      loading: false
    }
  }, options);
}
