import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_LINKED_ACCOUNTS,
  CREATE_LINKED_ACCOUNTS_START,
  CREATE_LINKED_ACCOUNTS_SUCCESS,
  CREATE_LINKED_ACCOUNTS_ERROR
} from '../LinkedAccountConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* createLinkedAccounts({ id, values, options, cb }) {
  yield put(postItemStart(CREATE_LINKED_ACCOUNTS_START, id, options));

  return yield spawn(postItemResource, `linked_users/${id}/link_users`, {
    success: CREATE_LINKED_ACCOUNTS_SUCCESS,
    error: CREATE_LINKED_ACCOUNTS_ERROR
  }, { id, values, options, cb });
}

export default function* watchCreateLinkedAccount() {
  yield takeEvery(CREATE_LINKED_ACCOUNTS, createLinkedAccounts);
}
