import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError, clearItemsFiltersStart, clearItemsFiltersSuccess
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_BILL_REFILLS_START: fetchItemsStart,
  FETCH_BILL_REFILLS_SUCCESS: fetchItemsSuccess,
  FETCH_BILL_REFILLS_ERROR: fetchItemsError,

  CLEAR_BILL_REFILLS_FILTERS_START: clearItemsFiltersStart,
  CLEAR_BILL_REFILLS_FILTERS_SUCCESS: clearItemsFiltersSuccess
});
