import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  CREATE_TAG,
  CREATE_TAG_START,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR
} from '../TagConstants';

import { createItemStart, createItemResource } from '../../common/sagas';

function* createTag({ values, options, cb }) {
  yield put(createItemStart(CREATE_TAG_START, options));

  return yield spawn(createItemResource, 'tags', {
    success: CREATE_TAG_SUCCESS,
    error: CREATE_TAG_ERROR
  }, { values, options, cb });
}

export default function* watchCreateTag() {
  yield takeEvery(CREATE_TAG, createTag);
}
