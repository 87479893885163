import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  FETCH_PAGES_START: fetchItemsStart,
  FETCH_PAGES_SUCCESS: fetchItemsSuccess,
  FETCH_PAGES_ERROR: fetchItemsError,

  CLEAR_PAGES_FILTERS_START: clearItemsFiltersStart,
  CLEAR_PAGES_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  FETCH_PAGE_START: fetchItemStart,
  FETCH_PAGE_SUCCESS: fetchItemSuccess,
  FETCH_PAGE_ERROR: fetchItemError,

  CREATE_PAGE_START: createItemStart,
  CREATE_PAGE_SUCCESS: createItemSuccess,
  CREATE_PAGE_ERROR: createItemError,

  UPDATE_PAGE_START: updateItemStart,
  UPDATE_PAGE_SUCCESS: updateItemSuccess,
  UPDATE_PAGE_ERROR: updateItemError,

  REMOVE_PAGE_START: removeItemStart,
  REMOVE_PAGE_SUCCESS: removeItemSuccess,
  REMOVE_PAGE_ERROR: removeItemError
});
