export default {
  'select_category...': 'Select product category...',
  add_new_to_the_library: 'Add new product to the library',
  add_plural: 'Add products',
  add: 'Add a product',
  category: 'Product category',
  edit: 'Edit product',
  enter_title: 'Enter product title',
  favorite: 'Favorite products',
  filter: 'Filter products',
  image: 'Product image',
  latest: 'Latest products',
  library: 'Products Library',
  model: 'Product 3d model',
  my: 'My Products',
  new: 'New product',
  no: 'No products',
  own: 'Own Products',
  plural_lower_case: 'products',
  plural: 'Products',
  selected: 'Selected products',
  singular: 'Product',
  stock: 'Products Stock',
  title: 'Product title'
};
