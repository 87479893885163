type ItemWithExpiry<T> = {
  value: T;
  expiry: number;
};

class LocalStorage {
  static loadItem<T>(key: string): T | null {
    const value: any = localStorage.getItem(key);

    if (!value) return null;

    return JSON.parse(value) as T;
  }

  static saveItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static loadItemWithExpiry<T>(key: string): T | null {
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    const item = JSON.parse(value) as ItemWithExpiry<T>;

    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  static saveItemWithExpiry<T>(key: string, value: T, ttl: number): void {
    const now = new Date();

    const item: ItemWithExpiry<T> = {
      value: value,
      expiry: now.getTime() + ttl
    };

    localStorage.setItem(key, JSON.stringify(item));
  }
}

export default LocalStorage;
