import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  OVERWATCH_USERS,
  OVERWATCH_USERS_START,
  OVERWATCH_USERS_SUCCESS,
  OVERWATCH_USERS_ERROR
} from '../UserConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* overwatchUsers({ values, options, cb }) {
  yield put(postItemStart(OVERWATCH_USERS_START, options));

  return yield spawn(
    postItemResource,
    'users/overwatch_users',
    {
      success: OVERWATCH_USERS_SUCCESS,
      error: OVERWATCH_USERS_ERROR
    },
    { values, options, cb }
  );
}

export default function* watchOverwatchUsers() {
  yield takeEvery(OVERWATCH_USERS, overwatchUsers);
}
