import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  EMPTY_TEMPLATES_SET,
  EMPTY_TEMPLATES_SET_START,
  EMPTY_TEMPLATES_SET_SUCCESS,
  EMPTY_TEMPLATES_SET_ERROR
} from '../TemplatesSetConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* emptyTemplatesSet({ id, options, cb }) {
  yield put(postItemStart(EMPTY_TEMPLATES_SET_START, id, options));

  const emptyTemplatesSetAction = yield spawn(postItemResource, `scenes_sets/${id}/empty`, {
    success: EMPTY_TEMPLATES_SET_SUCCESS,
    error: EMPTY_TEMPLATES_SET_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(emptyTemplatesSetAction);
}

export default function* watchEmptyTemplatesSet() {
  yield takeEvery(EMPTY_TEMPLATES_SET, emptyTemplatesSet);
}
