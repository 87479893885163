import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  removeItemStart, removeItemSuccess, removeItemError,
  itemsChannelCreated, itemsChannelUpdated, itemsChannelDestroyed,
  clearModalValues
} from '../../common/reducers';

import { checkDownloadStart, checkDownloadSuccess, checkDownloadError } from './checkDownload';
import { dropdownDownloadsChannelCreated } from './dropdownDownloadsChannelCreated';
import { locationChange } from './common';
import { setDownloadsBoxOpened } from './setDownloadsBoxOpened';

export default createReducer({
  ...defaultState,
  downloadsBoxOpened: false
}, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  FETCH_DOWNLOADS_START: fetchItemsStart,
  FETCH_DOWNLOADS_SUCCESS: fetchItemsSuccess,
  FETCH_DOWNLOADS_ERROR: fetchItemsError,

  FETCH_DOWNLOAD_START: fetchItemStart,
  FETCH_DOWNLOAD_SUCCESS: fetchItemSuccess,
  FETCH_DOWNLOAD_ERROR: fetchItemError,

  UPDATE_DOWNLOAD_START: updateItemStart,
  UPDATE_DOWNLOAD_SUCCESS: updateItemSuccess,
  UPDATE_DOWNLOAD_ERROR: updateItemError,

  REMOVE_DOWNLOAD_START: removeItemStart,
  REMOVE_DOWNLOAD_SUCCESS: removeItemSuccess,
  REMOVE_DOWNLOAD_ERROR: removeItemError,

  CHECK_DOWNLOAD_START: checkDownloadStart,
  CHECK_DOWNLOAD_SUCCESS: checkDownloadSuccess,
  CHECK_DOWNLOAD_ERROR: checkDownloadError,

  SET_DOWNLOADS_BOX_OPENED: setDownloadsBoxOpened,

  CLEAR_DOWNLOAD_MODAL_VALUES: clearModalValues,

  DROPDOWN_DOWNLOADS_CHANNEL_CREATED: dropdownDownloadsChannelCreated,

  DOWNLOADS_CHANNEL_CREATED: itemsChannelCreated,
  DOWNLOADS_CHANNEL_UPDATED: itemsChannelUpdated,
  DOWNLOADS_CHANNEL_DESTROYED: itemsChannelDestroyed
});
