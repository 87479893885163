import React, { Fragment, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cl from 'classnames';

import { Tooltip, TooltipProps } from '../Tooltip';

export interface LinkToProps {
  /**
   * @deprecated You should use hooks instead of using 'data' parameter
   */
  data?: {};
  id?: string;
  className?: string;
  addClass?: string;
  url?: string;
  href?: string;
  button?: boolean;
  color?: string;
  size?: string;
  icon?: string | ReactNode;
  xsButtonFix?: boolean;
  buttonTopFix?: boolean;
  outline?: boolean;
  rounded?: boolean;
  flat?: boolean;
  disabled?: boolean;
  active?: boolean;
  pureLink?: boolean;
  onClick?: (arg: {} | React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  target?: string;
  tabIndex?: number;
  title?: string;
  style?: {};
  children: ReactNode;
}

function LinkTo({
  id,
  data,
  className,
  addClass,
  url,
  href,
  button,
  color,
  size,
  icon,
  xsButtonFix,
  buttonTopFix,
  outline,
  rounded,
  flat,
  active,
  pureLink,
  disabled,
  onClick,
  onMouseDown,
  children,
  target,
  tabIndex,
  tooltipTarget,
  tooltipI18nValue,
  tooltipPlacement,
  tooltipDelay,
  style,
  title,
  ...props
}: LinkToProps & TooltipProps) {
  const linkUrl = url || href;

  const buttonClass =
    className ||
    cl({
      btn: button,
      [`btn-${color}`]: !!color,
      [`btn-${size}`]: !!size,
      ...(addClass ? { [addClass]: !!addClass } : {}),
      'b-m-b-xs': xsButtonFix,
      'b-button-top-fix': buttonTopFix,
      'btn-outline': outline,
      'btn-rounded': rounded,
      'btn-flat': flat,
      disabled,
      active
    });

  const linkIcon = icon ? (
    <i className={cl(icon, { 'mr-2': children })} />
  ) : null;

  const handlePureLinkClick = useCallback(
    e => {
      if (disabled) {
        e.preventDefault();
        return;
      }

      if (onClick && !disabled) {
        e.preventDefault();
        onClick(data || e);
      }
    },
    [disabled, onClick, data]
  );

  const handleClick = useCallback(
    e => {
      if (disabled) {
        e.preventDefault();
      }
    },
    [disabled]
  );

  if (pureLink) {
    return (
      <Fragment>
        <a
          id={id || tooltipTarget}
          className={cl(buttonClass)}
          href={linkUrl}
          target={target}
          onMouseDown={onMouseDown}
          onClick={handlePureLinkClick}
          tabIndex={tabIndex}
          title={title}
          style={style}
          {...props}
        >
          {linkIcon}
          {children}
        </a>
        {tooltipTarget ? (
          <Tooltip
            placement={tooltipPlacement}
            target={tooltipTarget}
            delay={tooltipDelay}
            i18nText={tooltipI18nValue}
          />
        ) : null}
      </Fragment>
    );
  }

  return (
    <Link
      id={id || tooltipTarget}
      className={cl(buttonClass)}
      to={`/${linkUrl}`}
      target={target}
      tabIndex={tabIndex}
      title={title}
      style={style}
      onClick={handleClick}
      {...props}
    >
      {linkIcon}
      {linkIcon && children ? ' ' : null}
      {children}
      {tooltipTarget ? (
        <Tooltip
          placement={tooltipPlacement}
          target={tooltipTarget}
          delay={tooltipDelay}
          i18nText={tooltipI18nValue}
        />
      ) : null}
    </Link>
  );
}

export default LinkTo;
