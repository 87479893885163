import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_START,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from '../ReportConstants';

import { downloadItemStart, downloadItemResource } from '../../common/sagas';

function* downloadReport({ id, values, options, cb }) {
  yield put(downloadItemStart(DOWNLOAD_REPORT_START, id, options));

  const downloadReportAction = yield spawn(downloadItemResource, `reports/${id}/download`, {
    success: DOWNLOAD_REPORT_SUCCESS,
    error: DOWNLOAD_REPORT_ERROR
  }, { id, options: { ...options, values }, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadReportAction);
}

export default function* watchDownloadReport() {
  yield takeEvery(DOWNLOAD_REPORT, downloadReport);
}
