import get from 'lodash/get';

import { Promise } from '../../../../../../../adapters/libs/bluebird';

import { postItemStart } from '../../actions/postItemStart';
import { postItemSuccess } from '../../actions/postItemSuccess';
import { postItemError } from '../../actions/postItemError';

import { PostItemRequestAction } from '../../reducers/postItemRequestReducer';

import { apiRequest } from '../../../../../../../utils/apiRequest';
import { parseError } from '../../../../../../../utils/ts/parseError';

interface RequestConfig {
  serializer?: string;
  values: object;
}

export default function sendPostItemRequest<ItemType>(
  url: string,
  requestConfig: RequestConfig,
  dispatch: (action: PostItemRequestAction<ItemType>) => void
) {
  dispatch(postItemStart<ItemType>());
  return new Promise<void>((resolve, reject) =>
    apiRequest
      .post(url, get(requestConfig, 'values', {}))
      .then(successResponse => {
        const item = get(successResponse, 'data.data');
        dispatch(postItemSuccess<ItemType>(item));
        resolve(item);
      })
      .catch(errorResponse => {
        dispatch(postItemError<ItemType>(parseError(errorResponse)));
        reject(parseError(errorResponse));
      })
  );
}
