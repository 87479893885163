export const FETCH_BILLS = 'FETCH_BILLS';
export const FETCH_BILLS_START = 'FETCH_BILLS_START';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_ERROR = 'FETCH_BILLS_ERROR';

export const FETCH_BILL = 'FETCH_BILL';
export const FETCH_BILL_START = 'FETCH_BILL_START';
export const FETCH_BILL_SUCCESS = 'FETCH_BILL_SUCCESS';
export const FETCH_BILL_ERROR = 'FETCH_BILL_ERROR';

export const CLEAR_BILL_MODAL_VALUES = 'CLEAR_BILL_MODAL_VALUES';

export const CLEAR_BILLS_FILTERS_START = 'CLEAR_BILLS_FILTERS_START';
export const CLEAR_BILLS_FILTERS_SUCCESS = 'CLEAR_BILLS_FILTERS_SUCCESS';

export const DOWNLOAD_BILLS_XLS = 'DOWNLOAD_BILLS_XLS';
export const DOWNLOAD_BILLS_XLS_START = 'DOWNLOAD_BILLS_XLS_START';
export const DOWNLOAD_BILLS_XLS_SUCCESS = 'DOWNLOAD_BILLS_XLS_SUCCESS';
export const DOWNLOAD_BILLS_XLS_ERROR = 'DOWNLOAD_BILLS_XLS_ERROR';

export const PAY_BILL_FROM_ACCOUNT = 'PAY_BILL_FROM_ACCOUNT';
export const PAY_BILL_FROM_ACCOUNT_START = 'PAY_BILL_FROM_ACCOUNT_START';
export const PAY_BILL_FROM_ACCOUNT_SUCCESS = 'PAY_BILL_FROM_ACCOUNT_SUCCESS';
export const PAY_BILL_FROM_ACCOUNT_ERROR = 'PAY_BILL_FROM_ACCOUNT_ERROR';

export const CHECK_BILL_PAYMENT = 'CHECK_BILL_PAYMENT';
export const CHECK_BILL_PAYMENT_START = 'CHECK_BILL_PAYMENT_START';
export const CHECK_BILL_PAYMENT_SUCCESS = 'CHECK_BILL_PAYMENT_SUCCESS';
export const CHECK_BILL_PAYMENT_ERROR = 'CHECK_BILL_PAYMENT_ERROR';

export const STRIPE_PAYMENT = 'STRIPE_PAYMENT';
export const STRIPE_PAYMENT_START = 'STRIPE_PAYMENT_START';
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_ERROR = 'STRIPE_PAYMENT_ERROR';

export const CANCEL_BILL = 'CANCEL_BILL';
export const CANCEL_BILL_START = 'CANCEL_BILL_START';
export const CANCEL_BILL_SUCCESS = 'CANCEL_BILL_SUCCESS';
export const CANCEL_BILL_ERROR = 'CANCEL_BILL_ERROR';

export const SET_BILL_PAYING = 'SET_BILL_PAYING';
export const SET_BILL_PAYING_START = 'SET_BILL_PAYING_START';
export const SET_BILL_PAYING_SUCCESS = 'SET_BILL_PAYING_SUCCESS';
export const SET_BILL_PAYING_ERROR = 'SET_BILL_PAYING_ERROR';

export const CHECK_ORDER_PAYMENT = 'CHECK_ORDER_PAYMENT';
export const CHECK_ORDER_PAYMENT_START = 'CHECK_ORDER_PAYMENT_START';
export const CHECK_ORDER_PAYMENT_SUCCESS = 'CHECK_ORDER_PAYMENT_SUCCESS';
export const CHECK_ORDER_PAYMENT_ERROR = 'CHECK_ORDER_PAYMENT_ERROR';
