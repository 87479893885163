import pluralize from 'pluralize';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import upperFirst from 'lodash/upperFirst';

import { EMPTY_ARRAY, EMPTY_OBJECT } from './lodash-id';

export default function providePropsToChildren(scopeOrObj, {
  fetchItems, loadMoreItems, changeItemsFilter, clearItemsFilters, removeItemsFilters, clearModalValues,
  fetchItem, createItem, updateItem, removeItem, subscribeToItemsChannel, unsubscribeFromItemsChannel,
  removeSubscriptions
}, { namespace, ...props }, additionalProps) {
  const scope = isObject(scopeOrObj) ? scopeOrObj.plural : scopeOrObj;
  const singularScope = isObject(scopeOrObj) ? scopeOrObj.singular : pluralize.singular(scopeOrObj);

  return {
    currentUser: get(props, 'currentUser'),
    [scope]: get(props, 'items', EMPTY_ARRAY),
    [`${scope}Meta`]: get(props, 'itemsMeta', EMPTY_OBJECT),
    [`${scope}Loading`]: get(props, 'itemsLoading', false),
    [`${scope}Fetched`]: get(props, 'itemsFetched', false),
    [`${scope}Uploading`]: get(props, 'itemsUploading', false),
    [`${scope}UploadErrorMessage`]: get(props, 'itemsUploadErrorMessage', null),
    [`${scope}SuccessMessage`]: get(props, 'itemsSuccessMessage', null),
    [`${scope}ErrorMessage`]: get(props, 'itemsErrorMessage', null),
    [`${scope}FiltersCleared`]: get(props, 'filtersCleared', false),
    [singularScope]: get(props, 'item', EMPTY_OBJECT),
    [`${singularScope}Loading`]: get(props, 'itemLoading', false),
    [`${singularScope}Fetched`]: get(props, 'itemFetched', false),
    [`${singularScope}SuccessMessage`]: get(props, 'itemSuccessMessage', null),
    [`${singularScope}ErrorMessage`]: get(props, 'itemErrorMessage', null),
    [`${singularScope}CreateSuccessMessage`]: get(props, 'itemCreateSuccessMessage', null),
    [`${singularScope}UpdateSuccessMessage`]: get(props, 'itemUpdateSuccessMessage', null),
    [`${singularScope}RemoveSuccessMessage`]: get(props, 'itemRemoveSuccessMessage', null),
    [`${singularScope}CreateErrorMessage`]: get(props, 'itemCreateErrorMessage', null),
    [`${singularScope}UpdateErrorMessage`]: get(props, 'itemUpdateErrorMessage', null),
    [`${singularScope}RemoveErrorMessage`]: get(props, 'itemRemoveErrorMessage', null),
    isLoading: get(props, 'isLoading', false),
    errorMessage: get(props, 'errorMessage', false),
    ...(namespace ? { [namespace]: props[namespace] } : EMPTY_OBJECT),
    ...(namespace ? {
      [`${namespace}-items`]: get(props, `${namespace}-items`, EMPTY_ARRAY),
      [`${namespace}-itemsMeta`]: get(props, `${namespace}-itemsMeta`, EMPTY_OBJECT),
      [`${namespace}-itemsLoading`]: get(props, `${namespace}-itemsLoading`, false),
      [`${namespace}-itemsFetched`]: get(props, `${namespace}-itemsFetched`, false),
      [`${namespace}-itemsSuccessMessage`]: get(props, `${namespace}-itemsSuccessMessage`, null),
      [`${namespace}-itemsErrorMessage`]: get(props, `${namespace}-itemsErrorMessage`, null),
      [`${namespace}-itemsUploading`]: get(props, `${namespace}-itemsUploading`, false),
      [`${namespace}-itemsUploadErrorMessage`]: get(props, `${namespace}-itemsUploadErrorMessage`, null),
      [`${namespace}-filtersCleared`]: get(props, `${namespace}-filtersCleared`, false),
      [`${namespace}-itemLoading`]: get(props, `${namespace}-itemLoading`, false),
      [`${namespace}-itemFetched`]: get(props, `${namespace}-itemFetched`, false),
      [`${namespace}-itemSuccessMessage`]: get(props, `${namespace}-itemSuccessMessage`, null),
      [`${namespace}-itemErrorMessage`]: get(props, `${namespace}-itemErrorMessage`, null),
      [`${namespace}-itemCreateSuccessMessage`]: get(props, `${namespace}-itemCreateSuccessMessage`, null),
      [`${namespace}-itemUpdateSuccessMessage`]: get(props, `${namespace}-itemUpdateSuccessMessage`, null),
      [`${namespace}-itemRemoveSuccessMessage`]: get(props, `${namespace}-itemRemoveSuccessMessage`, null),
      [`${namespace}-itemCreateErrorMessage`]: get(props, `${namespace}-itemCreateErrorMessage`, null),
      [`${namespace}-itemUpdateErrorMessage`]: get(props, `${namespace}-itemUpdateErrorMessage`, null),
      [`${namespace}-itemRemoveErrorMessage`]: get(props, `${namespace}-itemRemoveErrorMessage`, null),
      [`${namespace}-isLoading`]: get(props, `${namespace}-isLoading`, false),
      [`${namespace}-successMessage`]: get(props, `${namespace}-successMessage`, null),
      [`${namespace}-errorMessage`]: get(props, `${namespace}-errorMessage`, null)
    } : {}),
    ...additionalProps,
    [`fetch${upperFirst(scope)}`]: fetchItems,
    [`loadMore${upperFirst(scope)}`]: loadMoreItems,
    [`change${upperFirst(scope)}Filter`]: changeItemsFilter,
    [`clear${upperFirst(scope)}Filters`]: clearItemsFilters,
    [`remove${upperFirst(scope)}Filters`]: removeItemsFilters,
    [`clear${upperFirst(singularScope)}ModalValues`]: clearModalValues,
    [`fetch${upperFirst(singularScope)}`]: fetchItem,
    [`create${upperFirst(singularScope)}`]: createItem,
    [`update${upperFirst(singularScope)}`]: updateItem,
    [`remove${upperFirst(singularScope)}`]: removeItem,
    [`subscribeTo${upperFirst(scope)}Channel`]: subscribeToItemsChannel,
    [`unsubscribeFrom${upperFirst(scope)}Channel`]: unsubscribeFromItemsChannel,
    removeSubscriptions,
    ...pickBy(props, (value, key) => /^parent/.test(key) ? value : null)
  };
}
