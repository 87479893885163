import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_TEXTURE_FILE,
  UPDATE_TEXTURE_FILE_START,
  UPDATE_TEXTURE_FILE_SUCCESS,
  UPDATE_TEXTURE_FILE_ERROR
} from '../TextureFileConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateTextureFile({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_TEXTURE_FILE_START, id, options));

  return yield spawn(updateItemResource, 'texture_files', {
    success: UPDATE_TEXTURE_FILE_SUCCESS,
    error: UPDATE_TEXTURE_FILE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateTextureFile() {
  yield takeEvery(UPDATE_TEXTURE_FILE, updateTextureFile);
}
