import { updateState } from '../../../utils';

export function updateTaskProjectEstimateCostStart(state, { options = {} }) {
  return updateState(state, {
    itemLoading: true,
    taskErrorMessage: null
  }, options);
}

export function updateTaskProjectEstimateCostSuccess(state, { options = {} }) {
  return updateState(state, {
    itemLoading: false,
    taskErrorMessage: null
  }, options);
}

export function updateTaskProjectEstimateCostError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    itemLoading: false,
    taskErrorMessage: errorMessage
  }, options);
}
