import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  ACCEPT_TASKS_RESULTS,
  ACCEPT_TASKS_RESULTS_START,
  ACCEPT_TASKS_RESULTS_SUCCESS,
  ACCEPT_TASKS_RESULTS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* acceptTasksResults({ values, options, cb }) {
  yield put(postItemsStart(ACCEPT_TASKS_RESULTS_START, options));

  const acceptTasksResultsAction = yield spawn(
    postItemsResource,
    'discussions/batch_accept_results',
    {
      success: ACCEPT_TASKS_RESULTS_SUCCESS,
      error: ACCEPT_TASKS_RESULTS_ERROR
    },
    { values, options, cb }
  );

  yield take(LOCATION_CHANGE);
  yield cancel(acceptTasksResultsAction);
}

export default function* watchAcceptTasksResults() {
  yield takeEvery(ACCEPT_TASKS_RESULTS, acceptTasksResults);
}
