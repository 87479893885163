import { updateState } from '../../../utils';

export function clearContactModalValues(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null,
    itemErrorMessage: null,
    itemCreateErrorMessage: null,
    itemCreateSuccessMessage: null
  }, options);
}
