import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_USER,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR
} from '../UserConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateUser({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_USER_START, id, options));

  return yield spawn(updateItemResource, 'users', {
    success: UPDATE_USER_SUCCESS,
    error: UPDATE_USER_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}
