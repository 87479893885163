import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  FETCH_REPORTS,
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR
} from '../ReportConstants';

import { fetchItemsStart, fetchItemsResource } from '../../common/sagas';

function* fetchReports(params) {
  yield put(fetchItemsStart(FETCH_REPORTS_START, params));

  const fetchReportsAction = yield spawn(fetchItemsResource, 'reports', {
    success: FETCH_REPORTS_SUCCESS,
    error: FETCH_REPORTS_ERROR
  }, params);

  yield take(LOCATION_CHANGE);
  yield cancel(fetchReportsAction);
}

export default function* watchFetchReports() {
  yield takeEvery(FETCH_REPORTS, fetchReports);
}
