export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_START = 'FETCH_IMAGES_START';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_ERROR = 'FETCH_IMAGES_ERROR';

export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_IMAGE_START = 'UPDATE_IMAGE_START';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_ERROR = 'UPDATE_IMAGE_ERROR';

export const DOWNLOAD_IMAGE = 'DOWNLOAD_IMAGE';
export const DOWNLOAD_IMAGE_START = 'DOWNLOAD_IMAGE_START';
export const DOWNLOAD_IMAGE_SUCCESS = 'DOWNLOAD_IMAGE_SUCCESS';
export const DOWNLOAD_IMAGE_ERROR = 'DOWNLOAD_IMAGE_ERROR';

export const UPLOAD_IMAGES = 'UPLOAD_IMAGES';
export const UPLOAD_IMAGES_START = 'UPLOAD_IMAGES_START';
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_ERROR = 'UPLOAD_IMAGES_ERROR';

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const REMOVE_IMAGE_START = 'REMOVE_IMAGE_START';
export const REMOVE_IMAGE_SUCCESS = 'REMOVE_IMAGE_SUCCESS';
export const REMOVE_IMAGE_ERROR = 'REMOVE_IMAGE_ERROR';

export const CLEAR_IMAGE_MODAL_VALUES = 'CLEAR_IMAGE_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_IMAGES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_IMAGES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_IMAGES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_IMAGES_CHANNEL';
