export default {
  plural: 'Pending payments',
  singular: 'Pending payment',
  types: {
    bad_debt: 'Bad debt',
    debt: 'Debt',
    underpayment: 'Underpayment'
  },
  no: 'No pending payments'
};
