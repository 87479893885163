import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  CREATE_TASKS_BILLS,
  CREATE_TASKS_BILLS_START,
  CREATE_TASKS_BILLS_SUCCESS,
  CREATE_TASKS_BILLS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* createTasksBills({ values, options, cb }) {
  yield put(postItemsStart(CREATE_TASKS_BILLS_START, options));

  const createTasksBillsAction = yield spawn(postItemsResource, 'discussions/create_bills', {
    success: CREATE_TASKS_BILLS_SUCCESS,
    error: CREATE_TASKS_BILLS_ERROR
  }, { values, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(createTasksBillsAction);
}

export default function* watchCreateTasksBills() {
  yield takeEvery(CREATE_TASKS_BILLS, createTasksBills);
}

// import { takeEvery, put, call, spawn } from 'redux-saga/effects';
// import { stopSubmit } from 'redux-form';
//
// import {
//   CREATE_TASKS_BILLS,
//   CREATE_TASKS_BILLS_START,
//   CREATE_TASKS_BILLS_SUCCESS,
//   CREATE_TASKS_BILLS_ERROR
// } from '../TaskConstants';
//
// import { apiRequest, prepareErrors, parseError } from '../../../utils';
//
// export function createTasksBillsStart() {
//   return {
//     type: CREATE_TASKS_BILLS_START
//   };
// }
//
// export function createTasksBillsSuccess(item = {}) {
//   return {
//     type: CREATE_TASKS_BILLS_SUCCESS,
//     item
//   };
// }
//
// export function createTasksBillsError(error) {
//   return {
//     type: CREATE_TASKS_BILLS_ERROR,
//     errorMessage: parseError(error),
//     error
//   };
// }
//
// function createTasksBillsRequest(values) {
//   return apiRequest.post('discussions/create_bills', values);
// }
//
// function* createTasksBillsResource({ values, options: { form }, cb }) {
//   try {
//     yield call(createTasksBillsRequest, values);
//     yield put(createTasksBillsSuccess());
//     cb && cb();
//   } catch (error) {
//     yield put(createTasksBillsError(error));
//     yield form && put(stopSubmit(form, prepareErrors(error)));
//   }
// }
//
// function* createTasksBills({ values, options, cb }) {
//   yield put(createTasksBillsStart());
//
//   return yield spawn(createTasksBillsResource, { values, options, cb });
// }
//
// export default function* watchCreateTasksBills() {
//   yield takeEvery(CREATE_TASKS_BILLS, createTasksBills);
// }
