import { SOCKET_SUBSCRIBE_TO_DROPDOWN_MESSAGES_CHANNEL } from '../MessageConstants';

export default function subscribeToDropdownMessagesChannel(action, userId, params = {}) {
  return dispatch => dispatch({
    type: SOCKET_SUBSCRIBE_TO_DROPDOWN_MESSAGES_CHANNEL,
    channel: 'Archi::Crm::DropdownMessagesChannel',
    command: 'subscribe',
    action,
    params
  });
}
