import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  SET_SHARED_ALLOCATION,
  SET_SHARED_ALLOCATION_START,
  SET_SHARED_ALLOCATION_SUCCESS,
  SET_SHARED_ALLOCATION_ERROR
} from '../AllocationConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* setSharedAllocation({ id, options, cb }) {
  yield put(postItemStart(SET_SHARED_ALLOCATION_START, id, options));

  return yield spawn(postItemResource, `discussion_funds_allocations/${id}/set_allocated`, {
    success: SET_SHARED_ALLOCATION_SUCCESS,
    error: SET_SHARED_ALLOCATION_ERROR
  }, { id, options, cb });
}

export default function* watchSetSharedAllocation() {
  yield takeEvery(SET_SHARED_ALLOCATION, setSharedAllocation);
}
