import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemStart, fetchItemSuccess, fetchItemError
} from '../../common/reducers';

import {
  createTemplatesSetSelectedTemplatesSuccess
} from './createTemplatesSetSelectedTemplates';

import {
  emptyTemplatesSetStart,
  emptyTemplatesSetSuccess,
  emptyTemplatesSetError
} from './emptyTemplatesSet';

import {
  removeTemplatesSetSelectedTemplatesError,
  removeTemplatesSetSelectedTemplatesStart,
  removeTemplatesSetSelectedTemplatesSuccess
} from './removeTemplatesSetSelectedTemplates';

export default createReducer(defaultState, {
  FETCH_TEMPLATES_SET_START: fetchItemStart,
  FETCH_TEMPLATES_SET_SUCCESS: fetchItemSuccess,
  FETCH_TEMPLATES_SET_ERROR: fetchItemError,

  CREATE_SELECTED_TEMPLATE_SUCCESS: createTemplatesSetSelectedTemplatesSuccess,

  REMOVE_SELECTED_TEMPLATE_START: removeTemplatesSetSelectedTemplatesStart,
  REMOVE_SELECTED_TEMPLATE_SUCCESS: removeTemplatesSetSelectedTemplatesSuccess,
  REMOVE_SELECTED_TEMPLATE_ERROR: removeTemplatesSetSelectedTemplatesError,

  EMPTY_TEMPLATES_SET_START: emptyTemplatesSetStart,
  EMPTY_TEMPLATES_SET_SUCCESS: emptyTemplatesSetSuccess,
  EMPTY_TEMPLATES_SET_ERROR: emptyTemplatesSetError
});
