import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TAG,
  FETCH_TAG_START,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_ERROR
} from '../TagConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTag({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TAG_START, id, options));

  const fetchTagAction = yield spawn(fetchItemResource, 'tags', {
    success: FETCH_TAG_SUCCESS,
    error: FETCH_TAG_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTagAction);
}

export default function* watchFetchTag() {
  yield takeEvery(FETCH_TAG, fetchTag);
}
