import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  UPDATE_PROJECT_PROFILE,
  UPDATE_PROJECT_PROFILE_START,
  UPDATE_PROJECT_PROFILE_SUCCESS,
  UPDATE_PROJECT_PROFILE_ERROR
} from '../ProjectProfileConstants';

import { updateItemStart, updateItemResource } from '../../common/sagas';

function* updateProjectProfile({ id, values, options, cb }) {
  yield put(updateItemStart(UPDATE_PROJECT_PROFILE_START, id, options));

  return yield spawn(updateItemResource, 'task_profiles', {
    success: UPDATE_PROJECT_PROFILE_SUCCESS,
    error: UPDATE_PROJECT_PROFILE_ERROR
  }, { id, values, options, cb });
}

export default function* watchUpdateProjectProfile() {
  yield takeEvery(UPDATE_PROJECT_PROFILE, updateProjectProfile);
}
