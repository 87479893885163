import { FETCH_ITEM_START } from '../../constants';

import { ShowFinanceRequestAction } from '../../reducers/showFinanceRequestReducer';

export default function fetchItemStart<ItemType>(): ShowFinanceRequestAction<
  ItemType
> {
  return {
    type: FETCH_ITEM_START
  };
}
