import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  removeItemStart, removeItemSuccess, removeItemError,
  uploadItemsStart, uploadItemsSuccess, uploadItemsError,
  uploadItemStart, uploadItemSuccess, uploadItemError,
  locationChange, clearModalValues
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  REMOVE_MAX_FILE_START: removeItemStart,
  REMOVE_MAX_FILE_SUCCESS: removeItemSuccess,
  REMOVE_MAX_FILE_ERROR: removeItemError,

  UPLOAD_MAX_FILES_START: uploadItemsStart,
  UPLOAD_MAX_FILES_SUCCESS: uploadItemsSuccess,
  UPLOAD_MAX_FILES_ERROR: uploadItemsError,

  UPLOAD_MAX_FILE_START: uploadItemStart,
  UPLOAD_MAX_FILE_SUCCESS: uploadItemSuccess,
  UPLOAD_MAX_FILE_ERROR: uploadItemError,

  CLEAR_MAX_FILE_MODAL_VALUES: clearModalValues
});
