import get from 'lodash/get';

import { updateState, replaceById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function updateMessageFileAttachmentsStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      file_attachments: replaceById(get(message, 'file_attachments', []), id, fileAttachment => ({
        ...fileAttachment,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function updateMessageFileAttachmentsSuccess(state, { id, item, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      file_attachments: replaceById(get(message, 'file_attachments', []), id, fileAttachment => ({
        ...fileAttachment,
        ...item,
        error: null,
        loading: false
      }))
    }))
  }, options);
}

export function updateMessageFileAttachmentsError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      file_attachments: replaceById(get(message, 'file_attachments', []), id, fileAttachment => ({
        ...fileAttachment,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
