import React from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { ErrorMessage, IsFetched } from '../../../../../../../../types';
import { BillType } from '../../../../../../../common/hooks/bills/types';

import { ItemBillsModalTable } from '../../components/ItemBillsModalTable';

import { AlertMessage } from '../../../../../../../../helpers/ts/AlertMessage';
import { Loading } from '../../../../../../../../helpers/ts/Loading';
import { Translate } from '../../../../../../../../helpers/ts/Translate';

interface ItemBillsModalBodyProps {
  bill: BillType | null;
  billErrorMessage: ErrorMessage;
  billFetched: IsFetched;
}

function ItemBillsModalBody({
  bill,
  billErrorMessage,
  billFetched
}: ItemBillsModalBodyProps) {
  const payoneerPayingI18nErrorMessage =
    get<BillType, 'type'>(bill, 'type') === 'payoneer_bill' &&
    get<BillType, 'paying'>(bill, 'paying')
      ? 'messages.this_payment_is_already_processing'
      : null;

  const payoneerI18nErrorMessage =
    get<BillType, 'type'>(bill, 'type') === 'payoneer_bill' &&
    isNull(get<BillType, 'payment_url'>(bill, 'payment_url'))
      ? 'messages.we_re_sorry_but_there_was_an_error_processing_your_request_please_try_again_later'
      : null;

  return (
    <ModalBody>
      <Loading item={bill} loaded={billFetched}>
        <ItemBillsModalTable bill={bill} />

        {bill && bill.type === 'payoneer_bill' ? (
          <div className="mt-3">
            <Translate id="messages.by_using_the_payoneer_services_through_the_online_pages_and_apis" />
          </div>
        ) : null}

        <AlertMessage
          i18nMessage={
            payoneerPayingI18nErrorMessage || payoneerI18nErrorMessage
          }
          message={billErrorMessage}
          rowClassName="mt-3"
        />
      </Loading>
    </ModalBody>
  );
}

export default ItemBillsModalBody;
