import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  FORWARD_MESSAGE,
  FORWARD_MESSAGE_START,
  FORWARD_MESSAGE_SUCCESS,
  FORWARD_MESSAGE_ERROR
} from '../MessageConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* forwardMessageRequest({ id, values, options, cb }) {
  yield put(postItemStart(FORWARD_MESSAGE_START, id, options));

  return yield spawn(postItemResource, `discussion_messages/${id}/forward`, {
    success: FORWARD_MESSAGE_SUCCESS,
    error: FORWARD_MESSAGE_ERROR
  }, { id, values, options, cb });
}

export default function* watchForwardMessageRequest() {
  yield takeEvery(FORWARD_MESSAGE, forwardMessageRequest);
}
