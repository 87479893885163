import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_FILE_ATTACHMENT,
  REMOVE_FILE_ATTACHMENT_START,
  REMOVE_FILE_ATTACHMENT_SUCCESS,
  REMOVE_FILE_ATTACHMENT_ERROR
} from '../FileAttachmentConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeFileAttachment({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_FILE_ATTACHMENT_START, id, options));

  return yield spawn(removeItemResource, 'file_attachments', {
    success: REMOVE_FILE_ATTACHMENT_SUCCESS,
    error: REMOVE_FILE_ATTACHMENT_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveFileAttachment() {
  yield takeEvery(REMOVE_FILE_ATTACHMENT, removeFileAttachment);
}
