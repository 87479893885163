import { updateState } from '../../../utils';

export function submitMessageFileAttachmentsStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function submitMessageFileAttachmentsSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function submitMessageFileAttachmentsError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
