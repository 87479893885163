import { FETCH_CONTACTS } from '../ContactConstants';

// fetchContacts

export default function fetchContacts(params, cb) {
  return dispatch => dispatch({
    type: FETCH_CONTACTS,
    ...params,
    cb
  });
}
