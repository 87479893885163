import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemStart, fetchItemSuccess, fetchItemError
} from '../../common/reducers';

import {
  createProductsSetSelectedProductsSuccess
} from './createProductsSetSelectedProducts';

import {
  emptyProductsSetStart,
  emptyProductsSetSuccess,
  emptyProductsSetError
} from './emptyProductsSet';

import {
  removeProductsSetSelectedProductsError,
  removeProductsSetSelectedProductsStart,
  removeProductsSetSelectedProductsSuccess
} from './removeProductsSetSelectedProducts';

export default createReducer(defaultState, {
  FETCH_PRODUCTS_SET_START: fetchItemStart,
  FETCH_PRODUCTS_SET_SUCCESS: fetchItemSuccess,
  FETCH_PRODUCTS_SET_ERROR: fetchItemError,

  CREATE_SELECTED_PRODUCT_SUCCESS: createProductsSetSelectedProductsSuccess,

  REMOVE_SELECTED_PRODUCT_START: removeProductsSetSelectedProductsStart,
  REMOVE_SELECTED_PRODUCT_SUCCESS: removeProductsSetSelectedProductsSuccess,
  REMOVE_SELECTED_PRODUCT_ERROR: removeProductsSetSelectedProductsError,

  EMPTY_PRODUCTS_SET_START: emptyProductsSetStart,
  EMPTY_PRODUCTS_SET_SUCCESS: emptyProductsSetSuccess,
  EMPTY_PRODUCTS_SET_ERROR: emptyProductsSetError
});
