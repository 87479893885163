import React, { Fragment } from 'react';
import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const LibraryProductsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryProductsIndexPage/LibraryProductsIndexPage'
    )
});

const LibraryProductsShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryProductsShowPage/LibraryProductsShowPage'
    )
});

const LibraryLifestylesIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryLifestylesIndexPage/LibraryLifestylesIndexPage'
    )
});

const LibraryLifestylesShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryLifestylesShowPage/LibraryLifestylesShowPage'
    )
});

const LibraryMaterialsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryMaterialsIndexPage/LibraryMaterialsIndexPage'
    )
});

const LibraryMaterialsShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "library" */ './pages/LibraryMaterialsShowPage/LibraryMaterialsShowPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/library/:userId(\d+)"
      component={LibraryProductsShowPage}
    />
    <Route exact path="/library" component={LibraryProductsIndexPage} />
    <Route
      exact
      path="/library/products/:userId(\d+)"
      component={LibraryProductsShowPage}
    />
    <Route
      exact
      path="/library/products"
      component={LibraryProductsIndexPage}
    />
    <Route
      exact
      path="/library/lifestyles/:userId(\d+)"
      component={LibraryLifestylesShowPage}
    />
    <Route
      exact
      path="/library/lifestyles"
      component={LibraryLifestylesIndexPage}
    />
    <Route
      exact
      path="/library/materials/:userId(\d+)"
      component={LibraryMaterialsShowPage}
    />
    <Route
      exact
      path="/library/materials"
      component={LibraryMaterialsIndexPage}
    />
  </Fragment>
);
