import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  REMOVE_SELECTED_TEMPLATE,
  REMOVE_SELECTED_TEMPLATE_START,
  REMOVE_SELECTED_TEMPLATE_SUCCESS,
  REMOVE_SELECTED_TEMPLATE_ERROR
} from '../SelectedTemplateConstants';

import { removeItemStart, removeItemResource } from '../../common/sagas';

function* removeSelectedTemplate({ id, options, cb }) {
  yield put(removeItemStart(REMOVE_SELECTED_TEMPLATE_START, id, options));

  return yield spawn(removeItemResource, 'selected_scenes', {
    success: REMOVE_SELECTED_TEMPLATE_SUCCESS,
    error: REMOVE_SELECTED_TEMPLATE_ERROR
  }, { id, options, cb });
}

export default function* watchRemoveSelectedTemplate() {
  yield takeEvery(REMOVE_SELECTED_TEMPLATE, removeSelectedTemplate);
}
