import { FORWARD_MESSAGE } from '../MessageConstants';

// forwardMessage

export default function forwardMessage(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: FORWARD_MESSAGE,
    id,
    values,
    options,
    cb
  });
}
