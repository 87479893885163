import isString from 'lodash/isString';
import size from 'lodash/size';
import floor from 'lodash/floor';

function maskEmailPart(emailPart: string): string {
  const partSize = size(emailPart);
  if (partSize < 1) {
    return emailPart;
  }

  return emailPart
    .split('')
    .map((char, charIndex) => {
      const isCentralCharToHide =
        partSize > 4
          ? charIndex > 1 && charIndex < partSize - 2
          : charIndex === floor(partSize / 2);

      return isCentralCharToHide ? '*' : char;
    })
    .join('');
}

function maskEmail(email?: string): string | undefined {
  if (isString(email)) {
    const emailParts = email.split('@');
    const maskedEmailParts = emailParts.map(emailPart =>
      maskEmailPart(emailPart)
    );

    return maskedEmailParts.join('@');
  }
  return undefined;
}

export default maskEmail;
