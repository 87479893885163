import { takeEvery, put, spawn } from 'redux-saga/effects';

import {
  ALLOCATE_FUNDS_TO_TASKS,
  ALLOCATE_FUNDS_TO_TASKS_START,
  ALLOCATE_FUNDS_TO_TASKS_SUCCESS,
  ALLOCATE_FUNDS_TO_TASKS_ERROR
} from '../TaskConstants';

import { postItemsStart, postItemsResource } from '../../common/sagas';

function* allocateFundsToTasks({ values, options, cb }) {
  yield put(postItemsStart(ALLOCATE_FUNDS_TO_TASKS_START, options));

  return yield spawn(postItemsResource, 'discussions/allocate_funds', {
    success: ALLOCATE_FUNDS_TO_TASKS_SUCCESS,
    error: ALLOCATE_FUNDS_TO_TASKS_ERROR
  }, { values, options, cb });
}

export default function* watchAllocateFundsToTasks() {
  yield takeEvery(ALLOCATE_FUNDS_TO_TASKS, allocateFundsToTasks);
}
