import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_TODO_ITEM,
  FETCH_TODO_ITEM_START,
  FETCH_TODO_ITEM_SUCCESS,
  FETCH_TODO_ITEM_ERROR
} from '../TodoItemConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchTodoItem({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_TODO_ITEM_START, id, options));

  const fetchTodoItemAction = yield spawn(fetchItemResource, 'todo_items', {
    success: FETCH_TODO_ITEM_SUCCESS,
    error: FETCH_TODO_ITEM_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchTodoItemAction);
}

export default function* watchFetchTodoItem() {
  yield takeEvery(FETCH_TODO_ITEM, fetchTodoItem);
}
