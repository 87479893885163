import React, { ReactNode } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { FadeProps } from 'reactstrap/lib/Fade';
import cl from 'classnames';
import isString from 'lodash/isString';

import { IsLoading } from '../../../types';

import { Button } from '../Button';
import { PureButton } from '../PureButton';

import { Translate } from '../Translate';

export interface SimpleModalProps {
  backdrop?: boolean;
  backdropTransition?: FadeProps;
  keyboard?: boolean;
  dark?: boolean;
  centered?: boolean;
  modalBodyAddClass?: string;
  modalHeaderAddClass?: string;
  modalIcon?: string;
  modalSize?: string;
  modalTitle?: ReactNode;
  i18nModalTitle?: string;
  isLoading?: IsLoading;
  disabled?: boolean;
  cancelColor?: string;
  cancelI18nText?: string;
  cancelDisabled?: boolean;
  submitColor?: string;
  i18nSubmitText?: string;
  submitAddClass?: string | Array<{}> | Array<string>;
  submitDisabled?: boolean;
  submitIcon?: string | ReactNode;
  submitSize?: string;
  submitText?: string | ReactNode;
  submitTooltipI18nValue?: string;
  submitTooltipTarget?: string;
  withoutCancel?: boolean;
  withoutFooter?: boolean;
  withoutBody?: boolean;
  children: ReactNode;
}

export interface SimpleModalHookProps {
  isOpen: boolean;
  hideModal: () => void;
  handleSubmit?: () => void;
}

function SimpleModal({
  backdrop = true,
  backdropTransition,
  keyboard,
  dark,
  centered,
  modalBodyAddClass,
  modalHeaderAddClass,
  modalIcon,
  modalSize,
  modalTitle,
  i18nModalTitle,
  isLoading,
  disabled,
  cancelColor = 'white',
  cancelI18nText = 'words.cancel',
  cancelDisabled,
  submitColor = 'primary',
  i18nSubmitText = 'words.save',
  submitAddClass,
  submitDisabled,
  submitIcon,
  submitSize = 'sm',
  submitText,
  submitTooltipI18nValue,
  submitTooltipTarget,
  isOpen,
  hideModal,
  handleSubmit,
  children,
  withoutCancel,
  withoutFooter,
  withoutBody
}: SimpleModalProps & SimpleModalHookProps) {
  return (
    <Modal
      backdrop={backdrop}
      backdropTransition={backdropTransition}
      keyboard={keyboard}
      centered={centered}
      isOpen={isOpen}
      size={modalSize}
      toggle={hideModal}
    >
      <ModalHeader
        className={cl({ 'bg-dark': dark }, modalHeaderAddClass)}
        toggle={hideModal}
      >
        <div className="modal-title text-muted font-size-base">
          {modalIcon ? <i className={cl(modalIcon, 'mr-2')} /> : null}
          {i18nModalTitle ? <Translate id={i18nModalTitle} /> : modalTitle}
        </div>
      </ModalHeader>
      {withoutBody ? (
        children
      ) : (
        <ModalBody className={cl({ 'bg-dark': dark }, modalBodyAddClass)}>
          {children}
        </ModalBody>
      )}
      {withoutFooter ? null : (
        <ModalFooter
          className={cl('card-footer py-2 justify-content-between border-top', {
            'bg-dark border-0': dark
          })}
        >
          <div />
          <div className="btn-group">
            {!withoutCancel && (
              <PureButton
                disabled={isLoading || disabled || cancelDisabled}
                color={cancelColor}
                icon="times-circle"
                i18nText={cancelI18nText}
                size={submitSize}
                onClick={hideModal}
              />
            )}
            {submitText ? (
              <Button
                addClass={submitAddClass}
                disabled={isLoading || disabled || submitDisabled}
                color={submitColor}
                icon={
                  isString(submitIcon) ? (
                    <i className={submitIcon} />
                  ) : (
                    submitIcon
                  )
                }
                size={submitSize}
                onClick={handleSubmit}
                tooltipI18nValue={submitTooltipI18nValue}
                tooltipTarget={submitTooltipTarget}
              >
                {isLoading ? (
                  <Translate id="messages.processing" />
                ) : (
                  submitText
                )}
              </Button>
            ) : (
              <PureButton
                addClass={submitAddClass}
                disabled={isLoading || disabled || submitDisabled}
                color={submitColor}
                icon={
                  isString(submitIcon) ? (
                    <i className={submitIcon} />
                  ) : (
                    submitIcon
                  )
                }
                size={submitSize}
                i18nText={isLoading ? 'messages.processing' : i18nSubmitText}
                onClick={handleSubmit}
                tooltipI18nValue={submitTooltipI18nValue}
                tooltipTarget={submitTooltipTarget}
              />
            )}
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default SimpleModal;
