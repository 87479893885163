import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

import get from 'lodash/get';
import size from 'lodash/size';

import { withMessages } from '../../main/common/HOCs/withMessages';

import { ForwardedMessageBody } from '../../main/messages/helpers/ForwardedMessageBody';
import { MessageBody } from '../../main/messages/helpers/MessageBody';
import { MessageId } from '../../main/messages/helpers/MessageId';

import { UserLink } from '../../main/users/components/UserLink';

import { AlertMessage } from '../../helpers/AlertMessage';
import { LinkTo } from '../../helpers/LinkTo';
import { Loading } from '../../helpers/Loading';
import { MoreButton } from '../../helpers/MoreButton';
import { translate as t } from '../../utils';
import { renderDateTime } from '../../utils/renderDateTime';
import { pushToAction } from '../../utils/pushToAction';

// {currentUser.get('users_unread_discussion_messages_count') > 0 ? (
//   <div className="dropdown-content-header">
//             <span className="font-size-sm line-height-sm text-uppercase font-weight-semibold">
//               {t('models.messages.plural')}
//             </span>
//   </div>
// ) : null}

function MessagesMenuContent({
  currentUser,
  dropdownItems, dropdownItemsMeta, dropdownItemsFetched, dropdownItemsLoading, dropdownItemsErrorMessage,
  fetchMessages, readMessage, unreadMessage, loadMoreMessages, readAllMessages, dropdownIsLoading, handleNavigate
}) {
  return (
    <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350 show">
      <Loading loaded={dropdownItemsFetched && !dropdownIsLoading}>
        <div className="dropdown-content-body dropdown-scrollable">
          <AlertMessage>{dropdownItemsErrorMessage}</AlertMessage>
          {size(dropdownItems) === 0 ? (
            <div className="text-center my-5">
              <span>{t('models.messages.no')}</span>
            </div>
          ) : null}
          {size(dropdownItems) > 0 ? (
            <ul className="media-list">
              {dropdownItems.map(message => (
                <div key={message.id} className="media flex-column flex-md-row">
                  <div className="mr-md-3 mb-2 mb-md-0">
                    <UserLink user={get(message, 'user')} avatar />
                  </div>

                  <div className="media-body font-size-sm">
                    <div className="media-title">
                      <ul className="d-flex list-inline">
                        <li className="list-inline-item">
                          <UserLink user={get(message, 'user')} className="font-weight-semibold" />
                        </li>
                        <li className="list-inline-item ml-auto">
                          <span className="text-muted font-size-sm">
                            {renderDateTime(message.created_at, currentUser.get('locale'))}
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <LinkTo
                            pureLink
                            href="#"
                            tooltipTarget={`dropdown-message-${message.id}-read`}
                            tooltipValue={
                              message.read
                                ? t('models.messages.unread')
                                : t('models.messages.read')
                            }
                            onClick={
                              message.read
                                ? unreadMessage(message)
                                : readMessage(message)
                            }
                          >
                            <span className={`badge badge-mark ${message.read ? 'text-default' : 'border-pink-400'}`} />
                          </LinkTo>
                        </li>
                      </ul>
                    </div>

                    <ForwardedMessageBody item={get(message, 'forwarded_message')} />

                    <MessageBody className="mb-1" item={message} onPayment={fetchMessages} />

                    <ul className="list-inline mb-0">
                      <MessageId item={message} />
                      <li className="list-inline-item">
                        {message.task ? (
                          <LinkTo
                            pureLink
                            href={`/projects/${message.task_id}/messages`}
                            onClick={handleNavigate(`projects/${message.task_id}/messages`)}
                          >
                            {`#${get(message, 'task.id')}: ${get(message, 'task.name')}`}
                          </LinkTo>
                        ) : null}
                        {message.discussion ? (
                          <LinkTo
                            pureLink
                            href={`/tasks/${message.discussion_id}`}
                            onClick={handleNavigate(`tasks/${message.discussion_id}`)}
                          >
                            {`#${get(message, 'discussion.id')}: ${get(message, 'discussion.name')}`}
                          </LinkTo>
                        ) : null}
                      </li>
                    </ul>
                    <Loading loaded={!message.loading} />
                    <AlertMessage>{message.error}</AlertMessage>
                  </div>
                </div>
              ))}
            </ul>
          ) : null}
        </div>

        {currentUser.get('users_unread_discussion_messages_count') > 0 ? (
          <div className="dropdown-content-footer bg-light">
            <MoreButton
              withoutWrapper
              type="link"
              className="font-size-sm line-height-sm text-grey mr-auto"
              icon="font-size-sm icon-rotate-cw3 mr-1"
              meta={dropdownItemsMeta}
              isLoading={dropdownItemsLoading}
              onClick={loadMoreMessages}
            />
            <div className="pull-right">
              <LinkTo
                pureLink
                href="#"
                className="font-size-sm line-height-sm text-grey mr-auto"
                onClick={readAllMessages(fetchMessages)}
              >
                {t('words.mark_all_as_read')}
              </LinkTo>
            </div>
          </div>
        ) : null}
      </Loading>
    </div>
  );
}

MessagesMenuContent.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number, get: PropTypes.func }).isRequired,
  dropdownItems: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
  dropdownItemsMeta: PropTypes.shape({ page: PropTypes.number }).isRequired,
  dropdownItemsFetched: PropTypes.bool.isRequired,
  dropdownItemsLoading: PropTypes.bool.isRequired,
  dropdownIsLoading: PropTypes.bool.isRequired,
  dropdownItemsErrorMessage: PropTypes.string,
  fetchMessages: PropTypes.func.isRequired,
  readMessage: PropTypes.func.isRequired,
  unreadMessage: PropTypes.func.isRequired,
  loadMoreMessages: PropTypes.func.isRequired,
  readAllMessages: PropTypes.func.isRequired,
  handleNavigate: PropTypes.func.isRequired
};

MessagesMenuContent.defaultProps = {
  dropdownItemsErrorMessage: null
};

export default compose(
  connect(null, { pushTo: pushToAction }),
  withMessages({
    scope: 'messages',
    fetchType: 'index',
    indexAction: 'discussion_messages_discussion_messages_index_dropdown',
    namespace: 'dropdown',
    serializer: 'dropdown',
    fields: ['items', 'itemsMeta', 'itemsFetched', 'itemsLoading', 'isLoading', 'itemsErrorMessage'],
    actions: ['fetchMessages', 'readMessage', 'unreadMessage', 'loadMoreMessages', 'readAllMessages']
  }),
  withHandlers({
    handleNavigate: ({ pushTo, onNavigate }) => path => () => {
      pushTo(path);
      onNavigate && onNavigate();
    }
  })
)(MessagesMenuContent);
