import { TOGGLE_SELECTED_MESSAGE } from '../MessageConstants';

// toggleSelectedMessage

export default function toggleSelectedMessage(messageId, cb) {
  return dispatch => dispatch({
    type: TOGGLE_SELECTED_MESSAGE,
    messageId,
    cb
  });
}
