import { updateState } from '../../../utils';

export function readAllMessagesStart(state, { options = {} }) {
  return updateState(state, {
    isLoading: true,
    errorMessage: null
  }, options);
}

export function readAllMessagesSuccess(state, { options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage: null
  }, options);
}

export function readAllMessagesError(state, { errorMessage, options = {} }) {
  return updateState(state, {
    isLoading: false,
    errorMessage
  }, options);
}
