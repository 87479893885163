import React, { Fragment } from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../helpers/ts/Loading';

function loading() {
  return <Loading />;
}

function emptyLoading() {
  return <Fragment />;
}

export default function LoadableWrapper(opts) {
  return Loadable({
    loading: opts.withoutLoading ? emptyLoading : loading,
    delay: 200,
    timeout: 10000,
    ...opts
  });
}
