import dropRight from 'lodash/dropRight';
import get from 'lodash/get';
import split from 'lodash/split';
import last from 'lodash/last';

import defaultImage from '../../styles/base/images/placeholders/blank-profile-picture-512@2x.png';

class Files {
  static image(file, version) {
    const url = Files.url(file, version);

    return this.isImage(url) ? url : defaultImage;
  }

  static defaultVersion() {
    return 'thumb_160x160';
  }

  static extension(file) {
    return file && file.substr(file.lastIndexOf('.') + 1);
  }

  static isImage(file) {
    return /(jpe?g|png|gif|bmp)/i.test(this.extension(file));
  }

  static is3dMaxModel(file) {
    return /(max)/i.test(this.extension(file));
  }

  static isArchive(file) {
    return /(zip|rar|7z)/i.test(this.extension(file));
  }

  static isPDF(file) {
    return /(pdf)/i.test(this.extension(file));
  }

  static url(item, version) {
    const url = get(item, 'file');

    if (url && this.isImage(url)) {
      const urlParts = split(url, '/');

      return [
        ...dropRight(urlParts),
        [version || Files.defaultVersion(), last(urlParts)].join('_')
      ].join('/');
    }

    return url;
  }
}

export default Files;
