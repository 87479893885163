import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_PERMISSION,
  FETCH_PERMISSION_START,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_ERROR
} from '../PermissionConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchPermission({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_PERMISSION_START, id, options));

  const fetchPermissionAction = yield spawn(fetchItemResource, 'permissions', {
    success: FETCH_PERMISSION_SUCCESS,
    error: FETCH_PERMISSION_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchPermissionAction);
}

export default function* watchFetchPermission() {
  yield takeEvery(FETCH_PERMISSION, fetchPermission);
}
