import { DOWNLOAD_MESSAGE_SELECTED_PRODUCTS } from '../MessageConstants';

// downloadMessageSelectedProducts

export default function downloadMessageSelectedProducts(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: DOWNLOAD_MESSAGE_SELECTED_PRODUCTS,
    id,
    values,
    options,
    cb
  });
}
