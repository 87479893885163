export const FETCH_TEMPLATES_SET = 'FETCH_TEMPLATES_SET';
export const FETCH_TEMPLATES_SET_START = 'FETCH_TEMPLATES_SET_START';
export const FETCH_TEMPLATES_SET_SUCCESS = 'FETCH_TEMPLATES_SET_SUCCESS';
export const FETCH_TEMPLATES_SET_ERROR = 'FETCH_TEMPLATES_SET_ERROR';

export const DOWNLOAD_TEMPLATES_SET = 'DOWNLOAD_TEMPLATES_SET';
export const DOWNLOAD_TEMPLATES_SET_START = 'DOWNLOAD_TEMPLATES_SET_START';
export const DOWNLOAD_TEMPLATES_SET_SUCCESS = 'DOWNLOAD_TEMPLATES_SET_SUCCESS';
export const DOWNLOAD_TEMPLATES_SET_ERROR = 'DOWNLOAD_TEMPLATES_SET_ERROR';

export const EMPTY_TEMPLATES_SET = 'EMPTY_TEMPLATES_SET';
export const EMPTY_TEMPLATES_SET_START = 'EMPTY_TEMPLATES_SET_START';
export const EMPTY_TEMPLATES_SET_SUCCESS = 'EMPTY_TEMPLATES_SET_SUCCESS';
export const EMPTY_TEMPLATES_SET_ERROR = 'EMPTY_TEMPLATES_SET_ERROR';
