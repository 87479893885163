export default {
  roles: {
    client: {
      singular: 'Client',
      plural: 'Clients',
      add: 'Add client',
      select: 'Select clients'
    },
    worker: {
      singular: 'Artist',
      plural: 'Artists',
      add: 'Add artist',
      select: 'Select artists'
    },
    project_manager: {
      singular: 'Project manager',
      plural: 'Project managers',
      add: 'Add PM',
      select: 'Select project managers'
    },
    support: {
      singular: 'Support',
      plural: 'Support'
    },
    mentor: {
      singular: 'Mentor',
      plural: 'Mentors'
    },
    manager: {
      singular: 'Manager',
      plural: 'Managers'
    },
    art_director: {
      singular: 'Art director',
      plural: 'Art directors'
    },
    remote_worker: {
      singular: 'Remote worker',
      plural: 'Remote workers'
    },
    system: {
      singular: 'System',
      plural: 'System'
    }
  }
};
