import { put, call } from 'redux-saga/effects';

import { parseError } from '../../../utils/parseError';
import { apiRequest } from '../../../utils/apiRequest';

export function fetchItemsStart(type, options = {}) {
  return {
    type,
    options
  };
}

export function fetchItemsSuccess(type, items = [], meta = {}, options = {}) {
  return {
    type,
    items,
    meta,
    options
  };
}

export function fetchItemsError(type, error, options = {}) {
  return {
    type,
    errorMessage: parseError(error),
    error,
    options
  };
}

export function fetchItemsRequest(path, { page, pageSize, filters, persistFilters, serializer, withoutPagination, withoutTotal }) {
  return apiRequest.get(path, {
    page: page || 1,
    page_size: (withoutPagination ? 1000 : pageSize) || 50,
    filters,
    persist_filters: persistFilters,
    serializer,
    ...(withoutTotal ? { without_total: true } : {})
  }).then(({ data: { data: items, meta } }) => ({ items, meta }));
}

export function* fetchItemsResource(path, { success, error: errorConst }, {
  page, pageSize, filters, persistFilters, withoutPagination, withoutTotal, serializer, namespace, loadMore, cb
}) {
  try {
    const { items, meta } = yield call(fetchItemsRequest, path, { page, pageSize, filters, persistFilters, withoutPagination, withoutTotal, serializer });
    yield put(fetchItemsSuccess(success, items, { ...meta, page, pageSize, filters }, { persistFilters, namespace, loadMore, serializer }));
    cb && cb({ items, meta });
  } catch (error) {
    yield put(fetchItemsError(errorConst, error, { namespace }));
  }
}
