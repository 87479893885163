import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, take, put, cancel, spawn } from 'redux-saga/effects';

import {
  DOWNLOAD_TRANSACTIONS,
  DOWNLOAD_TRANSACTIONS_START,
  DOWNLOAD_TRANSACTIONS_SUCCESS,
  DOWNLOAD_TRANSACTIONS_ERROR
} from '../TransactionConstants';

import { downloadItemsStart, downloadItemsResource } from '../../common/sagas';

function* downloadTransactions(params) {
  yield put(downloadItemsStart(DOWNLOAD_TRANSACTIONS_START, params));

  const downloadTransactionsAction = yield spawn(downloadItemsResource, 'transactions.xlsx', {
    success: DOWNLOAD_TRANSACTIONS_SUCCESS,
    error: DOWNLOAD_TRANSACTIONS_ERROR
  }, params);
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTransactionsAction);
}

export default function* watchDownloadTransactions() {
  yield takeEvery(DOWNLOAD_TRANSACTIONS, downloadTransactions);
}
