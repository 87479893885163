import { createReducer } from 'redux-create-reducer';

import { initMaterialsFilters } from './common';
import {
  toggleSelectedMaterialStart,
  toggleSelectedMaterialSuccess,
  toggleSelectedMaterialError
} from './toggleSelectedMaterial';
import {
  toggleBlockedMaterialStart,
  toggleBlockedMaterialSuccess,
  toggleBlockedMaterialError
} from './toggleBlockedMaterial';

import {
  defaultState,
  downloadItemStart, downloadItemSuccess, downloadItemError,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  itemsChannelUpdated, locationChange, clearModalValues,
  removeItemStart, removeItemSuccess, removeItemError
} from '../../common/reducers';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  AUTH_USER: initMaterialsFilters,

  DOWNLOAD_MATERIAL_START: downloadItemStart,
  DOWNLOAD_MATERIAL_SUCCESS: downloadItemSuccess,
  DOWNLOAD_MATERIAL_ERROR: downloadItemError,

  FETCH_MATERIALS_START: fetchItemsStart,
  FETCH_MATERIALS_SUCCESS: fetchItemsSuccess,
  FETCH_MATERIALS_ERROR: fetchItemsError,

  FETCH_MATERIAL_START: fetchItemStart,
  FETCH_MATERIAL_SUCCESS: fetchItemSuccess,
  FETCH_MATERIAL_ERROR: fetchItemError,

  CREATE_MATERIAL_START: createItemStart,
  CREATE_MATERIAL_SUCCESS: createItemSuccess,
  CREATE_MATERIAL_ERROR: createItemError,

  UPDATE_MATERIAL_START: updateItemStart,
  UPDATE_MATERIAL_SUCCESS: updateItemSuccess,
  UPDATE_MATERIAL_ERROR: updateItemError,

  TOGGLE_SELECTED_MATERIAL_START: toggleSelectedMaterialStart,
  TOGGLE_SELECTED_MATERIAL_SUCCESS: toggleSelectedMaterialSuccess,
  TOGGLE_SELECTED_MATERIAL_ERROR: toggleSelectedMaterialError,

  TOGGLE_BLOCKED_MATERIAL_START: toggleBlockedMaterialStart,
  TOGGLE_BLOCKED_MATERIAL_SUCCESS: toggleBlockedMaterialSuccess,
  TOGGLE_BLOCKED_MATERIAL_ERROR: toggleBlockedMaterialError,

  REMOVE_MATERIAL_START: removeItemStart,
  REMOVE_MATERIAL_SUCCESS: removeItemSuccess,
  REMOVE_MATERIAL_ERROR: removeItemError,

  CLEAR_MATERIAL_FILTERS_START: clearItemsFiltersStart,
  CLEAR_MATERIAL_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_MATERIAL_MODAL_VALUES: clearModalValues,

  MATERIALS_CHANNEL_UPDATED: itemsChannelUpdated
});
